import { neosThunks } from '@/neos/business/thunks';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { ImportButton } from '@/neos/components/share/importExportClipboard/ImportButton.tsx';

type ElsBuyAndSemmImportProps = {
  rfqId: string;
  product: Els;
};

export const ElsBuyAndSellImport = ({ product, rfqId }: ElsBuyAndSemmImportProps) => {
  const dispatch = useAppDispatch();

  function onImport(importedData: unknown[]) {
    dispatch(
      neosThunks.createImportElsBuyAndSellCompositionThunk(
        rfqId,
        product.uuid,
        importedData,
        'file',
      ),
    );
  }

  return <ImportButton handleImport={onImport} dataE2e="els-buy-and-sell-import" />;
};
