import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import type { FC } from 'react';

type MarkitWireIdProps = { rfqId: string; legId: string; allocationId: string };

export const MarkitWireId: FC<MarkitWireIdProps> = props => {
  const dispatch = useAppDispatch();
  const markitWireBookingInfo = useAppSelector(state =>
    selectors.getMarkitWireBookingStep(state, props.rfqId, props.allocationId),
  );
  const allocationKey = {
    allocationId: props.allocationId,
    rfqId: props.rfqId,
    legId: props.legId,
  };

  const allocation = useAppSelector(state =>
    selectors.getOtcAllocationByAllocId(state, props.allocationId),
  );

  const markitWireReferenceId =
    allocation?.media === 'MARKITWIRE' ? allocation.markitWireReferenceId : undefined;

  return (
    <NeosBookingId
      defaultBookingId={markitWireBookingInfo?.bookingId}
      dataE2e="markitwire-booking-id"
      isReadOnly={allocation?.media !== 'MARKITWIRE'}
      bookingId={markitWireReferenceId?.id ?? markitWireBookingInfo?.bookingId}
      bookingStatus={markitWireReferenceId ? undefined : markitWireBookingInfo?.status}
      onBookingIdChanged={newBookingId =>
        dispatch(
          neosActionCreators.otcAllocationCrudActions.update(allocationKey, {
            markitWireReferenceId: { id: newBookingId },
          }),
        )
      }
    />
  );
};
