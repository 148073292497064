import { Portfolio } from '@/neos/components/rfq/strategyDetails/Portfolio.tsx';
import { AllocationLegSizeWithExecTooltip } from '../allocInfo/AllocationLegSizeWithExecTooltip';
import { AveragePrice } from '../allocInfo/AveragePrice';
import { ClientWay } from '../allocInfo/ClientWay';
import { LegBookingId } from '../allocInfo/LegBookingId/LegBookingId';
import { Maturity } from '../allocInfo/Maturity';
import { OptionStyle } from '../allocInfo/OptionStyle';
import { ProductType } from '../allocInfo/ProductType';
import { PtmmPrice } from '../allocInfo/PtmmPrice/PtmmPrice';
import { SettlementDate } from '../allocInfo/SettlementDate';
import { Strike } from '../allocInfo/Strike';
import { Underlying } from '../allocInfo/Underlying';
import styles from './LegDescription.module.scss';
import { Cashflows } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/cashflows/Cashflows.tsx';
import { StartDate } from '@/neos/components/rfq/postNego/allocs/allocInfo/StartDate.tsx';
import { ConfirmationModeComponent } from '@/neos/components/rfq/postNego/allocs/allocInfo/ConfirmationMode.tsx';
import { CounterpartPortfolio } from '@/neos/components/rfq/strategyDetails/CounterpartPortfolio.tsx';

export interface LegDescriptionOwnProps {
  rfqId: string;
  strategyId: string;
  legId: string;
}

export function LegDescription(props: LegDescriptionOwnProps) {
  return (
    <div
      className={`${styles['leg-description']} mb-2 text-nowrap`}
      data-e2e="neos-postNego-leg-description"
    >
      <AllocationLegSizeWithExecTooltip {...props} />
      <AveragePrice {...props} />
      <ClientWay {...props} />
      <Underlying {...props} />
      <ProductType {...props} />
      <StartDate {...props} />
      <Maturity {...props} />
      <Strike {...props} />
      <OptionStyle {...props} />
      <SettlementDate {...props} />
      <div>
        <Portfolio {...props} isComponentInLegDescription={true} />
      </div>
      <div>
        <CounterpartPortfolio rfqId={props.rfqId} strategyId={props.strategyId} />
      </div>
      <LegBookingId {...props} />
      <PtmmPrice {...props} />
      <Cashflows {...props} />
      <ConfirmationModeComponent {...props} />
    </div>
  );
}
