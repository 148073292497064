import {
  type BookingStepApplication,
  availableBookingApplications,
} from '@/neos/business/bookingSteps/bookingStepOnyxModel';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type DeltaAdjustedBookingIdProps = {
  strategyId: string;
  bookingIdManual: boolean;
  value: BookingStepApplication | undefined;
  defaultBookingId: string | undefined;
  bookingId: string | undefined;
  readOnly: boolean;
};

export function DeltaAdjustedBookingId(props: DeltaAdjustedBookingIdProps) {
  const dispatch = useAppDispatch();

  function onDeltaBookingIdChanged(strategyId: string, bookingId: string | undefined) {
    dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { bookingId }));
    if (bookingId === undefined) {
      dispatch(
        neosActionCreators.strategyDataCrudActions.update(strategyId, {
          bookingApplication: undefined,
        }),
      );
    }
  }

  function onDeltaBookingApplicationChanged(
    strategyId: string,
    bookingApplication: BookingStepApplication | undefined,
  ) {
    dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { bookingApplication }));
  }

  return (
    <div className="d-flex">
      {props.bookingIdManual && (
        <NeosSelect
          style={{ maxWidth: '120px' }}
          addEmptyOption
          dataE2e="neos-rfq-detlaAdjusted-bookingApplication"
          options={availableBookingApplications.map(application => ({
            value: application,
            label: application,
          }))}
          onChange={value => onDeltaBookingApplicationChanged(props.strategyId, value)}
          value={props.value}
          readOnly={props.readOnly}
        />
      )}
      <NeosBookingId
        dataE2e="neos-rfq-deltaAdjusted-bookingId"
        defaultBookingId={props.defaultBookingId}
        bookingId={props.bookingId}
        isReadOnly={props.readOnly}
        onBookingIdChanged={value => onDeltaBookingIdChanged(props.strategyId, value)}
      />
    </div>
  );
}
