import type { Selectors } from '@/bootstrap/selectors';
import { getIsStrategyDefinitionFieldEditable } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/fieldsEnablingConditions';
import { hasMaturity } from '../../../../../../../business/neosModel';
import { computeNumberOfDays } from '../../../../../reference/underlyingReference/refMaturityCell/getRefMaturityCellModel';
import { type Field, getFieldRelevantProducts } from '../../utils';
import type { AppState } from '@/bootstrap/store.ts';

export function getMaturitiesFieldsArray(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): Array<Field<string | undefined> & { isValid: boolean }> {
  const { getStrategyData, getStrategyDefinition } = selectors;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductMaturity } = getStrategyDefinition(appState.referenceData, strategyType);
  const products = getFieldRelevantProducts(appState, strategyId, sameProductMaturity, selectors);

  const derivativeProducts = products.filter(hasMaturity);

  const isEditable = getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
    'RFQ_SCOPE',
    'RFQ_EDITABLE',
    'UNDERLYING_SET',
    'STRATEGY_EDITABLE',
  ]);
  return derivativeProducts.map(product => {
    return {
      id: product.legId,
      value: product.maturity,
      isEditable,
      isValid: isLegMaturityUpToDate(product.maturity),
    };
  });
}

export function isLegMaturityUpToDate(maturity: string | undefined) {
  return computeNumberOfDays(new Date())(maturity ?? '') >= 0;
}
