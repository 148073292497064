import type { ChainOptions } from '@/neos/business/neosActions';
import type { ReferenceKey } from '../models';
import { RfqRecomputeScope } from '../models/recomputeModel';
import { referenceActionCreators } from '../reference/referenceActionCreators';
import { createActionWithRecompute } from './actionWithRecompute';
import type { Action } from '@/bootstrap/actions.ts';

export function createExecutedSpotChangedAction(
  referenceKey: ReferenceKey,
  executedSpot: number | undefined,
  chainOptions?: ChainOptions,
): Action[] {
  return createActionWithRecompute(
    referenceKey.rfqId,
    RfqRecomputeScope.CHANGE_EXECUTED_SPOT,
    [referenceActionCreators.referenceCrudActions.update(referenceKey, { executedSpot })],
    chainOptions,
  );
}
