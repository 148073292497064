import { thunks } from '@/bootstrap/thunks';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export const HelpRequestButton = () => {
  const dispatch = useAppDispatch();

  function handleHelpRequestClick() {
    dispatch(thunks.common.createHelpRequestSentThunk());
  }

  return (
    <SimpleNeosTooltip placement="left" message="Help request" id="help-request-menu-tooltip">
      <button
        className="btn btn-xl btn-flat-primary btn-icon"
        onClick={handleHelpRequestClick}
        data-e2e="nav-bar-help-request-icon"
      >
        <i className="icon">support</i>
      </button>
    </SimpleNeosTooltip>
  );
};
