import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import type { Counterpart } from '@/neos/business/neosModel';
import { CounterPart } from './CounterPart';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function Transferor({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  // can be mapped to either secondary info or step in info depending on the event type
  const dispatch = useAppDispatch();
  const currentAllocation = useAppSelector(state =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const { eventType } = useAppSelector(state => selectors.getLegData(state, legId));

  const counterpart =
    eventType === 'STEPIN'
      ? currentAllocation?.stepInInfo?.transferor
      : currentAllocation?.secondaryInfo?.transferor;

  const onChange = (value: Counterpart | undefined) => {
    if (eventType === 'STEPIN') {
      dispatch(
        thunks.neos.createUpdateStepInInfoThunk(allocationId, rfqId, legId, {
          transferor: value,
        }),
      );
    } else {
      dispatch(
        thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
          transferor: value,
        }),
      );
    }
  };

  return (
    <CounterPart
      allocationId={allocationId}
      counterpart={counterpart}
      onChange={onChange}
      rfqId={rfqId}
      legId={legId}
    />
  );
}
