import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export type CreateSecondaryButtonModel = {
  isRfqSecondaryWidgetToggleOn: boolean;
  isDisabled: boolean;
};

export function getCreateSecondaryButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): CreateSecondaryButtonModel {
  const isRfqSecondaryWidgetToggleOn = selectors.isRfqSecondaryWidgetToggleOn(state.ui, rfqId);
  const legsWithSecondaryType = selectors.getRfqLegsSecondaryEventCreationEventTypes(state, rfqId);
  const areSomeSecondaryEventCreationLegs = legsWithSecondaryType.length > 0;
  return { isRfqSecondaryWidgetToggleOn, isDisabled: !areSomeSecondaryEventCreationLegs };
}
