import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { thunks } from '@/bootstrap/thunks';
import { isElsProduct } from '@/neos/business/rfq/models';
import {
  type RightToSubstitute,
  type RightToSubstituteScope,
  RightToSubstituteScopeValues,
  RightToSubstituteValues,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';

import { selectors } from '@/bootstrap/selectors';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { commonElsBlocClasses, ElsFeaturesContext } from '../ElsFeatures';
import { useUpdateSectionHeight } from '../useUpdateSectionHeight';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

interface RightToSubstituteProps {
  canBeHidden: boolean;
}

export function RightToSubstituteBloc({ canBeHidden }: RightToSubstituteProps) {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();

  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  const { ref } = useUpdateSectionHeight(strategyId, 'Right to Substitute');

  if (!isElsProduct(product)) {
    return null;
  }

  const { rightToSubstituteConditions, rightToSubstituteScope, uuid } = product;

  function onRightToSubstituteConditionsChanged(
    newRightToSubstituteConditions: RightToSubstitute | undefined,
  ) {
    dispatch(
      actionCreators.neos.productCrudActions.update(uuid, {
        rightToSubstituteConditions: newRightToSubstituteConditions,
      }),
    );
  }

  function onRightToSubstituteScopeChanged(
    newRightToSubstituteScope: RightToSubstituteScope | undefined,
  ) {
    dispatch(
      actionCreators.neos.productCrudActions.update(uuid, {
        rightToSubstituteScope: newRightToSubstituteScope,
      }),
    );

    dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
  }

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Right to Substitute', false));
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Right to Substitute</h5>
          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>

        <div className="d-flex flex-wrap">
          <ElsBlocField
            label="Scope"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                value={rightToSubstituteScope}
                onChange={onRightToSubstituteScopeChanged}
                data-e2e="els-section-right-to-substitute-scope"
                addEmptyOption
                options={RightToSubstituteScopeValues}
              />
            )}
          />

          <ElsBlocField
            label="Conditions"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                value={rightToSubstituteConditions}
                onChange={onRightToSubstituteConditionsChanged}
                data-e2e="els-section-right-to-substitute-conditions"
                addEmptyOption
                options={RightToSubstituteValues}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
