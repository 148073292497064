import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  WeightCell as Component,
  type WeightCellDispatchProps,
  type WeightCellMapStateProps,
  type WeightCellOwnProps,
} from './WeightCell';
import { getWeightCellModel } from './getWeightCellModel';
import type { AppState } from '@/bootstrap/store.ts';

export const WeightCell = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: WeightCellOwnProps,
): WeightCellMapStateProps {
  return getWeightCellModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: WeightCellOwnProps,
): WeightCellDispatchProps {
  return {
    onWeightChanged(weight: number | undefined) {
      dispatch(neosThunks.createRfqChangeStrategyWeightThunk(rfqId, strategyId, weight));
    },
  };
}
