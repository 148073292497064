import type { Selectors } from '@/bootstrap/selectors';
import type {
  MultipleTypeaheadOrigin,
  MultipleTypeaheadValue,
} from '@/neos/components/share/multipleAsyncTypeahead/MultipleAsyncTypeahead';
import type { Status } from '../../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface Tags {
  status: Status | undefined;
  counterPart: string | undefined;
  strategyTypes: string[];
  underlyings: string[];
}

export function hasCurrentTypeaheadTags(
  state: AppState,
  tags: Tags,
  { getBlotterActiveTypeaheadValue }: Selectors,
): boolean {
  const currentTypeaheadValues = getBlotterActiveTypeaheadValue(state.blotter);

  const status = getTagValueForOriginName('STATUS', currentTypeaheadValues);
  const strategyTypes = getTagValueForOriginName('STRATEGY_TYPE', currentTypeaheadValues);
  const counterParts = getTagValueForOriginName('COUNTERPART', currentTypeaheadValues);
  const underlyings = getTagValueForOriginName('UNDERLYING', currentTypeaheadValues);

  const hasTagForStatus = !status.length || status.includes(tags.status as string);
  const hasTagForCounterPart =
    !counterParts.length || counterParts.includes(tags.counterPart ?? '');
  const hasTagsForStratType = hasTagOrEmpty(strategyTypes, tags.strategyTypes);
  const hasTagsForUnderlyings = hasTagOrEmpty(underlyings, tags.underlyings);

  return hasTagForStatus && hasTagForCounterPart && hasTagsForStratType && hasTagsForUnderlyings;
}

function getTagValueForOriginName(
  originName: MultipleTypeaheadOrigin,
  typeaheadValues: MultipleTypeaheadValue[],
) {
  return typeaheadValues.filter(({ origin }) => origin === originName).map(({ value }) => value);
}

function hasTagOrEmpty(typeaheadValues: string[], stratFieldValues: string[]) {
  if (!typeaheadValues.length) {
    return true;
  }

  for (const typeaheadValue of typeaheadValues) {
    if (stratFieldValues.includes(typeaheadValue)) {
      return true;
    }
  }
  return false;
}
