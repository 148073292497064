import { useAppSelector } from '@/bootstrap/hooks';
import { type Selectors, selectors } from '@/bootstrap/selectors';

import { lowerThenCamelCase } from '@/util/string/stringUtils';
import type { LegDescriptionComponentProps } from './AveragePrice';
import type { AppState } from '@/bootstrap/store.ts';

export function getClientWayByLegId(state: AppState, legId: string, selectors: Selectors) {
  return selectors.getLegData(state, legId).clientWay;
}

export function ClientWay({ legId }: LegDescriptionComponentProps) {
  const clientWay = useAppSelector(state => getClientWayByLegId(state, legId, selectors));
  return (
    <div>
      <label className="mb-0 me-3">C. Way</label>
      <input
        className="form-control"
        data-e2e="client-way"
        value={lowerThenCamelCase(clientWay ?? '').replace('Two', '2')}
        readOnly
      />
    </div>
  );
}
