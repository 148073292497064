import type { Selectors } from '@/bootstrap/selectors';
import type { SelectOption } from '@/neos/components/share/NeosSelect/NeosSelect';
import type { AppState } from '@/bootstrap/store.ts';

export type SettlementCurrencyModel = {
  displayedCurrency: string | undefined;
  rfqLegIds: string[];
  isReadonly: boolean;
  currencies: SelectOption[];
  haveAllLegsSameCurrency: boolean;
} | null;

export function getSettlementCurrencyModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): SettlementCurrencyModel {
  const rfqLegIds = selectors.getRfqLegIds(state, rfqId, selectors);
  const allLegsAreOtc =
    rfqLegIds.length && rfqLegIds.every(legId => selectors.isOtcLeg(state, legId, selectors));

  if (!allLegsAreOtc) {
    return null;
  }

  const isReadOnlyAtCurrentWorkflow = selectors.isRfqReadOnlyAtCurrentWorkflow(
    state,
    rfqId,
    selectors,
  );

  const rfqLegsData = rfqLegIds.map(legId => selectors.getLegData(state, legId));

  const firstLegCurrency = rfqLegsData[0].settlement?.currency;
  const haveAllLegsSameCurrency: boolean = rfqLegsData.every(
    leg => leg.settlement?.currency === firstLegCurrency,
  );

  const displayedCurrency = haveAllLegsSameCurrency ? firstLegCurrency : undefined;

  const currencies = selectors
    .getCurrencyList(state.referenceData)
    .map(currency => ({ value: currency.refId, label: currency.refId }));

  if (!haveAllLegsSameCurrency) {
    currencies.unshift({ value: '', label: 'Same as Undl' });
  }

  const isReadonly = isReadOnlyAtCurrentWorkflow || selectors.isRfqEls(state, rfqId, selectors);

  return { displayedCurrency, rfqLegIds, currencies, haveAllLegsSameCurrency, isReadonly };
}
