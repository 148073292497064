import type { Selectors } from '@/bootstrap/selectors';
import type { NegoStep } from '@/neos/business/ui/rfq/rfqUiModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface NegoStepSwitcherModel {
  targetStep: NegoStep;
}

export function getNegoStepSwitcherModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): NegoStepSwitcherModel {
  const currentStep = selectors.getSelectedNegoStep(state.ui, rfqId);
  const targetStep: NegoStep =
    currentStep === 'NEGO' || currentStep === 'ELS_DETAILS' ? 'POST_NEGO' : 'NEGO';

  return { targetStep };
}
