import type { NextUserActions } from './nextUserActionsModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getNextUserActions(state: AppState, rfqId: string): NextUserActions {
  return state.nextUserActions[rfqId] || { nextActions: [] };
}

export function isSavePossible(state: AppState, rfqId: string): boolean {
  return getNextUserActions(state, rfqId).nextActions.some(({ action }) => action === 'self');
}

export const nextUserActionsSelectors = { getNextUserActions, isSavePossible };
