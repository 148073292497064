import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export const quoteSelectors = {
  getLegQuoteIdsFromStrategy,
  getLegsPtmmms,
};

function getLegQuoteIdsFromStrategy(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): string[] {
  const { legIds } = selectors.getStrategyData(appState, strategyId);
  const quoteIds = legIds.map(legId => {
    const { quoteId } = selectors.getLegData(appState, legId);
    return quoteId;
  });
  return quoteIds;
}

export interface PriceWithKey {
  key: string;
  price: number | undefined;
}

function getLegsPtmmms(appState: AppState, legIds: string[], selectors: Selectors): PriceWithKey[] {
  return legIds.map(legId => {
    const { quoteId } = selectors.getLegData(appState, legId);

    const { preTradeMidMarketMarkPrice: ptmmm } = quoteId
      ? selectors.getQuote(appState, quoteId)
      : { preTradeMidMarketMarkPrice: undefined };

    return { key: legId, price: ptmmm };
  });
}
