import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { createDefaultTradeRecapRequestedAction } from '@/neos/business/rfq/rfqActionCreators/defaultTradeRecapRequestAction';
import { neosThunks } from '@/neos/business/thunks';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  LegExecutionComponent,
  type LegExecutionDispatchProps,
  type LegExecutionOwnProps,
} from './LegExecution';
import { getLegExecutionModel } from './getLegExecutionModel';

export const LegExecution: FC<LegExecutionOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const stateProps = useAppSelector(state => ({
    model: getLegExecutionModel(state, ownProps.executionKey, selectors),
  }));
  const dispatchProps = mapDispatchProps(dispatch, ownProps);

  return <LegExecutionComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  { executionKey }: LegExecutionOwnProps,
): LegExecutionDispatchProps {
  const { rfqId, executionId } = executionKey;
  return {
    onExecutionRemoved: () => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', `Remove execution`, executionId),
        neosActionCreators.executionCrudActions.delete(executionKey),
        neosActionCreators.listedAllocationCrudActions.deleteByPartialKey({
          executionId,
        }),
        neosActionCreators.allocationInstructionCrudActions.deleteByPartialKey({
          executionId,
        }),
        neosThunks.createChangeOrderUpdateExecSizeThunk({
          orderId: executionKey.orderId,
          rfqId: executionKey.rfqId,
        }),
        createDefaultTradeRecapRequestedAction(executionKey.rfqId, {
          success: {
            dispatchables: [
              neosThunks.createChangeOrderUpdateAveragePriceThunk({
                orderId: executionKey.orderId,
                rfqId: executionKey.rfqId,
              }),
            ],
          },
        }),
      );
    },
    onExecutionTimeChanged: (newTime: string | undefined) => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', `Change Execution Time`, executionId),
        neosThunks.createChangeExecutionTimeThunk(executionKey, newTime),
      );
    },
    onExecutionDateChanged: (newDate: string | undefined) => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', `Change Execution Date`, executionId),
        neosThunks.createChangeExecutionDateThunk(executionKey, newDate),
      );
    },
    onExecutionNumberOfLotsChanged: (numberOfLots: number | undefined) => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', `Change Execution Quantity`, executionId),
        neosThunks.createChangeExecutionNumberOfLotsThunk(executionKey, numberOfLots),
        neosThunks.createRfqChangeIsRfqContainingInvalidListedExecStrategyThunk(
          rfqId,
          executionKey,
        ),
      );
    },
    onExecutionPriceChanged: (price: number | undefined, unit: string | undefined) => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', `Change Execution Price`, executionId),
        neosActionCreators.executionCrudActions.update(executionKey, {
          lastPrice: { value: price, unit, type: 'CCY' },
        }),
        createDefaultTradeRecapRequestedAction(executionKey.rfqId, {
          success: {
            dispatchables: [
              neosThunks.createChangeOrderUpdateAveragePriceThunk({
                orderId: executionKey.orderId,
                rfqId: executionKey.rfqId,
              }),
            ],
          },
        }),
      );
    },
    onExecutionEliotIdChanged: eliotId => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', `Change Execution Eliot ID`, executionId),
        neosActionCreators.executionCrudActions.update(executionKey, {
          externalExecId: eliotId ? { application: 'XONE', id: eliotId } : undefined,
          bookingStatus: 'MANUALLY_BOOKED',
        }),
      );
    },
    onAllocationAdded: () => {
      dispatch(neosThunks.createAddAllocationThunk(executionKey));
    },
  };
}
