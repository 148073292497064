import { type Selectors } from '@/bootstrap/selectors.ts';
import type { Field } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/Field.ts';
import { isListedProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { AppState } from '@/bootstrap/store.ts';

interface ContractNameCellModel {
  contractNames: Field<string | undefined>[];
}

export function getContractNameModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): ContractNameCellModel {
  const products = selectors.getStrategyProducts(state, strategyId, selectors);

  const contractNamesFormatted = products.map(product =>
    isListedProduct(product)
      ? { id: product.legId, value: product.contractName, isEditable: true }
      : { id: product.legId, value: undefined, isEditable: false },
  );

  return { contractNames: contractNamesFormatted };
}
