import { useAppSelector } from '@/bootstrap/hooks';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function SgTermNotice() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, termNotice } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onTermNoticeChange = (newTermNotice: number) => {
    dispatch(updateProduct(uuid, { termNotice: newTermNotice }));
  };

  return (
    <ElsBlocField
      label="SG Term Notice"
      renderInput={readOnly => (
        <NumericInput
          readOnly={readOnly}
          value={termNotice}
          onBlur={onTermNoticeChange}
          data-e2e="early-termination-section-sgTermNotice"
        />
      )}
    />
  );
}
