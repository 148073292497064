import type { Thunk } from '@/bootstrap/thunks';

import type { Action } from '@/bootstrap/actions.ts';

export function createSelectNeosApplicationThunk(rfqId?: string): Thunk {
  return function selectNeosApplicationThunk(
    dispatch,
    getState,
    {
      selectors: { getSesameId, isFeatureToggleEnabled, getSalesGroups, isTrader },
      actionCreators: { neos },
      thunks: {
        neos: { createRequestBlotterThunk },
      },
    },
  ) {
    const state = getState();
    const isFirstAppLoad = getSalesGroups(state.referenceData).length === 0;
    const sesameId = getSesameId(state);
    const isUserTrader = isTrader(state);

    if (!isFirstAppLoad) {
      if (rfqId) {
        dispatch(neos.loadTransactionAtVersion({ rfqId, version: 'LATEST' }));
      }
      return;
    }

    const actions: Action[] = [
      neos.createReferenceDataRequested(
        'BLOTTER',
        sesameId,
        rfqId
          ? {
              success: {
                dispatchables: [neos.loadTransactionAtVersion({ rfqId, version: 'LATEST' })],
              },
            }
          : undefined,
      ),
      ...[neos.createAlertRequestedAction()],
    ];

    if (
      isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmail.enabled') ||
      isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmailFuture.enable') ||
      isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmailDivFut.enable')
    ) {
      actions.push(neos.loadUserPrefPreconfGroupsActionCreator());
    }

    if (isFeatureToggleEnabled(state, 'neos.user.preferences.defaulting')) {
      actions.push(
        neos.currentUserPreferencesActionCreators.createGetCurrentUserPreferencesReferentialActionCreator(
          isUserTrader,
        ),
        neos.currentUserPreferencesActionCreators.createGetCurrentUserPreferencesAction(),
      );
    }
    actions.push(neos.createGetCurrentUserBrokersAction());

    dispatch(actions, createRequestBlotterThunk());
  };
}
