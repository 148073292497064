import type { Selectors } from '@/bootstrap/selectors';
import type { StateMap } from '@/util/collectionHelper';
import { orderDomain } from './orderDataDomain';
import type { OrderData } from './orderDataModel';
import type { AppState } from '@/bootstrap/store.ts';

const orderCrudSelectors = orderDomain.selectors;

export const orderDataSelectors = {
  getOrders,
  getOrderByLegId,
  getOrdersByLegId,
  getOrder,
  getOrderByLegIdAndCrossWay,
};

function getOrders(state: StateMap<OrderData>, rfqId: string): OrderData[] | undefined {
  return orderCrudSelectors.selectObjects(state, { rfqId });
}

function getOrder(state: StateMap<OrderData>, orderId: string): OrderData | undefined {
  return orderCrudSelectors.selectObjects(state, { orderId })[0];
}

function getOrderByLegId(
  state: StateMap<OrderData>,
  rfqId: string,
  legId: string,
): OrderData | undefined {
  return orderCrudSelectors
    .selectObjects(state, { rfqId })
    .find(({ clientOrderUuid }) => clientOrderUuid === legId);
}

function getOrdersByLegId(state: StateMap<OrderData>, rfqId: string, legId: string): OrderData[] {
  return (
    orderCrudSelectors
      .selectObjects(state, { rfqId })
      .filter(({ clientOrderUuid }) => clientOrderUuid === legId) ?? []
  );
}

export function getOrderByLegIdAndCrossWay(
  state: AppState,
  { rfqId, legId }: { rfqId: string; strategyId: string; legId: string },
  selectors: Selectors,
): OrderData | undefined {
  const orders = selectors.getOrdersByLegId(state.orderData, rfqId, legId);

  const asDefinedOrder = orders.find(({ crossWay }) => crossWay === 'AS_DEFINED');

  return asDefinedOrder ?? orders.find(({ crossWay }) => crossWay === 'OPPOSITE');
}
