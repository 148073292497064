import { constantToSentence } from '@/util/string/stringUtils';
import { useRef, useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { catchError, type Observable } from 'rxjs';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { thunks } from '@/bootstrap/thunks';

export type MultipleTypeaheadOrigin = 'COUNTERPART' | 'UNDERLYING' | 'STATUS' | 'STRATEGY_TYPE';

const TypeaheadOriginPosition: { [key in MultipleTypeaheadOrigin]: number } = {
  STATUS: 1,
  STRATEGY_TYPE: 2,
  UNDERLYING: 3,
  COUNTERPART: 4,
};

export interface MultipleTypeaheadValue {
  label: string;
  value: string;
  origin: MultipleTypeaheadOrigin;
}

export interface MultipleAsyncTypeaheadApi {
  fetchMatchingOptions: (prefix: string) => Observable<MultipleTypeaheadValue[]>;
}

export interface MultipleAsyncTypeaheadProps {
  id?: string;
  api: MultipleAsyncTypeaheadApi;
  values: MultipleTypeaheadValue[];
  disabled?: boolean;
  placeholder?: string;
  staticOptions: MultipleTypeaheadValue[];
  onChange: (option: MultipleTypeaheadValue[]) => void;
  ['data-e2e']?: string;
  children?: any;
}

export function MultipleAsyncTypeahead(props: MultipleAsyncTypeaheadProps) {
  const dispatch = useAppDispatch();
  const typeaheadRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<MultipleTypeaheadValue[]>([]);

  const onSearch = (userInput: string) => {
    const filteredStaticOptions = props.staticOptions.filter(option =>
      option.label.toLocaleUpperCase().startsWith(userInput.toLocaleUpperCase()),
    );

    return props.api
      .fetchMatchingOptions(userInput)
      .pipe(
        catchError(() => {
          dispatch(
            thunks.createErrorToasterThunk(
              { message: 'An error has occured while searching for results' },
              undefined,
            ),
          );
          return [];
        }),
      )
      .subscribe(options => {
        setOptions(filteredStaticOptions.concat(options));
      });
  };

  const {
    id,
    api,
    children,
    disabled,
    onChange,
    values,
    placeholder,
    staticOptions,
    ...restProps
  } = props;

  return (
    <div ref={typeaheadRef} {...restProps}>
      <AsyncTypeahead
        id={id}
        isLoading={false}
        delay={1000}
        minLength={1}
        options={options.sort(
          (a, b) => TypeaheadOriginPosition[a.origin] - TypeaheadOriginPosition[b.origin],
        )}
        multiple
        onSearch={onSearch}
        onChange={selected => onChange(selected as MultipleTypeaheadValue[])}
        selected={values ? values : []}
        disabled={disabled}
        placeholder={placeholder}
        renderMenu={(
          results,
          { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps },
        ) => {
          return (
            <Menu
              {...menuProps}
              className={`${menuProps.className ?? ''} fit-content`}
              style={{ ...menuProps.style, width: undefined, maxWidth: '1000px' }}
            >
              {results.map((option, index) => {
                if (typeof option === 'string') {
                  return <MenuItem key={option + index} position={index} option="" />;
                }

                const value = option as MultipleTypeaheadValue;
                const label =
                  'paginationOption' in value ? 'Display additional results...' : value.label;

                return (
                  <MenuItem
                    key={value.label + index}
                    title={`${constantToSentence(value.origin)}: ${value.label}`}
                    option={option}
                    position={index}
                  >
                    <div className="d-inline-flex gap-2">
                      <span className={`badge bg-${getBadgeClass(value.origin)}`}>
                        {value.origin?.slice(0, 1)}
                      </span>
                      <label>{label}</label>
                    </div>
                  </MenuItem>
                );
              })}
            </Menu>
          );
        }}
        renderToken={(option, props) => {
          if (typeof option === 'string') {
            return <span></span>;
          }

          const value = option as MultipleTypeaheadValue;
          return (
            <span
              key={id}
              title={constantToSentence(value.origin)}
              className={`badge bg-${getBadgeClass(
                value.origin,
              )} badge-dismissible me-1 align-self-center`}
            >
              <button
                className="btn"
                onClick={() => {
                  props?.onRemove?.(option);
                }}
              >
                <i className="icon">close</i>
              </button>
              {value.label}
            </span>
          );
        }}
      />
    </div>
  );
}

function getBadgeClass(multipleTypeaheadOrigin: MultipleTypeaheadOrigin) {
  switch (multipleTypeaheadOrigin) {
    case 'COUNTERPART':
      return 'primary';
    case 'STATUS':
      return 'info';
    case 'UNDERLYING':
      return 'warning';
    case 'STRATEGY_TYPE':
      return 'danger';
  }
}
