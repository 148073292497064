import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { ExecutionKey } from '@/neos/business/order/orderModel';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { useState } from 'react';
import { getCashFlowIdModel } from './getCashFlowIdModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

interface CashFlowIdProps {
  executionKey: ExecutionKey;
}

export function CashFlowId({ executionKey }: CashFlowIdProps) {
  const dispatch = useAppDispatch();

  const { cashFlowId, defaultCashFlowId, status } = useAppSelector(state =>
    getCashFlowIdModel(state, executionKey, selectors),
  );

  const [refreshKey, setRefreshKey] = useState(0);

  return (
    <NeosBookingId
      bookingId={cashFlowId}
      bookingStatus={status}
      key={refreshKey}
      defaultBookingId={defaultCashFlowId}
      dataE2e="leg-execution-cash-flow-id"
      onBookingIdChanged={newCashFlowId => {
        setRefreshKey(Date.now);
        dispatch(
          actionCreators.neos.executionCrudActions.update(executionKey, {
            cashFlowId: newCashFlowId ? { id: newCashFlowId, application: 'XONE' } : undefined,
            bookingStatus: undefined,
          }),
        );
      }}
    />
  );
}
