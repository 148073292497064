import type { Thunk } from '@/bootstrap/thunks';
import { getLegRecomputeScope, LegRecomputeChangedScope } from '../models/recomputeModel';

export function createRfqChangeLegWeightThunk(
  rfqId: string,
  strategyId: string,
  legId: string,
  newWeight: number | undefined,
): Thunk {
  return function rfqChangeLegWeightThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createRecomputeRfqThunk, createChangeLegRatioThunk },
      },
    },
  ) {
    const state = getState();
    const { isMasterStrategy } = selectors.getStrategyData(state, strategyId);
    const { isMaster: isMasterLeg, initialWeight } = selectors.getLegData(state, legId);
    const hasWeightSignChanged =
      newWeight !== undefined && Math.sign(initialWeight) !== Math.sign(newWeight);

    const scope = getLegRecomputeScope({
      scope: LegRecomputeChangedScope.WEIGHT,
      isMasterStrategy,
      isMasterLeg,
      hasWeightSignChanged,
    });

    dispatch(
      createRecomputeRfqThunk(rfqId, scope, [
        createChangeLegRatioThunk(rfqId, strategyId, legId, newWeight || 1),
      ]),
    );
  };
}
