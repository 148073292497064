import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { wrapInChainableLoadingObservable } from '../epics/wrapInChainableLoadingObservable';
import type { OnyxError } from '../mappers/error';
import { createRetrieveUnderlyingApi } from '@/neos/business/referenceData/underlying/underlyingApi.ts';
import type { RetrieveUnderlyingApi } from '@/neos/business/referenceData/underlying/underlyingEpics.ts';
import type { ThunkEpic } from '@/bootstrap/epics.ts';

export function getPartialUnderlyingOptionInfoEpic(http: SgmeHttp): ThunkEpic {
  const api = createRetrieveUnderlyingApi(http);
  return createPartialUnderlyingOptionInfoEpic(api, actionCreators, thunks);
}

export function createPartialUnderlyingOptionInfoEpic(
  api: RetrieveUnderlyingApi,
  {
    common: { createAppCrashedAction },
    neos: { createPartialUnderlyingsInfosReceivedAction },
  }: ActionCreators,
  { createErrorToasterThunk }: Thunks,
): ThunkEpic {
  return (action$, _) =>
    action$.pipe(
      ofType('PARTIAL_UNDERLYING_INFO_REQUESTED'),
      mergeMap(({ rfqIds, underlyingIds, chainOptions }) => {
        return wrapInChainableLoadingObservable({
          tabIds: rfqIds,
          apiObservable: api.retrievePartialUnderlyingByGalaxyCodes(underlyingIds),
          observableOptions: {
            success: {
              on: partialUnderlyingInfos => {
                return [createPartialUnderlyingsInfosReceivedAction(partialUnderlyingInfos)];
              },
            },
            error: {
              on: (error: OnyxError) => [
                createAppCrashedAction('partial-underlying-option-info-requested', error),
                createErrorToasterThunk(
                  {
                    message: 'Error when retrieving partial underlying details',
                  },
                  error,
                ),
              ],
            },
          },
          chainOptions,
        });
      }),
    );
}
