import type { Selectors } from '@/bootstrap/selectors';
import type { Thunk } from '@/bootstrap/thunks';
import type { NeosActionCreators } from '@/neos/business/neosActionCreators';
import { flatMap } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';
import type { Action } from '@/bootstrap/actions.ts';

type QuoteStartLevel = 'STRATEGY' | 'LEG';

export function createRfqResetStrategyQuoteThunk(
  rfqId: string,
  strategyId: string,
  startLevel: QuoteStartLevel = 'LEG',
): Thunk {
  return function rfqResetStrategyQuoteThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { quoteCrudActions },
      },
    },
  ) {
    const state = getState();

    dispatch(
      getResetQuotesBatchAction(state, rfqId, strategyId, startLevel, quoteCrudActions, selectors),
    );
  };
}
function getResetQuotesBatchAction(
  state: AppState,
  rfqId: string,
  strategyId: string,
  startLevel: QuoteStartLevel,
  quoteCrudAction: NeosActionCreators['quoteCrudActions'],
  selectors: Selectors,
): Action[] {
  const { getRfqData, getStrategyData, getLegData, getQuote } = selectors;
  // Rfq quote
  const { quoteId: rfqQuoteId } = getRfqData(state, rfqId);
  const {
    unit: rfqQuoteUnit,
    salesAskType: rfqQuoteSalesAskType,
    salesBidType: rfqQuoteSalesBidType,
    traderAskType: rfqQuoteTraderAskType,
    traderBidType: rfqQuoteTraderBidType,
  } = getQuote(state, rfqQuoteId);
  const upsertRfqQuoteActions = [
    quoteCrudAction.upsert(rfqQuoteId, {
      parentId: { type: 'Rfq', id: rfqId },
      deltaUnit: '%',
      unit: rfqQuoteUnit,
      salesAskType: rfqQuoteSalesAskType,
      salesBidType: rfqQuoteSalesBidType,
      traderAskType: rfqQuoteTraderAskType,
      traderBidType: rfqQuoteTraderBidType,
      initialSalesPrice: undefined,
      initialTraderPrice: undefined,
    }),
  ];

  // Strategy quote
  const { quoteId: strategyQuoteId, legIds } = getStrategyData(state, strategyId);
  const {
    unit: strategyQuoteUnit,
    salesAskType: strategyQuoteSalesAskType,
    salesBidType: strategyQuoteSalesBidType,
    traderAskType: strategyQuoteTraderAskType,
    traderBidType: strategyQuoteTraderBidType,
  } = getQuote(state, strategyQuoteId);
  const upsertStrategyQuoteActions = [
    quoteCrudAction.upsert(strategyQuoteId, {
      parentId: { type: 'Strategy', id: strategyId },
      deltaUnit: '%',
      unit: strategyQuoteUnit,
      salesAskType: strategyQuoteSalesAskType,
      salesBidType: strategyQuoteSalesBidType,
      traderAskType: strategyQuoteTraderAskType,
      traderBidType: strategyQuoteTraderBidType,
      initialSalesPrice: undefined,
      initialTraderPrice: undefined,
    }),
  ];

  // Legs quotes
  const legIdsToMap = startLevel !== 'STRATEGY' ? legIds : [];
  const upsertLegQuoteActions = flatMap(legIdsToMap, legId => {
    const { quoteId: legQuoteId } = getLegData(state, legId);
    const {
      unit: legQuoteUnit,
      salesAskType: legQuoteSalesAskType,
      salesBidType: legQuoteSalesBidType,
      traderAskType: legQuoteTraderAskType,
      traderBidType: legQuoteTraderBidType,
    } = getQuote(state, legQuoteId);
    return [
      quoteCrudAction.upsert(legQuoteId, {
        parentId: { type: 'Leg', id: legId },
        deltaUnit: '%',
        unit: legQuoteUnit,
        salesAskType: legQuoteSalesAskType,
        salesBidType: legQuoteSalesBidType,
        traderAskType: legQuoteTraderAskType,
        traderBidType: legQuoteTraderBidType,
        initialSalesPrice: undefined,
        initialTraderPrice: undefined,
      }),
    ];
  });
  return [...upsertRfqQuoteActions, ...upsertStrategyQuoteActions, ...upsertLegQuoteActions];
}
