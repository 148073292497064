import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import { SearchBrokersInput } from '@/neos/components/userPreferences/brokers/SearchBrokersInput';
import { Button } from 'react-bootstrap';

export function BrokersPreferences() {
  const dispatch = useAppDispatch();

  const isAddButtonLoading = useAppSelector(
    state => state.ui.userPreferences.isAddBrokerPreferenceButtonLoading,
  );
  const isDeleteButtonLoading = useAppSelector(
    state => state.ui.userPreferences.isDeleteBrokerPreferenceButtonLoading,
  );
  const isLoading = isAddButtonLoading || isDeleteButtonLoading;

  const currentUserBrokers = useAppSelector(state => selectors.getBrokersList(state.referenceData));

  const searchedBrokers = useAppSelector(
    state => state.ui.userPreferences.searchedBrokers,
  ).toSorted((brokerA, brokerB) => (brokerA.name > brokerB.name ? 1 : -1));

  const onDeleteBroker = (broker: Broker) => {
    dispatch(actionCreators.neos.createChangeUserPreferenceDeleteBrokersLoadingAction(true));
    dispatch(actionCreators.neos.createDeleteBrokerPreferencesAction(broker.id));
  };

  const onAddBroker = (broker: Broker) => {
    const isDisabled =
      currentUserBrokers.find(userBroker => userBroker.id === broker.id) !== undefined;

    if (isDisabled) {
      return;
    }

    dispatch(actionCreators.neos.createChangeUserPreferenceAddBrokersLoadingAction(true));
    dispatch(actionCreators.neos.createAddBrokerPreferencesAction(broker.id));
  };

  return (
    <div className="d-grid gap-3 h-100" style={{ gridTemplateColumns: '1fr 1fr' }}>
      <div className="card bg-lvl3 overflow-hidden">
        <div className="card-header">
          <h5 className="card-title d-inline">Search </h5> by name, mnemo or BDR id
          {isLoading && <div className="spinner spinner-sm ms-2"></div>}
          <SearchBrokersInput searchedBrokers={searchedBrokers} />
        </div>

        <div className="card-body px-1 overflow-auto">
          <table className="table table-sm table-striped table-borderless">
            <tbody>
              {searchedBrokers.map(searchedBroker => {
                const isDisabled =
                  currentUserBrokers.find(userBroker => userBroker.id === searchedBroker.id) !==
                  undefined;

                return (
                  <tr key={searchedBroker.id}>
                    <th>{searchedBroker.name}</th>
                    <td>{searchedBroker.mnemo}</td>
                    <td>{searchedBroker.id}</td>
                    <td className="align-middle">
                      <Button
                        className="btn-icon flex-shrink-0"
                        variant="flat-primary"
                        style={{ pointerEvents: isDisabled ? 'none' : undefined }}
                        onClick={() => onAddBroker(searchedBroker)}
                        data-e2e={`user-preferences-brokers-add-${searchedBroker.id}`}
                      >
                        {isDisabled ? <i className="icon">star</i> : <i className="icon">add</i>}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card bg-lvl3 overflow-hidden">
        <div className="card-header">
          <h5 className="card-title">Selected brokers</h5>
        </div>
        <div className="card-body px-1 overflow-auto">
          <table className="table table-sm table-striped table-borderless">
            <tbody>
              {currentUserBrokers.map(broker => (
                <tr key={broker.id}>
                  <td className="align-middle">
                    <Button
                      className="btn-icon"
                      variant="flat-primary"
                      onClick={() => onDeleteBroker(broker)}
                      data-e2e={`user-preferences-brokers-delete-${broker.id}`}
                    >
                      <i className="icon">delete_forever</i>
                    </Button>
                  </td>
                  <th>{broker.name}</th>
                  <td>{broker.mnemo}</td>
                  <td>{broker.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
