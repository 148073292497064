import type { Thunk } from '@/bootstrap/thunks';
import { batch } from 'react-redux';

export interface StrategyAndOutdatedLegs {
  strategyId: string;
  outdatedLegIds: string[];
}

export function createRfqDeleteObsoleteLegsThunk(parameters: StrategyAndOutdatedLegs[]): Thunk {
  return function rfqDeleteObsoleteLegsThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData },
      thunks: {
        neos: { createRfqDeleteLegThunk },
      },
    },
  ) {
    batch(() => {
      const appState = getState();
      parameters.forEach(({ strategyId, outdatedLegIds }) => {
        const { legIds: updatedLegIds } = getStrategyData(appState, strategyId);
        const obsoleteLegIds = outdatedLegIds.filter(legId => !updatedLegIds.includes(legId));
        obsoleteLegIds.forEach(legId => dispatch(createRfqDeleteLegThunk(legId)));
      });
    });
  };
}
