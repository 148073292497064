import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { Axe, SpreadWidth } from '@/neos/business/rfq/rfqOnyxModel';
import { NumericInput } from '@/neos/components/share/numericInput';
import { cn } from '@/util/classNames';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { clientWays, idbWays } from '../../../../../neos/business/neosModel';
import styles from './ClientFeedback.module.scss';

export type ClientFeedbackProps = {
  rfqId: string;
};

export const ClientFeedback = ({ rfqId }: ClientFeedbackProps) => {
  const dispatch = useAppDispatch();
  const {
    feedbackPriceValue,
    feedbackPriceUnit,
    feedbackSpreadWidth = 'UNKNOWN',
    feedbackAxe = 'UNKNOWN',
    clientWay = 'TWO_WAYS',
    internal,
  } = useAppSelector(state => selectors.getRfqData(state, rfqId));

  function onPriceValueChanged(value: number | undefined) {
    dispatch(
      neosActionCreators.rfqDataCrudActions.update(rfqId, {
        feedbackPriceValue: value,
      }),
    );
  }
  function onSpreadWidthChanged(value: SpreadWidth) {
    dispatch(neosActionCreators.rfqDataCrudActions.update(rfqId, { feedbackSpreadWidth: value }));
  }
  function onAxeChanged(value: Axe) {
    dispatch(neosActionCreators.rfqDataCrudActions.update(rfqId, { feedbackAxe: value }));
  }

  const headerClassName = 'fw-bold';
  const blocClassName = 'd-flex flex-column';
  return (
    <div className={`${styles['client-feedback']}`}>
      <label className="fw-bold m-1">Client Feedback</label>
      <div className="d-flex border p-2">
        <div className={blocClassName + ' me-2'}>
          <div className={headerClassName}>Level</div>
          <NumericInput
            data-e2e="feedback-price"
            value={feedbackPriceValue}
            unit={feedbackPriceUnit}
            onBlur={value => onPriceValueChanged(value)}
          />
        </div>
        <div className={blocClassName + ' me-2'}>
          <div className={headerClassName}>{internal ? 'Initiator Way' : 'Client Way'}</div>
          <div
            className={cn(
              'flex-fill px-2 d-inline-flex align-items-center justify-content-start fw-medium',
              {
                'bg-danger': clientWay === 'SELL',
                'bg-success': clientWay === 'BUY',
                'bg-primary': clientWay === 'TWO_WAYS',
              },
            )}
            data-e2e="feedback-client-way"
          >
            {internal ? idbWays[clientWay] : clientWays[clientWay]}
          </div>
        </div>
        <div className={blocClassName + ' me-2'}>
          <div className={headerClassName}>Spread Width</div>
          <ToggleButtonGroup
            name="feedbackSpreadWidth"
            type="radio"
            value={feedbackSpreadWidth}
            onChange={(value: SpreadWidth) => onSpreadWidthChanged(value)}
          >
            <ToggleButton
              className="px-2"
              variant="outline-danger"
              value={'WIDE' as SpreadWidth}
              data-e2e="feedback-spread-width-wide"
              id="feedback-spread-width-wide"
            >
              Wide
            </ToggleButton>
            <ToggleButton
              className="px-2"
              variant="outline-info"
              value={'AVERAGE' as SpreadWidth}
              data-e2e="feedback-spread-width-avg"
              id="feedback-spread-width-avg"
            >
              Avg.
            </ToggleButton>
            <ToggleButton
              className="px-2"
              variant="outline-success"
              value={'TIGHT' as SpreadWidth}
              data-e2e="feedback-spread-width-tight"
              id="feedback-spread-width-tight"
            >
              Tight
            </ToggleButton>
            <ToggleButton
              className="px-2"
              variant="outline-primary"
              value={'UNKNOWN' as SpreadWidth}
              data-e2e="feedback-spread-width-na"
              id="feedback-spread-width-na"
            >
              N/A
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={blocClassName}>
          <div className={headerClassName}>Axe</div>
          <ToggleButtonGroup
            name="feedbackAxe"
            type="radio"
            value={feedbackAxe}
            onChange={(value: Axe) => onAxeChanged(value)}
          >
            <ToggleButton
              className="px-2"
              variant="outline-danger"
              value={'BETTER_BID' as Axe}
              data-e2e="feedback-axe-bid"
              id="feedback-axe-bid"
            >
              Bid
            </ToggleButton>
            <ToggleButton
              className="px-2"
              variant="outline-info"
              value={'CENTERED' as Axe}
              data-e2e="feedback-axe-in-line"
              id="feedback-axe-in-line"
            >
              In Line
            </ToggleButton>
            <ToggleButton
              className="px-2"
              variant="outline-success"
              value={'BETTER_ASK' as Axe}
              data-e2e="feedback-axe-ask"
              id="feedback-axe-ask"
            >
              Ask
            </ToggleButton>
            <ToggleButton
              className="px-2"
              variant="outline-primary"
              value={'UNKNOWN' as Axe}
              data-e2e="feedback-axe-na"
              id="feedback-axe-na"
            >
              N/A
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
};
