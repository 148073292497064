import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { ErrorHighlightWithTooltip } from '../../../../../share/errorHighlightWithTooltip/ErrorHighlightWithTooltip.container';
import { NumericInput } from '../../../../../share/numericInput';

type SizeCellsProps = {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
};

export function SizeCells({ rfqId, strategyId, isReadOnlyRfq }: SizeCellsProps) {
  const dispatch = useAppDispatch();

  const sizes = useAppSelector(state =>
    selectors.getLegSizes(state, { strategyId, rfqId }, selectors),
  );
  const isEls = useAppSelector(state => selectors.isElsStrategy(state, strategyId, selectors));

  function onSizeChanged(legId: string, size: number | undefined, isStrategyUpdate: boolean) {
    const parameter = isStrategyUpdate
      ? { strategyId, value: size, isStrategyUpdate }
      : { strategyId, legId, value: size, isStrategyUpdate };
    dispatch([
      neosThunks.createRfqChangeStrategyOrLegSizeThunk(rfqId, parameter),
      neosThunks.createRfqChangeIsRfqContainingInvalidListedStrategyThunk(rfqId),
    ]);
  }

  function onSizesUnitChanged(sizeId: string) {
    dispatch(neosThunks.createLegSizesUnitChangedThunk(strategyId, sizeId));
  }

  return (
    <section>
      {sizes.map(
        ({
          id,
          value,
          isEditable,
          unit,
          isStrategySize,
          isValidStrategySize,
          isListedStrategy,
        }) => {
          return (
            <ErrorHighlightWithTooltip
              key={id}
              errorField={'numberOfLots'}
              related={{ rfqId, legId: id, strategyId }}
              id={strategyId}
              isDisplayed={!isValidStrategySize}
              renderMessage={() => <p>Must be integer</p>}
              componentClassName="warning-bloc field-error"
              tooltipClassName={
                isListedStrategy ? 'react-bootstrap-danger-tooltip' : 'display-none'
              }
            >
              <NumericInput
                isInteger={isEls}
                unit={unit}
                value={value}
                className="errorable-bloc"
                onBlur={val => onSizeChanged(id, val, isStrategySize)}
                onUnitClick={() => onSizesUnitChanged(id)}
                readOnly={!isEditable || isReadOnlyRfq}
                data-e2e="neos-strategy-definition-size"
              />
            </ErrorHighlightWithTooltip>
          );
        },
      )}
    </section>
  );
}
