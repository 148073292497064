import {
  SaveButtonComponent,
  type SaveButtonDispatchProps,
  type SaveButtonMapStateToProps,
  type SaveButtonOwnProps,
} from './SaveButton';

import { actionCreators } from '@/bootstrap/actions';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { throttle } from 'lodash';
import { connect } from 'react-redux';
import { getSaveButtonModel } from './getSaveButtonModel';
import type { AppState } from '@/bootstrap/store.ts';

export const SaveButton = connect(mapStateToProps, mapDispatchToProps)(SaveButtonComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: SaveButtonOwnProps,
): SaveButtonMapStateToProps {
  return {
    model: getSaveButtonModel(state, rfqId, selectors),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: SaveButtonOwnProps,
): SaveButtonDispatchProps {
  return {
    onSave: throttle(
      () => {
        dispatch(
          actionCreators.neos.rfqUiCrudActions.update(rfqId, {
            isRefSpotChangedByTrader: false,
            oldRefSpotValue: undefined,
          }),
        );
        createLogAnalyticsAction('NEOS RFQ', `Rfq save requested`);
        dispatch(thunks.neos.createRequestTransactionSaveThunk(rfqId));
      },
      500,
      { trailing: false },
    ),
  };
}
