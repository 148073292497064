import { insert, insertOrUpdate, remove, type StateMap, update } from '@/util/collectionHelper';
import { isEqual } from 'lodash';
import type { Reducer } from 'redux';
import { isCrudAction, type SType } from './crudActions';
import { type KeyIdConverter, objectContains } from './crudUtils';

export function crudReducer<S extends SType, K = string>(
  domain: string,
  { keyToString, stringToKey }: KeyIdConverter<K>,
): Reducer<StateMap<S>> {
  return (state = {}, action) => {
    if (!isCrudAction<S, K>(action) || action.domain !== domain) {
      return state;
    }

    switch (action.type) {
      case 'INSERT_ACTION': {
        const { id, item } = action;
        const stringId = keyToString(id);
        return insert(state, stringId, item);
      }
      case 'INSERT_OR_UPDATE': {
        const { items } = action;
        return items.reduce(
          (prev, { id, value }) => insertOrUpdate(prev, keyToString(id), value),
          state,
        );
      }
      case 'PATCH_OR_INSERT': {
        const { id, value } = action;
        const stringId = keyToString(id);
        return patchOrInsert(state, stringId, value);
      }
      case 'UPDATE': {
        const { id, patch } = action;
        const stringId = keyToString(id);
        return update(state, stringId, () => patch);
      }
      case 'DELETE': {
        const stringId = keyToString(action.id);
        return remove(state, stringId);
      }
      case 'DELETE_BY_PARTIAL_KEY': {
        const partialKey = action.id;
        return Object.keys(state)
          .filter(id => objectContains(stringToKey(id), partialKey))
          .reduce<StateMap<S>>((acc, id) => remove(acc, id), state);
      }
      case 'ARRAY_ADD': {
        const {
          id,
          changeParameters: { property, value },
        } = action;
        const stringId = keyToString(id);
        const currentItem = state[stringId];
        const currentArray: Array<unknown> = currentItem[property] || [];
        return {
          ...state,
          [stringId]: {
            ...currentItem,
            [property]: [...currentArray, value],
          },
        };
      }
      case 'ARRAY_ADD_AT_INDEX': {
        const {
          id,
          changeParameters: { property, index, value },
        } = action;
        const stringId = keyToString(id);
        const currentItem = state[stringId];
        const currentArray: Array<unknown> = currentItem[property] || [];
        const firstPart = currentArray.slice(0, index);
        const lastPart = currentArray.slice(index);
        return {
          ...state,
          [stringId]: {
            ...currentItem,
            [property]: [...firstPart, value, ...lastPart],
          },
        };
      }
      case 'ARRAY_REMOVE': {
        const {
          id,
          changeParameters: { property, value },
        } = action;
        const stringId = keyToString(id);
        const currentItem = state[stringId];
        const currentArray: Array<unknown> = currentItem[property] || [];

        return {
          ...state,
          [stringId]: {
            ...currentItem,
            [property]: currentArray.filter(currentValue => !isEqual(currentValue, value)),
          },
        };
      }

      case 'ARRAY_REMOVE_INDEX': {
        const {
          id,
          changeParameters: { property, index },
        } = action;
        const stringId = keyToString(id);
        const currentItem = state[stringId];
        const currentArray: Array<unknown> = currentItem[property] || [];
        ensureValidArrayIndex(currentArray, domain, property, index);

        const newArray = [...currentArray];
        newArray.splice(index, 1);

        return {
          ...state,
          [stringId]: {
            ...currentItem,
            [property]: newArray,
          },
        };
      }

      case 'ARRAY_PATCH': {
        const {
          id,
          changeParameters: { property, index, value },
        } = action;
        const stringId = keyToString(id);
        const currentItem = state[stringId];
        const currentArray = currentItem[property];
        ensureValidArrayIndex(currentArray, domain, property, index);

        const newArray = [...currentArray];
        newArray[index] = {
          ...newArray[index],
          ...(value as object),
        };

        return {
          ...state,
          [stringId]: {
            ...currentItem,
            [property]: newArray,
          },
        };
      }
    }
  };
}

function patchOrInsert<S extends SType>(state: StateMap<S>, key: string, value: S) {
  if (!state[key]) {
    return {
      ...state,
      [key]: value,
    };
  }
  return update(state, key, () => value);
}

function ensureValidArrayIndex<S extends SType>(
  currentArray: any,
  domain: string,
  property: keyof S,
  index: number,
) {
  if (!Array.isArray(currentArray)) {
    throw new Error(`property "${String(property)}" in crud domain "${domain}" should be an array`);
  }
  if (index < 0 || index >= currentArray.length) {
    throw new Error(
      `index ${index} is out of bound for array property ${String(property)} and domain ${domain}`,
    );
  }
}
