import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { getIsRfqHasEverBeenSaved } from '@/neos/business/neosSelectors/getOpenedRfqIds';
import { Switch } from '@/neos/components/share/switch/Switch';

interface RfqInternalProps {
  rfqId: string;
}

export function RfqInternalSwitcher({ rfqId }: RfqInternalProps) {
  const dispatch = useAppDispatch();
  const { internal, status } = useAppSelector(state => selectors.getRfqData(state, rfqId));

  const hasRfqVersions = useAppSelector(state => getIsRfqHasEverBeenSaved(rfqId, state, selectors));
  const disableConditions = status !== 'NEW' || hasRfqVersions;

  return (
    <div className="d-flex fw-bold align-content-center">
      <label className="align-self-center me-2">Internal</label>
      <Switch
        data-e2e="rfq-internal-switch"
        checked={!!internal}
        onChange={() => {
          dispatch(thunks.neos.createChangeIsRfqInternalThunk(rfqId, internal));
        }}
        disabled={disableConditions}
      />
    </div>
  );
}
