import type { Thunk } from '@/bootstrap/thunks';
import { DefaultValueForOTCLotSize } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/lotSizeMarketCell/util/getStrategyDefinitionSpecificProduct';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { FullUnderlyingInfo, OptionInfo } from '../../../../neosModel';
import {
  type Future,
  isDividendFutureProduct,
  isFutureLikeProduct,
  isFutureOptionProduct,
  isFutureProduct,
  isListedProduct,
  isOptionProduct,
  type Listed,
  type Option,
} from '../legModel';
import type { Action } from '@/bootstrap/actions.ts';

export function createUpdateProductLotSizeMarketThunk(
  strategyId: string,
  legId: string,
  lotSizeMarketId: string | undefined,
  dispatchableAction?: Action,
): Thunk {
  return function updateProductLotSizeMarketThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const state = getState();
    const { legIds, strategyType } = selectors.getStrategyData(state, strategyId);

    const { sameProductLotSize } = selectors.getStrategyDefinition(
      state.referenceData,
      strategyType,
    );

    const actions: Action[] = (sameProductLotSize ? legIds : [legId])
      .map(legId => {
        const productId = selectors.getLegData(state, legId).productId;
        const product = selectors.getProduct(state, productId);
        if (
          !isFutureLikeProduct(product) &&
          !isOptionProduct(product) &&
          !isFutureOptionProduct(product)
        ) {
          throw Error(`Lot size / Market modification not handled for ${product.subFamily}`);
        }

        if (!product.underlyingId) {
          throw Error(`Lot size / Market modification not handled when underlying is not selected`);
        }

        const underlyingInfo = selectors.getFullUnderlyingInfo(state, product.underlyingId);

        if (!underlyingInfo) {
          throw Error(`Underlying Info not found for ${product.underlyingId}`);
        }

        if (isOptionProduct(product)) {
          const allStrategyProducts = selectors.getStrategyProducts(state, strategyId, selectors);
          const isSomeProductsFLexOptions = allStrategyProducts.some(
            product => isOptionProduct(product) && product.flex === 'FLEX',
          );
          const marketOptionField: MarketOptionField = isSomeProductsFLexOptions
            ? 'marketLotSizesFlex'
            : 'marketLotSizes';

          return productCrudActions.update(
            productId,
            getOptionProductPatch({
              underlyingInfo,
              lotSizeMarketId,
              isListed: isListedProduct(product),
              marketOptionField,
            }),
          );
        }

        if (!isListedProduct(product)) {
          return;
        }

        const patch = (
          isFutureProduct(product)
            ? getFutureProductPatch
            : isDividendFutureProduct(product)
              ? getDividendFutureProductPatch
              : getTotalReturnFutureProductPatch
        )(underlyingInfo, lotSizeMarketId);

        return productCrudActions.update(productId, patch);
      })
      .filter(isDefined);

    if (!actions.length) {
      return;
    }
    if (dispatchableAction) {
      actions.push(dispatchableAction);
    }

    dispatch(actions);
  };
}

function getFutureProductPatch(
  underlyingInfo: FullUnderlyingInfo,
  lotSizeMarketId: string | undefined,
): Pick<Listed<Future>, 'lotSize' | 'marketExchangeId' | 'marketMicCode' | 'pointValue'> {
  return getFutureLikeProductPatch(underlyingInfo, lotSizeMarketId, 'future');
}

function getDividendFutureProductPatch(
  underlyingInfo: FullUnderlyingInfo,
  lotSizeMarketId: string | undefined,
): Pick<Listed<Future>, 'lotSize' | 'marketExchangeId' | 'marketMicCode' | 'pointValue'> {
  return getFutureLikeProductPatch(underlyingInfo, lotSizeMarketId, 'dividendFuture');
}

function getTotalReturnFutureProductPatch(
  underlyingInfo: FullUnderlyingInfo,
  lotSizeMarketId: string | undefined,
): Pick<Listed<Future>, 'lotSize' | 'marketExchangeId' | 'marketMicCode' | 'pointValue'> {
  return getFutureLikeProductPatch(underlyingInfo, lotSizeMarketId, 'totalReturnFuture');
}

function getFutureLikeProductPatch(
  underlyingInfo: FullUnderlyingInfo,
  lotSizeMarketId: string | undefined,
  marketLotSizesProp: keyof Pick<
    FullUnderlyingInfo,
    'future' | 'dividendFuture' | 'totalReturnFuture'
  >,
): Pick<Listed<Future>, 'lotSize' | 'marketExchangeId' | 'marketMicCode' | 'pointValue'> {
  if (!lotSizeMarketId) {
    return {
      marketExchangeId: undefined,
      lotSize: undefined,
      marketMicCode: undefined,
      pointValue: undefined,
    };
  }
  const marketLotSizePointValue =
    underlyingInfo[marketLotSizesProp]?.marketLotSizes[lotSizeMarketId];
  if (!marketLotSizePointValue) {
    throw Error(
      `${lotSizeMarketId} does not match any of ${underlyingInfo.bloombergCode} market ${
        marketLotSizesProp === 'dividendFuture' ? 'dividend ' : ''
      }future info`,
    );
  }
  const { market, lotSize, pointValue } = marketLotSizePointValue;
  return {
    marketExchangeId: market.galaxyCode,
    marketMicCode: market.micCode,
    lotSize,
    pointValue,
  };
}

type MarketOptionField = keyof Pick<OptionInfo, 'marketLotSizes' | 'marketLotSizesFlex'>;

function getOptionProductPatch({
  underlyingInfo,
  lotSizeMarketId,
  isListed,
  marketOptionField,
}: {
  underlyingInfo: FullUnderlyingInfo;
  lotSizeMarketId: string | undefined;
  isListed: boolean;
  marketOptionField: MarketOptionField;
}):
  | Pick<Listed<Option>, 'lotSize' | 'marketExchangeId' | 'marketMicCode'>
  | Pick<Listed<Option>, 'lotSize'> {
  if (!lotSizeMarketId) {
    return { marketExchangeId: undefined, marketMicCode: undefined, lotSize: undefined };
  }
  if (lotSizeMarketId === (DefaultValueForOTCLotSize.ID as string)) {
    return { lotSize: 1 };
  }
  const marketLotSize = underlyingInfo.option[marketOptionField][lotSizeMarketId];

  if (!marketLotSize) {
    throw Error(
      `${lotSizeMarketId} does not match any of ${underlyingInfo.bloombergCode} market option info`,
    );
  }
  const { market, lotSize } = marketLotSize;
  if (!isListed) {
    return { lotSize };
  }
  return { marketExchangeId: market.galaxyCode, marketMicCode: market.micCode, lotSize };
}
