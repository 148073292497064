import type { Quote, RecomputeScope } from '@/neos/business/rfq/models';
import { quoteActionCreators } from '../quotes/quoteActionCreator';
import { createActionWithRecompute } from './actionWithRecompute';
import type { Action } from '@/bootstrap/actions.ts';

export function createRfqQuoteChangedAction(
  rfqId: string,
  scope: RecomputeScope,
  quotePatch: Partial<Quote>,
): Action[] {
  return createActionWithRecompute(rfqId, scope, [
    quoteActionCreators.quoteCrudActions.update(rfqId, quotePatch),
  ]);
}
