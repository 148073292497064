import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  StrikeCells as Component,
  type StrikeCellsDispatchProps,
  type StrikeCellsMapStateProps,
  type StrikeCellsOwnProps,
} from './StrikeCells';
import { getStrikeCellsModel } from './getStrikeCellsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const StrikeCells = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: StrikeCellsOwnProps,
): StrikeCellsMapStateProps {
  return { model: getStrikeCellsModel(state, rfqId, strategyId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: StrikeCellsOwnProps,
): StrikeCellsDispatchProps {
  return {
    onStrikeChanged(productId: string, strike: number | undefined) {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Select strike', strike?.toString()),
        neosThunks.createUpdateProductAndResetDependenciesThunk(
          rfqId,
          strategyId,
          productId,
          'strike',
          strike,
        ),
      );
    },
  };
}
