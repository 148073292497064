import { thunks } from '@/bootstrap/thunks.ts';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { ExportButton } from '@/neos/components/share/importExportClipboard/ExportButton.tsx';
import { selectors } from '@/bootstrap/selectors';

type ScheduleExportProps = {
  strategyId: string;
};

export const CLSScheduleExport = ({ strategyId }: ScheduleExportProps) => {
  const dispatch = useAppDispatch();
  const rateData = useAppSelector(state =>
    selectors.selectScheduleRateLegDates(state, strategyId, selectors),
  );

  const isButtonDisabled = !rateData?.length;
  const handleExport = () => {
    dispatch(thunks.neos.createClsExportScheduleThunk(rateData));
  };

  return (
    <ExportButton
      handleExport={handleExport}
      isDisabled={isButtonDisabled}
      dataE2e="cls-schedule-export"
      dataTestId="cls-schedule-export"
    />
  );
};
