import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import {
  type EquityFinanceType,
  equityFinanceTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { getEquityFinanceModel } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ProductDetailsBloc/getEquityFinanceModel.ts';

export function EquityFinanceTypeComponent() {
  const { strategyId, rfqId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();

  const { equityFinance, isDisplayed } = useAppSelector(state =>
    getEquityFinanceModel(state, rfqId, strategyId, selectors),
  );

  const onEquityFinanceTypeChange = (newEquityFinance: EquityFinanceType | undefined) => {
    dispatch(
      actionCreators.neos.featureCrudActions.upsert(
        {
          strategyId,
          type: 'EQUITY_FINANCE',
        },
        {
          type: 'EQUITY_FINANCE',
          strategyId,
          equityFinance: newEquityFinance,
        },
      ),
    );
  };

  if (!isDisplayed) {
    return null;
  }

  return (
    <ElsBlocField
      label="Equity Finance Type"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          onChange={onEquityFinanceTypeChange}
          data-e2e="equity-finance-type"
          options={equityFinanceTypes}
          value={equityFinance}
          addEmptyOption
        />
      )}
    />
  );
}
