import type { Selectors } from '@/bootstrap/selectors';
import type { OnyxTransaction } from '@/neos/business/neosOnyxModel';
import type { ToOnyxMappers } from '.';
import type { AppState } from '@/bootstrap/store.ts';

export function mapToOnyxTransaction(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxTransaction {
  return {
    rfq: mappers.mapToOnyxRfqWithLatestLocalVersion(state, rfqId, selectors, mappers),
    bookingSteps: state.bookingSteps[rfqId],
    preConfirmationSteps: mappers.mapToOnyxPreConfirmationSteps(state, rfqId, selectors),
    pdcs: selectors.getNeosPredealChecks(state, rfqId, selectors),
    workflow: undefined,
    orders: mappers.mapToOnyxOrders(state, rfqId, selectors, mappers),
    allocationInstructions: undefined,
    preConfirmationMail: undefined,
  };
}
