import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import {
  type LinearInterpolation,
  LinearInterpolationValues,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { FormSelect } from 'react-bootstrap';
import { isElsProduct } from '../../../../../../../business/neosModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function LinearInterpolationComponent() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, linearInterpolation } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;
  const onLinearInterpolationChange = (newLinearInterpolation: LinearInterpolation) => {
    dispatch(updateProduct(uuid, { linearInterpolation: newLinearInterpolation }));
  };

  return (
    <ElsBlocField
      label="Linear Interpolation"
      renderInput={readOnly => (
        <FormSelect
          readOnly={readOnly}
          value={linearInterpolation ?? ''}
          onChange={event =>
            onLinearInterpolationChange((event.target.value as LinearInterpolation) || undefined)
          }
          data-e2e="other-section-linear-interpolation"
        >
          <option value=""></option>
          {Object.entries(LinearInterpolationValues).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </FormSelect>
      )}
    />
  );
}
