import type { FromOnyxMappers } from '@/neos/business/mappers';
import type { OnyxRfq, OnyxStrategy } from '@/neos/business/rfq/rfqOnyxModel';
import type { CrudInsertAction } from '@/util/crudUtils';
import { flatMap } from 'lodash';
import type { PriceUnitType } from '../../neosModel';
import { quoteDomain } from './quoteDomain';
import type { ParentId, ParentIdType, Quote } from './quoteModel';
import type { Action } from '@/bootstrap/actions.ts';

export const quoteActionCreators = {
  quoteCrudActions: quoteDomain.actions,
  createDefaultQuotesAddedAction,
  createQuotesLoadedAction,
};

export function buildParameterWithParentIdAsQuoteId(
  parentIdType: ParentIdType,
  id: string,
  unit: string | undefined,
  type: PriceUnitType | undefined,
): Parameter {
  return {
    parentId: { type: parentIdType, id },
    quoteId: id,
    unit,
    type,
  };
}

interface Parameter {
  parentId: ParentId;
  quoteId: string;
  unit: string | undefined;
  type: PriceUnitType | undefined;
}

function createDefaultQuotesAddedAction(parameters: Parameter[]): Action[] {
  return parameters.map(createSingleDefaultQuoteAddedAction);
}

function createSingleDefaultQuoteAddedAction({
  parentId,
  quoteId,
  unit,
  type,
}: Parameter): CrudInsertAction<Quote> {
  return quoteDomain.actions.insert(quoteId, {
    parentId,
    deltaUnit: '%',
    unit,
    salesBidType: type,
    salesAskType: type,
    traderAskType: type,
    traderBidType: type,
    preTradeMidMarketMarkPriceType: type,
    initialTraderPrice: undefined,
    initialSalesPrice: undefined,
  });
}

function createQuotesLoadedAction(rfq: OnyxRfq, { mapFromOnyxQuote }: FromOnyxMappers): Action[] {
  function buildQuoteUpsertActionsForStrategiesAndLegs(strategies: OnyxStrategy[]) {
    return [
      ...flatMap(strategies, strategy => [
        quoteDomain.actions.upsert(
          strategy.uuid,
          mapFromOnyxQuote({ type: 'Strategy', id: strategy.uuid }, strategy.quote) || {},
        ),
        ...strategy.legs.map(leg =>
          quoteDomain.actions.upsert(
            leg.uuid,
            mapFromOnyxQuote({ type: 'Leg', id: leg.uuid }, leg.quote) || {},
          ),
        ),
      ]),
    ];
  }

  return [
    quoteDomain.actions.upsert(
      rfq.uuid,
      mapFromOnyxQuote({ type: 'Rfq', id: rfq.uuid }, rfq.quote) || {},
    ),
    ...buildQuoteUpsertActionsForStrategiesAndLegs(rfq.strategies),
    ...buildQuoteUpsertActionsForStrategiesAndLegs(rfq.deltas || []),
  ];
}
