import type { Selectors } from '@/bootstrap/selectors';
import type { Thunk } from '@/bootstrap/thunks';
import { isWithinInterval } from '@/util/date/dateFormatHelper';
import type { NeosActionCreators } from '../../neosActionCreators';
import type { OnyxRfq, Workflow } from '../../neosOnyxModel';
import type { NeosBlotterRfq } from '../blotterModel';
import { hasCurrentTypeaheadTags, type Tags } from './helpers/blotterThunksHelpers';
import type { AppState } from '@/bootstrap/store.ts';
import type { Action } from '@/bootstrap/actions.ts';

export function createAddRfqFromNotificationToBlotterThunk(notificationRfq: OnyxRfq): Thunk {
  return function addRfqFromNotificationToBlotterThunk(
    dispatch,
    getState,
    { actionCreators: { neos }, selectors },
  ) {
    const state = getState();

    if (isOutdatedNotificationRfq(state, notificationRfq, selectors)) {
      return;
    }

    const rfqFromState = selectors.getBlotterRfqById(state.blotter, notificationRfq.uuid);

    const externalMailStatus = rfqFromState?.externalMailStatus ?? 'NOT_SENT';
    const internalMailStatus = rfqFromState?.internalMailStatus ?? 'NOT_SENT';

    const notificationRfqWithPreconfStatus: NeosBlotterRfq = {
      ...notificationRfq,
      externalMailStatus,
      internalMailStatus,
    };

    if (
      isNotificationRfqLatestVersionAndRfqExistsInBlotter(
        state,
        notificationRfqWithPreconfStatus,
        selectors,
      )
    ) {
      dispatch(
        neos.updateBlotterRfq(notificationRfqWithPreconfStatus),
        ...addRfqIdToPendingBlotterRfqNotifications(
          state,
          notificationRfqWithPreconfStatus,
          selectors,
          neos,
        ),
      );
      return;
    }

    if (shouldAddNotificationRfqToBlotter(state, notificationRfqWithPreconfStatus, selectors)) {
      dispatch(
        neos.createBlotterRfqReceivedAction(notificationRfqWithPreconfStatus),
        ...addRfqIdToPendingBlotterRfqNotifications(
          state,
          notificationRfqWithPreconfStatus,
          selectors,
          neos,
        ),
      );
      return;
    }

    dispatch(
      neos.updateBlotterRfqFilteredOutNotificationLastDate(),
      ...addRfqIdToPendingBlotterRfqNotifications(
        state,
        notificationRfqWithPreconfStatus,
        selectors,
        neos,
      ),
    );
  };
}

function isNotificationRfqLatestVersionAndRfqExistsInBlotter(
  state: AppState,
  { uuid, version }: NeosBlotterRfq,
  { getBlotterRfqById }: Selectors,
) {
  const existingBlotterRfq = getBlotterRfqById(state.blotter, uuid);
  return existingBlotterRfq && version > existingBlotterRfq.version;
}

function isOutdatedNotificationRfq(
  state: AppState,
  { uuid, version }: OnyxRfq,
  { getBlotterRfqById }: Selectors,
) {
  const existingBlotterRfq = getBlotterRfqById(state.blotter, uuid);
  return existingBlotterRfq && version <= existingBlotterRfq.version;
}

function shouldAddNotificationRfqToBlotter(
  state: AppState,
  neosBlotterRfq: NeosBlotterRfq,
  selectors: Selectors,
): boolean {
  const { tradeDate, workflow } = neosBlotterRfq;
  const typeaheadValues = extractTypeaheadValuesFromRfq(neosBlotterRfq);
  return (
    isNotificationRfqBetweenDates(state, tradeDate, selectors) &&
    hasNotificationRfqCurrentWorkflowFilter(state, workflow, selectors) &&
    hasCurrentTypeaheadTags(state, typeaheadValues, selectors)
  );
}

function isNotificationRfqBetweenDates(
  state: AppState,
  tradeDate: string | undefined,
  { getBlotterFrom, getBlotterTo }: Selectors,
): boolean {
  const blotterFrom = getBlotterFrom(state.blotter);
  const blotterTo = getBlotterTo(state.blotter);
  return isWithinInterval(tradeDate ?? '', [blotterFrom, blotterTo]);
}

function hasNotificationRfqCurrentWorkflowFilter(
  state: AppState,
  workFlow: Workflow,
  { getBlotterWorkflows }: Selectors,
): boolean {
  const workflows = getBlotterWorkflows(state.blotter);
  return workflows.includes(workFlow);
}

function extractTypeaheadValuesFromRfq(onyxRfq: NeosBlotterRfq): Tags {
  const { lifecycle, strategies, hedges, salesCounterparty } = onyxRfq;
  const strategyTypes = strategies?.map(({ strategyType }) => strategyType) ?? [];
  const underlyings = hedges ? hedges.map(({ underlyingId }) => underlyingId) : [];
  const counterParty = salesCounterparty?.id.toString();

  return {
    status: lifecycle?.status,
    strategyTypes,
    counterPart: counterParty,
    underlyings,
  };
}

function addRfqIdToPendingBlotterRfqNotifications(
  state: AppState,
  blotterRfq: NeosBlotterRfq,
  selectors: Selectors,
  { createAddRfqIdToPendingBlotterRfqNotificationsAction }: NeosActionCreators,
): Action[] {
  const pendingRfqIds = selectors.getPendingRfqIds(state.ui.blotter);
  if (!pendingRfqIds.includes(blotterRfq.uuid)) {
    return [createAddRfqIdToPendingBlotterRfqNotificationsAction(blotterRfq.uuid)];
  }

  return [];
}
