import { useAppSelector } from '@/bootstrap/hooks';

import { type Selectors, selectors } from '@/bootstrap/selectors';
import { NumericInput } from '@/neos/components/share/numericInput';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { getProductSubFamilyByLegId } from './Maturity';
import type { AppState } from '@/bootstrap/store.ts';

export interface LegDescriptionComponentProps {
  rfqId: string;
  strategyId: string;
  legId: string;
}

export function AveragePrice({ rfqId, strategyId, legId }: LegDescriptionComponentProps) {
  const productSubFamily = useAppSelector(state =>
    getProductSubFamilyByLegId(state, legId, selectors),
  );

  const discriminator = useAppSelector(state => getLegDiscriminator(state, legId, selectors));

  const averagePrice = useAppSelector(state =>
    getOrderAveragePriceValue(state, rfqId, strategyId, legId, selectors),
  );

  const { negotiatedPrice } = useAppSelector(state =>
    getLegNegotiatedPrice(state, legId, selectors),
  );

  const priceValue = useAppSelector(state => getLegSalesPrice(state, legId, selectors));

  const isWarningDisplayed = useAppSelector(state =>
    getIsWarningDisplayed(state, { rfqId, strategyId, legId }, selectors),
  );

  if (productSubFamily === 'STOCK') {
    return null;
  }

  return (
    <div>
      {discriminator === 'LISTED' ? (
        <>
          <label className="mb-0 me-3">Av. Price</label>
          <SimpleNeosTooltip
            disable={!isWarningDisplayed}
            type="warning"
            id="negotiated-price-tooltip"
            message={`Negotiated price ${negotiatedPrice}`}
          >
            <NumericInput
              data-e2e="average-price"
              value={averagePrice?.value}
              unit={averagePrice?.unit}
              className={isWarningDisplayed ? 'field-warning' : ''}
              readOnly
              withMaximumNumberOfFloatingDigits={4}
            />
          </SimpleNeosTooltip>
        </>
      ) : (
        <>
          <label className="mb-0 me-3">Price</label>
          <NumericInput data-e2e="price" value={priceValue.value} unit={priceValue.unit} readOnly />
        </>
      )}
    </div>
  );
}

export function getLegSalesPrice(state: AppState, legId: string, selectors: Selectors) {
  const { clientWay, quoteId } = selectors.getLegData(state, legId);
  const quote = selectors.getQuote(state, quoteId);
  const value = clientWay === 'SELL' ? quote.salesBid : quote.salesAsk;
  return { value, unit: quote.unit };
}

export function getLegNegotiatedPrice(state: AppState, legId: string, selectors: Selectors) {
  const { clientWay, quoteId } = selectors.getLegData(state, legId);
  const quote = selectors.getQuote(state, quoteId);
  return clientWay === 'BUY'
    ? { negotiatedPrice: quote.salesAsk, type: quote.salesAskType }
    : { negotiatedPrice: quote.salesBid, type: quote.salesBidType };
}

export function getLegDiscriminator(state: AppState, legId: string, selectors: Selectors) {
  const { productId } = selectors.getLegData(state, legId);
  const { negotiationMode } = selectors.getProduct(state, productId);
  return negotiationMode;
}

export function getOrderAveragePriceValue(
  state: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
  selectors: Selectors,
) {
  const order = selectors.getOrderByLegIdAndCrossWay(
    state,
    { rfqId, strategyId, legId },
    selectors,
  );
  return order?.averagePrice;
}

export function getIsWarningDisplayed(
  state: AppState,
  {
    rfqId,
    strategyId,
    legId,
  }: {
    rfqId: string;
    strategyId: string;
    legId: string;
  },
  selectors: Selectors,
): boolean {
  const averagePrice = getOrderAveragePriceValue(state, rfqId, strategyId, legId, selectors);

  const { negotiatedPrice, type } = getLegNegotiatedPrice(state, legId, selectors);

  const order = selectors.getOrderByLegIdAndCrossWay(
    state,
    { rfqId, strategyId, legId },
    selectors,
  );

  const executions = order
    ? selectors.executionSelectors.selectObjects(state.execution, {
        orderId: order.uuid,
      })
    : [];

  return (
    averagePrice?.value !== undefined &&
    negotiatedPrice !== averagePrice?.value &&
    type === 'CCY' &&
    !!executions.length &&
    executions.some(({ lastPrice }) => lastPrice?.value !== undefined)
  );
}
