import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

type ElsClsObsoleteScheduleWarningProps = {
  rfqId: string;
  strategyId: string;
};

export function ElsClsObsoleteScheduleWarning(props: ElsClsObsoleteScheduleWarningProps) {
  const { rfqId, strategyId } = props;

  const dispatch = useAppDispatch();
  const isElsScheduleWarningDisplayed = useAppSelector(state =>
    selectors.getIsElsScheduleWarningDisplayed(state, rfqId, strategyId, selectors),
  );
  const isClsScheduleWarningDisplayed = useAppSelector(state =>
    selectors.getIsClsScheduleWarningDisplayed(state, rfqId, strategyId, selectors),
  );

  const isStrategyPrimary = useAppSelector(state =>
    selectors.isPrimaryStrategy(state, strategyId, selectors),
  );

  const isDisplayed =
    (isElsScheduleWarningDisplayed || isClsScheduleWarningDisplayed) && isStrategyPrimary;

  if (!isDisplayed) {
    return null;
  }

  function hideWarning() {
    dispatch(thunks.neos.createHideElsClsObsoleteScheduleWarningThunk(rfqId));
  }

  function onYes() {
    hideWarning();
    dispatch(
      neosActionCreators.createDefaultRfqRequestedAction(
        rfqId,
        new DefaultingOptions({
          overrideScopes: [DefaultingScope.SCHEDULE],
          enrichScopes: [DefaultingScope.UNDERLYING],
        }),
      ),
    );
  }

  function onNo() {
    hideWarning();
  }

  return (
    <div data-e2e="alert-re-generate-schedule">
      <div role="alert" className="alert alert-warning d-flex p-1">
        <span>Do you want to re-generate schedule ?</span>
        <div className="d-flex ms-2 gap-1">
          <button
            className="btn btn-sm btn-outline-primary-alt"
            data-e2e="neos-alert-leg-over-yes"
            onClick={onYes}
          >
            Yes
          </button>
          <button
            className="btn btn-sm btn-outline-primary-alt"
            data-e2e="neos-alert-leg-over-no"
            onClick={onNo}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}
