import { connect } from 'react-redux';
import {
  type PredealChecksScreenModel,
  type PredealChecksScreenOwnProps,
  PredealChecksWithStyle as PredealChecksScreenWithStyle,
} from './PredealChecksScreen';

import { selectors } from '@/bootstrap/selectors';
import { getPredealChecksScreenModel } from './getPredealChecksScreenModel';
import type { AppState } from '@/bootstrap/store.ts';

export const PredealChecksScreen = connect(mapStateToProps)(PredealChecksScreenWithStyle);

function mapStateToProps(
  state: AppState,
  { rfqId }: PredealChecksScreenOwnProps,
): PredealChecksScreenModel {
  return getPredealChecksScreenModel(state, rfqId, selectors);
}
