import { selectors } from '@/bootstrap/selectors';
import { commonSelectors } from '@/common/business/commonSelectors';
import { connect } from 'react-redux';
import { getStatusCellClass } from '../../../share/blotterGrid/getStatusCellClass';
import { getDisplayStatus } from '../../../share/status';
import { StatusComponent, type StatusMapStateToProps, type StatusOwnProps } from './Status';
import type { AppState } from '@/bootstrap/store.ts';

export const Status = connect(mapStateToProps)(StatusComponent);

function mapStateToProps(state: AppState, { rfqId }: StatusOwnProps): StatusMapStateToProps {
  const { status, subStatus } = selectors.getRfqData(state, rfqId);
  const isTrader = commonSelectors.isTrader(state);
  const statusClasses = getStatusCellClass(isTrader)({ data: { status, subStatus } });
  return {
    statusLabel: getDisplayStatus(status, subStatus, selectors),
    statusClassName: Array.isArray(statusClasses) ? statusClasses.join(' ') : statusClasses,
  };
}
