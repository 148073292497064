import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { neosThunks } from '@/neos/business/thunks';
import { getValueDateCellModel } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/valueDateCells/getValueDateCellModel.ts';
import { FlowDatePickerWithOffset } from '@/neos/components/share/datePicker/FlowDatePickerWithOffset.tsx';

export interface ValueDateCellProps {
  rfqId: string;
  strategyId: string;
}

export const ValueDateCell = ({ rfqId, strategyId }: ValueDateCellProps) => {
  const dispatch = useAppDispatch();

  const { valueDate, isReadonly } = useAppSelector(state =>
    getValueDateCellModel(state, rfqId, strategyId, selectors),
  );

  function onOffsetChange(offset: number) {
    dispatch(neosThunks.createUpdateValueDateOffsetThunk(rfqId, strategyId, offset));
  }

  function onDateChange(date: string) {
    dispatch(neosThunks.createUpdateValueDateThunk(rfqId, strategyId, date));
  }

  if (!valueDate) {
    return null;
  }

  return (
    <section>
      <FlowDatePickerWithOffset
        className="rfq-header-value-date"
        readOnly={isReadonly}
        data-e2e="value-date"
        date={valueDate.value ?? ''}
        onChange={onDateChange}
        onOffsetChange={onOffsetChange}
        currentOffset={valueDate.offset}
        hideCalendarIcon
      />
    </section>
  );
};
