import type { OnyxRfq } from '../neosOnyxModel';
import type { RequestSimilarActivitiesAction } from './similarActivitiesActions';
import { similarActivitiesDomain } from './similarActivitiesDomain';
import type { Action } from '@/bootstrap/actions.ts';

const similarActivitiesCrudActions = similarActivitiesDomain.actions;

export const similarActivitiesActionCreators = {
  similarActivitiesCrudActions,
  createRequestSimilarActivitiesAction,
  createSimilarActivitiesReceivedAction,
};

function createRequestSimilarActivitiesAction(rfqId: string): RequestSimilarActivitiesAction {
  return {
    type: 'REQUEST_SIMILAR_ACTIVITIES',
    rfqId,
  };
}

function createSimilarActivitiesReceivedAction(
  rfqId: string,
  similarActivities: OnyxRfq[],
): Action[] {
  return [similarActivitiesCrudActions.upsert(rfqId, similarActivities)];
}
