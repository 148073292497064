import type { Selectors } from '@/bootstrap/selectors';
import { constantToSentence } from '@/util/string/stringUtils';
import type { StrategyScope } from '../../../../../neos/business/neosModel';
import type {
  ElsType,
  OnyxBarrier,
  OnyxBarriers,
  OnyxRfq,
} from '../../../../../neos/business/neosOnyxModel';
import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { AppState } from '@/bootstrap/store.ts';

interface StrategyLabel {
  strategyTypeName: string;
  elsType: ElsType | undefined;
  barriers: OnyxBarrier[] | null | undefined;
}

export function getAllStrategyTypes(
  appState: AppState,
  { strategies, deltas = [] }: OnyxRfq,
  selectors: Selectors,
): string {
  const strategyLabelsData = strategies.map(({ strategyType, legs }) => {
    const elsType = legs?.[0].product?.elsType;
    const barrierExtraFeature = legs?.[0].product?.extraFeatures?.find(
      hasDiscriminator<OnyxBarriers>('BARRIER_CONTAINER'),
    );
    const strategyTypeName = appState.referenceData.strategyDefinitions[strategyType]
      ? selectors.getStrategyDefinition(appState.referenceData, strategyType).name
      : constantToSentence(strategyType);
    return {
      strategyTypeName,
      elsType,
      barriers: barrierExtraFeature?.barriers,
    };
  });

  const deltaStrategyTypeLabels = deltas
    .filter(({ discriminator }) => !isDeltaAdjusted(discriminator))
    .map(
      s =>
        `${selectors.getStrategyDefinition(appState.referenceData, s.strategyType).name} (Hedge)`,
    );

  const twoDArray = strategyLabelsData
    .map(s => getStrategyTypeBlotterLabel(s))
    .concat(deltaStrategyTypeLabels);

  return twoDArray.join(', ');
}

function isDeltaAdjusted(deltaDiscriminator: StrategyScope) {
  return deltaDiscriminator === 'DELTA_ADJUSTED';
}

function getBarriersLabel(barriers: OnyxBarrier[]) {
  if (barriers.length === 1) {
    return getBarrierLabel(barriers[0]);
  }

  return barriers
    .map(onyxBarrier => {
      return `[${getBarrierLabel(onyxBarrier)}]`;
    })
    .join(' ');
}

function getBarrierLabel(onyxBarrier: OnyxBarrier) {
  const type = onyxBarrier.type === 'KNOCK_IN' ? 'IN' : 'OUT';
  return `${onyxBarrier.activationType} & ${type}`;
}

export function getStrategyTypeBlotterLabel({
  strategyTypeName,
  elsType,
  barriers,
}: StrategyLabel): string {
  if (elsType) {
    return `${strategyTypeName} ${elsType}`;
  }
  if (barriers) {
    return `${strategyTypeName} ${getBarriersLabel(barriers)}`;
  }

  return strategyTypeName;
}
