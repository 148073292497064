import { memoize } from 'proxy-memoize';
import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import { TimersComponent, type TimersOwnProps } from './Timers';
import { getTimersModel } from './getTimersModel';
import { useAppSelector } from '@/bootstrap/hooks.ts';

export const Timers = ({ rfqId }: TimersOwnProps) => {
  const stateProps = useAppSelector(
    memoize(state => getTimersModel(state, rfqId, selectors, services)),
  );

  return <TimersComponent rfqId={rfqId} {...stateProps} />;
};
