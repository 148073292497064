import { selectors } from '@/bootstrap/selectors';
import { connect } from 'react-redux';
import { Cls as Component, type OwnProps, type StateProps } from './Cls';
import { getClsModel } from './getClsModel';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(appState: AppState, { strategyId }: OwnProps): StateProps {
  return getClsModel(appState, strategyId, selectors);
}

export const Cls = connect(mapStateToProps)(Component);
