import type { Selectors } from '@/bootstrap/selectors';
import type { BookingStepStatus } from '@/neos/business/neosOnyxModel';
import {
  type UiBookingStatus,
  getUiBookingStatus,
} from '../../../../../share/bookingStatusStyle/bookingStatusStyle';
import type { AppState } from '@/bootstrap/store.ts';

type BookingStepStatusLabelModel = UiBookingStatus;

export function getBookingStepStatusLabelModel(
  state: AppState,
  { rfqId, strategyId }: { rfqId: string; strategyId: string },
  selectors: Selectors,
): BookingStepStatusLabelModel {
  const isOtcStrategy = selectors.isOtcStrategy(state, strategyId, selectors);
  const bookingStep = selectors.getBookingStepByStrategyId(state, rfqId, strategyId);

  if (!isOtcStrategy) {
    const tooltip = bookingStep?.status === 'FAILED' ? bookingStep?.message : undefined;

    return getUiBookingStatus(bookingStep?.status, tooltip);
  }

  const otcAggregatedBookingStepStatus = getOtcAggregatedLabelBookingStepStatus(
    state,
    rfqId,
    strategyId,
    selectors,
  );

  return getUiBookingStatus(otcAggregatedBookingStepStatus ?? bookingStep?.status);
}

function getOtcAggregatedLabelBookingStepStatus(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): BookingStepStatus | undefined {
  const hasAnyFailedXOneSplitBookingInfos = selectors.hasAnyFailedXOneSplitBookingSteps(
    state,
    rfqId,
    strategyId,
  );
  if (hasAnyFailedXOneSplitBookingInfos) {
    return 'FAILED';
  }

  const isOneLegFailed = selectors.hasAnyFailedXOneLegBookingStep(state, rfqId, strategyId);
  if (isOneLegFailed) {
    return 'POSITION_FAILED';
  }

  const areAllLegsBooked = selectors.areAllXOneLegBookingStepsBooked(state, rfqId, strategyId);

  if (areAllLegsBooked) {
    const areAllAllocationsBooked = selectors.areAllXOneSplitBookingStepsBooked(
      state,
      rfqId,
      strategyId,
    );
    if (areAllAllocationsBooked) {
      return 'BOOKED';
    }

    return 'POSITION_BOOKED';
  }
}
