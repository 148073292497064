import { useContext } from 'react';
import { NeosBlurTextarea } from '@/neos/components/share/blurComponent/NeosBlurTextarea.tsx';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures.tsx';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField.tsx';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { actionCreators } from '@/bootstrap/actions.ts';

export function ElsDividendPreconfComment() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const dividendFeature = useAppSelector(state =>
    selectors.getFeature(state.featureState, {
      strategyId,
      type: 'DIVIDEND_COMPONENT',
    }),
  );

  const onCommentChanged = (newComment: string) => {
    dispatch(
      actionCreators.neos.featureCrudActions.upsert(
        {
          strategyId,
          type: 'DIVIDEND_COMPONENT',
        },
        {
          type: 'DIVIDEND_COMPONENT',
          strategyId,
          dividendPreconfComment: newComment,
        },
      ),
    );
  };

  return (
    <ElsBlocField
      className="flex-grow-1"
      label="Preconf Comment"
      renderInput={readOnly => (
        <NeosBlurTextarea
          className="form-control"
          data-e2e="neos-els-dividend-preconf-comment"
          value={dividendFeature?.dividendPreconfComment || ''}
          rows={3}
          onChange={event => onCommentChanged(event.target.value)}
          disabled={readOnly}
        />
      )}
    />
  );
}
