import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { CrudActionCreators } from '@/util/crudUtils';
import { RfqRecomputeScope, type StrategyData } from '../models';
import type { Action } from '@/bootstrap/actions.ts';

export function createRfqDeleteStrategyThunk(rfqId: string, strategyId: string): Thunk {
  return function rfqDeleteStrategyThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: {
          createRfqDeleteStrategyAndDependenciesThunk,
          createRfqAddStrategyWhenOnlyOneExistThunk,
          createRfqDeleteObsoleteUnderlyingDependenciesThunk,
          createRequestRfqDeltaHedgingAndFairPricesThunk,
        },
      },
      actionCreators: {
        neos: {
          createRecomputeRfqRequestedAction,
          createPreviousStrategySelectedAction,
          strategyDataCrudActions,
          rfqDataCrudActions,
        },
      },
    },
  ) {
    dispatch(createRfqAddStrategyWhenOnlyOneExistThunk(rfqId));

    const state = getState();
    const { strategyIds } = selectors.getRfqData(state, rfqId);

    const selectedStrategyId = selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId);

    const actions: Dispatchable[] = [];
    if (selectedStrategyId === strategyId) {
      actions.push(createPreviousStrategySelectedAction(rfqId));
    }
    const { isMasterStrategy } = selectors.getStrategyData(state, strategyId);
    if (isMasterStrategy) {
      actions.push(
        updateMasterStrategyToFirstStrategy(strategyDataCrudActions, strategyIds, strategyId),
      );
    }

    actions.push(
      rfqDataCrudActions.arrayRemove(rfqId, { property: 'strategyIds', value: strategyId }),
    );

    dispatch(
      actions,
      createRfqDeleteStrategyAndDependenciesThunk(strategyId),
      createRfqDeleteObsoleteUnderlyingDependenciesThunk(rfqId),
      createRecomputeRfqRequestedAction(rfqId, RfqRecomputeScope.CHANGE_PRICE, {
        success: { dispatchables: [createRequestRfqDeltaHedgingAndFairPricesThunk(rfqId)] },
      }),
    );
  };
}

function getAllOtherStrategyIds(strategyIds: string[], strategyIdToRemove: string): string[] {
  return strategyIds.filter(stratId => stratId !== strategyIdToRemove);
}

function updateMasterStrategyToFirstStrategy(
  strategyDataCrudActions: CrudActionCreators<StrategyData>,
  strategyIds: string[],
  strategyId: string,
): Action {
  const allOtherStrategyIds = getAllOtherStrategyIds(strategyIds, strategyId);
  const firstStrategyId = allOtherStrategyIds[0];
  return strategyDataCrudActions.update(firstStrategyId, { isMasterStrategy: true });
}
