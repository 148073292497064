import type { Selectors } from '@/bootstrap/selectors.ts';
import { services, type Services } from '@/bootstrap/services.ts';
import {
  isSingleUnderlyingDerivativeProduct,
  isVSwapProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { getVolStrikeValue } from '@/neos/components/rfq/strategies/strategy/compositionLegs/quoteByClientWayHelpers.ts';
import { getSizeUnit } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/sizeCells/getSizeUnit.ts';
import type { AppState } from '@/bootstrap/store.ts';

export interface CompositionLeg {
  legId: string;
  productId: string;
  underlyingId: string | undefined;
  bloombergCode: string | undefined;
  weight: number | undefined;
  size: number | undefined;
  sizeUnit: string | undefined;
  spot?: number;
  spotDisabled: boolean;
  expectedN: number | undefined;
  volStrike: number | undefined;
  quoteId: string | undefined;
  delta: number | undefined;
  isMaster: boolean;
}

export interface GetCompositionLegsModel {
  title: string;
  hasCompoLeg: boolean;
  allCompositionLegs: CompositionLeg[];
}

export function getCompositionLegsModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): GetCompositionLegsModel {
  const strategyData = selectors.getStrategyData(state, strategyId);

  const hasCompoLeg = selectors.hasACompositionLeg(state, strategyId, selectors);

  const allCompositionLegs = strategyData.legIds.map(legId =>
    getCompositionLeg(state, rfqId, strategyId, legId, selectors, services),
  );
  const title = strategyData.underlyingId
    ? `Top ${strategyData.top} ${
        selectors.getUnderlyingInfo(state, strategyData.underlyingId)?.bloombergCode
      }`
    : 'Custom';

  return {
    hasCompoLeg,
    allCompositionLegs,
    title,
  };
}

function getCompositionLeg(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
  selectors: Selectors,
  services: Services,
): CompositionLeg {
  const { clientWay } = selectors.getRfqData(appState, rfqId);
  const leg = selectors.getLegData(appState, legId);
  const product = selectors.getProduct(appState, leg.productId);
  const quote = selectors.getQuote(appState, leg.quoteId);
  const underlyingId = isSingleUnderlyingDerivativeProduct(product)
    ? product.underlyingId
    : undefined;
  const reference = underlyingId
    ? selectors.getReference(appState, { rfqId, underlyingId })
    : undefined;
  const bloombergCode = underlyingId
    ? selectors.getBloombergCode(appState, underlyingId, selectors)
    : undefined;

  const isReadOnlyRfq = selectors.isReadOnlyRfq(appState, rfqId);
  const uiSizeType = selectors.getDisplayNegotiatedSize(appState.ui, strategyId);
  const { notionalUnit = '', localNotionalUnit = '' } = leg;

  return {
    legId: leg.uuid,
    productId: leg.productId,
    underlyingId,
    bloombergCode,
    weight: services.applyMultiplierWithPrecision(leg.weight, 100, 6),
    size: leg.notional,
    spot: reference?.refSpot,
    spotDisabled: isReadOnlyRfq || !reference,
    expectedN: isVSwapProduct(product) ? product.expectedN : undefined,
    volStrike: getVolStrikeValue(clientWay, quote),
    quoteId: leg.quoteId,
    delta: quote.delta,
    sizeUnit: getSizeUnit(uiSizeType, { notionalUnit, localNotionalUnit }),
    isMaster: leg.isMaster,
  };
}
