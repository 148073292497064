import type { Selectors } from '@/bootstrap/selectors';
import { distinct } from '@/util/array/arrayUtils';
import { flatMap } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';

export function getRfqAndDeltaStrategiesUnderlyingIds(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): string[] {
  const { strategyIds, deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  const underlyingIds = [
    ...getStrategiesUnderlyingIds(state, strategyIds, selectors),
    ...getStrategiesUnderlyingIds(state, deltaHedgingStrategyIds, selectors),
  ];
  return distinct(underlyingIds);
}

function getStrategiesUnderlyingIds(
  state: AppState,
  strategyIds: string[],
  selectors: Selectors,
): string[] {
  return flatMap(strategyIds, strategyId =>
    selectors.getAllDistinctUnderlyingAndRefIdsOfStrategy(state, strategyId, selectors),
  );
}
