import type { Selectors } from '@/bootstrap/selectors';
import { flatMap } from 'lodash';
import type { Reference } from '../../../../neos/business/neosModel';
import { isCustomUnderlyingProduct } from '../models';
import type { AppState } from '@/bootstrap/store.ts';

export function getNotAggregatedReferences(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Reference[] {
  const rfqReferences = selectors.getReferences(state, { rfqId });
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  const notAggregatedUnderlyingIds = flatMap(strategyIds, strategyId => {
    const { legsData } = selectors.getStrategyLegsOrIndexLegData(state, strategyId, selectors);

    const nonCustomLegs = legsData.filter(legData => {
      const product = selectors.getProduct(state, legData.productId);
      return !isCustomUnderlyingProduct(product);
    });

    return nonCustomLegs.map(legData =>
      selectors.getUnderlyingOrRefIdOfLeg(state, legData.uuid, selectors),
    );
  });

  return rfqReferences.filter(({ underlyingId }) =>
    notAggregatedUnderlyingIds.includes(underlyingId),
  );
}
