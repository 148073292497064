import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import { toDisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { flatMap } from 'lodash';
import type { StrategyType } from '../../../../../neos/business/neosModel';
import type { FairPrice, ParentId } from '../../models';
import type { Product } from '../strategyModel';
import type { Action } from '@/bootstrap/actions.ts';

export function createStrategyProceedToLegsToStrategiesThunk(
  strategyToSplitId: string,
  strategiesToAddIds: string[],
): Thunk {
  return function strategyProceedToLegsToStrategiesThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: {
          strategyDataCrudActions,
          createLegLegsToStrategiesAction,
          quoteCrudActions,
          fairPriceCrudActions,
          legDataCrudActions,
          featureCrudActions,
          strategyUiCrudActions,
        },
      },
      selectors,
    },
  ) {
    const state = getState();
    const {
      getStrategyData,
      getLegData,
      getProduct,
      getQuote,
      getFairPrice,
      getStrategyTypeDefaultSizeType,
    } = selectors;
    const {
      legIds,
      strategyType,
      weight,
      quoteId: strategyToSplitQuoteId,
      fairPriceId: strategyToSplitFairPriceId,
      ...strategyDataRest
    } = getStrategyData(state, strategyToSplitId);
    const resultStrategiesIds = [strategyToSplitId, ...strategiesToAddIds];

    function updateQuotesAndFairPricesForLeg(legId: string, index: number): Action[] {
      const {
        weight: legWeight,
        productId,
        quoteId: legQuoteId,
        fairPriceId,
      } = getLegData(state, legId);
      const legQuote = getQuote(state, legQuoteId);
      const fairPrice: Omit<FairPrice, 'parentId'> = fairPriceId
        ? getFairPrice(state, fairPriceId)
        : { midPriceType: undefined };

      const product = getProduct(state, productId);

      // TODO handle case when product type is undefined
      const newStrategyType = getNewStrategyType(product);
      const currentStrategyId = resultStrategiesIds[index];

      function createUpdateStrategyDataQuoteAndFairPricesActionsForStrategyToSplit() {
        const updateStrategyDataActions = [
          strategyDataCrudActions.update(strategyToSplitId, {
            legIds: [legId],
            weight: weight * legWeight,
            strategyType: newStrategyType,
          }),
        ];

        const parentId: ParentId = { type: 'Strategy', id: strategyToSplitId };

        const updateStrategyQuoteActions = [
          quoteCrudActions.upsert(strategyToSplitQuoteId, {
            ...legQuote,
            parentId,
          }),
        ];

        const updateStrategyFairPricesActions = strategyToSplitFairPriceId
          ? [
              fairPriceCrudActions.upsert(strategyToSplitFairPriceId, {
                ...fairPrice,
                parentId,
              }),
            ]
          : [];

        const deleteStrategyFeatures: Action = featureCrudActions.deleteByPartialKey({
          strategyId: strategyToSplitId,
        });

        return [
          ...updateStrategyDataActions,
          ...updateStrategyQuoteActions,
          ...updateStrategyFairPricesActions,
          deleteStrategyFeatures,
        ];
      }

      function createUpdateStrategyDataQuoteAndFairPricesActionsForNewStrategies() {
        const updateStrategyDataActions = [
          strategyDataCrudActions.insert(currentStrategyId, {
            ...strategyDataRest,
            legIds: [legId],
            uuid: currentStrategyId,
            strategyType: newStrategyType,
            weight: weight * legWeight,
            isMasterStrategy: false,
            fairPriceId: currentStrategyId,
            quoteId: currentStrategyId,
          }),
        ];

        const parentId: ParentId = { type: 'Strategy', id: currentStrategyId };

        const updateStrategyQuoteActions = [
          quoteCrudActions.insert(currentStrategyId, {
            ...legQuote,
            parentId,
          }),
        ];

        const updateStrategyFairPricesActions = fairPriceId
          ? [
              fairPriceCrudActions.insert(currentStrategyId, {
                ...fairPrice,
                parentId,
              }),
            ]
          : [];

        const updateLegDataActions = [
          legDataCrudActions.update(legId, { strategyId: currentStrategyId }),
        ];

        return [
          ...updateStrategyDataActions,
          ...updateStrategyQuoteActions,
          ...updateStrategyFairPricesActions,
          ...updateLegDataActions,
        ];
      }

      return currentStrategyId === strategyToSplitId
        ? createUpdateStrategyDataQuoteAndFairPricesActionsForStrategyToSplit()
        : createUpdateStrategyDataQuoteAndFairPricesActionsForNewStrategies();
    }

    const actions: Dispatchable[] = [
      createLegLegsToStrategiesAction(legIds),
      ...flatMap(legIds, updateQuotesAndFairPricesForLeg),
    ];

    const defaultSizeType = getStrategyTypeDefaultSizeType(state, strategyType, selectors);

    strategiesToAddIds.forEach(stratId => {
      actions.push(
        strategyUiCrudActions.insert(stratId, {
          displayedSizeType: toDisplayNegotiatedSize[defaultSizeType],
          displayMissingCompositionDataWarning: false,
          displayCompoImportHasMultipleIndicesWarning: false,
        }),
      );
    });

    dispatch(actions);
  };
}

function getNewStrategyType(currentProduct: Product): StrategyType {
  switch (currentProduct.subFamily) {
    case 'OPTION':
      if (!currentProduct.type) {
        throw Error(
          'Could not split this Option strat. At least, one leg does not have an option type',
        );
      }
      return currentProduct.type;
    case 'FIXED_STRIKE_FVA':
      if (!currentProduct.type) {
        throw Error(
          'Could not split this FVA strat. At least, one leg does not have an option type',
        );
      }
      return currentProduct.type === 'CALL' ? 'FVA_FIXED_K_CALL' : 'FVA_FIXED_K_PUT';
    case 'FLOATING_STRIKE_FVA':
      if (!currentProduct.type) {
        throw Error(
          'Could not split this FVA strat. At least, one leg does not have an option type',
        );
      }
      return currentProduct.type === 'CALL' ? 'FVA_FLOATING_K_CALL' : 'FVA_FLOATING_K_PUT';
    case 'ELS':
      return 'ELS';
    case 'PRS':
      return 'ELS_PRS';
    case 'TRS':
      return 'ELS_TRS';
    default:
      return currentProduct.subFamily;
  }
}
