import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../business/neosModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function DeclareCashDiv() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, declaredCashDiv } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;
  const onDeclaredCashDivChange = (newDeclaredCashDiv: number | undefined) => {
    dispatch(updateProduct(uuid, { declaredCashDiv: newDeclaredCashDiv }));
  };

  return (
    <ElsBlocField
      label={{
        type: 'tooltip',
        content: 'Dec. Cash Equivalent Div %',
        tooltip: 'Declare Cash Equivalent Dividend Percentage',
      }}
      renderInput={readOnly => (
        <NumericInput
          readOnly={readOnly}
          style={{ width: '170px' }}
          unit="%"
          className="min-180"
          value={declaredCashDiv}
          onBlur={val => onDeclaredCashDivChange(val)}
          data-e2e="other-section-declaredCashDiv"
        />
      )}
    />
  );
}
