import { getContractNameModel } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/contractNameCell/getContractNameModel.ts';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput.tsx';
import { actionCreators } from '@/bootstrap/actions.ts';

interface ContractNameProps {
  strategyId: string;
}

export function ContractNameCell({ strategyId }: ContractNameProps) {
  const dispatch = useAppDispatch();
  const { contractNames } = useAppSelector(state =>
    getContractNameModel(state, strategyId, selectors),
  );

  const updateContractName = (productId: string, newValue: string) => {
    dispatch(
      actionCreators.neos.productCrudActions.update(productId, {
        contractName: newValue.length ? newValue : undefined,
      }),
    );
  };

  return (
    <section>
      {contractNames.map(({ id, value, isEditable }) => (
        <div key={id}>
          <NeosBlurInput
            value={value || ''}
            onBlur={event => updateContractName(id, event.target.value)}
            disabled={!isEditable}
            data-e2e="strategy-contract-name"
          />
        </div>
      ))}
    </section>
  );
}
