import type { Selectors } from '@/bootstrap/selectors';
import type { PredealChecksScreenModel } from '../PredealChecksScreen';
import type { AppState } from '@/bootstrap/store.ts';

export function getPredealChecksScreenModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PredealChecksScreenModel {
  const selectedPredealCheck = selectors.getNeosSelectedPredealCheck(state, rfqId, selectors);
  const selectedPredealCheckId = selectedPredealCheck ? selectedPredealCheck.uuid : undefined;

  const deltaLegs = selectors
    .getRfqLegIdsByDeltaStrategyIds(state, rfqId, selectors)
    .filter(
      ({ strategyId }) => !selectors.isDeltaHedgingStrategyAdjusted(state, strategyId, selectors),
    );

  return { selectedPredealCheckId, hasDelta: deltaLegs.length > 0 };
}
