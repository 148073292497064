import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { type Dispatchable, thunks } from '@/bootstrap/thunks';
import { DefaultingScope, isClsProduct, isElsProduct } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import type { GenerateFromType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { GenerateFromResult } from './GenerateFromResult';
import { GenerateFromValues } from './ScheduleModel';

export function GenerateFrom({ strategyId, rfqId }: { strategyId: string; rfqId: string }) {
  const dispatch = useAppDispatch();

  const isReadonly = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product) && !isClsProduct(product)) {
    throw new Error('product is neither an els nor a cls');
  }

  const { generateFrom, uuid } = product;

  function onGenerateFromChanged(value: GenerateFromType | undefined) {
    const overrideScopes = [];
    if (isElsProduct(product)) {
      overrideScopes.push(DefaultingScope.FIXED_DAY_TYPE);
    }
    if (isClsProduct(product)) {
      overrideScopes.push(DefaultingScope.UNDERLYING);
    }
    const options = new DefaultingOptions({
      overrideScopes,
    });
    const dispatchables: Array<Dispatchable> = [
      thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true),
      actionCreators.neos.productCrudActions.update(uuid, { generateFrom: value }),
      actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options),
    ];
    dispatch(dispatchables);
  }

  return (
    <>
      <label className="p-1">Generate From</label>
      <NeosSelect
        value={generateFrom}
        readOnly={isReadonly}
        onChange={onGenerateFromChanged}
        data-e2e="schedule-generate-from"
        addEmptyOption
        options={GenerateFromValues}
      />

      <span></span>
      {generateFrom ? (
        <>
          <label className="p-1">{GenerateFromValues[generateFrom]}</label>
          <GenerateFromResult generateFrom={generateFrom} strategyId={strategyId} />
        </>
      ) : (
        <>
          <span></span>
          <span></span>
        </>
      )}
    </>
  );
}
