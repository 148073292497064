import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { getCommissionsModel } from './getCommissionsModel';
import { getPrimeBrokersModel } from './getPrimeBrokersModel';

export const ExecutionAllocationsHeader = ({ rfqId }: { rfqId: string }) => {
  const areCommissionFieldsDisplayed = useAppSelector(state =>
    getCommissionsModel(state, rfqId, selectors),
  );
  const { arePrimeBrokersDisplayed } = useAppSelector(state =>
    getPrimeBrokersModel(state, rfqId, selectors),
  );

  return (
    <>
      {/* some fields doesnt have a header so we put empty label do keep the grid properly aligned */}
      <label className="mb-0">{/* Empty label for the bin icon */}</label>
      {arePrimeBrokersDisplayed && <label className="mb-0">Clearer</label>}

      <SimpleNeosTooltip id="clearer-account" message={'Clearer Account'}>
        <label className="mb-0">Clearer Acc.</label>
      </SimpleNeosTooltip>
      <SimpleNeosTooltip id="allocated-size" message={'Allocated Size'}>
        <label className="mb-0">Al. Size</label>
      </SimpleNeosTooltip>
      <label className="mb-0">Eliot ID</label>

      {areCommissionFieldsDisplayed && (
        <>
          <SimpleNeosTooltip id="commission" message={'Commission'}>
            <label className="mb-0">Com.</label>
          </SimpleNeosTooltip>

          <SimpleNeosTooltip id="commission-type" message={'Commission Type'}>
            <label className="mb-0">Com. Type</label>
          </SimpleNeosTooltip>
          <SimpleNeosTooltip
            id="commission-total"
            message={'Approximative Total Commission Amount'}
          >
            <label className="mb-0">Total Com.</label>
          </SimpleNeosTooltip>
        </>
      )}
    </>
  );
};
