import type { Selectors } from '@/bootstrap/selectors';
import type { PreconfColor } from '@/neos/business/preconfirmation/shared/preconfirmationModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface PreconfirmationModalModel {
  isPreconfirmationButtonsDisabled: boolean;
  isExternalPreconfirmationPreviewVisible: boolean;
  isInternalPreconfirmationPreviewVisible: boolean;
  externalColor: PreconfColor;
  internalColor: PreconfColor;
}

export function getPreconfirmationModalModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PreconfirmationModalModel {
  const isPreconfirmationButtonsDisabled = selectors.getIsPreconfirmationButtonsDisabled(
    state.ui,
    rfqId,
  );
  const isExternalPreconfirmationPreviewVisible = !!selectors.getPreconfirmationEmailPreview(
    state,
    rfqId,
    'EXTERNAL',
  );
  const isInternalPreconfirmationPreviewVisible = !!selectors.getPreconfirmationEmailPreview(
    state,
    rfqId,
    'INTERNAL',
  );
  const externalColor = selectors.getExternalPreconfBulletColor(state, rfqId);
  const internalColor = selectors.getInternalPreconfBulletColor(state, rfqId);

  return {
    isPreconfirmationButtonsDisabled,
    isExternalPreconfirmationPreviewVisible,
    isInternalPreconfirmationPreviewVisible,
    externalColor,
    internalColor,
  };
}
