import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  LowerStrikeCells as Component,
  type LowerStrikeCellsDispatchProps,
  type LowerStrikeCellsMapStateProps,
  type LowerStrikeCellsOwnProps,
} from './LowerStrikeCells';
import { getLowerStrikeCellsModel } from './getLowerStrikeCellsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const LowerStrikeCells = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: LowerStrikeCellsOwnProps,
): LowerStrikeCellsMapStateProps {
  return { model: getLowerStrikeCellsModel(state, rfqId, strategyId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: LowerStrikeCellsOwnProps,
): LowerStrikeCellsDispatchProps {
  return {
    onLowerStrikeChanged(productId: string, lowerStrike: number | undefined) {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Select lower strike', lowerStrike?.toString()),
      );
      dispatch(
        neosThunks.createUpdateProductAndResetDependenciesThunk(
          rfqId,
          strategyId,
          productId,
          'lowerStrike',
          lowerStrike,
        ),
      );
    },
  };
}
