import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { connect } from 'react-redux';
import {
  type SummaryDispatchProps,
  type SummaryOwnProps,
  type SummaryPropsFromState,
  SummaryUnconnected,
} from './Summary';
import type { AppState } from '@/bootstrap/store.ts';

export const Summary = connect(mapStateToProps, mapDispatchToProps)(SummaryUnconnected);

function mapStateToProps(state: AppState, { rfqId }: SummaryOwnProps): SummaryPropsFromState {
  const { quoteRecap } = selectors.getRfqData(state, rfqId);
  const isLoading = selectors.isRfqLoading(state.ui, rfqId);
  return {
    summary: quoteRecap,
    isLoading,
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch, _: SummaryOwnProps): SummaryDispatchProps {
  return {
    onCopySummaryToClipboard(summary: string) {
      dispatch(
        thunks.createCopyToClipboardThunk(summary),
        createLogAnalyticsAction('NEOS RFQ', 'Copy RFQ Summary'),
      );
    },
  };
}
