import type { Selectors } from '@/bootstrap/selectors';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { AppState } from '@/bootstrap/store.ts';

export function isLoadableRfq(state: AppState, { strategies }: OnyxRfq, selectors: Selectors) {
  const { editableStrategies, viewableStrategies, readOnlyStrategies } =
    selectors.getStrategyConfiguration(state.referenceData);

  const strategyTypes = strategies.map(strat => strat.strategyType);

  return strategyTypes.every(strategyType =>
    [...editableStrategies, ...viewableStrategies, ...readOnlyStrategies].includes(strategyType),
  );
}
