import type { Middleware, MiddlewareAPI } from 'redux';

let indent = 0;

export function createThunkLoggerMiddleware() {
  if (!import.meta.env.PROD) {
    const loggerMiddleware: Middleware = (_: MiddlewareAPI) => next => action => {
      const msg = getMessage(action);

      const indentString = ' |'.repeat(indent);
      msg.unshift('[REL]', indentString);
      // eslint-disable-next-line no-console
      console.debug(...msg);

      indent++;
      const returnValue = next(action);
      indent--;

      return returnValue;
    };

    return loggerMiddleware;
  }
  return undefined;
}

function getMessage(action: any): string[] {
  if (action instanceof Function) {
    if (action.name) {
      return [action.name];
    }
    const thunkString = action.toString();
    if (thunkString.includes('updateNotification') && thunkString.includes('addNotification')) {
      return ['reapopAddOrUpdateNotification'];
    }
    if (thunkString.includes('addNotification')) {
      return ['reapopAddNotification'];
    }
    return ['Unnamed', action];
  }

  return [action];
}
