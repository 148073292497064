import { actionCreators } from '@/bootstrap/actions';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import type { NextUserAction } from '@/neos/business/nextUserActions/nextUserActionsModel';
import { neosThunks } from '@/neos/business/thunks';
import { throttle } from 'lodash';
import { useDispatch } from 'react-redux';
import { type DispatchProps, NextUserActionsComponent, type OwnProps } from './NextUserActions';
import { getNextUserActionModel } from './getNextUserActionsModel';
import { memoize } from 'proxy-memoize';
import { useAppSelector } from '@/bootstrap/hooks.ts';

export const NextUserActions = (ownProps: OwnProps) => {
  const stateProps = useAppSelector(
    memoize(state => ({
      model: getNextUserActionModel(state, ownProps.rfqId, selectors),
    })),
  );
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchToProps(dispatch, ownProps);
  return <NextUserActionsComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchToProps(dispatch: ExtendedDispatch, { rfqId }: OwnProps): DispatchProps {
  return {
    onSelectAction: throttle(
      (nextUserAction?: NextUserAction) => {
        if (nextUserAction) {
          dispatch(
            actionCreators.neos.rfqUiCrudActions.update(rfqId, {
              isRefSpotChangedByTrader: false,
              oldRefSpotValue: undefined,
            }),
          );
          dispatch(
            createLogAnalyticsAction('NEOS RFQ', 'RFQ status', nextUserAction.action),
            neosThunks.createApplyNextUserActionThunk(rfqId, nextUserAction),
          );
        }
      },
      500,
      { trailing: false },
    ),
  };
}
