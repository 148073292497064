import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppState } from '@/bootstrap/store.ts';

export const isUserDraggingFileSlice = createSlice({
  name: 'isUserDraggingFile',
  initialState: false,
  reducers: {
    updateUserIsDraggingFile: (_state, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export function selectIsUserDraggingFile(state: AppState) {
  return state.ui.isUserDraggingFile;
}
