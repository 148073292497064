import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { getLegsToChange } from '@/neos/components/rfq/postNego/allocs/matchingOrInsertToggle/getLegsToChange';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { ToggleButton, ToggleButtonGroup, Tooltip } from 'react-bootstrap';
import type { AllocationsModel } from '../getAllocationsModel';

type MatchingOrInsertToggleProps = {
  rfqId: string;
  legs: Partial<AllocationsModel>;
  isDelta?: boolean;
};

export function MatchingOrInsertToggle({ rfqId, legs, isDelta }: MatchingOrInsertToggleProps) {
  const dispatch = useAppDispatch();

  const {
    isDisplayed,
    areButtonsInSync,
    allLegsAreMatching,
    isInsertionDisabled,
    isMatchingDisabled,
  } = useAppSelector(state =>
    selectors.getMatchingInsertionToggleModel(state, rfqId, legs, !!isDelta, selectors),
  );

  const onMatchingToggle = (value: boolean) => {
    const isValueDifferent = allLegsAreMatching !== value;

    if (isValueDifferent) {
      const legsToChange = getLegsToChange(areButtonsInSync, legs, isDelta);
      dispatch(neosThunks.createChangeListedOrdersMatchingThunk(rfqId, value, legsToChange));
    }
  };

  if (!isDisplayed) {
    return null;
  }

  const uniqueId = rfqId;

  return (
    <div className="d-flex mb-1 align-items-center">
      <SimpleNeosTooltip id={'booking-mode-tooltip'} message={'Booking mode'}>
        <ToggleButtonGroup
          name={'quoteType-' + uniqueId}
          className="btn-group-toggle"
          type="radio"
          value={allLegsAreMatching ? 'matching' : 'insertion'}
          onChange={value => onMatchingToggle(value === 'matching')}
        >
          <ToggleButton
            data-e2e="matching-toggle-btn"
            disabled={isMatchingDisabled}
            variant={allLegsAreMatching ? 'info' : 'outline-secondary'}
            value="matching"
            className="ms-0"
            id={`matching-${uniqueId}`}
          >
            Matching
          </ToggleButton>
          <ToggleButton
            data-e2e="insertion-toggle-btn"
            disabled={isInsertionDisabled}
            variant={!allLegsAreMatching ? 'info' : 'outline-secondary'}
            value="insertion"
            className="ms-0"
            id={`insertion-${uniqueId}`}
          >
            Insertion
          </ToggleButton>
        </ToggleButtonGroup>
      </SimpleNeosTooltip>
      <NeosTooltip
        placement="top"
        overlay={
          <Tooltip id={'booking-tooltip'}>
            {allLegsAreMatching ? (
              <div className="text-start">
                Matching mode:
                <br />
                <br />
                The deal was booked manually in booking tools. NEOS will retrieve the Ids through
                matching mechanism
                <br />
                {areButtonsInSync && (
                  <>
                    <br />
                    This button acts for Allocations and Deltas
                  </>
                )}
              </div>
            ) : (
              <div className="text-start">
                Insertion mode:
                <br />
                <br />
                You will insert from NEOS the deal in booking tools
                <br />
                {areButtonsInSync && (
                  <>
                    <br />
                    This button acts for Allocations and Deltas
                  </>
                )}
              </div>
            )}
          </Tooltip>
        }
      >
        <i className="icon icon-sm">info_outline</i>
      </NeosTooltip>
    </div>
  );
}
