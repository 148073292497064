import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { FlowDatePickerWithOffset } from '@/neos/components/share/datePicker/FlowDatePickerWithOffset';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import {
  type EffectiveDateCellsModel,
  getEffectiveDateCellsModel,
} from './getEffectiveDateCellsModel';
import {
  getIsEffectiveDateCellsEditableModel,
  type IsEffectiveDateCellsModel,
} from './getIsEffectiveDateCellsEditableModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export interface EffectiveDateCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

type EffectiveDateCellsProps = EffectiveDateCellsOwnProps;

export function EffectiveDateCells({ rfqId, strategyId, isReadOnlyRfq }: EffectiveDateCellsProps) {
  const dispatch = useAppDispatch();

  function onEffectiveDateChange(selectedDate: string) {
    dispatch(neosThunks.createUpdateProductEffectiveDateThunk(rfqId, strategyId, selectedDate));
  }

  function onEffectiveOffsetChange(selectedOffset: number) {
    dispatch(
      neosThunks.createUpdateProductEffectiveDateOffsetThunk(rfqId, strategyId, selectedOffset),
    );
  }

  const legId: string = useAppSelector(
    state => selectors.getStrategyMasterLeg(state, strategyId, selectors).uuid,
  );

  const { effectiveDate, effectiveDateOffset, isDisplayed }: EffectiveDateCellsModel =
    useAppSelector(state => getEffectiveDateCellsModel(state, strategyId, selectors));

  const { isEditable }: IsEffectiveDateCellsModel = useAppSelector(state =>
    getIsEffectiveDateCellsEditableModel(state, { strategyId, rfqId }, selectors),
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <section>
      <ErrorHighlight errorField={'effectiveDate'} related={{ rfqId, strategyId, legId }}>
        <FlowDatePickerWithOffset
          data-e2e="effective-date"
          date={effectiveDate ?? ''}
          onChange={onEffectiveDateChange}
          onOffsetChange={onEffectiveOffsetChange}
          currentOffset={effectiveDateOffset}
          readOnly={isReadOnlyRfq || !isEditable}
          hideCalendarIcon
        />
      </ErrorHighlight>
    </section>
  );
}
