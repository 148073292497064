import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  StrategyDetailsComponentWithStyle,
  type StrategyDetailsDispatchProps,
  type StrategyDetailsMapStateToProps,
  type StrategyDetailsOwnProps,
} from './StrategyDetails';
import { type StrategyDetailsModel, getStrategyDetailsModel } from './getStrategyDetailsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const StrategyDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StrategyDetailsComponentWithStyle);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: StrategyDetailsOwnProps,
): StrategyDetailsMapStateToProps {
  const result: StrategyDetailsModel = getStrategyDetailsModel(state, rfqId, strategyId, selectors);

  return { model: result };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: StrategyDetailsOwnProps,
): StrategyDetailsDispatchProps {
  return {
    onCommentChanged: comment => {
      dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { comment }));
    },

    onStepChanged: () => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Switch to target step', 'POST_NEGO'),
        neosThunks.createNeosSwitchNegoStepThunk(rfqId, 'POST_NEGO'),
      );
    },
    onDeliveryTypeChanged: deliveryType => {
      dispatch(neosThunks.createChangeStrategyDeliveryTypeThunk(strategyId, deliveryType));
      dispatch(neosThunks.createDefaultConfirmMediaRequested(rfqId));
    },
    onTraderChanged: traderId =>
      dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { traderId })),
    onTraderGroupChanged: traderGroupName =>
      dispatch(neosThunks.createChangeStrategyTraderGroup(rfqId, strategyId, traderGroupName)),

    // TODO use update booking step action
    onBookingStepApplicationChanged: (bookingStepApplication, key) =>
      dispatch(
        neosActionCreators.updateTopLevelBookingApplicationStrategyOrLegBookingStep({
          rfqId,
          parentBookingUuid: key,
          bookingApplication: bookingStepApplication,
        }),
      ),
    onBookingIdChanged: (bookingId, key) =>
      dispatch(
        neosActionCreators.updateTopLevelBookingIdStrategyOrLegBookingStep({
          rfqId,
          parentBookingUuid: key,
          bookingId,
        }),
      ),
    onEtdDeskCityChanged: etdDeskCity => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Strategy ETD Desk Change', etdDeskCity ?? '[None]'),
        neosActionCreators.strategyDataCrudActions.update(strategyId, { etdDeskCity }),
      );
    },
    onExecutorsChanged: executor => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Strategy Executor Change', executor ?? '[None]'),
        neosActionCreators.strategyDataCrudActions.update(strategyId, {
          executor,
        }),
      );
    },
  };
}
