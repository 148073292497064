import { selectedStrategyReducer } from './selectedStrategy/selectedStrategyReducer';
import { combineReducers } from 'redux';
import { tabReducer } from './tab/tabReducer';
import { errorReducer } from './error/errorReducer';
import { rfqUiReducer } from './rfq/rfqUiReducer';
import { strategyUiReducer } from './strategy/strategyUiReducer';
import { userPreferencesReducer } from './userPreferences/userPreferencesUiReducer';
import { blotterUiReducer } from './blotter/blotterUiReducer';
import { headerMenuReducer } from './headerMenu/headerMenuReducer';
import { underlyingUiReducer } from './underlying/underlyingUiReducer';
import { referenceUiReducer } from './reference/referenceUiReducer';
import { legUiReducer } from './leg/legUiReducer';
import { referencedRfqReducer } from './referencedRfq/referencedRfqReducer';
import { prefonfirmationPreferencesReducer } from './prefonfirmationPreferences/prefonfirmationPreferencesReducer';
import { allocationUiReducer } from './allocation/allocationUiReducer';
import { elsSectionsReducer } from '../rfq/strategy/feature/elsSections/elsSectionsReducer';
import { topLevelErrorSlice } from '@/neos/business/ui/topLevelError/topLevelErrorSlice';
import { isUserDraggingFileSlice } from './isUserDraggingFile/isUserDraggingFileSlice.ts';

export const neosUiReducer = combineReducers({
  tabManagement: tabReducer,
  selectedStrategy: selectedStrategyReducer,
  error: errorReducer,
  topLevelError: topLevelErrorSlice.reducer,
  isUserDraggingFile: isUserDraggingFileSlice.reducer,
  rfq: rfqUiReducer,
  referencedRfq: referencedRfqReducer,
  strategy: strategyUiReducer,
  leg: legUiReducer,
  allocation: allocationUiReducer,
  userPreferences: userPreferencesReducer,
  preconfirmationPreferences: prefonfirmationPreferencesReducer,
  blotter: blotterUiReducer,
  headerMenu: headerMenuReducer,
  underlying: underlyingUiReducer,
  reference: referenceUiReducer,
  elsSections: elsSectionsReducer,
});
