import type { Selectors } from '@/bootstrap/selectors';
import signalRService from '@/util/notifications/signalRService';
import type { AppState } from '@/bootstrap/store.ts';

export const helpRequestSelectors = {
  isHelpRequestModalDisplayed,
  getHelpRequestError,
  getAdditionnalHelpRequestInformation,
};

export function isHelpRequestModalDisplayed(state: AppState) {
  return state.helpRequestState.displayHelpRequestModal;
}

export function getHelpRequestError(state: AppState) {
  const hrState = state.helpRequestState;

  return hrState.errorLocation
    ? {
        location: hrState.errorLocation,
        error: hrState.error,
      }
    : undefined;
}

export function getAdditionnalHelpRequestInformation(state: AppState, selectors: Selectors) {
  const openedTransactions = Object.keys(state.rfqDataState);
  const userInfo = selectors.getCurrentUser(state);
  const userName = selectors.getUserName(state);
  const { login, sesameId } = userInfo;
  const { datastreamId } = signalRService;

  return {
    Login: login,
    Username: userName,
    'Sesame Id': sesameId,
    DataStreamId: datastreamId,
    'Opened Transactions': openedTransactions.length === 0 ? 'None' : openedTransactions.join(', '),
  };
}
