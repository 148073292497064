import type { Selectors } from '@/bootstrap/selectors';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { AppState } from '@/bootstrap/store.ts';

interface PreconfPrefGroups {
  groupKey: string;
  enabled: boolean;
  isLoading: boolean;
}

export interface UserPrefPreconfModel {
  preconfGroups: PreconfPrefGroups[];
}

export function getPreconfGroupsModel(state: AppState, selectors: Selectors): UserPrefPreconfModel {
  return {
    preconfGroups: getPreconfGroups(state, selectors),
  };
}

function getPreconfGroups(state: AppState, selectors: Selectors): PreconfPrefGroups[] {
  const referenceDataState = state.referenceData;
  const { diffusionGroups } = selectors.getCurrentUser(state);
  const savedPreconfGroups = selectors.getSavedPreconfGroups(state.ui.preconfirmationPreferences);
  const loadingPreconfGroups = selectors.getLoadingPreconfGroups(
    state.ui.preconfirmationPreferences,
  );

  const userGroups = selectors.getUserGroups(referenceDataState);

  const preconfGroups = diffusionGroups
    .map(diffusionGroup => {
      const groupKey = userGroups.find(({ id }) => id === diffusionGroup)?.value;
      if (groupKey) {
        const enabled = !!savedPreconfGroups?.find(gk => gk === groupKey);
        const isLoading = !!loadingPreconfGroups.find(gk => gk === groupKey);

        return {
          groupKey,
          enabled,
          isLoading,
        };
      }
      return undefined;
    })
    .filter(isDefined);

  return preconfGroups;
}
