import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { ClsType } from '../../../../../../../neos/business/neosModel';
import {
  type ClsTypeCellsDispatchProps,
  type ClsTypeCellsMapStateProps,
  type ClsTypeCellsOwnProps,
  ClsTypeCells as Component,
} from './ClsTypeCells';
import { getClsTypeCellsModel } from './getClsTypeCellsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const ClsTypeCells = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: ClsTypeCellsOwnProps,
): ClsTypeCellsMapStateProps {
  return getClsTypeCellsModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId, rfqId }: ClsTypeCellsOwnProps,
): ClsTypeCellsDispatchProps {
  return {
    onClsTypeChanged(productId: string, clsType: ClsType | undefined) {
      dispatch(neosThunks.createUpdateProductClsTypeThunk(strategyId, rfqId, productId, clsType));
    },
  };
}
