import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { DefaultingScope } from '../models';
import { RfqRecomputeScope } from '../models/recomputeModel';
import { DefaultingOptions } from '../rfqActions';
import { rfqDataActionCreators } from '../rfqData/rfqActionCreators';
import { createActionWithRecompute } from './actionWithRecompute';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';
import type { Action } from '@/bootstrap/actions.ts';

export function createRfqNotionalChangedAction(
  rfqId: string,
  notionalAmount: number | undefined,
): Action[] {
  return createActionWithRecompute(rfqId, RfqRecomputeScope.CHANGE_NOTIONAL_RFQ, [
    createLogAnalyticsAction('NEOS RFQ', 'Add Notional', notionalAmount?.toString()),
    rfqDataActionCreators.rfqDataCrudActions.update(rfqId, { notionalAmount }),
  ]);
}

export function createRfqNotionalCurrencyChangedAction(
  rfqId: string,
  notionalCurrency: string | undefined,
): Action[] {
  return [
    rfqDataActionCreators.rfqDataCrudActions.update(rfqId, { notionalCurrency }),
    createDefaultRfqRequestedAction(
      rfqId,
      new DefaultingOptions({
        enrichScopes: [DefaultingScope.FOREX],
        overrideScopes: [
          DefaultingScope.UNDERLYING,
          DefaultingScope.NOTIONAL,
          DefaultingScope.NEGOTIATED_SIZE,
          DefaultingScope.TRADED_AWAY_FEEDBACK,
        ],
      }),
    ),
  ];
}

export function createRfqPivotNotionalChangedAction(
  rfqId: string,
  pivotNotionalAmount: number | undefined,
  selectedPivotNotionalCurrency: string | undefined,
): Action[] {
  return createActionWithRecompute(rfqId, RfqRecomputeScope.CHANGE_PIVOT_NOTIONAL_RFQ, [
    createLogAnalyticsAction('NEOS RFQ', 'Add Pivot Notional', pivotNotionalAmount?.toString()),
    rfqDataActionCreators.rfqDataCrudActions.update(rfqId, {
      pivotNotionalAmount,
      pivotNotionalCurrency: selectedPivotNotionalCurrency,
    }),
  ]);
}
