import { createUpdateAllBasketSpotConfirmedThunk } from '@/neos/business/rfq/strategy/reference/updateAllBasketSpotConfirmedThunk';
import { featureThunks } from '../feature';
import { legThunks } from '../leg/thunks';
import { createAddExtraFeatureThunk } from './addExtraFeature';
import { createChangeMasterLegThunk } from './changeMasterLeg';
import { createChangeStrategiesNegotiationModeThunk } from './changeStrategiesNegotiationMode';
import { createChangeStrategyDeliveryTypeThunk } from './changeStrategyDeliveryType';
import { createChangeStrategyNegotiationModeThunk } from './changeStrategyNegotiationMode';
import { createChangeStrategyTraderGroup } from './changeStrategyTraderGroup';
import { createChangeStrategyTypeThunk } from './changeStrategyType';
import { createChangeStrategyUnderlyingThunk } from './changeStrategyUnderlying';
import { createChangeStrikeDateThunk } from './changeStrikeDateThunk';
import { createCloneStrategyThunk } from './cloneStrategy';
import { createCloneStrategyFeaturesThunk } from './cloneStrategyFeatures';
import { createDeleteStrategyDependenciesThunk } from './deleteStrategyDependencies';
import { createInitializeElsSectionsThunk } from './initializeElsSectionsThunk';
import { createLoadStrategiesThunk } from './loadStrategies';
import { createProceedToStrategyLegOverThunk } from './proceedToStrategyLegOver';
import { createRemoveExtraFeatureThunk } from './removeExtraFeature';
import { createRemoveStrategyFeaturesThunk } from './removeStrategyFeatures';
import {
  createStrategyAddLegsThunk,
  type LegAndQuoteIds as LocalLegAndQuoteIds,
} from './strategyAddLegsThunk';
import { createStrategyDeleteLegsThunk } from './strategyDeleteLegsThunk';
import { createStrategyProceedToLegsToStrategiesThunk } from './strategyProceedToLegsToStrategies';
import { createUpdateSettlementDateOffsetThunk } from './updateSettlementDateOffsetThunk';
import { createUpdateSettlementDateThunk } from './updateSettlementDateThunk';
import { createUpdateStrategyOrLegSizeThunk } from './updateStrategyOrLegSize';
import { createUpdateValueDateOffsetThunk } from './updateValueDateOffsetThunk';
import { createUpdateValueDateThunk } from './updateValueDateThunk';

export type LegAndQuoteIds = LocalLegAndQuoteIds;

export const strategyThunks = {
  ...legThunks,
  ...featureThunks,
  createDeleteStrategyDependenciesThunk,
  createChangeStrategyDeliveryTypeThunk,
  createCloneStrategyThunk,
  createStrategyProceedToLegsToStrategiesThunk,
  createChangeStrategyTypeThunk,
  createChangeMasterLegThunk,
  createChangeStrategiesNegotiationModeThunk,
  createProceedToStrategyLegOverThunk,
  createChangeStrategyNegotiationModeThunk,
  createAddExtraFeatureThunk,
  createRemoveExtraFeatureThunk,
  createRemoveStrategyFeaturesThunk,
  createCloneStrategyFeaturesThunk,
  createLoadStrategiesThunk,
  createChangeStrategyTraderGroup,
  createStrategyDeleteLegsThunk,
  createStrategyAddLegsThunk,
  createChangeStrategyUnderlyingThunk,
  createUpdateStrategyOrLegSizeThunk,
  createInitializeElsSectionsThunk,
  createUpdateSettlementDateOffsetThunk,
  createUpdateSettlementDateThunk,
  createUpdateValueDateOffsetThunk,
  createUpdateValueDateThunk,
  createChangeStrikeDateThunk,
  createUpdateAllBasketSpotConfirmedThunk,
};
