import type { Selectors } from '@/bootstrap/selectors';
import type { BlotterModelGetters } from '.';
import type { AppState } from '@/bootstrap/store.ts';

export function getBlotterRows(
  state: AppState,
  selectors: Selectors,
  blotterModelGetters: BlotterModelGetters,
) {
  const { filterBlotterRows, getFullBlotterRow } = blotterModelGetters;

  const rfqWithSubfilterApplied = filterBlotterRows(state, selectors, blotterModelGetters);

  return rfqWithSubfilterApplied.map(blotterRfq =>
    getFullBlotterRow(state, blotterRfq, selectors, blotterModelGetters),
  );
}
