import type { Selectors } from '@/bootstrap/selectors';
import type { PriceWithUnit } from '@/neos/business/rfq/rfqOnyxModel';
import { distinct } from '@/util/array/arrayUtils';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { intersection } from 'lodash';
import {
  type AvailableStrikes,
  type EventType,
  type FeatureKey,
  isElsBasketProduct,
  isSingleUnderlyingDerivativeProduct,
  type Listed,
  type Status,
} from '../../neosModel';
import type { ExecutionKey } from '../../order/orderModel';
import type { StrategyType } from '../../referenceData/referenceDataModel';
import type { SizeType } from '../../referenceData/referenceDataOnyxModel';
import { orderOf } from '../../services/statusOrder';
import type { QuoteFairPriceId } from '../selectors';
import { featureSelectors } from './feature/featureSelectors';
import {
  isCustomUnderlyingProduct,
  isElsProduct,
  isFutureLikeProduct,
  isFutureOptionProduct,
  isListedExecutionProduct,
  isListedProduct,
  isOptionLike,
  isOptionProduct,
  isOtcExecutionProduct,
  isOtcProduct,
  type LegData,
  type Product,
  type SecondaryEvent,
} from './leg/legModel';
import { legSelectors } from './leg/legSelectors';
import {
  REFERENTIAL_MISSING_CONTRACT_WARNING,
  REFERENTIAL_MULTIPLE_CONTRACTS_WARNING,
} from './referentialWarning';
import { isRfqStrategyData, type StrategyData } from './strategyData/strategyDataModel';
import type { AppState } from '@/bootstrap/store.ts';

export const strategySelectors = {
  ...legSelectors,
  ...featureSelectors,
  getStrategyLegsOrIndexLegData,
  isPrimaryStrategy,
  isListedExecutionStrategy,
  isOtcExecutionStrategy,
  getStrategyIdByLegId,
  getStrategyData,
  getStrategyLegsSizes,
  getStrategyLegsSecondaryEvent,
  getAvailableStrikesByMaturity,
  getAvailableStrikesByLegId,
  getAvailableStrikesByLegIdWithGivenMaturity,
  getAvailableStrikesForStrategy,
  getAvailableStrikesForStrategyWithGivenMaturity,
  getAvailableStrikesForSameStrikeStrategy,
  getAvailableStrikesForSameStrikeStrategyWithGivenMaturity,
  getAllDistinctUnderlyingAndRefIdsOfStrategy,
  isStrategyUnderlyingsSet,
  isVarVolDispIndexLegUnderlyingSet,
  isDeltaHedgingStrategy,
  isDeltaHedgingStrategyAdjusted,
  getStrategyFairPriceQuoteIds,
  getStrategyQuoteIds,
  getStrategyFairPriceIds,
  getStrategyLegsData,
  getStrategyProducts,
  getStrategyProductIds,
  getStrategyWarnings,
  isStrategyFeatureMandatory,
  isStrategyFeatureMandatoryOrDefined,
  isStrategyInconsistentForLegOverReverse,
  getStrategyTypeDefaultSizeType,
  isStrategyCustomUnderlying,
  getStrategyMasterLeg,
  getStrategyMasterProduct,
  getUnderlyingOrRefIdOfStrategy,
  isOtcStrategy,
  isListedStrategy,
  isValidListedAllocExecStrategySize,
  isValidListedExecStrategySize,
  isValidStrategySize,
  isCustomOptionsStrategy,
  areFairPricesDisplayed,
  areElsPtmmmDisplayed,
  isFairPriceFuruteOrForwardPtmmm,
  isElsStrategy,
  isClsStrategy,
  isMasterStrategyFuture,
  isMasterStrategyOtc,
  isStrategyFutureLike,
  isStrategyFutureRoll,
  areRfqStrategiesFutureLike,
  isRfqVarVolDisp,
  isSecondaryStrategy,
  isMasterStrategyIndexFutureOrFutureRoll,
  isSomeStrategyProductFlexOptions,
  onlyOneStrategyProductFlexOptions,
  getStrategyLegsEventTypes,
  getElsRefSpotInSwapCurrency,
  isElsStrategyWithLegacyPercentPriceUnitType,
  getRfqIdByStrategyId,
  isReadOnlyAtCurrentWorkflow,
  isRfqReadOnlyAtCurrentWorkflow,
  isRfqElsBasket,
  isSecondaryEditable,
  isVarVolDispersionStrategy,
  isDividendSwapStrategy,
};

/**
 * This is a check for legacy RFQs that were created with price unit REF_PERCENT
 * in order to display the price unit even if the strategyDefinition does not contain REF_PERCENT for ELS
 */
function isElsStrategyWithLegacyPercentPriceUnitType(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const isElsStrategy = selectors.isElsStrategy(state, strategyId, selectors);
  const { priceUnitType } = selectors.getStrategyData(state, strategyId);
  return isElsStrategy && priceUnitType === 'REF_PERCENT';
}

function getStrategyData(appState: AppState, strategyId: string): StrategyData {
  return appState.strategyDataState[strategyId] || {};
}

function getStrategyIdByLegId(appState: AppState, legId: string): string {
  return (
    Object.keys(appState.strategyDataState).find(strategyDataKey =>
      appState.strategyDataState[strategyDataKey].legIds.includes(legId),
    ) || ''
  );
}

function isStrategyInconsistentForLegOverReverse(
  appState: AppState,
  strategyId: string,
  { getStrategyData }: Selectors,
): boolean {
  const { strategyType } = getStrategyData(appState, strategyId);
  return strategyType === 'FUTURE_ROLL' || strategyType === 'ELS';
}

function isElsStrategy(
  appState: AppState,
  strategyId: string,
  { getStrategyData }: Selectors,
): boolean {
  const { strategyType } = getStrategyData(appState, strategyId);
  return strategyType === 'ELS';
}

function isClsStrategy(
  appState: AppState,
  strategyId: string,
  { getStrategyData }: Selectors,
): boolean {
  const { strategyType } = getStrategyData(appState, strategyId);
  return strategyType === 'CLS';
}

function isMasterStrategyFuture(
  state: AppState,
  rfqId: string,
  { getRfqMasterStrategy }: Selectors,
): boolean {
  const { strategyType } = getRfqMasterStrategy(state, rfqId);
  return strategyType === 'FUTURE';
}

function isMasterStrategyIndexFutureOrFutureRoll(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean | undefined {
  const { strategyType } = selectors.getRfqMasterStrategy(state, rfqId);
  const underlyingInfo = selectors.getMasterUnderlyingInfo(rfqId, state, selectors);

  if (!underlyingInfo) {
    return undefined;
  }

  return (
    (strategyType === 'FUTURE' || strategyType === 'FUTURE_ROLL') &&
    underlyingInfo.type === 'INDEX' &&
    underlyingInfo.metaFlagIdn !== 'Index Future Dividend'
  );
}

function areRfqStrategiesFutureLike(
  appState: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { strategyIds } = selectors.getRfqData(appState, rfqId);
  return !strategyIds.some(strategyId => {
    return !selectors.isStrategyFutureLike(appState, strategyId, selectors);
  });
}

function isStrategyFutureLike(
  appState: AppState,
  strategyId: string,
  { getStrategyData }: Selectors,
): boolean {
  const { strategyType } = getStrategyData(appState, strategyId);
  return (
    strategyType === 'FUTURE' ||
    strategyType === 'FUTURE_ROLL' ||
    strategyType === 'DIVIDEND_FUTURE' ||
    strategyType === 'TOTAL_RETURN_FUTURE_ROLL' ||
    strategyType === 'TOTAL_RETURN_FUTURE'
  );
}

function isStrategyFutureRoll(
  appState: AppState,
  strategyId: string,
  { getStrategyData }: Selectors,
): boolean {
  const { strategyType } = getStrategyData(appState, strategyId);
  return strategyType === 'FUTURE_ROLL';
}

function getStrategyLegsSizes(
  appState: AppState,
  strategyId: string,
  { getLegData, getStrategyData }: Selectors,
): (number | undefined)[] {
  const { legIds } = getStrategyData(appState, strategyId);
  return (legIds || []).map(legId => getLegData(appState, legId)?.numberOfLots);
}

function getStrategyLegsSecondaryEvent(
  appState: AppState,
  strategyId: string,
  { getLegData, getStrategyData }: Selectors,
): (SecondaryEvent | undefined)[] {
  const { legIds } = getStrategyData(appState, strategyId);
  return (legIds || []).map(legId => getLegData(appState, legId)?.secondaryEvent);
}

function getStrategyLegsEventTypes(
  appState: AppState,
  strategyId: string,
  { getLegData, getStrategyData }: Selectors,
): (EventType | undefined)[] {
  const { legIds } = getStrategyData(appState, strategyId);
  return legIds.map(legId => getLegData(appState, legId).eventType);
}

function getAllDistinctUnderlyingAndRefIdsOfStrategy(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): string[] {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  const barriers = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'BARRIERS',
  });

  const underlyingAndRefIds = legIds
    .flatMap(legId => {
      const { productId } = selectors.getLegData(state, legId);

      const product = selectors.getProduct(state, productId);
      const underlyingIds: (string | undefined)[] = [];

      if (isElsBasketProduct(product)) {
        if (product.stockLoanHedge?.stockLoanComponents) {
          const stockLoanUnderlyingIds = product.stockLoanHedge?.stockLoanComponents.map(
            ({ underlyingId }) => underlyingId,
          );
          underlyingIds.push(...stockLoanUnderlyingIds);
        }

        if (product.equityHedge?.equityHedgeComponents) {
          const equityHedgeUnderlyingIds = product.equityHedge?.equityHedgeComponents.map(
            ({ underlyingId }) => underlyingId,
          );
          underlyingIds.push(...equityHedgeUnderlyingIds);
        }

        const basketUnderlyingIds = product.basketUnderlying.basketComposition.map(
          ({ underlyingId }) => underlyingId,
        );
        underlyingIds.push(...basketUnderlyingIds);

        return underlyingIds;
      }

      if (isElsProduct(product)) {
        if (product.stockLoanHedge?.stockLoanComponents) {
          const stockLoanHedgeUnderlyingIds = product.stockLoanHedge?.stockLoanComponents.map(
            ({ underlyingId }) => underlyingId,
          );
          underlyingIds.push(...stockLoanHedgeUnderlyingIds);
        }

        if (product.equityHedge?.equityHedgeComponents) {
          const equityHedgeUnderlyingIds = product.equityHedge?.equityHedgeComponents.map(
            ({ underlyingId }) => underlyingId,
          );
          underlyingIds.push(...equityHedgeUnderlyingIds);
        }
      }

      underlyingIds.push(
        isSingleUnderlyingDerivativeProduct(product)
          ? product.underlyingId
          : isCustomUnderlyingProduct(product)
            ? undefined
            : product.refId,
      );

      return underlyingIds;
    })
    .filter(isDefined);

  const barriersUdls =
    barriers?.barriers
      .map(b => (b.underlyingType === 'NON_CUSTOM' ? b.underlyingId : undefined))
      .filter(isDefined) || [];

  return distinct([...underlyingAndRefIds, ...barriersUdls]);
}

function isStrategyUnderlyingsSet(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const products = selectors.getStrategyProducts(state, strategyId, selectors);
  return products.every(product => {
    if (isElsBasketProduct(product)) {
      return product.basketUnderlying.basketComposition.some(
        item => item.underlyingId !== undefined,
      );
    }

    return isSingleUnderlyingDerivativeProduct(product)
      ? !!product.underlyingId
      : isCustomUnderlyingProduct(product)
        ? product.underlyingName
        : !!product.refId;
  });
}

function isVarVolDispIndexLegUnderlyingSet(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const masterLeg = selectors.getStrategyMasterLeg(state, strategyId, selectors);
  const product = selectors.getLegProduct(state, masterLeg.uuid, selectors);
  if (!isSingleUnderlyingDerivativeProduct(product)) {
    throw new Error('VarVolDisp master product should be a Single Derivative Product');
  }

  return !!product.underlyingId;
}

function isRfqElsBasket(state: AppState, rfqId: string, selectors: Selectors): boolean {
  const { uuid: masterStrategyId } = selectors.getRfqMasterStrategy(state, rfqId);
  const masterProduct = selectors.getStrategyMasterProduct(state, masterStrategyId, selectors);
  return isElsBasketProduct(masterProduct);
}

function isRfqVarVolDisp(state: AppState, rfqId: string, selectors: Selectors): boolean {
  const { uuid: masterStrategyId } = selectors.getRfqMasterStrategy(state, rfqId);
  return isVarVolDispersionStrategy(state, masterStrategyId, selectors);
}

function isSecondaryStrategy(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const strat = selectors.getStrategyData(appState, strategyId);
  return strat.activityType === 'SECONDARY';
}

function getStrategyLegsOrIndexLegData(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): { legsData: LegData[]; hasACompositionLeg: boolean } {
  const hasACompositionLeg = selectors.hasACompositionLeg(appState, strategyId, selectors);

  if (hasACompositionLeg) {
    const strategyMasterLeg = selectors.getStrategyMasterLeg(appState, strategyId, selectors);
    return { hasACompositionLeg, legsData: [strategyMasterLeg] };
  }

  const legsData = selectors.getStrategyLegsData(appState, strategyId, selectors);

  return {
    legsData,
    hasACompositionLeg: false,
  };
}

function getStrategyLegsData(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): LegData[] {
  const { legIds } = selectors.getStrategyData(appState, strategyId);
  return legIds.map(legId => selectors.getLegData(appState, legId));
}

function getAvailableStrikesByMaturity(
  state: AppState,
  underlyingId: string | undefined,
  maturity: string | undefined,
  selectors: Selectors,
): number[] {
  if (underlyingId === undefined || maturity === undefined) {
    return [];
  }
  return selectors.getUnderlyingStrikesByMaturity(state, underlyingId, maturity);
}

function getAvailableStrikesByLegId(
  state: AppState,
  legId: string,
  selectors: Selectors,
): number[] {
  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  if (isFutureOptionProduct(product)) {
    const { maturity, underlyingId } = product;
    if (!underlyingId || !maturity) {
      return [];
    }
    return selectors.getUnderlyingStrikesByMaturityOnOptionOnFuture(state, underlyingId, maturity);
  }
  if (isSingleUnderlyingDerivativeProduct(product)) {
    const { maturity, underlyingId } = product;
    return selectors.getAvailableStrikesByMaturity(state, underlyingId, maturity, selectors);
  }
  return [];
}

function getAvailableStrikesByLegIdWithGivenMaturity(
  state: AppState,
  legId: string,
  maturity: string | undefined,
  selectors: Selectors,
): number[] {
  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  if (isFutureOptionProduct(product)) {
    const { underlyingId } = product;
    if (!underlyingId || !maturity) {
      return [];
    }
    return selectors.getUnderlyingStrikesByMaturityOnOptionOnFuture(state, underlyingId, maturity);
  }

  if (isSingleUnderlyingDerivativeProduct(product)) {
    const { underlyingId } = product;
    return selectors.getAvailableStrikesByMaturity(state, underlyingId, maturity, selectors);
  }
  return [];
}

function getAvailableStrikesForStrategy(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): AvailableStrikes[] {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  return legIds.map(legId => ({
    legId,
    strikes: selectors.getAvailableStrikesByLegId(state, legId, selectors),
  }));
}

function getAvailableStrikesForStrategyWithGivenMaturity(
  state: AppState,
  strategyId: string,
  maturity: string | undefined,
  selectors: Selectors,
): AvailableStrikes[] {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  return legIds.map(legId => ({
    legId,
    strikes: selectors.getAvailableStrikesByLegIdWithGivenMaturity(
      state,
      legId,
      maturity,
      selectors,
    ),
  }));
}

function getAvailableStrikesForSameStrikeStrategy(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): AvailableStrikes[] {
  const availableStrikes = selectors.getAvailableStrikesForStrategy(state, strategyId, selectors);
  const legIds = availableStrikes.map(({ legId }) => legId);
  const allDifferentStrikes: number[][] = availableStrikes
    .map(({ strikes }: AvailableStrikes) => strikes)
    .filter(strikes => strikes.length > 0);
  const strikesIntersection: number[] = intersection(...allDifferentStrikes);
  return buildAvailableStrikesForLegIdsWithGivenStrikes(legIds, strikesIntersection);
}

function getAvailableStrikesForSameStrikeStrategyWithGivenMaturity(
  state: AppState,
  strategyId: string,
  maturity: string | undefined,
  selectors: Selectors,
): AvailableStrikes[] {
  const availableStrikes = selectors.getAvailableStrikesForStrategyWithGivenMaturity(
    state,
    strategyId,
    maturity,
    selectors,
  );
  const legIds = availableStrikes.map(({ legId }) => legId);
  const allDifferentStrikes: number[][] = availableStrikes.map(
    ({ strikes }: AvailableStrikes) => strikes,
  );
  const strikesIntersection: number[] = intersection(...allDifferentStrikes);
  return buildAvailableStrikesForLegIdsWithGivenStrikes(legIds, strikesIntersection);
}

function getStrategyTypeDefaultSizeType(
  state: AppState,
  strategyType: StrategyType,
  selectors: Selectors,
): SizeType {
  const strategyDefinition = selectors.getStrategyDefinition(state.referenceData, strategyType);
  return strategyDefinition.legs[0].sizeType;
}

function buildAvailableStrikesForLegIdsWithGivenStrikes(
  legIds: string[],
  strikes: number[],
): AvailableStrikes[] {
  return legIds.map(legId => ({
    legId,
    strikes,
  }));
}

function isDeltaHedgingStrategy(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const strategyData = selectors.getStrategyData(appState, strategyId);
  return !isRfqStrategyData(strategyData);
}

function isDeltaHedgingStrategyAdjusted(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
) {
  const strategyData = selectors.getStrategyData(appState, strategyId);
  return strategyData.scope === 'DELTA_ADJUSTED';
}

function getStrategyFairPriceQuoteIds(state: AppState, strategyId: string): QuoteFairPriceId[] {
  const { legIds, quoteId, fairPriceId } = strategySelectors.getStrategyData(state, strategyId);
  return [
    { quoteId, fairPriceId },
    ...legIds.map(legId => {
      const { fairPriceId: fi, quoteId: qi } = strategySelectors.getLegData(state, legId);
      return {
        fairPriceId: fi,
        quoteId: qi,
      };
    }),
  ];
}

function getStrategyQuoteIds(state: AppState, strategyId: string): string[] {
  return getStrategyFairPriceQuoteIds(state, strategyId).map(({ quoteId }) => quoteId);
}

function getStrategyFairPriceIds(state: AppState, strategyId: string): string[] {
  return getStrategyFairPriceQuoteIds(state, strategyId)
    .map(({ fairPriceId }) => fairPriceId)
    .filter(isDefined);
}

function getStrategyProducts(state: AppState, strategyId: string, selectors: Selectors): Product[] {
  const productIds = selectors.getStrategyProductIds(state, strategyId, selectors);
  return productIds.map(productId => selectors.getProduct(state, productId));
}

function isSomeStrategyProductFlexOptions(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const allStrategyProducts = selectors.getStrategyProducts(state, strategyId, selectors);
  const isAllProductsFLexOptions = allStrategyProducts.some(
    product => isOptionProduct(product) && product.flex === 'FLEX',
  );
  return isAllProductsFLexOptions;
}

function onlyOneStrategyProductFlexOptions(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const allStrategyProducts = selectors.getStrategyProducts(state, strategyId, selectors);
  const onlyOneStrategyProductFlexOptions =
    allStrategyProducts.filter(product => isOptionProduct(product) && product.flex === 'FLEX')
      .length === 1;
  return onlyOneStrategyProductFlexOptions;
}

function getStrategyProductIds(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): string[] {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  return legIds.map(legId => selectors.getLegData(state, legId).productId);
}

function isStrategyFeatureMandatory(
  appState: AppState,
  { strategyId, type }: FeatureKey,
  selectors: Selectors,
): boolean {
  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  return selectors.isStrategyDefinitionFeatureMandatory(
    appState.referenceData,
    strategyType,
    type,
    selectors,
  );
}

function isStrategyFeatureMandatoryOrDefined(
  appState: AppState,
  featureKey: FeatureKey,
  selectors: Selectors,
): boolean {
  return (
    selectors.isStrategyFeatureMandatory(appState, featureKey, selectors) ||
    selectors.strategyHasFeature(appState.featureState, featureKey)
  );
}

function isStrategyCustomUnderlying(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  return selectors.isStrategyTypeCustomUnderlying(appState.referenceData, strategyType, selectors);
}

function getStrategyMasterLeg(state: AppState, strategyId: string, selectors: Selectors): LegData {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  const masterLeg = legIds
    .map(legId => selectors.getLegData(state, legId))
    .find(({ isMaster }) => isMaster);
  if (!masterLeg) {
    throw Error(`Strategy ${strategyId} has no master leg`);
  }

  return masterLeg;
}

function isCustomOptionsStrategy(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  return strategyType === 'CUSTOM_OPTIONS';
}

function getStrategyMasterProduct(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): Product {
  const { productId } = selectors.getStrategyMasterLeg(state, strategyId, selectors);
  return selectors.getProduct(state, productId);
}

function getUnderlyingOrRefIdOfStrategy(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): string | undefined {
  const { uuid: strategyLegId } = selectors.getStrategyMasterLeg(state, strategyId, selectors);
  return selectors.getUnderlyingOrRefIdOfLeg(state, strategyLegId, selectors);
}

function isMasterStrategyOtc(state: AppState, rfqId: string, selectors: Selectors): boolean {
  const { uuid } = selectors.getRfqMasterStrategy(state, rfqId);
  const masterProduct = selectors.getStrategyMasterProduct(state, uuid, selectors);
  return isOtcProduct(masterProduct);
}

function isOtcStrategy(state: AppState, strategyId: string, selectors: Selectors): boolean {
  const masterProduct = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  return isOtcProduct(masterProduct);
}

function isListedStrategy(state: AppState, strategyId: string, selectors: Selectors): boolean {
  const masterProduct = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  return isListedProduct(masterProduct);
}

function isValidListedStrategySize(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const stratLegsSizes = selectors.getStrategyLegsSizes(state, strategyId, selectors);
  return (
    isListedStrategy(state, strategyId, selectors) &&
    stratLegsSizes.every(legSize => Number.isInteger(legSize) || legSize === undefined)
  );
}

function isValidListedAllocExecStrategySize(
  state: AppState,
  executionKey: ExecutionKey,
  selectors: Selectors,
): boolean {
  const allocations = selectors.listedAllocationSelectors.selectObjects(
    state.listedAllocationState,
    { executionId: executionKey.executionId },
  );

  return allocations.every(
    ({ numberOfLots }) => Number.isInteger(numberOfLots) || numberOfLots === undefined,
  );
}

function isValidListedExecStrategySize(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const { legIds } = selectors.getStrategyData(appState, strategyId);
  const orders = legIds.map(legId => selectors.getOrderByLegId(appState.orderData, rfqId, legId));
  const isValidExecSize = (size: number | undefined) =>
    Number.isInteger(size) || size === undefined;
  return orders.every(orderData => isValidExecSize(orderData?.execSize?.numberOfLots));
}

function isValidStrategySize(state: AppState, strategyId: string, selectors: Selectors): boolean {
  return (
    isValidListedStrategySize(state, strategyId, selectors) ||
    isOtcStrategy(state, strategyId, selectors)
  );
}

function isListedExecutionStrategy(state: AppState, strategyId: string, selectors: Selectors) {
  const { scope } = selectors.getStrategyData(state, strategyId);
  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  return scope !== 'DELTA_ADJUSTED' && isListedExecutionProduct(product);
}

function isPrimaryStrategy(state: AppState, strategyId: string, selectors: Selectors) {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  return legIds.every(legId => selectors.isPrimaryLeg(state, legId, selectors));
}

function isOtcExecutionStrategy(state: AppState, strategyId: string, selectors: Selectors) {
  const { scope } = selectors.getStrategyData(state, strategyId);
  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  return scope !== 'DELTA_ADJUSTED' && isOtcExecutionProduct(product);
}

function getWarningMessage(product: Listed<Product>): string | undefined {
  if (product.sameProductIds) {
    return REFERENTIAL_MULTIPLE_CONTRACTS_WARNING;
  }
  if (!product.refId) {
    return REFERENTIAL_MISSING_CONTRACT_WARNING;
  }
}

export type ProductWarnings = {
  productId: string;
  warning: string | undefined;
}[];

function getStrategyWarnings(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ProductWarnings {
  const { getRfqData, getStrategyProducts, getStrategyData, getUnderlyingInfo } = selectors;

  const { status } = getRfqData(state, rfqId);
  if (status === 'NEW') {
    return [];
  }

  const { scope } = getStrategyData(state, strategyId);
  const products = getStrategyProducts(state, strategyId, selectors);

  return products.map(product => {
    const udlInfo =
      isSingleUnderlyingDerivativeProduct(product) && product.underlyingId
        ? getUnderlyingInfo(state, product.underlyingId)
        : undefined;
    const isProductFlex = isOptionLike(product) && product.flex === 'FLEX';
    const isStrategyDeltaExchangeOrAdjusted =
      scope === 'DELTA_EXCHANGE' || scope === 'DELTA_ADJUSTED';

    const hasWarningConditions =
      isListedProduct(product) &&
      (isFutureLikeProduct(product) ||
        isProductFlex ||
        scope === 'RFQ' ||
        (udlInfo?.type === 'INDEX' && isStrategyDeltaExchangeOrAdjusted));

    const warning = hasWarningConditions ? getWarningMessage(product) : undefined;

    return {
      productId: product.uuid,
      warning,
    };
  });
}

export function isFairPriceFuruteOrForwardPtmmm(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { strategyIds } = selectors.getRfqData(state, rfqId);

  return strategyIds.some(strategyId => {
    const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    return isFutureLikeProduct(product);
  });
}

export function areFairPricesDisplayed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status, strategyIds } = selectors.getRfqData(state, rfqId);
  const noFairPriceValues = !selectors.hasRfqFairPrices(state, rfqId, selectors);
  if (noFairPriceValues) {
    return false;
  }

  const legDetails = selectors.isLegDetailsToggleOn(state.ui, rfqId);
  const afterOrEqualPriced = orderOf(status).isAfterOrEqual('PRICED');
  const onlyEls = strategyIds.every(strategyId => {
    const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    return isElsProduct(product);
  });
  const isFairPriceFuruteOrForwardPtmmm = selectors.isFairPriceFuruteOrForwardPtmmm(
    state,
    rfqId,
    selectors,
  );

  return !(afterOrEqualPriced && onlyEls && !legDetails) || isFairPriceFuruteOrForwardPtmmm;
}

export function areElsPtmmmDisplayed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status, strategyIds } = selectors.getRfqData(state, rfqId);

  const afterOrEqualPriced = orderOf(status).isAfterOrEqual('PRICED');
  const atLeastOneEls = strategyIds.some(strategyId => {
    const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    return isElsProduct(product);
  });
  return afterOrEqualPriced && atLeastOneEls;
}

function getElsRefSpotInSwapCurrency(
  state: AppState,
  rfqId: string,
  strategyId: string,
): PriceWithUnit | undefined {
  return state.rfqDataState?.[rfqId].refSpotInSwapCurrency?.[strategyId];
}

function getRfqIdByStrategyId(state: AppState, strategyId: string): string | undefined {
  return Object.values(state.rfqDataState).find(rfqData => rfqData.strategyIds.includes(strategyId))
    ?.uuid;
}

function isRfqReadOnlyAtCurrentWorkflow(state: AppState, rfqId: string, selectors: Selectors) {
  const rfqMasterStrategy = selectors.getRfqMasterStrategy(state, rfqId);
  return selectors.isReadOnlyAtCurrentWorkflow(state, rfqMasterStrategy.uuid, selectors);
}

function isReadOnlyAtCurrentWorkflow(state: AppState, strategyId: string, selectors: Selectors) {
  const { rfqId } = selectors.getStrategyData(state, strategyId);
  const { status } = selectors.getRfqData(state, rfqId);
  const isSecondaryRfq = selectors.isSecondaryStrategy(state, strategyId, selectors);
  const readonlyStatuses: Status[] = ['TRADED', 'AMEND_COMPLETED', 'TRADE_TO_BE_COMPLETED'];

  if (isSecondaryRfq) {
    readonlyStatuses.push('AMEND_REQUESTED', 'TRADE_COMPLETED');
  }

  const isStatusInReadonly = readonlyStatuses.includes(status);

  return isStatusInReadonly && selectors.isOtcStrategy(state, strategyId, selectors);
}

function isSecondaryEditable(state: AppState, rfqId: string, selectors: Selectors) {
  const editableWorkflows: Status[] = ['AMEND_REQUESTED', 'TRADE_COMPLETED'];
  const status = selectors.getRfqData(state, rfqId).status;
  const isSecondary = selectors.isSecondaryRfq(state, rfqId);

  return isSecondary && editableWorkflows.includes(status);
}

function isVarVolDispersionStrategy(state: AppState, strategyId: string, selectors: Selectors) {
  const { strategyType } = selectors.getStrategyData(state, strategyId);
  return strategyType === 'VAR_DISPERSION' || strategyType === 'VOL_DISPERSION';
}

function isDividendSwapStrategy(state: AppState, strategyId: string, selectors: Selectors) {
  const { strategyType } = selectors.getStrategyData(state, strategyId);
  return strategyType === 'DIV_SWAP';
}
