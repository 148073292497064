import type { Selectors } from '@/bootstrap/selectors';
import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '../neosActions';
import type { NextUserAction } from '../nextUserActions/nextUserActionsModel';
import type { AppState } from '@/bootstrap/store.ts';

export function createRequestTransactionSaveThunk(
  rfqId: string,
  chainOptions?: ChainOptions,
): Thunk {
  return function requestTransactionSaveThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { createCancelNegociationRequestedAction },
      },
      thunks: {
        createErrorToasterThunk,
        neos: { createApplyNextUserActionThunk },
      },
      selectors,
    },
  ) {
    const { getRfqData } = selectors;
    const state = getState();
    const { status, version } = getRfqData(state, rfqId);

    if (status !== 'CANCELLED') {
      const selfNextUserAction = getSelfNextUserAction(rfqId, state, selectors);
      dispatch(createApplyNextUserActionThunk(rfqId, selfNextUserAction, chainOptions));
    } else if (version > 0) {
      dispatch(createCancelNegociationRequestedAction(rfqId));
    } else {
      dispatch(
        createErrorToasterThunk(
          {
            message: 'You are not allowed to cancel an unsaved RFQ',
          },
          undefined,
          false,
        ),
      );
    }
  };
}

function getSelfNextUserAction(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
): NextUserAction {
  const { nextActions } = selectors.getNextUserActions(state, rfqId);
  return nextActions.filter(({ action }) => action === 'self')[0];
}
