import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip.tsx';
import { Tooltip } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { neosActionCreators } from '@/neos/business/neosActionCreators.ts';
import type { CompositionLeg } from '@/neos/components/rfq/strategies/strategy/compositionLegs/getCompositionLegsModel.ts';
import { services, type Services } from '@/bootstrap/services.ts';
import { sum, uniq } from 'lodash';
import { isDefined } from '@/util/undefinedAndNull/isDefined.ts';

interface CompositionLegsWarningsProps {
  strategyId: string;
  compositionLegs: CompositionLeg[];
}

export function CompositionLegsWarnings({
  strategyId,
  compositionLegs,
}: CompositionLegsWarningsProps) {
  const dispatch = useAppDispatch();

  const stratUi = useAppSelector(state => selectors.getStrategyUi(state.ui, strategyId));
  const missingDataWarningMessage = stratUi?.displayMissingCompositionDataWarning
    ? 'Some underlyings or weights may be missing in the imported file!'
    : undefined;

  const displayCompoImportHasMultipleIndicesWarning =
    !!stratUi?.displayCompoImportHasMultipleIndicesWarning;

  const warningMessages = getWarningMessages(compositionLegs, services);

  function onCloseDataWarning() {
    dispatch(
      neosActionCreators.strategyUiCrudActions.update(strategyId, {
        displayMissingCompositionDataWarning: false,
      }),
    );
  }

  function onCloseIndexWarning() {
    dispatch(
      neosActionCreators.strategyUiCrudActions.update(strategyId, {
        displayCompoImportHasMultipleIndicesWarning: false,
      }),
    );
  }

  return (
    <>
      {missingDataWarningMessage && (
        <div className="alert alert-sm alert-outline-warning mb-1">
          <i className="icon icon-sm me-2">warning</i>
          {missingDataWarningMessage}
          <NeosTooltip
            placement="top"
            overlay={
              <Tooltip id="close-composition-warning" style={{ zIndex: 100000 }}>
                Close
              </Tooltip>
            }
          >
            <button
              className="btn btn-icon btn-flat-primary ms-auto text-warning"
              onClick={onCloseDataWarning}
            >
              <i className="icon icon-sm">clear</i>
            </button>
          </NeosTooltip>
        </div>
      )}
      {displayCompoImportHasMultipleIndicesWarning && (
        <div className="alert alert-sm alert-outline-warning mb-1">
          <i className="icon icon-sm me-2">warning</i>
          Imported index leg is ignored. File includes multiple index underlyings.
          <NeosTooltip
            placement="top"
            overlay={
              <Tooltip id="close-index-composition-warning" style={{ zIndex: 100000 }}>
                Close
              </Tooltip>
            }
          >
            <button
              className="btn btn-icon btn-flat-primary ms-auto text-warning"
              onClick={onCloseIndexWarning}
            >
              <i className="icon icon-sm">clear</i>
            </button>
          </NeosTooltip>
        </div>
      )}
      {warningMessages.map(message => {
        return (
          <div key={message} className="alert alert-sm alert-outline-warning mb-1">
            <i className="icon icon-sm me-2">warning</i>
            {message}
          </div>
        );
      })}
    </>
  );
}

function getWarningMessages(compositionLegs: CompositionLeg[], services: Services): string[] {
  const messages: string[] = [];

  getSumWarnings();

  getUnderlyingsWarnings();

  return messages;

  function getUnderlyingsWarnings() {
    const uniqBloombergCode = uniq(
      compositionLegs.map(compositionLeg => compositionLeg.bloombergCode),
    );

    uniqBloombergCode.forEach(bloombergCode => {
      if (bloombergCode) {
        const occurences = compositionLegs.filter(
          compositionLeg => compositionLeg.bloombergCode === bloombergCode,
        ).length;
        if (occurences > 1) {
          messages.push(`${bloombergCode} is present more than once, please check.`);
        }
      }
    });
  }

  function getSumWarnings() {
    const weightsTotal = services.applyMultiplierWithPrecision(
      sum(compositionLegs.map(leg => leg.weight).filter(isDefined)),
    );
    if (weightsTotal !== 100) {
      messages.push(
        `The sum of all weights is equal to ${weightsTotal}% whereas it should be to 100%.`,
      );
    }
  }
}
