import type { Selectors } from '@/bootstrap/selectors';
import type { PriceWithUnit } from '@/neos/business/rfq/rfqOnyxModel';
import {
  type AvailableStrikes,
  type Product,
  type UpperLowerStrikeProduct,
  isProductWithUpperLowerStrike,
} from '@/neos/business/rfq/strategy/strategyModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import type { AppState } from '@/bootstrap/store.ts';

interface CommonUpperStrikeCells {
  showAvailableStrikes: boolean;
  upperStrikes: Array<Field<PriceWithUnit | undefined>>;
}

interface UpperStrikeCellsWithAvailableStrikes extends CommonUpperStrikeCells {
  showAvailableStrikes: true;
  availableStrikes: AvailableStrikes[];
}

interface UpperStrikeCellsWithoutAvailableStrikes extends CommonUpperStrikeCells {
  showAvailableStrikes: false;
}

export type UpperStrikeCellsModel =
  | UpperStrikeCellsWithAvailableStrikes
  | UpperStrikeCellsWithoutAvailableStrikes;

export function getUpperStrikeCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): UpperStrikeCellsModel {
  const { getStrategyData, getStrategyProducts } = selectors;
  const { getStrategyDefinition } = selectors;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductStrike } = getStrategyDefinition(appState.referenceData, strategyType);

  const products = getStrategyProducts(appState, strategyId, selectors).filter(
    isProductWithUpperLowerStrike,
  );

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );

  const upperStrikesAreEditable =
    getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_EDITABLE',
      'STRATEGY_EDITABLE',
      'UNDERLYING_SET',
    ]) && !isReadonlyAtWorkflow;

  const upperStrikes = getUpperStrikes(products, sameProductStrike, upperStrikesAreEditable);

  const commonModel: Omit<CommonUpperStrikeCells, 'showAvailableStrikes'> = { upperStrikes };
  if (upperStrikes.every(strike => strike?.value?.unit === '%')) {
    return { ...commonModel, showAvailableStrikes: false };
  }

  const availableStrikes = sameProductStrike
    ? selectors.getAvailableStrikesForSameStrikeStrategy(appState, strategyId, selectors)
    : selectors.getAvailableStrikesForStrategy(appState, strategyId, selectors);
  return {
    ...commonModel,
    showAvailableStrikes: true,
    availableStrikes,
  };
}

function getUpperStrikes(
  products: Extract<Product, UpperLowerStrikeProduct>[],
  isSameApplicable: boolean,
  isEditable: boolean,
): Array<Field<PriceWithUnit | undefined>> {
  if (!products.length) {
    return [];
  }

  return isSameApplicable
    ? [
        {
          id: products[0].uuid,
          value: {
            value: products[0].upperStrike,
            unit: products[0].strikeUnit,
          },
          isEditable,
        },
      ]
    : products.map(product => ({
        id: product.uuid,
        value: {
          value: product.upperStrike,
          unit: product.strikeUnit,
        },
        isEditable,
      }));
}
