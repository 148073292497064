import { getResponse, type ResponseContentType } from '@/util/http/httpUtils';
import { commonTimeout } from '@/util/timeout';
import { type Observable, throwError, TimeoutError } from 'rxjs';
import type { AjaxConfig } from 'rxjs/ajax';
import { type AjaxResponse, AjaxTimeoutError } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import type { TestScheduler } from 'rxjs/testing';
import type {
  AsyncLogger,
  BaseRequestStatusLogParameters,
  LogLevels,
  NonTimeoutRequestStatusLogParameters,
  RequestStatusLogParameters,
} from '../logging/asyncLogger';
import { mt } from '../logging/messageTemplates';
import type { Ajax } from '@/util/http/sgmeHttpBase.ts';

export type SupportedVerb = 'POST' | 'DELETE' | 'PUT' | 'GET' | 'HEAD';

export interface AjaxCallParams {
  method: SupportedVerb;
  url: string;
  timeout?: number;
  headers?: object;
  shouldNotLog?: boolean;
  responseType?: ResponseType;
  responseContentType?: ResponseContentType;
  body?: any;
}

export interface BlobWithMetadata {
  blob: Blob;
  metadata: {
    fileName: string;
  };
}

export class SgmeAjaxHttpClient {
  constructor(
    public ajaxApi: Ajax,
    public buildHeaders: (headers?: object | undefined) => object,
    public logger: AsyncLogger | undefined,
    public testScheduler?: TestScheduler,
  ) {}

  public request = <T>(params: AjaxCallParams) => {
    return new SgmeAjaxHttpRequest(this, params).run<T>();
  };
}

class SgmeAjaxHttpRequest {
  private ajaxConfig: AjaxConfig;

  constructor(
    private client: SgmeAjaxHttpClient,
    private params: AjaxCallParams,
  ) {
    this.ajaxConfig = this.buildAjaxConfig(this.params);
  }

  public run = <T>(): Observable<T> => {
    const startTime = performance.now();
    return this.client.ajaxApi(this.ajaxConfig).pipe(
      map((ajaxResponse: AjaxResponse<any>): T => {
        const requestStatusLogParams = this.getRequestStatusLogParamsNonTimeoutLog(
          startTime,
          ajaxResponse,
        );
        this.logInfo({
          ...requestStatusLogParams,
        });

        const responseContentType = this.params.responseContentType ?? { contentType: 'JSON' };
        return getResponse(ajaxResponse, responseContentType);
      }),
      catchError(err => {
        if (err instanceof TimeoutError || err instanceof AjaxTimeoutError) {
          const requestStatusLogParams = this.getBaseLog(startTime);
          this.logError({
            ...requestStatusLogParams,
            isTimeout: true,
          });
          return throwError('A timeout has occurred');
        }
        const requestStatusLogParams = this.getRequestStatusLogParamsNonTimeoutLog(
          startTime,
          err as AjaxResponse<T>,
        );

        if (requestStatusLogParams.status! >= 500) {
          this.logError({ ...requestStatusLogParams });
        } else {
          this.logInfo({ ...requestStatusLogParams });
        }

        return throwError(err);
      }),
      catchError(err => {
        if (err.responseType) {
          err.responseType = 'json';
        }
        if (typeof err.response === 'string' && err.response !== '') {
          err.response = JSON.parse(err.response as string);
        }
        return throwError(err);
      }),
    );
  };

  private logInfo(params: RequestStatusLogParameters) {
    this.log(params, 'Information');
  }

  private logError(params: RequestStatusLogParameters) {
    this.log(params, 'Error');
  }

  private log = (params: RequestStatusLogParameters, level: LogLevels) => {
    // be careful param order matters in structured logging
    const commonLogParam = [
      params.path,
      params.isTimeout,
      params.method,
      params.duration,
      params.requestLength,
    ];
    if (this.params.shouldNotLog !== true && this.client.logger) {
      const logger = level === 'Error' ? this.client.logger.error : this.client.logger.info;
      if (params.isTimeout) {
        logger(mt.ajaxLog, ...commonLogParam);
      } else {
        logger(
          mt.ajaxLog,
          ...commonLogParam,
          params.responseLength,
          params.status,
          params.onyxBridgeDuration,
          params.frontWebApiDuration,
          -1,
        );
      }
    }
  };

  private buildAjaxConfig = (params: AjaxCallParams): AjaxConfig => {
    return {
      ...params,
      url: `${sgmeConfiguration.http.webApiHost}${params.url}`,
      body: params.body ? JSON.stringify(params.body) : undefined,
      headers: this.client.buildHeaders(params.headers),
      responseType: 'text',
      timeout: params.timeout ?? commonTimeout,
    };
  };

  private getBaseLog = (
    startTime: number,
  ): Omit<BaseRequestStatusLogParameters, 'content' | 'kind'> => {
    const { params, ajaxConfig } = this;
    const end = performance.now();
    const duration = Math.round(end - startTime);
    const requestLength = ajaxConfig.body?.length;
    return {
      duration,
      requestLength,
      method: params.method,
      path: params.url,
    };
  };

  private getRequestStatusLogParamsNonTimeoutLog = (
    startTime: number,
    ajaxResponse: AjaxResponse<any>,
  ): Omit<NonTimeoutRequestStatusLogParameters, 'content' | 'kind'> => {
    const xhr = ajaxResponse?.xhr;
    xhr.getAllResponseHeaders();
    const webApiDurationStr = xhr.getAllResponseHeaders().includes('x-sgme-webapi-request-duration')
      ? xhr.getResponseHeader('x-sgme-webapi-request-duration')
      : null;
    const onyxBridgeDurationStr = xhr
      .getAllResponseHeaders()
      .includes('x-sgme-onyxbridge-request-duration')
      ? xhr.getResponseHeader('x-sgme-onyxbridge-request-duration')
      : null;

    const base = this.getBaseLog(startTime);
    const totalDuration = base.duration;
    const webApiDuration = webApiDurationStr ? parseInt(webApiDurationStr) : undefined;
    const onyxBridgeDuration = onyxBridgeDurationStr ? parseInt(onyxBridgeDurationStr) : undefined;

    const frontWebApiDuration = webApiDuration ? totalDuration - webApiDuration : undefined;

    return {
      ...base,
      isTimeout: false,
      status: ajaxResponse?.status,
      responseLength: ajaxResponse?.response?.length,
      onyxBridgeDuration,
      frontWebApiDuration,
    };
  };
}
