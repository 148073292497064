import { combineReducers } from 'redux';
import { deltaNotificationChangesReducer } from './deltaNotificationChanges/deltaNotificationChangesReducer';
import { executionPriceNotificationChangesReducer } from './executionPriceNotificationChanges/executionPriceNotificationChangesReducer';
import { traderAskNotificationChangesReducer } from './traderAskNotificationChanges/traderAskNotificationChangesReducer';
import { traderBidNotificationChangesReducer } from './traderBidNotificationChanges/traderBidNotificationChangesReducer';

export const warningsReducer = combineReducers({
  deltaNotificationChanges: deltaNotificationChangesReducer,
  executionPriceNotificationChanges: executionPriceNotificationChangesReducer,
  traderAskNotificationChanges: traderAskNotificationChangesReducer,
  traderBidNotificationChanges: traderBidNotificationChangesReducer,
});
