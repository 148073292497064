import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { ImportButton } from '@/neos/components/share/importExportClipboard/ImportButton.tsx';

type ScheduleImportProps = {
  strategyId: string;
};

export const ELSScheduleImport = ({ strategyId }: ScheduleImportProps) => {
  const dispatch = useAppDispatch();

  function onImport(importedData: unknown[]) {
    dispatch(neosThunks.createImportScheduleLegsDataThunk(strategyId, importedData));
  }

  const isReadonlyAtWorfklow = useAppSelector(appState =>
    selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors),
  );

  return (
    <ImportButton
      handleImport={onImport}
      dataE2e="els-schedule-import"
      isDisabled={isReadonlyAtWorfklow}
    />
  );
};
