import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export function getIsPreConfWarningDisplayed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);

  const isPreconfirmationSent = selectors.selectIsPreconfirmationSent(state, rfqId, selectors);
  const initiatedByTrader = selectors.isRfqInitiatedByTrader(state, rfqId);
  const isElsSpotConfirmed = selectors.getIsElsSpotConfirmed(state, rfqId, selectors) === true;

  return (
    status === 'TRADE_TO_BE_COMPLETED' &&
    isElsSpotConfirmed &&
    !isPreconfirmationSent &&
    !initiatedByTrader
  );
}
