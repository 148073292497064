import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxTransaction } from '@/neos/business/neosOnyxModel';

import type { Action } from '@/bootstrap/actions.ts';

export function createLoadTransactionFirstStepThunk(
  transaction: OnyxTransaction,
  shouldDisplayTab: boolean,
): Thunk {
  return function loadTransactionFirstStepThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createTabAddedAction, createTabSelectedAction, createStrategySelectedAction },
      },
      thunks: {
        neos: { createUpsertReferencedRfqThunk },
      },
    },
  ) {
    const rfqId = transaction.rfq.uuid;
    const state = getState();
    const preActions: Action[] = [];

    if (shouldDisplayTab) {
      const tabAlreadyExists = selectors.tabExists(state.ui.tabManagement, rfqId);
      if (!tabAlreadyExists) {
        preActions.push(createTabAddedAction(rfqId));
        dispatch(createUpsertReferencedRfqThunk(rfqId, rfqId));
      } else {
        preActions.push(createTabSelectedAction(rfqId));
      }
    }

    const selectedStrategyId = selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId);
    if (!transaction.rfq.strategies.find(s => s.uuid === selectedStrategyId)) {
      preActions.push(createStrategySelectedAction(rfqId, transaction.rfq.strategies[0].uuid));
    }

    if (preActions.length) {
      dispatch(preActions);
    }
  };
}
