import type { FromOnyxMappers } from '@/neos/business/mappers';
import { executionActionCreators } from './execution/executionActionCreators';
import { orderActionDataCreators } from './orderData/orderDataActionCreators';
import type { OnyxOrder } from './orderOnyxModel';
import type { Action } from '@/bootstrap/actions.ts';
import type { Dispatchable } from '@/bootstrap/thunks.ts';

export const orderActionCreators = {
  ...orderActionDataCreators,
  ...executionActionCreators,
  createOrdersReceived,
  createOrdersDeleted,
};

function createOrdersReceived(
  rfqId: string,
  onyxOrders: OnyxOrder[],
  mappers: FromOnyxMappers,
): Dispatchable[] {
  const executionsInsertionActions = onyxOrders.map(
    ({ uuid, clientOrderUuid: legId, executions }) =>
      mappers
        .mapFromOnyxExecutions(executions)
        .map(execution =>
          executionActionCreators.executionCrudActions.patchOrInsert(
            { rfqId, legId, executionId: execution.uuid, orderId: uuid },
            execution,
          ),
        ),
  );
  const removeOldExecutionsForOnyxOrders = onyxOrders.map(onyxOrder =>
    executionActionCreators.executionCrudActions.deleteByPartialKey({
      rfqId,
      orderId: onyxOrder.uuid,
    }),
  );
  return [
    orderActionDataCreators.createOrdersDataReceived(rfqId, mappers.mapFromOnyxOrders(onyxOrders)),
    removeOldExecutionsForOnyxOrders,
    executionsInsertionActions,
  ];
}

function createOrdersDeleted(rfqId: string): Action[] {
  return [
    orderActionDataCreators.createOrdersDataDeleted(rfqId),
    executionActionCreators.executionCrudActions.deleteByPartialKey({ rfqId }),
  ];
}
