import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';

import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';

export function PrimaryBookingId({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const currentAllocation = useAppSelector(state =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const primaryBookingId = currentAllocation?.secondaryInfo?.primaryBookingId;

  return (
    <NeosBookingId
      onBookingIdChanged={null}
      bookingId={primaryBookingId?.id}
      dataE2e="primary-booking-id"
      isReadOnly
    />
  );
}
