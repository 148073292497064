import type { Selectors } from '@/bootstrap/selectors';
import type { Quote } from '@/neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export type PtmmPriceModel = {
  quoteId: string;
  preTradeMidMarketMarkPrice: Quote['preTradeMidMarketMarkPrice'];
  preTradeMidMarketMarkPriceUnit: Quote['preTradeMidMarketMarkPriceUnit'];
} | null;

export function getPtmmPriceModel(
  state: AppState,
  legId: string,
  selectors: Selectors,
): PtmmPriceModel {
  const isOtc = selectors.isOtcLeg(state, legId, selectors);
  if (!isOtc) {
    return null;
  }

  const quoteId = selectors.getLegData(state, legId).quoteId;
  const { preTradeMidMarketMarkPrice, preTradeMidMarketMarkPriceUnit } = selectors.getQuote(
    state,
    quoteId,
  );

  return {
    quoteId,
    preTradeMidMarketMarkPrice,
    preTradeMidMarketMarkPriceUnit,
  };
}
