import type { Selectors } from '@/bootstrap/selectors';
import { internalGetErrorId } from '@/neos/business/mappers/error/internal/errorIdGenerator';
import type { UnderlyingType } from '../../../../../../../business/neosOnyxModel';
import { getIsStrategyDefinitionFieldEditable } from '../../utils/fieldsEnablingConditions';
import type { AppState } from '@/bootstrap/store.ts';

interface CommonVolVarDispersionUnderlyingActionsModel {
  displayActions: boolean;
  displayErrorHighlight: boolean;
}

interface VolVarDispersionUnderlyingActionsWithoutTop
  extends CommonVolVarDispersionUnderlyingActionsModel {
  displayActions: false;
}

interface VolVarDispersionUnderlyingActionsWithTop
  extends CommonVolVarDispersionUnderlyingActionsModel {
  displayActions: true;
  disabled: boolean;
  top: number | undefined;
  isCustomMode: boolean;
  underlyingId: string | undefined;
  bloombergCode: string | undefined;
  underlyingTypes: UnderlyingType[];
}

export type VolVarDispersionUnderlyingActionsModel =
  | VolVarDispersionUnderlyingActionsWithoutTop
  | VolVarDispersionUnderlyingActionsWithTop;

export function getVolVarDispersionUnderlyingActionsModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): VolVarDispersionUnderlyingActionsModel {
  const aggregationEnabled = selectors.isFeatureToggleEnabled(state, 'neos.rfq.dispersion.enabled');
  const hasACompositionLeg =
    aggregationEnabled && selectors.hasACompositionLeg(state, strategyId, selectors);

  if (!hasACompositionLeg) {
    return { displayActions: false, displayErrorHighlight: false };
  }

  const { top, underlyingId, strategyType } = selectors.getStrategyData(state, strategyId);

  const bloombergCode = underlyingId
    ? selectors.getUnderlyingInfo(state, underlyingId)?.bloombergCode
    : undefined;

  const { legs } = selectors.getStrategyDefinition(state.referenceData, strategyType);
  const indexLeg = legs.find(leg => leg.master);
  const indexLegUnderlyingTypes = indexLeg?.availableProductUnderlyingTypes ?? [];

  const disabled = !getIsStrategyDefinitionFieldEditable(state, rfqId, strategyId, selectors, [
    'RFQ_EDITABLE',
    'STRATEGY_EDITABLE',
    'RFQ_SCOPE',
  ]);

  const compositionLegIds = selectors
    .getStrategyCompositionLegsData(state, strategyId, selectors)
    .map(({ uuid }) => uuid);

  const displayErrorHighlight = compositionLegIds.some(
    legId =>
      selectors.getNeosError(
        state.ui,
        internalGetErrorId('underlying')({
          rfqId,
          legId,
          strategyId,
        }),
      ).isError,
  );

  return {
    displayActions: true,
    disabled,
    top,
    isCustomMode: !underlyingId,
    underlyingId,
    bloombergCode,
    underlyingTypes: indexLegUnderlyingTypes,
    displayErrorHighlight,
  };
}
