import type { NegotiationMode } from '../../../../../neos/business/neosModel';
import type { Preset } from '../presets';
import type { Shortcut, ShortcutButtonDispatchProps } from '../shared/ShortcutButton';
import { ShortcutButton } from '../shared/ShortcutButton.container';
import { ShortcutsTable } from '../shared/ShortcutsTable';

export type NegotiationModeShortcutsOwnProps = {
  rfqId: string;
} & ShortcutButtonDispatchProps<NegotiationMode>;

export interface NegotiationModeShortcutsStateProps {
  otcDisabled: boolean;
  listedDisabled: boolean;
}

export type Props = NegotiationModeShortcutsOwnProps &
  NegotiationModeShortcutsStateProps &
  ShortcutButtonDispatchProps<NegotiationMode>;

export const NegotiationModeShortcutsComponent = (props: Props) => {
  const shortcuts: Preset<NegotiationMode> = [
    {
      label: 'Listed',
      id: 'LISTED',
      isHidden: props.listedDisabled,
      ['data-e2e']: 'neos-rfq-shortcuts-negociation-mode-listed',
    },
    {
      label: 'OTC',
      id: 'OTC',
      isHidden: props.otcDisabled,
      ['data-e2e']: 'neos-rfq-shortcuts-negociation-mode-otc',
    },
  ];

  if (shortcuts.every(shortcut => shortcut.isHidden)) {
    return null;
  }

  return (
    <ShortcutsTable>
      {shortcuts.map((shortcut: Shortcut<NegotiationMode>) => (
        <ShortcutButton
          key={shortcut.id}
          shortcut={shortcut}
          onShortcutSelected={props.onShortcutSelected}
          onShortcutLongPress={props.onShortcutLongPress}
        />
      ))}
    </ShortcutsTable>
  );
};
