import { useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { dateStringToIsoString, formatDateInTimeZone } from '@/util/date/dateFormatHelper.ts';

export interface TradeDateProps {
  rfqId: string;
}

export function TradeDate({ rfqId }: TradeDateProps) {
  const { tradeDate } = useAppSelector(state => selectors.getRfqData(state, rfqId));
  const displayTimezone = useAppSelector(state =>
    selectors.getDisplayTimeZone(state.ui.userPreferences),
  );
  const tradeDateToShow = tradeDate
    ? formatDateInTimeZone(displayTimezone, dateStringToIsoString(tradeDate))
    : 'N/A';

  return (
    <div className="d-flex flex-nowrap align-items-center">
      <label className="fw-bold me-2">Trade Date</label>
      <span className="text-nowrap">{tradeDateToShow}</span>
    </div>
  );
}
