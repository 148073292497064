import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ElsSectionName } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import { createUpdateElsSectionHeightThunk } from '@/neos/business/rfq/strategy/feature/elsSections/updateElsSectionHeightThunk';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { gridUnitHeight } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures.tsx';
import { debounce } from 'lodash';

const getStandardHeight = (height: number) => Math.floor(height / (gridUnitHeight + 5)) + 2;

export const useUpdateSectionHeight = (strategyId: string, sectionName: ElsSectionName) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const resize = useCallback(
    (height: number) => {
      dispatch(createUpdateElsSectionHeightThunk(height, strategyId, sectionName));
    },
    [dispatch, strategyId, sectionName],
  );
  const section = useAppSelector(state =>
    selectors
      .getFeatureToggledElsSections(state, strategyId, selectors)
      .find(s => s.i === sectionName),
  );

  useEffect(() => {
    const currentContent = ref.current;
    if (currentContent == null) {
      return;
    }
    const observer = new ResizeObserver(
      debounce(
        entries => {
          for (const entry of entries) {
            const { height } = entry.contentRect;
            const standardContentHeight = getStandardHeight(height as number);
            const standardContainerHeight = section?.h ? getStandardHeight(section.h) : undefined;

            if (standardContentHeight === standardContainerHeight) {
              return;
            }
            resize(standardContentHeight);
          }
        },
        200,
        { trailing: true, leading: false },
      ),
    );
    observer.observe(currentContent);

    return () => observer.unobserve(currentContent);
  }, [resize, section?.h]);

  return { ref };
};
