import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { MAX_BLOTTER_RFQS_TO_OPEN } from '@/neos/components/blotter/grid/gridStaticOptions';
import { blotterModelGetters } from '@/neos/components/share/blotterGrid';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import {
  type SimilarActivitiesGridDispatchProps,
  type SimilarActivitiesGridOwnProps,
  type SimilarActivitiesGridStateProps,
  SimilarActivitiesGridWithStyle,
} from './SimilarActivitiesGrid';
import { getSimilarActivitiesGridModel } from './getSimilarActivitiesGridModel';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(
  state: AppState,
  { rfqId }: SimilarActivitiesGridOwnProps,
): SimilarActivitiesGridStateProps {
  return {
    model: getSimilarActivitiesGridModel(state, rfqId, selectors, blotterModelGetters),
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): SimilarActivitiesGridDispatchProps {
  return {
    displayNotManagedRfqError: () =>
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: 'Strategy type not yet supported by SGME',
          },
          undefined,
          false,
        ),
      ),
    loadLatestTransactions: (rfqIds: string[]) => {
      if (rfqIds.length > MAX_BLOTTER_RFQS_TO_OPEN) {
        dispatch(
          thunks.createWarningToasterThunk({
            message: `Too many RFQs selected at once. Please open no more than ${MAX_BLOTTER_RFQS_TO_OPEN}.`,
          }),
        );
        return;
      }
      dispatch(neosActionCreators.bulkLoadTransactions(rfqIds));
    },
  };
}

export const SimilarActivitiesGrid = connect(mapStateToProps, mapDispatchToProps, undefined, {
  forwardRef: true,
})(forwardRef(SimilarActivitiesGridWithStyle));
