import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import type { SubfilterType } from '@/neos/business/blotter/blotterModel';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  BlotterSubfilterComponent,
  type BlotterSubfilterDispatchProps,
  type BlotterSubfilterStateProps,
} from './BlotterSubfilter';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(appState: AppState): BlotterSubfilterStateProps {
  const currentSubfilter = selectors.getBlotterRfqSubfilter(appState.blotter);
  return {
    currentSubfilter,
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): BlotterSubfilterDispatchProps {
  return {
    changeBlotterSubfilter: (subfilter: SubfilterType) =>
      dispatch(neosActionCreators.changeBlotterSubfilter(subfilter)),
  };
}

export const BlotterSubfilter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlotterSubfilterComponent);
