import type { Thunk } from '@/bootstrap/thunks';

import type { Action } from '@/bootstrap/actions.ts';

export function createFinalizeTransactionsLoadThunk(
  rfqIds: string[],
  shouldDisplayTab: boolean,
): Thunk {
  return function finalizeTransactionsLoadThunk(
    dispatch,
    getState,
    {
      selectors: { getActorsSetup, getRfqData, getSalesValo },
      actionCreators: {
        neos: { createLoadVersionsRequestedAction, createContactsRequestedAction },
      },
      thunks: {
        neos: {
          createErrorsRemovedThunk,
          createFinalizeRfqsMissingUnderlyingsThunk,
          createEnsureUniqueCounterpartsLoadedThunk,
          createAutoSelectDeltaAdjOrRefTabThunk,
          createFetchCurvesListBasedOnCurrencyThunk,
        },
      },
    },
  ) {
    const state = getState();
    const postActions: Action[] = [];
    for (const rfqId of rfqIds) {
      const actorsSetup = getActorsSetup(state, rfqId)!;

      postActions.push(createLoadVersionsRequestedAction(rfqId));

      if (actorsSetup?.salesValoId && actorsSetup.counterpartId) {
        const salesUser = getSalesValo(state.referenceData, actorsSetup.salesValoId);

        if (salesUser) {
          postActions.push(
            createContactsRequestedAction(rfqId, actorsSetup.counterpartId, salesUser.email),
          );
        }
      }
      const { strategyIds } = getRfqData(state, rfqId);
      dispatch(
        createErrorsRemovedThunk(rfqId),
        createAutoSelectDeltaAdjOrRefTabThunk(rfqId),
        createFetchCurvesListBasedOnCurrencyThunk(rfqId, strategyIds),
      );
    }

    dispatch(
      createEnsureUniqueCounterpartsLoadedThunk(rfqIds),
      createFinalizeRfqsMissingUnderlyingsThunk(rfqIds, shouldDisplayTab),
      postActions,
    );
  };
}
