import { ExportButton } from '@/neos/components/share/importExportClipboard/ExportButton.tsx';
import { selectors } from '@/bootstrap/selectors.ts';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { thunks } from '@/bootstrap/thunks.ts';

type ScheduleExportProps = {
  strategyId: string;
};

export const ELSScheduleExport = ({ strategyId }: ScheduleExportProps) => {
  const dispatch = useAppDispatch();
  const equityData = useAppSelector(state =>
    selectors.selectScheduleEquityLegDates(state, strategyId, selectors),
  );
  const rateData = useAppSelector(state =>
    selectors.selectScheduleRateLegDates(state, strategyId, selectors),
  );
  const dividendData = useAppSelector(state =>
    selectors.selectScheduleDividendLegDates(state, strategyId, selectors),
  );
  const isButtonDisabled = !rateData?.length && !equityData?.length && !dividendData?.length;

  const handleExport = () => {
    dispatch(
      thunks.neos.createElsExportScheduleThunk(strategyId, equityData, rateData, dividendData),
    );
  };

  return (
    <ExportButton
      handleExport={handleExport}
      isDisabled={isButtonDisabled}
      dataE2e="els-schedule-export"
    />
  );
};
