import type { Selectors } from '@/bootstrap/selectors';
import { getMaturitiesFieldsArray } from '../../components/rfq/strategies/strategy/strategyDefinition/maturityCells/modelGetters/getMaturitiesFieldsArray';
import type { Field } from '../../components/rfq/strategies/strategy/strategyDefinition/utils/Field';
import type { NegotiationMode } from '../neosModel';
import { elsSectionsSelectors } from '../rfq/strategy/feature/elsSections/elsSectionsSelectors';
import { blotterUiSelectors } from './blotter/blotterUiSelectors';
import type { NeosError } from './error/errorModel';
import { headerMenuSelectors } from './headerMenu/headerMenuSelectors';
import { preconfirmationUserPrefSelectors } from './prefonfirmationPreferences/preconfirmationPreferencesSelectors';
import type { ReferenceUi } from './reference/referenceUiModel';
import { referenceUiSelectors } from './reference/referenceUiSelectors';
import type { ReferencedRfqIds } from './referencedRfq/referencedRfqModel';
import type { NegoStep, RfqOutdatedModal } from './rfq/rfqUiModel';
import { getSelectedStrategyId } from './selectedStrategy/selectedStrategySelectors';
import type { DisplayNegotiatedSize, StrategyUi } from './strategy/strategyUiModel';
import { tabSelectors } from './tab/tabSelectors';
import type { UiState } from './uiState';
import { underlyingUiDomain } from './underlying/underlyingUiDomain';
import type { ColumnsDisplayToggle } from './userPreferences/userPreferencesUiModel';
import { userPreferencesSelectors } from './userPreferences/userPreferencesUiSelectors';
import type { AppState } from '@/bootstrap/store.ts';
import { selectIsUserDraggingFile } from '@/neos/business/ui/isUserDraggingFile/isUserDraggingFileSlice.ts';

export const uiSelectors = {
  selectIsUserDraggingFile,
  ...headerMenuSelectors,
  ...tabSelectors,
  ...userPreferencesSelectors,
  ...blotterUiSelectors,
  getUiShowTraderWarningModal,
  isDividedView,
  getSelectedStrategyId,
  getNeosError,
  getNeosErrorIds,
  isRfqLoading,
  isRfqWaitingForSecondarySelection,
  isShowLegPrices,
  getLegUnwindType,
  isRfqSecondaryWidgetToggleOn,
  getAllMaturitiesFieldsArray,
  isLegDetailsToggleOn,
  getRfqOutdatedModalState,
  isShowTradeRecapModal,
  isLegOverWarningDismissed,
  isReverseWeightsMessageDismissed,
  getExtraFeaturesModalState,
  getUiSelectedPredealCheckId,
  getUiShouldDisplayStratLink,
  areDeltaPredealChecksCollapsed,
  areMarketPredealChecksCollapsed,
  isPredealChecksModalShown,
  areSimilarActivitiesDisplayed,
  isMarkupModalShown,
  getUiStrategies,
  isUsualFairPricesDisplayed,
  isOtherFairGreeksDisplayed,
  isTraderDeltaDisplayed,
  areSalesPricesDisplayed,
  getDisplayNegotiatedSize,
  getSelectedLegId,
  isRfqSubscribedToNotification,
  getIbChatNegotiationMode,
  getStrategyUi,
  getWasSetToMixedDelta,
  getInitialDeltaSum,
  getRfqUnderlyingUiKeys,
  isDeltaAdjustedSelected,
  isQuoteTimerEditable,
  isVolBidAskDisplayed,
  getRfqSimilarActivitiesGridHeight,
  getSelectedPivotNotionalCurrency,
  isBlotterVisible,
  getSelectedNegoStep,
  isPostNegoStepAlreadyAutomaticallySet,
  getReferenceUiByRfqIdAndUnderlyingId,
  getIbChat,
  getIbChatTrustLevel,
  getPreconfIgnoreChangesModalInfo,
  getIsPreconfirmationModalOpened,
  getIsPreconfirmationButtonsDisabled,
  getReferencedRfqIds,
  getAllocErrors,
  getUiStrategy,
  isRefSpotChangedByTrader,
  getOldRefSpotValue,
  ...preconfirmationUserPrefSelectors,
  ...elsSectionsSelectors,
  isRfqDifferencesLoading,
};

function getNeosError(state: UiState, errorId: string): NeosError {
  return state.error[errorId] ? state.error[errorId] : { isError: false, errorMessages: [] };
}

function getStrategyUi(state: UiState, strategyId: string): StrategyUi | undefined {
  return state.strategy[strategyId];
}

function getDisplayNegotiatedSize(state: UiState, strategyId: string): DisplayNegotiatedSize {
  return state.strategy[strategyId].displayedSizeType;
}

function getNeosErrorIds(state: UiState): string[] {
  return Object.keys(state.error);
}

function isRfqLoading(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] && state.rfq[rfqId].isLoading === true;
}

function isRfqWaitingForSecondarySelection(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] && state.rfq[rfqId].isRfqWaitingForSecondarySelection === true;
}

function isRfqSubscribedToNotification(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] && state.rfq[rfqId].isSubscribedToNotification === true;
}

function isLegDetailsToggleOn(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] && state.rfq[rfqId].showLegDetails === true;
}

function isRefSpotChangedByTrader(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] && state.rfq[rfqId].isRefSpotChangedByTrader === true;
}

function getOldRefSpotValue(state: UiState, rfqId: string): number | undefined {
  return state.rfq[rfqId].oldRefSpotValue;
}

function isShowLegPrices(state: UiState, strategyId: string): boolean {
  return state.strategy[strategyId].showLegPrices === true;
}

function isLegOverWarningDismissed(state: UiState, strategyId: string): boolean {
  return state.strategy[strategyId].legOverMessageDismissed === true;
}

function getRfqOutdatedModalState(state: UiState, rfqId: string): RfqOutdatedModal {
  return (state.rfq[rfqId] && state.rfq[rfqId].rfqOutdatedModal) || { action: 'HIDE' };
}

function isShowTradeRecapModal(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] && state.rfq[rfqId].showTradeRecapModal === true;
}

function areSimilarActivitiesDisplayed(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId]?.areSimilarActivitiesDisplayed === true;
}

function isReverseWeightsMessageDismissed(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] && state.rfq[rfqId].reverseWeightsMessageDismissed === true;
}

function getExtraFeaturesModalState(state: UiState, strategyId: string) {
  return state.strategy[strategyId].extraFeaturesModal || 'HIDE';
}

function getUiSelectedPredealCheckId(state: UiState, rfqId: string): string | undefined {
  return state.rfq[rfqId] ? state.rfq[rfqId].selectedPredealCheckId : undefined;
}

function getUiShouldDisplayStratLink(state: UiState, rfqId: string): boolean | undefined {
  return state.rfq[rfqId] ? state.rfq[rfqId]?.shouldDisplayStrategyLink : false;
}

function getUiShowTraderWarningModal(state: UiState, rfqId: string): boolean {
  return !!(state.rfq[rfqId] && state.rfq[rfqId]?.showWarningModal);
}

function areDeltaPredealChecksCollapsed(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] ? state.rfq[rfqId].areDeltaPredealChecksCollapsed !== false : true;
}

function areMarketPredealChecksCollapsed(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] ? state.rfq[rfqId].areMarketPredealChecksCollapsed !== false : true;
}

function isPredealChecksModalShown(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] ? !!state.rfq[rfqId].showPredealChecksModal : false;
}

function isMarkupModalShown(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] ? !!state.rfq[rfqId].isMarkupModalShown : false;
}

function getSelectedLegId(state: UiState, rfqId: string): string | undefined {
  return state.rfq[rfqId] && state.rfq[rfqId].selectedLegId;
}

function getIbChat(state: UiState, rfqId: string): string | undefined {
  return state.rfq[rfqId]?.ibChat;
}

function getIbChatTrustLevel(state: UiState, rfqId: string): number | undefined {
  return state.rfq[rfqId]?.ibChatTrustLevel;
}

function getUiStrategies(state: UiState, strategyIds: string[]): Array<StrategyUi> {
  return strategyIds.map(strategyId => state.strategy[strategyId]);
}

function getUiStrategy(state: UiState, strategyId: string): StrategyUi | undefined {
  return state.strategy[strategyId];
}

function getSelectedNegoStep(state: UiState, rfqId: string): NegoStep {
  return state.rfq[rfqId]?.selectedNegoStep ?? 'NEGO';
}

function isPostNegoStepAlreadyAutomaticallySet(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId]?.isPostNegoStepAlreadyAutomaticallySet ?? false;
}

function isUsualFairPricesDisplayed(
  fairPricesToggle: ColumnsDisplayToggle,
  state: UiState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const isFairPricesToggleOn =
    fairPricesToggle === 'ON_TOGGLE' && selectors.isLegDetailsToggleOn(state, rfqId);
  const isFairPricesAlwaysDisplayed = fairPricesToggle === 'DISPLAYED';

  return isFairPricesToggleOn || isFairPricesAlwaysDisplayed;
}

function isOtherFairGreeksDisplayed(
  fairGreeksToggle: ColumnsDisplayToggle,
  state: UiState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const isFairGreeksToggleOn =
    fairGreeksToggle === 'ON_TOGGLE' && selectors.isLegDetailsToggleOn(state, rfqId);
  const isFairGreeksAlwaysDisplayed = fairGreeksToggle === 'DISPLAYED';

  return isFairGreeksToggleOn || isFairGreeksAlwaysDisplayed;
}

function isTraderDeltaDisplayed(
  traderDeltaToggle: ColumnsDisplayToggle,
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const strategyId = selectors.getRfqMasterStrategy(state, rfqId).uuid;
  const isElsStrategy = selectors.isElsStrategy(state, strategyId, selectors);
  const isTraderDeltaToggleOn =
    traderDeltaToggle === 'ON_TOGGLE' && selectors.isLegDetailsToggleOn(state.ui, rfqId);
  const isTraderDeltaAlwaysDisplayed = traderDeltaToggle === 'DISPLAYED';

  return !isElsStrategy && (isTraderDeltaToggleOn || isTraderDeltaAlwaysDisplayed);
}

function isVolBidAskDisplayed(
  volBidAskToggle: ColumnsDisplayToggle,
  state: UiState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const isVolBidAskToggleOn =
    volBidAskToggle === 'ON_TOGGLE' && selectors.isLegDetailsToggleOn(state, rfqId);
  const isVolBidAskAlwaysDisplayed = volBidAskToggle === 'DISPLAYED';

  return isVolBidAskToggleOn || isVolBidAskAlwaysDisplayed;
}

function areSalesPricesDisplayed(state: UiState, rfqId: string, selectors: Selectors): boolean {
  const { salesPricesToggle } = state.userPreferences;
  const areSalesPricesToggleOn =
    salesPricesToggle === 'ON_TOGGLE' && selectors.isLegDetailsToggleOn(state, rfqId);
  const areSalesPricesAlwaysDisplayed = salesPricesToggle === 'DISPLAYED';

  return areSalesPricesToggleOn || areSalesPricesAlwaysDisplayed;
}

function getIbChatNegotiationMode(state: UiState, rfqId: string): NegotiationMode {
  return state.rfq[rfqId]?.ibChatNegotiationMode === 'OTC' ? 'OTC' : 'LISTED';
}

function isDeltaAdjustedSelected(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId]?.isDeltaAdjustedSelected === true;
}

function isQuoteTimerEditable(state: UiState, rfqId: string): boolean {
  return !!state.rfq[rfqId]?.isQuoteTimerEditionEnable;
}

function getWasSetToMixedDelta(
  state: UiState,
  rfqId: string,
  underlyingId: string,
): boolean | undefined {
  return underlyingUiDomain.selectors.find(state.underlying, { rfqId, underlyingId })
    ?.wasSetToMixedDelta;
}

function getInitialDeltaSum(
  state: UiState,
  rfqId: string,
  underlyingId: string,
): number | undefined {
  return underlyingUiDomain.selectors.find(state.underlying, { rfqId, underlyingId })
    ?.initialDeltaSum;
}

function getRfqUnderlyingUiKeys(state: UiState, rfqId: string) {
  return underlyingUiDomain.selectors.selectKeys(state.underlying, { rfqId });
}

function getRfqSimilarActivitiesGridHeight(
  state: UiState,
  rfqId: string,
  defaultValue: number = 225,
): number {
  return state.rfq[rfqId]?.similarActivitiesHeight ?? defaultValue;
}

function getSelectedPivotNotionalCurrency(state: UiState, rfqId: string): string | undefined {
  return state.rfq[rfqId]?.selectedPivotNotionalCurrency;
}

function isBlotterVisible(state: UiState, n: Selectors) {
  return n.getSelectedTab(state.tabManagement) === 'BLOTTER' || n.isBlotterPinned(state.blotter);
}

function isDividedView(state: UiState, n: Selectors) {
  const isBlotterPinned = n.isBlotterPinned(state.blotter);
  const selectedTab = n.getSelectedTab(state.tabManagement);
  return isBlotterPinned && selectedTab !== 'BLOTTER';
}

function getReferenceUiByRfqIdAndUnderlyingId(
  state: UiState,
  rfqId: string,
  underlyingId: string,
): ReferenceUi | undefined {
  return referenceUiSelectors.selectObjects(state.reference, { rfqId, underlyingId })[0];
}

function getPreconfIgnoreChangesModalInfo(state: UiState, rfqId: string) {
  return state.rfq[rfqId]?.preconfIgnoreChangesModalInfo;
}

function getIsPreconfirmationModalOpened(state: UiState, rfqId: string) {
  return !!state.rfq[rfqId]?.isPreconfirmationModalOpened;
}

function getIsPreconfirmationButtonsDisabled(state: UiState, rfqId: string) {
  return !!state.rfq[rfqId]?.isPreconfirmationButtonsDisabled;
}

function getLegUnwindType(state: UiState, legId: string) {
  return state.leg[legId]?.secondaryType;
}

function getAllocErrors(state: UiState, allocId: string) {
  return state.allocation[allocId]?.errors;
}

function isRfqSecondaryWidgetToggleOn(state: UiState, rfqId: string) {
  return state.rfq[rfqId]?.isRfqSecondaryWidgetToggleOn ?? false;
}

function getAllMaturitiesFieldsArray(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): Array<Field<string | undefined> & { isValid: boolean }> {
  return getMaturitiesFieldsArray(appState, rfqId, strategyId, selectors);
}

function getReferencedRfqIds(state: UiState, rfqId: string): ReferencedRfqIds | undefined {
  return state.referencedRfq[rfqId];
}

function isRfqDifferencesLoading(state: UiState, rfqId: string): boolean {
  return state.rfq[rfqId] && !!state.rfq[rfqId].isRfqDifferencesLoading;
}
