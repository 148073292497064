import type { Selectors } from '@/bootstrap/selectors';
import type { ExecutionKey } from '@/neos/business/order/orderModel';
import { orderOf } from '@/neos/business/services/statusOrder';
import {
  isListedExecutionProduct,
  isStockProduct,
} from '../../../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface ListedExecutionsModel {
  orderId: string | undefined;
  executionKeys: ExecutionKey[];
  isReadOnly: boolean;
  isManual: boolean;
}

export function getListedExecutionsModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  legId: string,
  selectors: Selectors,
): ListedExecutionsModel {
  const { status } = selectors.getRfqData(state, rfqId);

  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  if (!isListedExecutionProduct(product) && !isStockProduct(product)) {
    throw new Error('Leg Description component only supports eligible products.');
  }

  const order = selectors.getOrderByLegIdAndCrossWay(
    state,
    { rfqId, strategyId, legId },
    selectors,
  );

  const executionKeys = selectors.executionSelectors.selectKeys(state.execution, {
    orderId: order?.uuid,
  });

  const isManual = !!order?.manual;

  const isEditable =
    status === 'ORDER_MANUALLY_CROSSED' ||
    status === 'BOOKING_REQUESTED' ||
    (isManual &&
      (orderOf(status).isBetween('ORDER_CONFIRMED', 'TRADE_COMPLETED') ||
        status === 'AMEND_REQUESTED' ||
        status === 'BEING_AMENDED'));

  return {
    orderId: order?.uuid,
    executionKeys,
    isReadOnly: !isEditable,
    isManual,
  };
}
