import type { Selectors } from '@/bootstrap/selectors';
import type { Destination } from '../../../../../../../../neos/business/neosModel';
import type { PreconfTabModel } from '../preconfTabmodel';
import type { AppState } from '@/bootstrap/store.ts';

export function getPreconfTabModel(
  state: AppState,
  rfqId: string,
  destination: Destination,
  selectors: Selectors,
): PreconfTabModel {
  const { strategyIds } = selectors.getRfqData(state, rfqId);

  const containsListedStrategy = strategyIds.some(strategyId =>
    selectors.isListedStrategy(state, strategyId, selectors),
  );

  const isSaveButtonDisplayed = containsListedStrategy;

  const isPreconfirmationButtonsDisabled = selectors.getIsPreconfirmationButtonsDisabled(
    state.ui,
    rfqId,
  );
  const isPreconfirmationPreviewVisible = !!selectors.getPreconfirmationEmailPreview(
    state,
    rfqId,
    destination,
  );

  const emailStatus =
    destination === 'INTERNAL'
      ? selectors.getInternalEmailStatus(state, rfqId)
      : selectors.getExternalEmailStatus(state, rfqId);
  const isEmailStatusDisplay = emailStatus != 'NOT_SENT';

  if (!isEmailStatusDisplay) {
    return {
      isPreconfirmationButtonsDisabled,
      isPreconfirmationPreviewVisible,
      isEmailStatusDisplay,
      isSaveButtonDisplayed,
    };
  }

  const color =
    destination === 'INTERNAL'
      ? selectors.getInternalPreconfBulletColor(state, rfqId)
      : selectors.getExternalPreconfBulletColor(state, rfqId);

  const text = emailStatus === 'SENT' ? 'Pre Confirmation sent' : 'Must be resent';

  return {
    isPreconfirmationButtonsDisabled,
    isPreconfirmationPreviewVisible,
    isEmailStatusDisplay,
    color,
    text,
    isSaveButtonDisplayed,
  };
}
