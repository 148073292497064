import type { Thunk } from '@/bootstrap/thunks';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { flatten } from 'lodash';
import type { OnyxRfq } from '../neosOnyxModel';
import type { Reference } from '../rfq/models';
import type { HighlightedValue, ReferenceUi } from '../ui/reference/referenceUiModel';
import type { Action } from '@/bootstrap/actions.ts';

export function createNeosDisplayRefWarningThunk(
  rfqId: string,
  onyxNotificationRfq: OnyxRfq,
): Thunk {
  return function neosDisplayRefWarningThunk(
    dispatch,
    getState,
    {
      selectors,
      fromMappers: { mapFromOnyxReferences },
      actionCreators: {
        neos: { referenceUiCrudActions },
      },
    },
  ) {
    const appState = getState();
    const oldReferences = selectors.getNotAggregatedReferences(appState, rfqId, selectors);
    const newReferences = mapFromOnyxReferences(
      flatten(onyxNotificationRfq.strategies.map(({ references }) => references)),
    );
    const actions: Action[] = mapToReferenceUi(oldReferences, newReferences).map(
      ({ underlyingId, ...restProps }) => {
        const referenceUi = selectors.getReferenceUiByRfqIdAndUnderlyingId(
          appState.ui,
          rfqId,
          underlyingId,
        );

        if (!restProps.refBasis?.isWarningDisplayed && referenceUi?.refBasis?.isWarningDisplayed) {
          delete restProps.refBasis;
        }

        if (!restProps.refLevel?.isWarningDisplayed && referenceUi?.refLevel?.isWarningDisplayed) {
          delete restProps.refLevel;
        }

        if (!restProps.refSpot?.isWarningDisplayed && referenceUi?.refSpot?.isWarningDisplayed) {
          delete restProps.refSpot;
        }

        return referenceUiCrudActions.patchOrInsert({ rfqId, underlyingId }, { ...restProps });
      },
    );
    if (actions?.length) {
      dispatch(actions);
    }
  };
}

function mapToReferenceUi(
  oldReferences: Reference[],
  newReferences: Reference[],
): Array<ReferenceUi & { underlyingId: string }> {
  const mapToHighlightedValue = (
    isWarningDisplayed: boolean,
    newValue: number | undefined,
    oldValue: number | undefined,
  ): HighlightedValue => ({
    isWarningDisplayed,
    newValue,
    oldValue,
  });

  return newReferences
    .map(({ underlyingId, refSpot: newRefSpot, refBasis: newRefBasis, refLevel: newRefLevel }) => {
      const oldReference = oldReferences.find(
        oldReference => oldReference.underlyingId === underlyingId,
      );
      if (!oldReference) {
        return undefined;
      }
      const { refSpot: oldRefSpot, refBasis: oldRefBasis, refLevel: oldRefLevel } = oldReference;
      return {
        underlyingId,
        refSpot: mapToHighlightedValue(oldRefSpot !== newRefSpot, newRefSpot, oldRefSpot),
        refBasis: mapToHighlightedValue(oldRefBasis !== newRefBasis, newRefBasis, oldRefBasis),
        refLevel: mapToHighlightedValue(oldRefLevel !== newRefLevel, newRefLevel, oldRefLevel),
      };
    })
    .filter(isDefined);
}
