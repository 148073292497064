import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import { createCreateLegsSecondaryEventThunk } from '@/neos/business/thunks/createLegsSecondaryEventThunk';
import { getCreateSecondaryButtonModel } from './getCreateSecondaryButtonModel';

type CreateSecondaryButtonProps = {
  rfqId: string;
};

export const CreateSecondaryButton = ({ rfqId }: CreateSecondaryButtonProps) => {
  const dispatch = useAppDispatch();
  const model = useAppSelector(state => getCreateSecondaryButtonModel(state, rfqId, selectors));

  function onCreateSecondaryEvent(rfqId: string) {
    dispatch([
      createCreateLegsSecondaryEventThunk(rfqId),
      createLogAnalyticsAction(
        'NEOS RFQ',
        'Secondary RFQ opened from create leg secondary events button',
      ),
    ]);
  }

  if (!model.isRfqSecondaryWidgetToggleOn) {
    return null;
  }

  return (
    <button
      className="btn btn-primary"
      disabled={model.isDisabled}
      onClick={() => onCreateSecondaryEvent(rfqId)}
    >
      Create leg secondary events
    </button>
  );
};
