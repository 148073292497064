import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import type { FC } from 'react';
import NotificationsSystem, { setUpNotifications, dismissNotification } from 'reapop';
import { v4 as generateUuid } from 'uuid';
import { neosNotificationTheme } from './neosNotificationTheme';

setUpNotifications({
  defaultProps: {
    position: 'top-center',
    showDismissButton: true,
    dismissible: true,
    dismissAfter: 5_000,
  },
  generateId: () => generateUuid(),
});

export const NeosNotificationsSystem: FC = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(state => state.notifications);

  return (
    <NotificationsSystem
      theme={neosNotificationTheme}
      smallScreenBreakpoint={768}
      dismissNotification={id => dispatch(dismissNotification(id))}
      notifications={notifications}
    />
  );
};
