import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '../../rfqActions';
import type { Action } from '@/bootstrap/actions.ts';

export function createSetHedgeMixedThunk(rfqId: string, underlyingId: string): Thunk {
  return function setHedgeMixedThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: {
          createDefaultRfqRequestedAction,
          strategyDataCrudActions,
          hedgeCrudActions,
          underlyingUiCrudActions,
        },
      },
    },
  ) {
    const state = getState();
    const { getHedges, getDeltaSum, getRfqData } = selectors;
    const { strategyIds } = getRfqData(state, rfqId);
    const delta = getDeltaSum(state, rfqId, underlyingId, selectors);
    const firstHedge = getHedges(state, { rfqId, underlyingId })[0];
    const deltaType = firstHedge?.deltaType;

    const actions: Action[] = [
      underlyingUiCrudActions.patchOrInsert({ rfqId, underlyingId }, { wasSetToMixedDelta: true }),
      hedgeCrudActions.delete({ rfqId, underlyingId, deltaType: 'RISK' }),
      hedgeCrudActions.delete({ rfqId, underlyingId, deltaType: undefined }),
      ...strategyIds.map(sId => strategyDataCrudActions.update(sId, { deltaType: undefined })),
    ];

    if (delta !== undefined) {
      switch (deltaType) {
        case 'DELTA_EXCHANGE':
        case 'RISK':
        case undefined:
          actions.push(
            hedgeCrudActions.upsert(
              { rfqId, underlyingId, deltaType: 'DELTA_EXCHANGE' },
              {
                deltaType: 'DELTA_EXCHANGE',
                underlyingId,
                deltaUnit: '%',
                delta,
                deltaSummary: undefined,
              },
            ),
          );
          break;
        case 'DELTA_EXCHANGE_OTC':
        case 'DELTA_ADJUSTED':
          actions.push(
            hedgeCrudActions.upsert(
              { rfqId, underlyingId, deltaType },
              {
                deltaType,
                underlyingId,
                deltaUnit: '%',
                delta,
                deltaSummary: undefined,
              },
            ),
          );
          break;
        default:
          break;
      }
      actions.push(
        createDefaultRfqRequestedAction(
          rfqId,
          new DefaultingOptions({
            overrideScopes: [DefaultingScope.TRADE_RECAP],
            enrichScopes: [DefaultingScope.HEDGES],
          }),
        ),
      );
    }

    dispatch(actions);
  };
}
