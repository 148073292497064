import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

interface SwapsWireAccountProps {
  rfqId: string;
}

export function SwapsWireAccount({ rfqId }: SwapsWireAccountProps) {
  const dispatch = useAppDispatch();
  const { swapsWireSgTraderAccount, uuid } = useAppSelector(state =>
    selectors.getRfqMasterStrategy(state, rfqId),
  );
  const isTrader = useAppSelector(state => selectors.isTrader(state));

  function onSwapsWireAccountChanged(swapsWireSgTraderAccount: string | undefined) {
    dispatch(
      actionCreators.neos.strategyDataCrudActions.update(uuid, {
        swapsWireSgTraderAccount,
      }),
    );
  }

  return (
    <div data-testid="SwapsWireAccount">
      <label>SG Trader</label>
      <NeosBlurInput
        readOnly={!isTrader}
        className="input-sm select-inline"
        value={swapsWireSgTraderAccount}
        onBlur={event => onSwapsWireAccountChanged(event.target.value || undefined)}
      />
    </div>
  );
}
