import { selectors } from '@/bootstrap/selectors';
import { type EventType, isElsProduct } from '../../../../../../business/neosModel';
import { availableEventTypes } from '../../../strategy/strategyDefinition/secondaryType/shared/SecondaryTypeCellsCommon';
import { SecondaryWidget } from '../../../strategy/strategyDefinition/secondaryType/shared/SecondaryWidget';
import { getRfqSecondaryTypeWidgetModel } from './getRfqSecondaryTypeWidgetModel';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { legSecondaryEventCreationActionCreators } from '@/neos/business/legSecondaryEventCreation/legSecondaryEventCreationActionCreators';
import { ElsBasketSecondaryWidget } from '@/neos/components/rfq/strategies/globalActions/secondaryEventWidget/widget/ElsBasketSecondaryWidget';

export interface RfqSecondaryTypeWidgetProps {
  rfqId: string;
}

// above the strategy section
// action button
export const RfqSecondaryTypeWidget = (props: RfqSecondaryTypeWidgetProps) => {
  const dispatch = useAppDispatch();
  const model = useAppSelector(state =>
    getRfqSecondaryTypeWidgetModel(state, props.rfqId, selectors),
  );
  const rfqProducts = useAppSelector(state =>
    selectors.getRfqProducts(state, props.rfqId, selectors),
  );
  const isNovationEnabled = rfqProducts.every(product => !isElsProduct(product));
  const isElsBasket = useAppSelector(state =>
    selectors.isRfqElsBasket(state, props.rfqId, selectors),
  );

  function onClicked(eventType: EventType | undefined) {
    dispatch(
      model.otcLegIdsWithValidEventType.map(legId =>
        legSecondaryEventCreationActionCreators.legSecondaryEventCreationCrudActions.patchOrInsert(
          { rfqId: props.rfqId, legId },
          { eventType, primaryLegUuid: legId },
        ),
      ),
    );
  }

  if (!model.isRfqSecondaryWidgetToggleOn || !model.isVisible) {
    return null;
  }

  return (
    <section>
      {isElsBasket ? (
        <ElsBasketSecondaryWidget />
      ) : (
        <SecondaryWidget
          eventTypes={availableEventTypes.filter(eventType => {
            return eventType === 'NOVATION_REMAINING' ? isNovationEnabled : true;
          })}
          isDisabled={!model.hasSomeValidLegMaturity}
          id={props.rfqId}
          handleClick={(_, __, eventType) => onClicked(eventType)}
        />
      )}
    </section>
  );
};
