import type { Selectors } from '@/bootstrap/selectors.ts';
import { type Els, isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { AppState } from '@/bootstrap/store.ts';

export function isEffectiveSettlementDatesOffsetEqual(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
) {
  const products = selectors.getRfqProducts(state, rfqId, selectors);
  const isEls = products.some(p => isElsProduct(p));
  const product = isEls ? (products[0] as Els) : undefined;

  if (product) {
    const { settlement } = selectors.getLegData(state, product.legId);
    return product.effectiveDateOffset === settlement?.date?.offset;
  }

  return undefined;
}
