import type { ActionCreators } from '@/bootstrap/actions';
import type { Selectors } from '@/bootstrap/selectors';
import type { Dispatchable, Thunks } from '@/bootstrap/thunks';
import {
  DefaultingScope,
  type ExecFeesValue,
  type Feature,
  type FeatureKey,
  LegRecomputeScope,
  type ResetFrequency,
  isElsProduct,
} from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import type { Dispatch } from 'react';
import type { AppState } from '@/bootstrap/store.ts';

type DisplayedField<T> = { value: T; isDisplayed: true } | { isDisplayed: false };

export function getElsFeatureFields(
  strategyId: string,
  state: AppState,
  selectors: Selectors,
  thunks: Thunks,
  actionCreators: ActionCreators,
  dispatch: Dispatch<Dispatchable>,
) {
  const getFeatureField = <FeatureField, Key extends FeatureKey>(
    featureKey: Key,
    fieldGetter: (feature: Extract<Feature, Key> | undefined) => FeatureField,
  ): DisplayedField<FeatureField> => {
    const { strategyType } = selectors.getStrategyData(state, featureKey.strategyId);
    const feature = selectors.getFeature(state.featureState, featureKey);

    return selectors.getSingleStrategyDefinitionFeature(
      state.referenceData,
      strategyType,
      featureKey.type,
    )
      ? {
          isDisplayed: true,
          value: fieldGetter(feature),
        }
      : { isDisplayed: false };
  };

  const equityBullet = getFeatureField(
    { strategyId, type: 'EQUITY_BULLET' },
    f => f?.equityBullet ?? false,
  );

  const rateBullet = getFeatureField(
    { strategyId, type: 'RATE_BULLET' },
    f => f?.rateBullet ?? false,
  );

  const resetFrequency = getFeatureField(
    { strategyId, type: 'RESET_FREQUENCY_FEATURE' },
    f => f?.resetFrequency ?? undefined,
  );

  const rateOvernight = getFeatureField(
    { strategyId, type: 'RATE_OVERNIGHT' },
    f => f?.rateOvernight ?? false,
  );

  const execFees = getFeatureField({ strategyId, type: 'EXEC_FEES' }, f => f?.execFees ?? 'NONE');

  const product = selectors.getStrategyProduct(state, strategyId, selectors);

  const isEls = isElsProduct(product);

  const onExecFeesTypeChanged = (value: ExecFeesValue | undefined, rfqId: string) => {
    onExecFeesTypeChangedFromOldEls(value);
    dispatch(
      actionCreators.neos.createRecomputeRfqRequestedAction(
        rfqId,
        LegRecomputeScope.CHANGE_EXEC_FEE,
      ),
    );
  };

  const onExecFeesTypeChangedFromOldEls = (value: ExecFeesValue | undefined) => {
    dispatch(
      thunks.neos.createChangeFeatureFieldThunk(
        { strategyId, type: 'EXEC_FEES' },
        { execFees: value },
      ),
    );
  };

  const onEquityBulletChanged = (value: boolean) =>
    dispatch(
      thunks.neos.createChangeFeatureFieldThunk(
        { strategyId, type: 'EQUITY_BULLET' },
        { equityBullet: value },
      ),
    );

  const onRateBulletChanged = (value: boolean) =>
    dispatch(
      thunks.neos.createChangeFeatureFieldThunk(
        { strategyId, type: 'RATE_BULLET' },
        { rateBullet: value },
      ),
    );

  const onResetFrequencyChanged = (value: ResetFrequency | undefined, rfqId: string) => {
    const defaultingOptions = new DefaultingOptions({
      overrideScopes: [DefaultingScope.RATE_RESET],
    });

    dispatch(
      thunks.neos.createChangeFeatureFieldThunk(
        { strategyId, type: 'RESET_FREQUENCY_FEATURE' },
        { resetFrequency: value },
      ),
    );
    dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, defaultingOptions));
  };

  return {
    equityBullet,
    rateBullet,
    resetFrequency,
    execFees,
    rateOvernight,
    isEls,
    onExecFeesTypeChanged,
    onExecFeesTypeChangedFromOldEls,
    onEquityBulletChanged,
    onRateBulletChanged,
    onResetFrequencyChanged,
  };
}
