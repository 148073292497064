import { UpdateJustificationType } from '@/neos/components/rfq/workflow/justification/UpdateJustificationType';
import type { FC } from 'react';
import { PricingDeadline } from '../pricingDeadline/PricingDeadline';
import styles from './Workflow.module.scss';
import { NextUserActions } from './nextUserActions';
import { PriceRequestDate } from './priceRequestDate/PriceRequestDate.container';
import { Status } from './status/Status.container';
import { Timers } from './timers/Timers.container';
import { Versionning } from './versionning/Versionning.container';
import { TradeDate } from '@/neos/components/rfq/workflow/TradeDate.tsx';

export interface WorkflowProps {
  rfqId: string;
}

export const Workflow: FC<WorkflowProps> = ({ rfqId }) => {
  return (
    <div className={`${styles['workflow']}`}>
      <Status rfqId={rfqId} />
      <NextUserActions rfqId={rfqId} />
      <Versionning rfqId={rfqId} />
      <UpdateJustificationType rfqId={rfqId} />
      <PriceRequestDate rfqId={rfqId} />
      <TradeDate rfqId={rfqId} />
      <PricingDeadline rfqId={rfqId} />
      <Timers rfqId={rfqId} />
    </div>
  );
};
