import type { Selectors } from '@/bootstrap/selectors.ts';
import type { Way } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import type { UnderlyingType } from '@/neos/business/rfq/strategy/leg/legOnyxModel.ts';
import type { AppState } from '@/bootstrap/store.ts';

export interface GetCompositionLegsGridModel {
  clientWay: Way | undefined;
  underlyingTypes: UnderlyingType[];
}

export function getCompositionLegsGridModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): GetCompositionLegsGridModel {
  const { clientWay } = selectors.getRfqData(state, rfqId);

  const strategyData = selectors.getStrategyData(state, strategyId);
  const firstCompositionLegDefinition = selectors
    .getStrategyDefinition(state.referenceData, strategyData.strategyType)
    .legs.find(({ master }) => !master);
  const underlyingTypes = firstCompositionLegDefinition?.availableProductUnderlyingTypes ?? [];

  return { clientWay, underlyingTypes };
}
