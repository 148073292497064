import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { SwapsWireSgTraderAccountType } from '@/neos/business/neosModel';
import { NeosSelect, type SelectOption } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

interface SwapsWireAccountTypeProps {
  rfqId: string;
}

export const swapsWireSgTraderAccountTypeOptions: SelectOption<SwapsWireSgTraderAccountType>[] = [
  { label: 'User', value: 'USER' },
  { label: 'Group', value: 'GROUP' },
];

export function SwapsWireAccountType({ rfqId }: SwapsWireAccountTypeProps) {
  const dispatch = useAppDispatch();
  const { swapsWireSgTraderAccountType, uuid } = useAppSelector(state =>
    selectors.getRfqMasterStrategy(state, rfqId),
  );
  const isTrader = useAppSelector(state => selectors.isTrader(state));

  function onSwapsWireAccountTypeChanged(
    swapsWireSgTraderAccountType: SwapsWireSgTraderAccountType | undefined,
  ) {
    dispatch(
      actionCreators.neos.strategyDataCrudActions.update(uuid, {
        swapsWireSgTraderAccountType,
      }),
    );
  }

  return (
    <div data-testid="SwapsWireAccountType">
      <label>Type</label>
      <NeosSelect
        value={swapsWireSgTraderAccountType}
        readOnly={!isTrader}
        options={swapsWireSgTraderAccountTypeOptions}
        onChange={newType => {
          onSwapsWireAccountTypeChanged(newType);
        }}
        addEmptyOption
      />
    </div>
  );
}
