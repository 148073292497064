import type { Selectors } from '@/bootstrap/selectors';
import type { Dispatchable, Thunks } from '@/bootstrap/thunks';
import type { InterestRateFeatureKey } from '@/neos/business/rfq/models';
import { DefaultingScope } from '@/neos/business/rfq/models';
import type { NewRateTenors } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import type { Dispatch } from 'react';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';
import type { ActionCreators } from '@/bootstrap/actions.ts';
import type { AppState } from '@/bootstrap/store.ts';

export function getInterestRateTenorModel(
  strategyId: string,
  state: AppState,
  actionCreators: ActionCreators,
  selectors: Selectors,
  thunks: Thunks,
  dispatch: Dispatch<Dispatchable>,
) {
  const interestRateFeature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'INTEREST_RATE_INDEX_FEATURE',
  });
  const { rfqId } = selectors.getStrategyData(state, strategyId);
  const isElsStrategy = selectors.isElsStrategy(state, strategyId, selectors);

  const featureKey: InterestRateFeatureKey = { strategyId, type: 'INTEREST_RATE_INDEX_FEATURE' };

  return {
    rateTenor: interestRateFeature?.rateTenor,
    newRateTenor: interestRateFeature?.newRateTenor,
    onRateTenorChanged: (newRateTenor: string | undefined) => {
      dispatch(thunks.neos.createChangeFeatureFieldThunk(featureKey, { rateTenor: newRateTenor }));
    },
    onNewRateTenorChanged: (newNewRateTenor: NewRateTenors | undefined) => {
      dispatch(
        thunks.neos.createChangeFeatureFieldThunk(featureKey, { newRateTenor: newNewRateTenor }),
      );

      if (isElsStrategy) {
        dispatch(
          actionCreators.neos.createDefaultRfqRequestedAction(
            rfqId,
            new DefaultingOptions({
              overrideScopes: [DefaultingScope.RESET_FREQUENCY, DefaultingScope.RATE_RESET],
            }),
          ),
        );
      }
    },
  };
}
