import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/store.ts';

export type LegBookingIdBrokenLinkModel = {
  haveSomeBrokenLinks: boolean;
  brokenLink: boolean;
  isBrokenLinkDisplayed: boolean;
};

export function getLegBookingIdBrokenLinkModel(
  state: AppState,
  { rfqId, legId }: { rfqId: string; legId: string },
  selectors: Selectors,
): LegBookingIdBrokenLinkModel {
  const legBookingStep = selectors.getLegBookingStep(state, rfqId, legId);
  const isLegOtc = selectors.isOtcLeg(state, legId, selectors);
  const { internal } = selectors.getRfqData(state, rfqId);

  const strategyId = selectors.getStrategyIdByLegId(state, legId);
  const haveSomeBrokenLinks = selectors.hasAnyBrokenLinkXOneLegBookingStep(
    state,
    rfqId,
    strategyId,
  );

  const isBrokenLinkDisplayed = isLegOtc && legBookingStep !== undefined && !!internal;

  return {
    haveSomeBrokenLinks,
    brokenLink: !!legBookingStep?.brokenLink,
    isBrokenLinkDisplayed,
  };
}
