import { useAppSelector } from '@/bootstrap/hooks';

import { type Action, actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { FormSelect } from 'react-bootstrap';
import {
  isElsProduct,
  type ValuationFrequencyFeature,
} from '../../../../../../../../neos/business/neosModel';
import {
  valuationFrequencies,
  type ValuationFrequency,
} from '../../../../../../../../neos/business/neosOnyxModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function ValuationFrequencyComponent() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const feature: ValuationFrequencyFeature | undefined = useAppSelector(state =>
    selectors.getFeature(state.featureState, {
      strategyId,
      type: 'VALUATION_FREQUENCY',
    }),
  );
  if (!isElsProduct(product)) {
    return null;
  }

  const onValuationFrequencyChange = (newValuationFrequency: ValuationFrequency | undefined) => {
    const action: Action = actionCreators.neos.featureCrudActions.upsert(
      {
        strategyId,
        type: 'VALUATION_FREQUENCY',
      },
      {
        type: 'VALUATION_FREQUENCY',
        strategyId,
        valuationFrequency: newValuationFrequency,
      },
    );

    dispatch(action);
  };

  return (
    <ElsBlocField
      label="Valuation Frequency"
      renderInput={readOnly => (
        <FormSelect
          readOnly={readOnly}
          value={feature?.valuationFrequency ?? ''}
          onChange={event =>
            onValuationFrequencyChange((event.target.value as ValuationFrequency) || undefined)
          }
          data-e2e="rate-convention-section-computed-rate-way"
        >
          <option value=""></option>
          {Object.entries(valuationFrequencies).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </FormSelect>
      )}
    />
  );
}
