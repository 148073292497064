import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import {
  type ShortcutButtonDispatchProps,
  type ShortcutButtonOwnProps,
  ShortcutButtonUnconnected,
} from './ShortcutButton';

export function ShortcutButton<T>(
  props: ShortcutButtonOwnProps<T> & ShortcutButtonDispatchProps<T>,
) {
  const { getSelectedTab, isRfqLoading } = selectors;

  const rfqId = useAppSelector(state => getSelectedTab(state.ui.tabManagement)) as string;
  const isLoading = useAppSelector(state => isRfqLoading(state.ui, rfqId)) as boolean;

  return <ShortcutButtonUnconnected {...props} isLoading={isLoading} />;
}
