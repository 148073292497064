import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { type NeosActionCreators, neosActionCreators } from '@/neos/business/neosActionCreators';
import { useDispatch } from 'react-redux';

import {
  PreconfirmationPreviewComponent,
  type PreconfirmationPreviewOwnProps,
} from './PreconfirmationPreview';
import { getPreconfirmationPreviewModel } from './getPreconfirmationPreviewModel';

export const PreconfirmationPreview = (ownProps: PreconfirmationPreviewOwnProps) => {
  const stateProps = useAppSelector(state => {
    return getPreconfirmationPreviewModel(state, ownProps, selectors);
  });
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, ownProps, neosActionCreators);
  return <PreconfirmationPreviewComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId, destination }: PreconfirmationPreviewOwnProps,
  { preconfirmationEmailPreviewCrudAction }: NeosActionCreators,
) {
  return {
    onCleanPreviewData: () =>
      dispatch(preconfirmationEmailPreviewCrudAction.delete({ rfqId, destination })),
  };
}
