import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { connect } from 'react-redux';
import {
  BlotterPinButtonsComponent,
  type BlotterPinButtonsDispatchProps,
  type BlotterPinButtonsStateProps,
} from './BlotterPinButtons';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(state: AppState): BlotterPinButtonsStateProps {
  const position = selectors.getBlotterPosition(state.ui.blotter);

  return {
    position,
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): BlotterPinButtonsDispatchProps {
  return {
    onChange: position =>
      dispatch(
        thunks.neos.createToggleBlotterPinThunk(position),
        actionCreators.analytics.createLogAnalyticsAction(
          'NEOS WORKSPACE',
          `${position !== 'CENTER' ? 'P' : 'Unp'}in Blotter`,
        ),
      ),
  };
}

export const BlotterPinButtons = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlotterPinButtonsComponent);
