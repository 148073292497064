import type { Selectors } from '@/bootstrap/selectors';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getCurrentLotSizeMarket } from './util/getCurrentLotSizeMarket';
import { getStrategyDefinitionAvailableLotSizeMarkets } from './util/getStrategyDefinitionAvailableLotSizeMarkets';
import type { LotSizeMarket } from './util/getStrategyDefinitionSpecificProduct';
import type { AppState } from '@/bootstrap/store.ts';

export interface LotSizeMarketCellModel {
  disabled: boolean;
  availableLotSizesMarkets: LotSizeMarket[];
  lotSizeMarkets: LotSizeMarket[];
  isListed: boolean;
}

export function getLotSizeMarketCellModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): LotSizeMarketCellModel {
  const products = selectors.getStrategyProducts(appState, strategyId, selectors);

  const lotSizeMarkets = products.map(product =>
    getCurrentLotSizeMarket(product, appState, strategyId, selectors),
  );

  const availableLotSizesMarkets = getStrategyDefinitionAvailableLotSizeMarkets(
    appState,
    strategyId,
    selectors,
  );

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const disabled =
    !getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_EDITABLE',
      'STRATEGY_EDITABLE',
      'UNDERLYING_SET',
    ]) || isReadonlyAtWorkflow;
  const isListed = selectors.isListedStrategy(appState, strategyId, selectors);

  const { strategyType: selectedStrategyType } = selectors.getStrategyData(appState, strategyId);
  const { sameProductLotSize } = selectors.getStrategyDefinition(
    appState.referenceData,
    selectedStrategyType,
  );

  return {
    disabled,
    availableLotSizesMarkets,
    lotSizeMarkets: !sameProductLotSize ? lotSizeMarkets : lotSizeMarkets.slice(0, 1),
    isListed,
  };
}
