import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope, type HedgeKey } from '../models';
import { DefaultingOptions } from '../rfqActions';
import type { Action } from '@/bootstrap/actions.ts';

export function createRfqUpdateDeltaTypeThunk(key: HedgeKey): Thunk {
  return function rfqUpdateDeltaType(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: {
          underlyingUiCrudActions,
          strategyDataCrudActions,
          hedgeCrudActions,
          createDefaultRfqRequestedAction,
        },
      },
    },
  ) {
    const state = getState();
    const { getDeltaSum, getRfqData } = selectors;
    const { rfqId, underlyingId, deltaType } = key;
    const delta = getDeltaSum(state, rfqId, underlyingId, selectors);
    const { strategyIds } = getRfqData(state, rfqId);

    const actions: Action[] = [
      underlyingUiCrudActions.patchOrInsert({ underlyingId, rfqId }, { wasSetToMixedDelta: false }),
      hedgeCrudActions.deleteByPartialKey({ underlyingId, rfqId }),
      hedgeCrudActions.patchOrInsert(key, {
        underlyingId,
        delta,
        deltaUnit: '%',
        deltaType,
        deltaSummary: undefined,
      }),
      ...strategyIds.map(sId => strategyDataCrudActions.update(sId, { deltaType: undefined })),
      createDefaultRfqRequestedAction(
        rfqId,
        new DefaultingOptions({
          overrideScopes: [DefaultingScope.TRADE_RECAP],
          enrichScopes: [DefaultingScope.HEDGES],
        }),
      ),
    ];

    dispatch(actions);
  };
}
