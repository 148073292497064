import { useLongClick } from '@/neos/components/share/longClick/longClickHook';
import { createRef } from 'react';

export interface ShortcutButtonOwnProps<T> {
  shortcut: Shortcut<T>;
}

export interface Shortcut<T> {
  label: string;
  id: T;
  isHidden?: boolean;
  ['data-e2e']?: string;
}

export interface ShortcutButtonDispatchProps<T> {
  onShortcutSelected: (value: T) => void;
  onShortcutLongPress: (value: T) => void;
}

export interface ShortcutButtonStateProps {
  isLoading: boolean;
}

export type ShortcutButtonProps<T> = ShortcutButtonOwnProps<T> &
  ShortcutButtonDispatchProps<T> &
  ShortcutButtonStateProps;

export function ShortcutButtonUnconnected<T>({
  isLoading,
  shortcut,
  onShortcutSelected,
  onShortcutLongPress,
}: ShortcutButtonProps<T>) {
  const ref = createRef<HTMLButtonElement>();
  const backspaceLongPress = useLongClick(
    () => {
      onShortcutSelected(shortcut.id);
      if (ref.current) {
        ref.current.blur();
      }
    },
    () => {
      onShortcutLongPress(shortcut.id);
      if (ref.current) {
        ref.current.blur();
      }
    },
    500,
    isLoading,
  );
  const { className = '', ...rest } = backspaceLongPress;

  if (shortcut.isHidden) {
    return null;
  }

  return (
    <button
      className={`btn btn-toggle-primary ` + className}
      data-e2e={shortcut['data-e2e']}
      ref={ref}
      {...rest}
    >
      {shortcut.label}
    </button>
  );
}
