import { memoize } from 'proxy-memoize';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch, NeosThunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch } from 'react-redux';
import {
  type SimilarActivitiesDispatchProps,
  type SimilarActivitiesOwnProps,
  SimilarActivitiesWithStyle,
} from './SimilarActivities';
import { getSimilarActivitiesModel } from './getSimilarActivitiesModel';
import { useAppSelector } from '@/bootstrap/hooks.ts';

export const SimilarActivities = (ownProps: SimilarActivitiesOwnProps) => {
  const stateProps = useAppSelector(
    memoize(state => {
      const selectedTabId = state.ui.tabManagement.selectedTabId;
      const currentPrimaryLegId = state.similarActivitiesLinkManagement.currentPrimaryLegId ?? '';
      const isRfqWaitingForSecondarySelection = selectors.isRfqWaitingForSecondarySelection(
        state.ui,
        selectedTabId,
      );
      return {
        model: getSimilarActivitiesModel(state, ownProps.rfqId, selectors),
        selectedTabId,
        isRfqWaitingForSecondarySelection,
        currentPrimaryLegId,
      };
    }),
  );

  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, ownProps, neosThunks);

  return <SimilarActivitiesWithStyle {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: SimilarActivitiesOwnProps,
  { createResetSecondaryTypesOnLegsThunk }: NeosThunks,
): SimilarActivitiesDispatchProps {
  return {
    requestSimilarActivities: () => {
      dispatch(neosActionCreators.createRequestSimilarActivitiesAction(rfqId));
    },
    changeDisplayedStatus: (areDisplayed: boolean) =>
      dispatch(
        neosActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, {
          areSimilarActivitiesDisplayed: areDisplayed,
        }),
      ),
    updateGridHeight: (newHeight: number) =>
      dispatch(
        neosActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, {
          similarActivitiesHeight: newHeight,
        }),
      ),
    onCloseBackDrop: () => {
      dispatch(createResetSecondaryTypesOnLegsThunk());
    },
  };
}
