import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export function isDeltaMixed(
  state: AppState,
  rfqId: string,
  underlyingId: string,
  selectors: Selectors,
) {
  const hedges = selectors.getHedges(state, { rfqId, underlyingId });
  return hedges.length > 1 || selectors.getWasSetToMixedDelta(state.ui, rfqId, underlyingId);
}
