import { type Selectors } from '@/bootstrap/selectors';
import type { OtcAllocation } from '@/neos/business/neosModel';
import type { PriceWithUnit, StatusColor } from '@/neos/business/neosOnyxModel';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { sum } from 'lodash';

import type { AppState } from '@/bootstrap/store.ts';

type SizeInfos = { className: string; tooltip?: { type: StatusColor; msg: string } };
type AllocStatus = 'under' | 'fully' | 'over' | undefined;

interface OtcAllocSizeModel {
  sizeInfos: SizeInfos;
}

export function getOtcAllocSizeModel(
  state: AppState,
  legId: string,
  uiSizeType: DisplayNegotiatedSize,
  allocationIndex: number,
  isAllocCancelled: boolean,
  selectors: Selectors,
): OtcAllocSizeModel {
  const allAllocations = selectors.getOtcAllocationsByLegId(state, legId);
  const activeAllocations = allAllocations.filter(allocation => !allocation.isCancelled);
  const allocationSizesValues = activeAllocations.map(
    alloc => getPriceWithUnitSizeInAlloc(alloc, uiSizeType)?.value,
  );

  const areWarningsDisplayed = allocationSizesValues.some(value => value !== undefined);

  const { notional, quantity, varUnit } = selectors.getLegData(state, legId);
  const totalQty = sum(allocationSizesValues.map(q => q || 0));
  const requiredQty =
    uiSizeType === 'QUANTITY' ? quantity : uiSizeType === 'VAR_UNIT' ? varUnit : notional;
  const allocStatus: AllocStatus = requiredQty
    ? requiredQty === totalQty
      ? 'fully'
      : requiredQty > totalQty
        ? 'under'
        : 'over'
    : undefined;

  return {
    sizeInfos: getSizeInfos(
      allocStatus,
      allocationIndex,
      activeAllocations.length,
      areWarningsDisplayed,
      isAllocCancelled,
    ),
  };
}

export function getPriceWithUnitSizeInAlloc(
  allocation: OtcAllocation,
  uiSizeType: DisplayNegotiatedSize,
): PriceWithUnit | undefined {
  if (uiSizeType === 'QUANTITY') {
    return {
      value: allocation.quantity,
      unit: '#',
    };
  }
  return uiSizeType === 'VAR_UNIT' ? allocation.varUnit : allocation.notional;
}

function getSizeInfos(
  allocStatus: AllocStatus,
  index: number,
  length: number,
  areWarningsDisplayed: boolean,
  allocationIsCancelled: boolean,
): SizeInfos {
  const infos: SizeInfos = { className: '', tooltip: undefined };
  if (allocStatus === undefined || !areWarningsDisplayed || allocationIsCancelled) {
    return infos;
  }

  let borderColor = '';

  switch (allocStatus) {
    case 'under':
      borderColor = ' border-warning ';
      infos.tooltip = {
        type: 'warning',
        msg: 'Under allocated',
      };
      break;
    case 'fully':
      borderColor = ' border-success ';
      infos.tooltip = {
        type: 'success',
        msg: 'Fully allocated',
      };
      break;
    case 'over':
      borderColor = ' border-danger ';
      infos.tooltip = {
        type: 'danger',
        msg: 'Over allocated',
      };
      break;
  }

  let borderType = ' border-start border-end quantity-border ';
  if (index === 0) {
    borderType += ' border-top quantity-border-top ';
  }
  if (index === length - 1) {
    borderType += ' border-bottom quantity-border-bottom ';
  }

  infos.className = `${borderColor} ${borderType} `;
  return infos;
}
