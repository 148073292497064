import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { uniqWith } from 'lodash';
import { getCurvesList } from './getCurvesList';
import { getInterestRateCurveModel } from './getInterestRateCurveModel';

type ClsCurveProps = {
  rfqId: string;
  strategyId: string;
  labelClassNames?: string;
  parentClassNames?: string;
};

export function ClsCurve({ rfqId, strategyId, labelClassNames, parentClassNames }: ClsCurveProps) {
  const dispatch = useAppDispatch();

  const { rateCurve, onRateCurveChanged } = useAppSelector(state =>
    getInterestRateCurveModel(
      strategyId,
      rfqId,
      state,
      selectors,
      actionCreators,
      thunks,
      dispatch,
    ),
  );

  const isReadonlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  const clsSwapCurrency = useAppSelector(
    state =>
      selectors.getFeature(state.featureState, {
        strategyId,
        type: 'SWAP_CURRENCY',
      })?.swapCurrency,
  );

  const curves = useAppSelector(state =>
    uniqWith(
      getCurvesList(state, selectors, clsSwapCurrency),
      (curveA, curveB) => curveA.eliotId === curveB.eliotId,
    ),
  );

  return (
    <div className={`${parentClassNames ?? ''} els-field`} onMouseDown={e => e.stopPropagation()}>
      <SimpleNeosTooltip message="Rate Curve">
        <label className={labelClassNames}>Curve</label>
      </SimpleNeosTooltip>
      <NeosSelect
        readOnly={isReadonlyAtCurrentWorkflow}
        value={rateCurve}
        onChange={onRateCurveChanged}
        options={curves.map(curve => ({ label: curve.rateName, value: curve.eliotId }))}
        addEmptyOption
        data-e2e="cls-rate-curve"
      />
      )
    </div>
  );
}
