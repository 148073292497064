import { orderBy } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';

export function getUnderlyingStrikesByMaturity(
  appState: AppState,
  underlyingId: string,
  maturity: string,
): number[] {
  const underlyingStateInfo = appState.underlyingState[underlyingId];

  return underlyingStateInfo &&
    underlyingStateInfo.hasAllUnderlyingInfo &&
    underlyingStateInfo.option.maturityInfo[maturity]
    ? orderBy(underlyingStateInfo.option.maturityInfo[maturity].strikes, strike => strike, ['asc'])
    : [];
}

export function getUnderlyingStrikesByMaturityOnOptionOnFuture(
  appState: AppState,
  underlyingId: string,
  maturity: string,
): number[] {
  const underlyingStateInfo = appState.underlyingState[underlyingId];

  if (
    underlyingStateInfo &&
    underlyingStateInfo.hasAllUnderlyingInfo &&
    underlyingStateInfo.option.optionOnFutureMaturityInfo
  ) {
    const maturities = underlyingStateInfo.option.optionOnFutureMaturityInfo;
    return orderBy(maturities?.[maturity]?.strikes, strike => strike, ['asc']);
  }
  return [];
}
