import { globalErrorActionCreators } from '@/common/business/globalError/globalErrorActionCreators';
import type { AsyncLogger } from './asyncLogger';
import { mt } from './messageTemplates';
import type { AppStore } from '@/bootstrap/store.ts';

export type FlowErrorHandler = (
  logger: AsyncLogger,
  store: AppStore,
  errorEvent: ErrorEvent,
) => void;
export function setUnhandledExceptionHandler(
  logger: AsyncLogger,
  store: AppStore,
  onError: FlowErrorHandler = onFlowError,
) {
  // onerror shouldn't fail no matter what !
  window.addEventListener('error', ev => {
    (window as any).lastErrorEvent = ev;
    onError(logger, store, ev);
  });
}

export function onFlowError(logger: AsyncLogger, store: AppStore, errorEvent: ErrorEvent) {
  try {
    const { error } = errorEvent;
    if (error) {
      logger.error(mt.unhandledException, error.message, error);
      // try to handle non UI errors
      if (!error.stack?.includes('src/neos/components')) {
        store.dispatch(
          globalErrorActionCreators.createAppCrashedAction('UnhandledExceptionHandler', error),
        );
      }
    }
  } catch (logError) {
    // eslint-disable-next-line no-console
    console.error('error when logging an error', logError);
  }
}
