import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

interface QuoteTimerTimerDisplayed {
  isDisplay: true;
  expiry: string;
  duration: number;
}
interface QuoteTimerTimerHidden {
  isDisplay: false;
}

export type QuoteTimerTimerModel = QuoteTimerTimerDisplayed | QuoteTimerTimerHidden;

export function getMdpQuoteTimerTimeModel(
  state: AppState,
  rfqId: string,
  { getRfqData }: Selectors,
): QuoteTimerTimerModel {
  const { quoteExpiryUTCTime: expiry, quoteValidityDuration } = getRfqData(state, rfqId);

  if (expiry && quoteValidityDuration) {
    return {
      isDisplay: true,
      expiry,
      duration: quoteValidityDuration * 1_000,
    };
  }
  return {
    isDisplay: false,
  };
}
