import { memoize } from 'proxy-memoize';
import { selectors } from '@/bootstrap/selectors';
import { MdpRfqTimerComponent, type MdpRfqTimerOwnProps } from '../MdpRfqTimer';
import { getMdpRfqOrderReceivedTimerModel } from './getMdpRfqOrderReceivedTimerModel';
import { useAppSelector } from '@/bootstrap/hooks.ts';

export const MdpRfqOrderReceivedTimer = (ownProps: MdpRfqTimerOwnProps) => {
  const model = useAppSelector(
    memoize(state => {
      return getMdpRfqOrderReceivedTimerModel(state, ownProps.rfqId, selectors);
    }),
  );

  if (model.isMdpRfqTimerDisplay) {
    const { isMdpRfqTimerDisplay, ...rest } = model;
    return <MdpRfqTimerComponent {...rest} {...ownProps} />;
  }
  return null;
};
