import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import { computeLegWay } from '@/neos/business/services/clientWayComputer';
import {
  type MarginRules,
  type PriceUnitType,
  type Way,
  isCustomUnderlyingProduct,
} from '../../../../../../../neos/business/neosModel';
import type { QuotesAndHeaderCommonModel } from './getQuotesAndHeaderCommonModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface QuoteModel {
  quoteId: string;
  unit: string;
  salesBid: number | undefined;
  traderBid: number | undefined;
  bidExecFees: number | undefined;
  salesAsk: number | undefined;
  askExecFees: number | undefined;
  traderAsk: number | undefined;
  salesMarginRule: MarginRules | undefined;
  traderDeltaPercent: number | undefined;
  bidIsTransparent: boolean;
  askIsTransparent: boolean;
  isQuoteUnitEditable: boolean;
  availablePriceUnitTypes: PriceUnitType[];
  isAveragePriceTooltipSalesAskEnabled: boolean;
  isAveragePriceTooltipSalesBidEnabled: boolean;
  averagePrice: string | undefined;
  currencyUnit: string | undefined;
  traderWarning?: Record<'traderAsk' | 'traderBid', string | undefined>;
}

export interface LegQuoteModel extends QuoteModel {
  legId: string;
}

export type StrategyAndLegsQuoteModel = FullQuotesModel | EmptyQuotesModel;

export interface FullQuotesModel extends QuotesAndHeaderCommonModel {
  isReadonly: boolean;
  quotesModelDiscriminator: 'FullQuotesModel';
  legsQuotes: LegQuoteModel[];
  strategyQuotes: QuoteModel;
  displayEmptyFirstLeg: boolean;
  availableMarginRules: MarginRules[];
  areTraderPricesEnabled: boolean;
  areTraderDeltaEnabled: boolean;
  areUnitsDifferent: boolean;
}

interface EmptyQuotesModel extends QuotesAndHeaderCommonModel {
  isReadonlyRfq: boolean;
  quotesModelDiscriminator: 'EmptyQuotesModel';
  isMasterStrategy: false;
}

export function isFullQuotesModel(model: StrategyAndLegsQuoteModel): model is FullQuotesModel {
  return model.quotesModelDiscriminator === 'FullQuotesModel';
}

export function computeAskIsTransparent(
  strategyClientWay: Way | undefined,
  strategyWeight: number,
): boolean {
  return (
    (strategyClientWay === 'BUY' && strategyWeight < 0) ||
    (strategyClientWay === 'SELL' && strategyWeight > 0)
  );
}

export function computeBidIsTransparent(
  strategyClientWay: Way | undefined,
  strategyWeight: number,
): boolean {
  return (
    (strategyClientWay === 'SELL' && strategyWeight < 0) ||
    (strategyClientWay === 'BUY' && strategyWeight > 0)
  );
}

export function getStrategyQuoteModel(
  appState: AppState,
  strategyId: string,
  strategyClientWay: Way | undefined,
  selectors: Selectors,
): QuoteModel {
  const {
    getStrategyData,
    getQuote,

    getStrategyAveragePrice,
    getStrategyLeg,
  } = selectors;
  const { getUnderlyingUnit, isQuoteUnitEditable, getStrategyAvailablePriceUnitTypes } = selectors;
  const { quoteId, weight, strategyType, rfqId } = getStrategyData(appState, strategyId);
  const {
    salesAsk,
    traderAsk,
    salesBid,
    traderBid,
    salesBidType,
    salesAskType,
    delta: traderDeltaPercent,
    extraSalesMarginRule: salesMarginRule,
    unit,
  } = getQuote(appState, quoteId);

  const askIsTransparent = computeAskIsTransparent(strategyClientWay, weight);
  const bidIsTransparent = computeBidIsTransparent(strategyClientWay, weight);
  const legWay = computeLegWay(weight, 1, strategyClientWay);
  const { averagePrice } = getStrategyAveragePrice(appState, rfqId, strategyId, selectors) ?? {};
  const isStatusAfterOrderBeingCrossed = selectors.isStatusAfterOrderBeingCrossed(
    appState,
    rfqId,
    selectors,
  );
  const isListedStrategy = selectors.isListedStrategy(appState, strategyId, selectors);
  const averagePriceWarningEnabled = isListedStrategy && isStatusAfterOrderBeingCrossed;
  const isAveragePriceTooltipSalesAskEnabled =
    averagePriceWarningEnabled &&
    legWay === 'BUY' &&
    salesAskType === 'CCY' &&
    salesAsk !== averagePrice?.value;
  const isAveragePriceTooltipSalesBidEnabled =
    averagePriceWarningEnabled &&
    legWay === 'SELL' &&
    salesBidType === 'CCY' &&
    salesBid !== averagePrice?.value;
  const quoteUnit = unit;
  const { productId } = getStrategyLeg(appState, strategyId);
  const underlyingUnit = getUnderlyingUnit(appState, productId, selectors);
  const traderWarning = computeTraderWarnings(selectors, appState, rfqId, strategyId);

  return {
    quoteId,
    traderWarning,
    unit: unit || '',
    salesAsk,
    askExecFees: undefined,
    traderAsk,
    salesBid,
    bidExecFees: undefined,
    traderBid,
    traderDeltaPercent,
    salesMarginRule,
    askIsTransparent,
    bidIsTransparent,
    isQuoteUnitEditable: isQuoteUnitEditable(appState.referenceData, strategyType, selectors),
    availablePriceUnitTypes: getStrategyAvailablePriceUnitTypes(
      appState.referenceData,
      strategyType,
      selectors,
    ),
    isAveragePriceTooltipSalesAskEnabled,
    isAveragePriceTooltipSalesBidEnabled,
    averagePrice: getAveragePriceTooltipMessage(averagePrice?.value, averagePrice?.unit),
    currencyUnit: getCurrencyUnitTooltipMessage(underlyingUnit, quoteUnit),
  };
}

export function getLegQuoteModel(
  appState: AppState,
  rfqId: string,
  legId: string,
  strategyWeight: number,
  strategyClientWay: Way | undefined,
  isStatusAfterOrderBeingCrossed: boolean,
  selectors: Selectors,
): LegQuoteModel {
  const { getLegData, getQuote, isListedLeg, getProduct } = selectors;
  const { getOrderByLegId } = selectors;
  const { getUnderlyingUnit } = selectors;
  const { quoteId, weight, productId } = getLegData(appState, legId);
  const {
    salesAsk,
    traderAsk,
    salesBid,
    traderBid,
    extraSalesMarginRule: salesMarginRule,
    delta: traderDeltaPercent,
    unit: quoteUnit,
  } = getQuote(appState, quoteId);

  const unit = quoteUnit || '';
  const legWay = services.computeLegWay(strategyWeight, weight, strategyClientWay);

  const isBuyWay = legWay === 'BUY';
  const isSellWay = legWay === 'SELL';
  const askIsTransparent = isSellWay;
  const bidIsTransparent = isBuyWay;
  const { averagePrice } = getOrderByLegId(appState.orderData, rfqId, legId) ?? {};
  const isListed = isListedLeg(appState, legId, selectors);
  const averagePriceWarningEabled = isListed && isStatusAfterOrderBeingCrossed;
  const isAveragePriceTooltipSalesAskEnabled =
    averagePriceWarningEabled &&
    isBuyWay &&
    unit === averagePrice?.unit &&
    salesAsk !== averagePrice.value;
  const isAveragePriceTooltipSalesBidEnabled =
    averagePriceWarningEabled &&
    isSellWay &&
    unit === averagePrice?.unit &&
    salesBid !== averagePrice.value;
  const product = getProduct(appState, productId);
  const underlyingUnit = getUnderlyingUnit(appState, product.uuid, selectors);
  const traderWarning = computeTraderWarnings(selectors, appState, rfqId, legId);
  return {
    legId,
    traderWarning,
    quoteId,
    unit,
    salesAsk,
    askExecFees: undefined,
    traderAsk,
    salesBid,
    bidExecFees: undefined,
    traderBid,
    salesMarginRule,
    traderDeltaPercent,
    askIsTransparent,
    bidIsTransparent,
    isQuoteUnitEditable: false,
    availablePriceUnitTypes: [],
    isAveragePriceTooltipSalesAskEnabled,
    isAveragePriceTooltipSalesBidEnabled,
    averagePrice: getAveragePriceTooltipMessage(averagePrice?.value, averagePrice?.unit),
    currencyUnit: getCurrencyUnitTooltipMessage(underlyingUnit, quoteUnit),
  };
}

export function getCurrencyUnitTooltipMessage(
  underlyingUnit: string | undefined,
  quoteUnit: string | undefined,
) {
  return underlyingUnit !== quoteUnit
    ? 'Beware, this CCY is different from the CCY of the underlying'
    : '';
}

export function getAveragePriceTooltipMessage(
  averagePriceValue: number | undefined,
  averagePriceUnit: string | undefined,
): string {
  return averagePriceValue && averagePriceUnit
    ? `Av. Price: ${averagePriceValue} ${averagePriceUnit}`
    : '';
}

type QuotesModelGetter = (
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
) => StrategyAndLegsQuoteModel;

export function getQuotesModelGetter(
  getStrategyQuoteModelFn: typeof getStrategyQuoteModel,
  getLegQuoteModelFn: typeof getLegQuoteModel,
): QuotesModelGetter {
  return (appState: AppState, rfqId: string, strategyId: string, selectors: Selectors) => {
    const neosSelectors = selectors;
    const { scope, weight } = neosSelectors.getStrategyData(appState, strategyId);
    const isReadonlyRfq = neosSelectors.isReadOnlyRfq(appState, rfqId);

    if (scope === 'DELTA_EXCHANGE') {
      return {
        isReadonlyRfq,
        quotesModelDiscriminator: 'EmptyQuotesModel',
        isMasterStrategy: false,
        areFairPricesDisplayed: false,
        areSalesPricesDisplayed: false,
        isTraderDeltaDisplayed: false,
        areElsPtmmmDisplayed: false,
        isMarkupDisplayed: true,
      };
    }

    const { clientWay } = neosSelectors.getRfqData(appState, rfqId);

    const strategyQuotes = getStrategyQuoteModelFn(appState, strategyId, clientWay, selectors);
    const {
      displayedPricesLegIds: legsToDisplayIds,
      displayReadonlyCellsForCompositionLeg: displayEmptyFirstLeg,
    } = selectors.getIdsOfLegsWithDisplayedPrices(appState, rfqId, strategyId, selectors);

    const isStatusAfterOrderBeingCrossed = selectors.isStatusAfterOrderBeingCrossed(
      appState,
      rfqId,
      selectors,
    );

    const legsQuotes = legsToDisplayIds.map(legId =>
      getLegQuoteModelFn(
        appState,
        rfqId,
        legId,
        weight,
        clientWay,
        isStatusAfterOrderBeingCrossed,
        selectors,
      ),
    );

    const isUnderlyingSelected = neosSelectors.isStrategyUnderlyingsSet(
      appState,
      strategyId,
      selectors,
    );

    const availableMarginRules = selectors.getSalesMarginRules(appState.referenceData);

    const areFairPricesDisplayed = neosSelectors.areFairPricesDisplayed(appState, rfqId, selectors);
    const areElsPtmmmDisplayed = neosSelectors.areElsPtmmmDisplayed(appState, rfqId, selectors);
    const areSalesPricesDisplayed = selectors.areSalesPricesDisplayed(
      appState.ui,
      rfqId,
      selectors,
    );
    const isMarkupDisplayed = !selectors.isRfqInitiatedByTrader(appState, rfqId);

    const { deltaToggle } = appState.ui.userPreferences;
    const isTraderDeltaDisplayed = selectors.isTraderDeltaDisplayed(
      deltaToggle,
      appState,
      rfqId,
      selectors,
    );

    const areTraderPricesEnabled = selectors.areTraderPricesEnabled(
      appState,
      rfqId,
      strategyId,
      selectors,
    );

    const masterProduct = neosSelectors.getStrategyMasterProduct(appState, strategyId, selectors);
    const underlyingId =
      !isCustomUnderlyingProduct(masterProduct) &&
      neosSelectors.getUnderlyingOrRefIdOfStrategy(appState, strategyId, selectors);

    const quoteId = underlyingId && neosSelectors.getStrategyData(appState, strategyId).quoteId;

    const areUnitsDifferent = !!(
      underlyingId &&
      quoteId &&
      selectors.areUnitsDifferent(appState, masterProduct.uuid, quoteId, selectors)
    );
    const isDeltaMixed =
      underlyingId && selectors.isDeltaMixed(appState, rfqId, underlyingId, selectors);

    const areTraderDeltaEnabled = !isReadonlyRfq && isUnderlyingSelected && !isDeltaMixed;

    const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
      appState,
      strategyId,
      selectors,
    );
    const isReadonly = !areTraderDeltaEnabled || isReadonlyAtWorkflow;

    return {
      quotesModelDiscriminator: 'FullQuotesModel',
      strategyQuotes,
      legsQuotes,
      displayEmptyFirstLeg,
      availableMarginRules,
      areFairPricesDisplayed,
      areElsPtmmmDisplayed,
      areSalesPricesDisplayed,
      isTraderDeltaDisplayed,
      areTraderPricesEnabled,
      areTraderDeltaEnabled,
      areUnitsDifferent,
      isReadonly,
      isMarkupDisplayed,
    };
  };
}

function computeTraderWarnings(
  selectors: Selectors,
  state: AppState,
  rfqId: string,
  id: string,
): Record<'traderAsk' | 'traderBid', string | undefined> {
  const traderWarning = {} as Record<'traderAsk' | 'traderBid', string | undefined>;
  const traderAskFieldChange = selectors.traderAskNotificationChangesCrudSelectors.find(
    state.warnings.traderAskNotificationChanges,
    { rfqId, id },
  );
  const traderAskWarning =
    traderAskFieldChange && traderAskFieldChange.oldValue
      ? `The Trader Ask has been changed! old value: ${traderAskFieldChange.oldValue}`
      : undefined;
  traderWarning['traderAsk'] = traderAskWarning;

  const traderBidFieldChange = selectors.traderBidNotificationChangesCrudSelectors.find(
    state.warnings.traderBidNotificationChanges,
    { rfqId, id },
  );
  const traderBidWarning =
    traderBidFieldChange && traderBidFieldChange.oldValue
      ? `The Trader Bid has been changed! old value: ${traderBidFieldChange.oldValue}`
      : undefined;
  traderWarning['traderBid'] = traderBidWarning;
  return traderWarning;
}

export const getQuotesModel = getQuotesModelGetter(getStrategyQuoteModel, getLegQuoteModel);
