import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { switchUnit } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/FeesBloc/ExecFeesUnitSwitcher';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';

export function ExecFeesOut() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const shouldBeEditable = useAppSelector(state =>
    selectors.isSecondaryEditable(state, rfqId, selectors),
  );
  const execFeesFeature = useAppSelector(state =>
    selectors.getFeature(state.featureState, {
      strategyId,
      type: 'EXEC_FEES',
    }),
  );

  const onExecFeesOutUnitClick = () => {
    const newUnit = switchUnit(execFeesFeature?.execFeesOut?.type ?? 'BIPS');
    dispatch(
      actionCreators.neos.featureCrudActions.update(
        { strategyId, type: 'EXEC_FEES' },
        { execFeesOut: { ...execFeesFeature?.execFeesOut, ...newUnit } },
      ),
    );
  };

  if (!isElsProduct(product)) {
    return null;
  }

  const onExecFeesOutChange = (newExecFeesOut: number | undefined) => {
    dispatch(
      actionCreators.neos.featureCrudActions.update(
        { strategyId, type: 'EXEC_FEES' },
        {
          execFeesOut: {
            ...(execFeesFeature?.execFeesOut ?? {
              unit: 'bp',
              type: 'BIPS',
            }),
            value: newExecFeesOut,
          },
        },
      ),
    );
  };

  return (
    <ElsBlocField
      label="Exec Fees Out"
      renderInput={readOnly => (
        <NumericInput
          readOnly={readOnly && !shouldBeEditable}
          value={execFeesFeature?.execFeesOut?.value}
          onBlur={(val: number | undefined) => onExecFeesOutChange(val)}
          onUnitClick={onExecFeesOutUnitClick}
          unit={execFeesFeature?.execFeesOut?.unit ?? 'bp'}
          data-e2e="fees-block-exec-fees-out"
        />
      )}
    />
  );
}
