import type { Selectors } from '@/bootstrap/selectors';
import type { MapToOnyxCommonAllocationResult } from '@/neos/business/rfq/strategy/leg/legMapper';
import type { OnyxDeltaStockListedAllocation } from '../../../../../../neos/business/neosOnyxModel';
import type { DeltaStockListedAllocation } from './deltaStockListedAllocationModel';
import type { AppState } from '@/bootstrap/store.ts';

export const toDeltaStockListedAllocationMappers = {
  mapToDeltaStockListedAllocations,
};

export const fromDeltaStockListedAllocationMappers = {
  mapFromDeltaStockListedAllocations,
};

function mapToDeltaStockListedAllocations(
  state: AppState,
  legId: string,
  selectors: Selectors,
): MapToOnyxCommonAllocationResult<OnyxDeltaStockListedAllocation> {
  const legDeltaStockAllocations = selectors.getDeltaStockListedAllocationsByLegId(state, legId);

  const activeAllocations = legDeltaStockAllocations.filter(allocation => !allocation.isCancelled);
  const cancelledAllocations = legDeltaStockAllocations.filter(
    allocation => allocation.isCancelled,
  );

  return {
    activeOnyxAllocations: innerMapToOnyxDeltaStockListedAllocations(activeAllocations),
    cancelledOnyxAllocations: innerMapToOnyxDeltaStockListedAllocations(cancelledAllocations),
  };
}

function innerMapToOnyxDeltaStockListedAllocations(
  allocations: DeltaStockListedAllocation[],
): OnyxDeltaStockListedAllocation[] {
  return allocations.map(
    ({ uuid, counterpartId, comment, numberOfLots, broker, externalReferenceId }) => ({
      negotiationMode: 'LISTED',
      uuid,
      counterparty: counterpartId ? { id: counterpartId } : undefined,
      comment,
      negotiatedSize:
        numberOfLots !== undefined
          ? {
              numberOfLots,
              sizeType: 'FIXED_QUANTITY',
            }
          : undefined,
      broker,
      externalReferenceId,
    }),
  );
}

function mapFromDeltaStockListedAllocations(
  legId: string,
  {
    comment,
    counterparty,
    negotiatedSize,
    uuid,
    broker,
    externalReferenceId,
  }: OnyxDeltaStockListedAllocation,
  isCancelled: 'active' | 'cancelled',
): DeltaStockListedAllocation {
  return {
    uuid,
    isCancelled: isCancelled === 'cancelled',
    comment,
    counterpartId: counterparty?.id,
    legId,
    numberOfLots: negotiatedSize?.numberOfLots,
    broker,
    externalReferenceId,
  };
}
