import type { Selectors } from '@/bootstrap/selectors';
import { filter, isUndefined, reduce, size, sortedUniq } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';

export interface NotionalModel {
  notionalAmount: number | undefined;
  notionalCurrency: string | undefined;
  notionalDisabled: boolean;
  notinalCurrencyEditable: boolean;

  extraNotionalAmount: number | undefined;
  extraNotionalCurrency: string | undefined;
  extraDisabled: boolean;

  notionalInSwapCurrencyAmount: number | undefined;
  notionalInSwapCurrencyCurrency: string | undefined;
  notionalInSwapCurrencyIsDisplayed: boolean | undefined;
  currencyOptions: string[];
  isExtraNotionalCurrencySwitchable: boolean;
  notionalWarning: string | undefined;
}

export function getNotionalModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): NotionalModel {
  const {
    notionalAmount,
    notionalCurrency,
    pivotNotionalAmount,
    pivotNotionalCurrency,
    usdNotionalAmount,
    usdNotionalCurrency,
    eurNotionalAmount,
    eurNotionalCurrency,
    warnings,
    notionalInSwapCurrencyAmount,
    notionalInSwapCurrencyCurrency,
  } = selectors.getRfqData(state, rfqId);

  const notionalDisabled = selectors.isReadOnlyRfq(state, rfqId);

  const notinalCurrencyEditable = selectors.isRfqCustomUnderlying(state, rfqId, selectors);

  const selectedPivotNotionalCurrency = selectors.getSelectedPivotNotionalCurrency(state.ui, rfqId);
  const isPivotNotional = notionalCurrency !== pivotNotionalCurrency;

  const [extraNotionalCurrency, extraNotionalAmount] = getExtraNotionalValues(
    isPivotNotional,
    notionalCurrency,
    usdNotionalCurrency,
    usdNotionalAmount,
    eurNotionalCurrency,
    eurNotionalAmount,
    pivotNotionalCurrency,
    pivotNotionalAmount,
    selectedPivotNotionalCurrency,
  );

  const isCurrencyUnavailable = !notionalCurrency || !extraNotionalCurrency;
  const extraDisabled = notionalDisabled || isCurrencyUnavailable;
  const currencyOptions = sortedUniq(
    filter(
      [pivotNotionalCurrency || '', usdNotionalCurrency || '', eurNotionalCurrency || ''],
      currency => !!currency && currency !== notionalCurrency,
    ).sort(),
  );

  // The model is weird, I decided to read the first entry in the object by default because the Id (key) is not related to anything
  const notionalWarning = warnings?.LEG_BIG_NOMINAL
    ? Object.values(warnings.LEG_BIG_NOMINAL).at(0)
    : undefined;

  const notionalInSwapCurrencyIsDisplayed =
    notionalInSwapCurrencyCurrency !== undefined &&
    notionalInSwapCurrencyCurrency !== notionalCurrency &&
    notionalInSwapCurrencyCurrency !== extraNotionalCurrency;

  return {
    notionalAmount,
    notinalCurrencyEditable,
    notionalWarning,
    notionalDisabled,
    notionalCurrency,
    extraNotionalAmount,
    extraNotionalCurrency,
    extraDisabled,
    currencyOptions,

    isExtraNotionalCurrencySwitchable:
      isPivotNotional &&
      !extraDisabled &&
      !isUndefined(extraNotionalAmount) &&
      size(currencyOptions) > 1,
    notionalInSwapCurrencyAmount,
    notionalInSwapCurrencyCurrency,
    notionalInSwapCurrencyIsDisplayed,
  };
}

function getExtraNotionalValues(
  isPivotNotional: boolean,
  notionalCurrency: string | undefined,
  usdNotionalCurrency: string | undefined,
  usdNotionalAmount: number | undefined,
  eurNotionalCurrency: string | undefined,
  eurNotionalAmount: number | undefined,
  pivotNotionalCurrency: string | undefined,
  pivotNotionalAmount: number | undefined,
  selectedPivotNotionalCurrency: string | undefined,
): [string | undefined, number | undefined] {
  if (!isPivotNotional) {
    if (notionalCurrency !== usdNotionalCurrency) {
      return [usdNotionalCurrency, usdNotionalAmount];
    }
    if (notionalCurrency !== eurNotionalCurrency) {
      return [eurNotionalCurrency, eurNotionalAmount];
    }
    return [undefined, undefined];
  }
  const notionals: { [notional: string]: [string | undefined, number | undefined] } = reduce(
    [
      [pivotNotionalCurrency, pivotNotionalAmount],
      [usdNotionalCurrency, usdNotionalAmount],
      [eurNotionalCurrency, eurNotionalAmount],
    ],
    (acc, [currency, amount]) => {
      if (currency) {
        acc = { ...acc, [currency]: [currency, amount] };
      }
      return acc;
    },
    {},
  );
  const selectedNotional =
    selectedPivotNotionalCurrency && selectedPivotNotionalCurrency !== notionalCurrency
      ? selectedPivotNotionalCurrency
      : pivotNotionalCurrency;
  return selectedNotional
    ? notionals[selectedNotional] || [pivotNotionalCurrency, pivotNotionalAmount]
    : [undefined, undefined];
}
