import type { Selectors } from '@/bootstrap/selectors';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import type { AppState } from '@/bootstrap/store.ts';

export type IsEffectiveDateCellsModel = { isEditable: boolean };

export function getIsEffectiveDateCellsEditableModel(
  appState: AppState,
  { strategyId, rfqId }: { strategyId: string; rfqId: string },
  selectors: Selectors,
): IsEffectiveDateCellsModel {
  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isStrategyDefinitionFieldEditable = getIsStrategyDefinitionFieldEditable(
    appState,
    rfqId,
    strategyId,
    selectors,
    ['RFQ_SCOPE', 'RFQ_EDITABLE', 'UNDERLYING_SET', 'STRATEGY_EDITABLE'],
  );
  const isEditable = isStrategyDefinitionFieldEditable && !isReadonlyAtWorkflow;

  return { isEditable };
}
