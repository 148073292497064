import type { Layout } from 'react-grid-layout';

export const elsSectionNames = [
  'Product Details',
  'Brokers',
  'Lend And Borrow',
  'Rate Convention',
  'Forex Constat / Type',
  'Equity Leg',
  'Early Termination',
  'Dividend Leg',
  'Right to Substitute',
  'Fees',
  'Buy & Sell',
  'Other',
] as const;

export type ElsSectionName = (typeof elsSectionNames)[number];

export interface ElsSectionLayout extends Layout {
  i: ElsSectionName;
  isDisplayed: boolean;
}

export const initialElsSectionsLayout: ElsSectionLayout[] = [
  {
    w: 25,
    h: 5,
    x: 0,
    y: 0,
    i: 'Product Details',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 30,
    h: 18,
    x: 0,
    y: 0,
    i: 'Brokers',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 30,
    h: 11,
    x: 0,
    y: 0,
    i: 'Rate Convention',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 24,
    h: 5,
    x: 0,
    y: 1,
    i: 'Forex Constat / Type',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 18,
    h: 18,
    x: 0,
    y: 0,
    i: 'Dividend Leg',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 22,
    h: 11,
    x: 0,
    y: 0,
    i: 'Equity Leg',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 13,
    h: 7,
    x: 0,
    y: 1,
    i: 'Early Termination',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 13,
    h: 7,
    x: 0,
    y: 0,
    i: 'Right to Substitute',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 20,
    h: 18,
    x: 0,
    y: 0,
    i: 'Fees',
    minW: 5,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 15,
    h: 7,
    x: 0,
    y: 0,
    i: 'Other',
    minW: 5,
    moved: false,
    static: false,
    isDisplayed: true,
    isResizable: true,
  },
  {
    w: 33,
    h: 18,
    x: 0,
    y: 4,
    i: 'Lend And Borrow',
    minW: 30,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
  {
    w: 33,
    h: 18,
    x: 0,
    y: 5,
    i: 'Buy & Sell',
    minW: 30,
    moved: false,
    static: false,
    isResizable: true,
    isDisplayed: true,
  },
];
