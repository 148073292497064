import { exportDataToExcel } from '@/util/excel/excel.ts';
import type { CompositionLeg } from '@/neos/components/rfq/strategies/strategy/compositionLegs/getCompositionLegsModel.ts';
import { ExportButton } from '@/neos/components/share/importExportClipboard/ExportButton.tsx';
import type { ImportedCompositionLegsData } from '@/neos/business/rfq/thunks/rfqSetUpImportedCompositionLegsThunk.ts';
import { type Selectors, selectors } from '@/bootstrap/selectors.ts';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import type { AppState } from '@/bootstrap/store.ts';

type CompositionLegsExportProps = {
  exportTitle: string;
  hasCompositionLegs: boolean;
  allCompositionLegs: CompositionLeg[];
};

export const CompositionLegsExport = ({
  exportTitle,
  hasCompositionLegs,
  allCompositionLegs,
}: CompositionLegsExportProps) => {
  const exportedContent = useAppSelector(state =>
    getCompositionLegsExportedContent(state, allCompositionLegs, selectors),
  );
  const isButtonDisabled = !hasCompositionLegs;

  const handleExport = () => {
    exportDataToExcel(exportedContent, exportTitle);
  };

  return (
    <ExportButton
      handleExport={handleExport}
      isDisabled={isButtonDisabled}
      dataE2e="composition-legs-export"
    />
  );
};

function getCompositionLegsExportedContent(
  state: AppState,
  allCompositionLegs: CompositionLeg[],
  selectors: Selectors,
): ImportedCompositionLegsData[] {
  const indexLeg = allCompositionLegs.filter(compo => compo.isMaster).at(0);
  const compositionLegs = allCompositionLegs.filter(compo => !compo.isMaster);

  const allLegs = [{ type: 'I', ...indexLeg }].concat(
    compositionLegs.map(c => ({ type: 'C', ...c })),
  );

  return allLegs.map(({ bloombergCode, type, weight, size, expectedN, volStrike, productId }) => ({
    underlying: bloombergCode,
    type,
    weight,
    size,
    unit: selectors.getUnderlyingUnit(state, productId!, selectors),
    expectedN,
    volStrike,
  }));
}
