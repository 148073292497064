import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { flattenDeep } from 'lodash';
import type { Middleware, MiddlewareAPI } from 'redux';
import type { AppState } from '@/bootstrap/store.ts';

export function createActionContainerMiddleware(): Middleware {
  return (_: MiddlewareAPI<ExtendedDispatch, AppState>) =>
    next =>
    (...actions) => {
      const subActions = flattenDeep(actions);
      subActions.forEach(a => {
        next(a);
      });
    };
}
