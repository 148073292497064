import type { Selectors } from '@/bootstrap/selectors';
import { getIsStrategyDefinitionFieldEditable } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/fieldsEnablingConditions';
import { isElsProduct } from '../../../../../../../neos/business/neosModel';
import type { ElsType } from '../../../../../../../neos/business/neosOnyxModel';
import type { Field } from '../utils/Field';
import { getFieldsArray } from '../utils/getFieldsArray';
import type { AppState } from '@/bootstrap/store.ts';

export interface ElsTypeCellsModel {
  elsTypes: Array<Field<ElsType | undefined>>;
  availableElsTypes: Array<ElsType | undefined>;
}

export function getElsTypeCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ElsTypeCellsModel {
  const { getStrategyData, getStrategyProducts } = selectors;
  const { getStrategyDefinition } = selectors;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductElsType, readOnlyProductElsType } = getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  const products = getStrategyProducts(appState, strategyId, selectors).filter(isElsProduct);

  const elsTypesAreEditable = getIsStrategyDefinitionFieldEditable(
    appState,
    rfqId,
    strategyId,
    selectors,
    ['RFQ_SCOPE', 'RFQ_EDITABLE', 'STRATEGY_EDITABLE'],
  );

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isReadonly = readOnlyProductElsType || !elsTypesAreEditable || isReadonlyAtWorkflow;
  const elsTypes = getFieldsArray(products, 'elsType', sameProductElsType, isReadonly);

  return {
    elsTypes,
    availableElsTypes: ['PRS', 'TRS', undefined],
  };
}
