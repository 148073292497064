import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { LegRecomputeScope } from '@/neos/business/rfq/models';
import type { UnderlyingType } from '@/neos/business/rfq/strategy/leg/legOnyxModel';
import type {
  BasketCompositionDetails,
  ElsBasket,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { UnderlyingInput } from '@/neos/components/rfq/strategies/strategy/common';
import { switchUnit } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/FeesBloc/ExecFeesUnitSwitcher';
import { If } from '@/neos/components/share/if/if';
import { NumericInput } from '@/neos/components/share/numericInput';

type GridRowProps = {
  index: number;
  compositionDetails: BasketCompositionDetails;
  rfqId: string;
  product: ElsBasket;
  underlyingTypes: UnderlyingType[];
  exclude: string[];
};

export function BasketCompositionDetailsGridRow(gridRowProps: GridRowProps) {
  const { product, index, compositionDetails, rfqId, underlyingTypes, exclude } = gridRowProps;

  const dispatch = useAppDispatch();
  const { underlyingId, execFeesOut, execFeesIn } = compositionDetails;
  const underlyingDetails = useAppSelector(state =>
    selectors.getUnderlyingInfo(state, underlyingId),
  );

  const reference = useAppSelector(state =>
    underlyingId ? selectors.getReference(state, { rfqId, underlyingId }) : undefined,
  );

  function changeExecFeesInUnit() {
    const newUnit = switchUnit(execFeesIn?.type ?? 'BIPS');
    dispatch(
      thunks.neos.createUpdateBasketCompositionFeesUnit(rfqId, product.uuid, newUnit, 'execFeesIn'),
    );
  }

  function changeExecFeesOutUnit() {
    const newUnit = switchUnit(execFeesOut?.type ?? 'BIPS');
    dispatch(
      thunks.neos.createUpdateBasketCompositionFeesUnit(
        rfqId,
        product.uuid,
        newUnit,
        'execFeesOut',
      ),
    );
  }

  function onDelete() {
    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        basketUnderlying: {
          ...product.basketUnderlying,
          basketComposition: product.basketUnderlying.basketComposition.toSpliced(index, 1),
        },
      }),
    );
    dispatch(thunks.neos.createDefaultBasketProductThunk(rfqId));
  }

  return (
    <div style={{ display: 'contents' }}>
      <button className="btn btn-icon btn-flat-primary" onClick={onDelete}>
        <i className="icon icon-md">delete_forever</i>
      </button>
      <UnderlyingInput
        autoFocus={
          underlyingId === undefined &&
          underlyingDetails?.bloombergCode === undefined &&
          index === product.basketUnderlying.basketComposition.length - 1
        }
        componentId={`neos-composition-underlying-${product.legId}-${gridRowProps.compositionDetails.containerIndex}`}
        underlyingId={underlyingId}
        bloombergCode={underlyingDetails?.bloombergCode}
        underlyingTypes={underlyingTypes}
        positionFixed
        data-e2e="neos-els-basket-underlying"
        exclude={exclude}
        onChange={event => {
          const newUnderlyingId = event?.value;
          dispatch(
            thunks.neos.createUpdateElsBasketCompositionUnderlyingThunk(
              product,
              rfqId,
              newUnderlyingId,
              index,
            ),
          );
        }}
        className="menu-in-modal"
      />
      <NumericInput
        readOnly
        value={compositionDetails.weight}
        maximumFractionDigits={2}
        onlyPositiveNumbers
      />
      <NumericInput
        value={compositionDetails.quantity}
        disabled={underlyingId === undefined}
        onBlur={value => {
          dispatch(
            thunks.neos.createUpdateElsBasketCompositionThunk(product, index, 'quantity', value),
          );
          dispatch(
            actionCreators.neos.createRecomputeRfqRequestedAction(
              rfqId,
              LegRecomputeScope.CHANGE_QUANTITY_STRAT_MASTER_LEG_MASTER,
            ),
          );
        }}
        maximumFractionDigits={2}
      />
      <NumericInput
        value={reference?.refSpot}
        unit={reference?.refSpotUnit}
        disabled={underlyingId === undefined}
        onBlur={value => {
          if (underlyingId) {
            dispatch(actionCreators.neos.createSpotChangedAction({ underlyingId, rfqId }, value));
          }
        }}
      />

      <If
        condition={
          product.basketUnderlying.execFees === 'IN' ||
          product.basketUnderlying.execFees === 'IN_AND_OUT'
        }
      >
        <NumericInput
          value={compositionDetails.execFeesIn?.value}
          unit={execFeesIn?.unit ?? 'bp'}
          onUnitClick={changeExecFeesInUnit}
          disabled={underlyingId === undefined}
          onBlur={value => {
            dispatch(
              thunks.neos.createUpdateElsBasketCompositionThunk(product, index, 'execFeesIn', {
                value,
                unit: compositionDetails.execFeesIn?.unit ?? 'bp',
                type: compositionDetails.execFeesIn?.type ?? 'BIPS',
              }),
            );
            dispatch(
              actionCreators.neos.createRecomputeRfqRequestedAction(
                rfqId,
                LegRecomputeScope.CHANGE_EXEC_FEE,
              ),
            );
          }}
        />
      </If>

      <If
        condition={
          product.basketUnderlying.execFees === 'OUT' ||
          product.basketUnderlying.execFees === 'IN_AND_OUT'
        }
      >
        <NumericInput
          value={compositionDetails.execFeesOut?.value}
          unit={execFeesOut?.unit ?? 'bp'}
          disabled={underlyingId === undefined}
          onUnitClick={changeExecFeesOutUnit}
          onBlur={value => {
            dispatch(
              thunks.neos.createUpdateElsBasketCompositionThunk(product, index, 'execFeesOut', {
                value,
                unit: compositionDetails.execFeesOut?.unit ?? 'bp',
                type: compositionDetails.execFeesOut?.type ?? 'BIPS',
              }),
            );
            dispatch(
              actionCreators.neos.createRecomputeRfqRequestedAction(
                rfqId,
                LegRecomputeScope.CHANGE_EXEC_FEE,
              ),
            );
          }}
        />
      </If>

      <NumericInput
        readOnly
        value={reference?.refSpotNet}
        unit={reference?.refSpotNetUnit}
        disabled={underlyingId === undefined}
      />
      <NumericInput
        readOnly
        value={reference?.refSpotNetSwapCurrency}
        unit={reference?.refSpotNetSwapCurrencyUnit}
        disabled={underlyingId === undefined}
      />
      <NumericInput
        readOnly
        value={compositionDetails?.nominal?.value}
        unit={compositionDetails?.nominal?.unit}
        maximumFractionDigits={2}
        onlyPositiveNumbers
      />
    </div>
  );
}
