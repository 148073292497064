import type { ChainOptions } from '@/neos/business/neosActions';
import type { RecomputeScope } from '../models/recomputeModel';
import { createRecomputeRfqRequestedAction } from './recomputeRfqRequestedAction';
import type { Action } from '@/bootstrap/actions.ts';

export function createActionWithRecompute(
  rfqId: string,
  scope: RecomputeScope,
  actions: Action[],
  chainOptions?: ChainOptions,
): Action[] {
  return [...actions, createRecomputeRfqRequestedAction(rfqId, scope, chainOptions)];
}
