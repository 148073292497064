import type { FC } from 'react';
import { NeosBlurInput } from '../../../../../share/blurComponent/NeosBlurInput';
import { ErrorHighlight } from '../../../../../share/errorHighlight';
import type { TypeaheadValue } from '../../../../../share/typeahead';
import { UnderlyingInput } from '../../common';
import type { UnderlyingCellsModel } from './getUnderlyingCellsModel';
import { VolVarDispersionUnderlyingActions } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/underlyingCells/VolVarDispersionUnderlyingActions/VolVarDispersionUnderlyingActions.tsx';

import styles from './UnderlyingCells.module.scss';

export interface UnderlyingCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

export interface UnderlyingCellsMapStateProps {
  model: UnderlyingCellsModel;
  hasCompositionLeg: boolean;
}

export interface UnderlyingCellsDispatchProps {
  onUnderlyingChanged(legId: string, underlyingId: string | undefined): void;

  onUnderlyingNameChanged(legId: string, underlyingName: string | undefined): void;

  onUnderlyingFieldFocused(legId: string): void;

  onUnderlyingFieldBlurred(legId: string, underlyingId: string | undefined): void;
}

type UnderlyingCellsProps = UnderlyingCellsOwnProps &
  UnderlyingCellsMapStateProps &
  UnderlyingCellsDispatchProps;

export const UnderlyingCellsComponent: FC<UnderlyingCellsProps> = props => {
  return (
    <section>
      {props.model.selectedUnderlyings.map(underlying => {
        if (underlying.type === 'BASKET') {
          return (
            <input
              key={underlying.legId}
              className="input-sm form-control"
              value="BASKET"
              readOnly={true}
              data-e2e="neos-strategy-definition-underlying"
            />
          );
        }

        const { legId } = underlying;

        if (underlying.type === 'NON_CUSTOM') {
          const { underlyingId, bloombergCode, underlyingTypes } = underlying;
          return (
            <ErrorHighlight
              key={legId}
              errorField={'underlying'}
              related={{ rfqId: props.rfqId, legId, strategyId: props.strategyId }}
            >
              <div className={`${styles['underlying-cell']}`}>
                <UnderlyingInput
                  componentId={`neos-underlying-${props.strategyId}`}
                  underlyingId={underlyingId}
                  bloombergCode={bloombergCode}
                  readOnly={props.model.disabled || props.isReadOnlyRfq || underlying.isDisabled}
                  underlyingTypes={underlyingTypes}
                  data-e2e="neos-strategy-definition-underlying"
                  onChange={(val: TypeaheadValue | undefined) =>
                    props.onUnderlyingChanged(legId, val && val.value)
                  }
                  onFocus={() => props.onUnderlyingFieldFocused(legId)}
                  onBlur={(val: TypeaheadValue | undefined) =>
                    props.onUnderlyingFieldBlurred(legId, val && val.value)
                  }
                />
              </div>
            </ErrorHighlight>
          );
        }

        return (
          <ErrorHighlight
            key={legId}
            errorField={'underlying'}
            related={{ rfqId: props.rfqId, legId, strategyId: props.strategyId }}
          >
            <NeosBlurInput
              className="input-sm"
              value={underlying.name || ''}
              disabled={props.model.disabled}
              readOnly={props.model.disabled || props.isReadOnlyRfq || underlying.isDisabled}
              data-e2e="neos-strategy-definition-underlying"
              onBlur={val => {
                props.onUnderlyingNameChanged(legId, val?.target?.value);
              }}
            />
          </ErrorHighlight>
        );
      })}

      {props.hasCompositionLeg && (
        <VolVarDispersionUnderlyingActions rfqId={props.rfqId} strategyId={props.strategyId} />
      )}
    </section>
  );
};
