import type { ColDef, ValueFormatterParams, ValueParserParams } from '@ag-grid-community/core';
import { selectors } from '@/bootstrap/selectors.ts';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useMemo, useState } from 'react';
import type { UserPreferencesStrategyTraderGroup } from '@/neos/business/currentUserPreferences/currentUserPreferencesModel.ts';
import type { StrategyTypeWithLabel } from '@/neos/business/referenceData/referenceDataSelectors.ts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { thunks } from '@/bootstrap/thunks.ts';
import { If } from '@/neos/components/share/if/if.tsx';

const defaultColDef: ColDef = {
  resizable: true,
  sortable: true,
  suppressMovable: true,
};

function getInitialRows(
  strategyTraderGroupRowData: UserPreferencesStrategyTraderGroup[] | undefined,
  availableStrategies: StrategyTypeWithLabel[],
): UserPreferencesStrategyTraderGroup[] {
  return availableStrategies.map(strat => {
    const strategyId = strat.id;
    const traderGroupId = strategyTraderGroupRowData?.find(
      row => row.strategyId === strategyId,
    )?.traderGroupId;
    return { strategyId, traderGroupId };
  });
}

export function DefaultTradingGroupPreferences() {
  const dispatch = useAppDispatch();
  const availableStrategies = useAppSelector(state =>
    selectors.getAvailableStrategies(state.referenceData, 'RFQ', selectors),
  );
  const traderGroups = useAppSelector(state => selectors.getTraderGroups(state.referenceData));
  const strategyTraderGroupRowData = useAppSelector(state =>
    selectors.currentUserPreferencesSelectors.selectStrategyTraderGroup(state),
  );

  const [rowData] = useState<UserPreferencesStrategyTraderGroup[]>(
    getInitialRows(strategyTraderGroupRowData, availableStrategies),
  );
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);

  const columnDefs: ColDef<UserPreferencesStrategyTraderGroup>[] = useMemo(
    () => [
      {
        field: 'strategyId',
        headerName: 'Strategy Id',
        editable: false,
        width: 100,
        flex: 1,
        valueFormatter: ({
          value: stratId,
        }: ValueFormatterParams<UserPreferencesStrategyTraderGroup, string>) => {
          if (stratId == null) {
            return '';
          }
          return availableStrategies.find(strat => strat.id === stratId)?.name ?? '';
        },
      },
      {
        field: 'traderGroupId',
        headerName: 'Trader Group Id',
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: traderGroups.map(trader => trader.id),
          searchType: 'match',
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        },
        flex: 2,
        valueFormatter: ({
          value: groupId,
        }: ValueFormatterParams<UserPreferencesStrategyTraderGroup, number>) => {
          if (groupId == null) {
            return '';
          }
          return traderGroups.find(group => group.id === groupId)?.value ?? '';
        },
        valueParser: ({
          newValue: groupIdString,
        }: ValueParserParams<UserPreferencesStrategyTraderGroup, number>) => {
          if (groupIdString == null) {
            return undefined;
          }
          return traderGroups.find(group => group.id.toString() === groupIdString)?.id ?? undefined;
        },
      },
    ],
    [availableStrategies, traderGroups],
  );

  return (
    <div className="h-100">
      <div className="d-flex justify-content-between mb-3">
        <h6 className="align-content-center">Default Trader Groups</h6>
        <If condition={showSaveButton}>
          <button
            className="btn btn-primary"
            onClick={() => {
              dispatch(thunks.neos.createUpdateAndPostStrategyTraderGroupsThunk(rowData));
              setShowSaveButton(false);
            }}
          >
            Save
          </button>
        </If>
      </div>
      <AgGridReact<UserPreferencesStrategyTraderGroup>
        className="ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed"
        onGridReady={({ api }) => api.sizeColumnsToFit()}
        onCellValueChanged={() => setShowSaveButton(true)}
        processCellFromClipboard={params => Number.parseInt(params.value as string)}
        enableRangeSelection
        rowData={rowData}
        columnDefs={columnDefs}
        modules={[
          ClientSideRowModelModule,
          ClipboardModule,
          RichSelectModule,
          RangeSelectionModule,
        ]}
        defaultColDef={defaultColDef}
      />
    </div>
  );
}
