import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { CommonResizableProps } from '../PostNego';
import { SwapsWireAccount } from './SwapsWireAccount';
import { SwapsWireAccountType } from './SwapsWireAccountType';

type ConfirmationDetailsProps = OmitSafe<
  CommonResizableProps,
  'isDeltaPresent' | 'onResize' | 'width'
>;

export const ConfirmationDetails = ({ rfqId }: ConfirmationDetailsProps) => {
  const isMasterStrategyOtc = useAppSelector(state =>
    selectors.isMasterStrategyOtc(state, rfqId, selectors),
  );
  const hasAMarkitWireMediaAllocation = useAppSelector(state => {
    const allocations = selectors.getOtcAllocationsByRfqId(state, rfqId);
    return allocations.some(allocation => allocation.media === 'MARKITWIRE');
  });

  if (!isMasterStrategyOtc || !hasAMarkitWireMediaAllocation) {
    return null;
  }

  return (
    <div>
      <div className="d-flex mb-2 justify-content-between">
        <label>Confirmation details</label>
      </div>
      <div className="p-1 overflow-auto d-flex" style={{ gap: '2px' }}>
        <SwapsWireAccount rfqId={rfqId} />
        <SwapsWireAccountType rfqId={rfqId} />
      </div>
    </div>
  );
};
