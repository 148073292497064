import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { type FC } from 'react';
import type { SizeType } from '../../../../../../../business/neosOnyxModel';
import { ImportButton } from '@/neos/components/share/importExportClipboard/ImportButton.tsx';

export interface ImportOtcAllocationsOwnProps {
  rfqId: string;
}

export interface OtcLegModel {
  legId: string;
  uiSizeType: DisplayNegotiatedSize;
  onChangeSizeType?: SizeType;
  ccy?: string;
  allocIds: string[];
}

export interface ImportOtcAllocationsStateProps {
  legs: OtcLegModel[];
  clients: number[];
}

export interface ImportOtcAllocationsDispatchProps {
  onImport: (rows: unknown[]) => void;
}

export type AllocRow = [string?, string?, string?, string?];

export type ImportOtcAllocationsProps = ImportOtcAllocationsOwnProps &
  ImportOtcAllocationsStateProps &
  ImportOtcAllocationsDispatchProps;

export const ImportOtcAllocationsComponent: FC<ImportOtcAllocationsProps> = props => {
  return (
    <ImportButton
      handleImport={props.onImport}
      dataE2e="import-otc-allocation"
      buttonClass="flat"
      withHeader={true}
    />
  );
};
