import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { SwitcherFeatureType } from '../../../../../../../../neos/business/neosModel';
import {
  FeatureSwitcher as Component,
  type DispatchProps,
  type OwnProps,
  type StateProps,
  type Toggle,
} from './FeatureSwitcher';
import type { AppState } from '@/bootstrap/store.ts';

const labelMapping: Record<SwitcherFeatureType, string> = {
  FORWARD_START: 'Forward Start Date',
  BARRIERS: 'Barriers',
  UP_DOWN: 'Up Down',
  CAP_FLOOR: 'Floor & Cap',
  FOREX_TYPE: 'Forex Constat / Type',
  DESCRIPTION: 'Description',
};

function mapStateToProps(state: AppState, { strategyId, type }: OwnProps): StateProps {
  return {
    label: labelMapping[type],
    visible: selectors.strategyHasFeature(state.featureState, {
      strategyId,
      type,
    }),
    type,
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId, type }: OwnProps,
): DispatchProps {
  return {
    handler: (toggle: Toggle) =>
      dispatch(
        toggle === 'ON'
          ? neosThunks.createAddExtraFeatureThunk(strategyId, type)
          : neosThunks.createRemoveExtraFeatureThunk({ strategyId, type }),
      ),
  };
}

export const FeatureSwitcher = connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
