import type { Selectors } from '@/bootstrap/selectors';
import type { DivSwap } from '../../../../../../business/neosModel';
import { getIsStrategyDefinitionFieldEditable } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/fieldsEnablingConditions.ts';
import type { AppState } from '@/bootstrap/store.ts';

export type StartDateProductModel = {
  productId: string;
  startDate: string | undefined;
};

export type ProductStartDateCellModel = {
  startDatesProducts: StartDateProductModel[];
  isEditable: boolean;
};

function getStartDateProductModel(
  state: AppState,
  productId: string,
  selectors: Selectors,
): StartDateProductModel {
  const { startDate, uuid } = selectors.getProduct(state, productId) as DivSwap;

  return {
    productId: uuid,
    startDate,
  };
}

export function getStartDateCellModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ProductStartDateCellModel {
  const products = selectors.getStrategyProducts(state, strategyId, selectors);
  const startDatesProducts = products.map(product =>
    getStartDateProductModel(state, product.uuid, selectors),
  );
  const isEditable = getIsStrategyDefinitionFieldEditable(state, rfqId, strategyId, selectors, [
    'RFQ_SCOPE',
    'RFQ_EDITABLE',
    'UNDERLYING_SET',
    'STRATEGY_EDITABLE',
  ]);

  return {
    startDatesProducts,
    isEditable,
  };
}
