import type { Selectors } from '@/bootstrap/selectors';
import type { BookingStepStatus } from '@/neos/business/bookingSteps/bookingStepOnyxModel';
import type { ListedAllocation } from '@/neos/business/neosModel';
import type { ExecutionKey } from '@/neos/business/order/orderModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { sum } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';

type QuantitiesStyleName = 'orange-quantities' | 'red-quantities';

export interface ExecutionAllocationsModel {
  allocations: ListedAllocationWithBookingInfo[];
  quantitiesStyleName: QuantitiesStyleName | undefined;
  isValidExecStrategySize: boolean;
}

export interface ListedAllocationWithBookingInfo extends ListedAllocation {
  bookingId?: string;
  bookingStatus?: BookingStepStatus;
  defaultBookingId?: string;
}

export function getExecutionAllocationsModel(
  state: AppState,
  executionKey: ExecutionKey,
  selectors: Selectors,
): ExecutionAllocationsModel {
  const allocations = selectors.listedAllocationSelectors.selectObjects(
    state.listedAllocationState,
    { executionId: executionKey.executionId },
  );

  const allocationsWithBookingInfos = allocations.map(allocation => {
    const bookingInfo = selectors.getAllocationBookingStep(
      state,
      executionKey.rfqId,
      allocation.uuid,
    );

    return {
      ...allocation,
      bookingId: allocation?.externalReferenceId?.id
        ? allocation?.externalReferenceId?.id
        : bookingInfo?.bookingId,
      bookingStatus: allocation?.externalReferenceId?.id ? undefined : bookingInfo?.status,
      defaultBookingId: bookingInfo?.bookingId,
    };
  });

  const isValidExecStrategySize = selectors.isValidListedAllocExecStrategySize(
    state,
    executionKey,
    selectors,
  );

  const quantitiesStyleName = getQuantitiesStyleName();

  return {
    allocations: allocationsWithBookingInfos,
    quantitiesStyleName,
    isValidExecStrategySize,
  };

  function getQuantitiesStyleName(): QuantitiesStyleName | undefined {
    const definedNumberOfLots = allocations
      .filter(allocation => !allocation.isCancelled)
      .map(({ numberOfLots }) => numberOfLots)
      .filter(isDefined);

    if (!definedNumberOfLots.length) {
      return undefined;
    }

    const executionNumberOfLots = selectors.executionSelectors.find(state.execution, executionKey)
      ?.size?.numberOfLots;
    if (executionNumberOfLots === undefined) {
      return undefined;
    }

    const allocationsNumberOfLotsSum = sum(definedNumberOfLots);

    if (executionNumberOfLots === allocationsNumberOfLotsSum) {
      return undefined;
    }

    return executionNumberOfLots < allocationsNumberOfLotsSum
      ? 'red-quantities'
      : 'orange-quantities';
  }
}
