import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export interface ReplicateInDeltaButtonModel {
  isDisabled: boolean;
}

export function getReplicateInDeltaButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ReplicateInDeltaButtonModel {
  const { listedPreAllocs, otcPreAllocs } = selectors.getRfqData(state, rfqId);
  const executionDeltaStrategies = selectors.getAllExecutionDeltaStrategies(
    state,
    rfqId,
    selectors,
  );
  return {
    isDisabled: !listedPreAllocs.length || !otcPreAllocs.length || !executionDeltaStrategies.length,
  };
}
