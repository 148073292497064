import { useAppSelector } from '@/bootstrap/hooks';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import {
  type BasisType,
  basisTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { FormSelect } from 'react-bootstrap';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function Basis() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, basisType } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onBasisTypeChange = (newBasisType: BasisType | undefined) => {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(updateProduct(uuid, { basisType: newBasisType }));
  };

  return (
    <ElsBlocField
      label="Basis"
      renderInput={readOnly => (
        <FormSelect
          readOnly={readOnly}
          value={basisType ?? ''}
          onChange={event => onBasisTypeChange((event.target.value as BasisType) || undefined)}
          data-e2e="rate-convention-section-basis"
        >
          <option value=""></option>
          {Object.entries(basisTypes).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </FormSelect>
      )}
    />
  );
}
