import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { createStrategySelectedAction } from '@/neos/business/ui/selectedStrategy/selectedStrategyActionCreators';
import { StrategyOptionsIndicators } from '@/neos/components/rfq/strategies/strategy/strategyOptionsIndicators/StrategyOptionsIndicators';
import { If } from '@/neos/components/share/if/if';
import type { ColumnGridDefinition } from '../getDisplayedColumns';
import { Features } from './features/Features';
import { getStrategyModel } from './getStrategyModel';
import { FairPrices } from './prices/fairPrices/FairPrices.container';
import { Quotes } from './prices/quotes/Quotes.container';
import { StrategyBorder } from './strategyBorder/StrategyBorder';
import { StrategyDefinition } from './strategyDefinition/StrategyDefinition';

export interface StrategyProps {
  rfqId: string;
  strategyId: string;
  columnGridDefinition: ColumnGridDefinition[];
  gridTemplateColumns: string;
  strategyIndex: number;
  isFairPriceDisplayed: boolean;
  isSimilarActivityMode?: boolean;
}

export function Strategy(strategyProps: StrategyProps) {
  const {
    rfqId,
    strategyId,
    columnGridDefinition,
    gridTemplateColumns,
    strategyIndex,
    isFairPriceDisplayed,
    isSimilarActivityMode,
  } = strategyProps;

  const model = useAppSelector(state => getStrategyModel(state, rfqId, strategyId, selectors));
  const dispatch = useAppDispatch();

  const onStrategySelected = (isSelectedStrategy: boolean) => {
    if (!isSelectedStrategy) {
      dispatch(createStrategySelectedAction(rfqId, strategyId));
    }
  };

  const isQuotesShown =
    model.isNotDeltaOrSyntheticDelta && !isSimilarActivityMode && !model.isVarVolStrategy;
  const isFairPriceShown =
    isFairPriceDisplayed && model.isNotDeltaOrSyntheticDelta && !isSimilarActivityMode;

  return (
    <StrategyBorder
      isMasterStrategy={model.isMasterStrategy}
      isSelectedStrategy={model.isSelectedStrategy}
      onClick={() => onStrategySelected(model.isSelectedStrategy)}
      strategyIndex={strategyIndex}
    >
      <If condition={!isSimilarActivityMode} fallback={<div />}>
        <StrategyOptionsIndicators rfqId={rfqId} strategyId={strategyId} />
      </If>
      <StrategyDefinition
        isReadOnlyRfq={model.isReadonlyRfq || !!isSimilarActivityMode}
        rfqId={rfqId}
        strategyId={strategyId}
        columnGridDefinition={columnGridDefinition}
        gridTemplateColumns={gridTemplateColumns}
        onFocus={() => onStrategySelected(model.isSelectedStrategy)}
        isSimilarActivityMode={isSimilarActivityMode}
      />
      <If condition={isQuotesShown}>
        <Quotes
          rfqId={rfqId}
          strategyId={strategyId}
          onFocus={() => onStrategySelected(model.isSelectedStrategy)}
        />
      </If>
      <If condition={isFairPriceShown}>
        <FairPrices
          rfqId={rfqId}
          strategyId={strategyId}
          onFocus={() => onStrategySelected(model.isSelectedStrategy)}
        />
      </If>
      <If condition={!isSimilarActivityMode}>
        <Features
          rfqId={rfqId}
          strategyId={strategyId}
          columnGridDefinition={columnGridDefinition}
          onFocus={() => onStrategySelected(model.isSelectedStrategy)}
        />
      </If>
    </StrategyBorder>
  );
}
