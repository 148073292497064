import { RfqRecomputeScope, type Way } from '../../neosModel';
import { rfqDataActionCreators } from '../rfqData/rfqActionCreators';
import { createActionWithRecompute } from './actionWithRecompute';
import type { Action } from '@/bootstrap/actions.ts';

export function createClientWayChangedAction(rfqId: string, clientWay: Way): Action[] {
  return createActionWithRecompute(rfqId, RfqRecomputeScope.CHANGE_WAY, [
    rfqDataActionCreators.rfqDataCrudActions.update(rfqId, { clientWay }),
  ]);
}
