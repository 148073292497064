import type { Thunk } from '@/bootstrap/thunks';
import { isOptionProduct, type Product } from '../legModel';

export function createAddLegThunk(strategyId: string, legId: string, legQuoteId: string): Thunk {
  return function addLegThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { legDataCrudActions, productCrudActions },
      },
    },
  ) {
    const {
      getStrategyData,
      getLegData,
      getProduct,
      getStrategyMasterProduct,
      getStrategyMasterLeg,
    } = selectors;
    const appState = getState();
    const { legIds } = getStrategyData(appState, strategyId);
    const strategyLastLegId = legIds[legIds.length - 1];

    const { productId, sizeType, activity } = getLegData(appState, strategyLastLegId);
    const lastProductSubFamily = getProduct(appState, productId).subFamily;

    const masterLeg = getStrategyMasterLeg(appState, strategyId, selectors);
    const masterProduct = getStrategyMasterProduct(appState, strategyId, selectors);

    const newProductId = legId;

    dispatch(
      productCrudActions.insert(newProductId, getDefaultProduct()),
      legDataCrudActions.insert(legId, {
        uuid: legId,
        activity,
        productId: newProductId,
        quoteId: legQuoteId,
        isMaster: false,
        strategyId,
        weight: isOptionProduct(masterProduct) ? masterLeg.weight : 0,
        initialWeight: isOptionProduct(masterProduct) ? masterLeg.weight : 0,
        sizeType,
        sgWay: undefined,
      }),
    );

    function getDefaultProduct(): Product {
      switch (lastProductSubFamily) {
        case 'VAR_SWAP':
          return {
            underlyingKind: 'SINGLE',
            uuid: newProductId,
            subFamily: lastProductSubFamily,
            hasCustomUnderlying: false,
            isDerivativeProduct: true,
            legId,
            negotiationMode: 'OTC',
            underlyingId: undefined,
            maturity: undefined,
            expectedN: undefined,
            lotSize: undefined,
            deliveryType: undefined,
            noTaxCollection: false,
            clientTaxRate: undefined,
          };
        case 'VOL_SWAP':
          return {
            underlyingKind: 'SINGLE',
            uuid: newProductId,
            subFamily: lastProductSubFamily,
            hasCustomUnderlying: false,
            isDerivativeProduct: true,
            legId,
            negotiationMode: 'OTC',
            underlyingId: undefined,
            maturity: undefined,
            expectedN: undefined,
            lotSize: undefined,
            deliveryType: undefined,
            noTaxCollection: false,
            clientTaxRate: undefined,
          };
        case 'OPTION': {
          if (!isOptionProduct(masterProduct)) {
            throw new Error('master product for this strategy must be an option');
          }

          return {
            underlyingKind: 'SINGLE',
            uuid: newProductId,
            subFamily: lastProductSubFamily,
            hasCustomUnderlying: false,
            isDerivativeProduct: true,
            legId,
            underlyingId: masterProduct.underlyingId,
            maturity: undefined,
            lotSize: masterProduct.lotSize,
            deliveryType: masterProduct.deliveryType,
            type: undefined,
            strike: undefined,
            strikeUnit: undefined,
            style: masterProduct.style,
            ...(masterProduct.negotiationMode === 'OTC'
              ? { negotiationMode: 'OTC', observableType: undefined }
              : {
                  negotiationMode: 'LISTED',
                  refId: masterProduct.refId,
                  marketExchangeId: masterProduct.marketExchangeId,
                  marketMicCode: masterProduct.marketMicCode,
                  observableType: masterProduct.observableType,
                }),
            noTaxCollection: false,
            clientTaxRate: undefined,
          };
        }
        default:
          throw Error(
            `"getDefaultProduct" is not implemented for subFamily "${lastProductSubFamily}" `,
          );
      }
    }
  };
}
