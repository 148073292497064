import type { Thunk } from '@/bootstrap/thunks';
import type { StrategyDefinition } from '../../../../../../neos/business/neosModel';
import type { FieldEditableProduct, ProductField } from '../legModel';
import type { Action } from '@/bootstrap/actions.ts';

export function createUpdateProductThunk<T extends ProductField>(
  strategyId: string,
  productId: string,
  productField: T,
  value: FieldEditableProduct[T],
): Thunk {
  return function updateProductThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyDefinition, getStrategyData, getLegData },
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();
    const { strategyType, legIds } = getStrategyData(appState, strategyId);
    const productStrategyDefinitionSameField = getProductStrategyDefinitionSameField(productField);
    const strategyDefinition = getStrategyDefinition(appState.referenceData, strategyType);

    function updateOneProduct(productId: string): Action {
      return productCrudActions.update(productId, {
        [productField]: value,
      });
    }

    function updateTenorProduct(productId: string): Action {
      return productCrudActions.update(productId, {
        maturity: undefined,
        [productField]: value,
      });
    }

    if (productField === 'maturityTenor') {
      const actionsForTenors =
        strategyDefinition &&
        productStrategyDefinitionSameField !== null &&
        strategyDefinition[productStrategyDefinitionSameField]
          ? legIds.map(legId => {
              const { productId } = getLegData(appState, legId);
              return updateTenorProduct(productId);
            })
          : [updateTenorProduct(productId)];
      dispatch(actionsForTenors);
    } else {
      const actions =
        strategyDefinition &&
        productStrategyDefinitionSameField !== null &&
        strategyDefinition[productStrategyDefinitionSameField]
          ? legIds.map(legId => {
              const { productId } = getLegData(appState, legId);
              return updateOneProduct(productId);
            })
          : [updateOneProduct(productId)];
      dispatch(actions);
    }
  };
}

export function createUpdateProductAndResetDependenciesThunk<T extends ProductField>(
  rfqId: string,
  strategyId: string,
  productId: string,
  productField: T,
  value: FieldEditableProduct[T],
): Thunk {
  return function updateProductThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createUpdateProductThunk, createRfqResetStrategyQuoteAndFairPricesThunk },
      },
    },
  ) {
    dispatch(
      createUpdateProductThunk(strategyId, productId, productField, value),
      createRfqResetStrategyQuoteAndFairPricesThunk(rfqId, strategyId),
    );
  };
}

type ProductStrategyDefinitionSameField = keyof Pick<
  StrategyDefinition,
  | 'sameProductMaturity'
  | 'sameProductClientPosition'
  | 'sameProductOptionStyle'
  | 'sameProductOptionType'
  | 'sameProductOptionFlex'
  | 'sameProductStrike'
  | 'sameProductUnderlying'
  | 'sameProductExpectedN'
  | 'sameProductForwardDrift'
  | 'sameProductForwardInterestRate'
  | 'sameProductStrikeDate'
  | 'sameProductSwapCurrency'
  | 'sameProductRateCurve'
  | 'sameProductRateTenor'
  | 'sameProductElsType'
  | 'sameProductClsType'
  | 'sameProductAccrual'
  | 'sameProductObservationShift'
  | 'sameProductLockout'
  | 'sameProductLookbackPeriod'
  | 'sameProductPaymentDelay'
  | 'sameProductCalculationMethod'
  | 'sameGenerateFrom'
  | 'sameEquityResetType'
  | 'sameRateReset'
  | 'sameResetMode'
  | 'sameBrokenPeriodType'
  | 'sameWRateResetOnEach'
  | 'sameConventionDay'
  | 'sameEffectiveDate'
  | 'sameEffectiveDateOffset'
  | 'sameRatePeriods'
  | 'sameEquityPeriods'
  | 'sameRoleDefinition'
  | 'sameCalculationAgent'
  | 'sameDeterminingParty'
  | 'sameHedgingParty'
  | 'sameRateSpreadAdjustment'
  | 'sameDividendSpreadAdjustment'
  | 'sameDividendPriceType'
  | 'sameLinearInterpolation'
  | 'sameLookthroughDR'
  | 'sameRightToSubstituteScope'
  | 'sameRightToSubstituteConditions'
  | 'sameRelatedExchange'
  | 'sameTermNotice'
  | 'sameClientTermNotice'
  | 'sameDailyMinSize'
  | 'sameDailyMaxSize'
  | 'sameSecondaryMarketAllowed'
  | 'sameComponentSecurityIndexAnnex'
  | 'sameBreakFeePeriods'
  | 'sameBreakFeeElection'
  | 'sameSettlementMethodElection'
  | 'sameTerminationType'
  | 'sameTerminationRights'
  | 'sameTerminationConditions'
  | 'sameBasisType'
  | 'sameCompoundRate'
  | 'sameElectionFee'
  | 'sameElectionDate'
  | 'sameValuationType'
  | 'sameDealType'
  | 'sameLocalTaxes'
  | 'sameDeclaredCashDiv'
  | 'sameSpecialDividends'
  | 'sameRateFixingOffset'
  | 'sameBrokenPeriodPosition'
  | 'sameExecFeesIn'
  | 'sameExecFeesOut'
  | 'sameFixedDay'
  | 'sameDerogateRateFixingOffset'
  | 'sameObservableType'
  | 'sameIsScheduleObsolete'
  | 'sameProductNoTaxCollection'
  | 'sameProductClientTaxRate'
>;

function getProductStrategyDefinitionSameField(
  productField: ProductField,
): ProductStrategyDefinitionSameField | null {
  switch (productField) {
    case 'derogateRateFixingOffset':
      return 'sameDerogateRateFixingOffset';
    case 'maturity':
    case 'maturityTenor':
    case 'futureMaturity':
      return 'sameProductMaturity';
    case 'style':
      return 'sameProductOptionStyle';
    case 'type':
      return 'sameProductOptionType';
    case 'flex':
      return 'sameProductOptionFlex';
    case 'strike':
    case 'upperStrike':
    case 'lowerStrike':
      return 'sameProductStrike';
    case 'underlyingId':
      return 'sameProductUnderlying';
    case 'expectedN':
      return 'sameProductExpectedN';
    case 'forwardDrift':
      return 'sameProductForwardDrift';
    case 'forwardInterestRate':
      return 'sameProductForwardInterestRate';
    case 'strikeDate':
      return 'sameProductStrikeDate';
    case 'swapCurrency':
      return 'sameProductSwapCurrency';
    case 'elsType':
      return 'sameProductElsType';
    case 'clsType':
      return 'sameProductClsType';
    case 'accrual':
      return 'sameProductAccrual';
    case 'calculationMethod':
      return 'sameProductCalculationMethod';
    case 'lockout':
      return 'sameProductLockout';
    case 'lookbackPeriod':
      return 'sameProductLookbackPeriod';
    case 'observationShift':
      return 'sameProductObservationShift';
    case 'paymentDelay':
      return 'sameProductPaymentDelay';
    case 'generateFrom':
      return 'sameGenerateFrom';
    case 'equityResetType':
      return 'sameEquityResetType';
    case 'rateReset':
      return 'sameRateReset';
    case 'resetMode':
      return 'sameResetMode';
    case 'brokenPeriod':
      return 'sameBrokenPeriodType';
    case 'wRateResetOnEach':
      return 'sameWRateResetOnEach';
    case 'conventionDay':
      return 'sameConventionDay';
    case 'effectiveDate':
      return 'sameEffectiveDate';
    case 'effectiveDateOffset':
      return 'sameEffectiveDateOffset';
    case 'ratePeriods':
      return 'sameRatePeriods';
    case 'equityPeriods':
      return 'sameEquityPeriods';
    case 'roleDefinition':
      return 'sameRoleDefinition';
    case 'calculationAgent':
      return 'sameCalculationAgent';
    case 'determiningParty':
      return 'sameDeterminingParty';
    case 'hedgingParty':
      return 'sameHedgingParty';
    case 'rateSpreadAdjustment':
      return 'sameRateSpreadAdjustment';
    case 'dividendSpreadAdjustment':
      return 'sameDividendSpreadAdjustment';
    case 'dividendPriceType':
      return 'sameDividendPriceType';
    case 'linearInterpolation':
      return 'sameLinearInterpolation';
    case 'lookthroughDR':
      return 'sameLookthroughDR';
    case 'rightToSubstituteScope':
      return 'sameRightToSubstituteScope';
    case 'rightToSubstituteConditions':
      return 'sameRightToSubstituteConditions';
    case 'relatedExchange':
      return 'sameRelatedExchange';
    case 'termNotice':
      return 'sameTermNotice';
    case 'clientTermNotice':
      return 'sameClientTermNotice';
    case 'dailyMinSize':
      return 'sameDailyMinSize';
    case 'dailyMaxSize':
      return 'sameDailyMaxSize';
    case 'componentSecurityIndexAnnex':
      return 'sameComponentSecurityIndexAnnex';
    case 'secondaryMarketAllowed':
      return 'sameSecondaryMarketAllowed';
    case 'breakFeeElection':
      return 'sameBreakFeeElection';
    case 'breakFeePeriods':
      return 'sameBreakFeePeriods';
    case 'settlementMethodElection':
      return 'sameSettlementMethodElection';
    case 'terminationType':
      return 'sameTerminationType';
    case 'terminationRights':
      return 'sameTerminationRights';
    case 'terminationConditions':
      return 'sameTerminationConditions';
    case 'basisType':
      return 'sameBasisType';
    case 'compoundRate':
      return 'sameCompoundRate';
    case 'electionFee':
      return 'sameElectionFee';
    case 'electionDate':
      return 'sameElectionDate';
    case 'valuationType':
      return 'sameValuationType';
    case 'dealType':
      return 'sameDealType';
    case 'localTaxes':
      return 'sameLocalTaxes';
    case 'specialDividends':
      return 'sameSpecialDividends';
    case 'declaredCashDiv':
      return 'sameDeclaredCashDiv';
    case 'rateFixingOffset':
      return 'sameRateFixingOffset';
    case 'brokenPeriodPosition':
      return 'sameBrokenPeriodPosition';
    case 'observableType':
      return 'sameObservableType';
    case 'fixedDay':
      return 'sameFixedDay';
    case 'isScheduleObsolete':
      return 'sameIsScheduleObsolete';
    case 'noTaxCollection':
      return 'sameProductNoTaxCollection';
    case 'clientTaxRate':
      return 'sameProductClientTaxRate';
    default:
      return null;
  }
}
