import type { Selectors } from '@/bootstrap/selectors';
import { flatten } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';

export function getImportOtcAllocationsModel(
  state: AppState,
  rfqId: string,
  appSelectors: Selectors,
) {
  const availableClients = (appSelectors.getCounterparts(state, rfqId) ?? []).map(c => c.id);
  const extraClients = (appSelectors.getAllocExtraCounterparts(state, rfqId) ?? []).map(c => c.id);

  const strategies = appSelectors.getOtcExecutionRfqStrategies(state, rfqId, appSelectors);

  return {
    legs: flatten(
      strategies.map(s => {
        const ccy = appSelectors.getCurrencyByStrategyId(state, s.strategyId, appSelectors);
        const uiSizeType = appSelectors.getDisplayNegotiatedSize(state.ui, s.strategyId);
        const { strategyType } = appSelectors.getStrategyData(state, s.strategyId);
        const availableSizeTypes = appSelectors.getStrategyAvailableSizeTypes(
          state.referenceData,
          strategyType,
          appSelectors,
        );

        const onChangeSizeType = availableSizeTypes.find(st =>
          st.includes(uiSizeType.replace('LOCAL_', '')),
        );

        return s.legIds.map(legId => ({
          ccy,
          uiSizeType,
          legId,
          onChangeSizeType,
          allocIds: appSelectors.getOtcAllocationsByLegId(state, legId).map(alloc => alloc.uuid),
        }));
      }),
    ),
    clients: [...availableClients, ...extraClients],
  };
}
