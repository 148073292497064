import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useContext } from 'react';

interface SpotConfirmedCheckboxProps {
  underlyingId: string | undefined;
}

export function SpotConfirmedCheckbox({ underlyingId }: SpotConfirmedCheckboxProps) {
  const { rfqId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();

  const shouldBeEditable = useAppSelector(state =>
    selectors.isSecondaryEditable(state, rfqId, selectors),
  );
  const isDisabled = underlyingId === undefined;

  const reference = useAppSelector(state =>
    isDisabled
      ? undefined
      : selectors.getReference(state, {
          underlyingId,
          rfqId,
        }),
  );

  const { update: updateReference } = actionCreators.neos.referenceCrudActions;

  const onCheckboxChange = (newValue: boolean) => {
    if (underlyingId !== undefined) {
      dispatch(updateReference({ rfqId, underlyingId }, { isSpotConfirmed: newValue }));
    }
  };

  return (
    <ElsBlocField
      label="Confirmed"
      renderInput={readOnly => (
        <Switch
          disabled={(isDisabled || readOnly) && !shouldBeEditable}
          data-e2e="neos-extra-features-els-equitybloc-spotConfirmed"
          checked={reference?.isSpotConfirmed ?? false}
          onChange={() => onCheckboxChange(!reference?.isSpotConfirmed)}
        />
      )}
    />
  );
}
