import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { CompositionLegsExport } from '@/neos/components/rfq/strategies/strategy/compositionLegs/CompositionLegsExport.tsx';
import { CompositionLegsImport } from '@/neos/components/rfq/strategies/strategy/compositionLegs/CompositionLegsImport.tsx';
import { neosThunks } from '@/neos/business/thunks';
import { CompositionLegsGrid } from '@/neos/components/rfq/strategies/strategy/compositionLegs/CompositionLegsGrid.tsx';
import { CompositionLegsWarnings } from '@/neos/components/rfq/strategies/strategy/compositionLegs/CompositionLegsWarnings.tsx';
import { getCompositionLegsModel } from '@/neos/components/rfq/strategies/strategy/compositionLegs/getCompositionLegsModel.ts';

interface CompositionLegsProps {
  rfqId: string;
  strategyId: string;
}

export function CompositionLegsDetails({ rfqId, strategyId }: CompositionLegsProps) {
  const dispatch = useAppDispatch();

  const { hasCompoLeg, allCompositionLegs, title } = useAppSelector(state =>
    getCompositionLegsModel(state, rfqId, strategyId, selectors),
  );
  const compositionLegs = allCompositionLegs.filter(compo => !compo.isMaster);

  if (!hasCompoLeg) {
    return null;
  }

  const onAddNewLine = () => {
    dispatch(neosThunks.createRfqAddLegsThunk(strategyId, 1));
  };

  return (
    <div style={{ width: '1200px', gridColumn: '2 / -2' }} className="p-1 px-2">
      <div className="border p-3 h-100 overflow-auto card">
        <div className="d-flex justify-content-between mb-4">
          <h4>Legs composition</h4>
          <div className="d-flex gap-1">
            <CompositionLegsImport rfqId={rfqId} strategyId={strategyId} />
            <CompositionLegsExport
              hasCompositionLegs={!!compositionLegs.length}
              exportTitle={title}
              allCompositionLegs={allCompositionLegs}
            />
          </div>
        </div>
        <div className="d-grid gap-1">
          <div
            className="d-grid align-items-center gap-1 overflow-auto fw-bold mb-1"
            style={{ gridTemplateColumns: '32px repeat(6, 1fr)' }}
          >
            <button
              className="sticky-top btn btn-icon btn-flat-primary bg-lvl1"
              onClick={onAddNewLine}
            >
              <i className="icon icon-md">add</i>
            </button>
            <label>Underlying</label>
            <label>Weight</label>
            <label>Size (Vega Notional)</label>
            <label>Spot</label>
            <label>Exp N</label>
            <label>Vol Strike</label>
            {compositionLegs.map(compositionLeg => (
              <CompositionLegsGrid
                rfqId={rfqId}
                strategyId={strategyId}
                compositionLeg={compositionLeg}
                key={compositionLeg.legId}
              />
            ))}
          </div>
          <CompositionLegsWarnings strategyId={strategyId} compositionLegs={compositionLegs} />
        </div>
      </div>
    </div>
  );
}
