import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export interface IdsOfLegsWithDisplayedPrices {
  displayReadonlyCellsForCompositionLeg: boolean;
  displayedPricesLegIds: string[];
}
export function getIdsOfLegsWithDisplayedPrices(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): IdsOfLegsWithDisplayedPrices {
  const isShowLegPrices = selectors.isNeosShowLegPrices(state, rfqId, strategyId, selectors);
  if (!isShowLegPrices) {
    return { displayReadonlyCellsForCompositionLeg: false, displayedPricesLegIds: [] };
  }

  const { legsData, hasACompositionLeg } = selectors.getStrategyLegsOrIndexLegData(
    state,
    strategyId,
    selectors,
  );

  const legIds = legsData.map(({ uuid }) => uuid);

  return {
    displayReadonlyCellsForCompositionLeg: hasACompositionLeg,
    displayedPricesLegIds: legIds,
  };
}
