import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export function isReverseWeightsWarningVisible(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const { fairPriceId, strategyIds, quoteId } = selectors.getRfqData(state, rfqId);
  const fairPrices = fairPriceId && selectors.getFairPrice(state, fairPriceId);
  const isReverseWeightsMessageDismissed = selectors.isReverseWeightsMessageDismissed(
    state.ui,
    rfqId,
  );
  const quote = quoteId && selectors.getQuote(state, quoteId);
  const noLegOverWarningVisible = strategyIds.every(
    strategyId => !selectors.isLegOverWarningVisible(state, strategyId, selectors),
  );
  const isMidFairPriceNegative = fairPrices && fairPrices.mid! < 0;
  const isBidAskQuoteQuote =
    quote && quote.traderAsk && quote.traderAsk < 0 && quote.traderBid && quote.traderBid < 0;

  const isReverseEnabled =
    noLegOverWarningVisible &&
    !isReverseWeightsMessageDismissed &&
    (isMidFairPriceNegative || isBidAskQuoteQuote);
  return !!isReverseEnabled;
}
