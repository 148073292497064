import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { rfqActionCreators } from '@/neos/business/rfq/rfqActionCreators';
import { createRfqChangeSalesCreditAmountThunk } from '@/neos/business/rfq/thunks/rfqChangeSalesCreditAmount';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { MarginRules, RfqRecomputeScope } from '../../../../business/neosModel';
import {
  type TotalSalesMarginDispatchProps,
  TotalSalesMarginModalWithCss,
  type TotalSalesMarginOwnProps,
} from './TotalSalesMarginModal';
import { getManualSalesCredit } from './getManualSalesCredit';
import { getSalesCreditStatusModel } from './getSalesCreditStatusModel';
import { getSalesCreditEliotId, getTotalSalesMarginModel } from './getTotalSalesMarginModel';

export const TotalSalesMarginModal: FC<TotalSalesMarginOwnProps> = ownProps => {
  const stateProps = useAppSelector(state => ({
    ...getTotalSalesMarginModel(state, ownProps.rfqId, selectors),
    ...getSalesCreditEliotId(state, ownProps.rfqId, selectors),
    ...getManualSalesCredit(state, ownProps.rfqId, selectors),
    salesCreditStatusModel: getSalesCreditStatusModel(state, ownProps.rfqId, selectors),
  }));

  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchToProps(dispatch, ownProps);

  return <TotalSalesMarginModalWithCss {...stateProps} {...ownProps} {...dispatchProps} />;
};

function getDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: TotalSalesMarginOwnProps,
): TotalSalesMarginDispatchProps {
  return {
    onHide: () =>
      dispatch(
        neosActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { isMarkupModalShown: false }),
      ),
    onSalesIncludedRuleChanged: (value: MarginRules | undefined) =>
      dispatch(
        neosActionCreators.createRfqQuoteChangedAction(rfqId, RfqRecomputeScope.CHANGE_RFQ_MARKUP, {
          includedSalesMarginRule: value,
        }),
      ),
    onSalesCreditIncludedChanged: (value: number | undefined) =>
      dispatch(
        createRfqChangeSalesCreditAmountThunk(rfqId, {
          includedSalesMarginAsk: value,
          includedSalesMarginBid: value,
          includedSalesMarginRule: MarginRules.CUSTOM,
        }),
      ),

    onSalesMarginRuleChanged: (value: MarginRules | undefined) =>
      dispatch(
        neosActionCreators.createRfqQuoteChangedAction(rfqId, RfqRecomputeScope.CHANGE_RFQ_MARKUP, {
          extraSalesMarginRule: value,
        }),
      ),
    onSalesCreditMarkUpChanged: (value: number | undefined) =>
      dispatch(
        createRfqChangeSalesCreditAmountThunk(rfqId, {
          extraSalesMarginAsk: value,
          extraSalesMarginBid: value,
          extraSalesMarginRule: MarginRules.CUSTOM,
        }),
      ),
    onSalesCreditEliotIdChanged(eliotId: string | undefined) {
      if (eliotId) {
        dispatch(
          rfqActionCreators.rfqDataCrudActions.update(rfqId, {
            salesCreditBookingReference: { application: 'XONE', id: eliotId },
          }),
        );
      }
    },
    onManualSalesCreditChange(value, totalScUnit) {
      dispatch(
        rfqActionCreators.rfqDataCrudActions.update(rfqId, {
          manualSalesCredit: value ? { value, type: 'CCY', unit: totalScUnit } : undefined,
        }),
      );
    },
  };
}
