import type { Selectors } from '@/bootstrap/selectors';
import type { OnyxSingleUnderlyingProduct, PriceWithUnit } from '@/neos/business/rfq/rfqOnyxModel';
import { isProductWithStrike } from '../../productModel';
import type { AppState } from '@/bootstrap/store.ts';

export function mapToOnyxStrike(
  state: AppState,
  productId: string,
  selectors: Selectors,
): PriceWithUnit | undefined {
  const product = selectors.getProduct(state, productId);
  if (!isProductWithStrike(product)) {
    return undefined;
  }
  const { strike, strikeUnit } = product;
  return {
    value: strike,
    unit: strikeUnit,
  };
}

export interface ProductStrike {
  strike: number | undefined;
  strikeUnit: string | undefined;
}
export function mapFromOnyxStrike(onyxProduct: OnyxSingleUnderlyingProduct): ProductStrike {
  if (!onyxProduct.strike) {
    return { strike: undefined, strikeUnit: undefined };
  }
  return {
    strike: onyxProduct.strike.value,
    strikeUnit: onyxProduct.strike.unit,
  };
}
