import type { RecomputeScope } from '../models/recomputeModel';
import { strategyActionCreators } from '../strategy/strategyActionCreators';
import { createActionWithRecompute } from './actionWithRecompute';
import type { Action } from '@/bootstrap/actions.ts';

export function createRfqStrategyWeightChangedAction(
  rfqId: string,
  strategyId: string,
  scope: RecomputeScope,
  weight: number | undefined,
): Action[] {
  return createActionWithRecompute(rfqId, scope, [
    strategyActionCreators.strategyDataCrudActions.update(strategyId, { weight }),
  ]);
}
