import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { AllocSecondaryEventCreation } from '@/neos/business/allocSecondaryEventCreation/allocSecondaryEventCreationDomain';
import { getEventTypesByProduct } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/secondaryType/shared/SecondaryTypeCellsCommon';
import { SecondaryWidget } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/secondaryType/shared/SecondaryWidget';
import { forwardRef } from 'react';

type AllocSecondaryToggleButtonsProps = {
  onEventTypeClick: (eventType: AllocSecondaryEventCreation['eventType']) => void;
  isLoading: boolean;
  secondaryType: AllocSecondaryEventCreation['eventType'];
  legId: string;
  isLegMaturityValid: boolean | undefined;
};

export const OtcAllocationSecondaryToggleButtons = forwardRef<
  HTMLDivElement,
  AllocSecondaryToggleButtonsProps
>(({ secondaryType, legId, isLegMaturityValid, onEventTypeClick, isLoading }, ref) => {
  const product = useAppSelector(state => selectors.getProduct(state, legId));
  const eventTypes = getEventTypesByProduct(product);

  return (
    <SecondaryWidget
      ref={ref}
      eventTypes={eventTypes}
      isDisabled={!isLegMaturityValid}
      isLoading={isLoading}
      id={legId}
      selectedEventType={secondaryType}
      handleClick={(_, __, eventType) => onEventTypeClick(eventType)}
    />
  );
});
