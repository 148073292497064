import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { wrapInLoadingObservable } from '../../epics/wrapInLoadingObservable';
import { mappers, type ToOnyxMappers } from '../../mappers';
import type { OnyxError } from '../../mappers/error';
import type { OnyxIbChatResponse, OnyxRfq } from '../../neosOnyxModel';
import { createIbChatApi } from '../apis/ibChatApi';
import type { OnyxIbChatRequest } from '../rfqOnyxModel';
import { logger } from '@/util/logging/logger.ts';
import { mt } from '@/util/logging/messageTemplates.ts';

export interface IbChatApi {
  requestIbChat: (ibChatRequest: OnyxIbChatRequest) => Observable<OnyxIbChatResponse>;
}

export function getIbChatEpic(http: SgmeHttp): ThunkEpic {
  const api = createIbChatApi(http);
  return createIbChatEpic(api, selectors, thunks, actionCreators, mappers.toOnyxMappers);
}

export function createIbChatEpic(
  api: IbChatApi,
  selectors: Selectors,
  { createErrorToasterThunk, neos: { createIntegrateReceivedIbChatThunk } }: Thunks,
  { common: { createAppCrashedAction } }: ActionCreators,
  toOnyxMappers: ToOnyxMappers,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('IBCHAT_REQUESTED'),
      mergeMap(({ rfqId }) => {
        let rfq: OnyxRfq;
        try {
          rfq = toOnyxMappers.mapToOnyxRfqWithCurrentLocalVersion(
            state$.value,
            rfqId,
            selectors,
            toOnyxMappers,
          );
        } catch (error) {
          logger.error(mt.unhandledException, 'Mapping error with ib chat', error, rfqId);
          return [createErrorToasterThunk({ message: 'Error with ib chat' }, undefined)];
        }
        const ibChat = selectors.getIbChat(state$.value.ui, rfqId);
        const mode = selectors.getIbChatNegotiationMode(state$.value.ui, rfqId);
        return wrapInLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.requestIbChat({
            type: 'CHAT',
            chat: ibChat ? `${ibChat} ${mode}` : '',
            rfq,
          }),
          onSuccess: (ibChatResponse: OnyxIbChatResponse) =>
            createIntegrateReceivedIbChatThunk(rfqId, { ...ibChatResponse, chat: ibChat ?? '' }),
          onError: (error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: 'Error requesting IB Chat',
              },
              error,
            ),
          ],
        });
      }),
      catchError(error => [createAppCrashedAction('ib-chat-error', error)]),
    );
}
