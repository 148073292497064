import { actionCreators } from '@/bootstrap/actions';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { NeosBlurTextarea } from '@/neos/components/share/blurComponent/NeosBlurTextarea.tsx';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function ElsRatePreconfComment() {
  const { product } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const { uuid, ratePreconfComment } = product;

  const onCommentChanged = (newComment: string) => {
    dispatch(
      actionCreators.neos.productCrudActions.update(uuid, { ratePreconfComment: newComment }),
    );
  };

  return (
    <ElsBlocField
      className="flex-grow-1"
      label="Preconf Comment"
      renderInput={readOnly => (
        <NeosBlurTextarea
          className="form-control"
          data-e2e="neos-els-rate-preconf-comment"
          value={ratePreconfComment || ''}
          rows={3}
          onChange={event => onCommentChanged(event.target.value)}
          disabled={readOnly}
        />
      )}
    />
  );
}
