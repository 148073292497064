import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  ResetAllocationsButtonComponent,
  type ResetAllocationsButtonDispatchProps,
  type ResetAllocationsButtonOwnProps,
} from './ResetAllocationsButton';
import { getResetAllocationsButtonModel } from './getResetAllocationsButtonModel';

export const ResetAllocationsButton: FC<ResetAllocationsButtonOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const stateProps = useAppSelector(appState => ({
    model: getResetAllocationsButtonModel(appState, ownProps.rfqId, selectors),
  }));
  const dispatchProps = mapDispatchProps(dispatch, ownProps);

  return <ResetAllocationsButtonComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ResetAllocationsButtonOwnProps,
): ResetAllocationsButtonDispatchProps {
  return {
    onButtonClicked: () => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Reset Allocations', rfqId),
        neosActionCreators.listedAllocationCrudActions.deleteByPartialKey({ rfqId }),
        neosActionCreators.otcAllocationCrudActions.deleteByPartialKey({ rfqId }),
      );
    },
  };
}
