import type { Selectors } from '@/bootstrap/selectors';
import type { LegData } from '../neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getStrategyCompositionLegsData(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): LegData[] {
  const { strategyType, legIds } = selectors.getStrategyData(state, strategyId);
  const { hasACompositionLeg } = selectors.getStrategyDefinition(state.referenceData, strategyType);

  if (!hasACompositionLeg) {
    return [];
  }

  const legsData = legIds.map(legId => selectors.getLegData(state, legId));
  return legsData.filter(legData => !legData.isMaster);
}
