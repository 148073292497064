import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { isSecondaryEvent } from '@/neos/business/neosModel';
import { getSecondaryFieldsToDisplay, labelBySecondaryField } from './secondaryFieldsByType';

export function SecondaryAllocationsHeaders({ legId }: { legId: string; rfqId: string }) {
  const { eventType } = useAppSelector(state => selectors.getLegData(state, legId));
  const product = useAppSelector(state => selectors.getProduct(state, legId));

  if (!eventType || !isSecondaryEvent(eventType)) {
    return null;
  }
  return (
    <>
      {getSecondaryFieldsToDisplay(eventType, product).map(field => (
        <label key={field} className="m-0">
          {labelBySecondaryField[field]}
        </label>
      ))}
    </>
  );
}
