import type { Selectors } from '@/bootstrap/selectors';
import type { StrategyData } from '@/neos/business/rfq/strategy/strategyData/strategyDataModel.ts';
import type { AppState } from '@/bootstrap/store.ts';

export type ValueDateCellModel = {
  valueDate: StrategyData['valueDate'];
  isReadonly: boolean;
};

export function getValueDateCellModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): ValueDateCellModel {
  const isStatusTraded = selectors.getRfqData(state, rfqId).status === 'TRADED';

  const valueDate = selectors.getStrategyData(state, strategyId)?.valueDate;

  return {
    valueDate,
    isReadonly: isStatusTraded,
  };
}
