import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { type ToOnyxMappers, mappers } from '../mappers';
import type { OnyxError } from '../mappers/error';
import type { OnyxTransaction } from '../neosOnyxModel';
import type { PreConfirmationStep } from './PreConfirmationStepModel';
import { createGeneratePreconfApi } from './generatePreconfApi';
import type { ThunkEpic } from '@/bootstrap/epics.ts';
import type { AppState } from '@/bootstrap/store.ts';

export interface GeneratePreconfApi {
  generatePreconfApi: (
    rfqId: string,
    onyxTransaction: OnyxTransaction,
  ) => Observable<PreConfirmationStep[]>;
}

export function generatePreconfEpic(http: SgmeHttp) {
  const api = createGeneratePreconfApi(http);
  return createGeneratePreconfEpic(api, mappers.toOnyxMappers, thunks, selectors, actionCreators);
}

export function createGeneratePreconfEpic(
  api: GeneratePreconfApi,
  toOnyxMappers: ToOnyxMappers,
  { createErrorToasterThunk }: Thunks,
  selectors: Selectors,
  { neos: { createPreConfirmationStepsReceivedAction } }: ActionCreators,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('POST_GENERATE_PRECONFIRMATION'),
      mergeMap(({ rfqId }) => {
        const transaction = toOnyxMappers.mapToOnyxTransaction(
          state$.value as AppState,
          rfqId,
          selectors,
          toOnyxMappers,
        );

        return api.generatePreconfApi(rfqId, transaction).pipe(
          mergeMap(preconfSteps => [createPreConfirmationStepsReceivedAction(rfqId, preconfSteps)]),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: 'Error regenerating preconfirmations',
              },
              error,
            ),
          ]),
        );
      }),
    );
}
