import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { TransactionActions } from '@/neos/components/rfq/transactionActions/TransactionActions';
import type { FC } from 'react';
import { ActorsSetup } from '../actorsSetup/ActorsSetup';
import { Notional } from '../notional/Notional.container';
import { Workflow } from '../workflow/Workflow';
import styles from './StepsCommonInfo.module.scss';

export interface StepsCommonInfoProps {
  rfqId: string;
}

export const StepsCommonInfo: FC<StepsCommonInfoProps> = ({ rfqId }) => {
  const isEls = useAppSelector(state => selectors.isRfqEls(state, rfqId, selectors));

  return (
    <div
      className={`${styles['steps-common-info']}   ${
        isEls ? styles['steps-common-info-els'] : ''
      } `}
    >
      <Workflow rfqId={rfqId} />
      <Notional rfqId={rfqId} />
      <ActorsSetup rfqId={rfqId} />
      <TransactionActions rfqId={rfqId} />
    </div>
  );
};
