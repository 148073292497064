import { extraCounterpartsDomain } from './extraCounterpartsDomain';
import type { AppState } from '@/bootstrap/store.ts';

export const extraCounterpartsSelectors = {
  getAllocExtraCounterparts,
};

function getAllocExtraCounterparts(state: AppState, rfqId: string) {
  return extraCounterpartsDomain.selectors.find(state.extraCounterparts, rfqId)?.ALLOCATIONS;
}
