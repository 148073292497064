import type { Selectors } from '@/bootstrap/selectors';
import type { PttPredealCheck } from '@/neos/business/predealCheck/predealCheckModel';
import type { LegIdsByStrategyId } from '@/neos/business/rfq/models';
import { flatMap } from 'lodash';
import type { BulletColor } from '../../predealCheckBullet';
import { convertToBulletColor } from '../../util/convertToBulletColor';
import type { AppState } from '@/bootstrap/store.ts';

interface PttPredealCheckModel {
  bulletColor: BulletColor;
  isinCode?: string;
  token?: string;
  isUnknown: boolean;
}

type PttPredealCheckLegs = { [legId: string]: PttPredealCheckModel };
export interface PttPredealChecksModel {
  legIdsByStrategyIds: LegIdsByStrategyId[];
  legs: PttPredealCheckLegs;
}

export function getPttPredealChecksModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PttPredealChecksModel {
  const pttPredealChecks = selectors.getNeosPttPredealChecks(state, rfqId, selectors);
  const legIdsByStrategyIds = selectors.getRfqLegIdsByStrategyIds(state, rfqId, selectors);

  const emptyPttPredealChecksModel = buildEmptyPttPredealChecksModel(legIdsByStrategyIds);
  const pttPredealChecksModel = buildPttPredealChecksLegs(
    emptyPttPredealChecksModel,
    pttPredealChecks,
  );
  return pttPredealChecksModel;
}

function buildEmptyPttPredealChecksModel(
  legIdsByStrategyIds: LegIdsByStrategyId[],
): PttPredealChecksModel {
  const legIds = flatMap(legIdsByStrategyIds, ({ legIds }): string[] => legIds);
  const legs: PttPredealCheckLegs = legIds.reduce(
    (resultAccumulator, currentLegId): PttPredealCheckLegs => ({
      ...resultAccumulator,
      [currentLegId]: { bulletColor: 'unknown', isUnknown: true },
    }),
    {} as PttPredealCheckLegs,
  );

  return {
    legIdsByStrategyIds,
    legs,
  };
}

function buildPttPredealChecksLegs(
  emptyPttPredealChecksModel: PttPredealChecksModel,
  predealChecksToAdd: PttPredealCheck[],
): PttPredealChecksModel {
  const pttPredealChecksModel = predealChecksToAdd.reduce(
    (accumulator, predealCheckToAdd) =>
      integratePttPredealCheckIfConsistent(accumulator, predealCheckToAdd),
    emptyPttPredealChecksModel,
  );
  return pttPredealChecksModel;
}

function integratePttPredealCheckIfConsistent(
  currentPttPredealChecksModel: PttPredealChecksModel,
  predealCheckToAdd: PttPredealCheck,
): PttPredealChecksModel {
  const legIds = flatMap(
    currentPttPredealChecksModel.legIdsByStrategyIds,
    ({ legIds }): string[] => legIds,
  );
  if (!legIds.includes(predealCheckToAdd.legUuid)) {
    return currentPttPredealChecksModel;
  }
  const { legUuid, isinCode, token, uiColor } = predealCheckToAdd;
  const updatedPttPredealChecksLegs: PttPredealCheckLegs = {
    ...currentPttPredealChecksModel.legs,
    [legUuid]: {
      bulletColor: convertToBulletColor(uiColor),
      isUnknown: uiColor === 'UNKNOWN',
      isinCode,
      token,
    },
  };

  return {
    legIdsByStrategyIds: currentPttPredealChecksModel.legIdsByStrategyIds,
    legs: updatedPttPredealChecksLegs,
  };
}
