import type {
  FullUnderlyingInfo,
  PartialUnderlyingInfo,
  UnderlyingInfo,
} from '../underlyingInfo/underlyingInfoModel';
import type { AppState } from '@/bootstrap/store.ts';

//NOTE: In case of a basket product, we will not fetch the complete underlying options, the partial underlying is enough
function getPartialUnderlyingInfo(
  state: AppState,
  underlyingId: string | undefined,
): PartialUnderlyingInfo | undefined {
  if (underlyingId) {
    const underlyingInfo = state.underlyingState[underlyingId];

    return underlyingInfo && !underlyingInfo.hasAllUnderlyingInfo ? underlyingInfo : undefined;
  }
  return undefined;
}

export function getFullUnderlyingInfo(
  state: AppState,
  underlyingId: string | undefined,
): FullUnderlyingInfo | undefined {
  if (underlyingId) {
    const underlyingInfo = state.underlyingState[underlyingId];
    return underlyingInfo && underlyingInfo.hasAllUnderlyingInfo ? underlyingInfo : undefined;
  }
  return undefined;
}

export function getUnderlyingInfo(
  state: AppState,
  underlyingId: string | undefined,
): UnderlyingInfo | undefined {
  if (underlyingId) {
    const partialUnderlyingInfo = getPartialUnderlyingInfo(state, underlyingId);

    return partialUnderlyingInfo !== undefined
      ? partialUnderlyingInfo
      : getFullUnderlyingInfo(state, underlyingId);
  }
  return undefined;
}

export function getUnderlyingsInfo(state: AppState, underlyingId: string[]) {
  return underlyingId
    .map(underlyingId => getUnderlyingInfo(state, underlyingId))
    .filter((underlying): underlying is UnderlyingInfo => underlying !== undefined);
}
