import type { Thunk } from '@/bootstrap/thunks';
import { times } from 'lodash';

export function createAddStrategyThunk(rfqId: string): Thunk {
  return function addStrategyThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: {
          rfqDataCrudActions,
          strategyInitialized,
          quoteCrudActions,
          createStrategySelectedAction,
          strategyUiCrudActions,
        },
      },
      thunks: {
        neos: { createDefaultLegsAsPerStrategyDefinitionThunk },
      },
      services: { idCreator },
    },
  ) {
    const newStrategyId = idCreator.createId();
    const newLegId = idCreator.createId();
    dispatch(
      quoteCrudActions.insert(newLegId, {
        parentId: { type: 'Leg', id: newLegId },
        deltaUnit: '%',
        unit: undefined,
        initialSalesPrice: undefined,
        initialTraderPrice: undefined,
      }),
      quoteCrudActions.insert(newStrategyId, {
        parentId: { type: 'Strategy', id: newStrategyId },
        deltaUnit: '%',
        unit: undefined,
        initialSalesPrice: undefined,
        initialTraderPrice: undefined,
      }),
      rfqDataCrudActions.arrayAdd(rfqId, { property: 'strategyIds', value: newStrategyId }),
      strategyUiCrudActions.insert(newStrategyId, {
        displayedSizeType: 'QUANTITY',
        displayMissingCompositionDataWarning: false,
        displayCompoImportHasMultipleIndicesWarning: false,
      }),
      createStrategySelectedAction(rfqId, newStrategyId),
      strategyInitialized(rfqId, newStrategyId, newLegId, getState()),
      createDefaultLegsAsPerStrategyDefinitionThunk(newStrategyId),
    );
  };
}

export function createManyStrategiesAddedThunk(rfqId: string, n: number): Thunk {
  return function manyStrategiesAddedThunk(dispatch, _, { thunks: { neos } }) {
    dispatch(times(n, () => neos.createAddStrategyThunk(rfqId)));
  };
}
