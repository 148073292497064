import { rootReducer } from '@/bootstrap/reducer.ts';
import type { DevToolsEnhancerOptions } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { Action as ReduxAction } from 'redux';
import { type Action, actionCreators } from '@/bootstrap/actions.ts';
import { services } from '@/bootstrap/services.ts';
import { type Dispatchable, thunks } from '@/bootstrap/thunks.ts';
import { mappers } from '@/neos/business/mappers';
import { createActionContainerMiddleware } from '@/util/actionContainer/actionContainerMiddleware.ts';
import { createErrorHandlingMiddleware } from '@/bootstrap/middlewares/errorHandling/errorHandlingMiddleware.ts';
import { createThunkLoggerMiddleware } from '@/bootstrap/middlewares/thunkLoggerMiddleware.ts';
import { createLoggingMiddleware } from '@/bootstrap/middlewares/logging/loggingMiddleware.ts';
import { logger } from '@/util/logging/logger.ts';
import { createAnalyticMiddleware } from '@/bootstrap/middlewares/analytics';
import { isCrudAction } from '@/util/crudUtils';
import type { EpicMiddleware } from 'redux-observable';
import { createEpicMiddleware } from 'redux-observable';
import { isDefined } from '@/util/undefinedAndNull/isDefined.ts';
import { debounce, snakeCase } from 'lodash';
import { getNeosRootEpic } from '@/neos/business/epics';
import { http } from '@/bootstrap/apis.ts';
import { selectors } from '@/bootstrap/selectors.ts';

export function createMiddlewares() {
  const batchActionsMiddleware = createActionContainerMiddleware();
  const errorHandlingMiddleware = createErrorHandlingMiddleware();
  const thunkMiddlewareLogger = createThunkLoggerMiddleware();

  const epicMiddleware = createEpicMiddleware() as EpicMiddleware<
    Dispatchable,
    Dispatchable,
    AppState
  >;
  const loggingMiddleware = createLoggingMiddleware(logger);
  const analyticMiddleware = createAnalyticMiddleware();

  const middlewares = [
    batchActionsMiddleware,
    errorHandlingMiddleware,
    thunkMiddlewareLogger,
    epicMiddleware,
    loggingMiddleware,
    analyticMiddleware,
  ];

  return { middlewares: middlewares.filter(isDefined), epicMiddleware };
}

export type AppState = ReturnType<typeof rootReducer>;

export function createFlowStore(preloadedState?: Partial<AppState>) {
  const { middlewares, epicMiddleware } = createMiddlewares();

  const store = configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: {
            selectors,
            actionCreators,
            services,
            thunks,
            fromMappers: mappers.fromOnyxMappers,
            toMappers: mappers.toOnyxMappers,
          },
        },
      }).prepend(middlewares),
    devTools: getDevToolsOptions(),
    enhancers: getDefaultEnhancers =>
      getDefaultEnhancers({
        autoBatch: {
          type: 'callback',
          queueNotification: notify => debounce(notify),
        },
      }),
  });
  // use in case of debug need with an initial state
  // return configureStore({
  //   reducer: rootReducer,
  //   middleware: middleWares,
  //   devTools: getDevToolsOptions(),
  //   preloadedState: initialStateJson,
  //   enhancers: getDefaultEnhancers =>
  //     getDefaultEnhancers({
  //       autoBatch: {
  //         type: 'callback',
  //         queueNotification: notify => debounce(notify),
  //       },
  //     }),
  // });

  const neosRootEpic = getNeosRootEpic(http);

  epicMiddleware.run(neosRootEpic);

  return {
    store,
  };
}

export type AppStore = ReturnType<typeof createFlowStore>['store'];

function getSanitizeWarningMessage<T>(field: string): T {
  return `THE ${field} VALUES ARE SANITIZED, comment ${field} in the stateSanitizer in bootstrap.ts to see the actual values.` as unknown as T;
}

function getDevToolsOptions(): DevToolsEnhancerOptions {
  return {
    actionSanitizer: (action: ReduxAction, _): any => {
      const a = action as Action;

      if (a.type === 'BLOTTER_RFQS_RECEIVED' && a.rfqs?.length) {
        return {
          ...a,
          rfqs: 'THE blotter VALUES ARE SANITIZED, comment the BLOTTER_RFQS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'MARKETS_RECEIVED' && a.markets) {
        return {
          ...a,
          markets:
            'THE markets VALUES ARE SANITIZED, comment the MARKETS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'MARKETS_ELIOT_RECEIVED' && a.markets) {
        return {
          ...a,
          marketsEliot:
            'THE markets eliot VALUES ARE SANITIZED, comment the MARKETS_ELIOT_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'STRATEGIES_DEFINITIONS_RECEIVED' && a.strategiesDefinitions) {
        return {
          ...a,
          strategiesDefinitions:
            'THE strategiesDefinitions VALUES ARE SANITIZED, comment the STRATEGIES_DEFINITIONS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'SALES_VALOS_RECEIVED' && a.salesValos) {
        return {
          ...a,
          salesValos:
            'THE salesValos VALUES ARE SANITIZED, comment the SALES_VALOS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'TRADER_GROUPS_RECEIVED' && a.traderGroupList) {
        return {
          ...a,
          traderGroupList:
            'THE traderGroupList VALUES ARE SANITIZED, comment the TRADER_GROUPS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'TRADERS_RECEIVED' && a.traders) {
        return {
          ...a,
          traders:
            'THE traders VALUES ARE SANITIZED, comment the TRADERS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'SALES_GROUPS_RECEIVED' && a.salesGroupList) {
        return {
          ...a,
          salesGroupList:
            'THE salesGroupList VALUES ARE SANITIZED, comment the SALES_GROUPS_RECEIVED condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      if (a.type === 'INSERT_OR_UPDATE' && a.domain === 'alert' && a.items?.length) {
        return {
          ...a,
          type: `$${snakeCase(a.domain).toLocaleUpperCase()}.${a.type}`,
          $originalType: a.type,
          items:
            'THE ALERT VALUES ARE SANITIZED, comment the INSERT_OR_UPDATE condition in actionSanitizer in bootstrap.ts to see the actual values.',
        };
      }

      return {
        ...a,
        type: isCrudAction(a)
          ? `$${snakeCase(a.domain).toLocaleUpperCase()}.${a.type}`
          : `${a.type}`,
        $originalType: a.type,
      };
    },
    stateSanitizer: (s, _): any => {
      const state = s as AppState;
      const unorderedState: AppState = {
        ...state,
        referenceData: {
          ...state.referenceData,
          areReferenceDataReceived: getSanitizeWarningMessage('areReferenceDataReceived'),
          counterparts: getSanitizeWarningMessage('counterparts'),
          currencies: getSanitizeWarningMessage('currencies'),
          currencyCurvesList: getSanitizeWarningMessage('currencyCurvesList'),
          marginRules: getSanitizeWarningMessage('marginRules'),
          predealChecksDerogations: getSanitizeWarningMessage('predealChecksDerogations'),
          salesGroups: getSanitizeWarningMessage('salesGroups'),
          salesLocations: getSanitizeWarningMessage('salesLocations'),
          salesValos: getSanitizeWarningMessage('salesValos'),
          strategyConfiguration: state.referenceData.strategyConfiguration,
          strategyDefinitions: getSanitizeWarningMessage('strategyDefinitions'),
          tenors: getSanitizeWarningMessage('tenors'),
          traderGroups: getSanitizeWarningMessage('traderGroups'),
          traders: getSanitizeWarningMessage('traders'),
          usMarkets: getSanitizeWarningMessage('usMarkets'),
          markets: getSanitizeWarningMessage('markets'),
          relatedExchangeFields: getSanitizeWarningMessage('relatedExchangeFields'),
          marketsEliot: getSanitizeWarningMessage('marketsEliot'),
        },
        blotter: {
          ...state.blotter,
          rfqs: getSanitizeWarningMessage('blotter'),
        },
        alerts: getSanitizeWarningMessage('alerts'),
      } as AppState;

      const orderedState = (Object.keys(unorderedState) as Array<keyof typeof unorderedState>)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unorderedState[key] as any;
          return obj;
        }, {} as AppState);
      return orderedState as AppState;
    },
  };
}
