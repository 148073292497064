import { thunks } from '@/bootstrap/thunks';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { ExportButton } from '@/neos/components/share/importExportClipboard/ExportButton.tsx';

type ElsBasketExportProps = {
  rfqId: string;
  product: ElsBasket;
};

export const ElsBasketExport = ({ rfqId, product }: ElsBasketExportProps) => {
  const dispatch = useAppDispatch();
  const isButtonDisabled = product.basketUnderlying.basketComposition.length === 0;

  const handleExport = () => {
    dispatch(thunks.neos.createExportElsBasketCompositionThunk(rfqId, product.uuid));
  };

  return (
    <ExportButton
      handleExport={handleExport}
      isDisabled={isButtonDisabled}
      dataE2e="els-basket-export"
    />
  );
};
