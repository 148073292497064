import type { Thunk } from '@/bootstrap/thunks';
import { getLegRecomputeScope, LegRecomputeChangedScope } from '@/neos/business/rfq/models';

import type { Action } from '@/bootstrap/actions.ts';

export function createProceedToStrategyLegOverThunk(rfqId: string, strategyId: string): Thunk {
  return function proceedToStrategyLegOverThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData, getLegData },
      actionCreators: {
        neos: { legDataCrudActions, createRecomputeRfqRequestedAction, strategyUiCrudActions },
      },
      thunks: {
        neos: { createRequestRfqFairPricesThunk },
      },
    },
  ) {
    const appState = getState();

    const { legIds, isMasterStrategy } = getStrategyData(appState, strategyId);
    const actions: Action[] = legIds
      .map(legId => {
        const legData = getLegData(appState, legId);

        return legData.isMaster
          ? {
              legId,
              newWeight: -legData.weight,
            }
          : { legId, newWeight: legData.weight };
      })
      .map(({ legId, newWeight }) => legDataCrudActions.update(legId, { weight: newWeight }));

    actions.push(
      createRecomputeRfqRequestedAction(
        rfqId,
        getLegRecomputeScope({
          scope: LegRecomputeChangedScope.WEIGHT,
          isMasterStrategy,
          isMasterLeg: true,
          hasWeightSignChanged: true,
        }),
        {
          success: {
            dispatchables: [createRequestRfqFairPricesThunk(rfqId)],
          },
        },
      ),
    );
    actions.push(strategyUiCrudActions.update(strategyId, { legOverMessageDismissed: true }));
    dispatch(actions);
  };
}
