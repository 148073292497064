import type { Selectors } from '@/bootstrap/selectors';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { getAvailableLotSizesMarketsForRfqStrategies } from './getAvailableLotSizesMarketsForRfqStrategies';
import { getCurrentLotSizeMarket } from './getCurrentLotSizeMarket';
import type { LotSizeMarket } from './getStrategyDefinitionSpecificProduct';
import type { AppState } from '@/bootstrap/store.ts';

export function getStrategyDefinitionAvailableLotSizeMarkets(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): LotSizeMarket[] {
  const products = selectors.getStrategyProducts(state, strategyId, selectors);
  if (products[0].hasCustomUnderlying) {
    return [];
  }

  const lotSizeMarket = getCurrentLotSizeMarket(products[0], state, strategyId, selectors);
  const lotSizeMarketsAsString = [lotSizeMarket].map(({ value }) => value).filter(isDefined);
  const underlyingId = selectors.getUnderlyingOrRefIdOfStrategy(state, strategyId, selectors);

  const availableLotSizesMarkets = getAvailableLotSizesMarketsForRfqStrategies(
    state,
    strategyId,
    underlyingId,
    lotSizeMarketsAsString,
    selectors,
  );

  return availableLotSizesMarkets;
}
