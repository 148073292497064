import type { Selectors } from '@/bootstrap/selectors';
import { allFieldsAreDefined } from '@/util/undefinedAndNull/allFieldsAreDefined';
import type { AppState } from '@/bootstrap/store.ts';

export function isFairPriceButtonEnabled(
  rfqId: string,
  appState: AppState,
  selectors: Selectors,
): boolean {
  const { strategyIds } = selectors.getRfqData(appState, rfqId);
  const isReadonlyRfq = selectors.isReadOnlyRfq(appState, rfqId);
  return (
    !isReadonlyRfq &&
    strategyIds.some(strategyId => {
      const { legIds, strategyType } = selectors.getStrategyData(appState, strategyId);
      const areAllLegsFullfilled = legIds.every(legId =>
        getIsLegFullFilled(appState, legId, selectors),
      );
      return areAllLegsFullfilled && strategyType !== undefined;
    })
  );
}

function getIsLegFullFilled(
  appState: AppState,
  legId: string,
  { getLegData, getProduct }: Selectors,
): boolean {
  const { numberOfLots, notional, varUnit, productId } = getLegData(appState, legId);
  const product = getProduct(appState, productId);
  switch (product.subFamily) {
    case 'OPTION': {
      const {
        lotSize: optionLotSize,
        style,
        strike,
        underlyingId: optionUnderlyingId,
        maturity: optionMaturity,
        type,
      } = product;

      return allFieldsAreDefined({
        optionLotSize,
        style,
        strike,
        optionUnderlyingId,
        optionMaturity,
        type,
        numberOfLots,
      });
    }
    case 'FUTURE': {
      const {
        lotSize: futureLotSize,
        underlyingId: futureUnderlyingId,
        maturity: futureMaturity,
      } = product;

      return allFieldsAreDefined({
        futureLotSize,
        futureUnderlyingId,
        futureMaturity,
        numberOfLots,
      });
    }

    case 'VOL_SWAP': {
      const { underlyingId: volSwapUnderlyingId, maturity: volSwapMaturity } = product;
      return allFieldsAreDefined({ volSwapUnderlyingId, volSwapMaturity, notional });
    }
    case 'VAR_SWAP': {
      const { underlyingId: varSwapUnderlyingId, maturity: varSwapMaturity } = product;
      return allFieldsAreDefined({
        varSwapUnderlyingId,
        varSwapMaturity,
        size: notional || varUnit,
      });
    }
    default:
      return true;
  }
}
