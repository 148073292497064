import type { Selectors } from '@/bootstrap/selectors';
import type { NeosBlotterRfq } from '@/neos/business/blotter/blotterModel';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import type { BlotterModelGetters } from '.';
import type { BlotterRowModel } from '../blotterRowModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getBlotterRow(
  state: AppState,
  rfq: OnyxRfq,
  selectors: Selectors,
  g: BlotterModelGetters,
): BlotterRowModel {
  return {
    uuid: rfq.uuid,
    source: rfq.source,
    hasMasterLeg: g.getMasterLeg(rfq, g) !== undefined,
    isLoadableRfq: g.getMasterLeg(rfq, g) !== undefined && g.isLoadableRfq(state, rfq, selectors),
    workflow: rfq.workflow,
    bookingInfo: g.getBlotterBookingInfoModel(rfq, g),
    creationTime: g.getBlotterCreationTime(rfq),
    updateTime: g.getBlotterUpdateTime(rfq),
    salesInit: g.getSalesInitName(state, rfq, selectors),
    salesValo: g.getSalesValoName(state, rfq, selectors),
    salesCounterpartyName: rfq.salesCounterparty?.name,
    statusLabel: g.getBlotterDisplayStatus(rfq, selectors),
    status: rfq.lifecycle.status,
    subStatus: rfq.lifecycle.subStatus,
    comment: rfq.comment,
    masterRefLevel: g.getMasterRefLevel(rfq, g),
    masterQuantity: g.getMasterQuantity(rfq, g),
    masterSize: g.getBlotterMasterSize(rfq, g),
    masterLotSize: g.getBlotterMasterLotSize(rfq, g),
    legSizes: g.getBlotterLegSizes(rfq, g),
    eurNotionalValue: rfq.eurNotional?.value,
    usdNotionalValue: rfq.usdNotional?.value,
    underlyingBloombergCodes: g.getUnderlyingBloombergCodes(rfq, g),
    allStrategyTypes: g.getAllStrategyTypes(state, rfq, selectors),
    legMaturities: g.getLegMaturities(rfq, g),
    legStrikes: g.getLegStrikes(rfq, g),
    traderGroups: g.getTraderGroups(rfq),
    rfqBid: rfq.quote?.traderPrice?.bid?.value,
    rfqMid: g.getRfqMid(rfq, g),
    rfqAsk: rfq.quote?.traderPrice?.ask?.value,
    isPtmmm: g.isPtmmm(rfq, g),
    totalSalesMarginEur: g.getTotalSalesMarginEur(rfq, g),
    totalCommissionAmountEur: rfq.totalCommissionAmountEur,
    rfqQuoteUnit:
      rfq.quote?.traderPrice?.ask?.unit ??
      rfq.quote?.traderPrice?.bid?.unit ??
      rfq.fairPrice?.price?.unit,
    clientWay: g.getBlotterClientWayLabel(rfq, g),
    isQuotePercent:
      (rfq.quote?.traderPrice?.ask?.unit || rfq.quote?.traderPrice?.bid?.unit) === '%',
    quoteRecap: rfq.quoteRecap,
    tradeRecap: rfq.tradeRecap,
    crossBorder: Boolean(rfq.crossBorder),
    extraFeatures: g.getBlotterExtraFeatures(rfq, g),
    tradeDate: g.getBlotterTradeDate(rfq),
    traders: g.getTraderNames(state, rfq, selectors, g),
    negotiationTypes: g.getBlotterNegoTypes(rfq),
    optionStyle: g.getBlotterOptionStyles(rfq, g),
    activity: rfq?.activity,
    secondaryEventType: g.getSecondaryEventType(rfq, g),
    feedbackLevel: rfq?.tradedAwayFeedback?.price?.value,
    observableType: g.getObservableType(rfq, g),
    elsType: g.getElsType(rfq),
  };
}

export function getFullBlotterRow(
  state: AppState,
  rfq: NeosBlotterRfq,
  selectors: Selectors,
  g: BlotterModelGetters,
): BlotterRowModel {
  return {
    ...getBlotterRow(state, rfq, selectors, g),
    preconfStatus: {
      externalMailStatus: rfq.externalMailStatus,
      internalMailStatus: rfq.internalMailStatus,
    },
  };
}
