import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import type { Counterpart } from '@/neos/business/neosModel';
import { CounterPart } from './CounterPart';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function Transferee({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useAppDispatch();
  const currentAllocation = useAppSelector(state =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const counterpart = currentAllocation?.secondaryInfo?.transferee;

  const onChange = (value: Counterpart | undefined) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        transferee: value,
      }),
    );
  };

  return (
    <CounterPart
      allocationId={allocationId}
      counterpart={counterpart}
      onChange={onChange}
      rfqId={rfqId}
      legId={legId}
    />
  );
}
