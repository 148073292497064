import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { DefaultingScope, isClsProduct } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import type { FixedDayType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { FixedDayValues } from './ScheduleModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function FixedDay({ strategyId, rfqId }: { rfqId: string; strategyId: string }) {
  const dispatch = useAppDispatch();

  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isClsProduct(product)) {
    throw new Error('product is not a cls');
  }

  function onFixedDayChanged(value: FixedDayType | undefined) {
    dispatch(actionCreators.neos.productCrudActions.update(uuid, { fixedDay: value }));
    const overrideScopes = [DefaultingScope.UNDERLYING];
    const defaultingOptions = new DefaultingOptions({ overrideScopes });
    dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, defaultingOptions));
  }

  const { fixedDay, uuid } = product;
  return (
    <>
      <label className="p-1">Reset on each</label>
      <NeosSelect
        options={Object.entries(FixedDayValues).map(([key, value]) => ({
          label: value,
          value: key as FixedDayType,
        }))}
        addEmptyOption
        onChange={onFixedDayChanged}
        value={fixedDay}
        data-e2e="schedule-rate-reset"
      />
    </>
  );
}
