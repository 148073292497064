import type { Thunk } from '@/bootstrap/thunks';
import type { ElsSectionName } from './elsSectionsModel';

export function createUpdateElsSectionHeightThunk(
  height: number,
  strategyId: string,
  sectionName: ElsSectionName,
): Thunk {
  return function updateElsSectionHeightThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { upsertElsSections },
      },
    },
  ) {
    const state = getState();

    const elsSections = selectors.getFeatureToggledElsSections(state, strategyId, selectors);

    const newLayout = elsSections.map(section =>
      section.i === sectionName ? { ...section, h: height } : section,
    );

    dispatch(upsertElsSections(newLayout));
  };
}
