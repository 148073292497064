import type { Selectors } from '@/bootstrap/selectors';
import type { BulletColor } from '../predealCheckBullet';
import { convertToBulletColor } from '../util/convertToBulletColor';
import { getAggregationColor } from '../util/getAggregationColor';
import { isRiskPdc } from '@/neos/business/predealCheck/predealCheckSelectors.ts';
import type { AppState } from '@/bootstrap/store.ts';

export interface AggregatedPredealCheckModel {
  riskColor: BulletColor;
  othersColor: BulletColor;
  isRefreshEnabled: boolean;
}

export function getAggregatedPredealCheckModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): AggregatedPredealCheckModel {
  const predealChecks = selectors.getNeosPredealChecks(state, rfqId, selectors);
  const isRefreshEnabled = !selectors.isReadOnlyRfq(state, rfqId);

  if (!predealChecks.length) {
    return { riskColor: 'unknown', othersColor: 'unknown', isRefreshEnabled };
  }

  const riskPdc = predealChecks.filter(isRiskPdc);
  const othersPdc = predealChecks.filter(pdc => !isRiskPdc(pdc));

  return {
    riskColor: getAggregationColor(riskPdc.map(pdc => convertToBulletColor(pdc.uiColor))),
    othersColor: getAggregationColor(othersPdc.map(pdc => convertToBulletColor(pdc.uiColor))),
    isRefreshEnabled,
  };
}
