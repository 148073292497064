import {
  type MailStatusType,
  type PreconfColor,
  mailStatusToColor,
} from '../shared/preconfirmationModel';
import type { InternalMailOptions } from './internalOnyxPreconfirmationModel';
import { internalPreconfirmationDomain } from './internalPreconfirmationDomain';
import type { AppState } from '@/bootstrap/store.ts';

export const internalPreconfirmationSelectors = {
  getInternalMailOptions,
  getInternalEmailStatus,
  getInternalPreconfBulletColor,
};

function getInternalPreconfState(state: AppState) {
  return state.preconfirmation.internalPreconfirmation;
}

function getInternalMailOptions(state: AppState, rfqId: string): InternalMailOptions | undefined {
  return internalPreconfirmationDomain.selectors.find(getInternalPreconfState(state), rfqId);
}

function getInternalEmailStatus(state: AppState, rfqId: string): MailStatusType {
  const internalPreconfirmation = internalPreconfirmationDomain.selectors.find(
    getInternalPreconfState(state),
    rfqId,
  );
  return internalPreconfirmation?.mailStatus ?? 'NOT_SENT';
}

function getInternalPreconfBulletColor(state: AppState, rfqId: string): PreconfColor {
  const emailStatus = getInternalEmailStatus(state, rfqId);
  return mailStatusToColor[emailStatus];
}
