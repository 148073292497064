import type { Selectors } from '@/bootstrap/selectors';
import { getDifferencesExceptForIrrelevantFields } from '@/util/comparison/getDifferences';
import { getKeys } from '@/util/getKeys/getKeys';
import type { ToOnyxMappers } from '../mappers';
import type { RfqDifference } from '../neosModel';
import type { OnyxRfq } from '../neosOnyxModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getDifferencesBetweenCurrentAndOnyxRfq(
  state: AppState,
  rfqId: string,
  onyxRfqToCompare: OnyxRfq | undefined,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): RfqDifference[] {
  if (!onyxRfqToCompare) {
    return [];
  }

  const currentRfq = mappers.mapToOnyxRfqWithCurrentLocalVersion(state, rfqId, selectors, mappers);

  return getDifferencesExceptForIrrelevantFields(currentRfq, onyxRfqToCompare).map(
    (diff): RfqDifference => {
      switch (diff.kind) {
        case 'E':
          return {
            path: computePath(diff.path),
            currentValue: displayValue(diff.lhs),
            newValue: displayValue(diff.rhs),
          };
        case 'D':
          return {
            path: computePath(diff.path),
            currentValue: displayValue(diff.lhs),
            newValue: '',
          };
        case 'A':
          return {
            path: computePath(diff.path) + `[${diff.index}]`,
            currentValue: '',
            newValue: diff.item.kind === 'N' ? 'Added' : diff.item.kind === 'D' ? 'Deleted' : '',
          };
        default:
          throw 'there is no other differences types. This cant happen';
      }
    },
  );
}

function displayValue(value: unknown): string {
  if (value === undefined || value === null) {
    return '';
  }

  if (typeof value !== 'object') {
    return (value as any).toString();
  }

  return `{${getKeys(value as object).map(key => ` ${key}: ${displayValue(value![key])}`)} }`;
}

function computePath(diffPath: Array<string | number> | undefined): string {
  if (!diffPath) {
    return '';
  }
  return diffPath.reduce(
    (previous: string, current): string =>
      typeof current === 'string' ? `${previous}.${current}` : `${previous}[${current}]`,
    'rfq',
  );
}
