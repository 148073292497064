import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  type PredealChecksDetailsDispatchProps,
  type PredealChecksDetailsOwnProps,
  type PredealChecksDetailsPropsFromState,
  PredealChecksDetailsWithStyle,
} from './PredealCheckDetails';
import { getPredealCheckDetailsModel } from './getPredealCheckDetailsModel';
import type { AppState } from '@/bootstrap/store.ts';

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  props: PredealChecksDetailsOwnProps,
): PredealChecksDetailsDispatchProps {
  return {
    onDerogate(derogateAll, derogationKey) {
      dispatch(
        neosThunks.createPredealCheckDerogatedThunk(props.rfqId, derogateAll, derogationKey),
      );
    },
    onDerogationRiskIdChange(predealCheckId, riskId, oldRiskId) {
      if (riskId !== oldRiskId) {
        dispatch(
          actionCreators.neos.predealChecksCrudActions.update(predealCheckId, {
            riskId,
            hasRiskFieldsChanged: true,
          }),
        );
      }
    },
    onDerogationCommentChange(predealCheckId, derogationComment, oldDerogationComment) {
      if (derogationComment !== oldDerogationComment) {
        dispatch(
          actionCreators.neos.predealChecksCrudActions.update(predealCheckId, {
            derogationComment,
            hasRiskFieldsChanged: true,
          }),
        );
      }
    },
    onClose() {
      dispatch(actionCreators.neos.createNeosPredealCheckUnselected(props.rfqId));
    },
  };
}

function mapStateToProps(
  state: AppState,
  props: PredealChecksDetailsOwnProps,
): PredealChecksDetailsPropsFromState {
  return getPredealCheckDetailsModel(state, props.rfqId, selectors);
}

export const PredealChecksDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PredealChecksDetailsWithStyle);
