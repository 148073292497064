import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { sortBy, uniq } from 'lodash';
import { counterpartsDomain } from './counterpartsDomain';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel.ts';
import { type Selectors } from '@/bootstrap/selectors.ts';
import type { AppState } from '@/bootstrap/store.ts';

export const counterpartsSelectors = {
  getCounterparts,
  getCounterpartsBySales,
  getAndFormatAvailableClients,
};

function getCounterparts(state: AppState, rfqId: string): Counterpart[] | undefined {
  const { salesInitId, salesValoId } = state.actorsSetup[rfqId];
  const salesIds = uniq([salesInitId, salesValoId].filter(isDefined));
  return getCounterpartsBySales(state, salesIds);
}

function getCounterpartsBySales(state: AppState, salesIds: string[]): Counterpart[] | undefined {
  return counterpartsDomain.selectors.find(state.counterparts, salesIds);
}

function getAndFormatAvailableClients(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Counterpart[] {
  const counterparts = selectors.getCounterparts(state, rfqId) ?? [];
  const capitalizedCounterparts = counterparts.map(capitalizeCounterpartName);
  return sortBy(capitalizedCounterparts, ({ name }) => name);
}

function capitalizeCounterpartName(counterpart: Counterpart): Counterpart {
  return {
    ...counterpart,
    name: counterpart.name.toUpperCase(),
  };
}
