import type { Selectors } from '@/bootstrap/selectors';
import type { PredealCheck } from '../predealCheck/predealCheckModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getNeosPredealChecks(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PredealCheck[] {
  const counterpartyId = selectors.getSelectedCounterpartId(state, rfqId);
  const brokerId = selectors.getElsRfqBrokerInfo(state, rfqId, selectors)?.id;

  return [
    ...selectors.getRequestExceptPttPredealChecksByBdrId(state, rfqId, counterpartyId, selectors),
    ...selectors.getRequestExceptPttPredealChecksByBdrId(state, rfqId, brokerId, selectors),
    ...selectors.getNeosPttPredealChecks(state, rfqId, selectors),
    ...selectors.getAllNeosAllocationPredealChecks(state, rfqId, selectors),
    ...selectors.getNeosDeltaPredealChecks(state, rfqId, selectors),
  ];
}
