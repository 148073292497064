import type { Selectors } from '@/bootstrap/selectors';
import { type AccrualType, isClsProduct } from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';
import type { AppState } from '@/bootstrap/store.ts';

export interface AccrualCellsModel {
  accruals: Array<Field<AccrualType | undefined>>;
  availableAccruals: Array<AccrualType | undefined>;
}

export function getAccrualCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): AccrualCellsModel {
  const { getStrategyData, getStrategyProducts } = selectors;
  const { getStrategyDefinition } = selectors;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductAccrual, readOnlyProductAccrual } = getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  const products = getStrategyProducts(appState, strategyId, selectors).filter(isClsProduct);

  const accrualsAreEditable = getIsStrategyDefinitionFieldEditable(
    appState,
    rfqId,
    strategyId,
    selectors,
    ['RFQ_SCOPE', 'RFQ_EDITABLE', 'UNDERLYING_SET', 'STRATEGY_EDITABLE'],
  );

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isReadonly = readOnlyProductAccrual || !accrualsAreEditable || isReadonlyAtWorkflow;
  const accruals = getFieldsArray(products, 'accrual', sameProductAccrual, isReadonly);

  return {
    accruals,
    availableAccruals: ['ACT360', 'ACT365', undefined],
  };
}
