import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import {
  dividendFxFixingDateValues,
  type DividendPaymentDate,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NumericInput } from '@/neos/components/share/numericInput';
import {
  type DividendFxFixingDate,
  type DividendType,
  DividendTypeValues,
} from '../../../../../../../../neos/business/neosOnyxModel';

import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { DividendPaymentDateValues } from '../../elsClsSchedule/ScheduleModel';
import { commonElsBlocClasses, ElsFeaturesContext } from '../ElsFeatures';
import { useUpdateSectionHeight } from '../useUpdateSectionHeight';
import { SpecialDividends } from './SpecialDividends';
import { isElsBasketProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { If } from '@/neos/components/share/if/if';
import { ElsDividendPreconfComment } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/DividendBloc/ElsDividendPreconfComment.tsx';

export function DividendBloc() {
  const { rfqId, strategyId, product } = useContext(ElsFeaturesContext);
  const elsBasketProduct = isElsBasketProduct(product);

  const dispatch = useAppDispatch();

  const dividendFeature = useAppSelector(state =>
    selectors.getFeature(state.featureState, {
      strategyId,
      type: 'DIVIDEND_COMPONENT',
    }),
  );

  const { ref } = useUpdateSectionHeight(strategyId, 'Dividend Leg');

  const currencies = useAppSelector(state => selectors.getCurrencyList(state.referenceData));
  const strategyProducts = useAppSelector(state =>
    selectors.getStrategyProducts(state, strategyId, selectors),
  );

  function dispatchElsObsoleteScheduleWarningForStrategyProducts() {
    strategyProducts.forEach(product =>
      dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true)),
    );
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Dividend Leg</h5>
        </div>
        <div className="d-flex flex-wrap">
          <ElsBlocField
            label="Dividend Type"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                options={Object.entries(DividendTypeValues).map(([value, label]) => ({
                  value: value as DividendType,
                  label,
                }))}
                onChange={value => {
                  dispatchElsObsoleteScheduleWarningForStrategyProducts();
                  dispatch(
                    thunks.neos.createChangeFeatureFieldThunk(
                      { strategyId, type: 'DIVIDEND_COMPONENT' },
                      { dividendType: value },
                    ),
                  );
                }}
                addEmptyOption
                value={dividendFeature?.dividendType}
                data-e2e="dividend-section-dividend-type"
              />
            )}
          />

          <ElsBlocField
            label="Dividend Payment Date"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                options={Object.entries(DividendPaymentDateValues).map(([value, label]) => ({
                  value: value as DividendPaymentDate,
                  label,
                }))}
                onChange={value => {
                  dispatch(
                    thunks.neos.createChangeFeatureFieldThunk(
                      { strategyId, type: 'DIVIDEND_COMPONENT' },
                      { dividendPaymentDateType: value },
                    ),
                  );
                  dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
                }}
                addEmptyOption
                value={dividendFeature?.dividendPaymentDateType}
                data-e2e="dividend-section-dividend-payment-date-type"
              />
            )}
          />

          <ElsBlocField
            label="Div. Payment Date Offset"
            renderInput={readOnly => (
              <NumericInput
                readOnly={readOnly}
                value={dividendFeature?.dividendPaymentDateOffset}
                onBlur={value => {
                  dispatchElsObsoleteScheduleWarningForStrategyProducts();
                  dispatch(
                    thunks.neos.createChangeFeatureFieldThunk(
                      { strategyId, type: 'DIVIDEND_COMPONENT' },
                      { dividendPaymentDateOffset: value },
                    ),
                  );
                }}
                data-e2e="dividend-section-dividend-payment-date-offset"
              />
            )}
          />

          <ElsBlocField
            label="Dividend Fx Fixing Date"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                options={Object.entries(dividendFxFixingDateValues).map(([value, label]) => ({
                  value: value as DividendFxFixingDate,
                  label,
                }))}
                onChange={value => {
                  dispatchElsObsoleteScheduleWarningForStrategyProducts();
                  dispatch(
                    thunks.neos.createChangeFeatureFieldThunk(
                      { strategyId, type: 'DIVIDEND_COMPONENT' },
                      { dividendFxFixingDate: value },
                    ),
                  );
                }}
                addEmptyOption
                data-e2e="dividend-section-dividend-fx-fixing-date"
                value={dividendFeature?.dividendFxFixingDate}
              />
            )}
          />

          <ElsBlocField
            label="Div. Payment Currency"
            renderInput={readOnly => (
              <NeosSelect
                readOnly={readOnly}
                options={currencies.map(({ refId }) => ({ value: refId, label: refId }))}
                onChange={value => {
                  dispatch(
                    thunks.neos.createChangeFeatureFieldThunk(
                      { strategyId, type: 'DIVIDEND_COMPONENT' },
                      { dividendPaymentCurrency: value },
                    ),
                  );
                }}
                addEmptyOption
                value={dividendFeature?.dividendPaymentCurrency}
                data-e2e="dividend-section-dividend-payment-currency"
              />
            )}
          />

          <ElsBlocField
            label="Dividend Requirement"
            renderInput={readOnly => (
              <div
                className="d-grid gap-4px"
                style={{ gridTemplateColumns: 'minmax(85px, 120px) min-content' }}
              >
                <NumericInput
                  readOnly={readOnly}
                  value={dividendFeature?.dividendRequirement?.value}
                  onBlur={value =>
                    dispatch(
                      thunks.neos.createChangeFeatureFieldThunk(
                        { strategyId, type: 'DIVIDEND_COMPONENT' },
                        {
                          dividendRequirement: {
                            ...dividendFeature?.dividendRequirement,
                            value,
                            unit: '%',
                          },
                        },
                      ),
                    )
                  }
                  data-e2e="dividend-section-dividend-dividend-requirement"
                />
                <div className="d-flex align-items-center">%Total</div>
              </div>
            )}
          />

          <If condition={!elsBasketProduct}>
            <ElsBlocField
              label="Dividend Amount"
              renderInput={readOnly => {
                return (
                  <div
                    className="d-grid gap-4px"
                    style={{ gridTemplateColumns: 'minmax(85px, 120px) 70px' }}
                  >
                    <NumericInput
                      readOnly={readOnly}
                      value={dividendFeature?.dividendAmount?.value}
                      onBlur={value =>
                        dispatch(
                          thunks.neos.createChangeFeatureFieldThunk(
                            { strategyId, type: 'DIVIDEND_COMPONENT' },
                            { dividendAmount: { ...dividendFeature?.dividendAmount, value } },
                          ),
                        )
                      }
                      data-e2e="dividend-section-dividend-dividend-amount"
                    />
                    <NeosSelect
                      readOnly={readOnly}
                      options={currencies.map(({ refId }) => ({ value: refId, label: refId }))}
                      onChange={value => {
                        dispatch(
                          thunks.neos.createChangeFeatureFieldThunk(
                            { strategyId, type: 'DIVIDEND_COMPONENT' },
                            {
                              dividendAmount: {
                                ...dividendFeature?.dividendAmount,
                                unit: value,
                              },
                            },
                          ),
                        );
                      }}
                      addEmptyOption
                      value={dividendFeature?.dividendAmount?.unit}
                      data-e2e="dividend-section-dividend-dividend-amount-currency"
                    />
                  </div>
                );
              }}
            />
          </If>

          <SpecialDividends />
        </div>
        <div className="d-flex flex-wrap">
          <ElsDividendPreconfComment />
        </div>
      </div>
    </div>
  );
}
