import { type Selectors } from '@/bootstrap/selectors.ts';
import { commonSelectors } from '@/common/business/commonSelectors.ts';
import {
  type DisplayUser,
  toDisplayUser,
  tryGetUserFullName,
  userIdToFullName,
} from '@/neos/components/share/userUtils/userUtils.ts';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel.ts';
import { sortBy } from 'lodash';
import type { User } from '@/neos/business/referenceData/referenceDataModel.ts';
import type { PredealCheckUiColor } from '@/neos/business/predealCheck/predealCheckModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface ActorSetupModel {
  counterpartPopoverInfo: Counterpart | undefined;
  availableSalesValos: DisplayUser[];
  salesInitId: string;
  selectedSalesValo: string | undefined;
  selectedCounterpart: string | undefined;
  salesInitName: string | undefined;
  availableClients: Counterpart[];
  selectedClient: string | undefined;
  selectedClientKycTextColor: KycTextColor;
  isReadonlyRfq: boolean;
  isDisabled: boolean;
  userInitFieldName: string;
  isInternalEls: boolean;
  isElsIdb: boolean;
}

export function getActorSetupModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ActorSetupModel {
  const isTrader = selectors.isTrader(state);

  const selectedSalesValo = selectors.getSelectedSalesValoId(state, rfqId);
  const salesValos = selectors.getSalesValos(state.referenceData);
  const availableSalesValos = getAndFormatSalesValos(isTrader, salesValos, selectedSalesValo);

  const userName = commonSelectors.getUserName(state);
  const sesameId = commonSelectors.getSesameId(state);
  const actorsSetup = selectors.getActorsSetup(state, rfqId);
  const salesInitId = actorsSetup?.salesInitId ?? sesameId;

  const salesInitName = tryGetUserFullName(salesValos, salesInitId, userName);
  const counterpartPopoverInfo = selectors.getSalesCounterpart(state, rfqId, selectors);

  const { status, initiatedByTrader } = selectors.getRfqData(state, rfqId);

  const availableClients = selectors.getAndFormatAvailableClients(state, rfqId, selectors);
  const selectedClientId =
    selectors.getSelectedCounterpartId(state, rfqId) ||
    (availableClients.length === 1 ? availableClients[0].id : undefined);
  const selectedClientKycColor =
    selectors
      .getRequestExceptPttPredealChecksByBdrId(state, rfqId, selectedClientId, selectors)
      .find(pdc => pdc.type === 'KYC')?.uiColor || 'UNKNOWN';

  const userInitFieldName = `${initiatedByTrader ? 'Trader' : 'Sales'} init`;
  const isInternalEls = selectors.isRfqInternalEls(state, rfqId, selectors);
  const isElsIdb = selectors.isRfqInitiatedByTrader(state, rfqId);
  const selectedClient = selectedClientId?.toString();

  return {
    counterpartPopoverInfo,
    availableSalesValos,
    salesInitId,
    selectedSalesValo,
    salesInitName,
    selectedClient,
    selectedClientKycTextColor: getKycTextColor(selectedClientKycColor),
    availableClients,
    isReadonlyRfq: selectors.isReadOnlyRfq(state, rfqId),
    isDisabled:
      selectors.isStatusOrderConfirmedOrAfter(state, rfqId, selectors) &&
      status !== 'AMEND_REQUESTED',
    userInitFieldName,
    isInternalEls,
    isElsIdb,
    selectedCounterpart: availableClients.find(client => client.id.toString() === selectedClient)
      ?.name,
  };
}

export type KycTextColor = 'text-warning' | 'text-success' | 'text-danger' | '';

export function getKycTextColor(color: PredealCheckUiColor): KycTextColor {
  switch (color) {
    case 'AMBER':
    case 'AMBER_BLUR':
      return 'text-warning';
    case 'GREEN':
    case 'GREEN_BLUR':
      return 'text-success';
    case 'RED':
    case 'RED_DEROGATED':
    case 'RED_BLUR':
      return 'text-danger';
    default:
      return '';
  }
}

function getAndFormatSalesValos(
  isTrader: boolean,
  salesValos: Record<string, User>,
  selectedSalesValo: string | undefined,
): DisplayUser[] {
  const salesValoList: DisplayUser[] = Object.values(salesValos).map(toDisplayUser);

  if (!isTrader && selectedSalesValo && !salesValos[selectedSalesValo]) {
    salesValoList.push({
      id: selectedSalesValo,
      fullName: userIdToFullName(selectedSalesValo),
    });
  }

  return sortBy(salesValoList, salesValo => salesValo.fullName.toLocaleLowerCase());
}
