import type { Selectors } from '@/bootstrap/selectors';
import type { UnderlyingType } from '@/neos/business/rfq/strategy/leg/legOnyxModel';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getUnderlyingFieldsArray } from './getUnderlyingFieldsArray';
import type { AppState } from '@/bootstrap/store.ts';

export interface BasketUnderlyingField {
  type: 'BASKET';
  legId: string;
}
export interface CustomUnderlyingField {
  type: 'CUSTOM';
  name: string | undefined;
  legId: string;
  isDisabled: boolean;
}

export interface NonCustomUnderlyingField {
  type: 'NON_CUSTOM';
  legId: string;
  underlyingId: string | undefined;
  bloombergCode: string | undefined;
  underlyingTypes: UnderlyingType[];
  isDisabled: boolean;
}

export type UnderlyingField =
  | NonCustomUnderlyingField
  | CustomUnderlyingField
  | BasketUnderlyingField;

export interface UnderlyingCellsModel {
  disabled: boolean;
  selectedUnderlyings: UnderlyingField[];
}

export function getUnderlyingCellsModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): UnderlyingCellsModel {
  const selectedUnderlyings = getUnderlyingFieldsArray(state, rfqId, strategyId, selectors);

  const disabled = !getIsStrategyDefinitionFieldEditable(state, rfqId, strategyId, selectors, [
    'RFQ_SCOPE',
    'RFQ_EDITABLE',
    'STRATEGY_EDITABLE',
  ]);

  return {
    disabled,
    selectedUnderlyings,
  };
}
