import type { Selectors } from '@/bootstrap/selectors';
import type { BookingStepStatus } from '@/neos/business/neosOnyxModel';
import type { ExecutionKey } from '@/neos/business/order/orderModel';
import { getBookingStatus } from '../getBookingStatus';
import type { AppState } from '@/bootstrap/store.ts';

export type CashFlowIdModel = {
  cashFlowId: string | undefined;
  defaultCashFlowId?: string;
  status: BookingStepStatus | undefined;
};

export function getCashFlowIdModel(
  state: AppState,
  executionKey: ExecutionKey,
  selectors: Selectors,
): CashFlowIdModel {
  const execution = selectors.executionSelectors.find(state.execution, executionKey);

  if (!execution) {
    return { cashFlowId: undefined, status: undefined };
  }

  const eliotCashFlowBookingStep = selectors.getEliotCashFlowBookingStep(
    state,
    executionKey.rfqId,
    executionKey.executionId,
  );

  return {
    cashFlowId: execution.cashFlowId?.id ?? eliotCashFlowBookingStep?.bookingId,
    defaultCashFlowId: eliotCashFlowBookingStep?.bookingId,
    status: execution.cashFlowId?.id
      ? undefined
      : getBookingStatus(eliotCashFlowBookingStep).status,
  };
}
