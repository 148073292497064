import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { NumericInput } from '@/neos/components/share/numericInput';
import type { FC } from 'react';
import type { ExpectedNModel } from './getExpectedNModel';

export interface ExpectedNCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

export interface ExpectedNCellsMapStateProps {
  model: ExpectedNModel;
}

export interface ExpectedNCellsDispatchProps {
  onExpectedNChanged(legId: string, expectedN: number | undefined): void;
}

type ExpectedNCellsProps = ExpectedNCellsOwnProps &
  ExpectedNCellsMapStateProps &
  ExpectedNCellsDispatchProps;

export const ExpectedNCellsComponent: FC<ExpectedNCellsProps> = ({
  rfqId,
  strategyId,
  isReadOnlyRfq,
  model,
  onExpectedNChanged,
}) => {
  return (
    <section>
      {model.expectedNs.map(({ id: legId, value, isEditable }) => (
        <ErrorHighlight key={legId} errorField={'expectedN'} related={{ rfqId, legId, strategyId }}>
          <NumericInput
            value={value}
            onBlur={expectedN => onExpectedNChanged(legId, expectedN)}
            readOnly={!isEditable || isReadOnlyRfq}
            onlyPositiveNumbers
          />
        </ErrorHighlight>
      ))}
    </section>
  );
};
