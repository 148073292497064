import { NumericInput } from '@/neos/components/share/numericInput';
import { Fragment } from 'react';
import { type FairPricesColumns, getFairPricesGridColmunTemplate } from './fairPricesCommon';
import type { FairPricesModel } from './getFairPricesModel';

import styles from './FairPrices.module.scss';

export interface FairPricesMapStateProps {
  model: FairPricesModel;
}

export interface FairPricesOwnProps {
  strategyId: string;
  rfqId: string;
  onFocus?(): void;
}

export function FairPricesComponentWithCss({
  model,
  onFocus,
}: FairPricesOwnProps & FairPricesMapStateProps) {
  const columns: FairPricesColumns = [];

  if (model.isVolBidAskPricesDisplayed) {
    columns.push({
      columnKey: 'VOL_BID',
      element: (
        <section>
          {model.legsVolBidAsk.map((volBidAsk, index) => (
            <NumericInput
              value={volBidAsk.volBid}
              unit={volBidAsk.volBidUnit}
              key={index}
              withMaximumNumberOfFloatingDigits={2}
              readOnly
            />
          ))}
          {model.displayReadonlyCellsForCompositionLeg && <NumericInput readOnly />}
          <NumericInput
            value={model.strategyVolBidAsk.volBid}
            unit={model.strategyVolBidAsk.volBidUnit}
            className={`${styles['strategy-price']}`}
            readOnly
            data-e2e="neos-strategy-faire-price-vol-bid"
            withMaximumNumberOfFloatingDigits={2}
          />
        </section>
      ),
    });
  }

  if (model.isUsualFairPricesDisplayed && !model.areAllStrategiesFutureLike) {
    columns.push({
      columnKey: 'VOL',
      element: (
        <section>
          {model.legsFairPrices.map((fairPrices, index) => (
            <NumericInput
              value={fairPrices.volatility}
              key={index}
              withMaximumNumberOfFloatingDigits={2}
              readOnly
            />
          ))}
          {model.displayReadonlyCellsForCompositionLeg && <NumericInput readOnly />}
          <NumericInput
            value={model.strategyFairPrice.volatility}
            className={`${styles['strategy-price']}`}
            readOnly
            data-e2e="neos-strategy-faire-price-vol"
            withMaximumNumberOfFloatingDigits={2}
          />
        </section>
      ),
    });
  }

  if (model.isVolBidAskPricesDisplayed) {
    columns.push({
      columnKey: 'VOL_ASK',
      element: (
        <section>
          {model.legsVolBidAsk.map((volBidAsk, index) => (
            <NumericInput
              value={volBidAsk.volAsk}
              unit={volBidAsk.volAskUnit}
              key={index}
              withMaximumNumberOfFloatingDigits={2}
              readOnly
            />
          ))}
          {model.displayReadonlyCellsForCompositionLeg && <NumericInput readOnly />}
          <NumericInput
            value={model.strategyVolBidAsk.volAsk}
            unit={model.strategyVolBidAsk.volAskUnit}
            className={`${styles['strategy-price']}`}
            readOnly
            data-e2e="neos-strategy-faire-price-vol-ask"
            withMaximumNumberOfFloatingDigits={2}
          />
        </section>
      ),
    });
  }

  if (model.isUsualFairPricesDisplayed) {
    columns.push({
      columnKey: 'DELTA',
      element: (
        <section>
          {model.legsFairPrices.map((fairPrices, index) => (
            <NumericInput
              unit="%"
              value={fairPrices.delta}
              withMaximumNumberOfFloatingDigits={2}
              readOnly
              key={index}
            />
          ))}
          {model.displayReadonlyCellsForCompositionLeg && <NumericInput readOnly />}
          <NumericInput
            unit="%"
            value={model.strategyFairPrice.delta}
            className={`${styles['strategy-price']}`}
            readOnly
            data-e2e="neos-strategy-faire-price-delta"
            withMaximumNumberOfFloatingDigits={2}
          />
        </section>
      ),
    });

    if (!model.areAllStrategiesFutureLike) {
      columns.push({
        columnKey: 'VEGA',
        element: (
          <section>
            {model.legsFairPrices.map((fairPrices, index) => (
              <NumericInput
                value={fairPrices.vega}
                withMaximumNumberOfFloatingDigits={2}
                readOnly
                key={index}
              />
            ))}
            {model.displayReadonlyCellsForCompositionLeg && <NumericInput readOnly />}
            <NumericInput
              value={model.strategyFairPrice.vega}
              className={`${styles['strategy-price']}`}
              readOnly
              data-e2e="neos-strategy-faire-price-vega"
              withMaximumNumberOfFloatingDigits={2}
            />
          </section>
        ),
      });
    }
  }

  if (model.isOtherFairGreeksDisplayed) {
    columns.push(
      {
        columnKey: 'GAMMA',
        element: (
          <section>
            {model.legsFairPrices.map((fairPrices, index) => (
              <NumericInput
                value={fairPrices.gamma}
                withMaximumNumberOfFloatingDigits={2}
                readOnly
                key={index}
              />
            ))}
            {model.displayReadonlyCellsForCompositionLeg && <NumericInput readOnly />}
            <NumericInput
              value={model.strategyFairPrice.gamma}
              className={`${styles['strategy-price']}`}
              readOnly
              withMaximumNumberOfFloatingDigits={2}
            />
          </section>
        ),
      },
      {
        columnKey: 'THETA',
        element: (
          <section>
            {model.legsFairPrices.map((fairPrices, index) => (
              <NumericInput
                value={fairPrices.theta}
                withMaximumNumberOfFloatingDigits={2}
                readOnly
                key={index}
              />
            ))}
            {model.displayReadonlyCellsForCompositionLeg && <NumericInput readOnly />}
            <NumericInput
              value={model.strategyFairPrice.theta}
              className={`${styles['strategy-price']}`}
              readOnly
              withMaximumNumberOfFloatingDigits={2}
            />
          </section>
        ),
      },
    );
  }
  const gridTemplateColumns = getFairPricesGridColmunTemplate(columns);

  return (
    <section
      className={`${styles['fair-prices']}`}
      style={{
        gridTemplateColumns,
      }}
      onFocus={onFocus}
    >
      {columns.map(({ element, columnKey }) => (
        <Fragment key={columnKey}>{element}</Fragment>
      ))}
    </section>
  );
}
