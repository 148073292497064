import { neosThunks } from '@/neos/business/thunks';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { ImportButton } from '@/neos/components/share/importExportClipboard/ImportButton.tsx';

type ElsBasketImportProps = {
  rfqId: string;
  product: ElsBasket;
};

export const ElsBasketImport = ({ product, rfqId }: ElsBasketImportProps) => {
  const dispatch = useAppDispatch();

  function onImport(importedData: unknown[]) {
    dispatch(
      neosThunks.createImportBasketCompositionThunk(rfqId, product.uuid, importedData, 'file'),
    );
  }

  return <ImportButton handleImport={onImport} dataE2e="els-basket-import" />;
};
