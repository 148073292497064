import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

const availableSettingTabs = [
  'General',
  'RFQ',
  'Els Strategy',
  'Notifications',
  'Preconfirmations',
  'Counterparties',
  'Defaulting',
  'Brokers',
  'Default Trading Group',
] as const;
export type SettingTab = (typeof availableSettingTabs)[number];

export interface UserPreferencesModalModel {
  showModal: boolean;
  isDefaultingFeatureToggled: boolean;
  isPreconfToggled: boolean;
  isBrokersTabDisplayed: boolean;
  isDefaultTradingGroupTabDisplayed: boolean;
  tabNames: SettingTab[];
}

export function getUserPreferencesModel(
  state: AppState,
  selectors: Selectors,
): UserPreferencesModalModel {
  const userPreferencesState = state.ui.userPreferences;
  const isPreconfToggled =
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmail.enabled') ||
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmailFuture.enable') ||
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmailDivFut.enable');
  const isDefaultingFeatureToggled = selectors.isFeatureToggleEnabled(
    state,
    'neos.user.preferences.defaulting',
  );
  const isTrader = selectors.isTrader(state);

  const isBrokersTabDisplayed = isTrader;
  const isDefaultTradingGroupTabDisplayed = !isTrader;

  const tabNames: SettingTab[] = availableSettingTabs.filter(tab => {
    switch (tab) {
      case 'Preconfirmations':
        return isPreconfToggled;
      case 'Defaulting':
        return isDefaultingFeatureToggled;
      case 'Brokers':
        return isBrokersTabDisplayed;
      case 'Default Trading Group':
        return isDefaultTradingGroupTabDisplayed;
      default:
        return true;
    }
  });

  return {
    isPreconfToggled,
    isDefaultingFeatureToggled,
    isBrokersTabDisplayed,
    isDefaultTradingGroupTabDisplayed,
    tabNames,
    showModal: selectors.isUserPreferencesModalShown(userPreferencesState),
  };
}
