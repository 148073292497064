import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import type { PriceUnitType } from '@/neos/business/neosModel';
import { otcAllocationsIaCurrencies } from '@/neos/business/neosModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function AmountDue({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useAppDispatch();

  const currentAllocation = useAppSelector(state =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );

  const ccy = currentAllocation?.secondaryInfo?.amountDue?.nominal.unit;
  const amountDue = currentAllocation?.secondaryInfo?.amountDue?.nominal.value;

  const onAmountDueChange = (value: number | undefined) => {
    dispatch(
      thunks.neos.createUpdateSecondaryAmountDueThunk(allocationId, rfqId, legId, {
        value,
      }),
    );
  };
  const onAmountDueCurrencyChange = (value: string | undefined) => {
    const patch =
      value === '%'
        ? { unit: value, type: 'REF_PERCENT' as PriceUnitType }
        : { unit: value ? value : undefined, type: value ? ('CCY' as PriceUnitType) : undefined };
    dispatch(thunks.neos.createUpdateSecondaryAmountDueThunk(allocationId, rfqId, legId, patch));
  };

  let iaCurrencies = otcAllocationsIaCurrencies;
  if (ccy && !iaCurrencies.includes(ccy)) {
    iaCurrencies = [ccy, ...iaCurrencies];
  }

  return (
    <div className="d-flex">
      <NumericInput
        value={amountDue}
        onBlur={value => onAmountDueChange(value)}
        disableAccelerators
        onlyPositiveNumbers
        data-e2e={`otc-alloc-anount-due-value`}
      />
      <NeosSelect
        style={{ width: '100px' }}
        value={ccy}
        onChange={selected => onAmountDueCurrencyChange(selected)}
        addEmptyOption
        options={iaCurrencies.map(ccy => ({
          value: ccy,
          label: ccy,
        }))}
        data-e2e={`otc-alloc-anount-due-ccy`}
      />
    </div>
  );
}
