import type { Selectors } from '@/bootstrap/selectors';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { type PttPredealCheck, isPttPredealCheck } from '../predealCheck/predealCheckModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getNeosPttPredealChecks(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PttPredealCheck[] {
  const { predealCheckIds } = selectors.getRfqData(state, rfqId);
  return predealCheckIds
    .map(id => selectors.getPredealCheck(state, id))
    .filter(isDefined)
    .filter(isPttPredealCheck);
}
