import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { DescriptionFeatureKey } from '../../../../../../../neos/business/neosModel';
import {
  type DescriptionDispatchProps,
  type DescriptionOwnProps,
  type DescriptionStateProps,
  DescriptionStyled,
} from './Description';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(
  appState: AppState,
  { strategyId }: DescriptionOwnProps,
): DescriptionStateProps {
  const isDisplayed = selectors.isStrategyFeatureMandatoryOrDefined(
    appState,
    {
      strategyId,
      type: 'DESCRIPTION',
    },
    selectors,
  );
  const feature = selectors.getFeature(appState.featureState, {
    strategyId,
    type: 'DESCRIPTION',
  });
  const isReadonly = selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors);

  return { isDisplayed, description: feature?.description, isReadonly };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId }: DescriptionOwnProps,
): DescriptionDispatchProps {
  const featureKey: DescriptionFeatureKey = { strategyId, type: 'DESCRIPTION' };

  return {
    onDescriptionChanged: (description: string) =>
      dispatch(neosThunks.createChangeFeatureFieldThunk(featureKey, { description })),
  };
}

export const Description = connect(mapStateToProps, mapDispatchToProps)(DescriptionStyled);
