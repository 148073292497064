import type { Selectors } from '@/bootstrap/selectors';
import type { BookingStepStatus } from '@/neos/business/neosOnyxModel';
import type { DeltaStockListedAllocation } from '@/neos/business/rfq/strategy/leg/deltaStockListedAllocation/deltaStockListedAllocationModel';
import { getBookingStatusAsIs } from '../listedExecutions/legExecution/getBookingStatus';
import type { AppState } from '@/bootstrap/store.ts';

export type DeltaStockAllocationModel = {
  allocationBookingId: string | undefined;
  extractedStatus: BookingStepStatus | undefined;
  defaultAllocationBookingId: string | undefined;
};

export function getDeltaStockAllocationModel(
  state: AppState,
  {
    rfqId,
    strategyId,
    legId,
    allocation,
  }: { allocation: DeltaStockListedAllocation; strategyId: string; rfqId: string; legId: string },
  selectors: Selectors,
): DeltaStockAllocationModel {
  const eliotStockSplitBookingInfo = selectors.getAllocationEliotStockSplitBookingStep(
    state,
    rfqId,
    allocation.uuid,
  );

  const legAllocations = selectors.getDeltaStockListedAllocationsByLegId(state, legId);

  const allocationBookingStepInfo =
    eliotStockSplitBookingInfo ??
    (legAllocations.length === 1
      ? selectors.getDeltaAllocBookingStepByStrategyId(state, rfqId, strategyId)
      : undefined);

  if (allocation?.externalReferenceId?.id) {
    return {
      defaultAllocationBookingId: allocationBookingStepInfo?.bookingId,
      allocationBookingId: allocation?.externalReferenceId?.id,
      extractedStatus: undefined,
    };
  }

  if (!allocationBookingStepInfo) {
    return {
      defaultAllocationBookingId: undefined,
      allocationBookingId: undefined,
      extractedStatus: undefined,
    };
  }

  return {
    defaultAllocationBookingId: allocationBookingStepInfo.bookingId,
    allocationBookingId: allocationBookingStepInfo.bookingId,
    extractedStatus: getBookingStatusAsIs(allocationBookingStepInfo).status,
  };
}
