import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { retryWithDelay } from '@/util/rxFunctionsAndOperators/retryWithDelay';
import { ofType } from 'redux-observable';
import type { Observable, SchedulerLike } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { createNotificationUnsubscriptionApi } from '../apis/notificationUnsubscriptionApi';
import type { NotificationUnsubscriptionRequest } from '../neosModel';
import type { ThunkEpic } from '@/bootstrap/epics.ts';

export interface NotificationUnsubscriptionApi {
  unsubscribeFromNotification: (
    notificationUnsubscriptionRequest: NotificationUnsubscriptionRequest[],
  ) => Observable<void>;
}

export function getNotificationUnsubscriptionEpic(http: SgmeHttp): ThunkEpic {
  return notificationUnsubscriptionEpic(createNotificationUnsubscriptionApi(http), 5000);
}

export function notificationUnsubscriptionEpic(
  api: NotificationUnsubscriptionApi,
  retrialDelay: number,
  scheduler?: SchedulerLike,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('REQUEST_UNSUBSCRIPTION_ACTION'),
      mergeMap(({ notificationUnsubscriptionRequests }) =>
        api
          .unsubscribeFromNotification(
            notificationUnsubscriptionRequests.filter(isDataGroupNamesNotEmpty),
          )
          .pipe(
            mergeMap(_ => []),
            retryWithDelay(5, retrialDelay, scheduler),
            catchError(() => []),
          ),
      ),
    );
}

function isDataGroupNamesNotEmpty(
  notificationSubscriptionRequest: NotificationUnsubscriptionRequest,
): boolean {
  return notificationSubscriptionRequest.dataGroupNames.length > 0;
}
