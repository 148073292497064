import type { TabId } from '../NeosTabs';
import type { TabTitleStateProps } from './TabTitle';

import type { Selectors } from '@/bootstrap/selectors';
import { getBlotterTabTitleModel } from './getBlotterTabTitleModel';
import { getRfqTabTitleModel } from './getRfqTabTitleModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getTabTitleModel(
  appState: AppState,
  tabId: TabId,
  selectors: Selectors,
): TabTitleStateProps {
  if (tabId === 'BLOTTER') {
    return getBlotterTabTitleModel(appState, selectors);
  }
  return getRfqTabTitleModel(appState, tabId.rfqId, selectors);
}
