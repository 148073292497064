import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { Version } from '@/neos/business/rfq/versions/versionsModel';
import { neosThunks } from '@/neos/business/thunks';
import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import { dateStringToIsoString, formatDateInTimeZone } from '@/util/date/dateFormatHelper';
import { orderBy } from 'lodash';
import { connect } from 'react-redux';
import { getDisplayStatus } from '../../../share/status';
import {
  VersionningComponent,
  type VersionningDispatchProps,
  type VersionningMapStateToProps,
  type VersionningOwnProps,
} from './Versionning';
import type { AppState } from '@/bootstrap/store.ts';

const { getRfqData, getVersions, isReadOnlyRfq } = selectors;
const { getRfqOutdatedModalState, getDisplayTimeZone } = selectors;

export const Versionning = connect(mapStateToProps, mapDispatchToProps)(VersionningComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: VersionningOwnProps,
): VersionningMapStateToProps {
  const currentVersion = getRfqData(state, rfqId).version;
  const displayTimeZone = getDisplayTimeZone(state.ui.userPreferences);
  const versions = getVersions(state, rfqId);
  const orderedVersions = orderVersionsByTradeDateDesc(versions);
  const availableVersions = buildAvailableVersions(orderedVersions, displayTimeZone);
  const rfqOutdatedModalState = getRfqOutdatedModalState(state.ui, rfqId);
  return {
    currentVersion,
    availableVersions,
    rfqOutdatedModalState,
    isReadonlyRfq: isReadOnlyRfq(state, rfqId),
  };
}

function buildAvailableVersions(orderedVersions: Version[], displayTimeZone: DisplayTimeZone) {
  return orderedVersions.map((version: Version) => ({
    id: version.version,
    value: `${version.version} | ${
      version.lifecycle.updateTime
        ? formatDateInTimeZone(displayTimeZone, dateStringToIsoString(version.lifecycle.updateTime))
        : 'N/A'
    } | ${getDisplayStatus(version.lifecycle.status, version.lifecycle.subStatus, selectors)} ${
      version.lifecycle.updater ? ' | ' + version.lifecycle.updater : ''
    }`,
  }));
}

function orderVersionsByTradeDateDesc(versions: Version[]) {
  return orderBy(versions, (version: Version) => version.version, ['desc']);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: VersionningOwnProps,
): VersionningDispatchProps {
  return {
    onVersionSelected: (versionNumber: number) =>
      dispatch(
        neosActionCreators.createLoadTransactionAfterRfqOutdatedAction(rfqId, versionNumber),
      ),
    onSavedWithVersion: (version: number) => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', `Rfq save with version requested`),
        neosThunks.createRequestTransactionSaveWithVersionThunk(rfqId, version),
      );
    },
    onOutdatedDismiss: () =>
      dispatch(
        neosActionCreators.rfqUiCrudActions.update(rfqId, { rfqOutdatedModal: { action: 'HIDE' } }),
      ),
  };
}
