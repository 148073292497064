import type { ActionCreators } from '@/bootstrap/actions';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import type { Contact } from './contactModel';
import type { ThunkEpic } from '@/bootstrap/epics.ts';

export interface ContactApi {
  getContacts: (salesValoEmail: string, counterpartId: number) => Observable<Contact[]>;
}

export function createRetrieveContactsEpic(
  api: ContactApi,
  { common: { createAppCrashedAction }, neos: { actorsSetupCrudActions } }: ActionCreators,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('CONTACT_LIST_REQUESTED'),
      mergeMap(({ rfqId, salesValoEmail, counterpartId }) => {
        return api.getContacts(salesValoEmail, counterpartId).pipe(
          map((contacts: Contact[]) => actorsSetupCrudActions.update(rfqId, { contacts })),
          catchError(error =>
            error && error.code === 400
              ? []
              : [createAppCrashedAction('contact-list-requested', error)],
          ),
        );
      }),
    );
}
