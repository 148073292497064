import type { Thunk } from '@/bootstrap/thunks';

import type { Action } from '@/bootstrap/actions.ts';

export function createChangeMasterLegThunk(strategyId: string, newMasterLegId: string): Thunk {
  return function changeMasterLegThunk(
    dispatch,
    getState,
    {
      actionCreators: {
        neos: { legDataCrudActions },
      },
      selectors: { getStrategyData, getLegData },
    },
  ) {
    const appState = getState();
    const strategyLegsIds = getStrategyData(appState, strategyId).legIds;
    const currentMasterLegId = strategyLegsIds.find(legId => getLegData(appState, legId).isMaster);
    const updateCurrentMasterLegActions: Action[] = currentMasterLegId
      ? [legDataCrudActions.update(currentMasterLegId, { isMaster: false })]
      : [];

    const updateNewMasterLegAction: Action = legDataCrudActions.update(newMasterLegId, {
      isMaster: true,
    });

    dispatch(...updateCurrentMasterLegActions, updateNewMasterLegAction);
  };
}
