import { selectors } from '@/bootstrap/selectors';
import { connect } from 'react-redux';
import {
  DeltaAdjustedBookingComponent,
  type DeltaAdjustedBookingMapStateToProps,
  type DeltaAdjustedBookingOwnProps,
} from './DeltaAdjustedBooking';
import { getDeltaAdjustedBookingModel } from './getDeltaAdjustedBookingModel';
import type { AppState } from '@/bootstrap/store.ts';

export const DeltaAdjustedBooking = connect(mapStateToProps)(DeltaAdjustedBookingComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: DeltaAdjustedBookingOwnProps,
): DeltaAdjustedBookingMapStateToProps {
  return getDeltaAdjustedBookingModel(state, rfqId, selectors);
}
