import type { Destination } from '../../../../neos/business/neosModel';
import { preconfirmationEmailPreviewDomain } from './preconfirmationEmailPreviewDomain';
import type { PreconfirmationEmailPreview } from './preconfirmationEmailPreviewModel';
import type { AppState } from '@/bootstrap/store.ts';

export const preconfirmationEmailPreviewSelectors = {
  getPreconfirmationEmailPreview,
};

function getPreconfirmationEmailPreview(
  state: AppState,
  rfqId: string,
  destination: Destination,
): PreconfirmationEmailPreview | undefined {
  return preconfirmationEmailPreviewDomain.selectors.find(state.preconfirmationEmail, {
    rfqId,
    destination,
  });
}
