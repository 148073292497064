import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  CommonListedPreAllocationsComponent,
  type CommonListedPreAllocationsOwnProps,
} from '../CommonListedPreAllocations';
import { getGenericListedPreAllocationsModel } from '../getModel/getGenericListedPreAllocationsModel';
import { genericMapDispatchToProps } from './genericMapDispatchToProps';

export const RfqListedPreAllocations: FC<CommonListedPreAllocationsOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const stateProps = useAppSelector(state => ({
    model: getGenericListedPreAllocationsModel(
      state,
      ownProps.rfqId,
      'listedPreAllocs',
      selectors,
      services,
    ),
  }));
  const dispatchProps = genericMapDispatchToProps(dispatch, ownProps, 'RFQ');

  return (
    <CommonListedPreAllocationsComponent
      scope="RFQ"
      {...ownProps}
      {...stateProps}
      {...dispatchProps}
    />
  );
};
