import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { rfqUiActionCreators } from '@/neos/business/ui/rfq/rfqUiActionCreators';
import { connect } from 'react-redux';
import {
  RefDeltaTabsComponentStyled,
  type RefDeltaTabsDispatchProps,
  type RefDeltaTabsMapStateToProps,
  type RefDeltaTabsOwnProps,
} from './RefDeltaTabs';
import { getRefDeltaTabsModel } from './getRefDeltaTabsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const RefDeltaTabs = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RefDeltaTabsComponentStyled);

function mapStateToProps(
  state: AppState,
  { rfqId }: RefDeltaTabsOwnProps,
): RefDeltaTabsMapStateToProps {
  return getRefDeltaTabsModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: RefDeltaTabsOwnProps,
): RefDeltaTabsDispatchProps {
  return {
    onDeltaAdjustedSelectionChanged: (isDeltaAdjustedSelected: boolean) => {
      dispatch(
        rfqUiActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { isDeltaAdjustedSelected }),
      );
    },
  };
}
