import type { Thunk } from '@/bootstrap/thunks';

import { sgwtConnect } from '@/util/api/connect/connect';
import { formatUtcNow } from '@/util/date/dateFormatHelper';
import type { AppState } from '@/bootstrap/store.ts';

export function createHelpRequestSentThunk(): Thunk {
  return (
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        common: { createSaveStateRequestedAction },
      },
      thunks: { createErrorToasterThunk },
      services,
    },
  ) => {
    const state = getState();

    const { email, sesameId } = selectors.getCurrentUser(state);
    const userEmail = email || (sgwtConnect.getIdTokenClaims()?.sub as string);

    const env = sgmeConfiguration?.sgconnect?.helpRequest?.environment || '';
    const widget = getSgwtHelpCenterWidget();

    if (widget !== null) {
      const subject = `[SGME-NEOS : ${env}] - Help request from ${userEmail} - ${formatUtcNow()}`;
      widget.setMailSubject(subject);

      const additionalMessageInformation = selectors.getAdditionnalHelpRequestInformation(
        state,
        selectors,
      );

      const attachmentUuid = services.idCreator.createId();
      const attachmentName = `${env}_${sesameId}_${attachmentUuid}`;
      const storeUrl =
        sgmeConfiguration.s3 === undefined
          ? ''
          : `${sgmeConfiguration.s3.serviceUrl}/${sgmeConfiguration.s3.bucketName}/${attachmentName}`;

      const selectedTabId = selectors.getSelectedTab(state.ui.tabManagement);
      const appError = selectors.getHelpRequestError(state);

      const helpRequestAdditionalInformation = {
        selectedTabId,
        storeUrl,
        ...(appError && { appError }),
      };

      widget.setAdditionalMessageInformation({
        ...additionalMessageInformation,
        ...helpRequestAdditionalInformation,
      });

      const listener = () => {
        const { stateToSend, isAttachmentEncoded } = getStateToSend(state);

        dispatch(
          createSaveStateRequestedAction({
            data: stateToSend,
            isAttachmentEncoded,
            name: attachmentName,
          }),
        );

        widget.removeEventListener('sgwt-help-center--send', listener);
      };

      widget.addEventListener('sgwt-help-center--send', listener);

      // open the contact us panel from the help-center
      widget.form({
        topic: 'Report a technical issue',
        caseType: 'incident',
      });
    } else {
      dispatch(
        createErrorToasterThunk(
          {
            message: 'An error has occured while trying to open the help center',
          },
          undefined,
          false,
        ),
      );
    }
  };
}

type SgwtHelpCenter = Element & {
  form: (args: { topic: string; caseType: string }) => void;
  setAdditionalMessageInformation: (additionalMessageInformation: Record<string, unknown>) => void;
  setMailSubject: (subject: string) => void;
};

function getSgwtHelpCenterWidget(): SgwtHelpCenter | null {
  const widget = document.querySelector('sgwt-help-center') as SgwtHelpCenter;
  if (
    widget &&
    'form' in widget &&
    typeof widget.form === 'function' &&
    'setAdditionalMessageInformation' in widget &&
    typeof widget.setAdditionalMessageInformation === 'function' &&
    'setMailSubject' in widget &&
    typeof widget.setMailSubject === 'function'
  ) {
    return widget;
  }
  return null;
}

function getStateToSend(state: AppState): { stateToSend: string; isAttachmentEncoded: boolean } {
  const { blotter, ...stateWithoutBlotter } = state;
  let stateToSend: string;
  let isAttachmentEncoded = false;
  // in case we have non utf8 char in the state, because btoa support only utf8
  try {
    stateToSend = btoa(JSON.stringify(stateWithoutBlotter));
  } catch {
    const encodedStore = btoa(encodeURI(JSON.stringify(stateWithoutBlotter)));
    stateToSend = btoa(JSON.stringify({ state: encodedStore }));
    isAttachmentEncoded = true;
  }
  return { stateToSend, isAttachmentEncoded };
}
