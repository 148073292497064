import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { rfqUiActionCreators } from '@/neos/business/ui/rfq/rfqUiActionCreators';
import type { IbChatRequested } from '../rfqActions';
import { createIbChatRequestedAction } from './ibChatRequestedAction';
import type { Action } from '@/bootstrap/actions.ts';

export function createIbChatWithClearLevelRequestedAction(rfqId: string): Action[] {
  const ibChatRequestedAction: IbChatRequested = createIbChatRequestedAction(rfqId);
  return [
    createLogAnalyticsAction('NEOS RFQ', `Rfq ib chat requested`),
    ibChatRequestedAction,
    rfqUiActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { ibChatTrustLevel: undefined }),
  ];
}
