import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '@/neos/business/neosActions';
import { DefaultingScope } from '../models';
import {
  getLegRecomputeScope,
  LegRecomputeChangedScope,
  StrategyRecomputeScope,
} from '../models/recomputeModel';
import { DefaultingOptions } from '../rfqActions';
import type { SizeUpdateParameters } from '../strategy/thunks/updateStrategyOrLegSize';

export function createRfqChangeStrategyOrLegSizeThunk(
  rfqId: string,
  parameters: SizeUpdateParameters,
): Thunk {
  return function rfqChangeStrategyOrLegSizeThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: { createRecomputeRfqThunk, createUpdateStrategyOrLegSizeThunk },
      },
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    const { strategyId } = parameters;
    const appState = getState();
    const { isMasterStrategy, scope } = selectors.getStrategyData(appState, strategyId);
    const isDeltaStrategy = scope !== 'RFQ';
    const recomputeScope = getRecomputeScope();

    const dispatchable = createUpdateStrategyOrLegSizeThunk(parameters);
    const chainOptions: ChainOptions | undefined = {
      success: {
        dispatchables: [
          createDefaultRfqRequestedAction(
            rfqId,
            new DefaultingOptions({
              overrideScopes: [DefaultingScope.BREAK_FEE],
            }),
          ),
        ],
      },
    };
    return dispatch(createRecomputeRfqThunk(rfqId, recomputeScope, dispatchable, chainOptions));

    function getRecomputeScope() {
      if (parameters.isStrategyUpdate) {
        return isMasterStrategy
          ? StrategyRecomputeScope.CHANGE_COMPO_NEGOCIATED_SIZE_STRAT_MASTER
          : StrategyRecomputeScope.CHANGE_COMPO_NEGOCIATED_SIZE_STRAT_NONMASTER;
      }

      const isMasterLeg = selectors.getLegData(appState, parameters.legId).isMaster;

      return getLegRecomputeScope({
        scope: LegRecomputeChangedScope.SIZE,
        isMasterStrategy,
        isMasterLeg,
        isDeltaStrategy,
      });
    }
  };
}
