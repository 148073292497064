import type { Selectors } from '@/bootstrap/selectors';
import {
  isClsProduct,
  isElsProduct,
  type Tenor,
} from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import type { MaturityCellsModelGetters } from './modelGetters';
import type { AppState } from '@/bootstrap/store.ts';

export interface MaturityCellsModel {
  maturities: Array<Field<string | undefined> & { isValid: boolean }>;
  maturityWarningText?: string;
  isRfqSecondaryWidgetToggleOn: boolean;
  availableMaturities: string[][];
  tenors: Tenor[];
  isElsOrCls: boolean;
}

export function getMaturityCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
  { getMaturitiesFieldsArray }: MaturityCellsModelGetters,
): MaturityCellsModel {
  const maturities = getMaturitiesFieldsArray(appState, rfqId, strategyId, selectors);
  const maturityWarningText = 'Maturity must be today or later';
  const isRfqSecondaryWidgetToggleOn = selectors.isRfqSecondaryWidgetToggleOn(appState.ui, rfqId);

  const { legsData } = selectors.getStrategyLegsOrIndexLegData(appState, strategyId, selectors);

  const availableMaturities = legsData.map(legData =>
    selectors.getLegMaturities(appState, legData.uuid, selectors),
  );

  const product = selectors.getStrategyProduct(appState, strategyId, selectors);
  const tenors = selectors.getTenors(appState.referenceData);
  const isElsOrCls = isElsProduct(product) || isClsProduct(product);

  return isRfqSecondaryWidgetToggleOn
    ? {
        maturities,
        maturityWarningText,
        availableMaturities,
        isRfqSecondaryWidgetToggleOn,
        isElsOrCls,
        tenors,
      }
    : {
        maturities,
        availableMaturities,
        isRfqSecondaryWidgetToggleOn,
        isElsOrCls,
        tenors,
      };
}
