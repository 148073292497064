import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch } from 'react-redux';
import { PreconfIgnoreChangesModal } from './PreconfIgnoreChangesModal';
import { getPreconfirmationButtonModel } from './getPreconfirmationButtonModel';
import { PreconfirmationModal } from './preconfirmationModal/PreconfirmationModal.container';

export interface PreconfirmationButtonProps {
  rfqId: string;
}

export const PreconfirmationButton = ({ rfqId }: PreconfirmationButtonProps) => {
  const model = useAppSelector(state => getPreconfirmationButtonModel(state, rfqId, selectors));
  const dispatch = useDispatch<ExtendedDispatch>();

  if (!model.isDisplayed) {
    return null;
  }

  return (
    <>
      <button
        data-e2e="neos-give-up-step"
        className="btn btn-primary text-nowrap"
        disabled={model.isDisabled}
        onClick={() => {
          dispatch(neosThunks.createRequestTransfereesContactsThunk(rfqId));
          dispatch(neosThunks.createPreconfModalDisplayChangeThunk(rfqId, true));
        }}
      >
        <span>Pre Conf</span>
        {model.isBulletDisplayed && (
          <i
            className={`icon icon-sm ms-2 text-${model.bulletColor}`}
            data-e2e="preconfirmation-is-email-sent-icon"
          >
            circle
          </i>
        )}
      </button>
      <PreconfirmationModal
        rfqId={rfqId}
        isDisplayed={model.isPreconfirmationButtonModalOpened && !model.isDisabled}
        onHide={() => dispatch(neosThunks.createPreconfModalDisplayChangeThunk(rfqId, false))}
      />
      <PreconfIgnoreChangesModal rfqId={rfqId} />
    </>
  );
};
