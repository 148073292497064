import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { FC } from 'react';
import { MdpQuoteTimerTimeComponent, type MdpQuoteTimerTimeOwnProps } from './MdpQuoteTimerTime';
import { getMdpQuoteTimerTimeModel } from './getMdpQuoteTimerTimeModel';

export const MdpQuoteTimerTime: FC<MdpQuoteTimerTimeOwnProps> = ownProps => {
  const model = useAppSelector(state => {
    return getMdpQuoteTimerTimeModel(state, ownProps.rfqId, selectors);
  });

  if (model.isDisplay) {
    const { isDisplay: _, ...rest } = model;
    return <MdpQuoteTimerTimeComponent {...ownProps} {...rest} />;
  }
  return null;
};
