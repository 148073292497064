import { neosThunks } from '@/neos/business/thunks';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { ImportButton } from '@/neos/components/share/importExportClipboard/ImportButton.tsx';

type ScheduleImportProps = {
  strategyId: string;
};

export const CLSScheduleImport = ({ strategyId }: ScheduleImportProps) => {
  const dispatch = useAppDispatch();

  function onImport(importedData: unknown[]) {
    dispatch(neosThunks.createClsImportScheduleDataThunk(strategyId, importedData));
  }

  return (
    <ImportButton
      handleImport={onImport}
      dataE2e="cls-schedule-import"
      dataTestId="cls-schedule-import"
    />
  );
};
