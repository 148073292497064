import {
  type ConfirmationMode,
  ConfirmationModeValues,
  type OtcAllocation,
} from '@/neos/business/rfq/strategy/leg/otcAllocation/otcAllocationModel.ts';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete.tsx';
import { mapRecordEntriesToSelectOptions } from '@/util/array/arrayUtils.ts';
import { neosActionCreators } from '@/neos/business/neosActionCreators.ts';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

interface AllocConfirmationModeProps {
  allocation: OtcAllocation;
  rfqId: string;
  legId: string;
}

export function OtcAllocConfirmationMode({ allocation, rfqId, legId }: AllocConfirmationModeProps) {
  const dispatch = useAppDispatch();
  const confirmationMode = allocation.confirmationMode;

  if (confirmationMode === undefined) {
    onConfirmationModeChanged(ConfirmationModeValues.ST as ConfirmationMode);
  }

  function onConfirmationModeChanged(newValue: ConfirmationMode | undefined) {
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId: allocation.uuid, rfqId, legId },
        { confirmationMode: newValue },
      ),
    );
  }

  return (
    <div className="d-flex">
      <NeosSelectWithAutocomplete
        value={confirmationMode}
        onChange={selected => onConfirmationModeChanged(selected?.value as ConfirmationMode)}
        data-e2e="otc-alloc-confirmation-mode"
        options={mapRecordEntriesToSelectOptions(ConfirmationModeValues)}
      />
    </div>
  );
}
