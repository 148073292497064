import type {
  DividendPeriod,
  LegPeriod,
  LegPeriodDates,
  PeriodDates,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';
import { exportDataToExcel } from '@/util/excel/excel.ts';

export function createElsExportScheduleThunk(
  strategyId: string,
  equityData: LegPeriod<PeriodDates>[] | undefined,
  rateData: LegPeriod<RateLegPeriodDates>[] | undefined,
  dividendData: DividendPeriod[] | undefined,
): Thunk {
  return function elsExportScheduleThunk(_, getState, { selectors }) {
    const state = getState();

    const hasRateOverNightFeature = selectors.getFeature(state.featureState, {
      strategyId,
      type: 'RATE_OVERNIGHT',
    })?.rateOvernight;

    const maxLength = [rateData, dividendData].reduce<number>(
      (previousLength, currentArray) =>
        currentArray && currentArray.length > previousLength ? currentArray.length : previousLength,
      equityData?.length ?? 0,
    );

    const exportedData = [];
    for (let index = 0; index < maxLength; index++) {
      const equityColumns = {
        'Equity Start Date': getCellValue(index, equityData, 'startDate'),
        'Equity End Date': getCellValue(index, equityData, 'endDate'),
        'Equity Pay Date': getCellValue(index, equityData, 'paymentDate'),
      };
      const rateOverNightColumns = {
        'Rate First Fixing Date': getCellValue(index, rateData, 'firstFixingDate'),
        'Rate Last Fixing Date': getCellValue(index, rateData, 'fixingDate'),
        'Rate Start Date': getCellValue(index, rateData, 'startDate'),
        'Rate End Date': getCellValue(index, rateData, 'endDate'),
        'Rate Pay Date': getCellValue(index, rateData, 'paymentDate'),
      };
      const rateColumns = {
        'Rate Fixing Date': getCellValue(index, rateData, 'fixingDate'),
        'Rate Start Date': getCellValue(index, rateData, 'startDate'),
        'Rate End Date': getCellValue(index, rateData, 'endDate'),
        'Rate Pay Date': getCellValue(index, rateData, 'paymentDate'),
      };
      const dividendColumns = {
        'Dividend Start Date': getCellValue(index, dividendData, 'startDate'),
        'Dividend End Date': getCellValue(index, dividendData, 'endDate'),
        'Dividend Pay Date': getCellValue(index, dividendData, 'paymentDate'),
        'Dividend Theoretical Payment Date': getCellValue(
          index,
          dividendData,
          'theoreticalPeriodPaymentDate',
        ),
      };
      exportedData.push({
        ...equityColumns,
        ...(hasRateOverNightFeature && rateOverNightColumns),
        ...(!hasRateOverNightFeature && rateColumns),
        ...dividendColumns,
      });
    }

    function getCellValue<T extends LegPeriodDates>(
      index: number,
      data: LegPeriod<T>[] | undefined,
      key: AllUnionKeys<T>,
    ) {
      return data && data.length > index ? data[index].dates[key] : '';
    }

    exportDataToExcel(exportedData, 'Els Schedule');
  };
}
