import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { connect } from 'react-redux';
import { neosActionCreators } from '../../business/neosActionCreators';
import {
  AlertMenuComponent,
  type AlertMenuDispatchProps,
  type AlertMenuStateProps,
} from './AlertMenu';
import type { AppState } from '@/bootstrap/store.ts';

export const AlertMenu = connect(mapStateToprops, mapDispatchToProps)(AlertMenuComponent);

function mapStateToprops(state: AppState): AlertMenuStateProps {
  const { getAlertRecordLength } = selectors;

  const isAlertBlotterEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.alert.blotter.enabled',
  );

  return {
    pendingAlertNumber: getAlertRecordLength(state),
    isAlertBlotterEnabled,
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): AlertMenuDispatchProps {
  return {
    showAlertModal: () => {
      dispatch(neosActionCreators.createAlertMenuDisplayedActionAndLog());
    },
  };
}
