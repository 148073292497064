interface ExportButtonProps {
  handleExport: () => void;
  isDisabled?: boolean;
  dataE2e?: string;
  dataTestId?: string;
}

export function ExportButton({
  handleExport,
  dataE2e,
  isDisabled = false,
  dataTestId,
}: ExportButtonProps) {
  return (
    <button
      data-e2e={dataE2e}
      className="btn btn-discreet-primary"
      onClick={() => handleExport()}
      disabled={isDisabled}
      data-testid={dataTestId}
    >
      Export <i className="icon icon-sm ms-2">file_download</i>
    </button>
  );
}
