import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import {
  isElsProduct,
  isSecondaryEvent,
  type OtcAllocation,
  otcAllocationsIaCurrencies,
  type Product,
  shouldShowForcedReason,
} from '@/neos/business/neosModel';
import type { BookingStepStatus, PriceWithUnit, SizeType } from '@/neos/business/neosOnyxModel';
import { isStatusAfterTradedIncluded } from '@/neos/business/referenceData/status/statusSelector';
import type { CancelledAllocationsModel } from '@/neos/components/rfq/postNego/allocs/getCancelledAllocationsModel';
import type { MeteorBookingStepsModel } from '@/neos/components/rfq/postNego/allocs/otcAllocations/getOtcAllocationMeteorBookingSteps';
import type { OtcAllocationsModel } from '@/neos/components/rfq/postNego/allocs/otcAllocations/getOtcAllocationsModel';
import { If } from '@/neos/components/share/if/if';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { formatGridTemplateColumns } from '@/util/format/formatGridTemplateColumns';
import { LegDescription } from '../genericLegDescription/LegDescription';
import type { SalesMarginAmountInfo } from './OtcAllocation';
import { OtcAllocationInfo } from './OtcAllocation';
import styles from './OtcAllocations.module.scss';
import { SecondaryAllocationsHeaders } from './secondaryAllocations/SecondaryAllocationsHeaders';
import { getSecondaryCssGridValues } from './secondaryAllocations/secondaryFieldsGrid';

export interface OtcAllocationsOwnProps {
  rfqId: string;
  strategyId: string;
  legId: string;
}

export type AllocBookingInfo = {
  status: BookingStepStatus | undefined;
  bookingId: string | undefined;
  defaultBookingId?: string;
  message: string | undefined;
  isManuallyUpload: boolean;
  allocationId: string;
  lastUpdateTime: string | undefined;
  brokenLink?: boolean;
};
export type AllocBookingInfos = AllocBookingInfo[];

export type OtcAllocationsStateProps = OmitSafe<OtcAllocationsModel, 'allocations'> &
  CancelledAllocationsModel<OtcAllocation> & {
    meteorBookingSteps: MeteorBookingStepsModel;
    areFileIconsDisplayed: boolean;
  };

export interface OtcAllocationsDispatchProps {
  onAllocationAdded: (firstAllocCcy: string | undefined, ccy?: string, sizeType?: SizeType) => void;
}

export type OtcAllocationsProps = OtcAllocationsOwnProps &
  OtcAllocationsStateProps &
  OtcAllocationsDispatchProps;

export const OtcAllocationsComponent = ({
  rfqId,
  legId,
  strategyId,
  activeAllocations,
  cancelledAllocations,
  bookingInfos,
  meteorBookingSteps,
  ccy,
  firstAllocCcy,
  sizeType,
  uiSizeType,
  errors,
  salesMarginAmountInfos,
  isSecondaryStrategy,
  isSecondaryNovation,
  areFileIconsDisplayed,
  onAllocationAdded,
}: OtcAllocationsProps) => {
  const isUserDraggingFile = useAppSelector(selectors.selectIsUserDraggingFile);

  const { manualSalesCredit, internal } = useAppSelector(state =>
    selectors.getRfqData(state, rfqId),
  );
  const { eventType } = useAppSelector(state => selectors.getLegData(state, legId));
  const product = useAppSelector(state => selectors.getProduct(state, legId));

  const isAfterTradedStatus = useAppSelector(state =>
    isStatusAfterTradedIncluded(state, rfqId, selectors),
  );
  const isInitiatedByTrader = useAppSelector(state =>
    selectors.isRfqInitiatedByTrader(state, rfqId),
  );
  const isAllocSecondaryWidgetColumnDisplayed = useAppSelector(state =>
    selectors.isOtcAllocSecondaryWidgetColumnDisplayed(state, rfqId, selectors),
  );
  const isConfirmationModeDisplayed = isElsProduct(product) && !internal;

  const shouldShowForcedReasonColumn = activeAllocations.some(shouldShowForcedReason);
  const shouldShowIAToBeBookedColumn = activeAllocations.some(
    alloc => alloc.independantAmountType !== 'CCY',
  );
  const shouldDisableAddButton = isInitiatedByTrader && activeAllocations.length === 1;

  const haveSomeBrokenLinkAllocations =
    bookingInfos.some(bookingInfo => bookingInfo.brokenLink) && bookingInfos.length > 0;

  const isSalesMarginDisplayed = getIsSalesMarginDisplayed(
    salesMarginAmountInfos,
    manualSalesCredit,
    isInitiatedByTrader,
  );
  const hasFailedSalesMarginBookings = Object.values(salesMarginAmountInfos).some(
    s => s.status === 'FAILED',
  );

  const allocBookingIdTooltipMessage = Object.values(bookingInfos)[0]?.message;

  const iaCurrencies = Array.from(otcAllocationsIaCurrencies);
  if (ccy && !iaCurrencies.includes(ccy)) {
    iaCurrencies.splice(1, 0, ccy);
  }

  const isAllocBookingIdDisplayed = getIsAllocBookingIdDisplayed(
    internal,
    bookingInfos,
    isAfterTradedStatus,
    isInitiatedByTrader,
    product,
  );

  const showSecondaryFields = !!eventType && isSecondaryEvent(eventType);

  const hasMarkitAlloc = activeAllocations.some(alloc => alloc.media === 'MARKITWIRE');

  const areSomeMeteorTradeBookingSteps = Object.values(meteorBookingSteps).some(
    bookingStep => bookingStep?.meteorTradeId !== undefined,
  );
  const areSomeMeteorExecBookingSteps = Object.values(meteorBookingSteps).some(
    bookingStep => bookingStep?.meteorExGenId !== undefined,
  );
  const gridTemplateColumns = formatGridTemplateColumns([
    '32px 250px 46px 110px 100px 170px',
    [shouldShowIAToBeBookedColumn, '130px'],
    '110px 120px',
    [isConfirmationModeDisplayed, '120px'],
    '50px',
    [hasMarkitAlloc, '110px 200px 130px'],
    [shouldShowForcedReasonColumn, 'minmax(100px, 255px)'],
    [isAllocBookingIdDisplayed, `${isSecondaryStrategy ? '210px' : '150px'}`],
    [areSomeMeteorTradeBookingSteps, '150px'],
    [areSomeMeteorExecBookingSteps, '150px'],
    [haveSomeBrokenLinkAllocations, '32px'],
    [areFileIconsDisplayed, isUserDraggingFile ? '264px' : '64px'],
    [isAllocSecondaryWidgetColumnDisplayed, '180px'],
    [isSalesMarginDisplayed, '100px'],
    [hasFailedSalesMarginBookings, '100px'],
    [showSecondaryFields, getSecondaryCssGridValues(eventType, product)],
  ]);

  const uiSizeTypeHeader =
    uiSizeType === 'QUANTITY'
      ? 'Alloc. Qty'
      : uiSizeType === 'VAR_UNIT'
        ? 'Alloc. VU'
        : 'Alloc. Amount';

  return (
    <div className={styles['leg-description']} data-e2e="neos-postNego-legDetails">
      <LegDescription key={legId} rfqId={rfqId} strategyId={strategyId} legId={legId} />
      <div className={`${styles['common-grid']} mt-3`} style={{ gridTemplateColumns }}>
        <button
          className="btn btn-icon btn-flat-primary"
          onClick={() => onAllocationAdded(firstAllocCcy, ccy, sizeType)}
          data-e2e="add-otc-alloc"
          disabled={shouldDisableAddButton}
        >
          <i className="icon icon-md">add</i>
        </button>
        <label data-e2e="otc-allocation-counterparties-label">Counterparties</label>
        <span></span> {/*NOTE: acts as a header for PDC buttons*/}
        <label>{uiSizeTypeHeader}</label>
        <label data-e2e="otc-allocation-ia-type-label">IA Type</label>
        <label data-e2e="otc-allocation-ia-label">
          IA
          {showSecondaryFields ? ' (Post-Event)' : null}
        </label>
        {shouldShowIAToBeBookedColumn && (
          <label data-e2e="otc-allocation-ia-in-ccy-label">IA to be booked</label>
        )}
        <label data-e2e="otc-allocation-ia-value-date-label">IA Value date</label>
        <label data-e2e="otc-allocation-media-label">Media</label>
        {isConfirmationModeDisplayed && (
          <label data-e2e="otc-allocation-confirmation-mode-label">Confirm. Mode</label>
        )}
        <label data-e2e="otc-allocation-mca-label">MCA</label>
        <If condition={hasMarkitAlloc}>
          <label className="me-3" data-e2e="otc-allocation-mode-label">
            Mode
          </label>
          <label className="me-3" data-e2e="otc-allocation-electronic-user-label">
            Electronic user
          </label>
          <label className="me-3" data-e2e="otc-allocation-markitwireId-label">
            Markitwire Id
          </label>
        </If>
        <If condition={shouldShowForcedReasonColumn}>
          <label data-e2e="otc-allocation-forced-reason-label">Forced reason</label>
        </If>
        <If condition={isAllocBookingIdDisplayed}>
          <label data-e2e="otc-allocation-booking-id-label" className="text-nowrap">
            {isSecondaryStrategy ? 'Primary Alloc. Booking ID' : 'Alloc. Booking ID'}
            <If condition={!!allocBookingIdTooltipMessage}>
              <SimpleNeosTooltip
                type="warning"
                id="booking-error-tooltip"
                message={allocBookingIdTooltipMessage}
              >
                <i className="icon icon-sm ms-2 text-warning">warning</i>
              </SimpleNeosTooltip>
            </If>
          </label>
        </If>
        <If condition={areSomeMeteorTradeBookingSteps}>
          <label data-e2e="otc-allocation-meteor-trade-booking-id-label">MeteorTrade</label>
        </If>
        <If condition={areSomeMeteorExecBookingSteps}>
          <label data-e2e="otc-allocation-meteor-exec-booking-id-label">MeteorExGen</label>
        </If>
        <If condition={areFileIconsDisplayed}>
          <label data-e2e="otc-allocation-file-icons-label"></label>
        </If>
        <If condition={haveSomeBrokenLinkAllocations}>
          <label data-e2e="otc-allocation-broken-link-label"></label>
        </If>
        <If condition={isAllocSecondaryWidgetColumnDisplayed}>
          <label data-e2e="otc-allocation-secondary-widget-label">Secondary Type</label>
        </If>
        <If condition={isSalesMarginDisplayed}>
          <label data-e2e="otc-allocation-sc-label">SC</label>
        </If>
        <If condition={hasFailedSalesMarginBookings}>
          <label data-e2e="otc-allocation-manually-book-sc-label">Manually Book SC</label>
        </If>
        <SecondaryAllocationsHeaders legId={legId} rfqId={rfqId} />
        {activeAllocations.map((alloc, index) => {
          const allocationBookingInfos = bookingInfos.find(
            ({ allocationId }) => allocationId === alloc.uuid,
          );
          const meteorBookingStep = meteorBookingSteps[alloc.uuid];

          return (
            <OtcAllocationInfo
              key={alloc.uuid}
              index={index}
              rfqId={rfqId}
              legId={legId}
              strategyId={strategyId}
              alloc={alloc}
              error={errors[alloc.uuid] || {}}
              hasMarkitAlloc={hasMarkitAlloc}
              shouldShowForcedReasonColumn={shouldShowForcedReasonColumn}
              uiSizeType={uiSizeType}
              bookingInfo={allocationBookingInfos}
              hasOtcAllocationBookingStep={isAllocBookingIdDisplayed}
              isSalesMarginDisplayed={isSalesMarginDisplayed}
              areFileIconsDisplayed={areFileIconsDisplayed}
              hasFailedSalesMarginBookings={hasFailedSalesMarginBookings}
              salesMarginValue={salesMarginAmountInfos[alloc.uuid]}
              isSecondaryStrategy={isSecondaryStrategy}
              isSecondaryNovation={isSecondaryNovation}
              shouldShowIAToBeBookedColumn={shouldShowIAToBeBookedColumn}
              haveSomeBrokenLinkAllocations={haveSomeBrokenLinkAllocations}
              meteorBookingStep={meteorBookingStep}
              isConfirmationModeDisplayed={isConfirmationModeDisplayed}
            />
          );
        })}
      </div>

      {cancelledAllocations.length > 0 && (
        <div style={{ cursor: 'not-allowed' }}>
          <div
            className={`${styles['common-grid']} opacity-50`}
            style={{ gridTemplateColumns, pointerEvents: 'none' }}
          >
            {cancelledAllocations.map((cancelledAllocation, index) => {
              const allocationBookingInfos = bookingInfos.find(
                ({ allocationId }) => allocationId === cancelledAllocation.uuid,
              );
              const meteorBookingStep = meteorBookingSteps[cancelledAllocation.uuid];

              return (
                <OtcAllocationInfo
                  key={cancelledAllocation.uuid}
                  index={index}
                  rfqId={rfqId}
                  legId={legId}
                  strategyId={strategyId}
                  alloc={cancelledAllocation}
                  error={errors[cancelledAllocation.uuid] || {}}
                  hasMarkitAlloc={hasMarkitAlloc}
                  shouldShowForcedReasonColumn={shouldShowForcedReasonColumn}
                  uiSizeType={uiSizeType}
                  bookingInfo={allocationBookingInfos}
                  hasOtcAllocationBookingStep={isAllocBookingIdDisplayed}
                  isSalesMarginDisplayed={isSalesMarginDisplayed}
                  areFileIconsDisplayed={areFileIconsDisplayed}
                  hasFailedSalesMarginBookings={hasFailedSalesMarginBookings}
                  salesMarginValue={salesMarginAmountInfos[cancelledAllocation.uuid]}
                  isSecondaryStrategy={isSecondaryStrategy}
                  isSecondaryNovation={isSecondaryNovation}
                  shouldShowIAToBeBookedColumn={shouldShowIAToBeBookedColumn}
                  haveSomeBrokenLinkAllocations={haveSomeBrokenLinkAllocations}
                  meteorBookingStep={meteorBookingStep}
                  isConfirmationModeDisplayed={isConfirmationModeDisplayed}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

function getIsSalesMarginDisplayed(
  salesMarginAmountInfos: Record<string, SalesMarginAmountInfo>,
  manualSalesCredit: PriceWithUnit | undefined,
  isInitiatedByTrader: boolean,
) {
  const areAnySalesMarginAmountInfos = Object.entries(salesMarginAmountInfos).length > 0;
  return areAnySalesMarginAmountInfos && manualSalesCredit === undefined && !isInitiatedByTrader;
}

function getIsAllocBookingIdDisplayed(
  internal: boolean | undefined,
  bookingInfos: AllocBookingInfo[],
  isAfterTradedStatus: boolean,
  isInitiatedByTrader: boolean,
  product: Product,
) {
  const isElsIdb = isInitiatedByTrader && isElsProduct(product) && !internal;
  const areAnyBookingInfos = Object.entries(bookingInfos).length > 0;

  return (areAnyBookingInfos || isAfterTradedStatus) && (!isInitiatedByTrader || isElsIdb);
}
