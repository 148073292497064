import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function InitialTradeDate({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const currentAllocation = useAppSelector(state =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const dispatch = useAppDispatch();
  const initialTradeDate = currentAllocation?.stepInInfo?.initialTradeDate;

  const onInitialTradeDateChange = (value: string | undefined) => {
    dispatch(
      thunks.neos.createUpdateStepInInfoThunk(allocationId, rfqId, legId, {
        initialTradeDate: value,
      }),
    );
  };

  return (
    <FlowDatePicker
      data-e2e="otc-alloc-stepin-initial-trade-date"
      date={initialTradeDate ?? ''}
      maturities={[]}
      onChange={onInitialTradeDateChange}
    />
  );
}
