import type { Selectors } from '@/bootstrap/selectors';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import {
  type RequestKycPredealCheck,
  type RequestMifidPredealCheck,
  isRequestLevelExceptPttPredealCheck,
} from '../predealCheck/predealCheckModel';
import type { RequestRiskPredealCheck } from '../predealCheck/predealCheckModel/riskPredealCheck';
import type { AppState } from '@/bootstrap/store.ts';

export function getRequestExceptPttPredealChecksByBdrId(
  state: AppState,
  rfqId: string,
  givenBdrId: number | undefined,
  selectors: Selectors,
): Array<RequestKycPredealCheck | RequestMifidPredealCheck | RequestRiskPredealCheck> {
  const { predealCheckIds } = selectors.getRfqData(state, rfqId);
  return predealCheckIds
    .map(id => selectors.getPredealCheck(state, id))
    .filter(isDefined)
    .filter(isRequestLevelExceptPttPredealCheck)
    .filter(({ counterpartyId }) => counterpartyId === givenBdrId);
}
