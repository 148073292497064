import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { DefaultingScope, isClsProduct, isElsProduct } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import type { BusinessDayConvention } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { BusinessDayConventionValues } from './ScheduleModel';

export function ConventionDay({ strategyId, rfqId }: { rfqId: string; strategyId: string }) {
  const dispatch = useAppDispatch();
  const isReadonly = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product) && !isClsProduct(product)) {
    throw new Error('product is neither an els nor a cls');
  }
  const { conventionDay, uuid } = product;

  function onBusinessDayConventionChanged(value: BusinessDayConvention | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, uuid, true));
    dispatch(actionCreators.neos.productCrudActions.update(uuid, { conventionDay: value }));
    if (isClsProduct(product)) {
      const overrideScopes = [DefaultingScope.UNDERLYING];
      const defaultingOptions = new DefaultingOptions({ overrideScopes });
      dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, defaultingOptions));
    }
  }

  return (
    <>
      <label className="p-1">Business Day Conv.</label>

      <NeosSelect
        options={BusinessDayConventionValues}
        readOnly={isReadonly}
        addEmptyOption
        onChange={onBusinessDayConventionChanged}
        value={conventionDay}
        data-e2e="schedule-rate-reset"
      />
    </>
  );
}
