import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import type { Tab } from '@/neos/business/ui/uiModel';
import { MAX_BLOTTER_RFQS_TO_OPEN } from '@/neos/components/blotter/grid/gridStaticOptions';
import { connect } from 'react-redux';
import {
  type NavTabsDispatchProps,
  type NavTabsOwnProps,
  type NavTabsStateProps,
  NavTabsUnconnected,
} from './NavTabs';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(state: AppState, props: NavTabsOwnProps): NavTabsStateProps {
  const isDividedView = selectors.isDividedView(state.ui, selectors);
  const isLeftTabs = isDividedView && props.kind === 'MAIN';
  const allTabIds = selectors
    .getTabs(state.ui.tabManagement)
    .filter(isRfqFullyLoaded(state, selectors))
    .map(t => t.id);

  const tabIds = isDividedView
    ? props.kind === 'MAIN'
      ? ['BLOTTER' as const]
      : allTabIds.filter(tabId => tabId !== 'BLOTTER').map(rfqId => ({ rfqId }))
    : allTabIds.map(tabId => (tabId === 'BLOTTER' ? ('BLOTTER' as const) : { rfqId: tabId }));

  const selectedTabId = selectors.getSelectedTab(state.ui.tabManagement);

  return {
    tabIds,
    selectedTabId,
    canAdd: !isLeftTabs,
  };
}

function isRfqFullyLoaded(state: AppState, selectors: Selectors) {
  return (tab: Tab) => {
    const rfqData = selectors.getRfqData(state, tab.id);
    return tab.id !== 'BLOTTER'
      ? rfqData?.strategyIds !== undefined &&
          rfqData.strategyIds.length > 0 &&
          rfqData.strategyIds.every(id => {
            const stratetyData = selectors.getStrategyData(state, id);
            return stratetyData !== undefined && Object.keys(stratetyData).length > 0;
          })
      : true;
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): NavTabsDispatchProps {
  const { createAddTabThunk, createNeosCloneRfqThunk } = neosThunks;

  const onTabAdded = () => {
    dispatch(createAddTabThunk(), createLogAnalyticsAction('NEOS WORKSPACE', 'Open new tab'));
  };

  const onTabCloned = (rfqId: string) => {
    dispatch(
      createNeosCloneRfqThunk(rfqId),
      createLogAnalyticsAction('NEOS WORKSPACE', 'Clone tab'),
    );
  };
  const onTabOpened = (rfqIds: string[]) => {
    if (rfqIds.length === 0) {
      return dispatch(
        thunks.createWarningToasterThunk({ message: 'No RFQ IDs found in the clipboard' }),
      );
    }
    if (rfqIds.length > MAX_BLOTTER_RFQS_TO_OPEN) {
      return dispatch(
        thunks.createWarningToasterThunk({
          message: `Too many RFQs selected at once. Please open no more than ${MAX_BLOTTER_RFQS_TO_OPEN}.`,
        }),
      );
    }
    dispatch(
      neosActionCreators.bulkLoadTransactions(rfqIds),
      createLogAnalyticsAction('NEOS WORKSPACE', 'RFQ(s) Opened From clipboard'),
    );
  };

  return {
    onTabAdded,
    onTabCloned,
    onTabOpened,
  };
}

export const NavTabs = connect(mapStateToProps, mapDispatchToProps)(NavTabsUnconnected);
