import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export type BrokerModel = {
  broker: string | undefined;
  isBrokerFieldDisplayed: boolean;
};

export function getBrokerModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): BrokerModel {
  const { broker } = selectors.getStrategyData(state, strategyId);
  const isBrokerFieldDisplayed = selectors.isListedStrategy(state, strategyId, selectors);

  return {
    broker,
    isBrokerFieldDisplayed,
  };
}
