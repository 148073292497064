import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '@/neos/business/neosActions';
import { getStrategyRecomputeScope, StrategyRecomputeChangedScope } from '../models/recomputeModel';
import type { Action } from '@/bootstrap/actions.ts';

export function createRfqReverseStrategyWeightThunk(
  rfqId: string,
  chainOptions?: ChainOptions,
): Thunk {
  return function rfqReverseStrategyWeightThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData, getStrategyData },
      actionCreators: {
        neos: { strategyDataCrudActions, createRecomputeRfqRequestedAction },
      },
    },
  ) {
    const appState = getState();

    const { strategyIds } = getRfqData(appState, rfqId);
    const actions: Action[] = strategyIds.map(strategyId => {
      const { weight: currentWeight } = getStrategyData(appState, strategyId);
      return strategyDataCrudActions.update(strategyId, { weight: -1 * currentWeight });
    });

    dispatch(actions);
    dispatch(
      createRecomputeRfqRequestedAction(
        rfqId,
        getStrategyRecomputeScope({
          scope: StrategyRecomputeChangedScope.WEIGHT,
          hasSignChanged: true,
          isMaster: false,
        }),
        chainOptions,
      ),
    );
  };
}
