import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  CrossborderComponent,
  type CrossborderDispatchProps,
  type CrossborderOwnProps,
  type CrossborderPropsFromState,
} from './Crossborder';
import type { AppState } from '@/bootstrap/store.ts';

const { getRfqData, isReadOnlyRfq } = selectors;

export const Crossborder = connect(mapStateToProps, mapDispatchToProps)(CrossborderComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: CrossborderOwnProps,
): CrossborderPropsFromState {
  const { crossBorder } = getRfqData(state, rfqId);

  return {
    isCrossBorder: !!crossBorder,
    isReadonlyRfq: isReadOnlyRfq(state, rfqId),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: CrossborderOwnProps,
): CrossborderDispatchProps {
  return {
    onCrossBorderToggled: (crossBorder: boolean | undefined) =>
      dispatch(neosActionCreators.rfqDataCrudActions.update(rfqId, { crossBorder })),
  };
}
