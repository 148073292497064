import type { Selectors } from '@/bootstrap/selectors';
import type { EventType } from '../../../../../../../../neos/business/neosModel';
import type { BaseSecondaryTypeField, NotApplicableField } from '../secondaryTypeModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface SecondaryRfqSecondaryTypeField extends BaseSecondaryTypeField {
  primaryRfqId: string;
  isNewUnwind: boolean;
  isOldUnwind: boolean;
}

export type SecondaryTypeFieldModel = SecondaryRfqSecondaryTypeField | NotApplicableField;

interface LegIdWithSecondaryEvent {
  legId: string;
  secondaryEvent: EventType | undefined;
}

export interface SecondaryTypeCellModel {
  secondaryTypes: SecondaryTypeFieldModel[];
  secondaryEvents: LegIdWithSecondaryEvent[];
  isLoading: boolean;
  isSecondaryOrMixedRfq: boolean;
  isReadonly: boolean;
}

export function getSecondaryTypeCellModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): SecondaryTypeCellModel {
  const { legIds } = selectors.getStrategyData(appState, strategyId);

  const secondaryTypes = legIds.map((legId): SecondaryTypeFieldModel => {
    const { eventType, secondaryEvent } = selectors.getLegData(appState, legId);
    const primaryRfqId = selectors.getLegPrimaryRfqId(appState, legId) || '';

    const isNewUnwind = !!primaryRfqId && eventType !== undefined && eventType !== 'CREATE';
    const isOldUnwind =
      !primaryRfqId && (secondaryEvent === 'FULL_UNWIND' || secondaryEvent === 'PARTIAL_UNWIND');

    const isEligible =
      selectors.isOtcLeg(appState, legId, selectors) && (isNewUnwind || isOldUnwind);

    if (!isEligible) {
      return { isEligible, legId };
    }
    const secondaryType = selectors.getLegUnwindType(appState.ui, legId);

    return {
      legId,
      isEligible,
      isNewUnwind,
      isOldUnwind,
      secondaryType,
      primaryRfqId,
    };
  });

  const secondaryEvents: LegIdWithSecondaryEvent[] = legIds.map(legId => {
    const { eventType: secondaryEvent } = selectors.getLegData(appState, legId);
    return { legId, secondaryEvent };
  });

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const isSecondaryOrMixedRfq = selectors.isSecondaryOrMixedRfq(appState, rfqId);
  return {
    secondaryTypes,
    isLoading: false,
    secondaryEvents,
    isSecondaryOrMixedRfq,
    isReadonly: isReadonlyAtWorkflow,
  };
}
