import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  NotionalComponent,
  type NotionalDispatchProps,
  type NotionalMapStateToProps,
  type NotionalOwnProps,
} from './Notional';
import { getNotionalModel } from './getNotionalModel';
import type { AppState } from '@/bootstrap/store.ts';

export const Notional = connect(mapStateToProps, mapDispatchToProps)(NotionalComponent);

function mapStateToProps(state: AppState, { rfqId }: NotionalOwnProps): NotionalMapStateToProps {
  return { model: getNotionalModel(state, rfqId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: NotionalOwnProps,
): NotionalDispatchProps {
  return {
    onNotionalChanged(notionalAmount) {
      dispatch(neosActionCreators.createRfqNotionalChangedAction(rfqId, notionalAmount));
    },
    onPivotNotionalChanged(notionalAmount, selectedPivotNotionalCurrency) {
      dispatch(
        neosActionCreators.createRfqPivotNotionalChangedAction(
          rfqId,
          notionalAmount,
          selectedPivotNotionalCurrency,
        ),
      );
    },
    onNotionalCurrencyChanged(notionalCurrency) {
      dispatch(neosActionCreators.createRfqNotionalCurrencyChangedAction(rfqId, notionalCurrency));
    },
    getSelectedPivotNotionalCurrency(selectedPivotNotionalCurrency) {
      dispatch(
        neosActionCreators.rfqUiCrudActions.update(rfqId, { selectedPivotNotionalCurrency }),
        createLogAnalyticsAction('NEOS RFQ', 'Switch Pivot Notional Currency'),
      );
    },
  };
}
