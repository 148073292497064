import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { DefaultingScope, getStrategyRecomputeScope } from '@/neos/business/rfq/models';
import { StrategyRecomputeChangedScope } from '@/neos/business/rfq/models/recomputeModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  StrategyActionsButtonComponent,
  type StrategyActionsButtonDispatchProps,
  type StrategyActionsButtonMapStateProps,
  type StrategyActionsButtonOwnProps,
} from './StrategyActionsButton';
import { getStrategyActionsButtonModel } from './getStrategyActionsButtonModel';
import type { AppState } from '@/bootstrap/store.ts';

export const StrategyActionsButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StrategyActionsButtonComponent);

function mapStateToProps(
  state: AppState,
  { rfqId, strategyId }: StrategyActionsButtonOwnProps,
): StrategyActionsButtonMapStateProps {
  return { model: getStrategyActionsButtonModel(state, rfqId, strategyId, selectors) };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId, rfqId }: StrategyActionsButtonOwnProps,
): StrategyActionsButtonDispatchProps {
  const dispatchSetAsMaster = () => {
    dispatch(
      createLogAnalyticsAction('NEOS RFQ', 'Strategy master menu hit'),
      neosThunks.createRecomputeRfqThunk(
        rfqId,
        getStrategyRecomputeScope({ scope: StrategyRecomputeChangedScope.MASTER }),
        neosThunks.createRfqSetStrategyAsMasterThunk(rfqId, strategyId),
        {
          success: {
            dispatchables: [
              neosActionCreators.createDefaultRfqRequestedAction(
                rfqId,
                new DefaultingOptions({
                  overrideScopes: [DefaultingScope.TRADED_AWAY_FEEDBACK],
                }),
              ),
            ],
          },
        },
      ),
    );
  };
  return {
    onDeleteMenuSelected() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Strategy delete menu hit'),
        neosThunks.createRfqDeleteStrategyThunk(rfqId, strategyId),
      );
    },
    onDeleteShortcutSelected() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Strategy delete shortcut hit'),
        neosThunks.createRfqDeleteStrategyThunk(rfqId, strategyId),
      );
    },
    onCloneMenuSelected() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Strategy clone menu hit'),
        neosThunks.createNeosCloneStrategyThunk(rfqId, strategyId),
      );
    },
    onSetAsMasterMenuSelected() {
      dispatch(createLogAnalyticsAction('NEOS RFQ', 'Strategy master menu hit'));
      dispatchSetAsMaster();
    },
    onSetAsMasterShortcutSelected() {
      dispatch(createLogAnalyticsAction('NEOS RFQ', 'Strategy master shortcut hit'));
      dispatchSetAsMaster();
    },
    onStratToLegsMenuSelected() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Strategy to legs menu hit'),
        neosThunks.createRfqProceedToLegsToStrategiesThunk(rfqId, strategyId),
      );
    },
    onToggleShowLegPricesMenuSelected() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Strategy / legs prices menu hit'),
        neosThunks.createNeosToggleStrategyShowLegPricesThunk(strategyId),
      );
    },
    onToggleShowLegPricesShortcutSelected() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Strategy / legs prices shortcut hit'),
        neosThunks.createNeosToggleStrategyShowLegPricesThunk(strategyId),
      );
    },
    onManageExtraFeaturesMenuSelected() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Extra feature menu hit'),
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          extraFeaturesModal: 'SHOW',
        }),
      );
    },
    onManageExtraFeaturesShortcutSelected() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Extra feature shortcut hit'),
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          extraFeaturesModal: 'SHOW',
        }),
      );
    },
    onLegRemoved(legId: string) {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Leg removed'),
        neosThunks.createRfqDeleteLegThunk(legId),
      );
    },
    onLegAdded() {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Leg added'),
        neosThunks.createRfqCustomOptionsAddLegsThunk(rfqId, strategyId),
      );
    },
    onManageElsSectionsMenuSelected() {
      dispatch(
        neosActionCreators.strategyUiCrudActions.update(strategyId, {
          showElsSectionsModal: true,
        }),
      );
    },
  };
}
