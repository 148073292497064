import { thunks } from '@/bootstrap/thunks';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type ElsBasketExportProps = {
  product: ElsBasket;
};

export const ElsBasketClear = ({ product }: ElsBasketExportProps) => {
  const dispatch = useAppDispatch();
  const isButtonDisabled = product.basketUnderlying.basketComposition.length === 0;

  return (
    <button
      data-e2e="els-basket-clear"
      className={`btn btn-discreet-primary sgbs-btn-primary ${isButtonDisabled && 'disabled'}`}
      onClick={() => {
        if (!isButtonDisabled) {
          dispatch(thunks.neos.createUpdateElsBasketThunk(product.uuid, 'basketComposition', []));
        }
      }}
      disabled={isButtonDisabled}
    >
      Clear <i className="icon icon-sm ms-2">delete_forever</i>
    </button>
  );
};
