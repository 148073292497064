import type { Selectors } from '@/bootstrap/selectors';
import type { Status } from '../../../../../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface LegLinkCellModel {
  status: Status;
  disabled: boolean;
}

export function getLegLinkCellModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): LegLinkCellModel {
  const isLoading = selectors.isRfqLoading(appState.ui, rfqId);
  const { status } = selectors.getRfqData(appState, rfqId);
  const { negotiationMode } = selectors.getStrategyProduct(appState, strategyId, selectors);

  const isValidStrategy = selectors.isValidStrategyForImplicitUnwind(
    appState,
    rfqId,
    strategyId,
    negotiationMode,
  );

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const disabled = isLoading || !isValidStrategy || isReadonlyAtWorkflow;

  return {
    status,
    disabled,
  };
}
