import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { getErrorId } from '@/neos/business/mappers/error';
import type {
  ErrorableAllUi,
  ErrorableAllUiRelated,
} from '@/neos/business/mappers/error/errorHandlerData';
import { uiActionCreators } from '@/neos/business/ui/uiActionCreators';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { flatMap } from 'lodash';
import { connect } from 'react-redux';
import {
  type ErrorHighlightDispatchProps,
  type ErrorHighlightOwnProps,
  type ErrorHighlightPropsFromState,
  ErrorHighlightUnconnected,
} from './ErrorHighlight';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps<T extends ErrorableAllUi>(
  state: AppState,
  props: ErrorHighlightOwnProps<T>,
): ErrorHighlightPropsFromState {
  const errorIds = getErrorIds<T>(props);
  const neosErrors = errorIds.map(errorId => selectors.getNeosError(state.ui, errorId));

  return {
    isError: neosErrors.some(neosError => neosError.isError),
    errorMessages: flatMap(neosErrors, neosError => neosError.errorMessages),
  };
}

export function getErrorIds<T extends ErrorableAllUi>(props: ErrorHighlightOwnProps<T>) {
  const getErrorIdBasedOnField = getErrorId[props.errorField];

  const related = props.related;
  const relateds: Array<ErrorableAllUiRelated[T]> = [
    related,
    'strategyId' in related ? { ...related, strategyId: 'ALL' } : undefined,
    'legId' in related ? { ...related, legId: 'ALL' } : undefined,
    'strategyId' in related && 'legId' in related
      ? { ...related, strategyId: 'ALL', legId: 'ALL' }
      : undefined,
    'deltaId' in related ? { ...related, deltaId: 'ALL' } : undefined,
  ].filter(isDefined);

  return relateds.map(errorId => getErrorIdBasedOnField(errorId));
}

function mapDispatchToProps<T extends ErrorableAllUi>(
  dispatch: ExtendedDispatch,
  ownProps: ErrorHighlightOwnProps<T>,
): ErrorHighlightDispatchProps {
  return {
    onChange() {
      getErrorIds(ownProps).forEach(errorId =>
        dispatch(uiActionCreators.errorCrudActions.delete(errorId)),
      );
    },
  };
}

export const ErrorHighlightUntyped = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorHighlightUnconnected);
