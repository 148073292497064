import { actionCreators } from '@/bootstrap/actions';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { createCloseTabThunk } from '@/neos/business/thunks/closeTab';
import { connect } from 'react-redux';

import type { TabId } from '../NeosTabs';
import {
  type TabTitleDispatchProps,
  type TabTitleOwnProps,
  type TabTitleStateProps,
  TabTitleStyled,
} from './TabTitle';
import { getTabTitleModel } from './getTabTitleModel';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(state: AppState, { tabId }: TabTitleOwnProps): TabTitleStateProps {
  return getTabTitleModel(state, tabId, selectors);
}

function mapDispatchToProps(dispatch: ExtendedDispatch): TabTitleDispatchProps {
  const onTabSelectionChanged = (tabId: TabId) => {
    const tabIdStr = tabId === 'BLOTTER' ? tabId : tabId.rfqId;
    const logAction = createLogAnalyticsAction('NEOS WORKSPACE', 'Access blotter area', tabIdStr);
    const tabAction = actionCreators.neos.createTabSelectedAction(tabIdStr);
    dispatch(tabAction, logAction);
  };
  const onCopied = (rfqId: string) => {
    dispatch(
      thunks.createCopyToClipboardThunk(rfqId),
      createLogAnalyticsAction('NEOS WORKSPACE', 'Copy RFQ ID'),
    );
  };

  const onTabClosed = (rfqId: string) => {
    dispatch(
      createCloseTabThunk(rfqId),
      createLogAnalyticsAction('NEOS WORKSPACE', 'Close tab', rfqId),
    );
  };

  return {
    onTabSelectionChanged,
    onCopied,
    onTabClosed,
  };
}

export const TabTitle = connect(mapStateToProps, mapDispatchToProps)(TabTitleStyled);
