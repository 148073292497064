import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { FC } from 'react';
import { EndOfObservationComponent, type OwnProps } from './EndOfObservation';
import { getEndOfObservationModel } from './getEndOfObservationModel';

export const EndOfObservation: FC<OwnProps> = ({ rfqId, strategyId }) => {
  const model = useAppSelector(appState =>
    getEndOfObservationModel(appState, strategyId, selectors),
  );
  return <EndOfObservationComponent {...{ rfqId, strategyId, model }} />;
};
