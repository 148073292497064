import type { Selectors } from '@/bootstrap/selectors';
import { isStatusAfterPriceRequestedIncluded } from '@/neos/business/referenceData/status/statusSelector';
import type { AppState } from '@/bootstrap/store.ts';

export function getCommissionsModel(state: AppState, rfqId: string, selectors: Selectors): boolean {
  return (
    isStatusAfterPriceRequestedIncluded(state, rfqId, selectors) &&
    selectors.isFeatureToggleEnabled(state, 'neos.commission.enabled')
  );
}
