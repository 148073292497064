import { enhanceErrorHandler, sgwtConnect } from '@/util/api/connect/connect';
import { sgwtConnectSetup } from '@/util/api/connect/setupConnect';
import { logger } from '@/util/logging/logger';
import { setUnhandledExceptionHandler } from '@/util/logging/unhandledExceptions';
import { renderReactApp } from './main';
import { listenToAllNotifications } from './notifications';
import signalRService from '../util/notifications/signalRService';
import { thunks } from './thunks';
import { commonActionCreators } from '@/common/business/commonActionCreators';
import { actionCreators } from './actions';
import { type AppStore, createFlowStore } from '@/bootstrap/store.ts';

// const initialStateJson = JSON.parse(decodeURI(atob(storeEncoded.state)));

export function bootstrap(bootstrapOptions?: { isSignalRError: boolean }) {
  const { store } = createFlowStore();

  if (bootstrapOptions?.isSignalRError) {
    store.dispatch(actionCreators.neos.setIsSignalRConnexionErrorDisplayed());
  }

  setUnhandledExceptionHandler(logger, store);
  enhanceErrorHandler(() => {
    store.dispatch(commonActionCreators.createLogAction('Got empty Authorization header'));
    store.dispatch(commonActionCreators.createSessionExpiredAction());
    logger.scheduleStop();
  }, logger);

  initApp();

  function initApp() {
    renderReactApp(store);

    addFileDragEventListener(store);
    store.dispatch(thunks.common.createListenToAppStartEventsThunk() as any);
    if (
      (!window.location.search?.length ||
        !window.location.search?.includes('random=') ||
        window.location.origin === 'https://eqdflow.sgmarkets.com') &&
      signalRService.connected
    ) {
      listenToAllNotifications(
        store.getState.bind(store),
        store.dispatch.bind(store),
        thunks,
        signalRService.datastream$,
        signalRService.reconnect$,
      );
    }

    // Setup SGWT Listener and link them to store.
    sgwtConnectSetup.setup(sgwtConnect, store.dispatch);
  }
}

// Add a global event listener for file drag and drop event
function addFileDragEventListener(store: AppStore) {
  //to enable full window
  let lastTarget: EventTarget | null = null;

  // drag start
  window.addEventListener('dragenter', function (event) {
    // unhide our red overlay
    lastTarget = event.target; // cache the last target here
    store.dispatch(actionCreators.neos.updateUserIsDraggingFile(true));
  });

  // user canceled
  window.addEventListener('dragleave', function (event) {
    if (event.target === lastTarget || event.target === document) {
      store.dispatch(actionCreators.neos.updateUserIsDraggingFile(false));
    }
  });

  window.addEventListener('dragover', function (event) {
    //to stop default browser act
    event.preventDefault();
  });

  window.addEventListener('drop', function (event) {
    event.preventDefault();
    store.dispatch(actionCreators.neos.updateUserIsDraggingFile(false));
  });
}
