import { connect } from 'react-redux';

import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import {
  DeltaAdjustedAmendButtonComponent,
  type DeltaAdjustedAmendButtonDispatchProps,
  type DeltaAdjustedAmendButtonOwnProps,
  type DeltaAdjustedAmendButtonStateProps,
} from './DeltaAdjustedAmendButton';
import type { AppState } from '@/bootstrap/store.ts';

export const DeltaAdjustedAmendButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeltaAdjustedAmendButtonComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: DeltaAdjustedAmendButtonOwnProps,
): DeltaAdjustedAmendButtonStateProps {
  const { status } = selectors.getRfqData(state, rfqId);
  const isSavePossible = selectors.isSavePossible(state, rfqId);

  return {
    isDisabled: status !== 'DELTA_BEING_EXECUTED' || !isSavePossible,
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: DeltaAdjustedAmendButtonOwnProps,
): DeltaAdjustedAmendButtonDispatchProps {
  return {
    onSave: () => {
      createLogAnalyticsAction('NEOS RFQ', `Order amend requested`);
      dispatch(neosThunks.createRequestTransactionSaveThunk(rfqId));
    },
  };
}
