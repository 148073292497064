import type { Selectors } from '@/bootstrap/selectors';
import {
  isDerivativeProduct,
  isOtcProduct,
  isStockProduct,
  type LegIdsByStrategyId,
  type Product,
} from '../../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

interface LegModel {
  legId: string;
  isOtc: boolean;
}

interface DeltaLegModel {
  legId: string;
  isOtc: boolean;
  isUnderlyingTypeDelta: boolean;
}

export type StrategyAllocationsModel = {
  strategyId: string;
  legsModel: LegModel[];
};

export type DeltaStrategyAllocationsModel = {
  strategyId: string;
  legsModel: DeltaLegModel[];
};

export interface AllocationsModel {
  strategyLegs: StrategyAllocationsModel[];
  deltaLegs: DeltaStrategyAllocationsModel[];
}

export function getAllocationsModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): AllocationsModel {
  const getProductByLeg = (legId: string) =>
    selectors.getProduct(state, selectors.getProductIdOfLeg(state, legId, selectors));

  const legMapper = (legId: string): LegModel => ({
    legId,
    isOtc: isOtcProduct(getProductByLeg(legId)),
  });

  const deltaLegMapper = (legId: string): DeltaLegModel => ({
    legId,
    isOtc: isOtcProduct(getProductByLeg(legId)),
    isUnderlyingTypeDelta: getIsUnderlyingTypeDelta(state, getProductByLeg(legId), selectors),
  });

  const isEligibleFn = (legId: string) =>
    isDerivativeProduct(getProductByLeg(legId)) || isStockProduct(getProductByLeg(legId));

  const genericAllocationMapper = makeGenericAllocationMapper(isEligibleFn);

  const legAllocationMapper = genericAllocationMapper(legMapper);
  const deltaLegAllocationMapper = genericAllocationMapper(deltaLegMapper);

  const strategyLegs = selectors
    .getRfqLegIdsByStrategyIds(state, rfqId, selectors)
    .filter(isStrategyEligible(state, selectors))
    .map(legAllocationMapper);

  const deltaLegs = selectors
    .getRfqLegIdsByDeltaStrategyIds(state, rfqId, selectors)
    .filter(isStrategyEligible(state, selectors))
    .filter(
      ({ strategyId }) => !selectors.isDeltaHedgingStrategyAdjusted(state, strategyId, selectors),
    )
    .map(deltaLegAllocationMapper);
  return {
    strategyLegs,
    deltaLegs,
  };
}

function isStrategyEligible(state: AppState, selectors: Selectors) {
  const withOtc = selectors.isFeatureToggleEnabled(state, 'neos.otc.allocs.enabled');
  const withListed = selectors.isFeatureToggleEnabled(state, 'neos.allocs.enabled');

  return ({ strategyId }: LegIdsByStrategyId) =>
    (selectors.isOtcStrategy(state, strategyId, selectors) && withOtc) ||
    (selectors.isListedStrategy(state, strategyId, selectors) && withListed);
}

function makeGenericAllocationMapper(isEligibleFn: (legId: string) => boolean) {
  return <T extends LegModel | DeltaLegModel>(mapper: (legId: string) => T) =>
    ({ strategyId, legIds }: LegIdsByStrategyId) => {
      const legsModel = legIds.filter(isEligibleFn).map(mapper);
      return {
        strategyId,
        legsModel,
      };
    };
}

function getIsUnderlyingTypeDelta(state: AppState, product: Product, selectors: Selectors) {
  const underlyingId = selectors.getUnderlyingId(product);
  if (!underlyingId) {
    return false;
  }

  const underlyingInfo = selectors.getUnderlyingInfo(state, underlyingId);
  const types = ['STOCK', 'PREFERENCE_SHARE', 'ETF', 'ADR'];
  return underlyingInfo?.type ? types.includes(underlyingInfo?.type) : false;
}
