import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export function hasACompositionLeg(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  const { hasACompositionLeg } = selectors.getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  return hasACompositionLeg;
}

export function rfqHasCompositionLeg(
  selectors: Selectors,
  state: AppState,
  rfqId: string,
): boolean {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return strategyIds.some(stratId => selectors.hasACompositionLeg(state, stratId, selectors));
}
