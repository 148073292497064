import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { StrategyRecomputeScope } from '@/neos/business/rfq/models/recomputeModel';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { ObservationConvention, UpDownFeatureKey } from '../../../../../../business/neosModel';
import { UpDown as Component, type DispatchProps, type OwnProps, type StateProps } from './UpDown';
import { getUpDownModel } from './getUpDownModel';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(state: AppState, { strategyId }: OwnProps): StateProps {
  return {
    model: getUpDownModel(state, strategyId, selectors),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: OwnProps,
): DispatchProps {
  const featureKey: UpDownFeatureKey = { strategyId, type: 'UP_DOWN' };
  return {
    onUpStrikeChanged: (upStrikeValue: number | undefined) =>
      dispatch(
        neosThunks.createChangeFeatureFieldThunk(featureKey, {
          upStrikeValue,
        }),
      ),
    onDownStrikeChanged: (downStrikeValue: number | undefined) =>
      dispatch(
        neosThunks.createChangeFeatureFieldThunk(featureKey, {
          downStrikeValue,
        }),
      ),

    onObsChanged: (observationConvention: ObservationConvention | undefined) =>
      dispatch(
        neosThunks.createChangeFeatureFieldThunk(featureKey, {
          upObservationConvention: observationConvention,
          downObservationConvention: observationConvention,
        }),
      ),

    onUpDownUnitChanged: (currentUnit: string) => {
      dispatch(
        neosActionCreators.createRecomputeRfqRequestedAction(
          rfqId,
          currentUnit === '%'
            ? StrategyRecomputeScope.CHANGE_UP_DOWN_TO_CURRENCY_UNIT
            : StrategyRecomputeScope.CHANGE_UP_DOWN_TO_PERCENT_UNIT,
        ),
      );
    },
  };
}

export const UpDown = connect(mapStateToProps, mapDispatchToProps)(Component);
