import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput.tsx';
import { thunks } from '@/bootstrap/thunks.ts';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures.tsx';
import { useContext } from 'react';
import type { Way } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip.tsx';
import { toUpperCase } from '@/util/string/stringUtils.ts';

export function ElsLendAndBorrowPortfolio() {
  const dispatch = useAppDispatch();
  const { product } = useContext(ElsFeaturesContext);

  const legWay = useAppSelector(state => selectors.getLegWay(state, product.legId, selectors));
  const label = getLabel(legWay);

  return (
    <>
      <label>{label}</label>
      <SimpleNeosTooltip message={'Please choose a way'} disable={legWay !== 'TWO_WAYS'}>
        <NeosBlurInput
          readOnly={legWay === 'TWO_WAYS'}
          value={product.stockLoanHedge?.portfolio}
          data-e2e="els-LendAndBorrow-portfolio"
          transform={toUpperCase}
          onBlur={event => {
            dispatch(
              thunks.neos.createUpdateElsStockLoanHedgePortfolioThunk(
                product.uuid,
                event.target.value,
              ),
            );
          }}
        />
      </SimpleNeosTooltip>
    </>
  );
}

function getLabel(legWay: Way | undefined) {
  const wayLabel = legWay === 'TWO_WAYS' ? '' : legWay === 'SELL' ? 'Lending' : 'Borrowing';
  return `${wayLabel}${wayLabel !== '' ? ' ' : ''}Portfolio`;
}
