import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  GenerateAllocationsButtonComponent,
  type GenerateAllocationsButtonDispatchProps,
  type GenerateAllocationsButtonOwnProps,
} from './GenerateAllocationsButton';
import { getGenerateAllocationsButtonModel } from './getGenerateAllocationsButtonModel';

export const GenerateAllocationsButton: FC<GenerateAllocationsButtonOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const stateProps = useAppSelector(appState => ({
    model: getGenerateAllocationsButtonModel(appState, ownProps.rfqId, selectors),
  }));
  const dispatchProps = mapDispatchProps(dispatch, ownProps);

  return <GenerateAllocationsButtonComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: GenerateAllocationsButtonOwnProps,
): GenerateAllocationsButtonDispatchProps {
  return {
    onButtonClicked: () => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Apply Pre Allocations to Executions', rfqId),
        neosActionCreators.createDefaultAllocationsRequestedAction(rfqId),
      );
    },
  };
}
