import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { services } from '@/bootstrap/services';
import type { ExtendedDispatch } from '@/bootstrap/thunks';

import { NeosTooltip } from '../../share/tooltip/NeosTooltip';

import { selectors } from '@/bootstrap/selectors';
import { useDispatch } from 'react-redux';

interface CrossBadgeProps {
  rfqId: string;
  strategyId: string;
}

export function CrossBadge({ rfqId, strategyId }: CrossBadgeProps) {
  const dispatch = useDispatch<ExtendedDispatch>();

  const isEditable = useAppSelector(state => {
    const { status } = selectors.getRfqData(state, rfqId);
    return services.orderOf(status).isBeforeOrEqual('ORDER_ACCEPTED');
  });

  const crossPartially = useAppSelector(state => {
    const { crossPartially } = selectors.getStrategyData(state, strategyId);
    return crossPartially;
  });

  const onClicked = (isCrossPartially: boolean) => {
    if (isEditable) {
      dispatch(
        actionCreators.neos.strategyDataCrudActions.update(strategyId, {
          crossPartially: isCrossPartially,
        }),
      );
    }
  };

  if (!crossPartially && crossPartially !== false) {
    return <span />;
  }

  return (
    <NeosTooltip
      id="crossValue"
      isDisplayed
      renderMessage={() => <>{crossPartially ? 'Partial Cross' : 'Full Cross'}</>}
    >
      <span
        onClick={() => onClicked(!crossPartially)}
        className={`badge bg-info my-auto ms-1 ${isEditable ? 'c-pointer' : ''}`}
      >
        {crossPartially ? 'PC' : 'FC'}
      </span>
    </NeosTooltip>
  );
}
