import type { Selectors } from '@/bootstrap/selectors';
import {
  type AvailableStrikes,
  type Product,
  type StrikeProduct,
  isProductWithStrike,
} from '@/neos/business/rfq/strategy/strategyModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import type { AppState } from '@/bootstrap/store.ts';

interface StrikeWithRecommendedValue {
  unit?: string;
  value?: number;
  recommendedValue?: number;
}

interface CommonStrikeCells {
  showAvailableStrikes: boolean;
  strikes: Array<Field<StrikeWithRecommendedValue | undefined>>;
}

interface StrikeCellsWithAvailableStrikes extends CommonStrikeCells {
  showAvailableStrikes: true;
  availableStrikes: AvailableStrikes[];
}

interface StrikeCellsWithoutAvailableStrikes extends CommonStrikeCells {
  showAvailableStrikes: false;
}

export type StrikeCellsModel = StrikeCellsWithAvailableStrikes | StrikeCellsWithoutAvailableStrikes;

export function getStrikeCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): StrikeCellsModel {
  const { getStrategyData, getStrategyProducts } = selectors;
  const { getStrategyDefinition } = selectors;

  const { strategyType } = getStrategyData(appState, strategyId);
  const { sameProductStrike } = getStrategyDefinition(appState.referenceData, strategyType);

  const products = getStrategyProducts(appState, strategyId, selectors).filter(isProductWithStrike);

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  const strikesAreReadonly =
    getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_EDITABLE',
      'STRATEGY_EDITABLE',
      'UNDERLYING_SET',
    ]) && !isReadonlyAtWorkflow;

  const availableStrikes = sameProductStrike
    ? selectors.getAvailableStrikesForSameStrikeStrategy(appState, strategyId, selectors)
    : selectors.getAvailableStrikesForStrategy(appState, strategyId, selectors);

  const strikes = getStrikeFields(
    rfqId,
    appState,
    products,
    sameProductStrike,
    strikesAreReadonly,
    selectors,
    availableStrikes,
  );

  const commonModel: Omit<CommonStrikeCells, 'showAvailableStrikes'> = { strikes };
  if (strikes.every(strike => strike.value?.unit === '%')) {
    return { ...commonModel, showAvailableStrikes: false };
  }

  return {
    ...commonModel,
    showAvailableStrikes: true,
    availableStrikes,
  };
}

function getStrikeFields(
  rfqId: string,
  state: AppState,
  products: Extract<Product, StrikeProduct>[],
  isSameApplicable: boolean,
  isEditable: boolean,
  selectors: Selectors,
  availableStrikes: AvailableStrikes[],
): Array<Field<StrikeWithRecommendedValue | undefined>> {
  if (!products.length) {
    return [];
  }

  return isSameApplicable
    ? [
        {
          id: products[0].uuid,
          value: {
            value: products[0].strike,
            recommendedValue: selectors.getRfqProductNearestStrike(
              rfqId,
              state,
              products[0].uuid,
              availableStrikes.filter(e => e.legId === products[0].uuid)[0],
              selectors,
            ),
            unit: products[0].strikeUnit,
          },
          isEditable,
        },
      ]
    : products.map(product => ({
        id: product.uuid,
        value: {
          value: product.strike,
          recommendedValue: selectors.getRfqProductNearestStrike(
            rfqId,
            state,
            product.uuid,
            availableStrikes.filter(e => e.legId === product.uuid)[0],
            selectors,
          ),
          unit: product.strikeUnit,
        },
        isEditable,
      }));
}
