import { useContext } from 'react';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures.tsx';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput.tsx';
import styles from '@/neos/components/rfq/strategyDetails/StrategyDetails.module.scss';
import { thunks } from '@/bootstrap/thunks.ts';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect.tsx';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { clientWays, type Way } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import { Switch } from '@/neos/components/share/switch/Switch.tsx';
import {
  NeosSelectWithAutocomplete,
  type Option,
} from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete.tsx';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel.ts';
import type { EquityHedgeType } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { actionCreators } from '@/bootstrap/actions.ts';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

export function ElsBuyAndSellDescription() {
  const dispatch = useAppDispatch();
  const { product, rfqId } = useContext(ElsFeaturesContext);

  const currencies = useAppSelector(state => selectors.getCurrencyList(state.referenceData));

  const availableClients = useAppSelector(state =>
    selectors.getAndFormatAvailableClients(state, rfqId, selectors),
  );

  const options: Option<Counterpart>[] = availableClients.map(counterpart => ({
    value: counterpart.id.toString(),
    label: counterpart.name,
    tag: counterpart,
  }));

  const { isElsEquityHedgeTypeInternal, equityType, equityTypeOptions } = useAppSelector(state =>
    selectors.getEquityHedgeTypeAndOptions(
      state,
      rfqId,
      product.equityHedge?.equityType,
      selectors,
    ),
  );

  const handleEquityTypeChange = (value: EquityHedgeType | undefined) => {
    dispatch(thunks.neos.createUpdateElsEquityHedgeThunk(product.uuid, 'equityType', value));
    product.equityHedge?.equityHedgeComponents.forEach((_, index) => {
      dispatch(
        thunks.neos.createUpdateElsEquityComponentThunk(product.uuid, index, 'equityType', value),
      );
    });
  };

  const handleCounterpartChange = (value: Counterpart | undefined) => {
    dispatch(thunks.neos.createUpdateElsEquityHedgeThunk(product.uuid, 'counterparty', value));
    product.equityHedge?.equityHedgeComponents.forEach((_, index) => {
      dispatch(
        thunks.neos.createUpdateElsEquityComponentThunk(product.uuid, index, 'counterparty', value),
      );
    });
  };

  const handleWayChange = (value: Way | undefined) => {
    dispatch(thunks.neos.createUpdateElsEquityHedgeThunk(product.uuid, 'way', value));
    product.equityHedge?.equityHedgeComponents.forEach((_, index) => {
      dispatch(thunks.neos.createUpdateElsEquityComponentThunk(product.uuid, index, 'way', value));
    });
  };

  return (
    <div
      className="d-grid gap-y-1 gap-x-2 text-end align-items-center"
      style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr', cursor: 'default' }}
      onMouseDown={e => e.stopPropagation()}
    >
      <label>Broker</label>
      <NeosBlurInput
        className={`${styles['strategy-broker-input']} form-control-alt`}
        type="text"
        value={product.equityHedge?.broker || ''}
        onBlur={event => {
          dispatch(
            thunks.neos.createUpdateElsEquityHedgeBrokerThunk(product.uuid, event.target.value),
          );
        }}
        data-e2e="els-BuyAndSell-broker"
      />

      <label>Type</label>
      {isElsEquityHedgeTypeInternal ? (
        <NeosSelect
          addEmptyOption
          value={equityType}
          options={equityTypeOptions}
          onChange={handleEquityTypeChange}
          data-e2e="els-BuyAndSell-equityType"
        />
      ) : (
        <NeosSelect
          addEmptyOption
          value={equityType}
          options={equityTypeOptions}
          onChange={handleEquityTypeChange}
          data-e2e="els-BuyAndSell-equityType"
        />
      )}

      <label>Way</label>
      <NeosSelect
        value={product.equityHedge?.way}
        onChange={handleWayChange}
        addEmptyOption
        options={clientWays}
        data-e2e="els-BuyAndSell-way"
      />

      <label>Settl. Currency</label>
      <NeosSelect
        value={product.equityHedge?.currency}
        onChange={value => {
          dispatch(thunks.neos.createUpdateElsEquityHedgeThunk(product.uuid, 'currency', value));
          dispatch(
            actionCreators.neos.createDefaultRfqRequestedAction(
              rfqId,
              new DefaultingOptions({
                enrichScopes: [DefaultingScope.FOREX],
              }),
            ),
          );
        }}
        addEmptyOption
        options={currencies.map(({ refId }) => ({ value: refId, label: refId }))}
        data-e2e="els-BuyAndSell-currency"
      />

      <label>Counterpart</label>
      <NeosSelectWithAutocomplete
        data-e2e="els-BuyAndSell-Counterpart"
        value={product.equityHedge?.counterparty?.id.toString()}
        onChange={selectedOption => handleCounterpartChange(selectedOption?.tag)}
        addEmptyOption
        options={options}
      />

      <label>Manually Booked</label>
      <Switch
        disabled
        checked={product.equityHedge?.bookedManually ?? false}
        data-e2e="els-BuyAndSell-manuallyBooked"
      />
    </div>
  );
}
