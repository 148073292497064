import type { Selectors } from '@/bootstrap/selectors';
import { type DeterminationMethod, isRfqStrategyData } from '@/neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface ClsDeterminationModel {
  isElsDetermination: boolean;
  isClsDetermination: boolean;
  areFieldsReadOnly: boolean;
  determinationMethod: DeterminationMethod | undefined;
  initialPriceDetermination: DeterminationMethod | undefined;
}

export function getDeterminationFieldsModel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): ClsDeterminationModel {
  const strategyData = selectors.getStrategyData(state, strategyId);
  const { strategyType, determinationMethod, initialPriceDetermination, rfqId } = strategyData;

  const isStrategyEls = selectors.isElsStrategy(state, strategyId, selectors);

  return {
    isElsDetermination: isStrategyEls,
    isClsDetermination: strategyType === 'CLS',
    areFieldsReadOnly: selectors.isReadOnlyRfq(state, rfqId) || !isRfqStrategyData(strategyData),
    determinationMethod,
    initialPriceDetermination,
  };
}
