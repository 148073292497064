import { neosThunks } from '@/neos/business/thunks';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { thunks } from '@/bootstrap/thunks.ts';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { PasteClipboard } from '@/neos/components/share/importExportClipboard/PasteClipboard.tsx';

type ElsBuyAndSellClipboardPasteProps = {
  rfqId: string;
  product: Els;
};

export const ElsBuyAndSellClipboardPaste = ({
  product,
  rfqId,
}: ElsBuyAndSellClipboardPasteProps) => {
  const dispatch = useAppDispatch();

  function handleError(error: string) {
    dispatch(thunks.createErrorToasterThunk({ message: error }, undefined));
  }

  function handleClipboardData(importedData: unknown[]) {
    dispatch(
      neosThunks.createImportElsBuyAndSellCompositionThunk(
        rfqId,
        product.uuid,
        importedData,
        'clipboard',
      ),
    );
  }

  return (
    <PasteClipboard
      handleClipboardData={handleClipboardData}
      handleError={handleError}
      dataE2e="els-buy-sell-clipboard"
    />
  );
};
