import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { rfqUiActionCreators } from '@/neos/business/ui/rfq/rfqUiActionCreators';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { getRfqSecondaryWidgetToggleModel } from './getRfqSecondaryWidgetToggleModel';

export interface RfqSecondaryWidgetToggleProps {
  rfqId: string;
}

export const RfqSecondaryWidgetToggle = ({ rfqId }: RfqSecondaryWidgetToggleProps) => {
  const dispatch = useAppDispatch();

  const model = useAppSelector(state => getRfqSecondaryWidgetToggleModel(state, rfqId, selectors));

  function onToggleClick(isRfqSecondaryWidgetToggleOn: boolean) {
    dispatch(
      rfqUiActionCreators.rfqUiCrudActions.patchOrInsert(rfqId, { isRfqSecondaryWidgetToggleOn }),
    );
  }

  if (!model || model.isSecondary) {
    return null;
  }

  return (
    <SimpleNeosTooltip
      id="rfq-secondary-widget-toggle-tooltip"
      message={'Toggle Secondary Event Mode'}
    >
      <button
        className="btn btn-primary btn-icon"
        data-e2e="rfq-secondary-widget-toggle"
        disabled={!model.isEnabled}
        onClick={() => onToggleClick(!model.isRfqSecondaryWidgetToggleOn)}
      >
        <i className="icon">
          {model.isRfqSecondaryWidgetToggleOn ? 'chevron_left' : 'chevron_right'}
        </i>
      </button>
    </SimpleNeosTooltip>
  );
};
