import type { Selectors } from '@/bootstrap/selectors';
import {
  hasMaturity,
  isElsBasketProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { isLegMaturityUpToDate } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/maturityCells/modelGetters/getMaturitiesFieldsArray';
import type { EventType } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface OtcAllocationSecondaryTypeWidgetModel {
  isEligible: boolean;
  isLegMaturityValid: boolean | undefined;
  secondaryType: EventType | undefined;
  isLoading: boolean;
  isDisplayed: boolean;
  isElsBasket: boolean;
}

export function getOtcAllocationSecondaryTypeWidgetModel(
  state: AppState,
  rfqId: string,
  legId: string,
  allocationId: string,
  selectors: Selectors,
): OtcAllocationSecondaryTypeWidgetModel {
  const isDisplayed = selectors.isOtcAllocSecondaryWidgetColumnDisplayed(state, rfqId, selectors);

  if (!isDisplayed) {
    return {
      isDisplayed,
      isEligible: false,
      isLoading: false,
      isLegMaturityValid: undefined,
      secondaryType: undefined,
      isElsBasket: false,
    };
  }

  const isRfqLoading = selectors.isRfqLoading(state.ui, rfqId);

  const isLegEligibleToSecondaryEventCreation = selectors.isLegEligibleToSecondaryEventCreation(
    state,
    rfqId,
    legId,
    selectors,
  );

  if (!isLegEligibleToSecondaryEventCreation) {
    return {
      isDisplayed,
      isEligible: false,
      isLoading: isRfqLoading,
      isLegMaturityValid: undefined,
      secondaryType: undefined,
      isElsBasket: false,
    };
  }

  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  const secondaryType = selectors.getAllocationSecondaryEventCreation(state, {
    rfqId,
    allocationId,
  });

  const isLegMaturityValid = hasMaturity(product) && isLegMaturityUpToDate(product.maturity);

  return {
    isDisplayed,
    isEligible: true,
    isLoading: isRfqLoading,
    secondaryType,
    isLegMaturityValid,
    isElsBasket: isElsBasketProduct(product),
  };
}
