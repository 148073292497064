import type { NegotiationMode } from '@/neos/business/neosModel';
import { MultiToggleButton } from '@/neos/components/share/multiToggleButton/MultiToggleButton';
import { getNegoTypeCellModel } from './getNegoTypeCellModel';
import { neosThunks } from '@/neos/business/thunks';
import { selectors } from '@/bootstrap/selectors.ts';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';

export interface NegoTypeCellProps {
  rfqId: string;
  strategyId: string;
}

export function NegoTypeCell({ rfqId, strategyId }: NegoTypeCellProps) {
  const dispatch = useAppDispatch();
  const { negoTypes } = useAppSelector(state =>
    getNegoTypeCellModel(state, rfqId, strategyId, selectors),
  );

  function onNegotiationTypeChanged(negoType: NegotiationMode) {
    dispatch(neosThunks.createChangeStrategiesNegotiationModeThunk(rfqId, [strategyId], negoType));
  }

  return (
    <section>
      {negoTypes.map(({ id, value, isEditable }) => (
        <MultiToggleButton<NegotiationMode>
          className="form-control"
          key={id}
          readOnly={!isEditable}
          selectedValue={value}
          valueFormatter={v => (v ? (v === 'LISTED' ? 'Listed' : v) : '')}
          availableValues={['OTC', 'LISTED']}
          onValueChange={newNegoType => onNegotiationTypeChanged(newNegoType)}
          data-e2e="neos-strategy-definition-nego-type"
        />
      ))}
    </section>
  );
}
