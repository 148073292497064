import type { Reducer } from '@reduxjs/toolkit';
import type { AppCrashedAction, CriticalAppCrashedAction } from '../globalError/globalErrorActions';
import type { HelpRequestActions } from './helpRequestActions';
import { defaultHelpRequestState, type HelpRequestState } from './helpRequestState';

export const helpRequestReducer: Reducer<HelpRequestState> = (
  state = defaultHelpRequestState,
  unknownAction,
) => {
  const action = unknownAction as unknown as
    | HelpRequestActions
    | AppCrashedAction
    | CriticalAppCrashedAction;

  switch (action.type) {
    case 'HELP_REQUEST_MODAL_DISPLAYED_ACTION':
      return {
        ...state,
        displayHelpRequestModal: action.isDisplayed,
        errorLocation: action.errorLocation,
        error: action.error,
      };
    case 'APP_CRASHED':
      return {
        ...state,
        displayHelpRequestModal: true,
        errorLocation: action.source,
        error: JSON.stringify(action.error),
      };
    default:
      return state;
  }
};
