import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { Switch } from '@/neos/components/share/switch/Switch';

import { Modal } from 'react-bootstrap';

import styles from './elsSectionsModal.module.scss';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export interface elsSectionsModalProps {
  strategyId: string;
}

export function ElsSectionsModal({ strategyId }: elsSectionsModalProps) {
  const dispatch = useAppDispatch();
  const showElsSectionsModal = useAppSelector(
    state => selectors.getUiStrategy(state.ui, strategyId)?.showElsSectionsModal,
  );

  const elsSections = useAppSelector(state =>
    selectors
      .getFeatureToggledElsSections(state, strategyId, selectors)
      .filter(section => section.i !== 'Dividend Leg' && section.i !== 'Brokers'),
  );

  function onModalHide() {
    dispatch(
      neosActionCreators.strategyUiCrudActions.update(strategyId, {
        showElsSectionsModal: false,
      }),
    );
  }

  return (
    <Modal size="sm" show={showElsSectionsModal} onHide={onModalHide}>
      <Modal.Header closeButton>
        <Modal.Title>Els Sections</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`${styles['els-sections-switchers-container']}`}>
          <div className={`${styles['els-sections-switchers']} text-nowrap flex-container`}>
            {elsSections.map(section => {
              return (
                <div className="row py-2" key={section.i}>
                  <div className="col-5">
                    <label>{section.i.toString()}</label>
                  </div>
                  <div className="col-4">
                    <Switch
                      checked={section.isDisplayed}
                      onChange={() =>
                        dispatch(
                          thunks.neos.createToggleElsSectionThunk(section.i, !section.isDisplayed),
                        )
                      }
                      e2eId={`els-sections-switcher-${section.i
                        .toLowerCase()
                        .split(' ')
                        .join('-')}`}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
