import type { Selectors } from '@/bootstrap/selectors';
import { isValidSecondaryWidgetStatus } from '@/neos/business/secondaryWidget/isValidSecondaryWidgetStatus';
import type { AppState } from '@/bootstrap/store.ts';

export type RfqSecondaryTypeWidgetModel = {
  isRfqSecondaryWidgetToggleOn: boolean;
  isVisible: boolean;
  otcLegIdsWithValidEventType: string[];
  hasSomeValidLegMaturity: boolean;
};

export function getRfqSecondaryTypeWidgetModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RfqSecondaryTypeWidgetModel {
  const isRfqSecondaryWidgetToggleOn = selectors.isRfqSecondaryWidgetToggleOn(state.ui, rfqId);
  const legIds = selectors.getRfqLegIds(state, rfqId, selectors);

  const otcLegIdsWithValidEventType = legIds.filter(legId => {
    const eventType = selectors.getLegData(state, legId).eventType;
    const isLegOtc = selectors.isOtcLeg(state, legId, selectors);

    return isLegOtc && (eventType === 'CREATE' || eventType === 'STEPIN');
  });

  const { strategyIds, status } = selectors.getRfqData(state, rfqId);

  const allMaturitiesFields = strategyIds.map(strategyId =>
    selectors.getAllMaturitiesFieldsArray(state, rfqId, strategyId, selectors),
  );
  const hasSomeValidLegMaturity = allMaturitiesFields.some(maturityField =>
    maturityField.some(field => field.isValid),
  );
  const isValidStatus = isValidSecondaryWidgetStatus(status);

  return {
    isRfqSecondaryWidgetToggleOn,
    isVisible: isValidStatus,
    otcLegIdsWithValidEventType,
    hasSomeValidLegMaturity,
  };
}
