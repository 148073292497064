import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { FlowDatePickerWithOffset } from '@/neos/components/share/datePicker/FlowDatePickerWithOffset';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import {
  getIsSettlementDateCellsEditableModel,
  type IsSettlementDateCellsEditableModel,
} from './getIsSettlementDateCellsEditableModel';
import {
  getSettlementDateCellsModel,
  type SettlementDateCellsModel,
} from './getSettlementDateCellsModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export interface SettlementDateCellsOwnProps {
  rfqId: string;
  strategyId: string;
  isReadOnlyRfq: boolean;
}

type SettlementDateCellsProps = SettlementDateCellsOwnProps;

export const SettlementDateCells = ({
  rfqId,
  strategyId,
  isReadOnlyRfq,
}: SettlementDateCellsProps) => {
  const dispatch = useAppDispatch();

  function onSettlementDateChange(selectedDate: string) {
    dispatch(neosThunks.createUpdateSettlementDateThunk(rfqId, legId, selectedDate));
  }

  function onSettlementOffsetChange(selectedOffset: number) {
    dispatch(neosThunks.createUpdateSettlementDateOffsetThunk(rfqId, legId, selectedOffset));
  }

  const { settlementDate, settlementDateOffset, isDisplayed, legId }: SettlementDateCellsModel =
    useAppSelector(state => getSettlementDateCellsModel(state, strategyId, selectors));

  const isEditable: IsSettlementDateCellsEditableModel = useAppSelector(state =>
    getIsSettlementDateCellsEditableModel(state, { strategyId, rfqId }, selectors),
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <section>
      <ErrorHighlight errorField={'settlementDate'} related={{ rfqId, strategyId, legId }}>
        <FlowDatePickerWithOffset
          data-e2e="neos-strategy-settlement-date"
          date={settlementDate ?? ''}
          onChange={onSettlementDateChange}
          onOffsetChange={onSettlementOffsetChange}
          currentOffset={settlementDateOffset}
          readOnly={isReadOnlyRfq || !isEditable}
          hideCalendarIcon
        />
      </ErrorHighlight>
    </section>
  );
};
