import { useAppSelector } from '@/bootstrap/hooks';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';

import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, type Thunks, thunks } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import {
  PreconfTabComponent,
  type PreconfTabDispatchProps,
  type PreconfTabOwnProps,
} from '../shared/preconfTab/PreconfTab';
import { getPreconfTabModel } from '../shared/preconfTab/getPreconfTabModel';

export const ExternalPreconfTab: FC<PreconfTabOwnProps> = ownProps => {
  const stateProps = useAppSelector(state => ({
    model: getPreconfTabModel(state, ownProps.rfqId, 'EXTERNAL', selectors),
  }));
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, thunks);
  return <PreconfTabComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

export function getDispatchProps(
  dispatch: ExtendedDispatch,
  { neos: { createExternalPreconfirmationPreviewAttachmentThunks } }: Thunks,
): PreconfTabDispatchProps {
  return {
    onPreconfTabPreviewClicked: (rfqId: string) => {
      dispatch(neosThunks.createPreconfirmationEmailPreviewRequestedThunk(rfqId, 'EXTERNAL'));
    },
    onPreconfTabPreviewAttachmentClicked: (rfqId: string) => {
      dispatch(createExternalPreconfirmationPreviewAttachmentThunks(rfqId));
    },
  };
}
