import type { Selectors } from '@/bootstrap/selectors';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import {
  type AllocationPredealCheck,
  isAllocationPredealCheck,
} from '../predealCheck/predealCheckModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getAllNeosAllocationPredealChecks(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): AllocationPredealCheck[] {
  const { predealCheckIds } = selectors.getRfqData(state, rfqId);

  return predealCheckIds
    .map(id => selectors.getPredealCheck(state, id))
    .filter(isDefined)
    .filter(isAllocationPredealCheck);
}
