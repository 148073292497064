import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import type { SalesLocations } from '@/neos/business/referenceData/salesLocation/salesLocationsModel';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  type SalesInitLocationDispatchProps,
  type SalesInitLocationMapStateToProps,
  type SalesInitLocationOwnProps,
  SalesInitLocationUnconnected,
} from './SalesInitLocation';
import type { AppState } from '@/bootstrap/store.ts';

const { getSalesInitCityLocation, getSalesPhysicalLocation, isReadOnlyRfq } = selectors;
const { getSalesLocations } = selectors;

export const SalesInitLocation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesInitLocationUnconnected);

function mapStateToProps(
  state: AppState,
  { rfqId }: SalesInitLocationOwnProps,
): SalesInitLocationMapStateToProps {
  const salesPhysicalLocation = getSalesPhysicalLocation(state, rfqId);
  const salesInitCityLocation = getSalesInitCityLocation(state, rfqId);
  const salesLocations = getSalesLocations(state.referenceData);
  return {
    salesInitCityLocation: salesInitCityLocation
      ? salesInitCityLocation
      : retrieveCityFromCountryCode(salesLocations, salesPhysicalLocation),
    salesLocations,
    isReadonlyRfq: isReadOnlyRfq(state, rfqId),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: SalesInitLocationOwnProps,
): SalesInitLocationDispatchProps {
  return {
    onChangeSalesLocation: (location: string) => {
      dispatch(neosThunks.createChangeSalesLocationThunk(rfqId, location));
    },
  };
}

function retrieveCityFromCountryCode(
  salesLocations: SalesLocations[],
  salesPhysicalLocation: string | undefined,
): string | undefined {
  return salesLocations.find(salesLocation => salesLocation.countryCode === salesPhysicalLocation)
    ?.name;
}
