import type { Thunk } from '@/bootstrap/thunks';
import type { BloombergCodeByProductId } from '@/neos/business/referenceData/underlying/underlyingActionCreators';

export interface ImportedCompositionLegsData {
  underlying?: string;
  type?: string;
  weight?: number;
  expectedN?: number;
  volStrike?: number;
  size?: number;
}

export function createRfqSetUpImportedCompositionLegsThunk(
  rfqId: string,
  strategyId: string,
  importedData: ImportedCompositionLegsData[],
): Thunk {
  return function rfqSetUpImportedCompositionLegs(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createUnderlyingIdsRequestedAction, strategyUiCrudActions },
      },
      thunks: {
        neos: {
          createRfqApplyImportedCompositionLegsThunk,
          createRequestRfqMissingUnderlyingsThunk,
          createRfqAddLegsThunk,
          createRfqDeleteLegThunk,
        },
      },
    },
  ) {
    const state = getState();

    const indexData = importedData.filter(id => id.type?.toLocaleLowerCase() === 'i');
    if (indexData.length > 1) {
      dispatch(
        strategyUiCrudActions.update(strategyId, {
          displayCompoImportHasMultipleIndicesWarning: true,
        }),
      );
      return;
    }

    const compositionData = importedData.filter(id => id.type?.toLocaleLowerCase() === 'c');
    const initialLegs = selectors.getRfqLegsData(state, rfqId, selectors);

    initialLegs
      .filter(leg => !leg.isMaster)
      .map(leg => leg.uuid)
      .forEach(legToDeleteId => {
        dispatch(createRfqDeleteLegThunk(legToDeleteId));
      });

    dispatch(createRfqAddLegsThunk(strategyId, compositionData.length));

    const updatedState = getState();
    const { legIds } = selectors.getStrategyData(updatedState, strategyId);
    const legsData = legIds.map(legId => selectors.getLegData(updatedState, legId));

    const bloombergCodesByProductId: BloombergCodeByProductId[] = legsData
      .map(({ productId }, index) => ({
        productId,
        bloombergCode: importedData[index].underlying,
      }))
      .filter(
        (bloombergCodeByProductId): bloombergCodeByProductId is BloombergCodeByProductId =>
          bloombergCodeByProductId.bloombergCode !== undefined,
      );

    if (bloombergCodesByProductId.length) {
      dispatch(
        createUnderlyingIdsRequestedAction([rfqId], bloombergCodesByProductId, {
          success: {
            dispatchables: [
              createRequestRfqMissingUnderlyingsThunk(rfqId, {
                success: {
                  dispatchables: [
                    createRfqApplyImportedCompositionLegsThunk(rfqId, strategyId, importedData),
                  ],
                },
              }),
            ],
          },
        }),
      );
    }
  };
}
