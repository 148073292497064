import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  ForexComponent,
  type ForexDispatchProps,
  type ForexMapStateToProps,
  type ForexOwnProps,
} from './Forex';
import { getForexModel } from './getForexModel';
import type { AppState } from '@/bootstrap/store.ts';

export const Forex = connect(mapStateToProps, mapDispatchToProps)(ForexComponent);

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: ForexOwnProps,
): ForexDispatchProps {
  return {
    updateForexRate: (value: number | undefined, fromCurrency: string, toCurrency: string) => {
      dispatch(neosThunks.createChangeForexRatesThunk(rfqId, value, fromCurrency, toCurrency));
    },
  };
}

function mapStateToProps(state: AppState, { rfqId }: ForexOwnProps): ForexMapStateToProps {
  const model = getForexModel(state, rfqId, selectors);

  return {
    model,
  };
}
