import type { Selectors } from '@/bootstrap/selectors';
import {
  isDividendFutureProduct,
  isFutureOptionProduct,
  isFutureProduct,
  isListedProduct,
  isOptionProduct,
  isSingleUnderlyingDerivativeProduct,
  isTotalReturnFutureProduct,
  type Product,
} from '../../../../../../../../neos/business/neosModel';
import {
  buildDefaultResultToBuildLotSizeMarket,
  buildLotSizeMarketFieldForDividendFutureListedProduct,
  buildLotSizeMarketFieldForFutureListedProduct,
  buildLotSizeMarketFieldForOptionFlexProduct,
  buildLotSizeMarketFieldForOptionProduct,
  buildLotSizeMarketFieldForTotalReturnFutureListedProduct,
  type LotSizeMarket,
} from './getStrategyDefinitionSpecificProduct';
import type { AppState } from '@/bootstrap/store.ts';

export function getCurrentLotSizeMarket(
  firstProduct: Product,
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): LotSizeMarket {
  const isSomeProductsFLexOptions = selectors.isSomeStrategyProductFlexOptions(
    state,
    strategyId,
    selectors,
  );

  const defaultResult = buildDefaultResultToBuildLotSizeMarket(firstProduct);
  if (!isSingleUnderlyingDerivativeProduct(firstProduct) || !firstProduct.underlyingId) {
    return defaultResult;
  }

  const underlyingInfo = selectors.getFullUnderlyingInfo(state, firstProduct.underlyingId);

  if (!underlyingInfo) {
    return defaultResult;
  }

  if (isFutureOptionProduct(firstProduct)) {
    return buildLotSizeMarketFieldForOptionProduct(firstProduct, underlyingInfo);
  }
  if (isOptionProduct(firstProduct)) {
    return isSomeProductsFLexOptions
      ? buildLotSizeMarketFieldForOptionFlexProduct(firstProduct, underlyingInfo)
      : buildLotSizeMarketFieldForOptionProduct(firstProduct, underlyingInfo);
  }

  if (!isListedProduct(firstProduct)) {
    return defaultResult;
  }

  if (isFutureProduct(firstProduct)) {
    return buildLotSizeMarketFieldForFutureListedProduct(firstProduct, underlyingInfo);
  }

  if (isDividendFutureProduct(firstProduct)) {
    return buildLotSizeMarketFieldForDividendFutureListedProduct(firstProduct, underlyingInfo);
  }

  if (isTotalReturnFutureProduct(firstProduct)) {
    return buildLotSizeMarketFieldForTotalReturnFutureListedProduct(firstProduct, underlyingInfo);
  }
  return defaultResult;
}
