import { DefaultingScope } from '../models';
import { DefaultingOptions } from '../rfqActions';
import { createDefaultRfqRequestedAction } from './defaultRfqRequestedAction';
import type { Action } from '@/bootstrap/actions.ts';

export function createRfqDeltaHedgingRequestedAction(rfqId: string): Action {
  return createDefaultRfqRequestedAction(
    rfqId,
    new DefaultingOptions({
      overrideScopes: [DefaultingScope.DELTAS, DefaultingScope.UNDERLYING],
    }),
  );
}
