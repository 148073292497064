import type { Selectors } from '@/bootstrap/selectors';
import type { MapToOnyxCommonAllocationResult } from '@/neos/business/rfq/strategy/leg/legMapper';
import {
  mapFromOnyxUpdateJustification,
  mapToOnyxUpdateJustification,
} from '@/neos/business/rfq/strategy/leg/otcAllocation/updateJustificationMappers';
import type { OnyxOtcAllocation } from '../../../../../../neos/business/neosOnyxModel';
import type { Product } from '../legModel';
import type { OtcAllocation } from './otcAllocationModel';
import type { AppState } from '@/bootstrap/store.ts';

export const toOtcAllocationMappers = {
  mapToOnyxLegOtcAllocations,
};

export const fromOtcAllocationMappers = {
  mapFromOnyxOtcAllocation,
};

function mapToOnyxLegOtcAllocations(
  state: AppState,
  rfqId: string,
  legId: string,
  selectors: Selectors,
): MapToOnyxCommonAllocationResult<OnyxOtcAllocation> {
  const legOtcAllocations = selectors.getOtcAllocationsByLegId(state, legId);
  const product = selectors.getProduct(state, legId);

  const activeAllocations = legOtcAllocations.filter(allocation => !allocation.isCancelled);
  const cancelledAllocations = legOtcAllocations.filter(allocation => allocation.isCancelled);

  return {
    activeOnyxAllocations: innerMapToOnyxLegOtcAllocations(
      state,
      rfqId,
      activeAllocations,
      product,
      selectors,
    ),
    cancelledOnyxAllocations: innerMapToOnyxLegOtcAllocations(
      state,
      rfqId,
      cancelledAllocations,
      product,
      selectors,
    ),
  };
}

function innerMapToOnyxLegOtcAllocations(
  state: AppState,
  rfqId: string,
  allocations: OtcAllocation[],
  product: Product,
  selectors: Selectors,
): OnyxOtcAllocation[] {
  return allocations.map(
    (alloc): OnyxOtcAllocation => ({
      negotiationMode: 'OTC',
      uuid: alloc.uuid,
      mcaEligible: alloc.mcaEligible,
      counterparty: alloc.counterpartId ? { id: alloc.counterpartId } : undefined,
      negotiatedSize: {
        sizeType: alloc.sizeType,
        notional: alloc.notional,
        varUnit: alloc.varUnit,
        ...(alloc.quantity
          ? { quantity: alloc.quantity, numberOfLots: alloc.quantity / product.lotSize! }
          : undefined),
      },
      independentAmount: {
        nominal: {
          value: alloc.independantAmountValue,
          type: alloc.independantAmountType,
          unit: alloc.independantAmountUnit,
        },
        valueDate: alloc.independantAmountValueDate,
      },
      independentAmountCurrency: alloc.independantAmountCurrencyUnit
        ? {
            unit: alloc.independantAmountCurrencyUnit,
            value: alloc.independantAmountCurrencyValue,
            type: 'CCY',
          }
        : undefined,
      confirmationMedia:
        alloc.media === 'MARKITWIRE'
          ? {
              discriminator: 'MARKITWIRE',
              mode: alloc.markitWireMode,
              counterpartyTrader: alloc.electronicMediaUser,
              eligibilityReason: alloc.eligibilityReason,
              markitWireReferenceId: alloc.markitWireReferenceId,
            }
          : alloc.media !== undefined
            ? {
                discriminator: 'PAPER',
                eligibilityReason: alloc.eligibilityReason,
                forcedReason: alloc.forcedReason,
                form: alloc.media ? alloc.media : undefined,
              }
            : undefined,
      broker: alloc.broker,
      externalReferenceId: alloc.externalReferenceId,
      secondaryInfo: alloc.secondaryInfo,
      stepInInfo: alloc.stepInInfo,
      salesCreditReferenceId: alloc.salesCreditReferenceId,
      markitWireEligible: alloc.markitWireEligible,
      updateJustificationType: mapToOnyxUpdateJustification(
        state,
        rfqId,
        alloc.updateJustificationLabel,
        selectors,
      ),
      confirmationMode: alloc.confirmationMode,
    }),
  );
}

function mapFromOnyxOtcAllocation(
  legId: string,
  {
    uuid,
    negotiatedSize,
    independentAmount,
    independentAmountCurrency,
    counterparty,
    confirmationMedia,
    broker,
    externalReferenceId,
    secondaryInfo,
    stepInInfo,
    salesCreditReferenceId,
    markitWireEligible,
    mcaEligible,
    updateJustificationType,
    confirmationMode,
  }: OnyxOtcAllocation,
  isCancelled: 'active' | 'cancelled',
): OtcAllocation {
  return {
    uuid,
    isCancelled: isCancelled === 'cancelled',
    legId,
    mcaEligible: mcaEligible ?? undefined,
    quantity: negotiatedSize?.quantity,
    notional: negotiatedSize?.notional,
    varUnit: negotiatedSize?.varUnit,
    sizeType: negotiatedSize?.sizeType,
    counterpartId: counterparty?.id,
    independantAmountValue: independentAmount?.nominal?.value,
    independantAmountUnit: independentAmount?.nominal?.unit,
    independantAmountType: independentAmount?.nominal?.type,
    independantAmountValueDate: independentAmount?.valueDate,
    independantAmountCurrencyValue: independentAmountCurrency?.value,
    independantAmountCurrencyUnit: independentAmountCurrency?.unit,
    ...(confirmationMedia?.discriminator === 'MARKITWIRE'
      ? {
          media: 'MARKITWIRE',
          markitWireMode: confirmationMedia.mode,
          electronicMediaUser: confirmationMedia.counterpartyTrader,
          eligibilityReason: confirmationMedia.eligibilityReason,
          markitWireReferenceId: confirmationMedia.markitWireReferenceId,
        }
      : confirmationMedia?.form === undefined
        ? {
            media: undefined,
            eligibilityReason: confirmationMedia?.eligibilityReason,
            eligibleMedia: confirmationMedia?.form === 'UNKNOWN' ? 'LONG_FORM' : undefined,
          }
        : {
            media: confirmationMedia?.form,
            forcedReason: confirmationMedia?.forcedReason,
            eligibilityReason: confirmationMedia.eligibilityReason,
          }),
    broker,
    externalReferenceId,
    secondaryInfo,
    stepInInfo,
    salesCreditReferenceId,
    markitWireEligible,
    updateJustificationLabel: mapFromOnyxUpdateJustification(updateJustificationType),
    confirmationMode,
  };
}
