import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import { makeContactApi } from '@/neos/business/rfq/actorsSetup/contact/contactApi';
import type { ContactApi } from '@/neos/business/rfq/actorsSetup/contact/contactEpics';
import type { Contact } from '@/neos/business/rfq/actorsSetup/contact/contactModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import type { ThunkEpic } from '@/bootstrap/epics.ts';

export function getTransfereeContactsEpic(http: SgmeHttp) {
  const contactApi: ContactApi = makeContactApi(http);
  return createRetrieveTransfereeContactsEpic(contactApi, actionCreators);
}

export function createRetrieveTransfereeContactsEpic(
  api: ContactApi,
  { common: { createAppCrashedAction }, neos: { transfereeContactsCrudActions } }: ActionCreators,
): ThunkEpic {
  return action$ =>
    action$.pipe(
      ofType('TRANSFEREE_CONTACT_LIST_REQUESTED'),
      mergeMap(({ salesValoEmail, counterpartId }) => {
        return api.getContacts(salesValoEmail, counterpartId).pipe(
          map((contacts: Contact[]) =>
            transfereeContactsCrudActions.upsert(counterpartId.toString(), contacts),
          ),
          catchError(error =>
            error && error.code === 400
              ? []
              : [createAppCrashedAction('transferee-contact-list-requested', error)],
          ),
        );
      }),
    );
}
