import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { getBrokerModel } from './getBrokerModel';

import styles from './StrategyDetails.module.scss';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type BrokerProps = {
  strategyId: string;
  isReadOnlyRfq: boolean;
};

export function Broker({ strategyId, isReadOnlyRfq }: BrokerProps) {
  const dispatch = useAppDispatch();
  const { broker, isBrokerFieldDisplayed } = useAppSelector(state =>
    getBrokerModel(state, strategyId, selectors),
  );

  function onBrokerChanged(broker: string | undefined) {
    dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { broker }));
  }

  if (!isBrokerFieldDisplayed) {
    return null;
  }

  return (
    <>
      <label>Broker</label>
      <NeosBlurInput
        className={`${styles['strategy-broker-input']} form-control-alt`}
        readOnly={isReadOnlyRfq}
        type="text"
        value={broker || ''}
        onBlur={event => onBrokerChanged(event.target.value || undefined)}
        data-e2e="strategy-details-broker"
      />
    </>
  );
}
