import type { Selectors } from '@/bootstrap/selectors';
import type { Status } from '../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface StrategiesModel {
  strategyIds: string[];
  displayedDeltaStrategyIds: string[];
  isFairPriceDisplayed: boolean;
  isVarVolDispersion: boolean;
}

export function getStrategiesModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): StrategiesModel {
  const { strategyIds, deltaHedgingStrategyIds, status } = selectors.getRfqData(state, rfqId);

  const isLegDetailsToggleOn = selectors.isLegDetailsToggleOn(state.ui, rfqId);
  const hasFairPrices = strategyIds.some(strategyId => {
    const { fairPriceId } = selectors.getStrategyData(state, strategyId);
    const fairPrice = fairPriceId !== undefined && selectors.getFairPrice(state, fairPriceId);
    return (
      fairPrice &&
      (fairPrice.mid !== undefined ||
        fairPrice.volatility !== undefined ||
        fairPrice.delta !== undefined ||
        fairPrice.vega !== undefined)
    );
  });

  const isFairPriceDisplayed = getIsFairPriceDisplayed(hasFairPrices, status, isLegDetailsToggleOn);

  const displayedDeltaStrategyIds = deltaHedgingStrategyIds.filter(
    strategyId => !selectors.isDeltaHedgingStrategyAdjusted(state, strategyId, selectors),
  );

  return {
    strategyIds,
    displayedDeltaStrategyIds,
    isFairPriceDisplayed,
    isVarVolDispersion: strategyIds.some(strategyId =>
      selectors.isVarVolDispersionStrategy(state, strategyId, selectors),
    ),
  };
}

export function getIsFairPriceDisplayed(
  hasFairPrices: boolean,
  status: Status,
  isLegDetailsToggleOn: boolean,
): boolean {
  return (
    hasFairPrices &&
    (!['PRICED', 'PRICE_PROPOSED', 'ORDER_ACCEPTED'].includes(status) || isLegDetailsToggleOn)
  );
}
