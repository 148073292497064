import type { Thunk } from '@/bootstrap/thunks.ts';
import type { ElsSectionName } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel.ts';

export function createToggleElsSectionThunk(section: ElsSectionName, enable: boolean): Thunk {
  return function toggleElsSectionThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createEnableElsSectionThunk, createDisableElsSectionThunk },
      },
    },
  ) {
    if (enable) {
      dispatch(createEnableElsSectionThunk(section));
    } else {
      dispatch(createDisableElsSectionThunk(section));
    }
  };
}
