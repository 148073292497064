import type { Selectors } from '@/bootstrap/selectors';
import type { PreconfirmationPreviewOwnProps } from './PreconfirmationPreview';
import type { AppState } from '@/bootstrap/store.ts';

export function getPreconfirmationPreviewModel(
  state: AppState,
  { rfqId, destination }: PreconfirmationPreviewOwnProps,
  selectors: Selectors,
) {
  const preview = selectors.getPreconfirmationEmailPreview(state, rfqId, destination);

  const validFrom = preview?.from ?? '';
  const validTo = preview?.to?.join('; ') ?? '';
  const validCc = preview?.cc?.join('; ') ?? '';
  const validBcc = preview?.bcc?.join('; ') ?? '';
  const validEmailBody = preview?.emailBody ?? '';

  return {
    from: validFrom,
    to: validTo,
    cc: validCc,
    bcc: validBcc,
    emailBody: validEmailBody,
  };
}
