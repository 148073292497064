import type { Selectors } from '@/bootstrap/selectors';
import { getMatchingInsertionToggleModel } from '@/neos/business/rfq/strategy/leg/matchingInsertionToggleSelector';
import { find } from '@/util/collectionHelper';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { deltaStockListedAllocationSelectors } from './deltaStockListedAllocation/deltaStockListedAllocationSelector';
import type { LegData, LegField } from './legData/legDataModel';
import type { ListedAllocation } from './legModel';
import { getLegSizes } from './legSizesSelector';
import { listedAllocationSelectors } from './listedAllocation/listedAllocationSelector';
import { otcAllocationSelectors } from './otcAllocation/otcAllocationSelector';
import {
  hasFutureMaturity,
  isCustomUnderlyingProduct,
  isDividendFutureProduct,
  isElsBasketProduct,
  isFutureProduct,
  isListedProduct,
  isOtcProduct,
  isSingleUnderlyingDerivativeProduct,
  isTotalReturnFutureProduct,
  type Product,
} from './product/productModel';
import { productSelectors } from './product/productSelector';
import type { AppState } from '@/bootstrap/store.ts';

export const legSelectors = {
  ...productSelectors,
  ...listedAllocationSelectors,
  ...otcAllocationSelectors,
  ...deltaStockListedAllocationSelectors,
  getLegData,
  getProduct,
  getLegDataByProductId,
  getUnderlyingOrRefIdOfLeg,
  getUnderlyingIdOrNameOrRefIdOfLeg,
  getLegMaturities,
  getLegSizeField,
  isListedLeg,
  isOtcLeg,
  isPrimaryLeg,
  getProductIdOfLeg,
  getLegUnderlyingLabel,
  getStrategyMasterUnderlyingLabel,
  getStrategyLeg,
  getLegProduct,
  getStrategyProduct,
  getLegPrimaryRfqId,
  getStrategyLegTaxCollection,
  getStrategyUnderlyingId,
  isSecondaryNovation,
  getAllocationTotalCommission,
  getRfqTotalCommission,
  getLegSizes,
  getMatchingInsertionToggleModel,
  getRfqLegsData,
  getStrategyMasterLegByRfqId,
};

export function getLegData({ legDataState }: AppState, legId: string): LegData {
  return legDataState[legId] || {};
}

export function getRfqLegsData(state: AppState, rfqId: string, selectors: Selectors): LegData[] {
  const rfqData = selectors.getRfqData(state, rfqId);
  return rfqData.strategyIds.flatMap(strategyId =>
    selectors.getStrategyLegsData(state, strategyId, selectors),
  );
}

export function getStrategyMasterLegByRfqId(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): LegData {
  const masterLeg = selectors.getRfqLegsData(state, rfqId, selectors).find(leg => leg.isMaster);
  if (!masterLeg) {
    throw Error(`Rfq Strategy has no master leg`);
  }

  return masterLeg;
}

function getLegPrimaryRfqId(appState: AppState, legId: string): string | undefined {
  const { legReferenceUuids } = getLegData(appState, legId);
  return legReferenceUuids?.[0]?.rfqId?.id;
}

function isListedLeg(appState: AppState, legId: string, selectors: Selectors): boolean {
  const { productId } = selectors.getLegData(appState, legId);
  const product = selectors.getProduct(appState, productId);
  return isListedProduct(product);
}

function isOtcLeg(appState: AppState, legId: string, selectors: Selectors): boolean {
  const { productId } = selectors.getLegData(appState, legId);
  const product = selectors.getProduct(appState, productId);
  return isOtcProduct(product);
}

function isPrimaryLeg(appState: AppState, legId: string, selectors: Selectors): boolean {
  const { activity, legReferenceUuids } = selectors.getLegData(appState, legId);
  return (
    activity === 'PRIMARY' ||
    legReferenceUuids === undefined ||
    (!!legReferenceUuids && legReferenceUuids?.length === 0)
  );
}

export function getLegDataByProductId(appState: AppState, productId: string): LegData | undefined {
  return find<LegData>(
    appState.legDataState,
    (_legData: LegData) => _legData.productId === productId,
  );
}

export function getProduct(appState: AppState, productId: string): Product {
  return appState.productState[productId] || {};
}

function getUnderlyingOrRefIdOfLeg(
  appState: AppState,
  legId: string,
  selectors: typeof legSelectors,
): string | undefined {
  const leg = selectors.getLegData(appState, legId);
  const product = selectors.getProduct(appState, leg.productId);
  if (isCustomUnderlyingProduct(product)) {
    throw new Error('cannot call getUnderlyingOrRefIdOfLeg on a custom underlying product');
  }

  if (isElsBasketProduct(product)) {
    return undefined;
  }

  return isSingleUnderlyingDerivativeProduct(product) ? product.underlyingId : product.refId;
}

function getLegUnderlyingLabel(
  state: AppState,
  legId: string,
  selectors: Selectors,
): string | undefined {
  const leg = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, leg.productId);
  if (isElsBasketProduct(product)) {
    return product.basketUnderlying.basketEliotID;
  }
  if (isCustomUnderlyingProduct(product)) {
    return product.underlyingName;
  }
  const underlyingId = isSingleUnderlyingDerivativeProduct(product)
    ? product.underlyingId
    : product.refId;

  if (underlyingId) {
    return selectors.getUnderlyingInfo(state, underlyingId)?.bloombergCode;
  }
}

function getStrategyMasterUnderlyingLabel(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): string | undefined {
  const { uuid: strategyLegId } = selectors.getStrategyMasterLeg(state, strategyId, selectors);
  return selectors.getLegUnderlyingLabel(state, strategyLegId, selectors);
}

function getUnderlyingIdOrNameOrRefIdOfLeg(
  appState: AppState,
  legId: string,
  selectors: typeof legSelectors,
): string | undefined {
  const leg = selectors.getLegData(appState, legId);
  const product = selectors.getProduct(appState, leg.productId);

  if (isElsBasketProduct(product)) {
    return undefined;
  }

  return isSingleUnderlyingDerivativeProduct(product)
    ? product.underlyingId
    : isCustomUnderlyingProduct(product)
      ? product.underlyingName
      : product.refId;
}

export function getLegMaturities(state: AppState, legId: string, selectors: Selectors): string[] {
  const { productId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);
  const underlyingId = isSingleUnderlyingDerivativeProduct(product)
    ? product.underlyingId
    : undefined;

  if (!underlyingId) {
    return [];
  }

  if (isFutureProduct(product)) {
    return selectors.getUnderlyingFutureMaturities(state, underlyingId).sort();
  }

  if (isDividendFutureProduct(product)) {
    return selectors.getUnderlyingDividendFutureMaturities(state, underlyingId).sort();
  }

  if (isTotalReturnFutureProduct(product)) {
    return selectors.getUnderlyingTotalReturnFutureMaturities(state, underlyingId).sort();
  }

  if (hasFutureMaturity(product)) {
    return selectors.getUnderlyingOptionFutureMaturities(state, underlyingId).sort();
  }

  return selectors.getUnderlyingOptionMaturities(state, underlyingId).sort();
}

export type SizeField = Extract<
  LegField,
  'notional' | 'localNotional' | 'numberOfLots' | 'varUnit'
>;

export function getLegSizeField(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): SizeField {
  const displayNegotiatedSize = selectors.getDisplayNegotiatedSize(state.ui, strategyId);
  switch (displayNegotiatedSize) {
    case 'NOTIONAL':
      return 'notional';
    case 'LOCAL_NOTIONAL':
      return 'localNotional';
    case 'VAR_UNIT':
      return 'varUnit';
    case 'QUANTITY':
      return 'numberOfLots';
    case 'NUMBER_OF_LOTS':
      return 'numberOfLots';
  }
}

export function getLegProduct(appState: AppState, legId: string, selectors: Selectors): Product {
  const { productId } = selectors.getLegData(appState, legId);
  return selectors.getProduct(appState, productId);
}

export function getProductIdOfLeg(
  appState: AppState,
  legId: string,
  { getLegData }: Selectors,
): string {
  const { productId } = getLegData(appState, legId);
  return productId;
}

export function getStrategyLeg({ legDataState }: AppState, strategyIdToMatch: string): LegData {
  return Object.keys(legDataState)
    .map(key => legDataState[key])
    .filter(({ strategyId }) => strategyId === strategyIdToMatch)[0];
}

function getStrategyProduct(state: AppState, strategyId: string, selectors: Selectors): Product {
  const { productId } = selectors.getStrategyLeg(state, strategyId);
  return selectors.getProduct(state, productId);
}

function getStrategyUnderlyingId(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): string | undefined {
  const { productId } = selectors.getStrategyLeg(state, strategyId);
  const product = selectors.getProduct(state, productId);
  return selectors.getUnderlyingId(product);
}

function getStrategyLegTaxCollection(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean | undefined {
  const { taxCollection } = selectors.getStrategyLeg(state, strategyId);
  return taxCollection;
}

function isSecondaryNovation(appState: AppState, legId: string, selectors: Selectors): boolean {
  const legData = selectors.getLegData(appState, legId);
  return legData.eventType?.toUpperCase().includes('NOVATION') ?? false;
}

function getAllocationTotalCommission(
  state: AppState,
  rfqId: string,
  allocation: ListedAllocation,
  selectors: Selectors,
): number | undefined {
  const { commission, commissionType, numberOfLots } = allocation;

  if (commission === undefined || commissionType === undefined) {
    return undefined;
  }

  if (commissionType === 'FIXED') {
    return commission;
  }

  if (numberOfLots === undefined) {
    return undefined;
  }

  if (commissionType === 'FIXED_PER_LOTS') {
    return formatNumber(commission * numberOfLots);
  }

  const product = selectors.getProduct(state, allocation.legId);
  const underlyingId = selectors.getUnderlyingId(product);

  const lotSize = product?.lotSize;
  const refSpot = underlyingId
    ? selectors.getReference(state, { rfqId, underlyingId })?.refSpot
    : undefined;

  if (lotSize === undefined) {
    return undefined;
  }

  if (commissionType === 'NOTIONAL') {
    if (refSpot === undefined) {
      return undefined;
    }
    return formatNumber((commission * numberOfLots * lotSize * refSpot) / 10000);
  }

  if (commissionType === 'PREMIUM') {
    const allocationExecution = selectors.executionSelectors.selectObjects(state.execution, {
      executionId: allocation.executionId,
    })[0];

    const executionLastPrice = allocationExecution?.lastPrice?.value;

    if (executionLastPrice === undefined) {
      return undefined;
    }

    return formatNumber((commission * numberOfLots * lotSize * executionLastPrice) / 10000);
  }

  return undefined;
}

function formatNumber(num: number): number {
  return parseFloat(num.toFixed(3));
}

function getRfqTotalCommission(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): { value: number; unit: string } | undefined {
  const allocations = selectors.getListedAllocationsByRfqId(state, rfqId);
  const allocationsTotalCommissions = allocations
    .map(allocation => {
      return selectors.getAllocationTotalCommission(state, rfqId, allocation, selectors);
    })
    .filter(isDefined);

  const executions = selectors.executionSelectors.selectObjects(state.execution, { rfqId });

  if (!executions.length) {
    return undefined;
  }

  const firstUnit = executions[0].lastPrice?.unit;
  const areAllUnitsEqual = executions.every(execution => execution.lastPrice?.unit === firstUnit);

  if (!allocationsTotalCommissions.length || !areAllUnitsEqual || !firstUnit) {
    return undefined;
  }

  const value = allocationsTotalCommissions.filter(isDefined).reduce((a, b) => a + b, 0);
  return { value, unit: firstUnit };
}
