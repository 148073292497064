import { listenToNeosNotifications } from '@/neos/business/notification';
import type { DataStreamData } from '@/util/notifications/signalrConnectionHelper';
import type { Observable } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';
import type { Notification } from '.';
import type { ExtendedDispatch, Thunks } from '../thunks';

import type { AppState } from '@/bootstrap/store.ts';

export function listenToAllNotifications(
  getState: () => AppState,
  dispatch: ExtendedDispatch,
  thunks: Thunks,
  dataStream$: Observable<DataStreamData> | undefined,
  reconnect$: Observable<any>,
) {
  if (dataStream$) {
    const rawNotification$ = dataStream$.pipe(
      map(({ data }) => data as Notification),
      share(),
    );

    const notification$ = rawNotification$?.pipe(
      filter(_ => getState().globalErrors.sessionActive),
    );

    listenToNeosNotifications(dispatch, thunks, notification$);
  }
  reconnect$.subscribe(() => dispatch(thunks.neos.createRequestResubscribeThunk()));
}
