import { neosActionCreators } from '@/neos/business/neosActionCreators';
import {
  type OtcAllocation,
  type PaperConfirmationMedia,
  otcAllocForcedReasonOptions,
  shouldShowForcedReason,
} from '@/neos/business/neosModel';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { mapRecordEntriesToSelectOptions } from '@/util/array/arrayUtils';
import type { FC } from 'react';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type OtcAllocForcedReasonProps = {
  allocation: OtcAllocation;
  rfqId: string;
  legId: string;
  shouldShowForcedReasonColumn: boolean;
};

export const OtcAllocForcedReason: FC<OtcAllocForcedReasonProps> = ({
  allocation,
  rfqId,
  legId,
  shouldShowForcedReasonColumn,
}) => {
  const dispatch = useAppDispatch();

  const onForcedReasonChanged = (
    allocationId: string,
    forcedReason?: PaperConfirmationMedia['forcedReason'],
  ) =>
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        { forcedReason },
      ),
    );

  if (!shouldShowForcedReasonColumn) {
    return null;
  }

  return (
    <NeosSelectWithAutocomplete
      data-e2e="otc-alloc-forced-reason"
      isReadOnly={!shouldShowForcedReason(allocation)}
      value={
        allocation.media === 'LONG_FORM' || allocation.media === 'SHORT_FORM'
          ? allocation.forcedReason
          : undefined
      }
      onChange={selected =>
        onForcedReasonChanged(
          allocation.uuid,
          selected?.value as PaperConfirmationMedia['forcedReason'],
        )
      }
      addEmptyOption
      options={mapRecordEntriesToSelectOptions(otcAllocForcedReasonOptions)}
    />
  );
};
