import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { OtcPreAlloc } from '@/neos/business/neosModel';
import { FlowDatePicker } from '@/neos/components/share/datePicker/FlowDatePicker';
import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { formatShort } from '@/util/date/dateFormatHelper';
import { parseISO } from 'date-fns';

type PreAllocIAValueDateProps = {
  preAlloc: OtcPreAlloc;
  rfqId: string;
  onPreAllocAIValueDateChanged: (independantAmountValueDate: string) => void;
};

export function PreAllocIAValueDate({
  preAlloc,
  rfqId,
  onPreAllocAIValueDateChanged,
}: PreAllocIAValueDateProps) {
  const date = preAlloc.independantAmountValueDate;
  const minDate = useAppSelector(state => selectors.getRfqData(state, rfqId)).tradeDate ?? '';

  return (
    <ErrorHighlight errorField="otcPreAllocIAValueDate" related={{ rfqId }}>
      <FlowDatePicker
        onChange={newDate => {
          if (newDate) {
            onPreAllocAIValueDateChanged(newDate);
          }
        }}
        maturities={[]}
        date={date ? formatShort(parseISO(date)) : ''}
        filterDate={date => (minDate ? date >= new Date(minDate) : true)}
      />
    </ErrorHighlight>
  );
}
