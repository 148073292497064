import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { isElsProduct } from '@/neos/business/neosModel';
import {
  type ResetType,
  resetTypeValues,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { If } from '@/neos/components/share/if/if';
import { Switch } from '@/neos/components/share/switch/Switch';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { FormSelect } from 'react-bootstrap';
import { BrokenPeriod } from '../BrokenPeriod';
import { ConventionDay } from '../ConventionDay';
import { GenerateFrom } from '../GenerateFrom';
import { RateReset } from '../RateReset';
import { DividendPaymentDateValues, FixedDayValues } from '../ScheduleModel';
import { getScheduleInputsFields } from '../getScheduleInputsFields';
import styles from './ElsSchedule.module.scss';

interface ScheduleInputsProps {
  rfqId: string;
  strategyId: string;
}

function Options() {
  const options = [];
  options.push(<option key={-6} value="" />);
  for (let i = -5; i <= 5; i++) {
    options.push(
      <option key={i} value={i}>
        {`${i} days`}
      </option>,
    );
  }
  return options;
}

function ColFiller({ length = 1 }: { length?: number }) {
  const cols = [];
  for (let i = 0; i++ < length; ) {
    cols.push(<span key={i} />);
  }
  return <>{cols}</>;
}

export function ELSScheduleInputs({ rfqId, strategyId }: ScheduleInputsProps) {
  const dispatch = useAppDispatch();
  const isReadonly = useAppSelector(appState =>
    selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors),
  );

  const {
    generateFrom,
    wRateResetOnEach,
    equityResetType,
    dividendPaymentDate,
    rateFixingOffset,
    derogateRateFixingOffset,
    onDerogateRateFixingOffsetChanged,
    onEquityResetTypeChanged,
    onWRateResetOnEachChanged,
    onDividendPaymentDateChanged,
    onRateFixingOffsetChanged,
  } = useAppSelector(state =>
    getScheduleInputsFields(rfqId, strategyId, state, selectors, actionCreators, thunks, dispatch),
  );

  const product = useAppSelector(state =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );
  const rateOvernightFeature = useAppSelector(state =>
    selectors.getFeature(state.featureState, { strategyId, type: 'RATE_OVERNIGHT' }),
  );

  const isTrs = isElsProduct(product) && product.elsType === 'TRS';

  const labelResetOnEach =
    generateFrom === 'FINAL_VALUATION_DATE' || generateFrom === 'STRIKE_DATE'
      ? 'W / Equity reset on each'
      : 'W / Rate reset on each';

  const isDerogateRateReadonly = rateOvernightFeature?.rateOvernight || isReadonly;

  return (
    <div
      className={`${styles['scheduleInputGrid']} mb-2 d-grid gap-1`}
      style={{ gridTemplateColumns: '0.7fr 1fr 50px 1fr 1fr' }}
    >
      <GenerateFrom rfqId={rfqId} strategyId={strategyId} />
      <label className="p-1">{labelResetOnEach}</label>
      <NeosSelect
        readOnly={isReadonly}
        value={wRateResetOnEach}
        onChange={onWRateResetOnEachChanged}
        data-e2e="schedule-rate-reset"
        addEmptyOption
        options={FixedDayValues}
      />
      <ColFiller />

      <label className="p-1">Equity Reset Type </label>
      <NeosSelect
        readOnly={isReadonly}
        value={equityResetType}
        onChange={onEquityResetTypeChanged}
        data-e2e="schedule-equity-reset-type"
        addEmptyOption
        options={Object.entries(resetTypeValues).map(([value, label]) => ({
          value: value as ResetType,
          label,
        }))}
      />
      <ConventionDay rfqId={rfqId} strategyId={strategyId} />
      <ColFiller />

      <RateReset rfqId={rfqId} strategyId={strategyId} />
      <BrokenPeriod rfqId={rfqId} strategyId={strategyId} />
      <ColFiller />

      <div className="d-flex justify-content-between align-items-center">
        <label className="p-1">Derogate Rate Fixing Offset</label>
        <SimpleNeosTooltip
          id="tooltip-derogateRateFixingOffset"
          message="Please refer to the Lookback Period field"
          type="info"
          disable={!rateOvernightFeature?.rateOvernight}
        >
          <Switch
            disabled={isDerogateRateReadonly}
            e2eId="derogateRateFixingOffset"
            checked={!!derogateRateFixingOffset}
            onChange={() => onDerogateRateFixingOffsetChanged(!derogateRateFixingOffset)}
          />
        </SimpleNeosTooltip>
      </div>
      <FormSelect
        readOnly={isReadonly}
        value={rateFixingOffset !== undefined ? rateFixingOffset : ''}
        onChange={event => {
          onRateFixingOffsetChanged(
            !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : undefined,
          );
        }}
        data-e2e="derogate-rate-fixing-offset"
      >
        <Options />
      </FormSelect>
      <ColFiller length={3} />

      <If condition={isTrs} fallback={<ColFiller length={2} />}>
        <label className="p-1">Div Pay Date</label>
        <NeosSelect
          readOnly={isReadonly}
          value={dividendPaymentDate}
          onChange={onDividendPaymentDateChanged}
          data-e2e="schedule-div-pay-date"
          addEmptyOption
          options={DividendPaymentDateValues}
        />
      </If>
      <ColFiller length={2} />
    </div>
  );
}
