import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  DeltaHedgingButtonComponent,
  type DeltaHedgingButtonDispatchProps,
  type DeltaHedgingButtonOwnProps,
  type DeltaHedgingButtonPropsFromState,
} from './DeltaHedgingButton';
import { isDeltaHedgingButtonEnabled } from './getDeltaHedgingButtonModel';
import type { AppState } from '@/bootstrap/store.ts';

export const DeltaHedgingButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeltaHedgingButtonComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: DeltaHedgingButtonOwnProps,
): DeltaHedgingButtonPropsFromState {
  const isEnabled = isDeltaHedgingButtonEnabled(state, rfqId, selectors);
  return { isEnabled };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: DeltaHedgingButtonOwnProps,
): DeltaHedgingButtonDispatchProps {
  return {
    onDeltaHedgingRequested: () => {
      dispatch(
        neosThunks.createRequestRfqDeltaHedgingThunk(rfqId),
        createLogAnalyticsAction('NEOS RFQ', 'Select Delta Hedge'),
      );
    },
  };
}
