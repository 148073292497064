import { useAppSelector } from '@/bootstrap/hooks';
import type { FC } from 'react';

import { selectors } from '@/bootstrap/selectors';
import {
  RequestPredealChecksComponent,
  type RequestPredealChecksOwnProps,
} from './RequestPredealChecks';
import { getRequestPredealChecksModel } from './getRequestPredealChecksModel';

export const RequestPredealChecks: FC<RequestPredealChecksOwnProps> = ownProps => {
  const stateProps = useAppSelector(state => ({
    model: getRequestPredealChecksModel(state, ownProps.rfqId, selectors),
  }));
  return <RequestPredealChecksComponent {...ownProps} {...stateProps} />;
};
