import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export interface QuotesAndHeaderCommonModel {
  areFairPricesDisplayed: boolean;
  areSalesPricesDisplayed: boolean;
  isTraderDeltaDisplayed: boolean;
  areElsPtmmmDisplayed: boolean;
  isMarkupDisplayed: boolean;
}

export function getQuotesAndHeaderCommonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): QuotesAndHeaderCommonModel {
  const { deltaToggle } = state.ui.userPreferences;
  const areFairPricesDisplayed = selectors.areFairPricesDisplayed(state, rfqId, selectors);
  const areElsPtmmmDisplayed = selectors.areElsPtmmmDisplayed(state, rfqId, selectors);

  const areSalesPricesDisplayed = selectors.areSalesPricesDisplayed(state.ui, rfqId, selectors);

  const isTraderDeltaDisplayed = selectors.isTraderDeltaDisplayed(
    deltaToggle,
    state,
    rfqId,
    selectors,
  );

  const isMarkupDisplayed = !selectors.isRfqInitiatedByTrader(state, rfqId);

  return {
    areFairPricesDisplayed,
    areElsPtmmmDisplayed,
    areSalesPricesDisplayed,
    isTraderDeltaDisplayed,
    isMarkupDisplayed,
  };
}
