import { memoize } from 'proxy-memoize';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { useDispatch } from 'react-redux';
import {
  PredealChecksModalComponent,
  type PredealChecksModalDispatchProps,
  type PredealChecksModalOwnProps,
} from './PredealChecksModal';
import { getPredealChecksModalModel } from './getPredealChecksModalModel';

import { selectors } from '@/bootstrap/selectors';
import { neosThunks } from '@/neos/business/thunks';
import { useAppSelector } from '@/bootstrap/hooks.ts';

export const PredealChecksModal = (ownProps: PredealChecksModalOwnProps) => {
  const stateProps = useAppSelector(
    memoize(appState => getPredealChecksModalModel(appState, ownProps.rfqId, selectors)),
  );
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = mapDispatchToProps(dispatch, ownProps);
  return <PredealChecksModalComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: PredealChecksModalOwnProps,
): PredealChecksModalDispatchProps {
  return {
    hideModal: () => {
      dispatch(neosThunks.createDerogatePredealChecksOnModalCloseThunk(rfqId));
      dispatch(
        neosActionCreators.rfqUiCrudActions.update(rfqId, { showPredealChecksModal: false }),
      );
    },
  };
}
