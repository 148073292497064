import { thunks } from '@/bootstrap/thunks';
import type { Els } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { ExportButton } from '@/neos/components/share/importExportClipboard/ExportButton.tsx';

type ElsBuyAndSellExportProps = {
  rfqId: string;
  product: Els;
};

export const ElsBuyAndSellExport = ({ rfqId, product }: ElsBuyAndSellExportProps) => {
  const dispatch = useAppDispatch();
  const isButtonDisabled = !product.equityHedge?.equityHedgeComponents.length;

  const handleExport = () => {
    dispatch(thunks.neos.createExportElsBuyAndSellCompositionThunk(rfqId, product.uuid));
  };

  return (
    <ExportButton
      handleExport={handleExport}
      isDisabled={isButtonDisabled}
      dataE2e="els-buy-and-sell-export"
    />
  );
};
