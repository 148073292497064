import type { NeosError } from '../../../ui/error/errorModel';
import { type ErrorableFieldsRelated, errorReceivedRelatedFieldFilter } from '../errorHandlerData';
import type { AllIds } from '../errorHandlerModel';
import { internalGetErrorId } from '../internal/errorIdGenerator';
import type { OnyxFieldError } from '../onyxErrorModel';

import type { Selectors } from '@/bootstrap/selectors';
import type { StrategyType } from '@/neos/business/services/fieldMatchers';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { chain, flatMap } from 'lodash';
import { getMatcherResults } from './erroFieldsMapper';
import type { ErrorFieldMatcherResult } from './errorMapperModel';
import type { AppState } from '@/bootstrap/store.ts';

export type NeosMappedError = { errorId: string } & NeosError;
export type MappedNeosErrors = NeosMappedError[];

export function mapFromOnyxErrors(
  state: AppState,
  rfqId: string,
  fieldErrors: OnyxFieldError[],
  selectors: Selectors,
): MappedNeosErrors {
  const matcherResults: ErrorFieldMatcherResult[] = getMatcherResults(fieldErrors);
  return mapFromMatcherResults(state, rfqId, matcherResults, selectors);
}

interface ErrorWithIds extends Pick<ErrorFieldMatcherResult, 'fieldName'> {
  strategyId: string | 'ALL' | undefined;
  deltaId: string | 'ALL' | undefined;
  legId: string | 'ALL' | undefined;
  errorMessages: string[];
}
export function mapFromMatcherResults(
  state: AppState,
  rfqId: string,
  matcherResults: ErrorFieldMatcherResult[],
  selectors: Selectors,
): NeosMappedError[] {
  const mappedErrors = flatMap(
    matcherResults,
    ({
      fieldName,
      errorMessage,
      groupResults: { strategyType = 'strategies', legIndex, strategyIndex, deltaIndex },
    }) => {
      if (strategyType !== 'strategies' && strategyType !== 'deltas') {
        throw new Error('strategyType should be strategies or deltas');
      }

      const deltaId = deltaIndex
        ? getDeltaIdFromIndex(state, rfqId, deltaIndex, selectors)
        : undefined;
      if (deltaIndex && !deltaId) {
        throw new Error('legIndex specified but strategyId not found');
      }

      const strategyId = strategyIndex
        ? getStrategyIdFromIndex(state, rfqId, strategyIndex, strategyType, selectors)
        : undefined;
      if (strategyIndex && !strategyId) {
        throw new Error('legIndex specified but strategyId not found');
      }

      const legIds: string[] | ['ALL'] =
        legIndex && strategyId
          ? getLegIdsFromIndex(state, rfqId, strategyId, legIndex, selectors)
          : [];
      const errorWithIds: ErrorWithIds = {
        fieldName,
        strategyId,
        deltaId,
        legId: undefined,
        errorMessages: [errorMessage],
      };

      const result: ErrorWithIds[] = legIds.length
        ? legIds.map(legId => ({ ...errorWithIds, legId }))
        : [errorWithIds];
      return result;
    },
  );

  const errorsOnStrategies = chain(mappedErrors)
    .map(({ strategyId }) => strategyId)
    .filter(isDefined)
    .uniqBy(strategyId => strategyId)
    .map(
      (strategyId: string): NeosMappedError => ({
        errorId: internalGetErrorId('strategy')({ strategyId }),
        isError: true,
        errorMessages: ['An error has occured.', 'Please select this strategy to see all errors.'],
      }),
    )
    .value();

  const errorsOnFields = mappedErrors.map(
    ({ strategyId, legId, deltaId, fieldName, errorMessages }) => {
      const relatedTo: Partial<AllIds> = {
        rfqId,
        strategyId,
        legId,
        deltaId,
      };
      const relatedToFiltered: ErrorableFieldsRelated = (
        errorReceivedRelatedFieldFilter[fieldName] as any
      )(relatedTo);

      const neosMappedError: NeosMappedError = {
        errorId: internalGetErrorId(fieldName)(relatedToFiltered),
        isError: true,
        errorMessages,
      };
      return neosMappedError;
    },
  );

  return errorsOnFields.concat(errorsOnStrategies);
}

function getDeltaIdFromIndex(
  state: AppState,
  rfqId: string,
  deltaIndex: string | 'ALL',
  selectors: Selectors,
): string {
  if (deltaIndex === 'ALL') {
    return 'ALL';
  }
  const { deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  return deltaHedgingStrategyIds[parseInt(deltaIndex, 10)];
}

function getStrategyIdFromIndex(
  state: AppState,
  rfqId: string,
  strategyIndex: string | 'ALL',
  strategyType: StrategyType,
  selectors: Selectors,
): string {
  if (strategyIndex === 'ALL') {
    return 'ALL';
  }
  const { strategyIds, deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  return (strategyType === 'strategies' ? strategyIds : deltaHedgingStrategyIds)[
    parseInt(strategyIndex, 10)
  ];
}

function getLegIdsFromIndex(
  state: AppState,
  rfqId: string,
  strategyId: string | 'ALL',
  legIndex: string | 'ALL',
  selectors: Selectors,
): string[] | ['ALL'] {
  if (legIndex === 'ALL') {
    return ['ALL'];
  }
  return strategyId === 'ALL'
    ? selectors
        .getRfqData(state, rfqId)
        .strategyIds.map(id => selectors.getStrategyData(state, id).legIds[parseInt(legIndex, 10)])
    : [selectors.getStrategyData(state, strategyId).legIds[parseInt(legIndex, 10)]];
}
