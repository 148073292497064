import { thunks } from '@/bootstrap/thunks.ts';
import { neosThunks } from '@/neos/business/thunks';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { PasteClipboard } from '@/neos/components/share/importExportClipboard/PasteClipboard.tsx';

export function ClsScheduleClipBoardPasteButton(props: { strategyId: string }) {
  const dispatch = useAppDispatch();

  function handleError(error: string) {
    dispatch(thunks.createErrorToasterThunk({ message: error }, undefined));
  }

  function handleClipboardData(importedData: unknown[]) {
    dispatch(neosThunks.createClsImportScheduleDataThunk(props.strategyId, importedData));
  }
  return (
    <PasteClipboard
      handleClipboardData={handleClipboardData}
      handleError={handleError}
      dataE2e="cls-import-schedule"
    />
  );
}
