import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { OtcAllocationKey } from '@/neos/business/rfq/strategy/leg/otcAllocation/otcAllocationModel';
import { FlowDatePicker } from '@/neos/components/share/datePicker/FlowDatePicker';
import { formatShort } from '@/util/date/dateFormatHelper';
import { parseISO } from 'date-fns';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function IAValueDate({ allocationKey }: { allocationKey: OtcAllocationKey }) {
  const dispatch = useAppDispatch();
  const date =
    useAppSelector(state => selectors.getAllocationValueDate(allocationKey, state)) ?? '';
  const minDate =
    useAppSelector(state => selectors.getRfqData(state, allocationKey.rfqId)).tradeDate ?? '';

  function onAIValueDateChanged(date: string, allocationKey: OtcAllocationKey) {
    dispatch(
      actionCreators.neos.otcAllocationCrudActions.update(allocationKey, {
        independantAmountValueDate: date,
      }),
    );
  }
  return (
    <FlowDatePicker
      onChange={date => {
        if (date) {
          onAIValueDateChanged(date, allocationKey);
        }
      }}
      maturities={[]}
      date={date ? formatShort(parseISO(date)) : ''}
      filterDate={date => (minDate ? date >= new Date(minDate) : true)}
    />
  );
}
