import type { Selectors } from '@/bootstrap/selectors';
import type { CommonSelectors } from '@/common/business/commonSelectors';
import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import type { OnyxUserCategory } from '../../../../common/business/commonModel';
import type { StatusLabel } from '../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface BlotterPanelInformationModel {
  displayTimezone: DisplayTimeZone;
  defaultFiltersByUser: BlotterFilterAgGrid;
}

export interface BlotterFilterAgGrid {
  statusLabel: FilterTypeAgGrid;
}

interface FilterTypeAgGrid {
  filterType: 'set';
  values: string[];
}

const salesUserFilter: StatusLabel[] = [
  'Captured',
  'Priced',
  'Price Proposed',
  'Order Received',
  'Order Accepted',
  'Delta Manually Executed',
  'Delta Executed',
  'Traded',
];

const tradersUserFilter: StatusLabel[] = [
  'Price Requested',
  'Being Priced',
  'Booking Requested',
  'Position Being Booked',
];

const viewOnlyUserFilter: StatusLabel[] = Array.from(
  new Set<StatusLabel>(['New', ...salesUserFilter, ...tradersUserFilter]),
);

const userFilterByRole: Record<OnyxUserCategory, StatusLabel[] | undefined> = {
  SALES: salesUserFilter,
  TRADER: tradersUserFilter,
  ADMIN: undefined,
  MIDDLE_OP: undefined,
  MIDDLE_SA: undefined,
  SALES_MANAGER: undefined,
  VIEW_ONLY: viewOnlyUserFilter,
};

export function getBlotterPanelInformationModel(
  state: AppState,
  selectors: Selectors,
): BlotterPanelInformationModel {
  const defaultFiltersByUser = createFilterListByRole(state, selectors);

  return {
    displayTimezone: selectors.getDisplayTimeZone(state.ui.userPreferences),
    defaultFiltersByUser,
  };
}

function createFilterListByRole(state: AppState, selectors: CommonSelectors): BlotterFilterAgGrid {
  const userRoles = selectors.getCategoriesForUser(state);
  const actifRole = userRoles.find(role => userFilterByRole[role]);

  return {
    statusLabel: {
      filterType: 'set',
      values: userFilterByRole[actifRole!] ?? [],
    },
  };
}
