import type { Thunk } from '@/bootstrap/thunks';
import type { CrudActionCreators } from '@/util/crudUtils';
import type { StrategyDefinition } from '../../../../../../neos/business/neosModel';
import { isSingleUnderlyingDerivativeProduct, type LegData } from '../legModel';
import {
  type CustomUnderlyingProduct,
  type Future,
  isCustomUnderlyingProduct,
  isFutureProduct,
  isOptionLike,
  isProductWithStrike,
  isProductWithUpperLowerStrike,
  isTotalReturnFutureProduct,
  type Listed,
  type Option,
  type Otc,
  type Product,
  type SingleUnderlyingDerivative,
  type StrikeProduct,
  type UpperLowerStrikeProduct,
} from '../product/productModel';
import type { AppState } from '@/bootstrap/store.ts';
import type { Action } from '@/bootstrap/actions.ts';

export function createApplyStrategyDefinitionSameValueToLegsThunk(strategyId: string): Thunk {
  return function applyStrategyDefinitionSameValueToLegsThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyDefinition, getStrategyData, getLegData, getProduct },
      actionCreators: {
        neos: { productCrudActions, legDataCrudActions },
      },
    },
  ) {
    const appState = getState();
    const { strategyType, legIds } = getStrategyData(appState, strategyId);

    const strategyDefinition = getStrategyDefinition(appState.referenceData, strategyType);
    if (!strategyDefinition || strategyDefinition.legs.length < 2) {
      return;
    }

    const { legs, legDataActions } = buildStrategyDefinitionSameValueLegUpdateActions(
      legIds,
      getLegData,
      appState,
      strategyDefinition,
      legDataCrudActions,
    );

    const productActions = buildStrategyDefinitionSameValueProductUpdateActions(
      legs,
      getProduct,
      appState,
      strategyDefinition,
      productCrudActions,
    );

    dispatch(...legDataActions, ...productActions);
  };
}

function buildStrategyDefinitionSameValueLegUpdateActions(
  legIds: string[],
  getLegData: (appState: AppState, legId: string) => LegData,
  appState: AppState,
  strategyDefinition: StrategyDefinition,
  legDataCrudActions: CrudActionCreators<LegData>,
) {
  const legs = legIds.map(legId => getLegData(appState, legId));
  const legPatch: Partial<LegData> = {};
  if (strategyDefinition.sameWeight) {
    legPatch.weight = legs[0].weight;
  }
  if (strategyDefinition.sameNegotiatedSize) {
    legPatch.numberOfLots = legs[0].numberOfLots;
    legPatch.notional = legs[0].notional;
  }

  const legDataActions = Object.keys(legPatch).length
    ? legIds.slice(1).map(legId => legDataCrudActions.update(legId, legPatch))
    : [];
  return { legs, legDataActions };
}

function buildStrategyDefinitionSameValueProductUpdateActions(
  legs: LegData[],
  getProduct: (appState: AppState, productId: string) => Product,
  appState: AppState,
  strategyDefinition: StrategyDefinition,
  productCrudActions: CrudActionCreators<Product>,
) {
  const products = legs.map(legData => getProduct(appState, legData.productId));

  const firstProduct = products[0];
  const productExceptTheFirst = products.slice(1);
  const futureActions = getFutureProductActions(
    firstProduct,
    productExceptTheFirst,
    productCrudActions,
  );

  const productActions: Action[] = [
    ...getCommonSubTypeProductActions(
      strategyDefinition,
      firstProduct,
      productExceptTheFirst,
      productCrudActions,
    ),
    ...getDerivativeProductActions(
      strategyDefinition,
      firstProduct,
      productExceptTheFirst,
      productCrudActions,
    ),
    ...getCustomUnderlyingProductActions(
      strategyDefinition,
      firstProduct,
      productExceptTheFirst,
      productCrudActions,
    ),
    ...getNegotiationModeActions(
      strategyDefinition,
      firstProduct,
      productExceptTheFirst,
      productCrudActions,
    ),
    ...getOptionProductActions(
      strategyDefinition,
      firstProduct,
      productExceptTheFirst,
      productCrudActions,
    ),
    ...getProductWithStrikeActions(
      strategyDefinition,
      firstProduct,
      productExceptTheFirst,
      productCrudActions,
    ),
    ...getProductWithUpperLowerStrikeActions(
      strategyDefinition,
      firstProduct,
      productExceptTheFirst,
      productCrudActions,
    ),
    ...futureActions,
  ];

  return productActions;
}

function getOptionProductActions(
  strategyDefinition: StrategyDefinition,
  firstProduct: Product,
  productExceptTheFirst: Product[],
  productCrudActions: CrudActionCreators<Product, string>,
): Action[] {
  if (isOptionLike(firstProduct)) {
    const patch: Partial<Option> = {};
    if (strategyDefinition.sameProductOptionStyle) {
      patch.style = firstProduct.style;
    }
    if (strategyDefinition.sameProductOptionType) {
      patch.type = firstProduct.type;
    }
    return productExceptTheFirst.map(({ uuid }) => productCrudActions.update(uuid, patch));
  }

  return [];
}

function getProductWithStrikeActions(
  strategyDefinition: StrategyDefinition,
  firstProduct: Product,
  productExceptTheFirst: Product[],
  productCrudActions: CrudActionCreators<Product, string>,
): Action[] {
  if (isProductWithStrike(firstProduct)) {
    const patch: Partial<StrikeProduct> = { strikeUnit: firstProduct.strikeUnit };
    if (strategyDefinition.sameProductStrike) {
      patch.strike = firstProduct.strike;
    }
    return productExceptTheFirst.map(({ uuid }) => productCrudActions.update(uuid, patch));
  }

  return [];
}

function getProductWithUpperLowerStrikeActions(
  strategyDefinition: StrategyDefinition,
  firstProduct: Product,
  productExceptTheFirst: Product[],
  productCrudActions: CrudActionCreators<Product, string>,
): Action[] {
  if (isProductWithUpperLowerStrike(firstProduct)) {
    const patch: Partial<UpperLowerStrikeProduct> = { strikeUnit: firstProduct.strikeUnit };
    if (strategyDefinition.sameProductStrike) {
      patch.upperStrike = firstProduct.upperStrike;
      patch.lowerStrike = firstProduct.lowerStrike;
    }
    return productExceptTheFirst.map(({ uuid }) => productCrudActions.update(uuid, patch));
  }

  return [];
}

function getFutureProductActions(
  firstProduct: Product,
  productExceptTheFirst: Product[],
  productCrudActions: CrudActionCreators<Product, string>,
): Action[] {
  if (isFutureProduct(firstProduct) || isTotalReturnFutureProduct(firstProduct)) {
    const patch: Partial<Future> = { pointValue: firstProduct.pointValue };
    return productExceptTheFirst.map(({ uuid }) => productCrudActions.update(uuid, patch));
  }

  return [];
}
function getNegotiationModeActions(
  strategyDefinition: StrategyDefinition,
  firstProduct: Product,
  productExceptTheFirst: Product[],
  productCrudActions: CrudActionCreators<Product, string>,
): Action[] {
  if (strategyDefinition.sameProductNegotiationMode) {
    const patch: Partial<Listed<Product>> | Partial<Otc<Product>> =
      firstProduct.negotiationMode === 'LISTED'
        ? {
            negotiationMode: firstProduct.negotiationMode,
            marketExchangeId: firstProduct.marketExchangeId,
            marketMicCode: firstProduct.marketMicCode,
            lotSize: firstProduct.lotSize,
          }
        : {
            negotiationMode: firstProduct.negotiationMode,
            lotSize: firstProduct.lotSize,
          };
    return productExceptTheFirst.map(({ uuid }) => productCrudActions.update(uuid, patch));
  }

  return [];
}

function getDerivativeProductActions(
  strategyDefinition: StrategyDefinition,
  firstProduct: Product,
  productExceptTheFirst: Product[],
  productCrudActions: CrudActionCreators<Product>,
): Action[] {
  if (isSingleUnderlyingDerivativeProduct(firstProduct)) {
    const patch: Partial<SingleUnderlyingDerivative> = {};
    if (strategyDefinition.sameProductUnderlying) {
      patch.underlyingId = firstProduct.underlyingId;
    }
    if (strategyDefinition.sameProductMaturity) {
      patch.maturity = firstProduct.maturity;
    }
    return productExceptTheFirst.map(({ uuid }) => productCrudActions.update(uuid, patch));
  }

  return [];
}

function getCustomUnderlyingProductActions(
  strategyDefinition: StrategyDefinition,
  firstProduct: Product,
  productExceptTheFirst: Product[],
  productCrudActions: CrudActionCreators<Product>,
): Action[] {
  if (isCustomUnderlyingProduct(firstProduct)) {
    const patch: Partial<CustomUnderlyingProduct> = {};
    if (strategyDefinition.sameProductUnderlying) {
      patch.underlyingName = firstProduct.underlyingName;
    }
    if (strategyDefinition.sameProductMaturity) {
      patch.maturity = firstProduct.maturity;
    }
    return productExceptTheFirst.map(({ uuid }) => productCrudActions.update(uuid, patch));
  }

  return [];
}

function getCommonSubTypeProductActions(
  strategyDefinition: StrategyDefinition,
  firstProduct: Product,
  productExceptTheFirst: Product[],
  productCrudActions: CrudActionCreators<Product>,
): Action[] {
  if (strategyDefinition.sameProductDeliveryType) {
    return productExceptTheFirst.map(({ uuid }) =>
      productCrudActions.update(uuid, { deliveryType: firstProduct.deliveryType }),
    );
  }

  return [];
}
