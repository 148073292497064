import type { Selectors } from '@/bootstrap/selectors';
import { flatMap, sortBy } from 'lodash';
import type { DeltaType } from '../../../../neos/business/neosModel';
import type { Hedge } from '../models';
import type { AppState } from '@/bootstrap/store.ts';

export function getSortedHedges(state: AppState, rfqId: string, selectors: Selectors): Hedge[] {
  const { getHedges, getNotAggregatedReferences } = selectors;
  const references = getNotAggregatedReferences(state, rfqId, selectors);
  return flatMap(references, ({ underlyingId }) => {
    const hedges = getHedges(state, { rfqId, underlyingId });
    return sortBy(hedges, ({ deltaType }) => deltaTypeComparator(deltaType));
  });
}

function deltaTypeComparator(deltaType: DeltaType | undefined): number {
  return deltaType === 'DELTA_EXCHANGE'
    ? 0
    : deltaType === 'DELTA_EXCHANGE_OTC'
      ? 1
      : deltaType === 'DELTA_ADJUSTED'
        ? 2
        : deltaType === 'RISK'
          ? 3
          : 4;
}
