import type { Thunk } from '@/bootstrap/thunks';
import type { StrategyDefinition } from '../../../../../../neos/business/neosModel';
import type { ClientPosition, LegData, LegField } from '../legModel';
import type { Action } from '@/bootstrap/actions.ts';

export function createUpdateLegThunk<T extends LegField>(
  strategyId: string,
  legId: string,
  legField: T,
  value: LegData[T],
): Thunk {
  return function updateLegThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyDefinition, getStrategyData },
      actionCreators: {
        neos: { legDataCrudActions },
      },
    },
  ) {
    const appState = getState();
    const { strategyType, legIds } = getStrategyData(appState, strategyId);
    const legStrategyDefinitionSameField = getLegStrategyDefinitionSameField(legField);
    const strategyDefinition = getStrategyDefinition(appState.referenceData, strategyType);
    const newValue: Partial<LegData> = { [legField]: value };
    const actions: Action[] = [];

    if (
      legStrategyDefinitionSameField !== undefined &&
      strategyDefinition[legStrategyDefinitionSameField]
    ) {
      actions.push(
        ...legIds.map(currentLegId => legDataCrudActions.update(currentLegId, newValue)),
      );
    } else {
      actions.push(legDataCrudActions.update(legId, newValue));
    }

    dispatch(actions);
  };
}

export function createUpdateClientPositionAndResetDependenciesThunk(
  rfqId: string,
  strategyId: string,
  legId: string,
  clientPosition: ClientPosition | undefined,
): Thunk {
  return function updateClientPositionThunk(dispatch, _, { thunks: { neos: nsThunk } }) {
    dispatch(
      nsThunk.createUpdateLegThunk(strategyId, legId, 'clientPosition', clientPosition),
      nsThunk.createRfqResetStrategyQuoteAndFairPricesThunk(rfqId, strategyId),
    );
  };
}

type LegStrategyDefinitionSameField = keyof Pick<
  StrategyDefinition,
  'sameWeight' | 'sameNegotiatedSize' | 'sameProductClientPosition'
>;

function getLegStrategyDefinitionSameField(
  legField: LegField,
): LegStrategyDefinitionSameField | undefined {
  switch (legField) {
    case 'numberOfLots':
    case 'notional':
    case 'localNotional':
    case 'notionalUnit':
    case 'varUnit':
    case 'sizeType':
      return 'sameNegotiatedSize';
    case 'weight':
      return 'sameWeight';
    case 'clientPosition':
      return 'sameProductClientPosition';
    default:
      return undefined;
  }
}
