import type { FromOnyxMappers } from '@/neos/business/mappers';
import { hedgeDomain } from './hedgeDomain';
import type { OnyxHedge } from './hedgeOnyxModel';
import type { Action } from '@/bootstrap/actions.ts';

export const hedgeActionCreators = {
  hedgeCrudActions: hedgeDomain.actions,
  createUpdateHedgesAction,
};

function createUpdateHedgesAction(
  rfqId: string,
  hedges: OnyxHedge[],
  { mapFromOnyxHedges }: FromOnyxMappers,
): Action[] {
  return mapFromOnyxHedges(hedges).map(hedge =>
    hedgeDomain.actions.upsert(
      { rfqId, underlyingId: hedge.underlyingId, deltaType: hedge.deltaType },
      hedge,
    ),
  );
}
