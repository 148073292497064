import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { neosActionCreators } from '@/neos/business/neosActionCreators.ts';
import type { FocusEventHandler } from 'react';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput.tsx';
import { toUpperCase } from '@/util/string/stringUtils.ts';

interface CounterPortfolioProps {
  rfqId: string;
  strategyId: string;
}

export function CounterpartPortfolio({ rfqId, strategyId }: CounterPortfolioProps) {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(state => selectors.getStrategyData(state, strategyId));
  const isElsInternal = useAppSelector(state =>
    selectors.isRfqInternalEls(state, rfqId, selectors),
  );

  const onMirrorPortfolioChanged = (mirrorPortfolioId: string | undefined) => {
    dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { mirrorPortfolioId }));
  };

  const onBlurEvent: FocusEventHandler<HTMLInputElement | undefined> = event => {
    const newValue = event.target.value !== '' ? event.target.value : undefined;
    onMirrorPortfolioChanged(newValue);
  };

  if (!isElsInternal) {
    return null;
  }

  return (
    <>
      <label>Counterpart Ptf</label>
      <NeosBlurInput
        className={`form-control-alt`}
        type="text"
        value={strategy.mirrorPortfolioId}
        onBlur={onBlurEvent}
        transform={toUpperCase}
        data-e2e="strategy-details-counterpart-portfolio"
      />
    </>
  );
}
