import type { Selectors } from '@/bootstrap/selectors';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { InfoBadgeValue } from '../TabTitleBadge';
import type { TabTitleStateProps } from './TabTitle';
import type { AppState } from '@/bootstrap/store.ts';

export function getBlotterTabTitleModel(state: AppState, selectors: Selectors): TabTitleStateProps {
  const { getSelectedTab, isBlotterPinned, getTabs } = selectors;
  const selectedTab = getSelectedTab(state.ui.tabManagement);
  const tabsCount = getTabs(state.ui.tabManagement).length;

  const { pendingBlotterRfqNumber, pendingTradeWebBlotterRfqNumber } = getPendingBlotterRfq(
    state,
    selectedTab,
    selectors,
  );

  const badgeToolTipText = `${pendingBlotterRfqNumber} notifs received${
    pendingTradeWebBlotterRfqNumber ? ` (incl. ${pendingTradeWebBlotterRfqNumber} from MDP)` : ''
  }`;
  const infoBadgeValue: InfoBadgeValue = {
    badgeText: pendingBlotterRfqNumber.toString(),
    badgeColorClassName: pendingTradeWebBlotterRfqNumber ? 'bg-success' : 'bg-info',
    badgeToolTipText,
  };
  const isPinned = isBlotterPinned(state.ui.blotter);
  return {
    isBadgeDisplayed: !!pendingBlotterRfqNumber,
    infoBadgeValue,
    active: selectedTab === 'BLOTTER',
    isPinned,
    isAlertIconDisplayed: false,
    isLoading: false,
    label: [{ text: 'Blotter', className: 'fw-bold lead' }],
    tabsCount,
  };
}

function getPendingBlotterRfq(
  state: AppState,
  selectedTab: string,
  { getPendingRfqIds, getBlotterRfqs }: Selectors,
) {
  if (selectedTab === 'BLOTTER') {
    return {
      pendingBlotterRfqNumber: 0,
      pendingTradeWebBlotterRfqNumber: 0,
    };
  }

  const ids = getPendingRfqIds(state.ui.blotter);
  const blotterRfqs = getBlotterRfqs(state.blotter);

  const pendingTradeWebBlotterRfqNumber = ids
    .map(id => blotterRfqs.find(({ uuid }) => uuid === id))
    .filter(isDefined)
    .filter(rfq => rfq.source !== 'VOICE').length;

  return {
    pendingBlotterRfqNumber: ids.length,
    pendingTradeWebBlotterRfqNumber,
  };
}
