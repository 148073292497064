import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { LegLinkCellsComponent, type LegLinkCellsOwnProps } from './LegLinkCells';
import { getLegLinkCellsModel } from './getLegLinkCellsModel';

export const LegLinkCells = (ownProps: LegLinkCellsOwnProps) => {
  const stateProps = useAppSelector(state => {
    return getLegLinkCellsModel(state, ownProps.rfqId, ownProps.strategyId, selectors);
  });

  return <LegLinkCellsComponent {...ownProps} {...stateProps} />;
};
