import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import type { OnyxElectronicMediaUser } from './electronicMediaUserModel';
import { makeElectronicMediaUsersApi } from './electronicMediaUsersApi';

export interface ElectronicMediaUsersApi {
  getElectronicMediaUsers: (counterpartId: number) => Observable<OnyxElectronicMediaUser[]>;
}

export function getElectronicMediaUsersEpic(http: SgmeHttp): ThunkEpic {
  const api = makeElectronicMediaUsersApi(http);
  return createElectronicMediaUsersEpic(api, actionCreators);
}

export function createElectronicMediaUsersEpic(
  api: ElectronicMediaUsersApi,
  { neos: { electronicMediaUsersActionCreators } }: ActionCreators,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('ELECTRONIC_MEDIA_USERS_REQUESTED'),
      filter(
        ({ counterpartId }) => !state$.value.electronicMediaUsersState[counterpartId.toString()],
      ),
      mergeMap(({ counterpartId }) => {
        return api.getElectronicMediaUsers(counterpartId).pipe(
          mergeMap(users => [
            electronicMediaUsersActionCreators.createElectronicMediaUsersReceivedAction(
              counterpartId.toString(),
              users,
            ),
          ]),
          catchError(_ => []),
        );
      }),
    );
}
