import type { Selectors } from '@/bootstrap/selectors';
import { getSizeUnit } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/sizeCells/getSizeUnit';
import type { Field } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/fieldsEnablingConditions';
import type { RfqStrategyData } from '../strategyModel';
import type { LegData } from './legModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface SizeInfo extends Field<number | undefined> {
  isStrategySize: boolean;
  isValidStrategySize: boolean;
  isListedStrategy: boolean;
  unit: string;
}

interface LegParameter {
  data: LegData;
  isStrategySize: false;
}

interface StrategyParameter {
  data: RfqStrategyData;
  isStrategySize: true;
}

type Parameters = LegParameter | StrategyParameter;

export function getLegSizes(
  state: AppState,
  {
    rfqId,
    strategyId,
  }: {
    rfqId: string;
    strategyId: string;
  },
  selectors: Selectors,
): SizeInfo[] {
  const strategyData = selectors.getStrategyData(state, strategyId);
  const { sameNegotiatedSize, ratio } = selectors.getStrategyDefinition(
    state.referenceData,
    strategyData.strategyType,
  );
  const { legsData, hasACompositionLeg } = selectors.getStrategyLegsOrIndexLegData(
    state,
    strategyId,
    selectors,
  );
  const sizeField = selectors.getLegSizeField(state, strategyId, selectors);
  const uiSizeType = selectors.getDisplayNegotiatedSize(state.ui, strategyId);

  return getSizesFieldsArray();

  function getSizesFieldsArray(): SizeInfo[] {
    if (sameNegotiatedSize) {
      const masterLegData = selectors.getStrategyMasterLeg(state, strategyId, selectors);
      return [
        getSizeField({
          data: masterLegData,
          isStrategySize: false,
        }),
      ];
    }

    const compositionSize = getCompositionSize();

    const consistentLegsDataSizesField = legsData.map(legData =>
      getSizeField({ data: legData, isStrategySize: false }),
    );

    return [...consistentLegsDataSizesField, ...compositionSize];
  }

  function getCompositionSize(): SizeInfo[] {
    if (hasACompositionLeg && strategyData.scope === 'RFQ') {
      return [
        getSizeField({
          data: strategyData,
          isStrategySize: true,
        }),
      ];
    }

    return [];
  }

  function getSizeField(parameter: Parameters): SizeInfo {
    const { data, isStrategySize } = parameter;
    const { uuid, notionalUnit = '', localNotionalUnit = '' } = data;

    const isEditableOnSecondary = selectors.isSecondaryOrMixedRfq(state, rfqId);
    const sizesFieldAreEnabled = getIsStrategyDefinitionFieldEditable(
      state,
      rfqId,
      strategyId,
      selectors,
      ['RFQ_EDITABLE', 'STRATEGY_EDITABLE', 'UNDERLYING_SET'],
    );
    const isElsBasket = selectors.isRfqElsBasket(state, rfqId, selectors);

    const isCompatibleStrategy = ratio || (!parameter.isStrategySize && parameter.data.isMaster);
    const isEditable =
      !isElsBasket && (isEditableOnSecondary || (sizesFieldAreEnabled && isCompatibleStrategy));
    const isListedStrategy = selectors.isListedStrategy(state, strategyId, selectors);
    const isValidStrategySize = isEditable
      ? selectors.isValidStrategySize(state, strategyId, selectors)
      : true;
    return {
      id: uuid,
      value: data[sizeField],
      isEditable,
      isStrategySize,
      unit: getSizeUnit(uiSizeType, { notionalUnit, localNotionalUnit }),
      isListedStrategy,
      isValidStrategySize,
    };
  }
}
