import type { Selectors } from '@/bootstrap/selectors';
import type { NegotiationMode } from '../../../../../neos/business/neosModel';
import type { NegotiationModeShortcutsStateProps } from './NegotiationMode';
import type { AppState } from '@/bootstrap/store.ts';

export function getNegotiationModeModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): NegotiationModeShortcutsStateProps {
  return {
    otcDisabled: areAllStrategiesReadOnlyNegociationMode(state, rfqId, 'OTC', selectors),
    listedDisabled: areAllStrategiesReadOnlyNegociationMode(state, rfqId, 'LISTED', selectors),
  };
}

export function areAllStrategiesReadOnlyNegociationMode(
  state: AppState,
  rfqId: string,
  negMode: NegotiationMode,
  selectors: Selectors,
) {
  const { strategyIds } = selectors.getRfqData(state, rfqId);
  return strategyIds.every(strategyId => {
    const products = selectors.getStrategyProducts(state, strategyId, selectors);
    const allNegModeNoChange = products.every(({ negotiationMode }) => {
      return negotiationMode === negMode;
    });

    const { strategyType } = selectors.getStrategyData(state, strategyId);
    const { readOnlyProductNegotiationMode } = selectors.getStrategyDefinition(
      state.referenceData,
      strategyType,
    );
    return allNegModeNoChange || readOnlyProductNegotiationMode;
  });
}
