import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { createInfoToasterThunk } from '@/bootstrap/toasterThunks/thunks';
import { useDispatch } from 'react-redux';
import { If } from '../../share/if/if';
import { NeosResizableBox } from '../../share/neosResizableBox/NeosResizableBox';
import { SalesCredit } from '../totals/totalSalesMargin/SalesCredit';
import { addBorder, type CommonResizableProps } from './PostNego';
import { TotalRfqLegsCommission } from './TotalRfqLegsCommission';
import { getPostNegoModel } from './getRecapModel';
import { useAppSelector } from '@/bootstrap/hooks';

type RecapeProps = CommonResizableProps;

export const Recap = (props: RecapeProps) => {
  const { tradeRecap, tradeRecapLinesNumber, deltaSummaries, isTotalSCDisplayed } = useAppSelector(
    state => getPostNegoModel(state, props.rfqId, selectors),
  );
  const dispatch = useDispatch<ExtendedDispatch>();

  const onCopy = (text: string) => {
    services.copyToClipboard(text, {
      onSuccess: () => dispatch(createInfoToasterThunk({ message: 'Copied!', dismissAfter: 1500 })),
    });
  };

  return (
    <div className="d-flex">
      <NeosResizableBox
        width={props.width}
        height={Infinity}
        resizeHandles={['e']}
        axis="x"
        minConstraints={[window.innerWidth * 0.25, Infinity]}
        maxConstraints={[window.innerWidth * 0.75, Infinity]}
        onResize={(_, d) => props.onResize(d.size.width)}
        isResizable={props.isDeltaPresent}
      >
        <div
          style={{
            gridTemplateColumns: '1fr auto auto',
          }}
          className={`${addBorder(props.isDeltaPresent)} px-1 d-grid gap-2`}
        >
          <div className="d-flex flex-column">
            <div className="d-flex mb-2 gap-2">
              <label>Trade Recap</label>
              <button className="btn btn-link btn-sm btn-icon" onClick={() => onCopy(tradeRecap)}>
                <i className="icon icon-xs">content_copy</i>
              </button>
            </div>
            <textarea
              className="form-control"
              value={tradeRecap}
              disabled={true}
              rows={tradeRecapLinesNumber}
            />
          </div>
          <If condition={isTotalSCDisplayed}>
            <div className="border-start border-md ps-2">
              <SalesCredit rfqId={props.rfqId} />
            </div>
          </If>
          <TotalRfqLegsCommission rfqId={props.rfqId} />
        </div>
      </NeosResizableBox>

      <If condition={props.isDeltaPresent}>
        <div className="px-1 overflow-auto">
          <div className="d-flex mb-2 gap-2">
            <label>Delta Summary</label>
            <button
              className="btn btn-link btn-sm btn-icon"
              onClick={() => onCopy(deltaSummaries.join('\n'))}
            >
              <i className="icon icon-xs">content_copy</i>
            </button>
          </div>
          {deltaSummaries.map((deltaSummary, index) => (
            <div key={`${deltaSummary}-${index}`}>{deltaSummary}</div>
          ))}
        </div>
      </If>
    </div>
  );
};
