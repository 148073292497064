import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { actionCreators } from '@/bootstrap/actions.ts';
import type { DragEventHandler, PropsWithChildren } from 'react';

export function DropFileWrapper(
  props: PropsWithChildren<{ handleDrop: (files: FileList) => void }>,
) {
  const dispatch = useAppDispatch();
  const isUserDraggingFile = useAppSelector(selectors.selectIsUserDraggingFile);

  const handleDrop: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    props.handleDrop(event?.dataTransfer?.files);
    dispatch(actionCreators.neos.updateUserIsDraggingFile(false));
  };

  return isUserDraggingFile ? (
    <div onDrop={handleDrop} className="file-input file-input-sm px-2" style={{ zIndex: 9999 }}>
      <div className="file-input-corners"></div>
      <em className="file-input-icon icon icon-lg">attach_file</em>
      <p className="file-input-text">Drag your file to this area to upload</p>
      <input className="d-none" type="file" name="fileInput" id="fileInput" />
      <div className="file-input-corners"></div>
    </div>
  ) : (
    props.children
  );
}
