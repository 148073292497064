import type { Selectors } from '@/bootstrap/selectors';
import type { Product } from '../../../../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getFieldRelevantProducts(
  state: AppState,
  strategyId: string,
  sameProductField: boolean,
  selectors: Selectors,
): Product[] {
  if (sameProductField) {
    return [selectors.getStrategyMasterProduct(state, strategyId, selectors)];
  }

  const { legsData } = selectors.getStrategyLegsOrIndexLegData(state, strategyId, selectors);

  return legsData.map(legData => selectors.getLegProduct(state, legData.uuid, selectors));
}
