import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export function AreValidAllocExecSizes(
  strategyIds: string[],
  rfqId: string,
  state: AppState,
  selectors: Selectors,
): boolean {
  return strategyIds
    .map(stratId => {
      const { legIds } = selectors.getStrategyData(state, stratId);

      const executions = legIds.map(legId =>
        selectors.listedAllocationSelectors.selectKeys(state.listedAllocationState, {
          legId,
        }),
      );
      const [allocation] = executions.map(([allocation]) =>
        selectors.listedAllocationSelectors
          .selectObjects(state.listedAllocationState, {
            executionId: allocation?.executionId,
          })
          .find(({ legId }) => legIds.includes(legId)),
      );
      const legId = allocation?.legId ? allocation?.legId : '';
      const order = selectors.getOrderByLegId(state.orderData, rfqId, legId)?.uuid;
      const orderId = order ? order : '';
      const executionId = allocation?.executionId ? allocation?.executionId : '';
      return selectors.isValidListedAllocExecStrategySize(
        state,
        { rfqId, legId, orderId, executionId },
        selectors,
      );
    })
    .every(isValidAllocExecStrategySize => isValidAllocExecStrategySize);
}
