import type { Selectors } from '@/bootstrap/selectors';
import type { AllocationsModel } from '@/neos/components/rfq/postNego/allocs/getAllocationsModel';
import type { AppState } from '@/bootstrap/store.ts';

export type TriggerMatchingButtonModel = {
  isDisplayed: boolean;
};

export function getTriggerMatchingButtonModel(
  state: AppState,
  rfqId: string,
  legs: Partial<AllocationsModel>,
  isDelta: boolean,
  selectors: Selectors,
): TriggerMatchingButtonModel {
  const matchingInsertionButtonModel = selectors.getMatchingInsertionToggleModel(
    state,
    rfqId,
    legs,
    isDelta,
    selectors,
  );
  const { status } = selectors.getRfqData(state, rfqId);
  const orders = selectors.getOrders(state.orderData, rfqId);

  const isStatusBookingRequestedOrPreTradeBookingRequested =
    status === 'BOOKING_REQUESTED' || status === 'PRE_TRADE_BOOKING_REQUESTED';

  const isUserTrader = selectors.isTrader(state);

  const hasOneMatchingOrder = !!orders?.some(order => order.matching);
  const hasOneManualOrder = !!orders?.some(order => order.manual);

  const executions = selectors.executionSelectors.selectObjects(state.execution, {
    rfqId,
  });
  const atLeastOneExecutionWithNoBookingId = executions.some(execution => {
    const executionBookingInfo = selectors.getExecutionBookingStep(state, rfqId, execution.uuid);
    return (
      executionBookingInfo?.bookingId === undefined && execution.externalExecId?.id === undefined
    );
  });

  const isDisplayed =
    isStatusBookingRequestedOrPreTradeBookingRequested &&
    isUserTrader &&
    hasOneMatchingOrder &&
    hasOneManualOrder &&
    atLeastOneExecutionWithNoBookingId &&
    matchingInsertionButtonModel.isDisplayed &&
    (isDelta ? !matchingInsertionButtonModel.areButtonsInSync : true);
  return { isDisplayed };
}
