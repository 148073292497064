import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { createStrategySelectedAction } from '@/neos/business/ui/selectedStrategy/selectedStrategyActionCreators';
import { getStrategyOptionsIndicatorsModel } from '@/neos/components/rfq/strategies/strategy/strategyOptionsIndicators/getStrategyOptionsIndicatorsModel';
import { BookingStepStatusLabel } from './bookingStepStatus/BookingStepStatusLabel';
import { StrategyActionsButton } from './strategyActionsButton';

export interface StrategyOptionsIndicatorsProps {
  rfqId: string;
  strategyId: string;
}

export function StrategyOptionsIndicators(props: StrategyOptionsIndicatorsProps) {
  const { rfqId, strategyId } = props;

  const model = useAppSelector(state =>
    getStrategyOptionsIndicatorsModel(state, rfqId, strategyId, selectors),
  );
  const dispatch = useAppDispatch();

  const onStrategySelected = (isSelectedStrategy: boolean) => {
    if (!isSelectedStrategy) {
      dispatch(createStrategySelectedAction(rfqId, strategyId));
    }
  };
  return (
    <div
      className="d-flex align-items-baseline me-1"
      onFocus={() => onStrategySelected(model.isSelectedStrategy)}
      onClick={() => onStrategySelected(model.isSelectedStrategy)}
    >
      {model.hasBookingSteps ? (
        <BookingStepStatusLabel
          rfqId={rfqId}
          strategyId={strategyId}
          onFocus={() => onStrategySelected(model.isSelectedStrategy)}
        />
      ) : (
        <StrategyActionsButton
          rfqId={rfqId}
          strategyId={strategyId}
          isMasterStrategy={model.isMasterStrategy}
          isSelectedStrategy={model.isSelectedStrategy}
          onFocus={() => onStrategySelected(model.isSelectedStrategy)}
        />
      )}
    </div>
  );
}
