import type { Selectors } from '@/bootstrap/selectors';
import type { ToOnyxMappers } from '@/neos/business/mappers';
import { hasDiscriminator } from '@/neos/business/rfq/strategy/feature/hasDiscriminator';
import type { WithExtraProperty } from '@/neos/business/rfq/strategy/feature/withExtraProperty';
import type {
  Barrier,
  BarriersFeature,
  NeosBarrierType,
} from '../../../../../../neos/business/neosModel';
import type {
  OnyxBarrier,
  OnyxBarrierActivationType,
  OnyxBarrierType,
  OnyxBarriers,
  OnyxFeature,
  OnyxProductFeaturesFields,
  OnyxProductUnderlying,
} from '../../../../../../neos/business/neosOnyxModel';
import type { AppState } from '@/bootstrap/store.ts';

interface BarrierTypeMapping {
  type: OnyxBarrierType | undefined;
  activationType: OnyxBarrierActivationType | undefined;
  label: NeosBarrierType;
}

const barrierTypeValues: BarrierTypeMapping[] = [
  {
    type: 'KNOCK_IN',
    activationType: 'DOWN',
    label: 'KI Down',
  },
  {
    type: 'KNOCK_IN',
    activationType: 'UP',
    label: 'KI Up',
  },
  {
    type: 'KNOCK_OUT',
    activationType: 'DOWN',
    label: 'KO Down',
  },
  {
    type: 'KNOCK_OUT',
    activationType: 'UP',
    label: 'KO Up',
  },
];

export function getNeosBarrierTypeByProperties(
  type: OnyxBarrierType | undefined,
  activationType: OnyxBarrierActivationType | undefined,
): NeosBarrierType | undefined {
  if (!type && !activationType) {
    return undefined;
  }

  const result = barrierTypeValues.find(
    elem => elem.type === type && elem.activationType === activationType,
  );
  if (!result) {
    throw new Error(
      `Cannot find label associated with barrierType: ${type} & activationType: ${activationType}`,
    );
  }
  return result.label;
}

export function getBarrierPropertiesByNeosBarrierType(barrierLabel: NeosBarrierType | undefined) {
  if (!barrierLabel) {
    return {
      type: undefined,
      activationType: undefined,
    };
  }

  const result = barrierTypeValues.find(elem => elem.label === barrierLabel);
  if (!result) {
    throw new Error(`Cannot find barrier properties for label: ${barrierLabel}`);
  }
  return {
    type: result.type,
    activationType: result.activationType,
  };
}

function mapSingleBarrier(onyxBarrierProps: OnyxBarrier): Barrier {
  const {
    type,
    activationType,
    allMustHit,
    limitObservationType,
    limitStrike,
    maturity,
    strictLimit,
    underlying,
    gap,
    rebate,
    limitWindowDate,
  } = onyxBarrierProps;

  const commonBarrier = {
    neosBarrierType: getNeosBarrierTypeByProperties(type, activationType),
    allMustHit,
    limitObservationType,
    limitStrikeUnit: limitStrike?.unit,
    limitStrikeValue: limitStrike?.value,
    limitStrikeUnitType: limitStrike?.type,
    maturity,
    strictLimit,
    rebate,
    gap,
    limitWindowDate,
  };

  return underlying?.type === 'CUSTOM'
    ? { underlyingType: 'CUSTOM', underlyingName: underlying?.name, ...commonBarrier }
    : { underlyingType: 'NON_CUSTOM', underlyingId: underlying?.id, ...commonBarrier };
}

export function mapFromOnyxExtraFeaturesToBarriers(
  extraFeatures: OnyxFeature[],
  strategyId: string,
): BarriersFeature | undefined {
  const barriersFeature: OnyxBarriers | undefined = extraFeatures.find(
    hasDiscriminator<OnyxBarriers>('BARRIER_CONTAINER'),
  );
  if (barriersFeature) {
    const barriers: OnyxBarrier[] = barriersFeature.barriers || [];

    return {
      strategyId,
      type: 'BARRIERS',
      barriers: barriers.map(mapSingleBarrier),
    };
  }
  return undefined;
}

export function mapToOnyxBarriers(
  state: AppState,
  feature: WithExtraProperty<BarriersFeature>,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxProductFeaturesFields {
  if (!feature.isExtra) {
    return {
      barriers: feature.barriers.map(mapSingleBarrier),
    };
  }

  return {
    extraFeatures: [
      {
        discriminator: 'BARRIER_CONTAINER',
        barriers: feature.barriers.map(mapSingleBarrier),
      },
    ],
  };

  function mapSingleBarrier(barrier: Barrier) {
    const {
      neosBarrierType,
      allMustHit,
      limitObservationType,
      limitStrikeUnit,
      limitStrikeValue,
      limitStrikeUnitType,
      maturity,
      maturityTenor,
      strictLimit,
      limitWindowDate,
      rebate,
      gap,
    } = barrier;
    const underlying: OnyxProductUnderlying | undefined =
      barrier.underlyingType === 'NON_CUSTOM'
        ? mappers.mapToOnyxUnderlying(state, barrier.underlyingId, selectors)
        : barrier.underlyingName
          ? { type: 'CUSTOM', name: barrier.underlyingName }
          : undefined;
    return {
      ...getBarrierPropertiesByNeosBarrierType(neosBarrierType),
      allMustHit,
      limitObservationType,
      limitStrike: {
        unit: limitStrikeUnit,
        value: limitStrikeValue,
        type: limitStrikeUnitType,
      },
      maturity,
      strictLimit,
      underlying,
      maturityTenor,
      limitWindowDate,
      rebate,
      gap,
    };
  }
}
