import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { AppState } from '@/bootstrap/store.ts';

export function createDispatchIfBeforeOrderAcceptedThunk(
  rfqId: string,
  ifAction: Dispatchable,
  elseAction?: Dispatchable,
): Thunk {
  return function dispatchIfBeforeOrderAcceptedThunk(dispatch, getState, { selectors }) {
    const state: AppState = getState();
    const isBeforeOrderAccepted: boolean = selectors.isStatusStrictlyBeforeOrderAccepted(
      state,
      rfqId,
      selectors,
    );
    const toggleIsActivated: boolean = selectors.isFeatureToggleEnabled(
      state,
      'onyx.workflow.norecompute.after.priceproposed',
    );
    if (isBeforeOrderAccepted || !toggleIsActivated) {
      dispatch(ifAction);
    } else if (elseAction) {
      dispatch(elseAction);
    }
  };
}
