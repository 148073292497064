import { importFromExcelClipboardData } from '@/util/excel/excel.ts';

interface PasteClipboardProps {
  handleClipboardData: (importedData: unknown[]) => void;
  handleError: (error: string) => void;
  dataE2e?: string;
}

export function PasteClipboard({ handleClipboardData, handleError, dataE2e }: PasteClipboardProps) {
  return (
    <button
      onClick={() => {
        importFromExcelClipboardData(handleClipboardData, handleError);
      }}
      className="btn btn-icon btn-discreet-primary"
      data-e2e={dataE2e}
    >
      <i className="icon">content_paste</i>
    </button>
  );
}
