import type { Selectors } from '@/bootstrap/selectors';
import { sortBy } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';

interface GroupData {
  id: number;
  label: string;
  enabled: boolean;
}

export interface UserPrefNotificationModel {
  notificationGroups: GroupData[];
  isAlertSoundPlayed: boolean;
}

export function getNotificationGroupsModel(
  state: AppState,
  selectors: Selectors,
): UserPrefNotificationModel {
  const userPreferencesState = state.ui.userPreferences;
  const isAlertSoundPlayed = selectors.isAlertSoundPlayed(userPreferencesState);

  return {
    notificationGroups: getNotificationGroups(state, selectors),
    isAlertSoundPlayed,
  };
}

function getNotificationGroups(state: AppState, selectors: Selectors): any[] {
  const referenceDataState = state.referenceData;

  const { diffusionGroups } = selectors.getCurrentUser(state);

  const savedNotificationGroups = selectors.getNotificationGroups(state.ui.userPreferences);

  const userGroups = selectors.getUserGroups(referenceDataState);

  const notificationGroups = diffusionGroups.map((diffusionGroup): any => {
    const groupById = userGroups.find(({ id }) => id === diffusionGroup);
    const label = groupById?.value ?? diffusionGroup.toString();

    const savedNotificationGroupById = savedNotificationGroups.find(
      ({ id }) => id === diffusionGroup,
    );
    const enabled = savedNotificationGroupById?.enable ?? true;

    return {
      id: diffusionGroup,
      label,
      enabled,
    };
  });

  return sortBy(notificationGroups, ['label']);
}
