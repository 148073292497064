import { makeSearchCounterpartApi } from '@/neos/business/blotter/searchByCounterpart/searchCounterpartApi';
import {
  type DoubleDate,
  DoubleDatePicker,
} from '@/neos/components/share/datePicker/DoubleDatePicker';
import { getAuthHeader } from '@/util/api/connect/connect';
import {
  formatDoubleDate,
  getEndOfLastMonth,
  getEndOfLastWeek,
  getStartOfLastMonth,
  getStartOfLastWeek,
  getStartOfThisMonth,
  getStartOfThisWeek,
  getToday,
  getYesterday,
} from '@/util/date/dateFormatHelper';
import { createSgmeHttp } from '@/util/http/sgmeHttp';
import { isMonday } from 'date-fns';
import { Dropdown, SplitButton, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { ajax } from 'rxjs/ajax';
import type { Workflow } from '../../../business/neosOnyxModel';
import { makeUnderlyingApi } from '../../rfq/strategies/strategy/common/underlyingInput/searchUnderlyingApi';
import {
  MultipleAsyncTypeahead,
  type MultipleAsyncTypeaheadApi,
  type MultipleTypeaheadValue,
} from '../../share/multipleAsyncTypeahead';
import type { BlotterSearchPropsFromState } from './getBlotterSearchModel';
import { forkJoin, map } from 'rxjs';

const sgmeHttp = createSgmeHttp(getAuthHeader, ajax);
const { fetchMUnderlyingMatchingOptions } = makeUnderlyingApi(sgmeHttp);
const { fetchCounterpartsByName } = makeSearchCounterpartApi(sgmeHttp);

const typeaheadApi: MultipleAsyncTypeaheadApi = {
  fetchMatchingOptions: (prefix: string) => {
    const apis = [fetchMUnderlyingMatchingOptions(prefix, []), fetchCounterpartsByName(prefix)];
    return forkJoin(apis).pipe(
      map(([underlyingOptions, counterpartOptions]) => {
        return underlyingOptions.concat(counterpartOptions);
      }),
    );
  },
};

export interface BlotterSearchDispatchProps {
  changeBlotterFromDate(from: string): void;

  changeBlotterToDate(to: string): void;

  requestBlotterRfqs(): void;

  changeBlotterWorkflows(workflows: Workflow[]): void;

  handleBlotterSearchChange(typeaheadValues: MultipleTypeaheadValue[]): void;

  requestBlotterWithFromToDates(from: string, to: string): void;

  cleanTypeaheadFilters(): void;

  changeMaturityDateRange(maturityDateFrom: string, maturityDateTo: string): void;
}

export type BlotterSearchProps = BlotterSearchDispatchProps & BlotterSearchPropsFromState;

export function BlotterSearchComponent(props: BlotterSearchProps) {
  const onTradeRangeDateChange = (dates: DoubleDate) => {
    if (dates[0] === null) {
      return;
    }
    const [startDateString, endDateString] = formatDoubleDate(dates);

    props.changeBlotterFromDate(startDateString);
    props.changeBlotterToDate(endDateString);
  };

  const onMaturityRangeDateChange = (dates: DoubleDate) => {
    const [startDateString, endDateString] = formatDoubleDate(dates);
    props.changeMaturityDateRange(startDateString, endDateString);
  };

  return (
    <section>
      <div className="d-flex align-items-center gap-2 ps-2">
        <div className="flex-center-x gap-1">
          <div>Trade date</div>
          <DoubleDatePicker
            dateFrom={props.from}
            dateTo={props.to}
            onDateRangeChange={onTradeRangeDateChange}
            data-e2e="blotter-trade-date-range"
          />
        </div>

        <div className="flex-center-x gap-1">
          <ToggleButtonGroup
            type="checkbox"
            value={props.workflows}
            onChange={(workflows: Workflow[]) =>
              workflows.length && props.changeBlotterWorkflows(workflows)
            }
            className="btn-group-toggle"
          >
            <ToggleButton variant="toggle-primary" value={'NEOS' as Workflow} id="NEOS">
              NEOS
            </ToggleButton>
            <ToggleButton variant="toggle-primary" value={'ONYX' as Workflow} id="ONYX">
              Onyx
            </ToggleButton>
            <ToggleButton variant="toggle-primary" value={'COBALT' as Workflow} id="COBALT">
              Cobalt
            </ToggleButton>
            <ToggleButton variant="toggle-primary" value={'SKIPPER' as Workflow} id="SKIPPER">
              Skipper
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="flex-center-x gap-1">
          <div>Maturity date</div>
          <DoubleDatePicker
            clearable={true}
            dateFrom={props.maturityDateFrom}
            dateTo={props.maturityDateTo}
            onDateRangeChange={onMaturityRangeDateChange}
            data-e2e="blotter-maturity-date-range"
          />
        </div>

        <div className="flex-center-x">
          <MultipleAsyncTypeahead
            id="blotter-search-typeahead"
            api={typeaheadApi}
            onChange={event => props.handleBlotterSearchChange(event)}
            placeholder="Status, Strategy type, Counterpart or Underlying"
            staticOptions={props.staticOptions}
            values={props.underlyingsAndCounterparts}
          />
          <button
            className="btn btn-icon btn-discreet-secondary"
            onClick={() => props.cleanTypeaheadFilters()}
          >
            <i className="icon">clear</i>
          </button>
          <button
            disabled={
              !props.from || !props.to || !!(props.maturityDateFrom && !props.maturityDateTo)
            }
            className="ms-1 btn btn-primary text-nowrap"
            onClick={() => props.requestBlotterRfqs()}
            type="button"
            data-e2e="blotter-search-button"
          >
            <i className="icon icon-sm me-1">search</i> Search
          </button>
        </div>

        <SplitButton
          className="btn-primary"
          title="Today"
          id="blotter-today-dropdown-btn"
          onClick={() => props.requestBlotterWithFromToDates(getToday(), getToday())}
        >
          <Dropdown.Item
            onClick={() => props.requestBlotterWithFromToDates(getYesterday(), getYesterday())}
          >
            {isMonday(new Date()) ? 'Last Friday' : 'Yesterday'}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.requestBlotterWithFromToDates(getStartOfThisWeek(), getToday())}
          >
            This Week
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.requestBlotterWithFromToDates(getStartOfLastWeek(), getEndOfLastWeek())
            }
          >
            Last Week
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.requestBlotterWithFromToDates(getStartOfThisMonth(), getToday())}
          >
            This Month
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.requestBlotterWithFromToDates(getStartOfLastMonth(), getEndOfLastMonth())
            }
          >
            Last Month
          </Dropdown.Item>
        </SplitButton>
      </div>
    </section>
  );
}
