import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { Dispatchable } from '@/bootstrap/thunks';
import {
  DefaultingScope,
  isClsProduct,
  type SwapCurrencyFeatureKey,
} from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import { neosThunks } from '@/neos/business/thunks';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { Tooltip } from 'react-bootstrap';

type ClsSwapCurrencyProps = {
  rfqId: string;
  strategyId: string;
  labelClassNames?: string;
  parentClassNames?: string;
};

export function ClsSwapCurrency({
  rfqId,
  strategyId,
  labelClassNames,
  parentClassNames,
}: ClsSwapCurrencyProps) {
  const availableCurrencies = useAppSelector(appState => {
    return selectors.getCurrencyList(appState.referenceData).map(({ refId }) => refId);
  });

  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const isReadonlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  const isCls = isClsProduct(product);

  const swapCurrency = useAppSelector(
    state =>
      selectors.getFeature(state.featureState, {
        strategyId,
        type: 'SWAP_CURRENCY',
      })?.swapCurrency,
  );
  const dispatch = useAppDispatch();

  const onSwapCurrencyChanged = (newSwapCurrency: string | undefined) => {
    const overrideScopes = [
      DefaultingScope.INTEREST_RATE_INDEX,
      DefaultingScope.CALCULATION_METHOD,
      DefaultingScope.BREAK_FEE,
    ];
    const options = new DefaultingOptions({ overrideScopes });

    const featureKey: SwapCurrencyFeatureKey = { strategyId, type: 'SWAP_CURRENCY' };
    dispatch(
      neosThunks.createChangeFeatureFieldThunk(featureKey, { swapCurrency: newSwapCurrency }),
    );

    const dispatchables: Array<Dispatchable> = [
      neosThunks.createUpdateProductAndResetDependenciesThunk(
        rfqId,
        strategyId,
        product.uuid,
        'swapCurrency',
        newSwapCurrency,
      ),
      neosThunks.createFetchCurvesListBasedOnCurrencyThunk(rfqId, [strategyId]),
    ];

    dispatchables.push(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options));
    dispatch(dispatchables);
  };

  if (!isCls) {
    return null;
  }

  return (
    <div
      className={`${parentClassNames ? parentClassNames : ''} els-field`}
      onMouseDown={e => e.stopPropagation()}
    >
      <NeosTooltip
        placement="top"
        overlay={<Tooltip id={'swap-currency-label'}>Swap Currency</Tooltip>}
      >
        <label className={labelClassNames}>Swap Ccy</label>
      </NeosTooltip>
      <NeosSelect
        readOnly={isReadonlyAtCurrentWorkflow}
        onChange={onSwapCurrencyChanged}
        options={availableCurrencies.map(currency => ({ label: currency, value: currency }))}
        value={swapCurrency}
        addEmptyOption
        data-e2e="neos-cls-strategy-definition-swap-currency"
      />
    </div>
  );
}
