import type { Shortcut, ShortcutButtonDispatchProps } from './ShortcutButton';
import { ShortcutButton } from './ShortcutButton.container';
import { ShortcutsTable } from './ShortcutsTable';

type Props<T> = {
  shortcuts: Shortcut<T>[];
  'data-e2e'?: string;
} & ShortcutButtonDispatchProps<T>;

export function CustomShortcutsTable<T>(props: Props<T>) {
  const { shortcuts, onShortcutSelected, onShortcutLongPress } = props;

  if (shortcuts.every(shortcut => shortcut.isHidden)) {
    return null;
  }

  return (
    <ShortcutsTable data-e2e={props['data-e2e']}>
      {shortcuts.map((shortcut: Shortcut<T>) => (
        <ShortcutButton
          key={`${shortcut.id}`}
          shortcut={shortcut}
          onShortcutSelected={onShortcutSelected}
          onShortcutLongPress={onShortcutLongPress}
        />
      ))}
    </ShortcutsTable>
  );
}
