import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { EquityLegPeriodDates } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { neosThunks } from '@/neos/business/thunks';
import { equityLegPeriodDatesFields } from '../ScheduleLegModel';
import { ScheduleLeg } from './ScheduleLeg';

type ScheduleEquityLegProps = {
  strategyId: string;
  rfqId: string;
};

export function ScheduleEquityLeg({ strategyId, rfqId }: ScheduleEquityLegProps) {
  const dispatch = useAppDispatch();

  const isReadonly = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );
  const equityData = useAppSelector(state =>
    selectors.selectScheduleEquityLegDates(state, strategyId, selectors),
  );

  function addEquityLegLine() {
    dispatch(neosThunks.createAddEquitySchedulePeriodThunk(strategyId));
  }

  function deleteEquityLegLine(uuid: string) {
    dispatch(neosThunks.createRemoveEquitySchedulePeriodThunk(strategyId, uuid));
  }

  function updateEquityLegField(newDate: Partial<EquityLegPeriodDates>, uuid: string) {
    const dispatchable = neosThunks.createUpdateEquitySchedulePeriodThunk(
      strategyId,
      newDate,
      uuid,
    );
    dispatch(
      neosThunks.createChangeScheduleStartOrEndDateThunk(rfqId, strategyId, newDate, dispatchable),
    );
  }

  return (
    <ScheduleLeg
      title="Equity Leg"
      data={equityData}
      dataStructure={equityLegPeriodDatesFields}
      addLine={addEquityLegLine}
      deleteLine={deleteEquityLegLine}
      onValueChanged={updateEquityLegField}
      isReadonly={isReadonly}
    />
  );
}
