import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, thunks } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  DeltaAdjustedComponent,
  type DeltaAdjustedDispatchProps,
  type DeltaAdjustedOwnProps,
} from './DeltaAdjusted';
import { getDeltaAdjustedModel } from './getDeltaAdjustedModel';

export const DeltaAdjusted: FC<DeltaAdjustedOwnProps> = ({ rfqId }) => {
  const stateProps = useAppSelector(state => getDeltaAdjustedModel(state, rfqId, selectors));
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchToProps(dispatch, rfqId);
  return <DeltaAdjustedComponent rfqId={rfqId} {...stateProps} {...dispatchProps} />;
};

function getDispatchToProps(dispatch: ExtendedDispatch, rfqId: string): DeltaAdjustedDispatchProps {
  return {
    onCopyToClipboard: text => {
      dispatch(thunks.createCopyToClipboardThunk(text));
    },
    onOrderTypeChanged: (strategyId, orderType) => {
      dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { type: orderType }));
      if (orderType !== 'LIMIT') {
        dispatch(
          neosActionCreators.strategyDataCrudActions.update(strategyId, {
            limitPriceValue: undefined,
            limitPriceUnit: undefined,
          }),
        );
      }
    },
    onLimitChanged: (strategyId, limit, unit) => {
      dispatch(
        neosActionCreators.strategyDataCrudActions.update(strategyId, {
          limitPriceValue: limit,
          limitPriceUnit: unit,
        }),
      );
    },
    onExecCommentChanged: (strategyId, execComment) => {
      dispatch(
        neosActionCreators.strategyDataCrudActions.update(strategyId, { comment: execComment }),
      );
    },
    onRefLevelChanged: (underlyingId, refLevel, referenceKey) => {
      dispatch(
        neosActionCreators.referenceCrudActions.update(referenceKey, {
          hasBeenManuallySet: true,
        }),
        neosThunks.createChangeReferenceLevelThunk({ rfqId, underlyingId }, refLevel),
      );
    },
    onPortfolioIdChanged: (strategyId, portfolioId) => {
      dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { portfolioId }));
    },
  };
}
