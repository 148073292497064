import {
  type MailStatusType,
  type PreconfColor,
  mailStatusToColor,
} from '../shared/preconfirmationModel';
import type { ExternalMailOptions } from './externalOnyxPreconfirmationModel';
import { externalPreconfirmationDomain } from './externalPreconfirmationDomain';
import type { AppState } from '@/bootstrap/store.ts';

export const externalPreconfirmationSelectors = {
  getExternalMailOptions,
  getExternalEmailStatus,
  getExternalPreconfBulletColor,
  getExternalPreconfirmationState: getExternalPreconfState,
};

function getExternalPreconfState(state: AppState) {
  return state.preconfirmation.externalPreconfirmation;
}

function getExternalMailOptions(state: AppState, rfqId: string): ExternalMailOptions | undefined {
  return externalPreconfirmationDomain.selectors.find(getExternalPreconfState(state), rfqId);
}

function getExternalEmailStatus(state: AppState, rfqId: string): MailStatusType {
  const externalPreconfirmation = externalPreconfirmationDomain.selectors.find(
    getExternalPreconfState(state),
    rfqId,
  );
  return externalPreconfirmation?.mailStatus ?? 'NOT_SENT';
}

function getExternalPreconfBulletColor(state: AppState, rfqId: string): PreconfColor {
  const emailStatus = getExternalEmailStatus(state, rfqId);
  return mailStatusToColor[emailStatus];
}
