import { selectors } from '@/bootstrap/selectors';
import { connect } from 'react-redux';
import {
  type FairPricesHeaderOwnProps,
  type FairPricesHeaderPropsFromState,
  FairPricesHeaderWithCss,
} from './FairPricesHeader';
import type { AppState } from '@/bootstrap/store.ts';

const { areRfqStrategiesFutureLike } = selectors;

const { isOtherFairGreeksDisplayed, isUsualFairPricesDisplayed, isVolBidAskDisplayed } = selectors;

function mapStateToProps(
  state: AppState,
  { rfqId }: FairPricesHeaderOwnProps,
): FairPricesHeaderPropsFromState {
  const { fairGreeksToggle, fairPricesToggle, volBidAskToggle } = state.ui.userPreferences;

  return {
    isOtherFairGreeksDisplayed: isOtherFairGreeksDisplayed(
      fairGreeksToggle,
      state.ui,
      rfqId,
      selectors,
    ),
    isUsualFairPricesDisplayed: isUsualFairPricesDisplayed(
      fairPricesToggle,
      state.ui,
      rfqId,
      selectors,
    ),
    isVolBidAskPricesDisplayed: isVolBidAskDisplayed(volBidAskToggle, state.ui, rfqId, selectors),
    areAllStrategiesFutureLike: areRfqStrategiesFutureLike(state, rfqId, selectors),
  };
}

export const FairPricesHeader = connect(mapStateToProps)(FairPricesHeaderWithCss);
