import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { NumericInput } from '@/neos/components/share/numericInput';

import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { TotalSalesMarginModal } from './TotalSalesMarginModal.container';
import { getSalesCreditStatusModel } from './getSalesCreditStatusModel';
import { getTotalSalesMarginModel } from './getTotalSalesMarginModel';

export function SalesCredit(props: { rfqId: string }) {
  const dispatch = useAppDispatch();
  const onOpen = () =>
    dispatch(
      neosActionCreators.rfqUiCrudActions.patchOrInsert(props.rfqId, { isMarkupModalShown: true }),
    );
  const { message, statusColor } = useAppSelector(state =>
    getSalesCreditStatusModel(state, props.rfqId, selectors),
  );
  const { displayedTotalSalesCredit } = useAppSelector(state =>
    getTotalSalesMarginModel(state, props.rfqId, selectors),
  );

  return (
    <div className="d-flex flex-column text-nowrap">
      <label className="fw-bold mb-1">
        Total SC
        {statusColor === 'success' && (
          <i className="ms-2 icon icon-sm text-success" style={{ lineHeight: 'initial' }}>
            done
          </i>
        )}
        {statusColor === 'danger' && (
          <i className="ms-2 icon icon-sm text-danger" style={{ lineHeight: 'initial' }}>
            clear
          </i>
        )}
      </label>
      <div className="d-flex align-items-center">
        <SimpleNeosTooltip id="sales-margin" type={statusColor} message={message}>
          <NumericInput
            data-e2e="total-sales-margin"
            inputClassName="fw-bold"
            unit={displayedTotalSalesCredit?.unit}
            value={displayedTotalSalesCredit?.value}
            withMaximumNumberOfFloatingDigits={0}
            readOnly
            borderColor={statusColor}
          />
        </SimpleNeosTooltip>
        <div>
          <button
            data-e2e="neos-rfq-total-sales-credit"
            className="btn btn-discreet-secondary btn-icon"
            onClick={onOpen}
          >
            <i className="icon">more_horiz</i>
          </button>
        </div>
        <TotalSalesMarginModal rfqId={props.rfqId} />
      </div>
    </div>
  );
}
