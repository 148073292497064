import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  MdpQuoteTimerEditionComponent,
  type MdpQuoteTimerEditionDispatchProps,
  type MdpQuoteTimerEditionOwnProps,
} from './MdpQuoteTimerEdition';
import { getQuoteTimerEditionModel } from './getMdpQuoteTimerEditionModel';
import { useAppSelector } from '@/bootstrap/hooks.ts';

export const MdpQuoteTimerEdition: FC<MdpQuoteTimerEditionOwnProps> = ({ rfqId }) => {
  const { isEditable, quoteValidityDuration } = useAppSelector(state =>
    getQuoteTimerEditionModel(state, rfqId, selectors),
  );
  const dispatch = useDispatch<ExtendedDispatch>();
  const { onEdit, onQuoteTimerDurationChange } = getDispatchProps(dispatch, rfqId);
  return (
    <MdpQuoteTimerEditionComponent
      rfqId={rfqId}
      isEditable={isEditable}
      quoteValidityDuration={quoteValidityDuration}
      onEdit={onEdit}
      onQuoteTimerDurationChange={onQuoteTimerDurationChange}
    />
  );
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  rfqId: string,
): MdpQuoteTimerEditionDispatchProps {
  return {
    onEdit: isQuoteTimerEditionEnable =>
      dispatch(neosActionCreators.rfqUiCrudActions.update(rfqId, { isQuoteTimerEditionEnable })),
    onQuoteTimerDurationChange: (quoteValidityDuration: number | undefined) => {
      dispatch(neosActionCreators.rfqDataCrudActions.update(rfqId, { quoteValidityDuration }));
    },
  };
}
