import type { Field } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/utils/Field';
import type { ActivityType, SizeType } from '../../../../neos/business/neosOnyxModel';

export type DisplayNegotiatedSize =
  | 'NOTIONAL'
  | 'LOCAL_NOTIONAL'
  | 'QUANTITY'
  | 'VAR_UNIT'
  | 'NUMBER_OF_LOTS';

export interface StrategyUi {
  activity?: ActivityType;
  showLegPrices?: boolean;
  legOverMessageDismissed?: boolean;
  extraFeaturesModal?: 'SHOW' | 'HIDE';
  displayedSizeType: DisplayNegotiatedSize;
  displayMissingCompositionDataWarning: boolean;
  displayCompoImportHasMultipleIndicesWarning: boolean;
  maturities?: Array<Field<string | undefined> & { isValid: boolean }>;
  showElsSectionsModal?: boolean;
  showImportScheduleError?: boolean;
  isScheduleUiToggleEnabled?: boolean;
}

export const toDisplayNegotiatedSize: { [key in SizeType]: DisplayNegotiatedSize } = {
  FIXED_NOTIONAL: 'NOTIONAL',
  FIXED_VEGA_NOTIONAL: 'NOTIONAL',
  FIXED_DIGITAL_NOTIONAL: 'NOTIONAL',
  FIXED_QUANTITY: 'QUANTITY',
  FIXED_VAR_UNIT: 'VAR_UNIT',
};

export const getNegotiatedSize = (
  sizeType: SizeType,
  descriminator: 'LISTED' | 'OTC',
): DisplayNegotiatedSize => {
  switch (sizeType) {
    case 'FIXED_NOTIONAL':
      return 'NOTIONAL';
    case 'FIXED_VEGA_NOTIONAL':
      return 'NOTIONAL';
    case 'FIXED_DIGITAL_NOTIONAL':
      return 'NOTIONAL';
    case 'FIXED_QUANTITY':
      return descriminator === 'LISTED' ? 'NUMBER_OF_LOTS' : 'QUANTITY';
    case 'FIXED_VAR_UNIT':
      return 'VAR_UNIT';
    default:
      throw new Error('Unknown size type');
  }
};
