import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

type EditableCondition = 'RFQ_SCOPE' | 'RFQ_EDITABLE' | 'UNDERLYING_SET' | 'STRATEGY_EDITABLE';

export function getIsStrategyDefinitionFieldEditable(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
  conditions: EditableCondition[],
): boolean {
  const { getStrategyData, isReadOnlyRfq, isStrategyUnderlyingsSet } = selectors;
  const { isReadOnlyStrategy } = selectors;
  const isStrategyVolVarDisp = selectors.isVarVolDispersionStrategy(state, strategyId, selectors);

  if (selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors)) {
    return false;
  }

  if (conditions.includes('RFQ_EDITABLE') && isReadOnlyRfq(state, rfqId)) {
    return false;
  }

  const { scope, strategyType } = getStrategyData(state, strategyId);
  if (
    conditions.includes('STRATEGY_EDITABLE') &&
    isReadOnlyStrategy(state.referenceData, strategyType)
  ) {
    return false;
  }

  const isRfqStrategy = scope === 'RFQ';
  if (conditions.includes('RFQ_SCOPE') && !isRfqStrategy) {
    return false;
  }

  if (
    conditions.includes('UNDERLYING_SET') &&
    !isStrategyVolVarDisp &&
    !isStrategyUnderlyingsSet(state, strategyId, selectors)
  ) {
    return false;
  }

  if (
    conditions.includes('UNDERLYING_SET') &&
    isStrategyVolVarDisp &&
    !selectors.isVarVolDispIndexLegUnderlyingSet(state, strategyId, selectors)
  ) {
    return false;
  }

  const shouldBeReadOnlyOnSecondaryRfq =
    selectors.isSecondaryOrMixedRfq(state, rfqId) &&
    !selectors.isPrimaryStrategy(state, strategyId, selectors);

  return !shouldBeReadOnlyOnSecondaryRfq;
}
