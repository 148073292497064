import type { Selectors } from '@/bootstrap/selectors';
import type { Product } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';
import type { AppState } from '@/bootstrap/store.ts';

type NoTaxCollectionField = Field<Product['noTaxCollection']> & { isDisplayed: boolean };

export interface NoTaxCollectionCellsModel {
  noTaxCollectionFields: NoTaxCollectionField[];
}

export function getNoTaxCollectionCellsModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): NoTaxCollectionCellsModel {
  const products = selectors.getStrategyProducts(state, strategyId, selectors);

  const { strategyType } = selectors.getStrategyData(state, strategyId);
  const strategyDefinition = selectors.getStrategyDefinition(state.referenceData, strategyType);
  const isReadonly =
    !getIsStrategyDefinitionFieldEditable(state, rfqId, strategyId, selectors, [
      'RFQ_SCOPE',
      'RFQ_EDITABLE',
      'STRATEGY_EDITABLE',
    ]) ||
    strategyDefinition.readOnlyProductNoTaxCollection ||
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors);

  const noTaxCollectionFields = getFieldsArray(
    products,
    'noTaxCollection',
    strategyDefinition.sameProductNoTaxCollection,
    isReadonly,
  );

  return {
    noTaxCollectionFields: noTaxCollectionFields.map((field, index) => ({
      ...field,
      isDisplayed: strategyDefinition.legs.at(index)?.taxCollection ?? false,
    })),
  };
}
