import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { MarkitConfirmationMedia, OtcAllocation } from '@/neos/business/neosModel';
import { If } from '@/neos/components/share/if/if';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import type { FC } from 'react';
import { ElectronicUser } from './ElectronicUser';
import { MarkitWireId } from './MarkitWireId';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type OtcAllocMarkitWireOptionsProps = {
  allocation: OtcAllocation;
  rfqId: string;
  legId: string;
  hasMarkitAlloc: boolean;
};

export const OtcAllocMarkitWireOptions: FC<OtcAllocMarkitWireOptionsProps> = ({
  allocation,
  rfqId,
  legId,
  hasMarkitAlloc,
}) => {
  const dispatch = useAppDispatch();

  const onMarkitWireModeChanged = (
    allocationId: string,
    markitWireMode?: MarkitConfirmationMedia['markitWireMode'],
  ) =>
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        { markitWireMode },
      ),
    );

  return (
    <If condition={hasMarkitAlloc}>
      <NeosSelectWithAutocomplete
        classes="minWidth90px"
        data-e2e="otc-alloc-mode"
        isDisabled={allocation.media !== 'MARKITWIRE'}
        value={allocation.media === 'MARKITWIRE' ? allocation.markitWireMode : undefined}
        onChange={selected =>
          onMarkitWireModeChanged(
            allocation.uuid,
            selected?.value as MarkitConfirmationMedia['markitWireMode'],
          )
        }
        addEmptyOption
        options={['AUTO', 'MANUAL'].map(option => ({
          value: option,
          label: option,
        }))}
      />
      <ElectronicUser
        onMediaUserChanged={electronicMediaUser =>
          dispatch(
            neosActionCreators.otcAllocationCrudActions.update(
              { allocationId: allocation.uuid, rfqId, legId },
              { electronicMediaUser },
            ),
          )
        }
        data-e2e="otc-alloc-media-user"
        allocation={allocation}
      />

      <MarkitWireId rfqId={rfqId} legId={legId} allocationId={allocation.uuid} />
    </If>
  );
};
