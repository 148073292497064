import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { ClientPosition } from '../../../../../../../neos/business/neosModel';
import {
  ClientPositionCellsComponent,
  type ClientPositionCellsDispatchProps,
  type ClientPositionCellsMapStateProps,
  type ClientPositionCellsOwnProps,
} from './ClientPositionCells';
import { getClientPositionCellsModel } from './getClientPositionCellsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const ClientPositionCells = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientPositionCellsComponent);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: ClientPositionCellsOwnProps,
): ClientPositionCellsMapStateProps {
  return getClientPositionCellsModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: ClientPositionCellsOwnProps,
): ClientPositionCellsDispatchProps {
  return {
    onClientPositionChanged(legId: string, clientPosition?: ClientPosition) {
      dispatch(
        neosThunks.createUpdateClientPositionAndResetDependenciesThunk(
          rfqId,
          strategyId,
          legId,
          clientPosition,
        ),
      );
    },
  };
}
