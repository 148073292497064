import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import {
  createRemoveReferencedRfqThunk,
  createUpsertReferencedRfqThunk,
} from '@/neos/business/ui/referencedRfq/referencedRfqThunk';
import { Component } from 'react';
import { connect } from 'react-redux';
import type { BlotterRowModel } from '../../../share/blotterGrid';
import {
  RfqDetailsComponent,
  type RfqDetailsDispatchProps,
  type RfqDetailsOwnProps,
  type RfqDetailsStateProps,
} from './RfqDetails';
import type { AppState } from '@/bootstrap/store.ts';

export interface RfqDetailsCellRendererProps {
  data: BlotterRowModel;
  parentRfqId: string;
}
export class RfqDetailsCellRenderer extends Component<RfqDetailsCellRendererProps> {
  constructor(props: RfqDetailsCellRendererProps) {
    super(props);
  }

  refresh() {
    return true;
  }

  render() {
    return <RfqDetails rfqId={this.props.data.uuid} parentRfqId={this.props.parentRfqId} />;
  }
}

function mapStateToProps(state: AppState, { rfqId }: RfqDetailsOwnProps): RfqDetailsStateProps {
  const rfqExists = selectors.doesRfqExist(state, rfqId);
  const { selectedTabId } = state.ui.tabManagement;
  const isSimilarActivityMode = selectors.areSimilarActivitiesDisplayed(state.ui, selectedTabId);
  return {
    rfqExists,
    isSimilarActivityMode,
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, parentRfqId }: RfqDetailsOwnProps,
): RfqDetailsDispatchProps {
  return {
    onOpen: (shouldLoad = false) => {
      if (shouldLoad) {
        dispatch(neosActionCreators.loadTransactionAtVersion({ rfqId, version: 'LATEST' }, false));
      }
      dispatch(createUpsertReferencedRfqThunk(rfqId, parentRfqId));
    },
    onClose: () => {
      dispatch(createRemoveReferencedRfqThunk(rfqId, parentRfqId));
    },
  };
}

export const RfqDetails = connect(mapStateToProps, mapDispatchToProps)(RfqDetailsComponent);
