import type { Selectors } from '@/bootstrap/selectors';
import type { LegData } from '@/neos/business/rfq/strategy/leg/legData/legDataModel';
import { getFieldRelevantLegsData } from '../utils';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import type { AppState } from '@/bootstrap/store.ts';

export interface RatioCellsModel {
  ratios: Array<Field<number | undefined>>;
  isLegOverEnabled: boolean;
  isMdpRfq: boolean;
}

export function getRatioCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): RatioCellsModel {
  const isMdpRfq = selectors.getIsMdpRfq(appState, rfqId);

  const { strategyType } = selectors.getStrategyData(appState, strategyId);
  const { sameWeight, ratio: legsWeightEditable } = selectors.getStrategyDefinition(
    appState.referenceData,
    strategyType,
  );

  const isOtcAndAmendWorkflowStatus = selectors.isOtcStrategyAndAmendWorkflowStatus(
    appState,
    rfqId,
    strategyId,
    selectors,
  );

  const enabled =
    getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_SCOPE',
      'RFQ_EDITABLE',
      'UNDERLYING_SET',
      'STRATEGY_EDITABLE',
    ]) && !isOtcAndAmendWorkflowStatus;

  const legsData = getFieldRelevantLegsData(appState, strategyId, sameWeight, selectors);

  const getLegDataField = (legData: LegData): Field<number | undefined> => {
    const isEditable = enabled && (legsWeightEditable || legData.isMaster);
    return {
      id: legData.uuid,
      value: legData.weight,
      isEditable,
    };
  };

  const ratios = legsData.map(getLegDataField);

  const isLegOverEnabled = selectors.isLegOverWarningVisible(appState, strategyId, selectors);

  return {
    ratios,
    isLegOverEnabled,
    isMdpRfq,
  };
}
