import type { Selectors } from '@/bootstrap/selectors';
import type { PriceUnitType } from '../../../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export type PricesUnitButtonModels = PriceUnitButtonModel[];

export interface PriceUnitButtonModel {
  label: string;
  priceUnitType: PriceUnitType;
}

export function getPricesUnitButtonModel(
  appState: AppState,
  rfqId: string,
  areAllButtonsDisabled: boolean,
  selectors: Selectors,
): PricesUnitButtonModels {
  if (areAllButtonsDisabled) {
    return [];
  }

  const { strategyIds } = selectors.getRfqData(appState, rfqId);

  const isPercentEligible = strategyIds.some(strategyId => {
    const { strategyType } = selectors.getStrategyData(appState, strategyId);
    const { availablePriceUnitTypes } = selectors.getStrategyDefinition(
      appState.referenceData,
      strategyType,
    );
    const isElsStrategyWithLegacyPercentPriceUnitType =
      selectors.isElsStrategyWithLegacyPercentPriceUnitType(appState, strategyId, selectors);
    return (
      availablePriceUnitTypes.includes('REF_PERCENT') || isElsStrategyWithLegacyPercentPriceUnitType
    );
  });

  const isCcyEligible = strategyIds.some(strategyId => {
    const { strategyType } = selectors.getStrategyData(appState, strategyId);
    const { availablePriceUnitTypes } = selectors.getStrategyDefinition(
      appState.referenceData,
      strategyType,
    );
    return availablePriceUnitTypes.includes('CCY');
  });
  const basisEligible = strategyIds.every(strategyId => {
    const { strategyType } = selectors.getStrategyData(appState, strategyId);
    const { availablePriceUnitTypes } = selectors.getStrategyDefinition(
      appState.referenceData,
      strategyType,
    );
    return availablePriceUnitTypes.includes('BASIS');
  });
  const isBipsEligible = strategyIds.every(strategyId => {
    const { strategyType } = selectors.getStrategyData(appState, strategyId);
    const { availablePriceUnitTypes } = selectors.getStrategyDefinition(
      appState.referenceData,
      strategyType,
    );
    return availablePriceUnitTypes.includes('BIPS');
  });

  const isThereAFutureRollStrategy = strategyIds.some(strategyId => {
    return selectors.isStrategyFutureRoll(appState, strategyId, selectors);
  });

  const createButtonModel = (priceUnitType: PriceUnitType, label: string) => ({
    label,
    priceUnitType,
  });

  return [
    ...(isPercentEligible ? [createButtonModel('REF_PERCENT', '%')] : []),
    ...(isCcyEligible ? [createButtonModel('CCY', 'CCY')] : []),
    ...(basisEligible && !isThereAFutureRollStrategy ? [createButtonModel('BASIS', 'Basis')] : []),
    ...(isBipsEligible ? [createButtonModel('BIPS', 'bp')] : []),
  ];
}
