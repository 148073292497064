import { commonActionCreators } from '@/common/business/commonActionCreators';
import type { CommonAction } from '@/common/business/commonActions';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { NeosAction } from '@/neos/business/neosActions';
import { createLogAnalyticsAction, type LogAnalyticsAction } from './middlewares/analytics/actions';
import type { Dispatchable } from './thunks';
import type { topLevelErrorSlice } from '@/neos/business/ui/topLevelError/topLevelErrorSlice.ts';
import type { onyxBookingStepSlice } from '@/neos/business/bookingSteps/BookingStepsSlice.ts';
import type { currentUserPreferencesSlice } from '@/neos/business/currentUserPreferences/currentUserPreferencesSlice.ts';
import type { lastOnyxTradeCompletedRfqSlice } from '@/neos/business/lastOnyxTradeCompletedRfq/lastOnyxTradeCompletedRfqSlice.ts';
import type { NotificationActions } from 'reapop/dist/reducers/notifications/actions';
import type { isUserDraggingFileSlice } from '@/neos/business/ui/isUserDraggingFile/isUserDraggingFileSlice.ts';

export interface NextAction {
  additionalNextAction?: Dispatchable;
}

type SliceActionCreators = typeof topLevelErrorSlice.actions &
  typeof isUserDraggingFileSlice.actions &
  typeof onyxBookingStepSlice.actions &
  typeof currentUserPreferencesSlice.actions &
  typeof lastOnyxTradeCompletedRfqSlice.actions;

type SliceActions = ReturnType<SliceActionCreators[keyof SliceActionCreators]>;

export type Action =
  | CommonAction
  | NeosAction
  | LogAnalyticsAction
  | SliceActions
  | NotificationActions;

export const actionCreators = {
  common: commonActionCreators,
  neos: neosActionCreators,
  analytics: { createLogAnalyticsAction },
};

export type ActionCreators = typeof actionCreators;
