import type { Selectors } from '@/bootstrap/selectors';
import type { CustomizableQuickButtonToggle } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import type { AppState } from '@/bootstrap/store.ts';

export interface RfqPreferencesModel {
  isQuickButtonsSectionShown: boolean;
  isIbChatSectionShown: boolean;
  quickButtonStrategyToggle: CustomizableQuickButtonToggle;
  quickButtonUnderlyingToggle: CustomizableQuickButtonToggle;
  isIndicativeTradableDisplayed: boolean;
}

export function getRfqPreferencesModel(state: AppState, selectors: Selectors): RfqPreferencesModel {
  const userPreferencesState = state.ui.userPreferences;
  return {
    isIbChatSectionShown: selectors.isIbChatSectionShown(userPreferencesState),
    isQuickButtonsSectionShown: selectors.isQuickButtonsSectionShown(userPreferencesState),
    quickButtonUnderlyingToggle: selectors.getQuickButtonUnderlyingToggle(userPreferencesState),
    quickButtonStrategyToggle: selectors.getQuickButtonStrategyToggle(userPreferencesState),
    isIndicativeTradableDisplayed: selectors.getIsIndicativeTradableDisplayed(userPreferencesState),
  };
}
