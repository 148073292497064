import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  FairPriceButtonComponent,
  type FairPriceButtonDispatchProps,
  type FairPriceButtonOwnProps,
  type FairPriceButtonPropsFromState,
} from './FairPriceButton';
import { isFairPriceButtonEnabled } from './getFairPriceButtonModel';
import type { AppState } from '@/bootstrap/store.ts';

export const FairPriceButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FairPriceButtonComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: FairPriceButtonOwnProps,
): FairPriceButtonPropsFromState {
  const isEnabled = isFairPriceButtonEnabled(rfqId, state, selectors);
  return { isEnabled };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: FairPriceButtonOwnProps,
): FairPriceButtonDispatchProps {
  return {
    onFairPricesRequested: () => {
      dispatch(
        neosThunks.createRequestRfqFairPricesThunk(rfqId),
        createLogAnalyticsAction('NEOS RFQ', 'Select Get Fair Prices'),
      );
    },
  };
}
