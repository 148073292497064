import type { Selectors } from '@/bootstrap/selectors';
import type { Services } from '@/bootstrap/services';

import type { AppState } from '@/bootstrap/store.ts';

export interface NegoModel {
  selectedStrategyId: string | undefined;
  isLoading: boolean;
  isIbChatSectionShown: boolean;
  isQuickButtonsSectionShown: boolean;
  isReadonlyRfq: boolean;
  areSummaryAndTotalsShown: boolean;
  isClientCommentShown: boolean;
}

export function getNegoModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
  services: Services,
): NegoModel {
  const selectedStrategyId = selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId);
  const isLoading = selectors.isRfqLoading(state.ui, rfqId);
  const isReadonlyRfq = selectors.isReadOnlyRfq(state, rfqId);
  const { savedStatus, source, clientComment } = selectors.getRfqData(state, rfqId);
  const isCurrentStatusBeforePriceRequested =
    !savedStatus || services.orderOf(savedStatus).isBefore('PRICE_REQUESTED');

  const isClientCommentShown = source !== 'VOICE' && !!clientComment;

  return {
    isReadonlyRfq,
    selectedStrategyId,
    isLoading,
    isIbChatSectionShown:
      isCurrentStatusBeforePriceRequested &&
      selectors.isIbChatSectionShown(state.ui.userPreferences),
    isQuickButtonsSectionShown:
      isCurrentStatusBeforePriceRequested &&
      selectors.isQuickButtonsSectionShown(state.ui.userPreferences),
    areSummaryAndTotalsShown:
      savedStatus !== undefined && services.orderOf(savedStatus).isAfterOrEqual('PRICE_REQUESTED'),
    isClientCommentShown,
  };
}
