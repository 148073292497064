import { legActionCreators } from './leg/legActionCreators';
import { strategyDataActionCreators } from './strategyData/strategyDataActionCreators';

import { selectors } from '@/bootstrap/selectors';
import { featureActionCreators } from './feature';
import { elsSectionsActionCreators } from './feature/elsSections/elsSectionsActionCreators';
import {
  getDefaultStrategyData,
  getDefaultTraderStrategyData,
} from './strategyData/strategyDataModel';
import type { AppState } from '@/bootstrap/store.ts';
import type { Action } from '@/bootstrap/actions.ts';

export const strategyActionCreators = {
  ...strategyDataActionCreators,
  ...legActionCreators,
  ...featureActionCreators,
  ...elsSectionsActionCreators,
  strategyInitialized,
};

function strategyInitialized(
  rfqId: string,
  strategyId: string,
  legId: string,
  state: AppState,
): Action[] {
  const legIds = [legId];
  const isTrader = selectors.isTrader(state);
  return [
    strategyDataActionCreators.strategyDataCrudActions.insert(
      strategyId,
      isTrader
        ? getDefaultTraderStrategyData(rfqId, strategyId, legIds)
        : getDefaultStrategyData(rfqId, strategyId, legIds),
    ),
    ...legActionCreators.createLegsInitializedAction(strategyId, legIds),
  ];
}
