import type { Selectors } from '@/bootstrap/selectors';
import type { Thunk } from '@/bootstrap/thunks';
import type { Derogation, DerogationKey, PredealCheck } from '../predealCheckModel';
import type { AppState } from '@/bootstrap/store.ts';

export function createPredealCheckDerogatedThunk(
  rfqId: string,
  derogateAll: boolean,
  derogationKey: DerogationKey,
): Thunk {
  return function predealCheckDerogatedThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createNeosPredealCheckDerogationRequestedAction },
      },
    },
  ) {
    const state = getState();
    const selectedPredealCheck = selectors.getNeosSelectedPredealCheck(state, rfqId, selectors);
    if (!selectedPredealCheck) {
      throw Error('No selected predeal check is found for transaction ' + rfqId);
    }

    const derogations = derogateAll
      ? getDerogateAllPredealCheckDerogations(
          state,
          rfqId,
          selectedPredealCheck,
          derogationKey,
          selectors,
        )
      : getPredealCheckDerogations(state, derogationKey, selectedPredealCheck, rfqId, selectors);

    const PdcDerogationRequestedAction = derogations.map(derogation =>
      createNeosPredealCheckDerogationRequestedAction(rfqId, derogation),
    );

    return dispatch(PdcDerogationRequestedAction);
  };
}

function getDerogateAllPredealCheckDerogations(
  state: AppState,
  rfqId: string,
  { type, level }: PredealCheck,
  pdcDerogation: DerogationKey,
  selectors: Selectors,
): Derogation[] {
  return selectors
    .getNeosPredealChecksByTypeAndLevel(state, rfqId, type, level, selectors)
    .filter(({ isDerogable }) => isDerogable)
    .map(predealCheck => ({ pdcDerogation, pdcUuid: predealCheck.uuid }));
}

function getPredealCheckDerogations(
  state: AppState,
  pdcDerogation: DerogationKey,
  { uuid, type }: PredealCheck,
  rfqId: string,
  selectors: Selectors,
): Derogation[] {
  const pdcsByType = selectors
    .getNeosPredealChecks(state, rfqId, selectors)
    .filter(pdc => pdc.type === type);

  const arePdcsSameCounterparty =
    pdcsByType.length > 0 &&
    pdcsByType.every(({ counterpartyId }) => counterpartyId === pdcsByType[0].counterpartyId);

  const shouldPropagateDerogationAtAllLevel =
    pdcDerogation !== 'DEFAULT' && canPdcBeAtRequestLevel(type) && arePdcsSameCounterparty;

  return shouldPropagateDerogationAtAllLevel
    ? pdcsByType.map(pdc => ({ pdcDerogation, pdcUuid: pdc.uuid }))
    : [{ pdcDerogation, pdcUuid: uuid }];
}

function canPdcBeAtRequestLevel(pdcType: PredealCheck['type']): boolean {
  const requestLevelPdc: PredealCheck['type'][] = ['KYC', 'MIFID1'];
  return requestLevelPdc.includes(pdcType);
}
