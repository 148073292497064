import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';

import type { AppState } from '@/bootstrap/store.ts';

export function isNeosShowLegPrices(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const { legIds, strategyType } = selectors.getStrategyData(state, strategyId);
  if (legIds.length === 1) {
    return false;
  }
  const { status, source } = selectors.getRfqData(state, rfqId);
  const isVolDispersionStrat = strategyType === 'VOL_DISPERSION';

  if (
    services.orderOf(status).isAfterOrEqual('ORDER_CONFIRMED') ||
    source === 'FIX_RFQHUB' ||
    isVolDispersionStrat
  ) {
    return true;
  }
  return selectors.isShowLegPrices(state.ui, strategyId);
}
