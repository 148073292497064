import type { StateMap } from '@/util/collectionHelper';
import { identity } from 'lodash';
import type { Reducer } from 'redux';
import {
  type ArrayPropertyIndex,
  type ArrayPropertyValue,
  type ArrayPropertyValueAndIndex,
  type CrudArrayAdd,
  type CrudArrayAddAtIndex,
  type CrudArrayPatch,
  type CrudArrayRemove,
  type CrudArrayRemoveIndex,
  type CrudDelete,
  type CrudDeleteByPartialKey,
  type CrudInsertAction,
  type CrudInsertOrUpdate,
  type CrudPatchOrInsert,
  type CrudUpdate,
  isCrudActionSymb,
  type Item,
  type ObjectArrayPropertyPatch,
  type SType,
} from './crudActions';
import { crudReducer } from './crudReducer';

interface CrudDomain<S extends SType, K = string> {
  actions: CrudActionCreators<S, K>;
  reducer: Reducer<StateMap<S>>;
  selectors: CrudSelectors<S, K>;
}

export interface CrudActionCreators<S extends SType, K = string> {
  insert: (id: K, item: S) => CrudInsertAction<S, K>;
  upsert: (id: K, item: S) => CrudInsertOrUpdate<S, K>;
  upsertMany: (items: Item<K, S>[]) => CrudInsertOrUpdate<S, K>;
  update: (id: K, patch: Partial<S>) => CrudUpdate<S, K>;
  patchOrInsert: (id: K, item: S) => CrudPatchOrInsert<S, K>;
  delete: (id: K) => CrudDelete<K>;
  deleteByPartialKey: (id: K extends string ? never : Partial<K>) => CrudDeleteByPartialKey<K>;

  arrayAdd: (id: K, key: ArrayPropertyValue<S>) => CrudArrayAdd<S, K>;
  arrayAddAtIndex: (
    id: K,
    valueParameters: ArrayPropertyValueAndIndex<S>,
  ) => CrudArrayAddAtIndex<S, K>;
  arrayRemove: (id: K, key: ArrayPropertyValue<S>) => CrudArrayRemove<S, K>;
  arrayRemoveIndex: (id: K, key: ArrayPropertyIndex<S>) => CrudArrayRemoveIndex<S, K>;

  arrayPatch: (id: K, patch: ObjectArrayPropertyPatch<S>) => CrudArrayPatch<S, K>;
}

export interface CrudSelectors<S, K = string> {
  find: (map: StateMap<S>, selectBy: K) => S | undefined;
  selectObjects: (map: StateMap<S>, selectBy: Partial<K>) => S[];
  selectKeys: (map: StateMap<S>, selectBy: Partial<K>) => K[];
}

export interface KeyIdConverter<K = string> {
  keyToString: (key: K) => string;
  stringToKey: (id: string) => K;
}

export function crudDomain<S extends SType, K = string>(
  domain: string,
  keyIdConverter: KeyIdConverter<K> = {
    keyToString: identity,
    stringToKey: identity,
  },
): CrudDomain<S, K> {
  const { stringToKey, keyToString } = keyIdConverter;
  const crudInsertAction = (id: K, item: S): CrudInsertAction<S, K> => {
    return {
      type: 'INSERT_ACTION',
      domain,
      id,
      item,
      [isCrudActionSymb]: true,
    };
  };

  const crudInsertOrUpdateAction = (id: K, item: S): CrudInsertOrUpdate<S, K> => {
    return {
      type: 'INSERT_OR_UPDATE',
      domain,
      items: [{ id, value: item }],
      [isCrudActionSymb]: true,
    };
  };

  const crudInsertOrUpdateManyAction = (items: Item<K, S>[]): CrudInsertOrUpdate<S, K> => {
    return {
      type: 'INSERT_OR_UPDATE',
      domain,
      items,
      [isCrudActionSymb]: true,
    };
  };

  const crudUpdateAction = (id: K, patch: Partial<S>): CrudUpdate<S, K> => {
    return {
      type: 'UPDATE',
      domain,
      id,
      patch,
      [isCrudActionSymb]: true,
    };
  };

  const crudDelete = (id: K): CrudDelete<K> => {
    return {
      type: 'DELETE',
      domain,
      id,
      [isCrudActionSymb]: true,
    };
  };

  const crudDeleteByPartialKey = (id: Partial<K>): CrudDeleteByPartialKey<K> => {
    return {
      type: 'DELETE_BY_PARTIAL_KEY',
      domain,
      id,
      [isCrudActionSymb]: true,
    };
  };

  const crudPatchOrInsert = (id: K, value: S): CrudPatchOrInsert<S, K> => {
    return {
      type: 'PATCH_OR_INSERT',
      domain,
      id,
      value,
      [isCrudActionSymb]: true,
    };
  };

  const crudArrayAdd = (id: K, changeParameters: ArrayPropertyValue<S>): CrudArrayAdd<S, K> => {
    return {
      type: 'ARRAY_ADD',
      domain,
      id,
      changeParameters,
      [isCrudActionSymb]: true,
    };
  };

  const crudArrayRemove = (
    id: K,
    changeParameters: ArrayPropertyValue<S>,
  ): CrudArrayRemove<S, K> => {
    return {
      type: 'ARRAY_REMOVE',
      domain,
      id,
      changeParameters,
      [isCrudActionSymb]: true,
    };
  };

  const crudArrayAddAtIndex = (
    id: K,
    changeParameters: ArrayPropertyValueAndIndex<S>,
  ): CrudArrayAddAtIndex<S, K> => {
    return {
      type: 'ARRAY_ADD_AT_INDEX',
      domain,
      id,
      changeParameters,
      [isCrudActionSymb]: true,
    };
  };

  const crudArrayRemoveIndex = (
    id: K,
    changeParameters: ArrayPropertyIndex<S>,
  ): CrudArrayRemoveIndex<S, K> => {
    return {
      type: 'ARRAY_REMOVE_INDEX',
      domain,
      id,
      changeParameters,
      [isCrudActionSymb]: true,
    };
  };

  const crudArrayPatch = (
    id: K,
    changeParameters: ObjectArrayPropertyPatch<S>,
  ): CrudArrayPatch<S, K> => {
    return {
      type: 'ARRAY_PATCH',
      domain,
      id,
      changeParameters,
      [isCrudActionSymb]: true,
    };
  };

  return {
    actions: {
      insert: crudInsertAction,
      upsert: crudInsertOrUpdateAction,
      upsertMany: crudInsertOrUpdateManyAction,
      update: crudUpdateAction,
      delete: crudDelete,
      deleteByPartialKey: crudDeleteByPartialKey,
      patchOrInsert: crudPatchOrInsert,
      arrayAdd: crudArrayAdd,
      arrayAddAtIndex: crudArrayAddAtIndex,
      arrayRemove: crudArrayRemove,
      arrayRemoveIndex: crudArrayRemoveIndex,
      arrayPatch: crudArrayPatch,
    },
    reducer: crudReducer<S, K>(domain, keyIdConverter),
    selectors: {
      find: (map, key) => map[keyToString(key)],
      selectObjects: filterAndMapByPartialKey<S, K>(stringToKey),
      selectKeys: filterByPartialKey<S, K>(stringToKey),
    },
  };
}

function filterAndMapByPartialKey<S, K>(stringToKey: (id: string) => K) {
  return (map: StateMap<S>, selectBy: Partial<K>) => {
    return Object.keys(map)
      .filter(id => objectContains(stringToKey(id), selectBy))
      .map(id => map[id]);
  };
}

function filterByPartialKey<S, K>(stringToKey: (key: string) => K) {
  return (map: StateMap<S>, selectBy: Partial<K>): K[] => {
    return Object.keys(map)
      .filter((key: string) => objectContains(stringToKey(key), selectBy))
      .map((key: string) => stringToKey(key));
  };
}

export function objectContains<T>(all: T, partial: Partial<T>) {
  return Object.keys(partial)
    .map(key => key as keyof T)
    .every(key => all[key] === partial[key]);
}
