import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { ClientTermNotice } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EarlyTerminationBloc/ClientTermNotice';
import { DividentReqAdjustment } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EarlyTerminationBloc/DividendReqAdjustment';
import { RateSpreadAdjustment } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EarlyTerminationBloc/RateSpreadAdjustment';
import { SgTermNotice } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EarlyTerminationBloc/SgTermNotice';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';
import { commonElsBlocClasses, ElsFeaturesContext } from '../ElsFeatures';
import { useUpdateSectionHeight } from '../useUpdateSectionHeight';
import { EarlyTerminationConditions } from './EarlyTerminationConditions';
import { EarlyTerminationRights } from './EarlyTerminationRights';
import { EarlyTerminationType } from './EarlyTerminationType';
import { ElsTerminationPreconfComment } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/EarlyTerminationBloc/ElsTerminationPreconfComment.tsx';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

interface EarlyTerminationBlocProps {
  canBeHidden: boolean;
}

export function EarlyTerminationBloc({ canBeHidden }: EarlyTerminationBlocProps) {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  const { ref } = useUpdateSectionHeight(strategyId, 'Early Termination');

  if (!isElsProduct(product)) {
    return null;
  }

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Early Termination', false));
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <h5 className="d-inline-block p-1 m-0 mb-1">Early Termination</h5>
          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>

        <div className="d-flex flex-wrap">
          <EarlyTerminationType />
          <EarlyTerminationRights />
          <EarlyTerminationConditions />
          <SgTermNotice />
          <ClientTermNotice />
          <RateSpreadAdjustment />
          <DividentReqAdjustment />
        </div>
        <div className="d-flex flex-wrap">
          <ElsTerminationPreconfComment />
        </div>
      </div>
    </div>
  );
}
