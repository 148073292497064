import type { AnalyticCategory } from './logger';

export interface LogAnalyticsAction {
  type: 'ANALYTICS_LOGGING_REQUESTED';
  category: AnalyticCategory;
  name: string;
  additionalData?: string;
}

export function createLogAnalyticsAction(
  category: AnalyticCategory,
  name: string,
  additionalData?: string,
): LogAnalyticsAction {
  return {
    type: 'ANALYTICS_LOGGING_REQUESTED',
    category,
    name,
    additionalData,
  };
}
