import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { useState } from 'react';
import { useTimerWithAutomaticDuration } from '../../share/neosTimer/useNeosTimer';

export function usePricingDeadline(rfqId: string) {
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const { rfqPricingExpirationUTCTimestamp } = useAppSelector(state =>
    selectors.getRfqData(state, rfqId),
  );

  const { remainingTime, duration } = useTimerWithAutomaticDuration(
    rfqPricingExpirationUTCTimestamp,
  );

  const isStatusBeforePriceProposed = useAppSelector(state =>
    selectors.isStatusBeforePriceProposed(state, rfqId, selectors),
  );

  const isDisplayed = isStatusBeforePriceProposed;

  const displayTimeZone = useAppSelector(state =>
    selectors.getDisplayTimeZone(state.ui.userPreferences),
  );

  const canPlaySound = useAppSelector(state => state.ui.userPreferences.isAlertSoundPlayed);
  const isTrader = useAppSelector(state => selectors.isTrader(state));

  function toggleIsEditable() {
    setIsEditable(value => !value);
  }

  return {
    toggleIsEditable,
    isEditable,
    isDisplayed,
    displayTimeZone,
    isTrader,
    canPlaySound,
    remainingTime,
    duration,
    pricingDeadline: rfqPricingExpirationUTCTimestamp,
  };
}
