import type { Selectors } from '@/bootstrap/selectors';
import type { CcyPremiumModel } from '@/neos/components/rfq/totals/ccyPremium/CcyPremium';
import { getAskClassName, getBidClassName } from '@/neos/components/rfq/totals/getBidAskDisplay';
import { orderBy } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';

export function getCcyPremiumModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): CcyPremiumModel {
  const {
    totalPremiums,
    aggregatedDataByPriceUnit = {},
    clientWay,
    strategyIds,
  } = selectors.getRfqData(state, rfqId);

  // when strategyType === ELS there is always only one strategy, so we can safely take the first strategyId here
  const { strategyType } = selectors.getStrategyData(state, strategyIds[0]);

  const isDisplay = strategyType !== 'ELS';

  const unit = totalPremiums?.ask?.unit ?? totalPremiums?.bid?.unit;
  const ask = totalPremiums?.ask?.value;
  const bid = totalPremiums?.bid?.value;

  const aggBidAskList = Object.keys(aggregatedDataByPriceUnit).map(unit => {
    const weight = aggregatedDataByPriceUnit?.[unit]?.weight ?? 1;

    return {
      unit,
      ask: aggregatedDataByPriceUnit?.[unit]?.totalPremium?.ask?.value,
      bid: aggregatedDataByPriceUnit?.[unit]?.totalPremium?.bid?.value,
      bidClassName: getBidClassName(clientWay, weight),
      askClassName: getAskClassName(clientWay, weight),
    };
  });

  const aggBidAskOrdered = orderBy(aggBidAskList, agg => agg.unit);

  return {
    total: {
      unit,
      ask,
      bid,
    },
    aggBidAsk: aggBidAskOrdered,
    isDisplay,
  };
}
