import type { Selectors } from '@/bootstrap/selectors';
import type { ProductSubFamily } from '@/neos/business/neosModel';
import { orderOf } from '@/neos/business/services/statusOrder';
import type { AppState } from '@/bootstrap/store.ts';

export interface PreconfirmationButtonModelBase {
  isDisplayed: boolean;
  isDisabled: boolean;
  isPreconfirmationButtonModalOpened: boolean;
  isBulletDisplayed: boolean;
}
export interface PreconfirmationButtonModelWithBullet extends PreconfirmationButtonModelBase {
  isBulletDisplayed: true;
  bulletColor: 'success' | 'warning';
}
export interface PreconfirmationButtonModelWithoutBullet extends PreconfirmationButtonModelBase {
  isBulletDisplayed: false;
}
export type PreconfirmationButtonModel =
  | PreconfirmationButtonModelWithBullet
  | PreconfirmationButtonModelWithoutBullet;

export function getPreconfirmationButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PreconfirmationButtonModel {
  const { status } = selectors.getRfqData(state, rfqId);
  const isDisabled = orderOf(status).isBefore('PRE_TRADE_BOOKING_REQUESTED');

  const externalEmailStatus = selectors.getExternalEmailStatus(state, rfqId);
  const internalEmailStatus = selectors.getInternalEmailStatus(state, rfqId);

  const isPreconfirmationButtonModalOpened = selectors.getIsPreconfirmationModalOpened(
    state.ui,
    rfqId,
  );

  const isDisplayed = getIsPreconfirmationButtonDisplayed(state, rfqId, selectors);

  const isBulletDisplayed = [externalEmailStatus, internalEmailStatus].some(s => s !== 'NOT_SENT');

  if (isBulletDisplayed) {
    const bulletColor = [externalEmailStatus, internalEmailStatus]
      .filter(s => s !== 'NOT_SENT')
      .every(s => s === 'SENT')
      ? 'success'
      : 'warning';

    return {
      isDisplayed,
      isDisabled,
      isPreconfirmationButtonModalOpened,
      isBulletDisplayed,
      bulletColor,
    };
  }

  return {
    isDisplayed,
    isDisabled,
    isPreconfirmationButtonModalOpened,
    isBulletDisplayed,
  };
}

export function getIsPreconfirmationButtonDisplayed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const isGiveUpEmailFeatureToggleEnabled =
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmail.enabled') ||
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmailFuture.enable') ||
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmailDivFut.enable');

  if (!isGiveUpEmailFeatureToggleEnabled) {
    return false;
  }

  const rfqProducts = selectors.getRfqProducts(state, rfqId, selectors);
  const { strategyIds } = selectors.getRfqData(state, rfqId);

  const isThereAtLeastAnUnderlying = strategyIds.some(strategyId =>
    selectors.isStrategyUnderlyingsSet(state, strategyId, selectors),
  );

  if (!isThereAtLeastAnUnderlying) {
    return false;
  }

  const isOtcPreConfEnabled = selectors.isFeatureToggleEnabled(state, 'neos.otc.preconf.enabled');

  if (isOtcPreConfEnabled) {
    return true;
  }

  const isThereAtLeastOneStrategyListed = selectors.isThereAtLeastOneStratListed(
    state,
    rfqId,
    selectors,
  );

  if (!isThereAtLeastOneStrategyListed) {
    return false;
  }

  const giveUpEmailFutureEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.rfq.giveUpEmailFuture.enable',
  );

  const isDivFutureGiveUpEmailEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.rfq.giveUpEmailDivFut.enable',
  );

  const isAnyProductEnabledForPreconf = (productSubFamily: ProductSubFamily[]) => {
    return rfqProducts.some(({ subFamily }) => productSubFamily.includes(subFamily));
  };

  const products: ProductSubFamily[] = ['OPTION', 'OPTION_ON_FUTURE'];

  if (giveUpEmailFutureEnabled) {
    products.push('FUTURE', 'TOTAL_RETURN_FUTURE');
  }
  if (isDivFutureGiveUpEmailEnabled) {
    products.push('DIVIDEND_FUTURE');
  }

  return isAnyProductEnabledForPreconf(products);
}
