import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { brokersPreferencesActionCreators } from '@/neos/business/ui/userPreferences/brokers/brokersPreferencesActions';
import {
  createAddCounterpartPreferencesAction,
  createDeleteCounterpartPreferencesAction,
  createGetCurrentUserCounterpartsAction,
  createSearchCounterpartPreferencesAction,
} from '@/neos/business/ui/userPreferences/counterparties/counterpartPreferencesActions';
import type { Shortcut } from '@/neos/components/rfq/shortcuts/shared/ShortcutButton';
import type {
  Counterpart,
  ExcelDateFormat,
  ExcelNumberFormat,
  StrategyType,
} from '../../neosModel';
import { prefonfirmationPreferencesActionCreators } from '../prefonfirmationPreferences/prefonfirmationPreferencesActionCreators';
import type {
  AlertSoundSwitchedAction,
  BlotterDefaultTimerangeChangedAction,
  ChangeUserPreferenceAddCounterpartsLoadingAction,
  ChangeUserPreferenceDeleteCounterpartsLoadingAction,
  ChangeUserPreferenceSearchCounterpartsLoadingAction,
  DeltaToggleAction,
  DisplayTimeZoneChangedAction,
  ExcelDateFormatChangedAction,
  ExcelNumberFormatChangedAction,
  FairGreeksToggleAction,
  FairPricesToggleAction,
  IbChatSwitchedAction,
  IndicativeTradableChangedAction,
  InsertSearchedCounterpartsAction,
  NotificationGroupSubscriptionChangedAction,
  QuickButtonsSwitchedAction,
  QuickButtonStrategyToggleAction,
  QuickButtonUnderlyingToggleAction,
  SalesPricesToggleAction,
  StrategiesShortcutsListUpdateAction,
  UnderlyingShortcutsListUpdateAction,
  UserPreferencesDisplayedAction,
  UserPreferencesHiddenAction,
  UserPreferencesLoadedAction,
  VolBidAskToggleAction,
} from './userPreferencesUiActions';
import type {
  BlotterDefaultTimerange,
  ColumnsDisplayToggle,
  CustomizableQuickButtonToggle,
  DisplayTimeZone,
} from './userPreferencesUiModel';
import type { UserPreferencesState } from './userPreferencesUiState';
import type { Action } from '@/bootstrap/actions.ts';

export const userPreferencesUiActionCreators = {
  createUserPreferencesHiddenAction,
  createUserPreferencesDisplayedAction,
  createIbChatSwitchedAction,
  createQuickButtonsSwitchedAction,
  createQuickButtonStrategyToggleAction,
  createQuickButtonUnderlyingToggleAction,
  createUpdateUnderlyingShortcutsListAction,
  createUserPreferencesDisplayedBatchAction,
  createSalesPricesToggleAction,
  createDeltaToggleAction,
  createFairPricesToggleAction,
  createFairGreeksToggleAction,
  createExcelNumberFormatChangedAction,
  createExcelDateFormatChangedAction,
  createDisplayTimeZoneChangedAction,
  createBlotterDefaultTimerangeChangedAction,
  createNotificationGroupSubscriptionChangedAction,
  createUserPreferencesLoadedAction,
  createIndicativeTradableChangedAction,
  createVolBidAskToggleAction,
  createAlertSoundSwitchedAction,
  createUpdateStrategiesShortcutsListAction,
  createAddCounterpartPreferencesAction,
  createSearchCounterpartPreferencesAction,
  createDeleteCounterpartPreferencesAction,
  createGetCurrentUserCounterpartsAction,
  createInsertSearchedCounterpartsAction,
  createChangeUserPreferenceAddCounterpartsLoadingAction,
  createChangeUserPreferenceDeleteCounterpartsLoadingAction,
  createChangeUserPreferenceSearchCounterpartsLoadingAction,
  ...brokersPreferencesActionCreators,
  ...prefonfirmationPreferencesActionCreators,
};

function createUserPreferencesDisplayedBatchAction(): Action[] {
  return [
    createUserPreferencesDisplayedAction(),
    createLogAnalyticsAction('NEOS RFQ', 'User preferences menu hit'),
  ];
}

export function createInsertSearchedCounterpartsAction(
  counterparts: Counterpart[],
): InsertSearchedCounterpartsAction {
  return {
    type: 'USER_PREFERENCES_INSERT_SEARCHED_COUNTERPARTS',
    counterparts,
  };
}

export function createUserPreferencesHiddenAction(): UserPreferencesHiddenAction {
  return {
    type: 'USER_PREFERENCES_HIDDEN',
  };
}

export function createUserPreferencesDisplayedAction(): UserPreferencesDisplayedAction {
  return {
    type: 'USER_PREFERENCES_DISPLAYED',
  };
}

export function createIbChatSwitchedAction(toggle: boolean): IbChatSwitchedAction {
  return {
    type: 'IBCHAT_SWITCHED',
    toggle,
  };
}

export function createUpdateUnderlyingShortcutsListAction(
  underlyingsList: Shortcut<string>[],
): UnderlyingShortcutsListUpdateAction {
  return {
    type: 'USER_PREFERENCES_UNDERLYING_SHORTCUTS_LIST_UPDATED',
    underlyingsList,
  };
}

export function createUpdateStrategiesShortcutsListAction(
  strategiesList: Shortcut<StrategyType>[],
): StrategiesShortcutsListUpdateAction {
  return {
    type: 'USER_PREFERENCES_STRATEGIES_SHORTCUTS_LIST_UPDATED',
    strategiesList,
  };
}

export function createAlertSoundSwitchedAction(toggle: boolean): AlertSoundSwitchedAction {
  return {
    type: 'ALERT_SOUND_SWITCHED',
    toggle,
  };
}

export function createQuickButtonsSwitchedAction(toggle: boolean): QuickButtonsSwitchedAction {
  return {
    type: 'QUICK_BUTTONS_SWITCHED',
    toggle,
  };
}

export function createQuickButtonStrategyToggleAction(
  toggle: CustomizableQuickButtonToggle,
): QuickButtonStrategyToggleAction {
  return {
    type: 'QUICK_BUTTON_STRATEGY_TOGGLE_ACTION',
    toggle,
  };
}

export function createQuickButtonUnderlyingToggleAction(
  toggle: CustomizableQuickButtonToggle,
): QuickButtonUnderlyingToggleAction {
  return {
    type: 'QUICK_BUTTON_UNDERLYING_TOGGLE_ACTION',
    toggle,
  };
}

export function createSalesPricesToggleAction(
  toggle: ColumnsDisplayToggle,
): SalesPricesToggleAction {
  return {
    type: 'SALES_PRICES_TOGGLE_ACTION',
    toggle,
  };
}

export function createDeltaToggleAction(toggle: ColumnsDisplayToggle): DeltaToggleAction {
  return {
    type: 'DELTA_TOGGLE_ACTION',
    toggle,
  };
}

export function createFairPricesToggleAction(toggle: ColumnsDisplayToggle): FairPricesToggleAction {
  return {
    type: 'FAIR_PRICES_TOGGLE_ACTION',
    toggle,
  };
}

export function createFairGreeksToggleAction(toggle: ColumnsDisplayToggle): FairGreeksToggleAction {
  return {
    type: 'FAIR_GREEKS_TOGGLE_ACTION',
    toggle,
  };
}

export function createExcelNumberFormatChangedAction(
  excelNumberFormat: ExcelNumberFormat,
): ExcelNumberFormatChangedAction {
  return {
    type: 'EXCEL_NUMBER_FORMAT_CHANGED',
    excelNumberFormat,
  };
}

export function createExcelDateFormatChangedAction(
  excelDateFormat: ExcelDateFormat,
): ExcelDateFormatChangedAction {
  return {
    type: 'EXCEL_DATE_FORMAT_CHANGED',
    excelDateFormat,
  };
}

export function createDisplayTimeZoneChangedAction(
  displayTimeZone: DisplayTimeZone,
): DisplayTimeZoneChangedAction {
  return {
    type: 'DISPLAY_TIMEZONE_CHANGED',
    displayTimeZone,
  };
}

export function createBlotterDefaultTimerangeChangedAction(
  blotterDefaultTimerange: BlotterDefaultTimerange,
): BlotterDefaultTimerangeChangedAction {
  return {
    type: 'BLOTTER_DEFAULT_TIMERANGE_CHANGED',
    blotterDefaultTimerange,
  };
}

export function createNotificationGroupSubscriptionChangedAction(
  notificationGroupId: number,
  enable: boolean,
): NotificationGroupSubscriptionChangedAction {
  return {
    type: 'NOTIFICATION_GROUP_SUBSCRIPTION_CHANGED',
    notificationGroupId,
    enable,
  };
}

export function createUserPreferencesLoadedAction(
  userPreferences: UserPreferencesState,
): UserPreferencesLoadedAction {
  return {
    type: 'USER_PREFERENCES_LOADED',
    userPreferences,
  };
}

export function createChangeUserPreferenceAddCounterpartsLoadingAction(
  value: boolean,
): ChangeUserPreferenceAddCounterpartsLoadingAction {
  return {
    type: 'USER_PREFERENCES_ADD_COUNTERPARTS_LOADING',
    value,
  };
}

export function createChangeUserPreferenceDeleteCounterpartsLoadingAction(
  value: boolean,
): ChangeUserPreferenceDeleteCounterpartsLoadingAction {
  return {
    type: 'USER_PREFERENCES_DELETE_COUNTERPARTS_LOADING',
    value,
  };
}

export function createChangeUserPreferenceSearchCounterpartsLoadingAction(
  value: boolean,
): ChangeUserPreferenceSearchCounterpartsLoadingAction {
  return {
    type: 'USER_PREFERENCES_SEARCH_COUNTERPARTS_LOADING',
    value,
  };
}

export function createIndicativeTradableChangedAction(
  isIndicativeTradableDisplayed: boolean,
): IndicativeTradableChangedAction {
  return {
    type: 'INDICATIVE_TRADABLE_CHANGED_ACTION',
    isIndicativeTradableDisplayed,
  };
}

export function createVolBidAskToggleAction(toggle: ColumnsDisplayToggle): VolBidAskToggleAction {
  return {
    type: 'VOL_BID_ASK_TOGGLE_ACTION',
    toggle,
  };
}
