import type { Selectors } from '@/bootstrap/selectors';
import type { FromOnyxMappers, ToOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import {
  type BasketCompositionDetails,
  type Derivative,
  type Product,
  type SingleUnderlyingDerivative,
  isDerivativeProduct,
  isElsBasketProduct,
} from '../../productModel';
import {
  type OnyxBasketUnderlying,
  type OnyxProduct,
  type OnyxProductUnderlying,
  type OnyxSingleUnderlyingProduct,
  isOnyxBasketUnderlying,
} from '../../productOnyxModel';
import type { AppState } from '@/bootstrap/store.ts';

export const toDerivativeProductMappers = { mapToOnyxDerivativeProduct };

export const fromDerivativeProductMappers = {
  mapFromSingleDerivativeOnyxProduct,
  mapFromElsDerivativeOnyxProduct,
};

export interface OnyxDerivativeProduct {
  underlying?: OnyxProductUnderlying | OnyxBasketUnderlying;
}

function mapToOnyxDerivativeProduct(
  state: AppState,
  productId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxDerivativeProduct {
  const product = selectors.getProduct(state, productId);
  if (!isDerivativeProduct(product)) {
    /**
     * TODO: once Onyx Stock model is fixed (see CLINEG-1023) replace by
     * return undefined
     */
    return hackForStockModelError(state, product, selectors, mappers);
  }

  if (isElsBasketProduct(product)) {
    const { basketComposition: neosBasketComposition, ...basketRest } = product.basketUnderlying;

    const underlying: OnyxBasketUnderlying = {
      ...basketRest,
      basketComposition: neosBasketComposition
        .filter(composition => composition.underlyingId !== undefined)
        .map(({ underlyingId, ...basketCompositionDetailsRest }) => ({
          ...basketCompositionDetailsRest,
          underlying: mappers.mapToOnyxUnderlying(state, underlyingId, selectors),
        })),
    };
    return { underlying };
  }

  return { underlying: mappers.mapToOnyxUnderlying(state, product.underlyingId, selectors) };
}

function mapFromElsDerivativeOnyxProduct(
  onyxProduct: OnyxProduct,
  { mapFromOnyxUnderlying }: FromOnyxMappers,
): Derivative {
  if (isOnyxBasketUnderlying(onyxProduct.underlying)) {
    const neosBasketComposition: BasketCompositionDetails[] =
      onyxProduct.underlying.basketComposition?.map((onyxBasketCompositionDetails, index) => ({
        containerIndex: onyxBasketCompositionDetails.containerIndex ?? index, // Defaulting to array index for the older data that don't have a containerIndex in db
        underlyingId: onyxBasketCompositionDetails.underlying?.id,
        nominal: onyxBasketCompositionDetails.nominal
          ? {
              value: onyxBasketCompositionDetails.nominal.value ?? undefined,
              unit: onyxBasketCompositionDetails.nominal.unit ?? undefined,
              type: onyxBasketCompositionDetails.nominal.type ?? undefined,
            }
          : undefined,
        execFeesIn: onyxBasketCompositionDetails.execFeesIn ?? undefined,
        execFeesOut: onyxBasketCompositionDetails.execFeesOut ?? undefined,
        quantity: onyxBasketCompositionDetails.quantity ?? undefined,
        weight: onyxBasketCompositionDetails.weight ?? undefined,
      })) ?? [];

    const sortedBasketComposition = neosBasketComposition.sort((a, b) =>
      a.containerIndex > b.containerIndex ? 1 : -1,
    );

    return {
      underlyingKind: 'BASKET',
      basketUnderlying: {
        discriminator: 'BASKET_UNDERLYING',
        type: 'BASKET',
        basketDivisor: onyxProduct.underlying.basketDivisor ?? undefined,
        basketEliotID: onyxProduct.underlying.basketEliotID ?? undefined,
        basketMarket: onyxProduct.underlying.basketMarket
          ? {
              galaxyCode: onyxProduct.underlying.basketMarket.galaxyCode ?? undefined,
              micCode: onyxProduct.underlying.basketMarket.micCode ?? undefined,
            }
          : undefined,
        basketMultiplier: onyxProduct.underlying.basketMultiplier ?? undefined,
        basketType: onyxProduct.underlying.basketType ?? undefined,
        basketTypology: onyxProduct.underlying.basketTypology ?? undefined,
        currency: onyxProduct.underlying.currency ?? undefined,
        execFees: onyxProduct.underlying.execFees ?? 'NONE',
        forexType: onyxProduct.underlying.forexType ?? undefined,
        isAutoDiv: onyxProduct.underlying.isAutoDiv ?? undefined,
        isAutoRepo: onyxProduct.underlying.isAutoRepo ?? undefined,
        repoSource: onyxProduct.underlying.repoSource ?? undefined,
        basketComposition: sortedBasketComposition,
      },
      isDerivativeProduct: true,
      maturity: onyxProduct.maturity ?? undefined,
    };
  }
  return {
    isDerivativeProduct: true,
    underlyingKind: 'SINGLE',
    maturity: convertNullToUndefined(onyxProduct.maturity),
    underlyingId: mapFromOnyxUnderlying(onyxProduct.underlying),
  };
}

function mapFromSingleDerivativeOnyxProduct(
  onyxProduct: OnyxSingleUnderlyingProduct,
  { mapFromOnyxUnderlying }: FromOnyxMappers,
): SingleUnderlyingDerivative {
  return {
    isDerivativeProduct: true,
    underlyingKind: 'SINGLE',
    maturity: convertNullToUndefined(onyxProduct.maturity),
    underlyingId: mapFromOnyxUnderlying(onyxProduct.underlying),
  };
}

// TODO: Remove it onces the error is fixed (see CLINEG-1023)
function hackForStockModelError(
  state: AppState,
  product: Product,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxDerivativeProduct {
  if (product.subFamily !== 'STOCK' || !product.refId) {
    return { underlying: undefined };
  }

  return { underlying: mappers.mapToOnyxUnderlying(state, product.refId, selectors) };
}
