import type { Selectors } from '@/bootstrap/selectors';
import { type OptionFlex, isOptionLike } from '../../../../../../../neos/business/neosModel';
import type { Field } from '../utils/Field';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import { getFieldsArray } from '../utils/getFieldsArray';
import type { AppState } from '@/bootstrap/store.ts';

export interface OptionFlexCellsModel {
  optionFlexs: Array<Field<OptionFlex | undefined>>;
  availableFlexs: Array<OptionFlex | undefined>;
  showFlexWarning: boolean;
}

export function getOptionFlexCellsModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): OptionFlexCellsModel {
  const { getStrategyProducts, isListedLeg } = selectors;
  const { isStatusStrictlyBeforeOrderAccepted } = selectors;

  const products = getStrategyProducts(appState, strategyId, selectors).filter(isOptionLike);

  const optionFlexsAreEditable = getIsStrategyDefinitionFieldEditable(
    appState,
    rfqId,
    strategyId,
    selectors,
    ['RFQ_SCOPE', 'RFQ_EDITABLE', 'STRATEGY_EDITABLE'],
  );

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );

  const isReadonly = !optionFlexsAreEditable || isReadonlyAtWorkflow;
  const optionFlexs = getFieldsArray(products, 'flex', false, isReadonly);

  let showFlexWarning = false;

  const warnings = selectors.getStrategyWarnings(appState, rfqId, strategyId, selectors);
  const beforeOrderAccepted = isStatusStrictlyBeforeOrderAccepted(appState, rfqId, selectors);

  const optionFlexsWithEditableValue = optionFlexs.map(option => ({
    ...option,
    isEditable: isListedLeg(appState, option.id, selectors) && beforeOrderAccepted,
  }));

  if (
    warnings.length &&
    !optionFlexsWithEditableValue.every(({ value }) => value === 'FLEX') &&
    beforeOrderAccepted &&
    !warnings.every(w => w.warning === undefined)
  ) {
    showFlexWarning = true;
  }

  return {
    optionFlexs: optionFlexsWithEditableValue,
    availableFlexs: ['FLEX', 'NON_FLEX', undefined],
    showFlexWarning,
  };
}
