import type { Thunk } from '@/bootstrap/thunks.ts';
import type { ElsSectionName } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel.ts';

export function createDisableElsSectionThunk(sectionName: ElsSectionName): Thunk {
  return function disableElsSectionThunk(
    dispatch,
    getState,
    {
      selectors: { getElsSections },
      actionCreators: {
        neos: { upsertElsSections },
      },
    },
  ) {
    const state = getState();
    const elsSections = getElsSections(state);

    dispatch(
      upsertElsSections(
        elsSections.map(elsSection => {
          if (elsSection.i === sectionName) {
            elsSection.isDisplayed = false;
          }
          return elsSection;
        }),
      ),
    );
  };
}
