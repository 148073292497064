import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import {
  type PtmmmDispatchProps,
  type PtmmmOwnProps,
  type PtmmmStateProps,
  StrategyPtmmmStyled,
} from '@/neos/components/rfq/strategies/strategy/prices/strategyPtmmm/StrategyPtmmm';
import { getStrategyPtmmmModel } from '@/neos/components/rfq/strategies/strategy/prices/strategyPtmmm/getStrategyPtmmmModel';
import { connect } from 'react-redux';
import type { AppState } from '@/bootstrap/store.ts';

export const StrategyPtmmm = connect(mapStateToProps, mapDispatchToProps)(StrategyPtmmmStyled);

function mapStateToProps(state: AppState, { strategyId, rfqId }: PtmmmOwnProps): PtmmmStateProps {
  return getStrategyPtmmmModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId }: PtmmmOwnProps,
): PtmmmDispatchProps {
  return {
    onStrategyPtmmmChange(value: number | undefined) {
      dispatch(neosThunks.createChangeStrategyMidThunk(strategyId, value, true));
    },
    onLegPtmmmChange(legId: string, value: number | undefined) {
      dispatch(neosThunks.createChangeLegMidThunk(legId, value, true));
    },
  };
}
