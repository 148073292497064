import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { AllocationUi } from '@/neos/business/ui/allocation/allocationUiModel';
import { distinct } from '@/util/array/arrayUtils';
import type { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import type { OtcAllocation } from '../../../../../../../business/neosModel';
import {
  type AllocRow,
  ImportOtcAllocationsComponent,
  type ImportOtcAllocationsDispatchProps,
  type ImportOtcAllocationsOwnProps,
  type ImportOtcAllocationsStateProps,
} from './ImportOtcAllocations';
import { getImportOtcAllocationsModel } from './ImportOtcAllocationsModel';

export const ImportOtcAllocationsButton: FC<ImportOtcAllocationsOwnProps> = ownProps => {
  const dispatch = useDispatch<ExtendedDispatch>();
  const stateProps = useAppSelector(state =>
    getImportOtcAllocationsModel(state, ownProps.rfqId, selectors),
  );

  const dispatchProps = mapDispatchToProps(dispatch, ownProps, stateProps);
  return <ImportOtcAllocationsComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

export function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: PropsWithChildren<ImportOtcAllocationsOwnProps>,
  { clients, legs }: ImportOtcAllocationsStateProps,
  idCreator = services.idCreator,
): ImportOtcAllocationsDispatchProps {
  return {
    onImport: (rows: unknown[]) => {
      dispatch(neosActionCreators.otcAllocationCrudActions.deleteByPartialKey({ rfqId }));

      const extraClients: number[] = [];

      rows.forEach(row => {
        legs.forEach(leg => {
          leg.allocIds.forEach(id =>
            dispatch(neosActionCreators.allocationUiCrudActions.delete(id)),
          );

          const uuid = idCreator.createId();

          const [validatedRow, errors] = validateRow(row as AllocRow);

          const size: Partial<OtcAllocation> = {
            sizeType: leg.onChangeSizeType,
          };
          switch (leg.uiSizeType) {
            case 'QUANTITY':
              size.quantity = validatedRow.sizeValue;
              break;
            case 'VAR_UNIT':
              size.varUnit = {
                unit: 'VU',
                type: 'VAR_UNIT',
                value: validatedRow.sizeValue,
              };

              break;
            default:
              size.notional = {
                unit: leg.ccy,
                type: 'CCY',
                value: validatedRow.sizeValue,
              };

              break;
          }
          const alloc: OtcAllocation = {
            uuid,
            legId: leg.legId,
            counterpartId: validatedRow.counterpartId,
            independantAmountValue: validatedRow.iaValue,
            independantAmountType: validatedRow.iaType === '%' ? 'REF_PERCENT' : 'CCY',
            independantAmountUnit: validatedRow.iaType,
            independantAmountCurrencyValue: undefined,
            ...size,
            media: undefined,
            secondaryInfo: undefined,
            broker: undefined, // TODO: Verify if the broker is imported
            markitWireEligible: undefined,
            isCancelled: false,
            updateJustificationLabel: undefined,
          };

          dispatch(
            neosActionCreators.otcAllocationCrudActions.insert(
              {
                allocationId: uuid,
                legId: alloc.legId,
                rfqId,
              },
              alloc,
            ),
            neosActionCreators.allocationUiCrudActions.patchOrInsert(uuid, { errors }),
          );

          if (alloc.counterpartId) {
            dispatch(
              neosActionCreators.electronicMediaUsersActionCreators.createElectronicMediaUsersRequestedAction(
                alloc.counterpartId,
              ),
            );

            if (!clients.includes(alloc.counterpartId)) {
              extraClients.push(alloc.counterpartId);
            }
          }
        });
      });

      distinct(extraClients).forEach(c =>
        dispatch(neosActionCreators.createCounterpartRequestedAction(rfqId, c)),
      );
      dispatch(
        neosActionCreators.createNeosPredealCheckRefreshRequestedAction(rfqId),
        neosActionCreators.createDefaultConfirmationMediaRequestedAction(rfqId),
      );
    },
  };
}

export type Row = { counterpartId?: number; sizeValue?: number; iaType?: string; iaValue?: number };

export function validateRow(row: AllocRow): [Row, AllocationUi['errors']] {
  const [counterpart, size, type, ia] = row;
  const validatedRow: Row = {};
  const errors: AllocationUi['errors'] = {};

  const c = parseInt(counterpart || '');
  if (Number.isInteger(c) && c > 0) {
    validatedRow.counterpartId = c;
  } else {
    errors.Counterparty = true;
  }

  const s = parseFloat(size || '');
  if (Number.isFinite(s) && s > 0) {
    validatedRow.sizeValue = s;
  } else {
    errors.Size = true;
  }

  if (['%', 'EUR', 'USD', 'GBP'].includes(type || '')) {
    validatedRow.iaType = type;
  } else {
    errors.IaType = true;
  }

  const i = parseFloat(ia || '');
  if (Number.isFinite(i) && i > 0) {
    validatedRow.iaValue = i;
  } else {
    errors.IaValue = true;
  }

  return [validatedRow, errors];
}
