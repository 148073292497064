import type { Selectors } from '@/bootstrap/selectors';
import { isClsProduct, isElsProduct } from '../../../../../../business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

interface GenerateFromDates {
  strikeDate: string | undefined;
  effectiveDate: string | undefined;
  settlementDate: string | undefined;
  maturity: string | undefined;
}

export function getGenerateFromValues(
  strategyId: string,
  state: AppState,
  selectors: Selectors,
): GenerateFromDates {
  const product = selectors.getStrategyProduct(state, strategyId, selectors);
  if (!isElsProduct(product) && !isClsProduct(product)) {
    throw new Error('product is neither an els nor a cls');
  }
  const strikeDate = product.strikeDate;
  const effectiveDate = product.effectiveDate;
  const maturity = product.maturity;

  const settlementDate = selectors.getStrategyLegsData(state, strategyId, selectors)[0].settlement
    ?.date?.value;

  return {
    strikeDate,
    effectiveDate,
    settlementDate,
    maturity,
  };
}
