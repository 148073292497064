import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { NumericInput } from '@/neos/components/share/numericInput';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function InitialStrike({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useAppDispatch();
  const currentAllocation = useAppSelector(state =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const initialStrike = currentAllocation?.stepInInfo?.initialStrike;

  const quote = useAppSelector(state =>
    selectors.getQuote(state, selectors.getRfqData(state, rfqId).quoteId),
  );

  const initialSalesPrice = quote?.initialSalesPrice;

  const initialValues = {
    type: initialSalesPrice?.ask?.type ?? initialSalesPrice?.bid?.type ?? quote?.salesAskType,
    unit: initialSalesPrice?.ask?.unit ?? initialSalesPrice?.bid?.unit ?? quote?.salesAskUnit,
  };

  const onInitialStrikeChange = (value: number | undefined) => {
    dispatch(
      thunks.neos.createUpdateStepInInfoThunk(allocationId, rfqId, legId, {
        initialStrike: {
          type: initialValues.type,
          unit: initialValues.unit,
          value,
        },
      }),
    );
  };

  return (
    <NumericInput
      data-e2e={`otc-alloc-stepin-initial-strike`}
      onBlur={onInitialStrikeChange}
      unit={initialStrike?.unit ?? initialValues.unit}
      value={initialStrike?.value}
    />
  );
}
