import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export function getOpenedRfqIds(state: AppState, selectors: Selectors) {
  return selectors
    .getTabs(state.ui.tabManagement)
    .map(({ id }) => id)
    .filter(tabId => tabId !== 'BLOTTER')
    .filter(rfqId => getIsRfqHasEverBeenSaved(rfqId, state, selectors));
}

export function getIsRfqHasEverBeenSaved(
  rfqId: string,
  appState: AppState,
  { getVersions }: Selectors,
): boolean {
  return getVersions(appState, rfqId).length !== 0;
}
