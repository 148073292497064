import type { Selectors } from '@/bootstrap/selectors';
import {
  type DeterminationMethod,
  type Tenor,
  isElsProduct,
} from '../../../../../../../neos/business/neosModel';
import type { FeatureComponentModel } from '../sharedFeatureComponentModel';
import type { AppState } from '@/bootstrap/store.ts';

export type ForwardStartModel = FeatureComponentModel<{
  forwardStartDate: string | undefined;
  determinationMethod: DeterminationMethod | undefined;
  maturities: string[];
  isEls: boolean;
  tenors: Tenor[];
  isReadonly: boolean;
}>;

export function getForwardStartModel(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): ForwardStartModel {
  const neosSelectors = selectors;
  const isDisplayed = neosSelectors.isStrategyFeatureMandatoryOrDefined(
    appState,
    { strategyId, type: 'FORWARD_START' },
    selectors,
  );

  if (!isDisplayed) {
    return { isDisplayed };
  }

  const feature = neosSelectors.getFeature(appState.featureState, {
    strategyId,
    type: 'FORWARD_START',
  });

  const forwardStartDate = feature?.forwardStartDate;
  const determinationMethod = feature?.determinationMethod;
  const legId = neosSelectors.getStrategyData(appState, strategyId).legIds[0];
  const maturities: string[] = neosSelectors.getLegMaturities(appState, legId, selectors);

  const product = neosSelectors.getStrategyProduct(appState, strategyId, selectors);
  const tenors = selectors.getTenors(appState.referenceData);
  const isEls = isElsProduct(product);
  const isReadonly = selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors);

  return {
    isDisplayed,
    forwardStartDate,
    determinationMethod,
    maturities,
    isEls,
    tenors,
    isReadonly,
  };
}
