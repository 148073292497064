import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import type { NegoStep } from '@/neos/business/ui/rfq/rfqUiModel';
import { connect } from 'react-redux';
import {
  NegoStepSwitcherComponent,
  type NegoStepSwitcherDispatchProps,
  type NegoStepSwitcherOwnProps,
  type NegoStepSwitcherStateProps,
} from './NegoStepSwitcher';
import { getNegoStepSwitcherModel } from './getNegoStepSwitcherModel';
import type { AppState } from '@/bootstrap/store.ts';

export const NegoStepSwitcher = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NegoStepSwitcherComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: NegoStepSwitcherOwnProps,
): NegoStepSwitcherStateProps {
  return getNegoStepSwitcherModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: NegoStepSwitcherOwnProps,
): NegoStepSwitcherDispatchProps {
  return {
    onStepChanged: (targetStep: NegoStep) => {
      dispatch(
        createLogAnalyticsAction('NEOS RFQ', 'Switch to target step', targetStep),
        neosThunks.createNeosSwitchNegoStepThunk(rfqId, targetStep),
      );
    },
  };
}
