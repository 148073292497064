import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { DefaultingScope, isClsProduct, isElsProduct } from '@/neos/business/neosModel';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';
import {
  type ResetType,
  resetTypeValues,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';

export function RateReset({ strategyId, rfqId }: { rfqId: string; strategyId: string }) {
  const dispatch = useAppDispatch();
  const isReadonly = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product) && !isClsProduct(product)) {
    throw new Error('product is neither an els nor a cls');
  }

  function onRateResetTypeChanged(value: ResetType | undefined) {
    dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true));
    dispatch(actionCreators.neos.productCrudActions.update(uuid, { rateReset: value }));
    if (isClsProduct(product)) {
      const overrideScopes = [DefaultingScope.UNDERLYING];
      const defaultingOptions = new DefaultingOptions({ overrideScopes });
      dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, defaultingOptions));
    }

    if (isElsProduct(product)) {
      const defaultingOptions = new DefaultingOptions({
        overrideScopes: [DefaultingScope.RESET_FREQUENCY],
      });
      dispatch(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, defaultingOptions));
    }
  }

  const { rateReset, uuid } = product;
  const label = isElsProduct(product) ? 'Rate Reset Type' : 'Reset Type';
  return (
    <>
      <label className="p-1">{label}</label>
      <NeosSelect
        options={Object.entries(resetTypeValues).map(([key, value]) => ({
          label: value,
          value: key as ResetType,
        }))}
        readOnly={isReadonly}
        addEmptyOption
        onChange={onRateResetTypeChanged}
        value={rateReset}
        data-e2e="schedule-rate-reset-type"
      />
    </>
  );
}
