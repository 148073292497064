import { selectors } from '@/bootstrap/selectors';
import { connect } from 'react-redux';
import { NeosAppComponent, type NeosAppMapStateToProps } from './NeosApp';
import { getNeosAppModel } from './getNeosAppModel';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(state: AppState): NeosAppMapStateToProps {
  return getNeosAppModel(state, selectors);
}

export const NeosApp = connect(mapStateToProps)(NeosAppComponent);
