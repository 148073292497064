import type { Selectors } from '@/bootstrap/selectors';
import type { PredealCheckWithoutLegsType } from '@/neos/business/predealCheck/predealCheckModel';
import type { BulletColor } from '../../../predealCheckBullet';
import { convertToBulletColor } from '../../../util';
import type { AppState } from '@/bootstrap/store.ts';

type Title = 'KYC' | 'MIFID' | 'Agg. Risk Ask' | 'Agg. Risk Bid';

interface CommonModel {
  title: Title;
  color: BulletColor;
  isLoading: boolean;
}

interface UnknownModel extends CommonModel {
  isUnknown: true;
  color: 'unknown';
  selectedStyle: 'predeal-check-unselected';
  selectableStyle: '';
}

interface SelectedModel extends CommonModel {
  isUnknown: false;
  isSelectable: false;
  selectedStyle: 'predeal-check-selected';
  selectableStyle: '';
}

interface SelectableModel extends CommonModel {
  isUnknown: false;
  isSelectable: true;
  predealCheckId: string;
  selectedStyle: 'predeal-check-unselected';
  selectableStyle: 'predeal-check-selectable';
}

export type RequestPredealCheckCellModel = SelectedModel | SelectableModel | UnknownModel;

const titleMap: { [k in PredealCheckWithoutLegsType]: Title } = {
  KYC: 'KYC',
  MIFID1: 'MIFID',
  CREDIT_CHECK_ASK: 'Agg. Risk Ask',
  CREDIT_CHECK_BID: 'Agg. Risk Bid',
};

export function getRequestPredealCheckCellModel(
  state: AppState,
  rfqId: string,
  predealCheckType: PredealCheckWithoutLegsType,
  bdrId: number | undefined,
  selectors: Selectors,
): RequestPredealCheckCellModel {
  const predealChecks = selectors.getNeosPredealChecksByTypeAndLevel(
    state,
    rfqId,
    predealCheckType,
    'REQUEST',
    selectors,
  );
  const predealCheck = predealChecks.find(pdc => pdc.counterpartyId === bdrId);
  const title = titleMap[predealCheckType];
  if (!predealCheck) {
    return {
      isUnknown: true,
      title,
      color: 'unknown',
      selectableStyle: '',
      selectedStyle: 'predeal-check-unselected',
      isLoading: false,
    };
  }

  const color = convertToBulletColor(predealCheck.uiColor);
  const selectedPredealCheckId = selectors.getUiSelectedPredealCheckId(state.ui, rfqId);

  const isLoading = predealCheck.status === 'COMPUTING';

  return selectedPredealCheckId === predealCheck.uuid
    ? {
        isUnknown: false,
        isSelectable: false,
        color,
        title,
        selectableStyle: '',
        selectedStyle: 'predeal-check-selected',
        isLoading,
      }
    : {
        isUnknown: false,
        isSelectable: true,
        predealCheckId: predealCheck.uuid,
        color,
        title,
        selectableStyle: 'predeal-check-selectable',
        selectedStyle: 'predeal-check-unselected',
        isLoading,
      };
}
