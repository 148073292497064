import type { Selectors } from '@/bootstrap/selectors';
import { isDividendFutureProduct } from '../../../../../../../neos/business/neosModel';
import type { FeatureComponentModel } from '../sharedFeatureComponentModel';
import type { AppState } from '@/bootstrap/store.ts';

export type EndOfObservationModel = FeatureComponentModel<{
  endOfObservation: string | undefined;
}>;

export function getEndOfObservationModel(
  appState: AppState,
  strategyId: string,
  selectors: Selectors,
): EndOfObservationModel {
  const neosSelectors = selectors;
  const isDisplayed = neosSelectors.isStrategyFeatureMandatoryOrDefined(
    appState,
    { strategyId, type: 'END_OF_OBSERVATION' },
    selectors,
  );

  if (!isDisplayed) {
    return { isDisplayed };
  }

  const feature = neosSelectors.getFeature(appState.featureState, {
    strategyId,
    type: 'END_OF_OBSERVATION',
  });
  const product = neosSelectors.getStrategyProduct(appState, strategyId, selectors);

  if (
    !isDividendFutureProduct(product) ||
    (isDividendFutureProduct(product) && product.maturity === feature?.endOfObservation)
  ) {
    return { isDisplayed: false };
  }
  return {
    isDisplayed,
    endOfObservation: feature?.endOfObservation,
  };
}
