import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { ElsType } from '../../../../../../../neos/business/neosOnyxModel';
import {
  ElsTypeCells as Component,
  type ElsTypeCellsDispatchProps,
  type ElsTypeCellsMapStateProps,
  type ElsTypeCellsOwnProps,
} from './ElsTypeCells';
import { getElsTypeCellsModel } from './getElsTypeCellsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const ElsTypeCells = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: ElsTypeCellsOwnProps,
): ElsTypeCellsMapStateProps {
  return getElsTypeCellsModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: ElsTypeCellsOwnProps,
): ElsTypeCellsDispatchProps {
  return {
    onElsTypeChanged(productId: string, elsType: ElsType | undefined) {
      dispatch(neosThunks.createUpdateProductElsTypeThunk(rfqId, strategyId, productId, elsType));
    },
  };
}
