import type { Selectors } from '@/bootstrap/selectors';
import type { PredealCheckWithoutLegsType } from '@/neos/business/predealCheck/predealCheckModel';
import { predealChecksWithoutLegsTypes } from '@/neos/business/predealCheck/predealCheckModel/common';
import type { Counterpart } from '../../../../../business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

interface NoCounterpartRequestPredealChecksModel {
  counterpart: undefined;
}

interface WithCounterpartRequestPredealChecksModel {
  counterpart: Counterpart;
  typesToDisplay: PredealCheckWithoutLegsType[];
}

export type RequestPredealChecksModel =
  | NoCounterpartRequestPredealChecksModel
  | WithCounterpartRequestPredealChecksModel;

export function getRequestPredealChecksModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RequestPredealChecksModel {
  const counterpart = selectors.getSalesCounterpart(state, rfqId, selectors);

  if (counterpart === undefined) {
    return { counterpart: undefined };
  }

  const typesToDisplay = predealChecksWithoutLegsTypes.filter(
    pdcType => pdcType !== 'CREDIT_CHECK_ASK' && pdcType !== 'CREDIT_CHECK_BID',
  );
  return {
    counterpart,
    typesToDisplay,
  };
}
