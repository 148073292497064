import type { Middleware } from 'redux';
import type { ExtendedDispatch } from '@/bootstrap/thunks.ts';
import type { Action } from '@/bootstrap/actions.ts';

export function createErrorHandlingMiddleware(): Middleware {
  const middleware = () => (next: ExtendedDispatch) => (action: Action) => {
    try {
      return next(action);
    } catch (err) {
      setTimeout(() => {
        throw err;
      });
    }
  };
  return middleware as Middleware;
}
