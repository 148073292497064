import type { Services } from '@/bootstrap/services';
import type { Thunk } from '@/bootstrap/thunks';
import { times } from 'lodash';
import type { StrategyType } from '../../../../neos/business/neosModel';
import type { Action } from '@/bootstrap/actions.ts';

export function createRfqChangeStrategyTypeThunk(
  rfqId: string,
  strategyId: string,
  strategyType: StrategyType,
): Thunk {
  return function rfqChangeStrategyTypeThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { quoteCrudActions },
      },
      thunks: {
        neos: { createChangeStrategyTypeThunk, createRfqResetStrategyQuoteAndFairPricesThunk },
      },
      services: { idCreator },
    },
  ) {
    const state = getState();
    const { legIds } = selectors.getStrategyData(state, strategyId);
    const strategyDefinition = selectors.getStrategyDefinition(state.referenceData, strategyType);
    if (!strategyDefinition) {
      return;
    }

    const legsToAddIds = getLegsToAddIds(legIds, strategyDefinition.legs.length, idCreator);
    const legsToRemoveIds = getLegsToRemoveIds(legIds, strategyDefinition.legs.length);
    const actions: Action[] = [
      ...legsToAddIds.map(legQuoteId =>
        quoteCrudActions.insert(legQuoteId, {
          parentId: { type: 'Leg', id: legQuoteId },
          deltaUnit: '%',
          unit: undefined,
          initialSalesPrice: undefined,
          initialTraderPrice: undefined,
        }),
      ),
      ...legsToRemoveIds.map(legQuoteId => quoteCrudActions.delete(legQuoteId)),
    ];

    if (actions.length) {
      dispatch(actions);
    }

    dispatch(
      createChangeStrategyTypeThunk({
        strategyId,
        strategyType,
        legsToAddIds,
        legsToRemoveIds,
      }),
    );
    dispatch(createRfqResetStrategyQuoteAndFairPricesThunk(rfqId, strategyId));
  };
}

function getLegsToAddIds(
  currentLegIds: string[],
  targetLegsNumber: number,
  idCreator: Services['idCreator'],
): string[] {
  if (targetLegsNumber <= currentLegIds.length) {
    return [];
  }
  return times(targetLegsNumber - currentLegIds.length, () => idCreator.createId());
}

function getLegsToRemoveIds(currentLegIds: string[], targetLegsNumber: number): string[] {
  if (targetLegsNumber >= currentLegIds.length) {
    return [];
  }
  return currentLegIds.slice(targetLegsNumber);
}
