import type { Selectors } from '@/bootstrap/selectors';
import { mappers } from '@/neos/business/mappers';
import type { RfqDifference } from '@/neos/business/neosModel';
import type { FieldChange } from '@/neos/business/warnings/changesNotificationModel';
import { classifyDifferences } from '@/util/comparison/getDifferences';
import type { AppState } from '@/bootstrap/store.ts';

type TraderNotification = {
  message: string;
  fieldChanges: Array<FieldChange>;
};

export interface NotificationModalModel {
  lastUpdater: string | undefined;
  isNotificationModalDisplayed: boolean;
  areDifferencesDisplayed: boolean;
  highlightedDifferences: RfqDifference[];
  traderNotifications: Array<TraderNotification>;
  isTraderWarningDisplayed: boolean;
  otherDifferences: RfqDifference[];
}

export function getNotificationModalModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): NotificationModalModel {
  const { isLastRfqSavingDone, isThereAPendingNotification, getPendingNotificationRfq } = selectors;
  const { areNotificationDifferencesDisplayed } = selectors;
  const {
    deltaNotificationChangesCrudSelectors,
    executionPriceNotificationChangesCrudSelectors,
    traderAskNotificationChangesCrudSelectors,
    traderBidNotificationChangesCrudSelectors,
  } = selectors;
  const { getDifferencesBetweenCurrentAndNotificationRfq } = selectors;

  const executionsFieldChanges = executionPriceNotificationChangesCrudSelectors.selectObjects(
    state.warnings.executionPriceNotificationChanges,
    { rfqId },
  );
  const deltasFieldChanges = deltaNotificationChangesCrudSelectors.selectObjects(
    state.warnings.deltaNotificationChanges,
    { rfqId },
  );
  const TraderAskFieldChanges = traderAskNotificationChangesCrudSelectors.selectObjects(
    state.warnings.traderAskNotificationChanges,
    { rfqId },
  );
  const TraderBidFieldChanges = traderBidNotificationChangesCrudSelectors.selectObjects(
    state.warnings.traderBidNotificationChanges,
    { rfqId },
  );

  const isTraderWarningDisplayed =
    selectors.isTrader(state) && selectors.getUiShowTraderWarningModal(state.ui, rfqId);

  const isNotificationModalDisplayed =
    (isLastRfqSavingDone(state, rfqId) && isThereAPendingNotification(state, rfqId)) ||
    isTraderWarningDisplayed;

  const pendingNotificationRfq = getPendingNotificationRfq(state, rfqId);
  const lastUpdater = pendingNotificationRfq && pendingNotificationRfq.lifecycle.updater;
  const areDifferencesDisplayed = areNotificationDifferencesDisplayed(state, selectors);

  const traderNotifications: Array<TraderNotification> = [];

  if (
    deltasFieldChanges.length &&
    deltasFieldChanges.some(change => change.oldValue !== undefined)
  ) {
    traderNotifications.push({
      message: 'The Deltas have been changed:',
      fieldChanges: deltasFieldChanges.filter(({ oldValue }) => oldValue !== undefined),
    });
  }
  if (executionsFieldChanges.length) {
    traderNotifications.push({
      message: 'The Average Executions Prices have been changed:',
      fieldChanges: executionsFieldChanges.filter(({ oldValue }) => oldValue !== undefined),
    });
  }
  if (
    TraderAskFieldChanges.length &&
    TraderAskFieldChanges.some(change => change.oldValue !== undefined)
  ) {
    traderNotifications.push({
      message: 'The Nego Trader Ask Prices have been changed:',
      fieldChanges: TraderAskFieldChanges.filter(({ oldValue }) => oldValue !== undefined),
    });
  }
  if (
    TraderBidFieldChanges.length &&
    TraderBidFieldChanges.some(change => change.oldValue !== undefined)
  ) {
    traderNotifications.push({
      message: 'The Nego Trader Bid Prices have been changed:',
      fieldChanges: TraderBidFieldChanges.filter(({ oldValue }) => oldValue !== undefined),
    });
  }

  if (!isNotificationModalDisplayed || !areDifferencesDisplayed) {
    return {
      lastUpdater,
      isNotificationModalDisplayed,
      areDifferencesDisplayed,
      highlightedDifferences: [],
      otherDifferences: [],
      traderNotifications,
      isTraderWarningDisplayed,
    };
  }

  const differences = classifyDifferences(
    getDifferencesBetweenCurrentAndNotificationRfq(state, rfqId, selectors, mappers.toOnyxMappers),
  );

  return {
    lastUpdater,
    isNotificationModalDisplayed,
    ...differences,
    areDifferencesDisplayed,
    traderNotifications,
    isTraderWarningDisplayed,
  };
}
