import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  type NotificationModalDispatchProps,
  type NotificationModalOwnProps,
  type NotificationModalPropsFromState,
  NotificationModalWithStyle,
} from './NotificationModal';
import { getNotificationModalModel } from './getNotificationModalModel';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(
  state: AppState,
  { rfqId }: NotificationModalOwnProps,
): NotificationModalPropsFromState {
  return getNotificationModalModel(state, rfqId, selectors);
}

function mapPropsToDispatch(
  dispatch: ExtendedDispatch,
  { rfqId }: NotificationModalOwnProps,
): NotificationModalDispatchProps {
  return {
    onConfirmNotificationModal() {
      dispatch(neosThunks.createNeosReplaceCurrentByNotificationRfqThunk(rfqId));
      dispatch(neosActionCreators.rfqUiCrudActions.update(rfqId, { showWarningModal: false }));
      dispatch(
        neosActionCreators.createExecutionPriceNotificationChangesCrudAction.deleteByPartialKey({
          rfqId,
        }),
      );
    },
  };
}

export const NotificationModal = connect(
  mapStateToProps,
  mapPropsToDispatch,
)(NotificationModalWithStyle);
