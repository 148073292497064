import { UnderlyingInput } from '../../../common';
import { getVolVarDispersionUnderlyingActionsModel } from './getVolVarDispersionUnderlyingActionsModel.ts';

import styles from './VolVarDispersionUnderlyingActions.module.scss';
import { selectors } from '@/bootstrap/selectors.ts';
import { useAppSelector } from '@/bootstrap/hooks.ts';

export interface VolVarDispersionUnderlyingActionsProps {
  rfqId: string;
  strategyId: string;
}

export const VolVarDispersionUnderlyingActions = ({
  rfqId,
  strategyId,
}: VolVarDispersionUnderlyingActionsProps) => {
  const model = useAppSelector(state =>
    getVolVarDispersionUnderlyingActionsModel(state, rfqId, strategyId, selectors),
  );
  if (!model) {
    return;
  }

  return (
    <div className={`mb-1 ${styles['top-section']}`}>
      <UnderlyingInput
        underlyingId={'Composition'}
        bloombergCode={'Composition'}
        onChange={() => {}}
        readOnly={true}
        componentId={`neos-underlying-${strategyId}`}
        data-e2e="neos-strategy-definition-aggregatable-underlying"
      />
    </div>
  );
};
