import type { Thunk } from '@/bootstrap/thunks.ts';
import { isElsBasketProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import type { ValidImportedBasketData } from '@/neos/business/rfq/strategy/leg/product/els/thunks/importBasketCompositionThunk.ts';
import type { OnyxUnderlying } from '@/neos/business/underlyingInfo/underlyingInfoOnyxModel.ts';
import { getValidatedDataWithProperBloombergCodes } from '@/neos/business/rfq/strategy/leg/product/els/thunks/getValidatedDataWithProperBloombergCodes.ts';

export function createHandleBasketUnderlyingsReceivedThunk(
  rfqId: string,
  productId: string,
  receivedUnderlyings: OnyxUnderlying[],
  validatedData: ValidImportedBasketData,
): Thunk {
  return function handleBasketUnderlyingsReceivedThunk(
    dispatch,
    getState,
    { thunks, selectors, actionCreators },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsBasketProduct(product)) {
      return;
    }

    const validatedDataWithProperBloombergCodes = getValidatedDataWithProperBloombergCodes(
      receivedUnderlyings,
      validatedData,
    );

    if (Array.isArray(validatedDataWithProperBloombergCodes)) {
      dispatch(
        actionCreators.neos.createPartialUnderlyingsInfosReceivedAction(receivedUnderlyings),
        thunks.neos.createUpdateProductAndReferenceWithImportedBasketDetailsThunk(
          rfqId,
          productId,
          receivedUnderlyings,
          validatedDataWithProperBloombergCodes,
        ),
      );

      return;
    }

    const errorMessage = `Error when retrieving underlyings, some imported underlyings cannot be retrieved or does not exist: ${validatedDataWithProperBloombergCodes.missingUnderlings.join(', ')}`;
    dispatch(actionCreators.common.createLogAction(errorMessage, undefined, true));
    dispatch(
      thunks.createErrorToasterThunk(
        {
          message: errorMessage,
        },
        undefined,
      ),
    );
  };
}
