import type { Action } from '@/bootstrap/actions';
import type { Selectors } from '@/bootstrap/selectors';
import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '../../models';
import { createDefaultRfqRequestedAction } from '../../rfqActionCreators/defaultRfqRequestedAction';
import { DefaultingOptions } from '../../rfqActions';
import type { Feature, InterestRateFeature } from '../feature/featureModel';
import { isElsProduct } from '../strategyModel';
import type { AppState } from '@/bootstrap/store.ts';

export function createCloneStrategyFeaturesThunk(
  rfqId: string,
  currentStrategyId: string,
  newStrategyId: string,
): Thunk {
  return function cloneStrategyFeatures(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const state = getState();

    const currentFeatures: Feature[] = selectors.getStrategyFeatures(
      state.featureState,
      currentStrategyId,
    );

    let isRateCurveToBeDeleted = false;

    const actions: Action[] = currentFeatures.map((currentFeature: Feature) => {
      let cloned = currentFeature;

      if (currentFeature.type === 'INTEREST_RATE_INDEX_FEATURE') {
        isRateCurveToBeDeleted = getIsRateCurveToBeDeleted(
          currentFeature,
          selectors,
          state,
          currentStrategyId,
        );

        cloned = {
          ...currentFeature,
          rateCurve: isRateCurveToBeDeleted ? undefined : currentFeature.rateCurve,
        };
      }

      if (currentFeature.type === 'DIVIDEND_COMPONENT') {
        const isStrategyEls: boolean = selectors.isElsStrategy(state, currentStrategyId, selectors);
        if (isStrategyEls) {
          cloned = {
            ...currentFeature,
            dividendSchedulePeriods: undefined,
          };
        }
      }

      const insertNewFeatureAction: Action = featureCrudActions.insert(
        { strategyId: newStrategyId, type: currentFeature.type },
        {
          ...cloned,
          strategyId: newStrategyId,
        },
      );
      return insertNewFeatureAction;
    });

    dispatch(actions);
    if (isRateCurveToBeDeleted) {
      const options = new DefaultingOptions({
        overrideScopes: [DefaultingScope.INTEREST_RATE_INDEX],
      });
      dispatch(createDefaultRfqRequestedAction(rfqId, options));
    }
  };
}

function getIsRateCurveToBeDeleted(
  currentFeature: InterestRateFeature,
  selectors: Selectors,
  state: AppState,
  currentStrategyId: string,
) {
  const currentProduct = selectors.getStrategyProduct(state, currentStrategyId, selectors);

  const currencyCurvesList = isElsProduct(currentProduct)
    ? selectors
        .getCurrencyCurvesList(state.referenceData, currentProduct.swapCurrency ?? '')
        ?.filter(curve => curve.active)
    : [];

  return (
    !!currentFeature.rateCurve &&
    currencyCurvesList.find(curve => curve.eliotId === currentFeature.rateCurve) === undefined
  );
}
