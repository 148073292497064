import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import {
  NegotiationModeShortcutsComponent,
  type NegotiationModeShortcutsOwnProps,
} from './NegotiationMode';
import { getNegotiationModeModel } from './getNegotiationModeModel';

export const NegotiationModeShortcuts = (ownProps: NegotiationModeShortcutsOwnProps) => {
  const stateProps = useAppSelector(state =>
    getNegotiationModeModel(state, ownProps.rfqId, selectors),
  );
  return <NegotiationModeShortcutsComponent {...ownProps} {...stateProps} />;
};
