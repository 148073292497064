import { readAndParseExcelFile, readAndParseExcelFileFromChangeEvent } from '@/util/excel/excel.ts';
import { useRef } from 'react';
import { DropFileWrapper } from '@/neos/components/share/DropFileWrapper.tsx';

interface ImportButtonProps {
  handleImport: (importedData: unknown[]) => void;
  isDisabled?: boolean;
  dataE2e?: string;
  dataTestId?: string;
  buttonClass?: 'flat' | 'discreet';
  withHeader?: boolean;
}

export function ImportButton({
  handleImport,
  dataE2e,
  isDisabled = false,
  buttonClass = 'discreet',
  withHeader = false,
  dataTestId,
}: ImportButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <DropFileWrapper
      handleDrop={files => readAndParseExcelFile(files[0], handleImport, { withHeader })}
    >
      <button
        disabled={isDisabled}
        onClick={() => {
          fileInputRef.current?.click();
        }}
        className={`btn btn-${buttonClass}-primary`}
      >
        Import <i className="icon icon-sm ms-2">file_upload</i>
        <input
          ref={fileInputRef}
          data-e2e={dataE2e}
          type="file"
          accept=".xlsx"
          className="d-none"
          data-testid={dataTestId}
          onChange={e => readAndParseExcelFileFromChangeEvent(e, handleImport, { withHeader })}
        />
      </button>
    </DropFileWrapper>
  );
}
