import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  CloseButton as Component,
  type DispatchProps,
  type OwnProps,
  type StateProps,
} from './CloseButton';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(state: AppState, { strategyId, type }: OwnProps): StateProps {
  const isMandatory = selectors.isStrategyFeatureMandatory(state, { strategyId, type }, selectors);
  const isReadonly = selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors);
  return { isClosable: !isMandatory && !isReadonly };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { strategyId, type }: OwnProps,
): DispatchProps {
  return {
    onClick() {
      dispatch(neosThunks.createRemoveExtraFeatureThunk({ strategyId, type }));
    },
  };
}

export const CloseButton = connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
