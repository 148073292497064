import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export interface PredealChecksModalModel {
  modalSize: 'xl' | 'lg';
  showModal: boolean;
}

export function getPredealChecksModalModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): PredealChecksModalModel {
  const hasAtListOTCStrategy = selectors
    .getRfqAndDeltaProducts(state, rfqId, selectors)
    .find(({ negotiationMode }) => negotiationMode === 'OTC');
  return {
    modalSize: hasAtListOTCStrategy ? 'xl' : 'lg',
    showModal: selectors.isPredealChecksModalShown(state.ui, rfqId),
  };
}
