import type { Thunk } from '@/bootstrap/thunks';

export interface LegAndQuoteIds {
  legId: string;
  quoteId: string;
}
export function createStrategyAddLegsThunk(
  strategyId: string,
  legAndQuoteIds: LegAndQuoteIds[],
  newLegsIndex: number,
): Thunk {
  return function strategyAddLegsThunk(
    dispatch,
    _,
    {
      thunks: {
        neos: { createAddLegThunk },
      },
      actionCreators: {
        neos: { strategyDataCrudActions },
      },
    },
  ) {
    dispatch(
      legAndQuoteIds.map(({ legId, quoteId }) => [
        createAddLegThunk(strategyId, legId, quoteId),
        strategyDataCrudActions.arrayAddAtIndex(strategyId, {
          property: 'legIds',
          value: legId,
          index: newLegsIndex,
        }),
      ]),
    );
  };
}
