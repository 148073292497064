import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { getRegeneratePreconfsButtonModel } from '@/neos/components/rfq/postNego/allocs/ReGeneratePreconfsButton/getRegeneratePreconfsButtonModel.ts';

type ReGeneratePreconfsButtonProps = {
  rfqId: string;
};

export const ReGeneratePreconfsButton: FC<ReGeneratePreconfsButtonProps> = ({ rfqId }) => {
  const dispatch = useAppDispatch();

  const { isDisplayed } = useAppSelector(state =>
    getRegeneratePreconfsButtonModel(state, rfqId, selectors),
  );

  if (!isDisplayed) {
    return null;
  }

  return (
    <button
      className="btn btn-primary"
      data-testid="regenerate-preconfs-button"
      onClick={() => dispatch(actionCreators.neos.createGeneratePreconfsAction(rfqId))}
    >
      Regenerate Pre Conf
      <i className="icon icon-sm ms-2">cached</i>
    </button>
  );
};
