import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import { type ExtendedDispatch, type Thunks, thunks } from '@/bootstrap/thunks';
import { type NeosActionCreators, neosActionCreators } from '@/neos/business/neosActionCreators';
import { useDispatch } from 'react-redux';
import {
  StrategyLinkCellComponent,
  type StrategyLinkCellDispatchProps,
  type StrategyLinkCellMapStateProps,
  type StrategyLinkCellOwnProps,
} from './StrategyLinkCell';
import { getStrategyLinkCellModel } from './getStrategyLinkCellModel';

export const StrategyLinkCell = (ownProps: StrategyLinkCellOwnProps) => {
  const stateProps = useAppSelector(state => {
    return getStrategyLinkCellModel(state, ownProps.strategyId, ownProps.rfqId, selectors);
  });
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(
    dispatch,
    ownProps,
    stateProps,
    neosActionCreators,
    thunks,
  );
  return <StrategyLinkCellComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: StrategyLinkCellOwnProps,
  { primaryRfqId, primaryRfqSecondaryEvent, legIds }: StrategyLinkCellMapStateProps,
  { rfqUiCrudActions, strategyUiCrudActions, legUiCrudActions }: NeosActionCreators,
  thunks: Thunks,
): StrategyLinkCellDispatchProps {
  return {
    onLink: isPrimaryRfq => {
      const newActivity = isPrimaryRfq ? 'SECONDARY' : 'PRIMARY';
      const legsActions = legIds.map(legId =>
        legUiCrudActions.patchOrInsert(legId, {
          secondaryType: primaryRfqSecondaryEvent,
        }),
      );
      return dispatch([
        rfqUiCrudActions.patchOrInsert(rfqId, {
          isRfqWaitingForSecondarySelection: false,
        }),
        strategyUiCrudActions.update(strategyId, {
          activity: newActivity,
        }),
        legsActions,
        createLogAnalyticsAction('NEOS RFQ', `Rfq similar activities strategy link requested`),
        thunks.neos.createChangeRfqActivity(
          { rfqId, strategyId, legId: '', legIds },
          {
            newActivity,
            primaryRfqId,
            primaryRfqSecondaryEvent,
          },
        ),
        thunks.neos.createResetSecondaryTypesOnLegsThunk(),
      ]);
    },
  };
}
