import type { Reducer } from '@reduxjs/toolkit';
import type { UserInfoReceivedAction } from './userInfoActions';
import { defaultUserInfoState, type UserInfoState } from './userInfoState';

export const userInfoReducer: Reducer<UserInfoState> = (
  state = defaultUserInfoState,
  unknownAction,
) => {
  const action = unknownAction as unknown as UserInfoReceivedAction;

  if (action.type === 'USER_INFO_RECEIVED') {
    const { currentUser, toggleFeatures } = action.userInfo;
    return {
      ...state,
      currentUser: {
        email: currentUser.email,
        diffusionGroups: currentUser.diffusionGroups,
        firstname: currentUser.firstname,
        groupId: currentUser.groupId,
        lastname: currentUser.lastname,
        login: currentUser.login,
        sesameId: currentUser.sesameId,
        categories: currentUser.categories,
      },
      toggleFeatures,
    };
  }
  return state;
};
