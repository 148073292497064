import type { OnyxUnderlying } from '@/neos/business/underlyingInfo/underlyingInfoOnyxModel.ts';
import type { ValidImportedBasketData } from '@/neos/business/rfq/strategy/leg/product/els/thunks/importBasketCompositionThunk.ts';

export function getValidatedDataWithProperBloombergCodes(
  receivedUnderlyings: OnyxUnderlying[],
  validatedData: ValidImportedBasketData,
): ValidImportedBasketData | { missingUnderlings: string[] } {
  const lineAndFoundUnderlying = validatedData.map(line => {
    return {
      line,
      underlying: receivedUnderlyings.find(
        underlying =>
          line.bloombergCode === underlying.bloombergCode ||
          line.bloombergCode === underlying.tickerComposite,
      ),
    };
  });

  const missingUnderlyings = lineAndFoundUnderlying.filter(
    element => element.underlying === undefined,
  );

  if (missingUnderlyings.length) {
    return { missingUnderlings: missingUnderlyings.map(element => element.line.bloombergCode) };
  }

  return lineAndFoundUnderlying.map(element => {
    if (element.underlying) {
      element.line.bloombergCode = element.underlying.bloombergCode;
    }
    return element.line;
  });
}
