import type { Selectors } from '@/bootstrap/selectors';
import type { BookingStep } from '@/neos/business/bookingSteps/BookingStepsSelectors';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { flatMap, sortBy, uniq } from 'lodash';
import {
  type DeliveryType,
  type DeltaType,
  isListedProduct,
  isRfqStrategyData,
  type TraderGroup,
  type User,
} from '../../../../neos/business/neosModel';
import type { BookingStepApplication } from '../../../../neos/business/neosOnyxModel';
import type { AppState } from '@/bootstrap/store.ts';

const availableDeliveryTypes: DeliveryType[] = ['CASH', 'PHYSICAL'];
const availableDeltaTypes: DeltaType[] = [
  'DELTA_ADJUSTED',
  'DELTA_EXCHANGE',
  'DELTA_EXCHANGE_OTC',
  'RISK',
];

const availableEtdDeskCities: string[] = ['Hong Kong', 'London', 'Paris'];
const _availableExecutors: string[] = ['A', 'B', 'C'];

export interface StrategyDetailsModel {
  isReadOnlyRfq: boolean;
  comment: string | undefined;
  deliveryType: Field<DeliveryType | undefined>;
  traderGroupName: Field<string | undefined>;
  availableTraderGroups: TraderGroup[];
  traderId: Field<string | undefined>;
  availableTraders: User[];
  bookingId: Field<string | undefined>;
  displayBookingIdByAlloc: boolean;
  portfolioId: string | undefined;
  bookingApplication: Field<BookingStepApplication | undefined>;
  bookingStep: BookingStep | undefined;
  availableDeliveryTypes: DeliveryType[];
  availableDeltaTypes: DeltaType[];
  isMasterStrategy: boolean;
  availableEtdDeskCities: string[];
  availableExecutors: string[];
  mirrorPortfolioId: string | undefined;
  etdDeskCity:
    | (Field<string | undefined> & { isHidden: false })
    | {
        isHidden: true;
      };
  executors:
    | (Field<string | undefined> & { isHidden: false })
    | {
        isHidden: true;
      };
}

type Field<T> = {
  value: T;
  isDisabled: boolean;
};

export function getStrategyDetailsModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
  availableExecutors = _availableExecutors,
): StrategyDetailsModel {
  const strategyData = selectors.getStrategyData(state, strategyId);
  const {
    traderId,
    legIds,
    traderGroupName,
    comment,
    portfolioId,
    etdDeskCity,
    executor,
    mirrorPortfolioId,
  } = strategyData;

  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  const { deliveryType } = product;
  const isUsListed = selectors.isUsListed(state, strategyId, selectors);

  const bookingStep = selectors.getBookingStepByStrategyIdOrByLegId(
    state,
    rfqId,
    strategyId,
    legIds[0],
  );

  const execBookingStep = selectors.getExecBookingStepForSingleExecSingleLegSingleStrat(
    state,
    rfqId,
    strategyId,
    selectors,
  );

  const displayedBookingStep = bookingStep ?? execBookingStep;
  const isBookingDisabled = !selectors.hasRFQBookingSteps(state, rfqId);
  const isOtcStrategyAndAmendWorkflowStatus = selectors.isOtcStrategyAndAmendWorkflowStatus(
    state,
    rfqId,
    strategyId,
    selectors,
  );

  // FOR OTC Only special rules for booking id
  // if booking step = alloc hide the booking id and display the button to show the allocs
  const isListed = isListedProduct(product);
  let displayBookingIdByAlloc = false;

  let bookingIdValue = displayedBookingStep?.bookingId;
  let bookingApplicationValue = displayedBookingStep?.application;

  if (!isListed && !bookingApplicationValue) {
    const allocations = legIds.flatMap(legId => {
      return selectors.getOtcAllocationsByLegId(state, legId);
    });

    const bookingInfos = allocations.map(allocation =>
      selectors.getAllocationBookingStep(state, rfqId, allocation.uuid),
    );

    const everyBookingInfoApplicationAreDefinedAndEqualToTheFirstElement =
      bookingInfos.length &&
      !bookingInfos.some(
        bookingInfo =>
          !bookingInfo?.application || bookingInfo?.application !== bookingInfos[0]?.application,
      );

    if (everyBookingInfoApplicationAreDefinedAndEqualToTheFirstElement) {
      bookingApplicationValue = bookingInfos[0]?.application;
      bookingIdValue = '';
      displayBookingIdByAlloc = true;
    }
  }

  const isDeltaHedgingStrategy = !isRfqStrategyData(strategyData);

  const isMasterStrategy = selectors.getRfqMasterStrategy(state, rfqId).uuid === strategyId;
  const isReadOnlyRfq = selectors.isReadOnlyRfq(state, rfqId);

  const isReadOnlyAtCurrentWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    state,
    strategyId,
    selectors,
  );

  return {
    isReadOnlyRfq,
    traderId: { value: traderId, isDisabled: isDeltaHedgingStrategy || isReadOnlyRfq },
    availableTraders: getAndFormatAvailableTraders(state, selectors, traderGroupName, traderId),
    traderGroupName: {
      value: traderGroupName,
      isDisabled: isDeltaHedgingStrategy || isReadOnlyRfq,
    },
    etdDeskCity:
      isListed && !isUsListed
        ? { value: etdDeskCity, isDisabled: isReadOnlyRfq, isHidden: false }
        : { isHidden: true },
    executors: isUsListed
      ? { value: executor, isDisabled: isReadOnlyRfq, isHidden: false }
      : { isHidden: true },
    availableTraderGroups: getAndFormatAvailableTraderGroups(state, selectors),
    comment,
    deliveryType: {
      value: deliveryType,
      isDisabled: isDeltaHedgingStrategy || isReadOnlyRfq || isReadOnlyAtCurrentWorkflow,
    },
    portfolioId,
    bookingId: {
      value: bookingIdValue,
      isDisabled: isBookingDisabled || isOtcStrategyAndAmendWorkflowStatus,
    },
    bookingApplication: { value: bookingApplicationValue, isDisabled: isBookingDisabled },
    bookingStep,
    availableDeliveryTypes,
    availableDeltaTypes,
    availableEtdDeskCities: uniq([...availableEtdDeskCities, etdDeskCity])
      .filter(isDefined)
      .sort(),
    availableExecutors,
    isMasterStrategy,
    displayBookingIdByAlloc,
    mirrorPortfolioId,
  };
}

function getAndFormatAvailableTraders(
  state: AppState,
  selectors: Selectors,
  traderGroupName: string | undefined,
  traderId: string | undefined,
): User[] {
  const result: User[] = [];

  const traders = selectors.getTraders(state.referenceData);

  if (traderGroupName) {
    const traderGroups: TraderGroup[] = selectors.getTraderGroups(state.referenceData);
    const selectedTraderGroup = traderGroups.find(
      traderGroup => traderGroup.value === traderGroupName,
    );

    if (selectedTraderGroup) {
      const usersByTraderGroup = selectedTraderGroup.diffusionUsers.map(
        diffusionUser => diffusionUser.id,
      );

      const users = flatMap(
        usersByTraderGroup
          .filter(userByGroup => userByGroup !== traderId && traders[userByGroup])
          .map(user => traders[user]),
      );
      users.forEach(user => result.push(user));
    }
  } else {
    result.push(...Object.values(traders));
  }

  if (traderId && !result.find(r => r.id === traderId)) {
    const selectedTrader = traders[traderId];
    if (selectedTrader) {
      result.push(selectedTrader);
    }
  }

  const capitalizedTraders: User[] = result.map(capitalizeTraderName);

  return sortBy(capitalizedTraders, [
    ({ lastname }: User) => lastname,
    ({ firstname }: User) => firstname,
  ]);
}

function capitalizeTraderName(trader: User): User {
  return {
    ...trader,
    lastname: trader.lastname.toUpperCase(),
  };
}

function getAndFormatAvailableTraderGroups(
  state: AppState,
  neosSelectors: Selectors,
): TraderGroup[] {
  const traderGroups: TraderGroup[] = neosSelectors.getTraderGroups(state.referenceData);
  return sortBy(traderGroups, [({ value }: TraderGroup) => value]);
}
