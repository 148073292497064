import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { ReferenceKey } from '@/neos/business/neosModel';
import type { ExecutionType } from '@/neos/business/neosOnyxModel';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  UnderlyingReferenceComponent,
  type UnderlyingReferenceDispatchProps,
  type UnderlyingReferenceMapStateToProps,
  type UnderlyingReferenceOwnProps,
} from './UnderlyingReference';
import { getUnderlyingReferenceModel } from './getUnderlyingReferenceModel';
import type { AppState } from '@/bootstrap/store.ts';

export const UnderlyingReference = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnderlyingReferenceComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: UnderlyingReferenceOwnProps,
): UnderlyingReferenceMapStateToProps {
  return getUnderlyingReferenceModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: UnderlyingReferenceOwnProps,
): UnderlyingReferenceDispatchProps {
  return {
    onRefSpotChanged: (underlyingId: string, refSpot: number | undefined) => {
      dispatch(neosThunks.createChangeReferenceSpotThunk({ rfqId, underlyingId }, refSpot));
    },
    onRefLevelChanged: (
      underlyingId: string,
      refLevel: number | undefined,
      referenceKey: ReferenceKey,
    ) => {
      dispatch(
        neosActionCreators.referenceCrudActions.update(referenceKey, {
          hasBeenManuallySet: true,
        }),
        neosThunks.createChangeReferenceLevelThunk({ rfqId, underlyingId }, refLevel),
      );
    },
    onRefBasisChanged: (underlyingId: string, refBasis: number | undefined) => {
      dispatch(neosThunks.createChangeReferenceBasisThunk({ rfqId, underlyingId }, refBasis));
    },
    onExecutionTypeChanged: (rfqId: string, executionType: ExecutionType) => {
      dispatch(neosThunks.createChangeMasterStrategyExecutionTypeThunk(rfqId, executionType));
    },
  };
}
