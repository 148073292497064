import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import type { LegDescriptionComponentProps } from '../AveragePrice';
import { getLegBookingIdModel } from './getLegBookingIdModel';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { BrokenLink } from '@/neos/components/rfq/postNego/allocs/otcAllocations/otcAllocationFields/BrokenLink';
import { If } from '@/neos/components/share/if/if';
import { getLegBookingIdBrokenLinkModel } from '@/neos/components/rfq/postNego/allocs/allocInfo/LegBookingId/getLegBookingIdBrokenLinkModel';

export function LegBookingId({ rfqId, legId }: LegDescriptionComponentProps) {
  const model = useAppSelector(state => getLegBookingIdModel(state, { rfqId, legId }, selectors));
  const brokenLinkModel = useAppSelector(state =>
    getLegBookingIdBrokenLinkModel(state, { rfqId, legId }, selectors),
  );
  const dispatch = useAppDispatch();

  function onLegBookingIdChanged(id: string | undefined) {
    dispatch(actionCreators.neos.legDataCrudActions.update(legId, { bookingId: { id } }));
  }

  if (!model) {
    return null;
  }

  const { bookingId, defaultBookingId, status, bookingStepMessage, bookingTimeStamp, isReadOnly } =
    model;

  const { brokenLink, haveSomeBrokenLinks, isBrokenLinkDisplayed } = brokenLinkModel;

  return (
    <>
      <div>
        <label className="mb-0 w-100" style={{ minWidth: '115px' }}>
          <SimpleNeosTooltip type={'warning'} id="leg-booking-tooltip" message={bookingStepMessage}>
            <span>
              Leg Booking Id
              {!!bookingStepMessage && (
                <i className="icon icon-sm text-warning ms-2" style={{ lineHeight: '17px' }}>
                  warning
                </i>
              )}
            </span>
          </SimpleNeosTooltip>
        </label>

        <NeosBookingId
          defaultBookingId={defaultBookingId}
          bookingId={bookingId}
          bookingStatus={status}
          bookingTimeStamp={bookingTimeStamp}
          dataE2e="leg-booking-id"
          isReadOnly={isReadOnly}
          onBookingIdChanged={onLegBookingIdChanged}
        />
      </div>
      <div>
        <label></label>
        <If condition={haveSomeBrokenLinks && isBrokenLinkDisplayed}>
          <BrokenLink hasBrokenLink={brokenLink} />
        </If>
      </div>
    </>
  );
}
