import type { Selectors } from '@/bootstrap/selectors';
import type { StepInInfo } from '@/neos/business/neosOnyxModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getStepInInfos(
  legId: string,
  allocationId: string,
  rfqId: string,
  state: AppState,
  selectors: Selectors,
): StepInInfo | undefined {
  const currentAllocation = selectors.getOtcAllocation(state, rfqId, legId, allocationId);
  return currentAllocation?.stepInInfo;
}
