import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { ResetFrequencySelect } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ResetFrequencySelect';
import { getElsFeatureFields } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/getElsFeatureFields';
import { useContext } from 'react';

export function ResetFrequencyField() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const { resetFrequency, onResetFrequencyChanged } = useAppSelector(state =>
    getElsFeatureFields(strategyId, state, selectors, thunks, actionCreators, dispatch),
  );

  if (!resetFrequency.isDisplayed) {
    return null;
  }

  return (
    <ElsBlocField
      label="Reset Frequency"
      renderInput={readOnly => (
        <ResetFrequencySelect
          readOnly={readOnly}
          rfqId={rfqId}
          strategyId={strategyId}
          resetFrequency={resetFrequency.value}
          onChange={newResetFrequency => onResetFrequencyChanged(newResetFrequency, rfqId)}
        />
      )}
    />
  );
}
