import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function PartialReset({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const dispatch = useAppDispatch();

  const currentAllocation = useAppSelector(state =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const partialReset = currentAllocation?.secondaryInfo?.partialReset;

  const onPartialReset = (value: boolean) => {
    dispatch(
      thunks.neos.createUpdateSecondaryInfoThunk(allocationId, rfqId, legId, {
        partialReset: value,
      }),
    );
  };

  return (
    <Switch
      switchClassName="text-center"
      data-e2e="partial-reset"
      checked={!!partialReset}
      onChange={() => onPartialReset(!partialReset)}
    />
  );
}
