import type { Thunk } from '@/bootstrap/thunks';
import type {
  LegPeriod,
  LegPeriodDates,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { exportDataToExcel } from '@/util/excel/excel.ts';

export function createClsExportScheduleThunk(
  rateData: LegPeriod<RateLegPeriodDates>[] | undefined,
): Thunk {
  return function clsExportScheduleThunk() {
    const maxLength = rateData?.length ?? 0;
    const exportedData = [];

    for (let index = 0; index < maxLength; index++) {
      const newLine = {
        'Rate Fixing Date': getCellValue(index, rateData, 'fixingDate'),
        'Rate Start Date': getCellValue(index, rateData, 'startDate'),
        'Rate End Date': getCellValue(index, rateData, 'endDate'),
        'Rate Pay Date': getCellValue(index, rateData, 'paymentDate'),
      };
      exportedData.push(newLine);
    }

    function getCellValue<T extends LegPeriodDates>(
      index: number,
      data: LegPeriod<T>[] | undefined,
      key: keyof T,
    ) {
      return data && data.length > index ? data[index].dates[key] : '';
    }

    exportDataToExcel(exportedData, 'Cls Schedule Period');
  };
}
