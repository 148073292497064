import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import type { ElsSectionLayout } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import { computeAndSaveLayoutToLS } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsUtils';
import { OtherBloc } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/OtherBloc/OtherBloc';
import { ElsSectionsModal } from '../elsSectionsModal/elsSectionsModal';
import { DividendBloc } from './DividendBloc/DividendBloc';
import { EarlyTerminationBloc } from './EarlyTerminationBloc/EarlyTerminationBloc';
import { EquityBloc } from './EquityBloc/EquityBloc';
import { FeesBloc } from './FeesBloc/FeesBloc';
import { ForexTypeBloc } from './ForexTypeBloc/ForexTypeBloc';
import { ProductDetailsBloc } from './ProductDetailsBloc/ProductDetailsBloc';
import { RateBloc } from './RateBloc/RateBloc';
import { RightToSubstituteBloc } from './RightToSubstituteBloc/RightToSubstituteBloc';
import './ElsFeatures.module.scss';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { type Els, isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { BrokersBloc } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/BrokersBloc/BrokersBloc';
import { ElsLendAndBorrow } from '@/neos/components/rfq/strategies/strategy/features/elsLendAndBorrow/ElsLendAndBorrow';
import { createContext } from 'react';
import { BuyAndSellBloc } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/BuyAndSellBloc/BuyAndSellBloc.tsx';

export const gridUnitHeight = 12;
export const commonElsBlocClasses = 'border p-1 pe-2 h-100 overflow-auto card';

export interface ElsFeaturesProps {
  rfqId: string;
  strategyId: string;
  isElsDetails?: boolean;
}

interface ElsFeaturesContext {
  rfqId: string;
  strategyId: string;
  isReadonlyAtCurrentWorkflow: boolean;
  labelClassNames: 'd-block mb-0 fw-bold ps-1';
  parentClassNames: 'py-1 ps-1';
  product: Els;
}

export const ElsFeaturesContext = createContext<ElsFeaturesContext>({
  product: {} as Els,
  strategyId: '',
  rfqId: '',
  isReadonlyAtCurrentWorkflow: false,
  parentClassNames: 'py-1 ps-1',
  labelClassNames: 'd-block mb-0 fw-bold ps-1',
});

export function ElsFeatures({ strategyId, rfqId, isElsDetails = false }: ElsFeaturesProps) {
  const dispatch = useAppDispatch();

  const isReadonlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  const elsSections = useAppSelector(state => {
    return selectors.getFeatureToggledElsSections(state, strategyId, selectors);
  });

  const isElsOfTypeTrs = useAppSelector(state => {
    return selectors.isElsOfTypeTrs(state, strategyId, selectors);
  });

  const isElsIdb = useAppSelector(state =>
    selectors.isStrategyRfqInitiatedByTrader(state, strategyId, selectors),
  );
  const isElsInternal = useAppSelector(state =>
    selectors.isRfqInternalEls(state, rfqId, selectors),
  );
  const isElsIdbAndNotInternal = isElsIdb && !isElsInternal;

  const isDisplayed = isElsProduct(product);

  if (!isDisplayed) {
    return null;
  }

  const displayedLayout = isElsDetails
    ? elsSections.filter(section => {
        if (section.i === 'Dividend Leg') {
          return isElsOfTypeTrs;
        }
        if (section.i === 'Brokers') {
          return isElsIdbAndNotInternal;
        }
        return true;
      })
    : elsSections.filter(section => section.isDisplayed);

  return (
    <ElsFeaturesContext.Provider
      value={{
        product,
        labelClassNames: 'd-block mb-0 fw-bold ps-1',
        parentClassNames: 'py-1 ps-1',
        rfqId,
        strategyId,
        isReadonlyAtCurrentWorkflow,
      }}
    >
      <ElsSectionsModal strategyId={strategyId} />
      <div className="d-flex flex-column">
        <GridLayout
          className="layout els-fields"
          layout={displayedLayout}
          cols={60}
          compactType={'vertical'}
          rowHeight={gridUnitHeight}
          width={1800}
          onLayoutChange={layout =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            computeAndSaveLayoutToLS(layout, elsSections, dispatch, actionCreators)
          }
          margin={[5, 5]}
        >
          {displayedLayout.map(section => (
            <div key={section.i}>{getSectionBloc(section, isElsDetails)}</div>
          ))}
        </GridLayout>
      </div>
    </ElsFeaturesContext.Provider>
  );
}

function getSectionBloc(section: ElsSectionLayout, isElsDetails: boolean) {
  switch (section.i) {
    case 'Buy & Sell':
      return <BuyAndSellBloc canBeHidden={!isElsDetails} />;

    case 'Lend And Borrow':
      return <ElsLendAndBorrow canBeHidden={!isElsDetails} />;

    case 'Product Details':
      return <ProductDetailsBloc canBeHidden={!isElsDetails} />;

    case 'Brokers':
      return <BrokersBloc />;

    case 'Rate Convention':
      return <RateBloc canBeHidden={!isElsDetails} />;

    case 'Forex Constat / Type':
      return <ForexTypeBloc canBeHidden={!isElsDetails} />;

    case 'Fees':
      return <FeesBloc canBeHidden={!isElsDetails} />;

    case 'Dividend Leg':
      return <DividendBloc />;

    case 'Right to Substitute':
      return <RightToSubstituteBloc canBeHidden={!isElsDetails} />;

    case 'Early Termination':
      return <EarlyTerminationBloc canBeHidden={!isElsDetails} />;

    case 'Equity Leg':
      return <EquityBloc canBeHidden={!isElsDetails} />;

    case 'Other':
      return <OtherBloc canBeHidden={!isElsDetails} />;
  }
}
