import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { type OtcAllocation, otcAllocMediaOptions } from '@/neos/business/neosModel';
import { If } from '@/neos/components/share/if/if';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { mapRecordEntriesToSelectOptions } from '@/util/array/arrayUtils';
import type { FC } from 'react';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type OtcAllocMediaProps = {
  allocation: OtcAllocation;
  rfqId: string;
  legId: string;
};

export const OtcAllocMedia: FC<OtcAllocMediaProps> = ({ allocation, rfqId, legId }) => {
  const dispatch = useAppDispatch();

  function onMediaChanged(allocationId: string, media: OtcAllocation['media']) {
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update({ allocationId, rfqId, legId }, { media }),
    );
  }

  return (
    <div className="d-flex">
      <NeosSelectWithAutocomplete
        data-e2e="otc-alloc-media"
        value={allocation.media}
        onChange={selected =>
          onMediaChanged(allocation.uuid, selected?.value as OtcAllocation['media'])
        }
        addEmptyOption
        options={mapRecordEntriesToSelectOptions(otcAllocMediaOptions).filter(
          x => x.value !== 'UNKNOWN',
        )}
      />

      <If condition={!!allocation.eligibilityReason}>
        <SimpleNeosTooltip
          placement="right"
          message={allocation.eligibilityReason}
          id={`tooltip-eligibility-reason-${allocation.uuid}`}
        >
          <i className="icon icon-sm">help</i>
        </SimpleNeosTooltip>
      </If>
    </div>
  );
};
