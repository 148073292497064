import { neosThunks } from '@/neos/business/thunks';
import { thunks } from '@/bootstrap/thunks.ts';
import { useAppDispatch } from '@/bootstrap/hooks.ts';
import { PasteClipboard } from '@/neos/components/share/importExportClipboard/PasteClipboard.tsx';

export function ElsScheduleClipBoardPasteButton(props: { strategyId: string }) {
  const dispatch = useAppDispatch();

  function handleError(error: string) {
    dispatch(thunks.createErrorToasterThunk({ message: error }, undefined));
  }

  function handleClipboardData(importedData: unknown[]) {
    dispatch(neosThunks.createImportScheduleLegsDataThunk(props.strategyId, importedData));
  }

  return (
    <PasteClipboard
      handleClipboardData={handleClipboardData}
      handleError={handleError}
      dataE2e="els-schedule-clipbard"
    />
  );
}
