import type {
  CurrentUserPreferences,
  CurrentUserPreferencesState,
  UserPreferencesCity,
  UserPreferencesCountry,
  UserPreferencesStrategyTraderGroup,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { AppState } from '@/bootstrap/store.ts';

const initialState: CurrentUserPreferencesState = {
  preferences: {
    defaultDeskId: '',
    swapswireAccount: undefined,
    swapswireAccountType: 'USER',
    swapswireAccountingCenter: undefined,
    city: { cityId: 0, name: '' },
    country: { countryRefId: 0, refId: '', name: '' },
    userEmail: undefined,
    partialReset: 'NONE',
    tradingBusiness: 'NONE',
    strategyTraderGroup: [],
  },
  referential: {
    cities: [],
    countries: [],
    desks: [],
    tradergroups: [],
  },
};

export const currentUserPreferencesSlice = createSlice({
  name: 'currentUserPreferencesState',
  initialState,
  reducers: {
    referentialReceived: (
      state,
      action: PayloadAction<CurrentUserPreferencesState['referential']>,
    ) => {
      state.referential = action.payload;
    },
    currentUserPrefReceived: (state, action: PayloadAction<CurrentUserPreferences>) => {
      state.preferences = action.payload;
    },
    updateCurrentPreferencesByField: <K extends keyof CurrentUserPreferences>(
      state: CurrentUserPreferencesState,
      action: PayloadAction<{
        field: K;
        newValue: CurrentUserPreferences[K];
      }>,
    ) => {
      const { field, newValue } = action.payload;

      state.preferences[field] = newValue;
    },
  },
});

export const currentUserPreferencesSliceReducer = currentUserPreferencesSlice.reducer;

// SELECTORS
const selectCurrentUserPreferences = (state: AppState): CurrentUserPreferences =>
  state.currentUserPreferences.preferences;

const selectCurrentUserReferential = (
  state: AppState,
): CurrentUserPreferencesState['referential'] => state.currentUserPreferences.referential;

function selectCityById(state: AppState, cityIdToFind: number): UserPreferencesCity | undefined {
  return selectCurrentUserReferential(state).cities.find(city => city.cityId === cityIdToFind);
}

function selectCountryById(
  state: AppState,
  countryIdToFind: number,
): UserPreferencesCountry | undefined {
  return selectCurrentUserReferential(state).countries.find(
    country => country.countryRefId === countryIdToFind,
  );
}

function selectStrategyTraderGroup(
  state: AppState,
): UserPreferencesStrategyTraderGroup[] | undefined {
  return selectCurrentUserPreferences(state).strategyTraderGroup;
}

export const currentUserPreferencesSelectors = {
  selectCurrentUserPreferences,
  selectCurrentUserReferential,
  selectCityById,
  selectCountryById,
  selectStrategyTraderGroup,
};

// ACTIONS

export type GetCurrentUserPreferencesAction = {
  type: 'GET_CURRENT_USER_PREFERENCES';
};

export function createGetCurrentUserPreferencesAction(): GetCurrentUserPreferencesAction {
  return {
    type: 'GET_CURRENT_USER_PREFERENCES',
  };
}

export type PostCurrentUserPreferencesAction = {
  type: 'UPDATE_CURRENT_USER_PREFERENCES';
  currentUserNewPreferences: CurrentUserPreferences;
};

export function createPostCurrentUserPreferencesAction(
  currentUserNewPreferences: CurrentUserPreferences,
): PostCurrentUserPreferencesAction {
  return {
    type: 'UPDATE_CURRENT_USER_PREFERENCES',
    currentUserNewPreferences,
  };
}

export interface CurrentUserPreferencesReferentialAction {
  type: 'CURRENT_USER_PREFERENCES_REFERENTIAL_REQUESTED';
  isUserTrader: boolean;
}

export function createGetCurrentUserPreferencesReferentialActionCreator(
  isUserTrader: boolean,
): CurrentUserPreferencesReferentialAction {
  return {
    type: 'CURRENT_USER_PREFERENCES_REFERENTIAL_REQUESTED',
    isUserTrader,
  };
}

export type CurrentUserPreferencesActions =
  | GetCurrentUserPreferencesAction
  | PostCurrentUserPreferencesAction
  | CurrentUserPreferencesReferentialAction;

export const currentUserPreferencesActionCreators = {
  ...currentUserPreferencesSlice.actions,
  createGetCurrentUserPreferencesAction,
  createPostCurrentUserPreferencesAction,
  createGetCurrentUserPreferencesReferentialActionCreator,
};
