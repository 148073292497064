import type { Selectors } from '@/bootstrap/selectors';
import { isValidSecondaryWidgetStatus } from '@/neos/business/secondaryWidget/isValidSecondaryWidgetStatus';
import type { BaseSecondaryTypeField, NotApplicableField } from '../../secondaryTypeModel';
import {
  type AvailableEventType,
  getEventTypesByProduct,
} from '../../shared/SecondaryTypeCellsCommon';
import type { SimilarActivitiesSecondaryTypeWidgetOwnProps } from './SimilarActivitiesSecondaries';
import type { AppState } from '@/bootstrap/store.ts';

export type SecondaryTypeFieldModel =
  | (BaseSecondaryTypeField & {
      eventTypes: AvailableEventType[];
    })
  | NotApplicableField;

export interface SimilarActivitiesSecondaryTypeCellsModel {
  secondaryTypes: SecondaryTypeFieldModel[];
  isLoading: boolean;
  isReadonly: boolean;
}

export function getSimilarActivitiesSecondaryTypeWidgetModel(
  appState: AppState,
  { rfqId, strategyId }: SimilarActivitiesSecondaryTypeWidgetOwnProps,
  selectors: Selectors,
): SimilarActivitiesSecondaryTypeCellsModel {
  const hasACompositionLeg = selectors.hasACompositionLeg(appState, strategyId, selectors);
  const { status } = selectors.getRfqData(appState, rfqId);
  const isLoading = selectors.isRfqLoading(appState.ui, rfqId);
  const isReadOnlyAtCurrentWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );

  if (hasACompositionLeg) {
    return {
      secondaryTypes: [],
      isLoading,
      isReadonly: isReadOnlyAtCurrentWorkflow,
    };
  }

  const { legIds } = selectors.getStrategyData(appState, strategyId);

  const secondaryTypes = legIds.map((legId): SecondaryTypeFieldModel => {
    const isOtcLeg = selectors.isOtcLeg(appState, legId, selectors);
    const { eventType } = selectors.getLegData(appState, legId);
    const isEventTypeCreate = eventType === 'CREATE';
    const isEventTypeStepIn = eventType === 'STEPIN';
    const isEligible =
      isOtcLeg && (isEventTypeCreate || isEventTypeStepIn) && isValidSecondaryWidgetStatus(status);

    if (!isEligible) {
      return { isEligible: false, legId };
    }

    const secondaryType = selectors.getSecondaryEventType(appState, legId);
    const product = selectors.getProduct(appState, legId);
    const eventTypes = getEventTypesByProduct(product);

    return {
      legId,
      isEligible: true,
      secondaryType,
      eventTypes,
    };
  });

  return {
    secondaryTypes,
    isLoading,
    isReadonly: isReadOnlyAtCurrentWorkflow,
  };
}
