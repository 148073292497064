import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import type { GlobalErrors } from '../../../common/business/commonModel';
import type { AppState } from '@/bootstrap/store.ts';

interface SessionLostModalProps {
  globalErrors: GlobalErrors;
}

const ModalWithStyle = (props: SessionLostModalProps) => (
  <Modal show={!props.globalErrors?.sessionActive} size="lg" backdrop="static" centered>
    <Modal.Header>
      <Modal.Title>Your session has now ended</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      For security reasons your session has timed out. Sign in again to continue using this service.
      <br />
      If you require assistance please contact you SG representative.
    </Modal.Body>
    <Modal.Footer>
      <button
        type="button"
        className="btn btn-lg btn-primary"
        data-e2e="restart-session"
        onClick={_ => window.location.reload()}
      >
        Restart
      </button>
    </Modal.Footer>
  </Modal>
);

export const SessionLostModal = connect(mapStateToProps)(ModalWithStyle);

function mapStateToProps(state: AppState): SessionLostModalProps {
  return {
    globalErrors: state.globalErrors,
  };
}
