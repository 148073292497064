import type { Thunk } from '@/bootstrap/thunks';

import type { Action } from '@/bootstrap/actions.ts';

export function createPreconfirmationPreferencesGroupUpdatedThunk(
  groupKey: string,
  enabled: boolean,
): Thunk {
  return function preconfirmationPreferencesGroupUpdatedThunk(
    dispatch,
    _getState,
    {
      actionCreators: {
        neos: {
          addPreconfPrefGroupToLoadingGroupAction,
          addUserPrefPreconfGroupsRequestedActionCreator,
          deleteUserPrefPreconfGroupsRequestedActionCreator,
        },
      },
    },
  ) {
    const actions: Action[] = [
      addPreconfPrefGroupToLoadingGroupAction(groupKey),
      enabled
        ? addUserPrefPreconfGroupsRequestedActionCreator(groupKey)
        : deleteUserPrefPreconfGroupsRequestedActionCreator(groupKey),
    ];

    dispatch(actions);
  };
}
