import { actionCreators } from '@/bootstrap/actions';
import {
  type DealType,
  dealTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { useContext } from 'react';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect.tsx';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function DealTypeComponent() {
  const { product } = useContext(ElsFeaturesContext);
  const { uuid, dealType } = product;
  const dispatch = useAppDispatch();

  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onDealTypeChange = (newDealType: DealType | undefined) => {
    dispatch(updateProduct(uuid, { dealType: newDealType }));
  };

  return (
    <ElsBlocField
      label="Deal type"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          options={dealTypes}
          onChange={value => onDealTypeChange(value)}
          value={dealType}
          data-e2e="product-details-deal-type"
        />
      )}
    />
  );
}
