import type { Selectors } from '@/bootstrap/selectors';
import type { Contact } from '@/neos/business/rfq/actorsSetup/contact/contactModel';
import { transfereeContactsDomain } from '@/neos/business/rfq/transfereeContacts/transfereeContactsDomain';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { uniqBy } from 'lodash';
import type { AppState } from '@/bootstrap/store.ts';

export const transfereeContactsSelectors = {
  getRfqTransfereesContacts,
  transfereeContactSelectors: transfereeContactsDomain.selectors,
};

export function getRfqTransfereesContacts(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Contact[] {
  const transfereeIds = selectors.getOtcAllocationsNovationRemainingTransferees(
    state,
    rfqId,
    selectors,
  );
  const allTransfereeContacts = transfereeIds
    .flatMap(transfereeId =>
      selectors.transfereeContactSelectors.find(state.transfereeContacts, transfereeId.toString()),
    )
    .filter(isDefined);
  return uniqBy(allTransfereeContacts, contact => contact.id).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
}
