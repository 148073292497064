import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { OptionType } from '../../../../../../../neos/business/neosModel';
import {
  OptionTypeCells as Component,
  type OptionTypeCellsDispatchProps,
  type OptionTypeCellsMapStateProps,
  type OptionTypeCellsOwnProps,
} from './OptionTypeCells';
import { getOptionTypeCellsModel } from './getOptionTypeCellsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const OptionTypeCells = connect(mapStateToProps, mapDispatchToProps)(Component);

function mapStateToProps(
  state: AppState,
  { strategyId, rfqId }: OptionTypeCellsOwnProps,
): OptionTypeCellsMapStateProps {
  return getOptionTypeCellsModel(state, rfqId, strategyId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: OptionTypeCellsOwnProps,
): OptionTypeCellsDispatchProps {
  return {
    onOptionTypeChanged(productId: string, optionType: OptionType | undefined) {
      dispatch(
        neosThunks.createUpdateProductAndResetDependenciesThunk(
          rfqId,
          strategyId,
          productId,
          'type',
          optionType,
        ),
      );
    },
  };
}
