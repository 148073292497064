import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export interface StrategyOptionsIndicatorsModel {
  isMasterStrategy: boolean;
  isSelectedStrategy: boolean;
  hasBookingSteps: boolean;
}

export function getStrategyOptionsIndicatorsModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): StrategyOptionsIndicatorsModel {
  const { isMasterStrategy } = selectors.getStrategyData(state, strategyId);
  const isSelectedStrategy =
    selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId) === strategyId;
  const hasBookingSteps = selectors.hasRFQBookingSteps(state, rfqId);
  return {
    isMasterStrategy,
    isSelectedStrategy,
    hasBookingSteps,
  };
}
