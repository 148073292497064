import {
  commonElsBlocClasses,
  ElsFeaturesContext,
} from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures.tsx';
import { If } from '@/neos/components/share/if/if.tsx';
import { Switch } from '@/neos/components/share/switch/Switch.tsx';
import { useContext } from 'react';
import { thunks } from '@/bootstrap/thunks.ts';
import { useUpdateSectionHeight } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/useUpdateSectionHeight.ts';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip.tsx';
import { ElsBuyAndSellDescription } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/BuyAndSellBloc/ElsBuyAndSellDescription.tsx';
import { ElsBuyAndSellComponents } from './ElsBuyAndSellComponents';
import { ElsBuyAndSellExport } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/BuyAndSellBloc/ElsBuyAndSellExport.tsx';
import { ElsBuyAndSellImport } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/BuyAndSellBloc/ElsBuyAndSelltImport.tsx';
import { ElsBuyAndSellClipboardPaste } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/BuyAndSellBloc/ElsBuyAndSellClipboardPaste.tsx';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type ElsBuyAndSellProps = {
  canBeHidden: boolean;
};

export function BuyAndSellBloc({ canBeHidden }: ElsBuyAndSellProps) {
  const dispatch = useAppDispatch();
  const { rfqId, strategyId, product } = useContext(ElsFeaturesContext);
  const { ref } = useUpdateSectionHeight(strategyId, 'Buy & Sell');

  const isToggled = product.equityHedge !== undefined;

  function handleBuyAndSellToggle() {
    dispatch(thunks.neos.createToggleElsBuyAndSellFeatureThunk(rfqId, product.uuid, false));
  }

  function hideSection() {
    dispatch(thunks.neos.createToggleElsSectionThunk('Buy & Sell', false));
  }

  function handleReset() {
    dispatch(thunks.neos.createToggleElsBuyAndSellFeatureThunk(rfqId, product.uuid, true));
  }

  return (
    <div className={commonElsBlocClasses} style={{ cursor: 'grab' }}>
      <div ref={ref}>
        <div className="d-flex justify-content-between">
          <div
            onMouseDown={e => e.stopPropagation()}
            className="d-flex gap-2 align-items-center mb-2"
            style={{ cursor: 'default' }}
          >
            <Switch onChange={handleBuyAndSellToggle} checked={isToggled} />

            <h5 className="d-inline-block me-1">Buy & Sell</h5>

            <If condition={isToggled}>
              <SimpleNeosTooltip message="Reset Buy & Sell">
                <button
                  className="btn btn-icon btn-discreet-primary"
                  onClick={handleReset}
                  data-e2e="els-buy-and-sell-reset"
                >
                  <i className="icon">refresh</i>
                </button>
              </SimpleNeosTooltip>
            </If>
          </div>

          {canBeHidden && (
            <button className="btn btn-icon btn-flat-primary" onClick={hideSection}>
              <i className="icon">clear</i>
            </button>
          )}
        </div>

        <If condition={isToggled}>
          <div className="d-flex flex-column gap-2">
            <ElsBuyAndSellDescription />
            <div className="d-flex justify-content-end">
              <div
                onMouseDown={e => e.stopPropagation()}
                className="d-flex gap-1 align-items-center"
                style={{ cursor: 'default' }}
              >
                <ElsBuyAndSellClipboardPaste product={product} rfqId={rfqId} />
                <ElsBuyAndSellExport product={product} rfqId={rfqId} />
                <ElsBuyAndSellImport product={product} rfqId={rfqId} />
              </div>
            </div>
            <ElsBuyAndSellComponents />
          </div>
        </If>
      </div>
    </div>
  );
}
