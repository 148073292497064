import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import type { BarriersFeatureKey } from '../../../../../../../business/neosModel';
import {
  type AddBarrierButtonDispatchProps,
  type AddBarrierButtonOwnProps,
  type AddBarrierButtonStateProps,
  AddBarrierButton as Component,
} from './AddBarrierButton';
import { getAddBarrierButtonModel } from './getAddBarrierButtonModel';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(
  appState: AppState,
  { strategyId }: AddBarrierButtonOwnProps,
): AddBarrierButtonStateProps {
  return {
    model: getAddBarrierButtonModel(appState, strategyId, selectors),
  };
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: AddBarrierButtonOwnProps,
): AddBarrierButtonDispatchProps {
  const featureKey: BarriersFeatureKey = { strategyId, type: 'BARRIERS' };
  return {
    onBarrierAdded: () => dispatch(neosThunks.createRfqAddBarrierThunk(rfqId, featureKey)),
  };
}

export const AddBarrierButton = connect(mapStateToProps, mapDispatchToProps)(Component);
