import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { NeosApp } from '@/neos/components/NeosApp.container';
import type { FC } from 'react';
import { AppCrashError } from './AppCrashError';
import styles from './ApplicationSelector.module.scss';

export const ApplicationSelector: FC = () => {
  const isAppCrashed = useAppSelector(state => selectors.isAppCriticallyCrashed(state));

  return isAppCrashed ? (
    <AppCrashError />
  ) : (
    <div className={styles['application']}>
      <NeosApp />
    </div>
  );
};
