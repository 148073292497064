import type { Selectors } from '@/bootstrap/selectors';
import { isValidSecondaryWidgetStatus } from '@/neos/business/secondaryWidget/isValidSecondaryWidgetStatus';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { EventType } from '../../../neos/business/neosModel';
import { legSecondaryEventCreationDomain } from './legSecondaryEventCreationDomain';
import type { LegSecondaryEventCreationKey } from './legSecondaryEventCreationModel';
import type { AppState } from '@/bootstrap/store.ts';

export const legSecondaryEventCreationSelectors = {
  getLegSecondaryEventCreation,
  getRfqLegsSecondaryEventCreationEventTypes,
  getLegSecondaryEventCreationLegIds,
  isLegEligibleToSecondaryEventCreation,
};

function getLegSecondaryEventCreation(
  state: AppState,
  key: LegSecondaryEventCreationKey,
): EventType | undefined {
  return legSecondaryEventCreationDomain.selectors.find(state.legSecondaryEventCreation, key)
    ?.eventType;
}

export function isLegEligibleToSecondaryEventCreation(
  state: AppState,
  rfqId: string,
  legId: string,
  selectors: Selectors,
): boolean {
  const { status } = selectors.getRfqData(state, rfqId);

  const isOtcLeg = selectors.isOtcLeg(state, legId, selectors);
  const { eventType } = selectors.getLegData(state, legId);
  const isEventTypeCreate = eventType === 'CREATE';
  const isEventTypeStepIn = eventType === 'STEPIN';
  return (
    isOtcLeg && (isEventTypeCreate || isEventTypeStepIn) && isValidSecondaryWidgetStatus(status)
  );
}

function getRfqLegsSecondaryEventCreationEventTypes(state: AppState, rfqId: string): EventType[] {
  return legSecondaryEventCreationDomain.selectors
    .selectObjects(state.legSecondaryEventCreation, { rfqId })
    .map(({ eventType }) => eventType)
    .filter(isDefined);
}

function getLegSecondaryEventCreationLegIds(state: AppState, rfqId: string) {
  return legSecondaryEventCreationDomain.selectors
    .selectKeys(state.legSecondaryEventCreation, { rfqId })
    .map(({ legId }) => legId);
}
