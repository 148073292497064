import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { connect } from 'react-redux';
import {
  PriceRequestDateComponent,
  type PriceRequestDateDispatchProps,
  type PriceRequestDateMapStateToProps,
  type PriceRequestDateOwnProps,
} from './PriceRequestDate';
import { getPriceRequestDateModel } from './getPriceRequestDateModel';
import type { AppState } from '@/bootstrap/store.ts';

export const PriceRequestDate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PriceRequestDateComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: PriceRequestDateOwnProps,
): PriceRequestDateMapStateToProps {
  return getPriceRequestDateModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: PriceRequestDateOwnProps,
): PriceRequestDateDispatchProps {
  return {
    onDateChanged(newDate: string) {
      dispatch(neosThunks.createRfqChangePriceRequestDateThunk(rfqId, newDate));
    },
    onTimeChanged(newTime: string) {
      dispatch(neosThunks.createRfqChangePriceRequestTimeThunk(rfqId, newTime));
    },
  };
}
