import type { Selectors } from '@/bootstrap/selectors';
import type { NeosAppMapStateToProps } from './NeosApp';
import type { AppState } from '@/bootstrap/store.ts';

export function getNeosAppModel(state: AppState, selectors: Selectors): NeosAppMapStateToProps {
  return {
    contentIsDisabled: !selectors.getAllReferenceDataAreFetched(state.referenceData),
    isDividedView: selectors.isDividedView(state.ui, selectors),
  };
}
