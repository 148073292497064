import {
  getCurrentUserPreferencesEpic,
  postCurrentUserPreferencesEpic,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesEpic';
import { getCurrentUserPreferencesReferentialEpic } from '@/neos/business/currentUserPreferences/currentUserPreferencesReferentialEpic';
import { getBulkLoadTransactionsEpic } from '@/neos/business/epics/bulkLoadTransactionsEpic';
import { getCreateCreateSecondaryFromXoneIdEpic } from '@/neos/business/epics/createSecondaryFromXoneIdEpic';
import { getSearchBrokerNotInPreferencesEpic } from '@/neos/business/epics/seachBrokerNotInPreferencesEpic';
import { getGetLastTradeCompletedRfqEpic } from '@/neos/business/lastOnyxTradeCompletedRfq/getLastTradeCompletedRfqEpic';
import { TradeRecapMailCCRecipientsEpic } from '@/neos/business/rfq/tradeRecapMail/TradeRecapMailCCRecipientsEpic';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { combineEpics } from 'redux-observable';
import { getCurvesListEpic } from '../../components/rfq/strategies/strategy/features/els/curvesListEpics';
import { getRetreiveAlertEpic } from '../alert/alertEpic';
import { getBlotterRootEpic } from '../blotter/epics';
import { getElectronicMediaUsersEpic } from '../electronicMediaUsers/electronicMediaUsersEpic';
import { generatePreconfEpic } from '../preConfirmationStep/generatePreconfEpic';
import { getExternalPreconfirmationPreviewEpic } from '../preconfirmation/external/externalPrefconfirmationPreviewEpic';
import { getSendExternalPreconfirmationSendEpic } from '../preconfirmation/external/externalPrefconfirmationSendEpic';
import { getInternalPreconfirmationPreviewEpic } from '../preconfirmation/internal/internalPrefconfirmationPreviewEpic';
import { getSendInternalPreconfirmationSendEpic } from '../preconfirmation/internal/internalPrefconfirmationSendEpic';
import { getPredealChecksEpics } from '../predealCheck/epics';
import { getRetrievePrimeBrokersEpic } from '../referenceData/primeBroker/primeBrokerEpics';
import { getReferenceEpic } from '../referenceData/referenceDataEpics';
import { getRfqRootEpic } from '../rfq/rfqEpics';
import {
  getDownloadAllocationFileEpic,
  getUploadAllocationFileEpic,
} from '../rfq/strategy/leg/otcAllocation/otcAllocationFile/handleAllocationFileEpic';
import { getSimilarActivitiesEpics } from '../similarActivities/epics';
import { getTriggerMatchingEpic } from '../triggerMatching/triggerMatchingEpics';
import { getUiRootEpic } from '../ui/uiEpics';
import { getUnderlyingOptionInfoEpic } from '../underlyingInfo/underlyingInfoEpics';
import { getApplyNextUserActionEpic } from './applyNextUserActionEpic';
import { getCancelNegotiationEpic } from './cancelNegotiationEpic';
import { getCreateCreateSecondaryEventEpic } from './createSecondaryEventEpic';
import { getLoadTransactionEpic } from './loadTransactionEpic';
import { getMailRequestEpic } from './mailRequestEpic';
import { getNotificationSubscriptionEpic } from './notificationSubscriptionEpic';
import { getNotificationUnsubscriptionEpic } from './notificationUnsubscriptionEpic';
import { getNextUserActionsEpic } from './retrieveNextUserActionsEpic';
import { getAllocRetrieveCashflowsEpic } from '@/neos/business/epics/allocationRetrieveCashflowsEpics.ts';
import { getPartialUnderlyingOptionInfoEpic } from '@/neos/business/underlyingInfo/partialUnderlyingInfoEpics.ts';
import { createRetrieveUserInfoEpic } from '@/common/business/userInfo/userInfoEpics.ts';
import { createSaveStateToStorageEpic } from '@/common/business/helpRequest/saveStateToStorageEpic.ts';
import type { ThunkEpic } from '@/bootstrap/epics.ts';

export function getNeosRootEpic(http: SgmeHttp): ThunkEpic {
  return combineEpics(
    getReferenceEpic(http),
    getRetrievePrimeBrokersEpic(http),
    getRfqRootEpic(http),
    getUiRootEpic(http),
    getUnderlyingOptionInfoEpic(http),
    getPartialUnderlyingOptionInfoEpic(http),
    getBlotterRootEpic(http),
    getLoadTransactionEpic(http),
    getBulkLoadTransactionsEpic(http),
    getCancelNegotiationEpic(http),
    getPredealChecksEpics(http),
    getMailRequestEpic(http),
    getNotificationSubscriptionEpic(http),
    getNotificationUnsubscriptionEpic(http),
    getNextUserActionsEpic(http),
    getApplyNextUserActionEpic(http),
    getRetreiveAlertEpic(http),
    getSimilarActivitiesEpics(http),
    getSendInternalPreconfirmationSendEpic(http),
    getSendExternalPreconfirmationSendEpic(http),
    getInternalPreconfirmationPreviewEpic(http),
    getExternalPreconfirmationPreviewEpic(http),
    getCreateCreateSecondaryEventEpic(http),
    getElectronicMediaUsersEpic(http),
    getCurvesListEpic(http),
    getUploadAllocationFileEpic(http),
    getDownloadAllocationFileEpic(http),
    getTriggerMatchingEpic(http),
    generatePreconfEpic(http),
    getCreateCreateSecondaryFromXoneIdEpic(http),
    TradeRecapMailCCRecipientsEpic(http),
    getGetLastTradeCompletedRfqEpic(http),
    getCurrentUserPreferencesEpic(http),
    postCurrentUserPreferencesEpic(http),
    getCurrentUserPreferencesReferentialEpic(http),
    getSearchBrokerNotInPreferencesEpic(http),
    getAllocRetrieveCashflowsEpic(http),
    createRetrieveUserInfoEpic(http),
    createSaveStateToStorageEpic(http),
  );
}
