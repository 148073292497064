import type { Thunk } from '@/bootstrap/thunks';
import { toDisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import type { StrategyType } from '../../../../../business/neosModel';
import type { LegData } from '../legModel';
import type { Action } from '@/bootstrap/actions.ts';

export function createLoadLegsThunk(
  rfqId: string,
  strategyId: string,
  strategyType: StrategyType,
  legsData: LegData[],
): Thunk {
  return function loadLegsThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: {
          legDataCrudActions,
          strategyUiCrudActions,
          createSearchBrokerNotInPreferencesAction,
        },
      },
    },
  ) {
    const state = getState();
    const localNationalEnabled = selectors.isFeatureToggleEnabled(
      state,
      'neos.rfq.localNotional.enabled',
    );
    const {
      getStrategyTypeDefaultSizeType,
      getStrategyUi,
      isStrategyRfqInitiatedByTrader,
      getPreferenceBrokerById,
    } = selectors;

    const defaultSizeType = getStrategyTypeDefaultSizeType(state, strategyType, selectors);
    const actions: Action[] = legsData.map(legData =>
      legDataCrudActions.upsert(legData.uuid, {
        ...legData,
        localNotional: localNationalEnabled ? legData.localNotional : legData.notional,
        localNotionalUnit: localNationalEnabled ? legData.localNotionalUnit : legData.notionalUnit,
        sizeType: legData.sizeType ?? defaultSizeType,
      }),
    );

    const strategyUi = getStrategyUi(state.ui, strategyId);
    if (!strategyUi) {
      const newSizeType = legsData[0].sizeType ?? defaultSizeType;
      const displayedSizeType = toDisplayNegotiatedSize[newSizeType];
      const defaultDisplayedSizeType =
        displayedSizeType === 'NOTIONAL' ? 'LOCAL_NOTIONAL' : displayedSizeType;

      actions.push(
        strategyUiCrudActions.insert(strategyId, {
          displayedSizeType: defaultDisplayedSizeType,
          displayMissingCompositionDataWarning: false,
          displayCompoImportHasMultipleIndicesWarning: false,
        }),
      );
    }
    if (strategyType === 'ELS' && isStrategyRfqInitiatedByTrader(state, strategyId, selectors)) {
      const { brokerId } = legsData[0];

      if (brokerId && !getPreferenceBrokerById(state.referenceData, brokerId)) {
        actions.push(createSearchBrokerNotInPreferencesAction(rfqId, brokerId));
      }
    }
    dispatch(actions);
  };
}
