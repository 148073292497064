import type { Selectors } from '@/bootstrap/selectors';
import { isClsProduct, isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { AppState } from '@/bootstrap/store.ts';

export const ScheduleSelectors = {
  selectScheduleDividendLegDates,
  selectScheduleEquityLegDates,
  selectScheduleRateLegDates,
  isScheduleUiToggleEnabled,
};

function selectScheduleEquityLegDates(state: AppState, strategyId: string, selectors: Selectors) {
  const product = selectors.getStrategyProduct(state, strategyId, selectors);
  return isElsProduct(product) ? product.equityPeriods : [];
}

function selectScheduleDividendLegDates(state: AppState, strategyId: string, selectors: Selectors) {
  const feature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'DIVIDEND_COMPONENT',
  });

  return feature?.dividendSchedulePeriods;
}

function selectScheduleRateLegDates(state: AppState, strategyId: string, selectors: Selectors) {
  const product = selectors.getStrategyProduct(state, strategyId, selectors);

  if (!isElsProduct(product) && !isClsProduct(product)) {
    return [];
  }

  return product.ratePeriods;
}

function isScheduleUiToggleEnabled(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const uiStrategy = selectors.getUiStrategy(state.ui, strategyId);
  return !!uiStrategy?.isScheduleUiToggleEnabled;
}
