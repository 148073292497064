import type { Selectors } from '@/bootstrap/selectors';
import { getIsStrategyDefinitionFieldEditable } from '../utils/fieldsEnablingConditions';
import type { AppState } from '@/bootstrap/store.ts';

export interface IsMasterLegCellsModel {
  isMasterLeg: Array<{
    id: string;
    value: boolean;
  }>;
  disabled: boolean;
}

export function getIsMasterLegCellsModel(
  appState: AppState,
  strategyId: string,
  rfqId: string,
  selectors: Selectors,
): IsMasterLegCellsModel {
  const { strategyType: selectedStrategyType } = selectors.getStrategyData(appState, strategyId);
  const legsData = selectors.getStrategyLegsData(appState, strategyId, selectors);
  const { readOnlyMaster } = selectors.getStrategyDefinition(
    appState.referenceData,
    selectedStrategyType,
  );

  const isMasterLeg = legsData.map(leg => ({
    id: leg.uuid,
    value: leg.isMaster,
  }));

  const isReadonlyAtWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );

  const disabled =
    readOnlyMaster ||
    !getIsStrategyDefinitionFieldEditable(appState, rfqId, strategyId, selectors, [
      'RFQ_SCOPE',
      'RFQ_EDITABLE',
      'UNDERLYING_SET',
      'STRATEGY_EDITABLE',
    ]) ||
    isReadonlyAtWorkflow;

  return {
    disabled,
    isMasterLeg,
  };
}
