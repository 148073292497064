import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { commonSelectors } from '@/common/business/commonSelectors';
import { isCustomEvent } from '@/util/customEvent/customEventCheck';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/store.ts';

interface HelpCenterPropsFromState {
  userName: string;
  login: string;
  additionalMessageInformation: string;
}

interface HelpCenterDispatchProps {
  requestForAnalyticLog(): void;
}

const messageTopic = JSON.stringify([{ topic: 'Suggest an enhancement' }]);

type HelpCenterProps = HelpCenterPropsFromState & HelpCenterDispatchProps;

function HelpCenterUnconnected({
  requestForAnalyticLog,
  additionalMessageInformation,
}: HelpCenterProps) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [imagePath, setImagePath] = useState('');

  useEffect(() => {
    document
      .querySelector('#flow-help-center')!
      .addEventListener('sgwt-help-center--open', requestForAnalyticLog);

    const defaultingListener = (e: Event) => {
      if (isCustomEvent(e)) {
        if (typeof e.detail.path === 'string') {
          setImagePath(e.detail.path as string);
        }
        setIsModalShown(true);
      } else {
        throw new Error('This is not a CustomEvent, property detail is missing!');
      }
    };
    document.addEventListener('help-center-defaulting', defaultingListener);

    return () => {
      document
        .querySelector('#flow-help-center')!
        .removeEventListener('sgwt-help-center--open', requestForAnalyticLog);

      document.removeEventListener('help-center-defaulting', defaultingListener);
      setIsModalShown(false);
    };
  }, [requestForAnalyticLog]);

  function onModalHide() {
    setIsModalShown(false);
  }

  return (
    <>
      <Modal show={isModalShown} onHide={onModalHide} dialogClassName="img-modal">
        <Modal.Header closeButton />
        <br />
        <Modal.Body>
          <img src={imagePath} alt="help-center-image" />
        </Modal.Body>
      </Modal>
      <div>
        <sgwt-help-center
          id="flow-help-center"
          sg-connect-support="sg-connect-v2"
          application-id={'sgme-neos'}
          additional-message-information={additionalMessageInformation}
          message-topics={messageTopic}
        />
      </div>
    </>
  );
}

function mapStateToProps(state: AppState): HelpCenterPropsFromState {
  const userInfo = commonSelectors.getCurrentUser(state);
  const userName = commonSelectors.getUserName(state);
  const { login } = userInfo;
  const additionalMessageInformation = selectors.getAdditionnalHelpRequestInformation(
    state,
    selectors,
  );
  return {
    userName,
    login,
    additionalMessageInformation: JSON.stringify(additionalMessageInformation),
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): HelpCenterDispatchProps {
  return {
    requestForAnalyticLog() {
      dispatch(createLogAnalyticsAction('NEOS RFQ', 'Help Center opened'));
    },
  };
}

export const HelpCenter = connect(mapStateToProps, mapDispatchToProps)(HelpCenterUnconnected);
