import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import {
  type AsianPeriod,
  averageDisruptionDateValues,
  isProductWithAsianFields,
  periodFrequencies,
  strikeTypeValues,
} from '@/neos/business/rfq/strategy/leg/product/productModel';
import { neosThunks } from '@/neos/business/thunks';
import { ConstatDates } from '@/neos/components/rfq/strategies/strategy/features/asianOptionsFeatures/ConstatDates';
import { BusinessDayConventionValues } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/ScheduleModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { DoubleDatePicker } from '@/neos/components/share/datePicker';
import { Switch } from '@/neos/components/share/switch/Switch';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { formatDoubleDate } from '@/util/date/dateFormatHelper';
import { useState } from 'react';

type AsianOptionsFeaturesProps = {
  strategyId: string;
  rfqId: string;
};

const initialPeriod: AsianPeriod = {
  endDate: '',
  startDate: '',
  dates: [],
  frequency: undefined,
  includeEndDate: undefined,
};

export function AsianOptionsFeatures({ strategyId, rfqId }: AsianOptionsFeaturesProps) {
  const [areDatesDisplayed, setAreDatesDisplayed] = useState(false);

  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );
  const isDisplayed = isProductWithAsianFields(product);

  if (!isDisplayed) {
    return null;
  }

  return (
    <div
      style={{ gridColumn: '2 / -2' }}
      className="d-flex flex-column gap-1 p-2 border fit-content"
    >
      <div className="d-flex gap-1">
        <div className="d-flex flex-column">
          <label className="fw-bold">Average Disruption Date</label>
          <NeosSelect
            addEmptyOption
            value={product.averageDisruptionDate}
            onChange={value => {
              dispatch(
                neosThunks.createUpdateProductThunk(
                  strategyId,
                  product.uuid,
                  'averageDisruptionDate',
                  value,
                ),
              );
            }}
            data-e2e="neos-features-average-disruption-date"
            options={averageDisruptionDateValues}
          />
        </div>
        <div className="d-flex flex-column">
          <label className="fw-bold">Period</label>
          <DoubleDatePicker
            dateFrom={product.period?.startDate ?? ''}
            dateTo={product.period?.endDate ?? ''}
            onDateRangeChange={dates => {
              const [newStartDate, newEndDate] = formatDoubleDate(dates);
              const period: AsianPeriod = product?.period ? product.period : initialPeriod;
              dispatch(
                neosThunks.createUpdateProductThunk(strategyId, product.uuid, 'period', {
                  ...period,
                  startDate: newStartDate,
                  endDate: newEndDate,
                }),
              );
            }}
            data-e2e="neos-features-period"
          />
        </div>

        <div className="d-flex flex-column">
          <label className="fw-bold">Frequency</label>
          <NeosSelect
            addEmptyOption
            value={product.period?.frequency}
            onChange={frequency => {
              const period: AsianPeriod = product?.period ? product.period : initialPeriod;
              dispatch(
                neosThunks.createUpdateProductThunk(strategyId, product.uuid, 'period', {
                  ...period,
                  frequency,
                }),
              );
            }}
            data-e2e="neos-features-period-frequency"
            options={periodFrequencies}
          />
        </div>

        <div className="d-flex flex-column">
          <label className="fw-bold">Incl. end date</label>
          <Switch
            checked={!!product.period?.includeEndDate}
            onChange={() => {
              const period: AsianPeriod = product?.period ? product.period : initialPeriod;
              dispatch(
                neosThunks.createUpdateProductThunk(strategyId, product.uuid, 'period', {
                  ...period,
                  includeEndDate: !period.includeEndDate,
                }),
              );
            }}
            data-e2e="neos-asian-options-features-includeEndDate"
          />
        </div>

        <div className="d-flex flex-column">
          <label className="fw-bold">Business Day Conv.</label>
          <NeosSelect
            options={BusinessDayConventionValues}
            addEmptyOption
            onChange={value =>
              dispatch(
                neosThunks.createUpdateProductThunk(
                  strategyId,
                  product.uuid,
                  'businessDayConvention',
                  value,
                ),
              )
            }
            value={product.businessDayConvention}
            data-e2e="neos-features-businessDayConvention"
          />
        </div>

        <div className="d-flex flex-column">
          <label className="fw-bold">Strike Type</label>
          <NeosSelect
            addEmptyOption
            value={product.strikeType}
            onChange={value => {
              dispatch(
                neosThunks.createUpdateProductThunk(strategyId, product.uuid, 'strikeType', value),
              );
            }}
            data-e2e="neos-features-strikeType"
            options={strikeTypeValues}
          />
        </div>

        <SimpleNeosTooltip message={`${areDatesDisplayed ? 'Hide' : 'Display'} constat dates`}>
          <button
            onClick={() => {
              setAreDatesDisplayed(value => !value);
            }}
            className={`btn btn-outline-primary align-self-end btn-icon px-2 ${areDatesDisplayed ? 'active' : ''}`}
            data-e2e="open-asian-options-dates-section"
          >
            <i className="icon" aria-hidden="true">
              {areDatesDisplayed ? 'edit_calendar' : 'calendar_month'}
            </i>
          </button>
        </SimpleNeosTooltip>
      </div>

      <ConstatDates rfqId={rfqId} areDatesDisplayed={areDatesDisplayed} product={product} />
    </div>
  );
}
