import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { availableDeterminationMethods } from '@/neos/business/neosModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { lowerThenCamelCase } from '@/util/string/stringUtils';
import type { FC } from 'react';
import { getDeterminationFieldsModel } from './getDeterminationFieldsModel';

type DeterminationFieldsProps = { strategyId: string; rfqId: string };

export const DeterminationFields: FC<DeterminationFieldsProps> = ({ strategyId, rfqId }) => {
  const dispatch = useAppDispatch();
  const {
    isClsDetermination,
    isElsDetermination,
    initialPriceDetermination,
    determinationMethod,
    areFieldsReadOnly,
  } = useAppSelector(state => getDeterminationFieldsModel(state, strategyId, selectors));

  if (isElsDetermination) {
    return null;
  }

  return (
    <>
      {isClsDetermination && (
        <>
          <label>Initial Determination Method</label>
          <NeosSelect
            value={initialPriceDetermination}
            onChange={value => {
              dispatch(
                actionCreators.neos.strategyDataCrudActions.update(strategyId, {
                  initialPriceDetermination: value,
                }),
              );
              dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
            }}
            options={availableDeterminationMethods.map(method => ({
              label: method === 'VWA_PPRICE' ? 'Vwa Price' : lowerThenCamelCase(method),
              value: method,
            }))}
            addEmptyOption
            data-e2e="strategy-details-cls-initial-determination-method"
            readOnly={areFieldsReadOnly}
          />
        </>
      )}

      <label>{isClsDetermination && 'Final '}Determination Method</label>
      <NeosSelect
        value={determinationMethod}
        onChange={value => {
          dispatch(
            actionCreators.neos.strategyDataCrudActions.update(strategyId, {
              determinationMethod: value,
            }),
          );
        }}
        options={availableDeterminationMethods.map(method => ({
          label: method === 'VWA_PPRICE' ? 'Vwa Price' : lowerThenCamelCase(method),
          value: method,
        }))}
        addEmptyOption
        data-e2e="strategy-details-determination-method"
        readOnly={areFieldsReadOnly}
      />
    </>
  );
};
