import { useAppSelector } from '@/bootstrap/hooks';
import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useContext } from 'react';
import { createDefaultRfqRequestedAction } from '@/neos/business/rfq/rfqActionCreators/defaultRfqRequestedAction.ts';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function SettlementMethodElection() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );

  if (!isElsProduct(product)) {
    return null;
  }

  const { uuid, settlementMethodElection } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onSettlementMethodElectionChange = () => {
    const options = new DefaultingOptions({ overrideScopes: [DefaultingScope.ELECTION_DATE] });
    dispatch([
      updateProduct(uuid, { settlementMethodElection: !settlementMethodElection }),
      createDefaultRfqRequestedAction(rfqId, options),
    ]);
  };

  return (
    <ElsBlocField
      label={{ type: 'tooltip', content: 'SME', tooltip: 'Settlement Method Election' }}
      renderInput={readOnly => (
        <Switch
          disabled={readOnly}
          switchClassName="text-center"
          data-e2e="neos-extra-features-els-compound"
          checked={!!settlementMethodElection}
          onChange={() => onSettlementMethodElectionChange()}
        />
      )}
    />
  );
}
