import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { NumericInput } from '@/neos/components/share/numericInput';

export function RemainingQuantity({
  allocationId,
  rfqId,
  legId,
}: {
  allocationId: string;
  rfqId: string;
  legId: string;
}) {
  const currentAllocation = useAppSelector(state =>
    selectors.getOtcAllocation(state, rfqId, legId, allocationId),
  );
  const remainingQuantity = currentAllocation?.secondaryInfo?.remainingQuantity;

  return (
    <NumericInput value={remainingQuantity} readOnly={true} data-e2e={`otc-alloc-remaining`} />
  );
}
