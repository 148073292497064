import type { Selectors } from '@/bootstrap/selectors';
import type { UpdateJustificationLabel } from '@/neos/business/rfq/strategy/leg/otcAllocation/updateJustificationModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getRfqUpdateJustificationLabel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): UpdateJustificationLabel | undefined {
  const rfqMasterStrategy = selectors.getRfqMasterStrategy(state, rfqId);
  const strategyMasterLeg = selectors.getStrategyMasterLeg(
    state,
    rfqMasterStrategy.uuid,
    selectors,
  );
  const firstOtcAllocation = selectors
    .getOtcAllocationsByLegId(state, strategyMasterLeg.uuid)
    .at(0);

  return firstOtcAllocation?.updateJustificationLabel;
}
