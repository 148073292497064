import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import {
  createUserPreferencesReferentialApis,
  type CurrentUserPreferencesReferentialApis,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesReferentialApis';
import type { OnyxError } from '@/neos/business/mappers/error';
import type { SalesGroup } from '@/neos/business/referenceData/salesGroups/salesGroupsModel';
import type { TraderGroup } from '@/neos/business/referenceData/traderGroups/traderGroupsModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import type {
  UserPreferencesCity,
  UserPreferencesCountry,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel.ts';
import { type CurrentUserPreferencesState } from '@/neos/business/currentUserPreferences/currentUserPreferencesModel.ts';

export function getCurrentUserPreferencesReferentialEpic(http: SgmeHttp) {
  const api = createUserPreferencesReferentialApis(http);
  return createGetUserPreferencesReferentialEpic(api, thunks, actionCreators);
}

export function createGetUserPreferencesReferentialEpic(
  apis: CurrentUserPreferencesReferentialApis,
  { createErrorToasterThunk }: Thunks,
  { neos: { currentUserPreferencesActionCreators } }: ActionCreators,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('CURRENT_USER_PREFERENCES_REFERENTIAL_REQUESTED'),
      mergeMap(() => {
        const isTrader = state$.value.userInfo.currentUser.categories.includes('TRADER');

        const arr$: [
          Observable<UserPreferencesCity[]>,
          Observable<UserPreferencesCountry[]>,
          Observable<TraderGroup[] | SalesGroup[]>,
        ] = [
          apis.getCities(),
          apis.getCountries(),
          isTrader ? apis.getTraderGroups() : apis.getDesks(),
        ];

        return forkJoin(arr$).pipe(
          mergeMap(([cities, countries, deskOrGroup]) => {
            const userPrefReferential: CurrentUserPreferencesState['referential'] = {
              cities,
              countries,
              tradergroups: isTrader ? (deskOrGroup as TraderGroup[]) : undefined,
              desks: !isTrader ? (deskOrGroup as SalesGroup[]) : undefined,
            };

            return [currentUserPreferencesActionCreators.referentialReceived(userPrefReferential)];
          }),
          catchError((error: OnyxError) => [
            createErrorToasterThunk(
              {
                message: 'Error getting user preferences referential',
              },
              error,
            ),
          ]),
        );
      }),
    );
}
