import type { Selectors } from '@/bootstrap/selectors';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { EventType } from '../../../neos/business/neosModel';
import {
  type AllocSecondaryEventCreationKey,
  allocSecondaryEventCreationDomain,
} from './allocSecondaryEventCreationDomain';
import type { AppState } from '@/bootstrap/store.ts';

export const allocSecondaryEventCreationSelectors = {
  getAllocationSecondaryEventCreation,
  isOtcAllocSecondaryWidgetColumnDisplayed,
  areSomeAllocSecondaryEventTypesSelectedForTheRfq,
};

function getAllocationSecondaryEventCreation(
  state: AppState,
  key: AllocSecondaryEventCreationKey,
): EventType | undefined {
  return allocSecondaryEventCreationDomain.selectors.find(state.allocSecondaryEventCreation, key)
    ?.eventType;
}

function areSomeAllocSecondaryEventTypesSelectedForTheRfq(state: AppState, rfqId: string): boolean {
  const rfqAllocsEventTypes = allocSecondaryEventCreationDomain.selectors
    .selectObjects(state.allocSecondaryEventCreation, { rfqId })
    .map(({ eventType }) => eventType);
  return rfqAllocsEventTypes.length > 0 && rfqAllocsEventTypes.some(isDefined);
}

export function isOtcAllocSecondaryWidgetColumnDisplayed(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const rfqLegIds = selectors.getRfqLegIds(state, rfqId, selectors);
  const someRfqLegAreEligibleToSecondaryEventCreation = rfqLegIds.some(legId =>
    selectors.isLegEligibleToSecondaryEventCreation(state, rfqId, legId, selectors),
  );
  const areSecondaryFeatureTogglesEnabled =
    selectors.areEveryRfqProductsSecondaryFeatureTogglesEnabled(state, rfqId, selectors);

  return (
    rfqLegIds.length > 0 &&
    someRfqLegAreEligibleToSecondaryEventCreation &&
    areSecondaryFeatureTogglesEnabled
  );
}
