import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';

export function ElectionDate() {
  const { strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, electionDate } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onElectionDateChange = (newElectionDate: string) => {
    dispatch(updateProduct(uuid, { electionDate: newElectionDate }));
  };

  return (
    <ElsBlocField
      label="Election Date"
      renderInput={readOnly => (
        <FlowDatePicker
          readOnly={readOnly}
          onChange={value => {
            onElectionDateChange(value);
          }}
          maturities={[]}
          date={electionDate ?? ''}
          hideCalendarIcon
          className="errorable-bloc"
          inputClassName="form-control w-100px"
          popperPlacement="top"
        />
      )}
    />
  );
}
