import type { Selectors } from '@/bootstrap/selectors';
import type { PriceWithKey } from '@/neos/business/rfq/quotes/quote.selectors';
import type { AppState } from '@/bootstrap/store.ts';

export interface StrategyPtmmmModel {
  displayEmptyFirstLeg: boolean;
  strategyPtmmm: PriceWithKey;
  legsPtmmms: PriceWithKey[];
  isReadonly: boolean;
}

export function getStrategyPtmmmModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): StrategyPtmmmModel {
  const { quoteId } = selectors.getStrategyData(state, strategyId);

  const {
    displayedPricesLegIds: legIds,
    displayReadonlyCellsForCompositionLeg: displayEmptyFirstLeg,
  } = selectors.getIdsOfLegsWithDisplayedPrices(state, rfqId, strategyId, selectors);

  const legsPtmmms = selectors.getLegsPtmmms(state, legIds, selectors);

  const { preTradeMidMarketMarkPrice: ptmmm } = quoteId
    ? selectors.getQuote(state, quoteId)
    : { preTradeMidMarketMarkPrice: undefined };
  const isReadonly = selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors);
  return {
    displayEmptyFirstLeg,
    strategyPtmmm: { price: ptmmm, key: strategyId },
    legsPtmmms,
    isReadonly,
  };
}
