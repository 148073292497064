import { type Selectors } from '@/bootstrap/selectors.ts';
import type { AppState } from '@/bootstrap/store.ts';

type RegeneratePreconfsButtonModel = {
  isDisplayed: boolean;
};

export function getRegeneratePreconfsButtonModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RegeneratePreconfsButtonModel {
  const rfqPreConfSteps = selectors.preConfirmationStepSelectors.selectObjects(
    state.preConfirmationStep,
    {
      rfqId,
    },
  );

  const isStatusAtOrAfterOrderAccepted = selectors.isStatusAtOrAfterOrderAccepted(
    state,
    rfqId,
    selectors,
  );

  const isDisplayed = !!rfqPreConfSteps.length || isStatusAtOrAfterOrderAccepted;

  return { isDisplayed };
}
