import { actionCreators } from '@/bootstrap/actions';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { NumericInput } from '@/neos/components/share/numericInput';
import type { FC } from 'react';

type DeltaStockAllocationPriceProps = {
  legId: string;
};

export const DeltaStockAllocationPrice: FC<DeltaStockAllocationPriceProps> = ({ legId }) => {
  const dispatch = useAppDispatch();
  const legData = useAppSelector(state => selectors.getLegData(state, legId));
  const quote = useAppSelector(state => selectors.getQuote(state, legData.quoteId));

  return (
    <NumericInput
      value={legData.clientWay === 'BUY' ? quote.salesAsk : quote.salesBid}
      unit={quote.unit}
      data-e2e="delta-stock-allocation-execution-price"
      onBlur={newPrice => {
        dispatch(
          actionCreators.neos.quoteCrudActions.update(legData.quoteId, {
            salesAsk: newPrice,
            salesBid: newPrice,
          }),
        );
      }}
    />
  );
};
