import type { Selectors } from '@/bootstrap/selectors';
import {
  isClsProduct,
  isElsProduct,
  isListedProduct,
  isSingleUnderlyingDerivativeProduct,
  isVSwapProduct,
  type Product,
} from './productModel';
import { getIsElsSpotConfirmed } from '@/neos/business/rfq/strategy/leg/product/getIsElsSpotConfirmed.ts';
import { elsSelectors } from '@/neos/business/rfq/strategy/leg/product/els/selectors';
import type { UnderlyingField } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/underlyingCells/getUnderlyingCellsModel.ts';
import type { AppState } from '@/bootstrap/store.ts';

export const productSelectors = {
  ...elsSelectors,
  getIsElsSpotConfirmed,
  getUnderlyingId,
  getBloombergCode,
  getIsElsScheduleWarningDisplayed,
  areSomeElsScheduleDates,
  getElsScheduleWarning,
  getIsClsScheduleWarningDisplayed,
  canClsScheduleBeGenerated,
  areSomeClsScheduleDates,
  canElsScheduleBeGenerated,
  hasRfqObsoleteProductSchedule,
  isVSwapProductInAmendWorkflowStatus,
  getVarVolDispersionUnderlyingFieldsArray,
};

export function isVSwapProductInAmendWorkflowStatus(
  state: AppState,
  rfqId: string,
  product: Product,
  selectors: Selectors,
) {
  const isVSwap = isVSwapProduct(product);
  const isInAmendWorkflow = selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors);
  return isVSwap && isInAmendWorkflow;
}

export function getVarVolDispersionUnderlyingFieldsArray(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): UnderlyingField[] {
  const { strategyType } = selectors.getStrategyData(state, strategyId);
  const { legs } = selectors.getStrategyDefinition(state.referenceData, strategyType);
  const masterStrategyDefinitionLeg = legs.find(leg => leg.master);

  const masterLegData = selectors.getStrategyMasterLeg(state, strategyId, selectors);
  const masterLegProduct = selectors.getProduct(state, masterLegData.productId);

  const isMasterLegOtcOptionInAmendWorkflow = selectors.isOtcOptionLikeLegInAmendWorkflowStatus(
    state,
    rfqId,
    masterLegData.uuid,
    selectors,
  );
  const isMasterLegVarOrVolSwapInAmendWorkflow = selectors.isVSwapProductInAmendWorkflowStatus(
    state,
    rfqId,
    masterLegProduct,
    selectors,
  );

  const underlyingId = selectors.getUnderlyingId(masterLegProduct);
  return [
    {
      type: 'NON_CUSTOM',
      legId: masterLegData.uuid,
      underlyingId,
      bloombergCode: selectors.getBloombergCode(state, underlyingId, selectors),
      underlyingTypes: masterStrategyDefinitionLeg?.availableProductUnderlyingTypes ?? [],
      isDisabled: isMasterLegOtcOptionInAmendWorkflow || isMasterLegVarOrVolSwapInAmendWorkflow,
    },
  ];
}

function hasRfqObsoleteProductSchedule(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): boolean {
  const products = selectors.getRfqProducts(state, rfqId, selectors);
  return (
    products.length > 0 &&
    products.some(product => {
      return (isElsProduct(product) || isClsProduct(product)) && product.isScheduleObsolete;
    })
  );
}

export function getUnderlyingId(product: Product): string | undefined {
  if (isSingleUnderlyingDerivativeProduct(product)) {
    return product.underlyingId;
  }

  if (isListedProduct(product)) {
    return product.refId;
  }

  return undefined;
}

export function getBloombergCode(
  state: AppState,
  underlyingId: string | undefined,
  selectors: Selectors,
) {
  if (!underlyingId) {
    return undefined;
  }

  const underlyingInfo = selectors.getUnderlyingInfo(state, underlyingId);
  return underlyingInfo ? underlyingInfo.bloombergCode : undefined;
}

export function canClsScheduleBeGenerated(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  return (
    isClsProduct(product) &&
    product.generateFrom !== undefined &&
    product.fixedDay !== undefined &&
    product.conventionDay !== undefined &&
    product.rateReset !== undefined &&
    product.resetMode !== undefined &&
    product.brokenPeriod !== undefined
  );
}

export function getIsClsScheduleWarningDisplayed(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const isClsProduct = selectors.isClsStrategy(state, strategyId, selectors);
  if (!isClsProduct) {
    return false;
  }

  const isScheduleObsolete = selectors.hasRfqObsoleteProductSchedule(state, rfqId, selectors);

  const areSomeClsScheduleDates = selectors.areSomeClsScheduleDates(state, strategyId, selectors);
  const canClsScheduleBeGenerated = selectors.canClsScheduleBeGenerated(
    state,
    strategyId,
    selectors,
  );

  return isScheduleObsolete && areSomeClsScheduleDates && canClsScheduleBeGenerated;
}

export function areSomeClsScheduleDates(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const rateData = selectors.selectScheduleRateLegDates(state, strategyId, selectors) ?? [];

  return rateData.length > 0;
}

export function canElsScheduleBeGenerated(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);
  const isTrs = isElsProduct(product) && product.elsType === 'TRS';
  const feature = selectors.getFeature(state.featureState, {
    strategyId,
    type: 'DIVIDEND_COMPONENT',
  });
  const dividendPaymentDate = feature?.dividendPaymentDateType;

  return (
    isElsProduct(product) &&
    product.generateFrom !== undefined &&
    product.wRateResetOnEach !== undefined &&
    product.equityResetType !== undefined &&
    product.conventionDay !== undefined &&
    product.rateReset !== undefined &&
    product.brokenPeriod !== undefined &&
    (!isTrs || dividendPaymentDate !== undefined)
  );
}

export function getIsElsScheduleWarningDisplayed(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const isElsProduct = selectors.isElsStrategy(state, strategyId, selectors);
  if (!isElsProduct) {
    return false;
  }
  const isScheduleObsolete = selectors.hasRfqObsoleteProductSchedule(state, rfqId, selectors);
  const areSomeElsScheduleDates = selectors.areSomeElsScheduleDates(state, strategyId, selectors);
  const canElsScheduleBeGenerated = selectors.canElsScheduleBeGenerated(
    state,
    strategyId,
    selectors,
  );

  return isScheduleObsolete && areSomeElsScheduleDates && canElsScheduleBeGenerated;
}

export function areSomeElsScheduleDates(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const equityData = selectors.selectScheduleEquityLegDates(state, strategyId, selectors) ?? [];
  const rateData = selectors.selectScheduleRateLegDates(state, strategyId, selectors) ?? [];
  const dividendData = selectors.selectScheduleDividendLegDates(state, strategyId, selectors) ?? [];

  return [...equityData, ...rateData, ...dividendData].length > 0;
}

export function getElsScheduleWarning(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): string | undefined {
  const { bookingValidation } = selectors.getRfqData(state, rfqId);

  return bookingValidation?.warnings;
}
