import type { Selectors } from '@/bootstrap/selectors';
import type { PriceWithKey } from '@/neos/business/rfq/quotes/quote.selectors';
import type { AppState } from '@/bootstrap/store.ts';

export interface MidPrices {
  displayEmptyFirstLeg: boolean;
  strategyMidOrPtmmmPrice: PriceWithKey;
  midOrPtmmLegsPrices: PriceWithKey[];
}

export function getMidPriceModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): MidPrices {
  const { fairPriceId, quoteId } = selectors.getStrategyData(state, strategyId);

  const {
    displayedPricesLegIds: legIds,
    displayReadonlyCellsForCompositionLeg: displayEmptyFirstLeg,
  } = selectors.getIdsOfLegsWithDisplayedPrices(state, rfqId, strategyId, selectors);

  const isFairPriceFuruteOrForwardPtmmm = selectors.isFairPriceFuruteOrForwardPtmmm(
    state,
    rfqId,
    selectors,
  );

  const { preTradeMidMarketMarkPrice } = quoteId
    ? selectors.getQuote(state, quoteId)
    : { preTradeMidMarketMarkPrice: undefined };

  const shouldBePtmmmPrice =
    isFairPriceFuruteOrForwardPtmmm && preTradeMidMarketMarkPrice !== undefined;

  const midOrPtmmLegsPrices = shouldBePtmmmPrice
    ? selectors.getLegsPtmmms(state, legIds, selectors)
    : getLegsMidPrices(state, legIds, selectors);

  const { mid } = fairPriceId ? selectors.getFairPrice(state, fairPriceId) : { mid: undefined };

  const midOrPtmmmPrice = shouldBePtmmmPrice ? preTradeMidMarketMarkPrice : mid;

  return {
    displayEmptyFirstLeg,
    strategyMidOrPtmmmPrice: { price: midOrPtmmmPrice, key: strategyId },
    midOrPtmmLegsPrices,
  };
}

function getLegsMidPrices(
  appState: AppState,
  legIds: string[],
  selectors: Selectors,
): PriceWithKey[] {
  return legIds.map(legId => {
    const { fairPriceId } = selectors.getLegData(appState, legId);

    const { mid } = fairPriceId
      ? selectors.getFairPrice(appState, fairPriceId)
      : { mid: undefined };

    return { key: legId, price: mid };
  });
}
