import type { AsyncLogger } from '@/util/logging/asyncLogger';
import { mt } from '@/util/logging/messageTemplates';
import type { Middleware } from 'redux';
import type { Action } from '../../actions';
import type { ExtendedDispatch } from '@/bootstrap/thunks.ts';

export function createLoggingMiddleware(logger: AsyncLogger): Middleware {
  const middleware = () => (next: ExtendedDispatch) => (action: Action) => {
    logger.info(mt.reduxAction, action.type, action);
    if (action.type === 'APP_CRASHED' || action.type === 'CRITICAL_APP_CRASHED') {
      logger.error(mt.reduxErrorAction, action.rawError?.message, action.rawError, action.source);
    }
    if (action.type === 'DEBUG_ACTION') {
      logger.debug(mt.debug, action.showLogInConsoleAndKibana, action.message, action.data);
    }
    if (action.type === 'ERROR_ACTION') {
      logger.error(mt.backEndErrorMessage, action.message, action.data);
    }
    return next(action);
  };

  return middleware as Middleware;
}
