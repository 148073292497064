import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import {
  FutureMaturityCellsComponent,
  type FutureMaturityCellsOwnProps,
} from './FutureMaturityCells';
import { getFutureMaturityCellsModel } from './getFutureMaturityCellsModel';

export const FutureMaturityCells = (ownProps: FutureMaturityCellsOwnProps) => {
  const stateProps = useAppSelector(state => {
    return getFutureMaturityCellsModel(state, ownProps.rfqId, ownProps.strategyId, selectors);
  });

  return <FutureMaturityCellsComponent {...ownProps} {...stateProps} />;
};
