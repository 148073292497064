import type { AppState } from '@/bootstrap/store.ts';

export function getUnderlyingTotalReturnFutureMaturities(
  state: AppState,
  underlyingId: string,
): string[] {
  const underlyingStateInfo = state.underlyingState[underlyingId];

  return underlyingStateInfo &&
    underlyingStateInfo.hasAllUnderlyingInfo &&
    underlyingStateInfo.totalReturnFuture
    ? underlyingStateInfo.totalReturnFuture.maturityInfo
    : [];
}
