import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import { type Thunks, thunks } from '@/bootstrap/thunks';
import type { ExternalPreconfirmationRequest } from '@/neos/business/preconfirmation/external/externalOnyxPreconfirmationModel';
import type { InternalPreconfirmationRequest } from '@/neos/business/preconfirmation/internal/internalOnyxPreconfirmationModel';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { type StateObservable, ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import type { Destination } from '../../../../neos/business/neosModel';
import { type ToOnyxMappers, mappers } from '../../mappers';
import type { OnyxError } from '../../mappers/error';
import type { OnyxTransaction } from '../../neosOnyxModel';
import { uiActionCreators } from '../../ui/uiActionCreators';
import type { UiState } from '../../ui/uiState';
import { createLoadPreconfirmationEmailPreviewApi } from './preconfirmationEmailPreviewApi';
import type { PreconfirmationEmailPreview } from './preconfirmationEmailPreviewModel';
import type { ThunkEpic } from '@/bootstrap/epics.ts';
import type { AppState } from '@/bootstrap/store.ts';

export interface LoadPreconfirmationEmailPreviewApi {
  loadPreconfirmationEmail: (
    rfqId: string,
    mailOptions: ExternalPreconfirmationRequest | InternalPreconfirmationRequest,
    destination: Destination,
    transaction: OnyxTransaction,
    ignoreFieldChanges: boolean,
  ) => Observable<PreconfirmationEmailPreview>;
}

export function loadPreconfirmationEmailPreviewEpic(http: SgmeHttp) {
  const api = createLoadPreconfirmationEmailPreviewApi(http);
  return createLoadPreconfirmationEmailPreviewEpic(
    api,
    thunks,
    selectors,
    actionCreators,
    mappers.toOnyxMappers,
  );
}

export function createLoadPreconfirmationEmailPreviewEpic(
  api: LoadPreconfirmationEmailPreviewApi,
  { createErrorToasterThunk, neos: { createHandleErrorsThunk } }: Thunks,
  { getIsPreconfirmationModalOpened }: Selectors,
  {
    neos: { preconfirmationEmailPreviewCrudAction: preconfirmationEmailCrudAction },
  }: ActionCreators,
  toOnyxMappers: ToOnyxMappers,
): ThunkEpic {
  return (action$, state$: StateObservable<AppState>) =>
    action$.pipe(
      ofType('LOAD_GIVE_UP_EMAIL_REQUESTED'),
      mergeMap(({ rfqId, destination, mailOptions, ignoreFieldChanges }) => {
        const transaction = toOnyxMappers.mapToOnyxTransaction(
          state$.value,
          rfqId,
          selectors,
          toOnyxMappers,
        );

        return api
          .loadPreconfirmationEmail(
            rfqId,
            mailOptions,
            destination,
            transaction,
            ignoreFieldChanges,
          )
          .pipe(
            filter(() => getIsPreconfirmationModalOpened(state$.value.ui as UiState, rfqId)),
            map(preconfirmationEmail =>
              preconfirmationEmailCrudAction.upsert({ rfqId, destination }, preconfirmationEmail),
            ),
            catchError(({ response }: { response: OnyxError }) => {
              if (!ignoreFieldChanges) {
                return [
                  uiActionCreators.rfqUiCrudActions.update(rfqId, {
                    preconfIgnoreChangesModalInfo: {
                      isModalDisplayed: true,
                      error: response,
                      type: destination === 'EXTERNAL' ? 'externalPreview' : 'internalPreview',
                    },
                  }),
                ];
              }

              return [
                createErrorToasterThunk(
                  {
                    message: 'Error getting preconfirmation email preview',
                  },
                  response,
                ),
                ...(response?.fieldErrors?.length
                  ? [createHandleErrorsThunk(rfqId, response.fieldErrors)]
                  : []),
              ];
            }),
          );
      }),
    );
}
