import type { Thunk } from '@/bootstrap/thunks';
import { range } from 'lodash';
import type { LegAndQuoteIds } from '.';

export function createRfqAddLegsThunk(strategyId: string, legsNumber: number = 1): Thunk {
  return function rfqAddLegsThunk(
    dispatch,
    getState,
    {
      selectors: { getStrategyData },
      thunks: {
        neos: { createStrategyAddLegsThunk },
      },
      actionCreators: {
        neos: { createDefaultQuotesAddedAction },
      },
      services: { idCreator },
    },
  ) {
    const state = getState();
    const { legIds } = getStrategyData(state, strategyId);
    const legAndQuoteIds = range(legsNumber).map((): LegAndQuoteIds => {
      const uuid = idCreator.createId();
      return {
        legId: uuid,
        quoteId: uuid,
      };
    });

    dispatch(
      createDefaultQuotesAddedAction(
        legAndQuoteIds.map(({ legId, quoteId }) => ({
          quoteId,
          parentId: { type: 'Leg', id: legId },
          unit: undefined,
          type: undefined,
        })),
      ),
      createStrategyAddLegsThunk(strategyId, legAndQuoteIds, legIds.length),
    );
  };
}
