import { selectors } from '@/bootstrap/selectors';
import { connect } from 'react-redux';
import { type BlotterPropsFromState, BlotterWithCss } from './Blotter';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(state: AppState): BlotterPropsFromState {
  return {
    isLoading: selectors.isRfqLoading(state.ui, 'BLOTTER'),
  };
}

export const Blotter = connect(mapStateToProps)(BlotterWithCss);
