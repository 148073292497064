import { useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from './selectors';

export function GlobalFileDragWrapper() {
  const isUserDraggingFile = useAppSelector(selectors.selectIsUserDraggingFile);

  return (
    <div style={{ visibility: isUserDraggingFile ? undefined : 'hidden' }} id="file-drag-wrapper" />
  );
}
