import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { NeosTooltip } from '@/neos/components/share/tooltip/NeosTooltip';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { sortBy } from 'lodash';
import type { FC } from 'react';
import { Tooltip } from 'react-bootstrap';
import { InvalidTooltip } from '../../InvalidTooltip';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type OtcAllocCounterpartProps = {
  rfqId: string;
  legId: string;
  allocationId: string;
  allocCounterpartId: number | undefined;
  errorCounterparty: boolean | undefined;
};

export const OtcAllocCounterpart: FC<OtcAllocCounterpartProps> = ({
  rfqId,
  legId,
  allocationId,
  allocCounterpartId,
  errorCounterparty,
}) => {
  const dispatch = useAppDispatch();
  const rfqClients = useAppSelector(state => selectors.getCounterparts(state, rfqId) ?? []);
  const extraClients = useAppSelector(
    state => selectors.getAllocExtraCounterparts(state, rfqId) ?? [],
  );

  const availableClients = sortBy(rfqClients, c => c.name);

  const allClients = [...availableClients, ...extraClients];

  const counterpart = allClients.filter(c => c.id === allocCounterpartId)?.[0];

  const clientError =
    allocCounterpartId !== undefined &&
    (errorCounterparty || !allClients.find(c => c.id === allocCounterpartId));
  const clientWarning = !!extraClients.find(c => c.id === allocCounterpartId);

  const onCounterpartChanged: (allocationId: string, counterpartId?: number) => void = (
    allocationId,
    counterpartId,
  ) => {
    dispatch(
      neosActionCreators.otcAllocationCrudActions.update(
        { allocationId, rfqId, legId },
        {
          counterpartId,
          eligibleMedia: undefined,
          eligibilityReason: undefined,
          markitWireMode: undefined,
          media: undefined,
          electronicMediaUser: undefined,
          forcedReason: undefined,
          independantAmountValueDate: undefined,
          independantAmountCurrencyUnit: undefined,
          independantAmountCurrencyValue: undefined,
          independantAmountValue: undefined,
          independantAmountUnit: undefined,
        },
      ),
    );
    if (counterpartId) {
      dispatch(
        neosActionCreators.electronicMediaUsersActionCreators.createElectronicMediaUsersRequestedAction(
          counterpartId,
        ),
      );
      dispatch(neosActionCreators.createNeosPredealCheckRefreshRequestedAction(rfqId));
      dispatch(neosActionCreators.createDefaultConfirmationMediaAndMcaRequestedAction(rfqId));
      dispatch(
        neosActionCreators.allocationUiCrudActions.patchOrInsert(allocationId, {
          errors: { Counterparty: false },
        }),
      );
    }
  };

  const options = allClients.map(({ id, name, eliotCode, mnemo }) => ({
    value: id.toString(),
    label: name,
    data: [eliotCode, mnemo],
  }));

  const selectElement = (
    <div>
      <InvalidTooltip show={clientError}>
        <NeosSelectWithAutocomplete
          classes={`${clientError ? ' field-error errorable-bloc-select-override ' : ''}`}
          isLabelBold
          data-e2e="otc-alloc-counterpart"
          value={allocCounterpartId?.toString()}
          onChange={selected =>
            onCounterpartChanged(
              allocationId,
              selected?.value ? parseInt(selected?.value, 10) : undefined,
            )
          }
          addEmptyOption
          options={options}
        />
      </InvalidTooltip>
    </div>
  );

  const clientNotInPortfolio = (
    <SimpleNeosTooltip
      type="warning"
      message="This counterparty is not in your client's portfolio"
      id="extra-client-tooltip"
    >
      {selectElement}
    </SimpleNeosTooltip>
  );

  const clientInfo = counterpart ? (
    <NeosTooltip
      delay={{ show: 350, hide: 300 }}
      overlay={
        <Tooltip className="counterpart-info-tooltip" id={`extra-client-info-tooltip`}>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>{counterpart.name}</td>
              </tr>
              <tr>
                <td>Bdr Code:</td>
                <td>{counterpart.id}</td>
              </tr>
              <tr>
                <td>Mnemo:</td>
                <td>{counterpart.mnemo}</td>
              </tr>
              <tr>
                <td>Eliot Code:</td>
                <td>{counterpart.eliotCode}</td>
              </tr>
            </tbody>
          </table>
        </Tooltip>
      }
    >
      {selectElement}
    </NeosTooltip>
  ) : (
    selectElement
  );
  return clientWarning ? clientNotInPortfolio : clientInfo;
};
