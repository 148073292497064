import { getTransfereeContactsEpic } from '@/neos/business/rfq/transfereeContacts/transfereeContactsEpic';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { combineEpics } from 'redux-observable';
import { getActorsSetupRootEpic } from './actorsSetup/actorsSetupEpics';
import { loadPreconfirmationEmailPreviewEpic } from './preconfirmationEmailPreview/preconfirmationEmailPreviewEpic';
import { getVersionsEpic } from './versions/versionsEpics';
import type { ThunkEpic } from '@/bootstrap/epics.ts';
import { getCreateTopicEpic } from '@/neos/business/rfq/epics/createTopicEpic.ts';
import { getRecomputeRfqEpic } from '@/neos/business/rfq/epics/recomputeRfqEpic.ts';
import { getDefaultRfqEpic } from '@/neos/business/rfq/epics/defaultRfqEpic.ts';
import { getIbChatEpic } from '@/neos/business/rfq/epics/ibChatEpic.ts';

export function getRfqRootEpic(http: SgmeHttp): ThunkEpic {
  return combineEpics(
    getActorsSetupRootEpic(http),
    getCreateTopicEpic(http),
    getRecomputeRfqEpic(http),
    getDefaultRfqEpic(http),
    getIbChatEpic(http),
    getVersionsEpic(http),
    loadPreconfirmationEmailPreviewEpic(http),
    getTransfereeContactsEpic(http),
  );
}
