import { useAppSelector } from '@/bootstrap/hooks';

import { actionCreators } from '@/bootstrap/actions';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import {
  type TerminationRightType,
  terminationRightTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures';
import { ElsBlocField } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/ElsBlocField';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { useContext } from 'react';
import { isElsProduct } from '../../../../../../../../neos/business/neosModel';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

export function EarlyTerminationRights() {
  const { rfqId, strategyId } = useContext(ElsFeaturesContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  if (!isElsProduct(product)) {
    return null;
  }
  const { uuid, terminationRights } = product;
  const { update: updateProduct } = actionCreators.neos.productCrudActions;

  const onTerminationRightsChange = (newTerminationRights: TerminationRightType | undefined) => {
    dispatch(updateProduct(uuid, { terminationRights: newTerminationRights }));
    dispatch(thunks.neos.createDefaultConfirmMediaRequested(rfqId));
  };

  return (
    <ElsBlocField
      minWidth={160}
      label="Early Term. Rights"
      renderInput={readOnly => (
        <NeosSelect
          readOnly={readOnly}
          value={terminationRights}
          onChange={onTerminationRightsChange}
          data-e2e="early-termination-section-termination-rights"
          addEmptyOption
          options={terminationRightTypes}
        />
      )}
    />
  );
}
