import type { Selectors } from '@/bootstrap/selectors';
import type { LegData } from '../../../../../../../neos/business/neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export function getFieldRelevantLegsData(
  state: AppState,
  strategyId: string,
  sameProductField: boolean,
  selectors: Selectors,
): LegData[] {
  if (sameProductField) {
    return [selectors.getStrategyMasterLeg(state, strategyId, selectors)];
  }

  const { legsData } = selectors.getStrategyLegsOrIndexLegData(state, strategyId, selectors);

  return legsData;
}
