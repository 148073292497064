import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch, NeosThunks } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch } from 'react-redux';
import {
  SimilarActivitiesSecondaryTypeWidgetComponent,
  type SimilarActivitiesSecondaryTypeWidgetDispatchProps,
  type SimilarActivitiesSecondaryTypeWidgetOwnProps,
} from './SimilarActivitiesSecondaries';
import { getSimilarActivitiesSecondaryTypeWidgetModel } from './getSimilarActivitiesSecondariesModel';

export const SimilarActivitiesSecondaryTypeWidget = (
  ownProps: SimilarActivitiesSecondaryTypeWidgetOwnProps,
) => {
  const stateProps = useAppSelector(state =>
    getSimilarActivitiesSecondaryTypeWidgetModel(state, ownProps, selectors),
  );
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, ownProps, neosThunks);
  return (
    <SimilarActivitiesSecondaryTypeWidgetComponent
      {...ownProps}
      {...stateProps}
      {...dispatchProps}
    />
  );
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId, strategyId }: SimilarActivitiesSecondaryTypeWidgetOwnProps,
  { createSimilarActivitiesSecondaryTypesOnLegsThunk, createSecondaryTypesOnLegsThunk }: NeosThunks,
): SimilarActivitiesSecondaryTypeWidgetDispatchProps {
  return {
    onEventTypeChange(eventType, legId, isLongClick) {
      if (eventType !== undefined) {
        if (isLongClick) {
          dispatch(createSecondaryTypesOnLegsThunk({ rfqId, strategyId, eventType }));
        } else {
          dispatch(createSimilarActivitiesSecondaryTypesOnLegsThunk({ rfqId, legId, eventType }));
        }
      }
    },
  };
}
