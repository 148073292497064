import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  type AlertBlotterRowDoubleClickedEvent,
  AlertMenuModalComponent,
  type AlertMenuModalDispatchProps,
  type AlertMenuModalStateProps,
} from './AlertMenuModal';
import type { AppState } from '@/bootstrap/store.ts';

export const AlertMenuModal = connect(mapStateToProps, mapDispatchToProps)(AlertMenuModalComponent);

function mapStateToProps(appState: AppState): AlertMenuModalStateProps {
  const { isAlertMenuModalShown, getDisplayTimeZone } = selectors;
  const { getAlertsAsArray } = selectors;
  const displayTimezone = getDisplayTimeZone(appState.ui.userPreferences);
  return {
    isModalShown: isAlertMenuModalShown(appState.ui.headerMenu),
    pendingAlerts: getAlertsAsArray(appState),
    displayTimezone,
  };
}

function mapDispatchToProps(dispatch: ExtendedDispatch): AlertMenuModalDispatchProps {
  const hideModal = () => dispatch(neosActionCreators.createAlertMenuHiddenAction());

  return {
    hideModal,
    openRfq: ({ data }: AlertBlotterRowDoubleClickedEvent) => {
      if (data && data.rfqUuid) {
        dispatch(
          neosActionCreators.loadTransactionAtVersion({ rfqId: data.rfqUuid, version: 'LATEST' }),
          createLogAnalyticsAction('NEOS WORKSPACE', 'Alert RFQ open', data.rfqUuid),
        );
        hideModal();
      }
    },
  };
}
