import { neosActionCreators } from '@/neos/business/neosActionCreators';
import type { OtcAllocation } from '@/neos/business/neosModel';
import { Switch } from '@/neos/components/share/switch/Switch';
import type { SalesMarginAmountInfo } from '../OtcAllocation';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type SalesCreditManualBookingProps = {
  allocation: OtcAllocation;
  bookingId: string | undefined;
  rfqId: string;
  legId: string;
  hasFailedSalesMarginBookings: boolean;
  salesMarginValue: SalesMarginAmountInfo | undefined;
};

export function SalesCreditManualBooking({
  allocation,
  bookingId,
  hasFailedSalesMarginBookings,
  legId,
  rfqId,
  salesMarginValue,
}: SalesCreditManualBookingProps) {
  const dispatch = useAppDispatch();

  if (!hasFailedSalesMarginBookings) {
    return null;
  }

  if (salesMarginValue?.status === 'FAILED') {
    return (
      <Switch
        checked={!!allocation.salesCreditReferenceId}
        onChange={() =>
          dispatch(
            neosActionCreators.otcAllocationCrudActions.update(
              { allocationId: allocation.uuid, rfqId, legId },
              {
                salesCreditReferenceId: allocation.salesCreditReferenceId?.id
                  ? undefined
                  : { application: 'XONE', id: bookingId },
              },
            ),
          )
        }
      />
    );
  }

  return <label></label>;
}
