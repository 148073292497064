import type { Selectors } from '@/bootstrap/selectors';
import { isRfqInternalEls } from '@/neos/business/rfq/selectors';
import { isElsProduct } from '../../strategyModel';
import type { ElsSectionLayout } from './elsSectionsModel';
import type { AppState } from '@/bootstrap/store.ts';

export const elsSectionsSelectors = {
  getElsSections,
  getFeatureToggledElsSections,
  isElsOfTypeTrs,
  isStrategyRfqInitiatedByTrader,
};

function getFeatureToggledElsSections(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): ElsSectionLayout[] {
  const rfqId = selectors.getRfqIdByStrategyId(state, strategyId);

  return getElsSections(state).map(section => {
    if (section.i === 'Dividend Leg') {
      section.isDisplayed = isElsOfTypeTrs(state, strategyId, selectors);
    }
    if (section.i === 'Brokers' && rfqId) {
      section.isDisplayed =
        isStrategyRfqInitiatedByTrader(state, strategyId, selectors) &&
        !isRfqInternalEls(state, rfqId, selectors);
    }
    return section;
  });
}

function getElsSections(state: AppState): ElsSectionLayout[] {
  return state.ui.elsSections;
}

function isElsOfTypeTrs(state: AppState, strategyId: string, selectors: Selectors) {
  const product = selectors.getStrategyProduct(state, strategyId, selectors);
  return isElsProduct(product) && product.elsType === 'TRS';
}

function isStrategyRfqInitiatedByTrader(
  state: AppState,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const rfqId = selectors.getRfqIdByStrategyId(state, strategyId);
  if (!rfqId) {
    return false;
  }
  return selectors.isRfqInitiatedByTrader(state, rfqId);
}
