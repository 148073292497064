import { useAppSelector } from '@/bootstrap/hooks';
import { type Selectors, selectors } from '@/bootstrap/selectors';
import type { DisplayNegotiatedSize } from '@/neos/business/ui/strategy/strategyUiModel';
import { NumericInput } from '@/neos/components/share/numericInput';
import { SimpleNeosTooltip } from '@/neos/components/share/tooltip/SimpleNeosTooltip';
import { kebabCase } from 'lodash';
import styles from './AllocationLegSize.module.scss';
import type { AppState } from '@/bootstrap/store.ts';

interface FullyCrossedTooltip {
  quantityStyleName: 'not-fully-crossed';
  tooltipMessage: 'Not Fully Crossed';
}

interface OverCrossedTooltip {
  quantityStyleName: 'over-crossed';
  tooltipMessage: 'Over Crossed';
}

export type AllocationTooltip = FullyCrossedTooltip | OverCrossedTooltip;

export interface AllocationSize {
  label: string;
  value: number | undefined;
}

type AllocationLegSizeProps = {
  legId: string;
  strategyId: string;
  displayedTooltip?: AllocationTooltip;
};

export function AllocationLegSize({ legId, strategyId, displayedTooltip }: AllocationLegSizeProps) {
  const size = useAppSelector(state =>
    getDisplayedValueAndLabelBaseOnSizeType(state, strategyId, legId, selectors),
  );

  const uiSizeType = useAppSelector(state =>
    selectors.getDisplayedSizeTypeByStrategy(state, strategyId, selectors),
  );

  return (
    <div>
      <label className="mb-0 me-3">{size?.label}</label>
      <SimpleNeosTooltip
        id={`leg-details-quantity-${legId}`}
        message={displayedTooltip?.tooltipMessage}
      >
        <span>
          <NumericInput
            data-e2e={kebabCase(uiSizeType)}
            className={`${
              displayedTooltip?.quantityStyleName ? styles[displayedTooltip?.quantityStyleName] : ''
            }`}
            value={size?.value}
            readOnly
          />
        </span>
      </SimpleNeosTooltip>
    </div>
  );
}

export function getDisplayedValueAndLabelBaseOnSizeType(
  state: AppState,
  strategyId: string,
  legId: string,
  selectors: Selectors,
): AllocationSize | undefined {
  const uiSizeType = selectors.getDisplayedSizeTypeByStrategy(state, strategyId, selectors);
  const { quantity, notional, varUnit, numberOfLots } = selectors.getLegData(state, legId);

  const values: Record<DisplayNegotiatedSize, number | undefined> = {
    NUMBER_OF_LOTS: numberOfLots,
    QUANTITY: quantity,
    VAR_UNIT: varUnit,
    NOTIONAL: notional,
    LOCAL_NOTIONAL: notional,
  };
  const labels: Record<DisplayNegotiatedSize, string> = {
    NUMBER_OF_LOTS: 'Leg Size',
    QUANTITY: 'Leg Qty',
    VAR_UNIT: 'Leg VarUnit',
    NOTIONAL: 'Leg Amount',
    LOCAL_NOTIONAL: 'Leg Amount',
  };
  if (!uiSizeType) {
    return undefined;
  }
  return {
    label: labels[uiSizeType],
    value: values[uiSizeType],
  };
}
