import { selectors } from '@/bootstrap/selectors';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { connect } from 'react-redux';
import {
  RefAdjustedComponent,
  type RefAdjustedDispatchProps,
  type RefAdjustedMapStateToProps,
  type RefAdjustedOwnProps,
} from './RefAdjusted';
import { getRefAdjustedModel } from './getRefAdjustedModel';
import type { AppState } from '@/bootstrap/store.ts';

export const RefAdjusted = connect(mapStateToProps, mapDispatchToProps)(RefAdjustedComponent);

function mapStateToProps(
  state: AppState,
  { rfqId }: RefAdjustedOwnProps,
): RefAdjustedMapStateToProps {
  return getRefAdjustedModel(state, rfqId, selectors);
}

function mapDispatchToProps(
  dispatch: ExtendedDispatch,
  { rfqId }: RefAdjustedOwnProps,
): RefAdjustedDispatchProps {
  return {
    onRefAdjustedChanged: (underlyingId: string, refAdjusted: number | undefined) => {
      dispatch(
        neosActionCreators.createRefAdjustedChangedAction({ rfqId, underlyingId }, refAdjusted),
      );
    },
  };
}
