import type { Selectors } from '@/bootstrap/selectors';

import type { AppState } from '@/bootstrap/store.ts';

export interface LegLinkCellsModel {
  legIds: string[];
}

export function getLegLinkCellsModel(
  state: AppState,
  _: string,
  strategyId: string,
  selectors: Selectors,
): LegLinkCellsModel {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  return {
    legIds,
  };
}
