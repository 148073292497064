import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { AlertMenuDisplayedAction, AlertMenuHiddenAction } from './headerMenuActions';
import type { Action } from '@/bootstrap/actions.ts';

export const headerMenuActionCreators = {
  createAlertMenuHiddenAction,
  createAlertMenuDisplayedActionAndLog,
};

function createAlertMenuHiddenAction(): AlertMenuHiddenAction {
  return {
    type: 'ALERT_MENU_HIDDEN',
  };
}

function createAlertMenuDisplayedAction(): AlertMenuDisplayedAction {
  return {
    type: 'ALERT_MENU_DISPLAYED',
  };
}

function createAlertMenuDisplayedActionAndLog(): Action[] {
  return [
    createAlertMenuDisplayedAction(),
    createLogAnalyticsAction('NEOS WORKSPACE', 'Alert Icon', 'Open'),
  ];
}
