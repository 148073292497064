import { selectors } from '@/bootstrap/selectors';
import { connect } from 'react-redux';
import { type NeosTabsStateProps, NeosTabsUnconnected } from './NeosTabs';
import type { AppState } from '@/bootstrap/store.ts';

function mapStateToProps(state: AppState): NeosTabsStateProps {
  const isDividedView = selectors.isDividedView(state.ui, selectors);
  const blotterPosition = selectors.getBlotterPosition(state.ui.blotter);
  const tabId = selectors.getSelectedTab(state.ui.tabManagement);

  return {
    isDividedView,
    blotterPosition: tabId !== 'BLOTTER' ? blotterPosition : 'CENTER',
    tabId,
  };
}

export const NeosTabs = connect(mapStateToProps)(NeosTabsUnconnected);
